<!--hard coded not good app-cube3d *ngIf="cs.enable(component,'cube3d')"> </app-cube3d-->


<app-sliders *ngIf="cs.enable(component,'sliders')"> </app-sliders>

<app-exam-list *ngIf="cs.enable(component,'exam-list-top')"> </app-exam-list>


<div *ngIf="cs.enable(component,'cubehor')" class= 'pb-1 mb-1'>
    <app-cubehor   > </app-cubehor>
    </div>
<app-thoughtoftheday *ngIf="cs.enable(component,'thoughtoftheday')" >... </app-thoughtoftheday>
<app-usp *ngIf="cs.enable(component,'usp')"></app-usp>
<app-featurete *ngIf="cs.enable(component,'featurete')"></app-featurete>
<app-exam-list *ngIf="cs.enable(component,'exam-list')"> </app-exam-list>
<app-our-products *ngIf="cs.enable(component,'products')"> </app-our-products>
<app-platform-set *ngIf="cs.enable(component,'platform-set')"> </app-platform-set>
<div *ngIf="cs.enable(component,'testimonials')">
<p class= "lead, text-center mx-5 mt-1  font-italic "> We would love your testimonial:</p>
<app-testimonials > </app-testimonials>
</div>