<div class="jumbotron text-center">
	<h5>Center of Excellence </h5>
	News that matter to Educationist!
</div>

<div >	

	<ul *ngIf="questions" >

		<li *ngFor="let question of questions; let i= index">

			<div *ngIf="i<=5">
			<app-news-viewer  [q]= 'question' [i]= i > </app-news-viewer>

			</div>

		</li>
		<app-display-ad>
		</app-display-ad>
		<div class="rainbow-text float-center font-italic small " >dedicated to Bestu</div>
	</ul>

</div>
