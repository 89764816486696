  import { Component, Input, OnInit } from '@angular/core';
  import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BtnService } from '../../gen/btn.service';
  
  import { BoardDataService } from '../../board-data.service';
  import { ClientInfoService } from '../../shared/client-info.service';
  import { FixedDataService } from '../../shared/fixed-data.service';
import { Observable } from 'rxjs';
  
  
  

  @Component({
    selector: 'app-usp',
    templateUrl: './usp.component.html',
    styleUrls: ['./usp.component.scss']
  })
  export class UspComponent implements OnInit {
    @Input() ovrSub:string;
    @Input() forPdf:boolean;
    
    images: string[];
    markets: any;
    headerObj:any;
   // static EbIntroComponent: any[] | Type<any>;
    get rootUrl() {return  this.cs.getRoot() };
  
    get site() {return  this.cs.getSite()} ;
    get cat() {return this.cs.getCat() };
    get board() {return this.cs.getBoard()};
    get course() { return this.cs.getCourse()};
    get offering() { return this.cs.getOffering()};
    get subject() { return this.cs.getSubject()};
    loading:boolean;
    
    headerId:number;
    noCall:boolean;
    data$: Observable<any>;
   calling:boolean;
  
    constructor(private cs:ClientInfoService, private ds: BoardDataService,private fd:FixedDataService ,private modalService: NgbModal, public b:BtnService) { }
  
  
    
  
  openScrollableContent(longContent) {
    this.modalService.open(longContent, { scrollable: true });
  }
  
  show(q:any){ return this.b.showQstn("usp",q)}
  
    ngOnInit() {
  
     // this.markets= this.fd.getMarketData(this.site);
      
     let key =  this.ovrSub?this.ovrSub:this.cat + "~usp"; 
            
     this.data$ =  this.ds.getCfgData(this.site,this.board,this.course,"cfg",key);
      
     /*
      this.ds.getCfgHeaders(this.site,this.board,this.course,'cfg',key).subscribe(data => { 
        if((data as any).dataList[0]){
        this.headerId= (data as any).dataList[0].id;
        this.headerObj = (data as any).dataList[0];
  
        this.ds.getSiteQuestions(this.site,this.board,this.course,'cfg',this.subject,this.headerId).subscribe(data => {
        this.markets= data;
        });
    
      }});
      */
      
      }
  
  
    getRouteUrl(category:string, extLink: string,format?:string): string{
    return this.cs.getRouteUrl(category, extLink,format);
    }
  
    
    navigate(category:string, extLink: string,format?:string){
      if(category){
      this.cs.navigate(category, extLink,format);
      }
      }

    navigatePrdDesc(headerObj:any){
      this.cs.navigate(headerObj.extUrl, '');
    }
  
  }
  