

<div class= "fullWidth" [hidden]="submitted" *ngIf= "detail">
    
  <!--&& this.offering.includes('cfg')-->
  
    <form #ngform #qdForm="ngForm" (ngSubmit)="onSubmit()">
     
     <div  *ngIf= "this.offering">
      
      
    <app-doc-mnt [h] = h [detail] = detail [edi] = edi [i] = i [offering] = offering 
      [parentFormGroup] = qdForm ></app-doc-mnt>
    
    
    <!--
    <app-upd-cfg-dtl [detail] = detail [i] = i>  </app-upd-cfg-dtl>
    -->
   
    </div>
            
    
  <button type="submit" class="btn btn-primary mx-3 ">Submit</button>
      
      
  <button id= 'updQstn_json-editor' type="button"  *ngIf='cs.enable("updQstn","json-editor")' class="btn btn-danger badge mx-3 float-right " (click) = "jsonUpd= !jsonUpd" >raw-json-update</button>
      
      <!-- check it later -->
  
      <button *ngIf='cs.enable("updQstn", "hDelete",detail)' type="submit" class="btn btn-danger" (click) = delete(detail.id)>
     Hard Delete {{detail.prntDtlId?'Customized': ""}}
  </button>


<!-- soft delete on crdu-menu already below not implemented need .subscribe
  <button type="submit" class="btn btn-warning" (click) = detailService.changeInactive(detail.id,true)>
  Soft Delete {{detail.prntDtlId?'Customized': ""}}
 </button>
 -->


<!-- idea is prntDtlId means this is a override rec for an master/prnt detail -->

<!--    
<br>
     <i> Note: please fillup & press upload if you have file else press submit. one is enough.
    </i>
  -->

    </form>
  </div>
  
  
  
      
  <!-- check it later -->


  <app-my-json *ngIf='jsonUpd' [q] = 'detail' [table] = '"qstnDtl"'  > </app-my-json>


<div [hidden]="!submitted">
   
<ngb-toast *ngIf="msg" header=""   (hide)="msg = ''" delay= "2000"
[autohide]="true" 
>
  {{ msg }}
</ngb-toast>


</div>

