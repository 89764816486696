


<div *ngIf="calling ; else noCall">

  <div id="aa" appNoRightClick is-info is-fullheight is-bold
    class="card border-0 img-fluid" *ngIf="(qstns$ | async) as contents; else loading">
  
   <div class= "text-capitalize font-weight-bold my-0 "> 
  
  <app-hdr-view-slctr *ngIf='contents.hdr' [hdr] = contents.hdr [ftrDescObj]="contents.dtlDesc"  [q]="contents.dtlDesc" > </app-hdr-view-slctr>

  <!--
  <app-headings-plain *ngIf='contents.hdr && !qs.enable("shortTitle",i,contents.hdr)' [hdr] = contents.hdr>  </app-headings-plain>
  
  <app-headings *ngIf='!contents.hdr || qs.enable("shortTitle",i,contents.hdr)'  [hdr] = contents.hdr>  </app-headings>
  -->
  
 </div>

 <!--
<div *ngIf= "false" >
<span class= 'justify-center' *ngIf='contents.hdr&& chapter && qs.enable("groupMenu",i,contents.hdr) ' >
  <app-group-addon [hdr] =contents.hdr> </app-group-addon>
</span>

</div>
-->


<div>
  <!--
<button class="btn badge btn-md btn-secondary float-right" (click)= 'refresh()'> refresh
</button>
-->
</div>
<app-gate-mover class= "float-right" [header]=contents.hdr [i]=i> </app-gate-mover>
  
  <app-unit-menu *ngIf= "contents" [hdr] = contents.hdr> </app-unit-menu>

  <app-dgtzr-btn class="ml-2" [header]=contents.hdr></app-dgtzr-btn> 
  <app-doughnut-chart *ngIf='subject=="recommendation"' [user] = contents.hdr.owner> </app-doughnut-chart>

    <ng-container *ngIf="contents.dataList.length; else noItems">
      
      <div > 
      
        <div *ngIf='!qs.onlyQstn' class="row"> 

          <!--    
        <div class="align-self-start mr-auto">
          <app-search *ngIf='qs.enable("searchMenu")'
            class="btn-md btn-outline-primary"
            placeholder="Search by Id#"
            (delayedWord)="qs.getSearchTerm('sequence',$event)"> </app-search>
        </div>
     -->

          <!--{{qs.enable('viewAnsrEligible')}} -->
       
          <!-- moved to unit-menu -->

          <!--
        <div *ngIf="qs.enable('viewAnsrEligible')"
          class="align-self-center mx-auto">
           
          <button *ngIf='qs.enable("onlyQstnView")' (click) = 'qs.onlyQstn=!qs.onlyQstn'> Only Qstn View </button> 
          
          <button *ngIf='qs.enable("studentView")' class="btn-md btn-info" ngbTooltip='Practice:ansr btn at qstn level, Learn: inline qstn & Ansr' placement="bottom"
            (click)="qs.flipExpandAllAnsrs()">{{qs.expandedAnsrs?'Practice Mode':'Learn Mode'}} </button>

            <button *ngIf='qs.enable("teacherView")' class="btn-md btn-info" ngbTooltip='Practice:ansr btn at qstn level, Learn: inline qstn & Ansr' placement="bottom"
            (click)="qs.flipExpandAllAnsrs()">{{qs.expandedAnsrs?'Answer on Click':'show All Answers'}} </button>

        </div>
        -->
      
<!--
        <div *ngIf='qs.enable("filterDoubts")' class="btn-group btn-outline-primary">
          <button type="button" class="btn badge btn-primary ">Filter:</button>
          <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
            <button class="btn badge btn-primary  dropdown-toggle-split" ngbDropdownToggle>Doubt</button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button ngbDropdownItem (click)='qs.filterList("Dclr")'>Qstns with doubts</button>
              <button ngbDropdownItem (click)='qs.filterList("Dbkt")'>Qstns with Tag</button>
              <button ngbDropdownItem (click)='qs.filterList("Dcmnt")'>Qstns with Comment</button>
              <button ngbDropdownItem (click)='qs.filterList()'>Unfilter</button>
            
            </div>
          </div>
        </div>
 -->         

         
<!--
        <div class="align-self-end ml-auto">
          <app-search *ngIf='qs.enable("searchMenu")'
            class=" btn-md btn-outline-primary px-3"
            placeholder="Search by word"
            (delayedWord)="qs.getSearchTerm('*',$event)"> </app-search>
        </div>

      </div>
      </div>
      <div class= "font-italic badge text-danger  text-center" *ngIf= 'this.cat== "gpb" && this.offering == "tasgn-pbstr"'>
      Non-personalized offline test enabled...Please signin for personalized Test
    </div>
    -->

      <!--
            <button class= "  col-md-2 mx-2  btn-md btn-info" *ngIf="showA" (click)="hideAns()">Hide Answer </button>
          -->
      
        <app-qstn-page *ngIf='!qs.enable("newTest")' [list]=contents.dataList [h] = contents.hdr > </app-qstn-page>
         
        <!-- cat check enable to stop faster -temp display-->
        <app-test-list *ngIf='cat== "te" && qs.enable("newTest")'  [contents] =contents> </app-test-list> 
        
        
        <app-test-hdr-btn *ngIf='(contents.hdr.hdrFormat == "worksheet"|| cat =="hwrk") && qs.enable("testHdrBtn")' [h] = 'contents.hdr' [i] = i list= 'contents.dataList' >  </app-test-hdr-btn>
        

      </div>
      </div>


      <!--
      <ng-container *ngFor="let item of contents.dataList,let i=index;">
        
        <div class="container list-group list-group-flush">
          
          <app-simple-qstn class="list-group-item" [q]=item [i] = i> </app-simple-qstn>
          </div>
     
     
        </ng-container>
        
      -->
      <div >

       
   <button   class="btn btn-secondary badge mx-3 "  *ngIf="qs.enable('mergeQstn')" (click) = 'mergeQstns()' >merge selected qstns </button>


        
    <app-cmnt-list *ngIf='qs.enable("hdrCmnt") || qs.enable("finalCrctn")'  class= "mt-3"  [cmntType]= '"H"+qs.status'  [featureType] = qs.offering  [parentHdrId] = qs.chapter  [mode]="'list'" [hdrObj] = contents.hdr
    > </app-cmnt-list>

    
        
      <app-status-changer class= "float-center pb-5" [hdr]= contents.hdr *ngIf='qs.enable("changeStatusBtm")' ></app-status-changer>
      
      <button   class="btn btn-primary  float-right"  *ngIf="qs.enable('performTrend')" (click) = 'cs.navigate("elevate","")' >Check Performance Trend</button>

      <button   class="btn btn-secondary badge"  *ngIf="qs.enable('loaderSource') &&  contents.hdr && contents.hdr.extUrl " (click) = 'showTextFile=!showTextFile' >Loader file </button>
    </div>

      </ng-container>

      <app-url-shower *ngIf="showTextFile" class= "px-1"
      [format]=contents.hdr.hdrFormat [extLink]=contents.hdr.extUrl
      [title]=contents.hdr.title [headerObj]=contents.hdr> </app-url-shower>
   
      
      <!--
      <app-topic-list [q] = 'contents.dtlDesc'> </app-topic-list>
      hey u; {{contents.dtlDesc|json}}
    -->
    
    <!-- desc  moved to topic-->
      
    <div *ngIf= "contents.dtlDesc && contents.dtlDesc.topic" class= "small text-muted text-center" >
        topic :
        <span class= "text-info small  font-italic " innerHTML= {{contents.dtlDesc.topic}}></span>
         
    
          <span *ngIf= 'qs.cs.debug' class ="text-muted float-right mr-3"> q{{contents.dtlDesc.id}}</span>
       </div>
   
       
    <ng-template #noItems> . 
      <!----> 
      <app-url-shower class= "px-1" *ngIf="contents.hdr && contents.hdr.extUrl && qs.enable('textFileEdit')"
      [format]=contents.hdr.hdrFormat [extLink]=contents.hdr.extUrl
      [title]=contents.hdr.title [headerObj]=contents.hdr> </app-url-shower>
   
    </ng-template>
    


  </div>
  <ng-template #loading>loading qstns...</ng-template>
</div>
<ng-template #noCall>!</ng-template>