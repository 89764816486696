import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BoardDataService } from '../../board-data.service';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';
import { QstnDtlService } from '../qstn-dtl.service';
declare var MathJax:any;

@Component({
  selector: 'app-view-qstn-dtl',
  templateUrl: './view-qstn-dtl.component.html',
  styleUrls: ['./view-qstn-dtl.component.scss']
})
export class ViewQstnDtlComponent implements OnInit {
 
  @Input() detailId : number 
  @Input()  q: any;
  @Input() i:number;
  @Input()  hdr: any;
  
  questions$:Observable<any>;
  wholeQ: any;

  
  constructor(private detailService: QstnDtlService,public qs:QstnListService, private ds: BoardDataService) { }

  ngAfterViewChecked(){
   this.qs.typeSet('');
  }
  
  
  ngOnInit(): void {
if(!this.i){
  //note i has bug in html hance defaulted to 2, fix later
  this.i= 2;
}

 if(!this.q && this.detailId){
 
  // this.getDetail(this.detailId);
  
 this.getQstnSet(this.detailId);

 }

 
  //  MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);  
  }

  ngOnChanges() {
    //MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);

    }

    getDetail(id:number){
      id = 31354;
      this.getQstnSet(id); 
      return;

      this.detailService.get(id)
      .subscribe(data => {
      //  console.log(data)
        this.q = data;
        id = 31354;
       this.getQstnSet(id); 
        //console.log(this.detail)
        
      }, error => console.log(error));

    }


    getQstnSet(id:number){

      
    //  this.questions$ = this.detailService.getQstnSetNoneCache(id);

     //call alter for utilizing cache -optimization
     this.questions$ = this.detailService.getQstnSetForHdrDtl(id);
     
     //.pipe(data=> {this.whole(data);return data});


      /*
      .subscribe(data => {
        //console.log(data)
        this.questions = data;
        this.whole(data);
        //console.log(this.detail)
        
      }, error => console.log(error));
      
      */
    }

    getNextQstnSet(id:number){
      this.questions$ = this.detailService.getNextQstnSet(id)
    }
    getPrvsQstnSet(id:number){
     this.questions$ = this.detailService.getPrvsQstnSet(id)
    }
        
whole(qs:any){
 
  if(qs.length>0){
 
 let wholeQ = Object.assign({},qs[0]);

 if(qs.length>1){
  for(let i=1; i<qs.length; i++){
  wholeQ.instruction += qs[i].instruction;
 //console.log(qs[i])
  wholeQ.question = wholeQ.question +" (" + qs[i].groupId +") " + qs[i].question;
    wholeQ.answer += qs[i].answer;
  }  
   }
this.wholeQ = wholeQ;
return wholeQ;
  }

  
  }  

}
