
 <table class="table table-striped">
    <thead>
      <tr class= "row">
        <th class= "col-1" *ngIf= '!tl.cs.isUnsigned()'>Option</th>
        <th  class= "col-1"># </th>
        <th class= "col-2">Title</th>
        <th class= "col-1">Added by</th>
        <th class= "col-1">Add Date</th>
        <!--
        <th class= "col-2">class</th>
        <th class= "col-2">section</th>
        <th class= "col-2">subject</th>
        
        <th class= "col-2">chapter</th>
        -->

        <div *ngIf="false" class= ".d-none">
        <th class= "col-2">section</th>
        
        <th class= "col-2">Booster Set</th>
        <th class= "col-2">status</th>
        <th class= "col-2">Active Flag</th>
        <th class= "col-2">Header Id</th>
      </div>
      </tr>
    </thead>
 
    <div *ngFor="let t of list1;let i = index">
        <tr class= "row">
  


   <td class= "col-1" *ngIf= '!tl.cs.isUnsigned()'>
    <button *ngIf='(!tl.selectedId ||( tl.selectedId != t.id))'  class= "mx-2 btn btn-primary  badge text-center"  (click)="tl.selectId(t)"> 
      Select </button> 
   
    <ng-container *ngIf='tl.selectedId && tl.selectedId == t.id' class= "btn-info badge ">
      Selected
    </ng-container>  

    <button *ngIf='!loadedFilter &&(!tl.selectedId ||( tl.selectedId != t.id))'  class= "mx-2 btn btn-primary  badge text-center"  (click)="loadFilterQstnSet(t.id)"> 
      load filter-qstns </button> 
    
      <button *ngIf='loadedFilter'  class= "mx-2 btn btn-primary  badge text-center"  (click)="cleanFilterQstnset()"> 
        clean filter-qstns </button> 
      
     </td>

    <td  class= "col-1">{{t.id}}    </td>
     <td class= "col-2">{{t.title}} </td>
     <td class= "col-1">{{t.owner}} </td>
     <td class= "col-1">{{t.addDate|date}} </td>
     <!--
     <td class= "col-2">{{t.course}}</td>
     <td class= "col-2">{{t.clsSec}}</td>
     <td class= "col-2">{{t.subject}}</td>
     <td class= "col-2">{{t.chapter}}</td>
     -->
     
     <div *ngIf="false" class= ".d-none">
     <td class= "col-2">{{t.section}}</td>
     <td class= "col-2">{{t.bstrSet}}</td>
     <td class= "col-2">{{t.status}}</td>
     <td class= "col-2">{{t.current}}</td>
     <td class= "col-2">{{t.headerId}}</td>
   </div>
   
   <td class= "col-6">

<!--
    
<app-view-selector *ngif= "false"  [hdrObj]= header > </app-view-selector>

-->

<ng-container >

  <ng-container *ngIf= '!tl.cs.isUnsigned()'>

<app-taghdr-crud class="mx-2" *ngIf="tl.enable('crudBtns',i,header)" [header] = t [i] = i > </app-taghdr-crud>




<a class="btn-md badge btn-outline-primary mr-1"
(click) = "tl.showChptr(t,'builder',true)">Show Selected </a>


<a class="btn-md badge btn-outline-primary mr-1"
(click) = 'tl.showChptr(t,"hwrk")'>worksheet</a>

<app-tag-optn *ngIf="tl.enable('tagDtl',i,header)"  [t] = t [i]= i> </app-tag-optn>

</ng-container>


<ng-container *ngIf= 'tl.cs.isUnsigned()'>
</ng-container>

  <a class="btn-md badge btn-outline-primary mr-1"
(click) = 'tl.showChptr(t,"hwrk")'>test</a>



</ng-container>

</td>

</tr>

 </div>
 </table>

<!--
 <app-chptr-src-slctr *ngIf="autoMaker"  [grpAsoId] = tl.grpAsoId [refHdr]=t >  </app-chptr-src-slctr>
 -->  
