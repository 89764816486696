  
  <!--
  <app-header></app-header>
  
  <app-menu> </app-menu>
-->


  <div class="card card-primary">
  
    <div class="card-heading">
       <br> 
      <h5>{{heading}} 
          
        <a class="btn btn-info mx-2 " [routerLink] = "['/',site,cat,'features','signup']" >Add </a>
        
        <a class="btn btn-info mx-2 " (click) = 'cs.navigate("mymkr","usr")'> batch Loader</a>


        <!--
          <button *ngIf= "!showAdd" (click)="addHeader()" class="btn btn-info" style="margin-left: 10px">Add <i class="fa fa-book" aria-hidden="true"></i> </button>
          
           <button *ngIf= "showAdd" (click)="showAdd = false" class="btn btn-info" style="margin-left: 10px">Hide Add</button>
          -->
        </h5>
       
     <!--   
      
      <app-show-header *ngIf="showHeader" [id]=id> </app-show-header>
      <app-update-header  *ngIf="showUpdate" [id]=id> </app-update-header>
    -->
    
      <!--
      <app-update-header  *ngIf="provideResponse" [responseMode]= true [id]=id> </app-update-header>
    
      <app-show-header  *ngIf="showHeader" [id]=id> </app-show-header>
  -->  
        
      </div>
  
      
  
      <div *ngIf= "headers" class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              
              <th>#</th>
              
              <th>domain</th>
              <th>user</th>
              <th>enabled</th>
              
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let header of headers">
             <!-- | async -->
    
              <td>{{header.id}}</td>
              
              <td>{{header.domain}}</td>
              <td>{{header.userId}}</td>
              <td>{{header.enabled}}</td>
              
               
              <td>
                
                <!--
          <button (click)="deleteHeader(header.id)" class="btn btn-danger">Delete</button>
               
          <button (click)="updateHeader(header.id)" class="btn btn-info" style="margin-left: 10px"  >Update</button>
               
         <button (click)="headerHeader(header.id)" class="btn btn-info" style="margin-left: 10px">Detail</button>

         -->

         <button (click)="changePwd(header)" class="btn btn-info" style="margin-left: 10px"  >Change Password</button>  
         <button (click)="changePwdEmail(header)" class="btn btn-info" style="margin-left: 10px"  >Send Pwd Change Email</button>
         <button (click)="changeUD(header)" class="btn btn-info" style="margin-left: 10px"  >Change usr-dtl</button>  
         
         <button (click)="disable(header)" class="btn btn-info" style="margin-left: 10px"  >Disable</button>  
         
         
         <button *ngIf="header.enabled==0" (click)="reEnable(header)" class="btn btn-info" style="margin-left: 10px"  >Re-enable</button>  

         <!--       
        <button *ngIf="!header.response && header.message" (click)="respond(header.id)" class="btn btn-info" style="margin-left: 10px" >Respond</button>
              
               
       <a *ngIf="cs.enable('updateQstn') && header.qstnDtlId "  href = '{{cs.getRoot()}}/faces/content-dtl-update.xhtml?qstnId={{header.qstnDtlId}}'
       class="nav-link text-warning px-3 " > <span class= "">update Question  </span>
     </a>
     -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
    </div>
