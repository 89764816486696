<div class="text-center" *ngIf="loading && !btnMapLoaded && !cs.loadedBtnMap">
  <img src="/assets/img/loader4_new.gif">
  Loading ...
</div>


<app-parm-loader> </app-parm-loader>


<div class="minHeight" *ngIf="btnMapLoaded">

  <div id="site">
    <app-menu> </app-menu>
  </div>
<div class="text-center newsroom-bg">
 <app-news-room> </app-news-room>
</div>
  <div class="py-3" *ngIf='enable("signup-site")'>
    <app-signuplogin> </app-signuplogin>
  </div>
  
  <ngb-toast *ngIf="message" header=" " (hide)="message = ''" delay="2000">
    {{ message }}
  </ngb-toast>
  <app-new-sub-mnu [hdr]=hdrObj> </app-new-sub-mnu>
  
  
  <!-- offering check is must for backward compatibility-->
  <!--
  <app-home
    *ngIf='cat && (cat.includes("home") || cat.includes("platform") ) && !offering'>
  </app-home>
-->


  <ng-container *ngIf="(!offering && !feature)">


    <app-featurelist
      *ngIf='(!cat || cat.startsWith("home") || cat.includes("platform")) else excel; '></app-featurelist>

    <ng-template #excel>

      <app-excel
        *ngIf='!enable("productView") && !(cat && (cat.startsWith("home") || cat.includes("platform")))'>
      </app-excel>

      <app-product *ngIf="enable('productView') "> </app-product>

      <app-cmnt-viewer *ngIf="cat && cat.startsWith('cmnt')"> </app-cmnt-viewer>

    </ng-template>

  </ng-container>



  <app-headings-offering
    *ngIf="offering  && !feature && !subject"></app-headings-offering>





  <!-- board changed to b1oard twice  to accomodate all records of news with board/course.. change it needed in future-->


  <div id="subjects" class="text-left" *ngIf="offering && !subject; ">

    <app-just-subject-list> Loading Subject List</app-just-subject-list>

  </div>




  <div id="chapters" *ngIf="subject && !(grpAsoId || chapter)">
    <app-chptr-view-selector> </app-chptr-view-selector>

  </div>


  <div id="contents" *ngIf='((chapter || grpAsoId) && !qstnId)'>
    <app-view-selector [hdrObj]=hdrObj> </app-view-selector>
  </div>


  <app-whole-qstn-viewer *ngIf="qstnId" [hdr]=hdrObj
    [detailId]='qstnId'></app-whole-qstn-viewer>





  <ng-container *ngIf='btnMapLoaded && ("affiliate")'>
    <app-affiliates *ngIf='enable("affiliate")'> </app-affiliates>

    <app-cmnt-rctn *ngIf="false && cat!= 'home'"> </app-cmnt-rctn>

    <app-signuplogin class="mt-4" *ngIf='enable("signupBottom-site")'>
    </app-signuplogin>

  </ng-container>



  <div *ngIf='enable("pageComments-site")' class='my-1 text-center'>

    <p class="lead, text-center mx-5 mt-1  font-italic "> We would appreciate
      your feedback:</p>

    <app-cmnt-list [featureType]=cat [cmntType]="'H'+cat"
      [parentHdrId]=chapter?chapter:0> </app-cmnt-list>

  </div>


  <app-display-ad id="adsfromSiteComponent"
    *ngIf='enable("pageAds-site")'></app-display-ad>


  </div>
  <!--https://javascript.plainenglish.io/5-steps-to-adding-a-sticky-footer-to-your-angular-11-application-6c185c2ed99f#:~:text=The%20next%20style%20property%20we,margin%2Dtop%3A%20%2D36px%20.-->

  <!-- needed below div for sticky down-->
  <app-footer [site]=site> </app-footer>