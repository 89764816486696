import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,Subject} from 'rxjs';
import { HttpHeaders,HttpResponse } from '@angular/common/http';
import { ClientInfoService } from '../shared/client-info.service';
import { Header } from './header';
import { SecurityService } from '../shared/security.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    //'Authorization': 'my-auth-token',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'HEAD,GET,POST,PUT,PATCH,DELETE',
    responseType: 'text',
  })
  };

  @Injectable({
    providedIn: 'root'
  })

  export class QstnhdrService {

    //Subject which will be pushed from child to advise parent to update List.
public static updateList: Subject<boolean> = new Subject();
public static updateDhwHdrId : Subject<number> = new Subject();


 
  get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get chapter() { return this.cs.getChapter()};
  get clsSec() { return this.cs.getClsSec()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get school() { return this.cs.getSchool()};
  get schlLoc() { return this.cs.getSchlLoc()};
  get signedUser() { return this.cs.getSignedUser()};

  
  get baseUrl() { if(this.cs.getOffering() && this.cs.getOffering() == "cfg") 
  { return this.cs.getRoot()+ "/rs/ch";}
  else {return this.cs.getRoot()+ "/rs/qh";} };


   constructor(private http: HttpClient,public cs:ClientInfoService,private ss:SecurityService) {
    //this.baseUrl= this.cs.getRoot()+ "/rs/qh";
    
   }



  get(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/header/${id}`);
  }

  create(header: Header): Observable<any> {
    console.log(header);
    return this.http.post(`${this.baseUrl}`, header);
  }

  populateDefault(header: Header){
   header = new Header();
   header.board= this.board;
   header.course= this.course;
   header.subject= this.subject;
   header.type= this.offering;
   if(this.chapter){header.headerId= Number(this.chapter);  }
   
   
   header.site= this.ss.getUsrSite();
   header.school =this.school; 
   header.location =this.schlLoc; 
   header.addUser = this.signedUser;
   header.owner = this.signedUser;
   header.clsSec = this.cs.getCrtClsSec(header.type);
   

   if(header.type && !header.status){
    var typeStatus = header.type.split('-'); 
      if(typeStatus.length==2){
      header.status= typeStatus[0].substr(1);
      } else{
        header.status= "raw"; 
         } 
   }
    
   return header;
  }

  update(id: number, value: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}`, value,httpOptions);
  }

  resetToRaw(id: number): Observable<Object> {
    return this.http.put(`${this.baseUrl}/resetToRaw/${id}`,null ,httpOptions);
  }

  delete(id: number): Observable<any> {
    //alert("I am called");
    return this.http.delete(`${this.baseUrl}/${id}`,httpOptions );
  }

  

  list(type: string): Observable<any> {
    
    if(this.cat== "istore" || this.cat == "siteowned"){
      return this.noneCOEList(type);
    }

    let overSite= this.cs.getSite();
   if(this.cat =="ebstore"){
    overSite= this.cs.getJustSite();  
     }

    return this.http.get(`${this.baseUrl}/list/${overSite}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getClsSec()}/${this.cs.getOffering()}/${this.cs.getSubject()}`);
  }

     noneCOEList(type: string): Observable<any> {
      let overSite= this.cs.getSite();
     
      return this.http.get(`${this.baseUrl}/noneCOEList/${overSite}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getClsSec()}/${this.cs.getOffering()}/${this.cs.getSubject()}`);
       }
       
   
    filteredList(owner:string,status: string): Observable<any> {
     if(this.cs.getCat()== "mymkr"){
         return this.filteredListByAddUser(owner,status);
      } else{
         return this.filteredListByOwner(owner,status);
       }
      }
//ideal for digitizer work flow
     //list based on workflow for actual Owner & Status only for dgtzr etc... ?
     filteredListByOwner(owner:string,status: string): Observable<any> {
      let subject = this.cs.getSubject()?this.cs.getSubject():'Subject'
      return this.http.get(`${this.cs.getRoot()}/rs/s/stts/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getDfltClsSec(this.cs.getOffering())}/${this.cs.getOffering()}/${subject}/${owner}/${status}`);
       }
  //ideal for mymkr
    filteredListByAddUser(owner:string,status: string): Observable<any> {
      let subject = this.cs.getSubject()?this.cs.getSubject():'Subject'
        return this.http.get(`${this.cs.getRoot()}/rs/list/sttsByAddUser/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getDfltClsSec(this.cs.getOffering())}/${this.cs.getOffering()}/${subject}/${owner}/${status}`);
    }
    








//localhost/rs/th/changeStatus/111358/tasgn_dhw/asgn
changeStatus(id: string,tagType:string, status:string): Observable<Object> {
  return this.http.put(`${this.baseUrl}/changeStatus/${id}/${tagType}/${status}`,' ' ,httpOptions);
}



}