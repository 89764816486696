    
    
import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../client-info.service';
import { HeaderService } from '../../tag/header.service';
import { FormsModule } from '@angular/forms';

import { FixedDataService } from '../fixed-data.service';
import { Header } from '../../tag/header';
import { ExtraInfo } from '../assigner/extra-info';
import { PrepAsgnrService } from '../assigner/prep-asgnr.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  @Input() hdr:Header;
  @Input() status : string;
  
  ei:ExtraInfo = new ExtraInfo(); 
  
  success: boolean;
  
  constructor(public pa:PrepAsgnrService,private cs: ClientInfoService) { }

  ngOnInit(): void {
   
    if(this.hdr){
  this.ei= this.pa.prePrepEI(this.hdr,'shr');
    }

 // console.log(this.ei);
  }

  
  submit() {
   let data= this.pa.postEI(this.hdr,this.ei).subscribe(data => {
    if ((data as any).status === 200) {
      this.cs.addMsg("successful", 'info');
     // console.log(data);
      this.success= true;
     
      /* original status changer
      this.pa.hs.changeOHStatus(this.hdr.id,this.hdr.type,'shr').subscribe(data => {
              if ((data as any).status === 200) {
              }}); 
      */

      return data;
      

      } else {
      this.cs.addMsg("error,try later", 'error');
    }
  }
  
  );
   //alert("got response"+ data);
      }
    

}

