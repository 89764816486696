import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {
navbarOpen = false;
board= 'ICSE';
site:string;
siteHead: string= ""


  constructor(private route: ActivatedRoute,private router: Router) {
/* not working -----> true*/
 this.route.params.subscribe( params => {
//alert(params); 
//console.log(params);
//this.site= params['site'];
//alert(this.site); 

/*
this.board= params['board']; 
this.course= params['course'];
this.offering= params['offering'];
this.subject= params['subject'];
this.chapter= params['chapter'];
this.content= params['content']
*/
});
//this.siteHead= "Exam Steppers";  

if(this.site){
if(this.site == 'esa' || this.site == 'es'){
this.siteHead= "Exam Steppers";  
}
if(this.site == 'csa'){
this.siteHead= "Career Steppers";  
}

if(this.site == 'eba'){
this.siteHead= "Edu Bestu";  
}
/* not working*/
}
  }

  ngOnInit() {
  }

changeBoard(){
  alert('Coming soon...');
  
}

toggleNavbar() {
 //alert("Inside toggle : "+ this.navbarOpen);
    this.navbarOpen = !this.navbarOpen;
  }
}
