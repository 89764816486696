import { Component, OnInit,Input,OnDestroy } from '@angular/core';
import { QuizService } from '../../shared/quiz.service';
import { NgModule, ElementRef, Injectable } from "@angular/core";
import { BrowserModule, Title,Meta } from "@angular/platform-browser";
import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { StyleService } from '../../shared/style.service';



declare var MathJax:any;

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit, OnDestroy {
  @Input()  questions : Object;
  @Input()  headerById : any;
  headerId:string;
qstnCount: number;

site:string;
cat:string;
board:string;
course:string;
offering: string;
subject:string;
chapter:string;
content:string;
  constructor(private router: Router, public quizService: QuizService ,private route: ActivatedRoute,
     private elementRef:ElementRef, private styleService: StyleService,  private titleService: Title, private cs:ClientInfoService, private fd:FixedDataService,
    private metaTagService: Meta) {

//styleService.addStyle('quizStyle', require('./materialize.min.css') );


//the below two lines need to be fixed later
    //localStorage.clear();
    localStorage.removeItem('seconds');
    clearInterval(this.quizService.timer);
   }

fetchData(): void{
  /*
  this.quizService.getQuestions(this.headerId).subscribe(
    (data: any) => {
  alert("Inside data Service and retrieved " + this.quizService.qns.length);
      this.quizService.qns = data;
      this.qstnCount=this.quizService.qns.length;
  }
);
*/

}

  ngOnInit() {

    this.route.params.subscribe( params => {
   //alert(params);
   //console.log(params);
   this.site= params['site'];
   this.cat= params['cat'];
   this.board= params['board'];
   this.course= params['course'];
   this.offering= params['offering'];
   this.subject= params['subject'];
   this.chapter= params['chapter'];
   this.content= params['content']
   });
  // alert(this.chapter);
      this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.fetchData();
    });
    if (parseInt(localStorage.getItem('seconds')) > 0) {
      this.quizService.seconds = parseInt(localStorage.getItem('seconds'));
      this.quizService.qnProgress = parseInt(localStorage.getItem('qnProgress'));
      this.quizService.qns = JSON.parse(localStorage.getItem('qns'));
      if (this.quizService.qnProgress == this.qstnCount)
        this.router.navigate(['/result']);
      else
        this.startTimer();
    }
    else {
      this.quizService.seconds = 0;
      this.quizService.qnProgress = 0;
      //alert("data size");
      //this.headerId= (this.headerById.dataList[0]).id;

      if(this.chapter)
      {
        this.headerId= this.chapter;
      //alert("data Header Id: " + this.headerId);
      this.quizService.getQuestions(this.headerId).subscribe(
        (data: any) => {
          this.quizService.qns = data;
          this.qstnCount=this.quizService.qns.length;
          //alert(this.qstnCount);
          this.startTimer();
        }
      );
}
}

    }


    ngOnDestroy(): void {
      //alert("Style Service called");
        this.styleService.removeStyle('loginStyle');
      }

  startTimer() {
    this.quizService.timer = setInterval(() => {
      this.quizService.seconds++;
      localStorage.setItem('seconds', this.quizService.seconds.toString());
    }, 1000);
  }

  Answer(qID, choice,qstnId) {
    this.quizService.qns[this.quizService.qnProgress].answer = choice;
    localStorage.setItem('qns', JSON.stringify(this.quizService.qns));
    this.quizService.qnProgress++;
    localStorage.setItem('qnProgress', this.quizService.qnProgress.toString());
    this.quizService.submitAnswer("answer",choice,qstnId,0).subscribe(() => {
      //console.log("answer submitted: " , qstnId +" / " + choice);
    });

    if (this.quizService.qnProgress == this.qstnCount) {
      clearInterval(this.quizService.timer);
//submit first digital result automatically
    this.quizService.submitScore().subscribe(() => {
        //this.restart();
        //this.router.navigate(['/dash']);
        console.log("result submitted");
      });

      this.router.navigate(['/result']);
      //this.router.navigate(this.getRouteUrl("features/result","te"));
    }
  }

  getRouteUrl(category:string, extLink: string): string{
  return this.cs.getRouteUrl(category, extLink);
  }

  ngAfterViewChecked(){
   //alert("Hello World");
   //MathJax.Hub.Queue(["Typeset", MathJax.Hub, "qstnSet"]);
   //alert("View typeset Retried- after view checked..! Thanks ");
this.cs.typeSet();
 }

 typeSet(){
 //alert("Hello World");
 this.cs.typeSet();
 //MathJax.Hub.Queue(["Typeset", MathJax.Hub, "qstnSet"]);
// alert("View typeset Retried! Thanks from tyepset ");

}

}
