import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CommentService } from '../comment.service';
import { Comment } from "../comment";
import { CmntListService } from './cmnt-list.service';

@Component({
  selector: 'app-cmnt-list',
  templateUrl: './cmnt-list.component.html',
  styleUrls: ['./cmnt-list.component.scss']
})
export class CmntListComponent implements OnInit {
  @Input() cmntType: string;
  @Input() hideTitle: boolean;
  @Input() featureType: string;
  @Input() parentHdrId: number;
  @Input() parentDtlId: number;
  @Input() hdrObj?: any;
  @Input() mode: string; //pass slides,list
  @Input() ovrSite: string;

  cList$: Observable<any>;
  calling: boolean;

  get cat() { return this.cms.cs.getCat() }

  constructor(private cms: CommentService, public ls: CmntListService) { }

  ngOnInit(): void {

    this.mode = this.mode ? this.mode : "slides";

    if (this.cmntType) {
      this.calling = true;
      this.fetchData();
      //this.cList$ = this.cms.getCommentsByTypeFtrHdrId(this.cmntType,this.featureType,this.parentHdrId);
    }
    else {
      console.log("not retrieving cmnts list");
    }
  }

  ngOnChanges() {

    this.fetchData();
  }


  fetchData() {
    //!this.parentHdrId

    //user ovrSite parm for main-list for now using cmnt-list directly..
    if (this.ovrSite) {
      if (!this.parentHdrId) {
        this.cList$ = this.cms.getCommentsByType(this.ovrSite, this.cmntType);
      } else {
        this.cList$ = this.cms.getCommentsByTypeHdr(this.ovrSite, this.cmntType,this.parentHdrId);
      }
    }
    
    else {
      //rs/cmnt/typeFtrHdrid/esadigi-best/Hhome/home/0
      if (this.cmntType.startsWith('H')) {
        this.cList$ = this.cms.getCommentsByTypeFtrHdrId(this.cmntType, this.featureType, this.parentHdrId);
      } else {
        if (this.cmntType.startsWith('D')) {
          this.cList$ = this.cms.getCommentsByTypeFtrDtlId(this.cmntType, this.featureType, this.parentHdrId, this.parentDtlId);
        }
        if (this.cmntType === "all") {
          this.cList$ = this.cms.getCommentsList();
        }

      }
    }
  }

  prepNewCmnt(cmntType: string) {
    let c = new Comment();
    c.cmntType = cmntType;
    c.featureType = this.featureType;
    c.parentHdrId = this.parentHdrId;
    c.parentDtlId = this.parentDtlId;
    c.domain = this.ls.cs.getSite();

    // fix it later .. we also need for rfc 
    if (true || this.featureType == 'rvsn-spdr') {
      c.subject = this.ls.cs.getSubject();
      c.clsSec = this.ls.cs.getClsSec();
      c.course = this.ls.cs.getCourse();
      // console.log(c); 
    }
    return c;
  }
}
