
User List:

<div  *ngIf = "(ul$ | async) as ul">
<table datatable [dtOptions]="dtOptions" class="row-border hover">
    <thead>
      <tr>
     <th>userId</th>
    <th>First Name</th>
    <th>Last Name</th>
    <th>Result</th>
    <th>Mistakes</th>
  
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let u of ul">
        <td>{{ u.userId}}</td>
        <td>{{ u.firstName}}</td>
        <td>{{ u.lastName}}</td>
        <td><button (click)= 'showResult(u)'> Result </button></td>
        <td><button (click)= 'showMistake(u)'> Mistake Types </button></td>
        <td><button (click)= 'showCIBooster(u)'> Personalized Booster</button></td>
      </tr>
    </tbody>
  </table>
  
  </div>
  
  