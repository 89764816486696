
<main role="main">

  <div *ngIf="calling; else noCall">

    <div *ngIf="(data$ | async) as d; else loading">


      <section *ngIf="d &&d.hdr" class="jumbotron text-center">
    <app-seo   [h] = 'd.hdr' [q] = 'd.cgDesc'> </app-seo> 
   
        <div  *ngIf="!hideTitle" class="container">
          <h2 class= "pen40" innerHTML={{hdng.transform(d.hdr.title)}}> </h2>
          <div class="" innerHTML={{hdng.transform(d.hdr.summary)}}> </div>
          <p>
            <!--
            {{d.hdr.id}}
            {{d|json}}
            -->
            <button *ngIf= "d.cfgDesc && d.cfgDesc.title" class="btn badge btn-primary mx-2" (click) = cs.navigate(d.cfgDesc.category,d.cfgDesc.extLink,d.cfgDesc.format)>{{d.cfgDesc.title}}</button>
            <!--
            <button *ngIf= "d.cfgDesc && d.cfgDesc.title" class="btn badge btn-primary mx-2" (click) = 'cs.navigate("fresh","features/thought")' > Daily Fresh</button>
            -->
             
            <!--
            <a *ngIf= "d.cfgDesc && d.cfgDesc.title" routerLink="/{{site}}/{{d.cfgDesc.category}}/{{d.cfgDesc.extLink}}/{{board}}/{{course}}"
            class="btn btn-primary mx-2">{{d.cfgDesc.title}}</a>
            -->
          <!--  don't show for product detail?
<app-cube3d *ngIf= 'cs.enable("prd-dtl","cube3d",s,"")'> </app-cube3d>
-->
             </p>
        </div>
      </section>

      <ng-container *ngIf= "showSiteInfo">
        <app-prd-intro  [ovrSub] = '"platform~slides"'> </app-prd-intro>
        <app-usp [ovrSub] = '"platform~usp"' [forPdf] = true> </app-usp>
      </ng-container>

      <div *ngIf= "d.cfgDesc && d.cfgDesc.instruction" class="text-left font-weight-bold" [innerHTML]=d.cfgDesc.instruction > </div>
      
      <div [ngClass]="{'py-3' : forPdf,'py-5' : !forPdf}"  class="album  bg-light">
      
        <div class="container">
          <div *ngIf= "d.cfgDesc && d.cfgDesc.question" class="font-weight-bold mt-0 mb-1" [innerHTML]=d.cfgDesc.question> </div>
          <ng-container *ngIf="d.dtls && d.dtls.length; else noItems">
            <div class="row">
             
              <!-- <ng-container *ngFor="let s of stripFtrDesc(d.dtls); let i= index;" *ngIf= "cs.enable('prd-dtl',s.groupId,s,'')" >-->
              
              <ng-container *ngFor="let s of d.dtls; let i= index;">
                
                
                <!-- 
                <app-usp-detail class="col-md-6" [m]=s [i]= i ></app-usp-detail>
              -->
             
            <div *ngIf= 'show(s)' class="col-md-6 px-3">
            <app-product-dtl [h]= 'd.hdr' [s]=s [menuType] = 'cs.enable("product", "enableMenu-site")' > </app-product-dtl>
            </div>


          </ng-container>
              
              </div>

            <div *ngIf= "d.cfgDesc && d.cfgDesc.answer" class="text-center font-weight-bold" [innerHTML]= d.cfgDesc.answer> </div>  

          </ng-container>


          <ng-template #noItems>*</ng-template>
        </div>
        <ng-template #loading>loading slides...</ng-template>
      </div>
     
<footer  *ngIf= "d.cfgDesc"  class="text-muted">
  <div class="container">
    <div *ngIf= "d.cfgDesc.digInstrctn" class= "bg-violet font-italic " innerHTML= {{d.cfgDesc.digInstrctn}}></div>
    
    <span *ngIf= "d.cfgDesc.digQstn" class= "bg-violet font-weight-bold font-italic " innerHTML= {{d.cfgDesc.digQstn}}></span>
    <span class="float-right">
      <a *ngIf= "d.cfgDesc.digAnsr" routerLink="/{{site}}/{{d.cfgDesc.digOptns}}"
      class="btn btn-primary mx-2"> <span [innerHTML]= 'd.cfgDesc.digAnsr'></span>  </a>
     </span>

     <!--
    <app-topic-list [q] = 'd.cfgDesc'> </app-topic-list>
-->

    </div>
 </footer>


</div>

</div>

<ng-template #noCall>!</ng-template>

</main>

