


<!--class="card img-fluid"-->
<div [ngSwitch]="cat" class= "container">

  



<ng-container *ngIf= "chapter">

 

<app-qstndtl-list *ngSwitchCase= "'massupd'" [qstnHdrId]= chapter > </app-qstndtl-list>
<app-qstndtl-list *ngSwitchCase= "'list-crud'" [qstnHdrId]= chapter > </app-qstndtl-list>
<app-add-qstn-dtl *ngSwitchCase= "'add-qd'" > </app-add-qstn-dtl>
<app-ngs-monaco *ngSwitchCase= "'editor'" [hdr] = hdrObj  > </app-ngs-monaco>


</ng-container>

<!--
<div *ngSwitchCase= "'vid-bldr'"> </div>
<div *ngSwitchCase= "'doc-bldr'"> </div>


<app-pdfviewer    src= '{{link}}' [startPage] = startPage > </app-pdfviewer>
{{hdrObj|json}}
for future--- > even below

<app-hdr-view-slctr *ngIf='hdrObj' [hdr] = hdrObj > </app-hdr-view-slctr>
-->

<app-url-shower *ngSwitchCase= "'p1df'"  class= "px-1"
      [format]=hdrObj.hdrFormat [extLink]=hdrObj.extUrl
      [title]=hdrObj.title [headerObj]=hdrObj> </app-url-shower>

<!--   
<app-my-subject  [hdr] = 'hdrObj' >  </app-my-subject>
-->

<!--
<app-my-subject  *ngSwitchCase= "'vid-bldr'" >  </app-my-subject>
<app-my-subject  *ngSwitchCase= "'doc-bldr'" >  </app-my-subject>
-->

<!--
<ng-container *ngSwitchCase= "'mtg'"> 

<app-headings-plain *ngIf= 'hdrObj' [hdr]= 'hdrObj' > </app-headings-plain>

<app-cmnt-list [cmntType]= '"Hmtg"' [featureType] = "'mtg'"  [parentHdrId] = chapter  [hideTitle] = true  [mode] = "'list'" > </app-cmnt-list>

</ng-container>
-->


<ng-container *ngSwitchDefault> 

  
  <ng-container *ngIf= 'chapter ' > 

        
    <app-qstn-list  *ngIf= 'tagBased'  > </app-qstn-list>
    

     <ng-container *ngIf= '!tagBased'>

   <div *ngIf='hdrObj &&hdrObj.status && ["wip","ready"].includes(hdrObj.status)'  >

  
<app-hdr-view-slctr *ngIf='hdrObj'  [hdr] = hdrObj > </app-hdr-view-slctr>
    
  <app-dgtzr-btn class="ml-2" [header]=hdrObj></app-dgtzr-btn> 
  

   <app-url-shower class= "px-1"
      [format]=hdrObj.hdrFormat [extLink]=hdrObj.extUrl
      [title]=hdrObj.title [headerObj]=hdrObj> </app-url-shower>
  </div>
  

  <div *ngIf='!hdrObj ||!hdrObj.status ||!["wip","ready"].includes(hdrObj.status)'>
  <app-url-shower *ngIf= 'hdrObj && hdrObj.hdrFormat && hdrObj.hdrFormat=="pdf" '  class= "px-1"
      [format]=hdrObj.hdrFormat [extLink]=hdrObj.extUrl
      [title]=hdrObj.title [headerObj]=hdrObj> </app-url-shower>

      <app-qstn-list  *ngIf= ' !hdrObj || !hdrObj.hdrFormat || hdrObj.hdrFormat !="pdf" '  > </app-qstn-list>
    </div>
  </ng-container>


</ng-container>

 <div *ngIf= "grpAsoId">  

<app-group-addon  [hdr] = hdrObj>  </app-group-addon>

<!--
<app-aso-hdr-lstr *ngIf="!autoMaker" [grpAsoId]= grpAsoId > Associated Records...</app-aso-hdr-lstr>
-->

 

<!--
<app-group-addon  [hdr] = hdrObj>  </app-group-addon>
 
    <h4> Select source:  </h4 >
   <app-associated-rec  *ngIf="!autoMaker" [grpAsoId]= grpAsoId  [asoHdr] = hdrObj> Associated Records...</app-associated-rec>

   <button (click) = "autoMaker = !autoMaker">auto-maker  </button>
  
  -->
  <app-chptr-src-slctr *ngIf="autoMaker"  [grpAsoId] = grpAsoId [refHdr] = hdrObj >  </app-chptr-src-slctr>
   </div>
   
   

   
</ng-container>


</div>



<!--
<app-qstn-list *ngIf= '!enable("test")'> </app-qstn-list>


<app-qstn-list *ngIf= 'enable("test") && offering == "twrk-tbstr"'> </app-qstn-list>

<app-take-test *ngIf= 'enable("test") && offering != "twrk-tbstr"'> </app-take-test>

-->