
import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HeaderService } from '../../tag/header.service';
import { FormBuilder } from '@angular/forms';
import { Header } from '../../tag/header';
import { FileService } from '../file.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { CommentService } from '../../comments/comment.service';

import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() iHeader: any;
  @Input() iComment: any;
  @Input() hdrType: any;
  @Input() iDetail: any;
  @Input() uploadType: string

  @Output() uploadedObj = new EventEmitter<any>();

  headerId: string;
  extLink: string;
  uploaded: any;
  uploadedPath: Object;

  get chapter() { return this.cs.getChapter() };

  myForm = new FormGroup({
    name: new FormControl('', []),
    file: new FormControl('', [Validators.required]),
    headerId: new FormControl('', []),
    fileSource: new FormControl('', []),
    uploadFldr: new FormControl('', [])
  });

  constructor(private headerService: HeaderService, private http: HttpClient, private cs: ClientInfoService) { }

  ngOnInit() {

    // this.createHeader();
    if (this.iHeader) {
      this.headerId = String(this.iHeader.headerId);
    }
    else {
      this.iHeader = new Header();
      this.headerId = this.chapter;
    }

    console.log(this.headerId);
  }

  showHeaderId() {
    // enable when we want to add file upload alone??
    if (this.iComment || this.iHeader) {
      return false;
    }
    return true;
  }
  uploadFile() {
    const formData = new FormData();
    formData.append('file', this.myForm.get('fileSource').value);

    if (this.myForm.get('uploadFldr').value) {

      formData.append('uploadFldr', this.myForm.get('uploadFldr').value);

    } else {
      if (this.iHeader) {
        formData.append('uploadFldr', this.dfltUpldFldr(this.iHeader));
      }

    }


    if (this.iDetail) {
      formData.append('iDetailStr', JSON.stringify(this.iDetail));
    }


    if (this.myForm.get('headerId').value) {
      // alert("Uh form has headerId??: " + this.myForm.get('headerId').value);
      formData.append('headerId', this.myForm.get('headerId').value);
    } else {
      formData.append('headerId', this.headerId);
    }



    formData.append('fileTitle', this.myForm.get('name').value);


    //not in use
    let tagHeader = this.iHeader;
    if (!tagHeader.type) {
      tagHeader.type = "doc";
    }
    //JSON strigify has 2nd parm- replacer and third space/no of empty spot for white space between fields.
    //const blob = new Blob([JSON.stringify(this.iHeader, null, 2)], {type : 'application/json'});
    if (!this.iComment) {
      formData.append('tagHeaderStr', JSON.stringify(this.iHeader));
      const value = new Blob([JSON.stringify(this.iHeader)], { type: 'application/json' });// or just JSON.stringify(value)
      console.log(this.hdrType);
      if (this.hdrType === 'qstn') {
        // formData.append('QstnHdrBlob', value);
      } else {

        // formData.append('tagHeaderBlob', value);
      }


    }
    if (this.iComment) {
      formData.append('commentStr', JSON.stringify(this.iComment));
      const commentBlob = new Blob([JSON.stringify(this.iComment)], { type: 'application/json' });
      formData.append('commentBlob', commentBlob);
    }

    // console.log(this.iHeader);
    //console.log(formData);
    this.headerService.uploadFile(formData)
      .subscribe(data => {

        this.addNewOutput(data);
        //let below be there for other generic subscriber
        this.uploaded = (data as any);
        //let ext= this.uploaded.replace("extlink:", "");
        //let ext= JSON.parse(this.uploaded);
        console.log(data);
        this.uploadedPath = data;
        FileService.updateUploadUrl.next(this.uploaded);
        //this.cs.addMsg("upload Successful.,please review image and submit ansr to attach ",'');
        //this.cs.addMsg("upload Successful",'');
      }
        , error => console.log(error));
  }

  dfltUpldFldr(h: any): string {
    //console.log("upload header", h);
   
    // the h is coming as prototype only!! hence the issue coming below... fix in free time
    if(h){
    let uf = h.type + "-";
    if (h.subject && h.subject.length > 9) { uf = uf + h.subject.substr(0, 10) } else { uf = uf + h.subject };
    if (h.book && h.book != undefined) {
      if (h.book && h.book.length > 2) { uf = uf + "-" + h.book.substr(0, 3) } else { uf = uf + "-" + h.book };
    }
    if (h.branch && h.branch != undefined) {
      if (h.branch && h.branch.length > 1) { uf = uf + "-" + h.branch.substr(0, 2) } else { uf = uf + "-" + h.branch };
    }
    //console.log("default folder is",uf)
    return uf.toLowerCase().replace("undefined-undefined","");
      
  } 
  else return "";

  }

  addNewOutput(value: any) {
    this.uploadedObj.emit(value);
  }

  get f() {
    return this.myForm.controls;
  }

  onFileChange(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
      //FileService.updateUploadUrl.next(file.name);

      console.log(file.name)

    }
  }

  //not in use-just for reference
  submit() {
    const formData = new FormData();
    formData.append('file', this.myForm.get('fileSource').value);
    this.http.post('http://localhost/rs/files/upload', formData)
      .subscribe(res => {
        // console.log(res);
        CommentService.updateList.next(true);
        alert('Uploaded Successfully.');
      })
  }
}