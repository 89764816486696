import { Component, Input, OnInit } from '@angular/core';
import { Header } from '../../qstn/header';
import { QstnhdrService } from '../../qstn/qstnhdr.service';

@Component({
  selector: 'app-aso-hdr-crtr',
  templateUrl: './aso-hdr-crtr.component.html',
  styleUrls: ['./aso-hdr-crtr.component.scss']
})
export class AsoHdrCrtrComponent implements OnInit {
@Input() asoHdr:any;
  header: any;
  constructor(private qhs:QstnhdrService) { }

  ngOnInit(): void {
    if(this.asoHdr){
    this.header = new Header();
    this.header= this.qhs.populateDefault(this.header);
    this.header.type= this.qhs.cat.replace("-bldr","").replace("my","");
    this.header.status = "wip";

    this.header.title= this.asoHdr.title;
    this.header.headerId= this.asoHdr.headerid?this.asoHdr.headerid:this.asoHdr.id;
    }  
  }

}
