import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ClientInfoService } from './client-info.service';
import { Header } from '../qstn/header';
@Injectable({
  providedIn: 'root'
})
export class FileService {
  public static updateUploadUrl: Subject<string> = new Subject<string>();
  public static updateCreateUrl: Subject<string> = new Subject<string>();
  uploadUrl: string;
  
  constructor(private http: HttpClient, private cs: 
  ClientInfoService) {
    this.uploadUrl = this.cs.getRoot() + "/rs/files";
    }

  createFile(fileName: any,header?:Header,dryRun?:boolean): Observable<Object> {
    return this.http.post(`${this.uploadUrl}/createNew/${fileName}/${dryRun}`, header);

  }

  getFolderName(fileName: any,header?:Header): Observable<Object> {
    return this.http.post(`${this.uploadUrl}/createfolderName/${fileName}`, header);

  }

}
