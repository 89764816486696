import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Header } from '../../tag/header';
import { HeaderService } from '../../tag/header.service';
import { PrepAsgnrService } from '../assigner/prep-asgnr.service';
import { ClientInfoService } from '../client-info.service';
import { Status } from '../Status';
import { StatusService } from './status.service';
import { QstnhdrService } from '../../qstn/qstnhdr.service';
import { BtnEnablerService } from '../btn-enabler.service';

@Component({
  selector: 'app-status-changer',
  templateUrl: './status-changer.component.html',
  styleUrls: ['./status-changer.component.scss']
})
export class StatusChangerComponent implements OnInit, OnChanges {
  @Input() hdr:any;
  @Input() i: any;
  statusList: Status[] = new Array();
  showStatusBtn: boolean;
  get offering() { return this.cs.getOffering() }
  get chapter() { return this.cs.getChapter() }
 // get status() { return this.cs.getStatus() }
 
  status: string;
  message: string;

  constructor(private qhs: QstnhdrService,private hs: HeaderService, private cs: ClientInfoService,private ss:StatusService, public pa: PrepAsgnrService, private be:BtnEnablerService) { }

  ngOnInit(): void {
    
   // this.status = this.hdr.status;
   // alert("staus changer called")
    //initial statse??
    //this.status1= "1";
    //this.status= "assign"
   //this.status = this.hdr.status;
   // this.statusList = this.ss.loadStatus(this.status);
    //console.log(this.statusList);

  }

  ngOnChanges() {
    //alert("staus changer called")
    if(this.hdr){
      if(this.hdr.status){ 
    this.status = this.hdr.status;
      }
      
    this.statusList = this.ss.loadStatus(this.status);
  }}
  
enableAvl(btnId:string){
  //console.log(this.hdr.status + btnId);
 return this.be.enable('qstnList',btnId,this.hdr,false)  
 }

 
enable(btnId:string){
 let rtn= this.statusList && this.statusList.some(e => e.code === btnId);
//console.log(btnId + ": "+ rtn);

if(btnId == "storeNAsgn"){
  return rtn && this.be.enable('status-changer',btnId,this.hdr,false);
} else{
  return rtn && this.be.enable('status-changer',btnId,this.hdr,false);
}

} 

//throw this out
 enableOld(status: string) {
if(this.hdr && !this.hdr.status){
if(status=='shr'||status=='avl'|| status=='asgn'){
  return true;
} 
console.log('calling' + status );
//console.log(this.hdr);
return this.cs.enable('status-changer', status,'',this.hdr);

}

//console.log(this.statusList)

 let rtn= this.hdr && this.statusList.some(e => e.code === status);
 
 if(rtn){  
 if(status=='shr'){
     if ( this.hdr.type.includes('hwh') || this.hdr.type.includes('pyq') || this.hdr.type.includes('rbs') || this.hdr.type.includes('vid') || this.hdr.type.includes('doc')  &&  ['raw','avl','final'].includes(this.hdr.status)) 
      {return true} else 
     {return false} 
   } 

   if(status=='asgn'){

    if ( this.hdr.type.includes('hwh') || this.hdr.type.includes('pyq') || this.hdr.type.includes('rbs') || this.hdr.type.includes('vid') || this.hdr.type.includes('doc')  &&  ['raw','avl','final'].includes(this.hdr.status)) 
    {return false} else 
    {return true} 
  }
 }
    return  rtn;

  }



  createNBrowse(hdr: Header, i: number, status: string, overCat: string) {
    //console.log(hdr);
    this.pa.autoPost(hdr, status).subscribe(data => {
      if ((data as any).status === 200) {
        console.log(data);
        console.log("Success:posting done");
        this.cs.addMsg("Made Available");
        //this.router.navigate([this.getChapterUrl('', (data as any).result),testMode]);
        //this.showChptr((data as any).result, overCat)
      }
      else {
        alert("error,try later");
      }
    });

  }


  
submit(status) {
//alert("status-changer submit ..."+ status);
let hdrId = 0;
if(this.hdr){
  hdrId = this.hdr.id;
} else{
  hdrId= +this.cs.getChapter();
}

if(status== 'raw'){
  this.qhs.resetToRaw(hdrId).subscribe(data => {
    if ( (data as any).status === 200 ) {
      this.message = "successful" 
    }});  
  
  } else{
//header service handles both qstn and tag

let offering = this.hdr.type.replace(this.hdr.status, status);
this.hs.changeStatus(hdrId,offering,status).subscribe(data => {
      if ((data as any).status === 200) {
        this.message = "successful"
        //this.cs.addMsg(this.message, 'info');
         //alert(status);

 /* original status changer */
     if(status=="avl" && offering=="doc")   
      this.hs.changeOHStatus(this.hdr.id,offering,status).subscribe(data => {
              if ((data as any).status === 200) {
      this.cs.addMsg(this.message, 'info');         
      }}); 
      


      } else {
         
        this.cs.addMsg('error,try later', 'error');
      }
    }
    );

  }
}

}
