import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import * as Rx from "rxjs/Rx";
import { from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpHlprService } from '../shared/http-hlpr.service';
@Injectable({
  providedIn: 'root'
})
export class ListerService {
  
  constructor(private h:HttpHlprService) {}
  

  getKeyFlds(key: string,type:string) {
   
    return this.h.c.get(`${this.h.ba}/setup/${key}/${type}`).
        pipe(
           map((data: any[]) => {
             return data;
           }), catchError( error => {
             return throwError( 'Something went wrong!' );
           })
        )
    }

  /*  
  getHdrFlds(type:string) {
   
    return this.h.c.get(`/rs/setup/hdrFlds/${type}`).
        pipe(
           map((data: any[]) => {
             return data;
           }), catchError( error => {
             return throwError( 'Something went wrong!' );
           })
        )
    }

    getDtlFlds(type:string) {
   
      return this.h.c.get(`/rs/setup/dtlFlds/${type}`).
          pipe(
             map((data: any[]) => {
               return data;
             }), catchError( error => {
               return throwError( 'Something went wrong!' );
             })
          )
      }

      getCmntFlds(type:string) {
   
        return this.h.c.get(`/rs/setup/cmntFlds/${type}`).
            pipe(
               map((data: any[]) => {
                 return data;
               }), catchError( error => {
                 return throwError( 'Something went wrong!' );
               })
            )
        }
      */
  getExamList(user?:string) {
    //  user= user?this.h.us:"owner";

    return this.h.c.get(`${this.h.ba}/courseList/${this.h.si}/${user}`).
        pipe(
           map((data: any[]) => {
             return data;
           }), catchError( error => {
             return throwError( 'Something went wrong!' );
           })
        )
    }
    
  
  getSubjectList(type?:string) {
   
    if(!type){type= this.h.ty}

    return this.h.c.get(`${this.h.ba}/list/subject/${this.h.si}/${this.h.bo}/${this.h.co}/${type}`).
        pipe(
           map((data: any[]) => {
             return data;
           }), catchError( error => {
             return throwError( 'Something went wrong!' );
           })
        )
    }

    
   
    getBookList(subject:string, type?:string) {
  
      if(!type){type= "type"}
      return this.h.c.get(`${this.h.ba}/list/book/${this.h.si}/${this.h.bo}/${this.h.co}/${type}/${subject}`).
          pipe(
             map((data: any[]) => {
               return data;
             }), catchError( error => {
               return throwError( 'Something went wrong!' );
             })
          )
      }
      
    
    getOnlySubjectList(type?:string) {
  
      if(!type){type= this.h.ty}
      return this.h.c.get(`${this.h.ba}/list/onlySubject/${this.h.si}/${this.h.bo}/${this.h.co}/${type}`).
          pipe(
             map((data: any[]) => {
               return data;
             }), catchError( error => {
               return throwError( 'Something went wrong!' );
             })
          )
      }

      
    getClsSecInfo(clsSec?:string) {
        if(!clsSec){clsSec= this.h.clse}
      return this.h.c.get(`${this.h.ba}/courseList/clsSecInfo/${this.h.si}/${this.h.bo}/${this.h.co}/${clsSec}`).
          pipe(
             map((data: any[]) => {
               return data;
             }), catchError( error => {
               return throwError( 'Something went wrong!' );
             })
          )
      }

  getTypeList(selSub?:string) {
   // return this.h.c.get(`http://localhost/rs/list/type/esa/CBSE/X`).
  // console.log("hi "+ selSub);
  // let sub= selSub?selSub:"subject";
  
let url = `${this.h.ba}/list/type/${this.h.si}/${this.h.bo}/${this.h.co}`;

if(selSub){
  url =  `${url}/${selSub}`
}

    return this.h.c.get(url).
        pipe(
           map((data: any[]) => {
             return data;
           }), catchError( error => {
             return throwError( 'Something went wrong!' );
           })
        )
    }



    getFtrDtl(type?:string) {
   
      if(!type){type= this.h.ty}
        return this.h.c.get(`${this.h.ba}/listSgmnt/${this.h.si}/${this.h.bo}/${this.h.co}/cfg/ftrDtl/${this.h.cat}/${type}`).
          pipe(
             map((data: any[]) => {
               return data;
             }), catchError( error => {
               return throwError( 'Something went wrong!' );
             })
          )
      }
}



