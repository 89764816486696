import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ur-flds',
  templateUrl: './ur-flds.component.html',
  styleUrls: ['./ur-flds.component.scss']
})
export class UrFldsComponent implements OnInit {
@Input() headerId:number;

  constructor() { }

  ngOnInit(): void {
  }

}
