import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-cmnt-view',
  templateUrl: './cmnt-view.component.html',
  styleUrls: ['./cmnt-view.component.scss']
})
export class CmntViewComponent implements OnInit {
  @Input() c:any;
  get userGroup() { return this.cs.getUserGroup()};
  
  quillView :boolean;

  constructor(public cs: ClientInfoService) { }

  ngOnInit(): void {
   if(this.c.flipWindow == -1){this.quillView= true} 
  }

  formatMsg(c){
    return [c.comment,c.digAnsr,c.timeTaken,c.flipWindow].filter(Boolean).join('-');
  }
}
