import { Component, OnInit,Input } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { BoardDataService } from '../../board-data.service';
import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";
import { identifierModuleUrl } from '@angular/compiler';

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.scss']
})
export class PdfviewerComponent implements OnInit {
  @Input() src;
  @Input() startPage;

  src1:string;
  rootUrl:string;
  fullUrl:string;

  
  get site(){  return this.cs.getSite();  } 
  get course(){  return this.cs.getCourse();  } 
  get board(){  return this.cs.getBoard();  } 
  get offering(){  return this.cs.getOffering();  } 
  get feature(){  return this.cs.getFeature();  } 
  get subject(){  return this.cs.getSubject();  } 
  get chapter(){  return this.cs.getChapter();  } 
  headerId:number;
  headerById:any;
 
  constructor(private cs:ClientInfoService,private dataService: BoardDataService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    //alert("source is"+ this.src);

    this.rootUrl= this.cs.getRoot();

    
  }

  getFullUrl():string{
    //alert("called with source" ); 
  if(!this.src){ 
    //alert("called" );
  this.cs.updateParms(this.route);
  this.headerId= +this.chapter;  //parseInt/parseFloat
  this.dataService.getSiteHeaderById(this.site,this.board,this.course,this.offering,this.subject,this.headerId).subscribe(dataList => {
    this.headerById = dataList;
    this.src= this.headerById.dataList[0].extUrl;
  }  );
  }
  this.fullUrl= this.rootUrl + "/" + this.src;
 // return this.fullUrl;
  return this.src;
}

}

