import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-feature-shower',
  templateUrl: './feature-shower.component.html',
  styleUrls: ['./feature-shower.component.scss']
})
export class FeatureShowerComponent implements OnInit {

  get site(){ return this.cs.getSite()};

  constructor(public cs:ClientInfoService) { }

  ngOnInit(): void {
  }


}
