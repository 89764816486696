import { Component, Input, OnInit } from '@angular/core';
import { FixedDataService } from '../../shared/fixed-data.service';
import { TaghdrListService } from '../../tag/taghdr-list/taghdr-list.service';
import { QstnListService } from '../qstn-list/qstn-list.service';

@Component({
  selector: 'app-group-addon',
  templateUrl: './group-addon.component.html',
  styleUrls: ['./group-addon.component.scss']
})

export class GroupAddonComponent implements OnInit {
  @Input() hdr:any;
  isCollapsed:boolean;
  cardCollapsed:string;
  @Input()  i:number;
 showVid:boolean;
  qsTitle: string;
  
  collapseVid: boolean;
  docGrp: string;
  noteGrp: string;
  dhwGrp: string;
  vidGrp: string;
  qsGrp: string;
  ubstrGrp: string = "tgubstr";
  qsUploadTitle: string;
  autoMaker:boolean;
  
  get unsigned() {return this.qs.cs.isUnsigned()}
  get grpAsoId(){ return this.qs.cs.grpAsoId}
  constructor(public qs:QstnListService,private fd:FixedDataService) { }

  ngOnInit(): void {
    QstnListService.emptyVid.subscribe(res=>{
      this.collapseVid = res;
    });
  this.setcard();
  }

  setcard(){
    let cat= this.qs.cat;  

    if(this.unsigned || this.qs.groupId=='learner'){
      this.dhwGrp= 'tasgn-dhw';
      this.docGrp= 'tshr-doc';
      this.noteGrp= 'snote'; //don't change as this is not tag group but self -record
      this.vidGrp= 'tshr-vid';
      this.ubstrGrp= 'tasgn-' + this.ubstrGrp;
    }
  else{
    //if(this.qs.cat.includes("-bldr")  && this.qs.groupId=='teacher')
   this.docGrp= 'doc' ; 
   this.noteGrp= 'snote' ; 
   this.dhwGrp= 'dhw';
   this.vidGrp= 'vid';
   
   if(cat.endsWith('-bldr')){
   this.setQstnSet(cat); 
  }
}

 

  
if(cat.endsWith('-bldr')){
  this.setQstnSet(cat);
  this.cardCollapsed = 'cuatro';
}
else{
  //let vid be off till collapseVid logic works
this.cardCollapsed = '--uno';
}

  }

  setQstnSet(cat:string){
 this.qsGrp= cat.replace("-bldr","");   
  switch(cat){
    case 'pbstr-bldr':
      this.qsTitle = "Bstr-Set";
      
      break;
    case 'ubstr-bldr':
      this.qsTitle = "UnitTest";
      this.qsUploadTitle = "Upload UnitTest";
      break;
      case 'eps-bldr':
      this.qsTitle = "Digi Qstn Set";
      this.qsUploadTitle = "Upload Digi Qstn Set";
      break; 
    case 'dhw-bldr':
      this.qsTitle = "Build HW";
      this.qsUploadTitle = "Upload HW";
    break;
    default: 
    this.qsTitle = "build";
    this.qsUploadTitle = "Upload";
     }
    
 } 

 //pass selector type and all the input for creation of new hdr in selector
 prepHdr1(hdr:any,type:string){
   hdr.type= type;
   return hdr;
 }

 
//move it to selector directly
 prepHdr(hdr: any,type:string) {

  let crtHdr= Object.assign({}, hdr);
  crtHdr.type= type;
  crtHdr.owner= this.qs.signedUser;
  crtHdr.site =this.qs.site;
  
   //console.log(crtHdr); 
   if(this.fd.tagBasedForCreate(type)){
   crtHdr.type = "twip-" + type;} 
   else{
   crtHdr.type = type; 
   }
   crtHdr.clsSec = this.qs.cs.getCrtClsSec(crtHdr.type);
   
  // crtHdr.type = "twip-" + this.qs.offering.replace("-bldr","");
   crtHdr.status= 'wip';
    
   //cleanup reference-tag
   
   //note-this is needed for selecting qstn, for other type like full chptr-asgnment one of these fields need to stay populated.
   if(hdr){
   hdr.qstnHdrId= "";
   hdr.tagHdrId= "";
   }
   //headerId is kind of- Hdr-Group Id who will be interrelated.
  
   

   if(type && this.qs.fd.hdrGrpNeeded(type)){
   if (hdr && hdr.headerId) {
      crtHdr.headerId = hdr.headerId;
    } else {
      if(this.grpAsoId){
      crtHdr.headerId = this.grpAsoId;
      }else{
      if(hdr && hdr.id){
        crtHdr.headerId = hdr.id;
      }
      }
    }
  } else{
    crtHdr.headerId= "";
  }

    //prntHdrId is kind of asgn-wip type overrider of exactly same sub-group

    crtHdr.extUrl = "";
    //crtHdr.title= "";
  
    
   return crtHdr;
  }
  
  enable(btnId:string){
return this.qs.enable(btnId);
  }

  
  getgrpAsoId(){
    if(this.qs.cs.grpAsoId){
      return this.qs.cs.grpAsoId;
    }
   
   // console.log('passed hdr is',this.hdr); 
    if (this.hdr && this.hdr.headerId && this.hdr.headerId>0) {return this.hdr.headerId}
     return this.qs.chapter;
    }


}
