

  import { Component, Input, OnInit } from '@angular/core';
  import { Observable } from 'rxjs';
import { ClientInfoService } from '../../shared/client-info.service';

  import { CommentService } from '../../comments/comment.service';
  import { PerformanceService } from '../performance.service';
  
  
  @Component({
    selector: 'app-fdbck-rcmnd',
    templateUrl: './fdbck-rcmnd.component.html',
    styleUrls: ['./fdbck-rcmnd.component.scss']
  })
  export class FdbckRcmndComponent implements OnInit {

    @Input() user:string;
    
    mts$: Observable<any>;
    mtC$: Observable<any>;
     
    constructor(private cmntSrvc:CommentService,private ps:PerformanceService,private cs:ClientInfoService) { }
    
    ngOnInit(): void {
      let user= this.cs.getUserId()?this.cs.getUserId():this.user;
      
      this.mts$ = this.ps.getFeedbackList(user) ; 

      this.mtC$ = this.ps.getFeedbackChartData(user) ; 

    }
  
  }
  