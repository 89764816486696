

<div class= "h3 text-primary pt-5" >Talk To BestuBot</div>
<div class= "pb-5 text-secondary font-italic" >powered by advanced AI *Bot</div>

<app-context> </app-context>

<ng-container *ngFor="let message of messages | async">

  <div class="message" [ngClass]="{ 'from': message.sentBy === 'bot',
                                    'to':   message.sentBy === 'user' }">
    {{ message.content }}
  </div>

</ng-container>


<label for="nameField">Message/Query</label>


<input [(ngModel)]="formValue" (keyup.enter)="sendMessage()"  class="form-control"  type="text"><br>

<button class="pt-2 btn btn-primary btn-lg float-right" (click)="sendMessage()">start chat</button>
