import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-mistake-type-rpt',
  templateUrl: './mistake-type-rpt.component.html',
  styleUrls: ['./mistake-type-rpt.component.scss']
})
export class MistakeTypeRptComponent implements OnInit {
  @Input() user:string;

  
 constructor(public cs:ClientInfoService) { }

 ngOnInit(): void {
 this.user= this.cs.getUserId()?this.cs.getUserId():this.user;
 if(!this.user){
   this.user= this.cs.getSignedUser();
 }     
  }

}
