import { Component, Input, OnInit, } from '@angular/core';
//ViewEncapsulation -don't use , it doesn't work
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-product-dtl',
  templateUrl: './product-dtl.component.html',
  styleUrls: ['./product-dtl.component.scss'],
 // encapsulation: ViewEncapsulation.None
 //better use dom sanitizer
})
export class ProductDtlComponent implements OnInit {
  @Input() h:any;
  @Input() s:any;
  @Input() i:number;
  @Input() menuType:boolean;

  //menuType:boolean= true;

showUpdate:boolean = false;
showHoverable : boolean;

 
get rootUrl() {return  this.cs.getRoot() };

  constructor(private modalService: NgbModal,public cs: ClientInfoService ,private router: Router,public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }
  
  getImageLink(h:any,s:any){
  
  if(s.questionImages){
    return this.rootUrl + s.questionImages; 
  } else{
   if(this.cs.eh.image(h.extra)){
    return  this.rootUrl +this.cs.eh.image(h.extra) 
   } 
   return  this.rootUrl + "/assets/img/1.jpg";
  }

  // return  this.rootUrl + s.questionImages?s.questionImages:this.cs.eh.image(h.extra);
  }

openScrollableContent(longContent) {
  this.modalService.open(longContent, { scrollable: true });
}

enable(btnId:string,dtlRec?:any){
  return this.cs.enable("productDtl",btnId, "",dtlRec);
}



navigateQstn(s) {
  let url = this.router.url.replace("/ftr","")+"/cfg/subject/" + "/"+s.headerId + "/view-qstn/" + s.id;
  this.router.navigate([url]);
}



getRouteUrl(category:string, extLink: string,format:string): string{
  return this.cs.getRouteUrl(category, extLink,format);
  }

  
  navigate(category:string, extLink: string){
    this.cs.navigate(category, extLink);
    }



}
