import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageExpiryService {

  constructor() { }


  // Function to set the item with expiration
public setItemWithExpiry(key: string, value: any, ttl: number) {
  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
      value: value,
      expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

// Function to get the item
 public getItemWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key);
  // If the item doesn't exist, return null
  if (!itemStr) {
      return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // Compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
  }
  return item.value;
}

check(){
// Usage
// Set item with a TTL of 30 days (30 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
const myValue = this.getItemWithExpiry('setupDate');
//console.log(myValue); // 'myValue' or null if expired
if(!myValue){
  localStorage.clear();
  sessionStorage.clear();
 //console.log("LocalStorage has been cleared after 30 days");
 //alert("localarea  has been cleared and setup fresh after 30 days");
  this.setItemWithExpiry('setupDate', 'myValue', 30 * 24 * 60 * 60 * 1000);
return 'cleared';

}
else{
  return null;
}



// Get item

}
}
