
<ng-container *ngIf="list"> 
<div class="scene">
  <div class="cube">
    <div class="side back">
      <div class="guides"></div>
      <span>{{list[0]}} </span>
    </div>
    <div class="side top">
      <div class="guides"></div>
      <span>{{list[1]}}</span>
    </div>
    <div class="side bottom">
      <div class="guides"></div>
      <span>{{list[2]}}</span>
    </div>
    <div class="side front">
      <div class="guides"></div>
      <span>{{list[3]}}</span>
    </div>
  </div>
</div>
</ng-container>