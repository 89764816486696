import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { DetailService } from '../../tag/detail.service';
import { HeaderService } from '../../tag/header.service';
import { QstnDtlService } from '../qstn-dtl.service';

@Component({
  selector: 'app-field-changer',
  templateUrl: './field-changer.component.html',
  styleUrls: ['./field-changer.component.scss']
})
export class FieldChangerComponent implements OnInit {
@Input() q: any;
@Input() fieldName :string;
@Input() h: any;
@Input() i:number;

  constructor(public cs:ClientInfoService, private ds: QstnDtlService) { }

  ngOnInit(): void {
  }

  submit(data:any){
    this.changeQDField(data.fieldValue)
   // this.cs.addMsg("changed " + data.fieldValue)
  }

  changeQDField(value:any){
    //ideally type = this.h.type if passed
    let type = "type";

    this.ds.changeField(this.q.id,type,this.fieldName,value).subscribe(data =>{
       if ((data as any).status === 200) {
 this.cs.addMsg((data as any).message, 'info');         
 } else {
 this.cs.addMsg('error,try later', 'error');
 }  
 });
 
   }
 

   
}
