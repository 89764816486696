 
  
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { BtnService } from '../../gen/btn.service';
import { PropFromUrlService } from '../../shared/prop-from-url.service';
import { ListerService } from '../../chat/lister.service';

@Component({
  selector: 'app-prd-combo-slides',
  templateUrl: './prd-combo-slides.component.html',
  styleUrls: ['./prd-combo-slides.component.scss']
})
export class PrdComboSlidesComponent implements OnInit {


@Input() ovrSub:string;

//rootUrl: string;
images: string[];
slides: any;
headerObj:any;
  totalSize: number;
  currentSize: number;
  list: any;
  dataCombo: any;
  get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get feature() { return this.cs.getFeature()};
  get rootUrl() {return  this.cs.getRoot() };
  get slideDataKey() {return  this.cs.slideDataKey };

  headerId:number;
  key:string;
  data$: Observable<any>;
  calling:boolean;
  constructor(config: NgbCarouselConfig, private route: ActivatedRoute,private b:BtnService,
    private router: Router, public cs:ClientInfoService, private ds: BoardDataService,private fd:FixedDataService,private prop: PropFromUrlService, public ls:ListerService) { 
      config.showNavigationArrows = true;
      config.showNavigationIndicators = true;
      //config.interval= 1500;
      config.interval= 0;
      config.pauseOnHover = true;
      //config.pauseOnFocus =true;
       }


   getRouteUrl(category:string, extLink: string): string{
      if(extLink=='te/Features/excel'){
    }
    return this.cs.getRouteUrl(category, extLink);
    }

    show(q:any){ return this.b.showQstn("slide",q)}

    
  ngOnInit() {
    
  this.cs.updateParms(this.route);

   let key =  this.ovrSub;
   if(!key){
   key = this.feature?this.feature:this.cat;
   }
  this.dataCombo = key;
  this.calling = true;
    // this.data$ =  this.ds.getCfgComboData(this.site,this.board,this.course,"cfg",key);
    
  //let key1= 'slidesDataKey' ; 
 this.ls.getKeyFlds('slidesDataKey',key).subscribe((data:any) => {
  
  this.dataCombo  = data.result?.value;
  this.data$ =  this.ds.getCfgComboDataNew(this.site,this.board,this.course,"cfg",this.dataCombo);
});
/*
    let propFile = 'ExamList.json';
    this.prop.getPropList(propFile).subscribe(data => {
      //this.httpClient.get(url).subscribe(data =>{
       console.log(data);
      (this.list as any) = data;
    });
  */  

    //let keyCombo = "platform~slides:platform~usp:digistore:digiEngage:assignment";
    
    /*
    let keyCombo = "platform~slides:platform~usp:platform~digistore:platform~digiEngage:platform~assignment:platform~elevate,platform~:plataform:assess:platform~special";
    */
    
     }

   /* needed for non async block in template call
    this.data$.subscribe(res => {
    this.slides= res.dtls;
    this.headerObj= res.hdr;
    //console.log(res);
  }  )
  */
    
   

  /*
    //put check for cat/features with slides..
    this.ds.getCatHeaders(this.site,this.board,this.course,this.key,this.subject).subscribe(data => {
     this.headerObj= (data as any).dataList[0];
     if(this.headerObj){
    this.headerId= this.headerObj.id;
    this.ds.getSiteQuestions(this.site,this.board,this.course,'cfg',this.subject,this.headerId).subscribe(data => {
    this.slides= data;
    //alert("Data retrieved"+ data);
    console.log(data);

    });
  }
  });
*/
 

getCurrent(d:any,di:number,i:number){
  
 let totalSize= 0;
  
 for (let c = 0; c < d.length; c++) {
    totalSize= totalSize + d[c].dtls?.length;
  }
this.totalSize= totalSize;

let currentSize= 0;
for (let c = 0; c <di; c++) {
  currentSize = currentSize + d[c].dtls?.length;
}
this.currentSize= currentSize+ i +1;

return this.currentSize + " of " +this.totalSize;

}
  


}
