import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Edi } from './edi';
import { QhFldService } from '../qh-flds/qh-fld.service';

@Component({
  selector: 'app-dtl-extra',
  templateUrl: './dtl-extra.component.html',
  styleUrls: ['./dtl-extra.component.scss']
})
export class DtlExtraComponent implements OnInit {
  @Input() d:any;
  @Input() edi: Edi;
  @Input() parentFormGroup: FormGroup;
  @Input()  fldList:string[];


constructor(public qhf:QhFldService) { }

  ngOnInit(): void {
    if(this.d && !this.edi){
      if(!this.d.extra){this.d.extra= "{}"}  
      this.edi= JSON.parse(this.d.extra)
      }
     
 }
}
