<div class= "bg-success jumbotron text-center"  style="color: gold;background-color:indigo;">
<h2>{{board}} - {{subject}}  Special Page! </h2>
<p> contains consolidated formulae, tips and tricks and videos</p>
</div>


<div *ngIf="images" class= "bg-indigo text-center ">
  <h3 class="text-center   text-warning "> {{subject}} Note-Consolidated for quick Revision</h3>
  <i class= "text-warning"> Note-click/touch left or right side to navigate many pages</i>
<ngb-carousel *ngIf="images" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators"  data-interval="false">
  <ng-template ngbSlide *ngFor="let image of images">
    <img [src]="image" alt="Random slide">
    <div class="carousel-caption">
      <h3>No mouse navigation</h3>
      <p>This carousel hides navigation arrows and indicators.</p>
    </div>
  </ng-template>
</ngb-carousel>
<!--
<ngb-carousel *ngIf="tips" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators"  data-interval="false">
  <ng-template ngbSlide *ngFor="let tip of tips">
    <img [src]='examsteppers.com\examimages\'+tip.instruction+'\'+tip.content alt="Random slide">
    <div class="carousel-caption">
      <h3>No mouse navigation</h3>
      <p>This carousel hides navigation arrows and indicators.</p>
    </div>
  </ng-template>
</ngb-carousel>
-->
</div>
<hr>

<!-- Tips -->
<div *ngIf="tips">
  <h3 class="text-center  text-warning "> {{titleTips}}  {{board}} - {{subject}} </h3>

<ul *ngIf="tips">
			

  <li *ngFor="let question of tips.dataList;">

    Tip#: {{question.sequence }}-{{question.subSeq}}-{{question.part}} <br>

    <div *ngIf="question.content" class="questionsc" innerHTML = " {{  question.instruction }}<br> {{  question.content }}"></div> 
    <br/>
    
    
    <div *ngIf="question.answer " class="answersc" innerHTML = "<br> {{ question.answer }}"></div> 
    
  </li>
</ul>
</div>


<div> 
<ul *ngIf="links">
   <h2 class="text-center"> {{links.title}} Practice Material - Important Links </h2>
  <li *ngFor="let question of links.dataList;">

    #{{question.sequence }} : <br>
<!--
{{question.subSeq}}-{{question.part}}
-->

<div *ngIf="question.instruction" class="questionsc"  innerHTML = " <h2>{{  question.instruction }}</h2>">
      
    </div> 

    <div *ngIf="question.content" class="text-center"  innerHTML = " {{  question.content }}">
      
    </div> 

    <br/>
    
    <div *ngIf="question.answer" class="text-center">
      <br>
    <a href={{question.answer}}   target="_blank" >Link to Full Article</a>
</div>

 </li>

</ul>
</div>





<!videos only for physics for now -->
<div *ngIf="subject=='Physics'">
<ul *ngIf="vids ">
			<h2 class="text-center"> {{links.title}} Important Videos </h2>
<h3 class="text-center   text-warning "> {{titleVids}}  {{board}} - {{subject}} </h3>
<div>
<h4 class = "text-center">Household Electric Circuits by BodhaGuru:</h4>

 <iframe width="480" height="360" src="https://www.youtube.com/embed/5TI2jIL7pSQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>

<div>
<h4 class = "text-center">Fleming's left hand rule</h4>
 <iframe width="492" height="360" src="https://www.youtube.com/embed/a97NpQKIbks" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>


<h3  class = "text-center   text-warning	"> For more video Tutorials like above, please click 'Vid' link against the topic: </h3>


  <li *ngFor="let question of vids.dataList;">

     <h4> 
    {{question.subSeq}}-{{question.part}} 
</h4>
    <div *ngIf="question.content" class="text-center questionsc" innerHTML = "  {{  question.instruction }}"></div> 
    <br/>
    
<div  class="text-center text-warning"> 
    <a href="{{ question.content }}">vid</a>
</div>

 </li>
</ul>


