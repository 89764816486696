


<!--
{{ts.closeTest}} --{{hideTest}}
-->

<div *ngIf= '!ts.closeTest'>

<ngb-progressbar  type= "info" [striped] = true tabindex="0" [value]="(ts.responsed)*100/totalQstns"></ngb-progressbar>

<!--
<span *ngIf= "!ts.timeRemaining()" class="text-small text-muted font-italic ">Elapsed Duration : {{ts.timeElapsed()}} </span>
-->
<div class= "bg-light">
<span *ngIf= '!ts.closeTest' class= "float-left">  # {{ts.showQstn +1}}/{{totalQstns}} </span>

<span class="text-small text-muted font-italic float-right"> Flip#: {{ts.count}} </span>

<span *ngIf= "ts.timeRemaining()" class="text-small text-muted font-italic float-center">Remaining Duration : {{ts.timeRemaining()}} </span>
</div>

<div id= "aa">
<app-test-qstn class="" [q]=ts.getQstn()  [i]=ts.showQstn [worksheet] = 'false'  [storeLocal] = true> </app-test-qstn>
</div>

</div>

 <app-test-hdr-btn [h] = 'ts.hdr' [i] = i list= 'ts.qstns' >  </app-test-hdr-btn>

 <app-qstn-status [qstnList] =ts.qstns> </app-qstn-status>