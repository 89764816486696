
<ng-container>
   
    <button *ngIf= "enable('outSlbs21') && !enable('inSlbs21')" class= "badge btn-md btn-secondary"   (click)="changeStatus(header.id,header.type,'outSlbs21',i); showAddI= i;">Tag-Out Of Syllabus-21 </button>
   
    <button *ngIf= "enable('inSlbs21')" class= "badge btn-md btn-info"   (click)="changeStatus(header.id,header.type,'inSlbs21',i); showAddI= i;">Bring In Syllabus-21 </button>
   
    <!--
    <button *ngIf= "enable('outSlbs')" class= "badge btn-md btn-warning"    (click)="changeStatus(header.id,header.type,'outSlbs',i); showAddI= i;">Tag-Out Of Syllabus</button>
    <button *ngIf= "enable('inSlbs')" class= "badge btn-md btn-info"    (click)="changeStatus(header.id,header.type,'inSlbs',i); showAddI= i;">Bring In Syllabus</button>
    -->

    <div *ngIf = "success &&(showAddI== i)"> changed: {{success}} </div>
</ng-container>
    
    