import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { HeaderService } from '../../tag/header.service';

@Component({
  selector: 'app-sem-mapper',
  templateUrl: './sem-mapper.component.html',
  styleUrls: ['./sem-mapper.component.scss']
})
export class SemMapperComponent implements OnInit {
@Input() h:any;
@Input() i:number;

constructor(private hs: HeaderService , private cs: ClientInfoService) { }

  ngOnInit(): void {
  }

changeField(h:any,field:any,value:any,i?:number){
   this.hs.changeField(h.id,h.type,field,value).subscribe(data =>{
      if ((data as any).status === 200) {
this.cs.addMsg((data as any).message, 'info');         
} else {
this.cs.addMsg('error,try later', 'error');
}  
});

  }


}
