import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-end-footer',
  templateUrl: './page-end-footer.component.html',
  styleUrls: ['./page-end-footer.component.scss']
})
export class PageEndFooterComponent implements OnInit {
  @Input() site:string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
