import { Component, OnInit, Input } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { Observable } from 'rxjs';
import { ClientInfoService } from '../client-info.service';
import { ConfigService } from '../config.service';
//declare var MathJax:any;

@Component({
  selector: 'app-text-edtr',
  templateUrl: './text-edtr.component.html',
  styleUrls: ['./text-edtr.component.scss']
})
export class TextEdtrComponent implements OnInit {



  @Input()  headerObj: any;
  fileData:any;
  public myHtml$: Observable<any>;
  result:any;
  submitted:boolean;
  readOnly= true;
  loading: boolean;
  get showEdit() {return ((this.headerObj && this.headerObj. owner==this.signedUser) || this.cs.enable('textEdtr', 'allowEdit')) };
  
  get site() {return  this.cs.getSite()} ;
    get cat() {return this.cs.getCat() };
    get board() {return this.cs.getBoard()};
    get course() { return this.cs.getCourse()};
    get offering() { return this.cs.getOffering()};
    get subject() { return this.cs.getSubject()};
    get chapter() { return this.cs.getChapter()};
    get rootUrl() {return  this.cs.getRoot() };
    get signedUser() {return this.cs.getSignedUser()};
    //headerId:number;
     showText:boolean;
     
     textFormat:boolean = false;

     constructor(private ds:BoardDataService, public cs:ClientInfoService,public cfg:ConfigService) {
   
   }

   
  ngOnInit(): void {
    this.fetchData();
  }

  ngOnChanges() {
    this.fetchData();
    
  }

  ngAfterViewChecked(){
    // MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
   this.cs.typeSet();
  }

  fetchData(){
    this.loading = true;
     this.ds.getSiteHeaderById(this.site,this.board,this.course,this.offering,this.subject,Number(this.chapter)).subscribe(data => {
      this.headerObj = data;
      let url = this.headerObj?.dataList[0]?.extUrl;
      if(url){
     this.getHtmlFile(url);
     //MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
     this.cs.typeSet();
     }
  } );

  this.readOnly = !this.cs.enable('textEdtr', 'allowEdit')
}
 

  getHtmlFile(url: any){
       // this.myHtml$ = this.ds.getTextFile(url);
   this.ds.getTextFile(url).subscribe( data =>
      {this.fileData = data,
      this.loading = false;}
      );
    
    }
  
    public onChange( event: any ) {
     // console.log( "yes:: " + event.editor.getData() );
  }



    postTextFile(fileData: any){
      //   this.myHtml$ = this.ds.getTextFile(url);
      const formData = new FormData();
      formData.append('fileData', fileData);

     this.ds.postTextFile(formData).subscribe( data  =>
     {this.cs.addMsg( "success: ")} );
     this.submitted= true;
   
   }

   onSubmit(){
     //console.log(this.fileData);
     this.postTextFile(this.fileData);
           
   }

   
      
   
}
