import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListerService } from '../../chat/lister.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { UsrdtlService } from '../usrdtl.service';

@Component({
  selector: 'app-usrdtl-update',
  templateUrl: './usrdtl-update.component.html',
  styleUrls: ['./usrdtl-update.component.scss']
})
export class UsrdtlUpdateComponent implements OnInit {
  //@Input() usrNum: any;
  ud: any;
  usrDtl: boolean;
  usrNum: any;
  site:string;
 submitted:boolean;
 showRole:boolean;
 
 fldList:any;
  primary: any;
  secondary: any;
  additional: string[];


  constructor(private route: ActivatedRoute,private cs:ClientInfoService,
    private router: Router, private uds: UsrdtlService,public ls:ListerService) { }

  ngOnInit(): void {

    this.route.params.subscribe( params => {
      this.site= params['site'];
      this.usrNum= params['usrNum'];
    });

    this.route.params.subscribe(params => {
      this.usrNum = params['usrNum'];
      this.uds.get(this.usrNum).subscribe(res => {
        this.ud = res;
        console.log(this.ud);
      });
    });

    this.ls.getKeyFlds('usr-dtl-flds',"dflt").subscribe((data:any) => {
      //console.log(data);
      let flds  = data.result;
      let StrArray = flds.primary.split(",");
      this.primary = StrArray;
      //console.log(this.primary);
      this.secondary= flds.secondary.split(",");
      this.additional = ['-'].concat(this.primary).concat(this.secondary);
    });
  }

enable(){
  return true;
}

  //this.usrNum= 123626;

  updateDetail(ud: any) {
    this.uds.update(ud.headerId, ud)
      .subscribe(error => console.log(error));
    alert("detail updated");
    //this.gotoList();
  }

  onSubmit() {
    this.updateDetail(this.ud);
  }

  gotoList() {
    this.router.navigate([this.site,'usr']);
    //this.router.navigate(['//eba//digibest//features//details']);
  }


}
