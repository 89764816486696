import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-cmnt-viewer',
  templateUrl: './cmnt-viewer.component.html',
  styleUrls: ['./cmnt-viewer.component.scss']
})
export class CmntViewerComponent implements OnInit {
  hdrObj:any;
  
  get cmntType(){
    //cat=cmnt/offering= cmntType  /subject=ftr?
    if(this.cs.getCat() == 'cmnt' && this.cs.getOffering() ){
      return this.cs.getOffering();
    }
    return this.cs.getCat()?this.cs.getCat().replace("cmnt-",""):"all"}

  constructor(public cs:ClientInfoService) { }
 
  static selectedSite :String;
  get chapterNum() {return this.cs.getChapterNum()}

  ngOnInit(): void {
  }

  setSite(site:string){
  CmntViewerComponent.selectedSite= site;
  }

getSite(){
if(CmntViewerComponent.selectedSite){ return CmntViewerComponent.selectedSite}
else{
return this.cs.getSite();
}
}

}
