
 

    <!-- Field expander start -->
    

    <div *ngIf = "enable('title')" class="form-group py-1">
      <label for="title">Title: </label>
      <button type="button" class="btn badge  btn-outline-info float-right"
        (click)='htmltitle =!htmltitle'> edit+ </button>
      <br>
      <input *ngIf="!htmltitle else ck" type="text" class="form-control" id="title"
        required [(ngModel)]="d.title" name="title">
      <ng-template #ck>
        <ckeditor  [(config)]="cfg.withWordConfig" #editor
        debounce="500" [(ngModel)]="d.title" id="title" name="title">
      </ckeditor>
      <br>
      Actual view:
      <p innerHTML={{d.title}}> </p>
    </ng-template>
    </div>
    
    <div *ngIf= "enable('extLink')" class="form-group">
      <label for="extLink">route: </label>
      <input type="text" class="form-control" id="extLink" required [(ngModel)]="d.extLink" name="extLink">
    </div>
    
    <div *ngIf= "enable('topic')" class="form-group">
      <label for="topic">topics: </label>
      <input type="text" class="form-control" id="topic" required [(ngModel)]="d.topic" name="topic">
    </div>

    <div  *ngIf = "enable('section')" class="form-group">
      <label for="section">Section: </label>
      <input type="text" class="form-control" id="section" required [(ngModel)]="d.section" name="section">
    </div>

    
    <div  *ngIf = "enable('groupId')" class="form-group">
      <label for="groupId">groupId </label>
      <input type="text" class="form-control" id="groupId" required [(ngModel)]="d.groupId" name="groupId">
    </div>
    

    <div *ngIf = "enable('instruction')" class="form-group py-1">
      <label for="instruction">instruction: </label>
      <button type="button" class="btn badge  btn-outline-info float-right"
        (click)='htmlinstruction =!htmlinstruction'> edit+ </button>
      <br>
      <input *ngIf="!htmlinstruction else ck2" type="text" class="form-control" id="instruction"
      required [(ngModel)]="d.instruction" name="instruction">
      
      <ng-template #ck2>
      <ckeditor *ngIf="htmlinstruction" [(config)]="cfg.withWordConfig" #editor
        debounce="500" [(ngModel)]="d.instruction" id="instruction" name="instruction">
      </ckeditor>
      <br>
      Actual view:
      <p innerHTML={{d.instruction}}> </p>
    </ng-template>

    </div>
    
    
    
    <div *ngIf = "enable('question')" class="form-group py-1">
      <label for="question">question: </label>
      <button type="button" class="btn badge  btn-outline-info float-right"
        (click)='htmlQuestion =!htmlQuestion'> edit+ </button>
      <br>
      <input *ngIf="!htmlQuestion else ck3" type="text" class="form-control" id="question"
        required [(ngModel)]="d.question" name="question">
        <ng-template #ck3>
        <ckeditor *ngIf="htmlQuestion" [(config)]="cfg.withWordConfig" #editor
        debounce="500" [(ngModel)]="d.question" id="question" name="question">
      </ckeditor>
      <br>
      Actual view:

      <!--
      <Div *ngIf= "d.format && d.format == 'slide'" [ngStyle]="{'background-image':(d.questionImages) ?'url(' + d.questionImages + ')' : '' }" innerHTML={{d.question}} > </Div>
                   
      <p *ngIf= "!d.format || d.format != 'slide'" innerHTML={{d.question}}> </p>
     -->

     <p innerHTML={{d.question}}> </p>

    </ng-template>
    </div>
      
    <!--
      
    <div  *ngIf = "enable('questionImages')" class="form-group">
      <label for="questionImages">questionImages: </label>
      <input  type="text" class="form-control" id="questionImages" required [(ngModel)]="d.questionImages" name="questionImages">
    </div>
    <br>
-->


    <div *ngIf = "enable('questionImages')" class="form-group py-1">
      <label for="questionImages">questionImages: </label>
      <button type="button" class="btn badge  btn-outline-info float-right"
        (click)='htmlquestionImages =!htmlquestionImages'> edit+ </button>
      <br>
      <input *ngIf="!htmlquestionImages else ck2" type="text" class="form-control" id="questionImages"
      required [(ngModel)]="d.questionImages" name="questionImages">
      
      <ng-template #ck2>
      <ckeditor *ngIf="htmlquestionImages" [(config)]="cfg.withWordConfig" #editor
        debounce="500" [(ngModel)]="d.questionImages" id="questionImages" name="questionImages">
      </ckeditor>
      <br>
      Actual view:
      <p innerHTML={{d.questionImages}}> </p>
    </ng-template>

    </div>

    
    <div *ngIf = "enable('answer')" class="form-group py-1">
      <label for="answer">answer: </label>
      <button type="button" class="btn badge  btn-outline-info float-right"
        (click)='htmlanswer =!htmlanswer'> edit+ </button>
      <br>
      <input *ngIf="!htmlanswer else ck4" type="text" class="form-control" id="answer"
        required [(ngModel)]="d.answer" name="answer">
        <ng-template #ck4>
        <ckeditor *ngIf="htmlanswer" [(config)]="cfg.withWordConfig" #editor
        debounce="500" [(ngModel)]="d.answer" id="answer" name="answer">
      </ckeditor>
      <br>
      Actual view:
      <p innerHTML={{d.answer}}> </p>
      </ng-template>
    </div>
    <br>

    <div *ngIf = "enable('answerImages')" class="form-group py-1">
      <label for="answerImages">answerImages: </label>
      <button type="button" class="btn badge  btn-outline-info float-right"
        (click)='htmlanswerImages =!htmlanswerImages'> edit+ </button>
      <br>
      <input *ngIf="!htmlanswerImages else ck2" type="text" class="form-control" id="answerImages"
      required [(ngModel)]="d.answerImages" name="answerImages">
      
      <ng-template #ck2>
      <ckeditor *ngIf="htmlanswerImages" [(config)]="cfg.withWordConfig" #editor
        debounce="500" [(ngModel)]="d.answerImages" id="answerImages" name="answerImages">
      </ckeditor>
      <br>
      Actual view:
      <p innerHTML={{d.answerImages}}> </p>
    </ng-template>

    </div>
    <!--
    <div  *ngIf = "enable('answerImages')" class="form-group">
      <label for="answerImages">answerImages: </label>
      <input type="text" class="form-control" id="answerImages" required [(ngModel)]="d.answerImages" name="answerImages">
    <input *ngIf="false" type="text" class="form-control" id="answerImages" required [(ngModel)]="d.answerImages" name="answerImages">
   </div>
  -->
    
     
      <div  *ngIf = "enable('category')" class="form-group">
        <label for="category">category: </label>
        <input type="text" class="form-control" id="category" required [(ngModel)]="d.category" name="category">
      </div>

      

      <div  *ngIf = "enable('title')" class="form-group">
        <label for="label">Title/Label: </label>
        <input type="text" class="form-control" id="label" required [(ngModel)]="d.title" name="label">
      </div>
      
      <div *ngIf = "enable('vidLink')" class="form-group">
        <label for="vidLink">vidLink: <button type= "button" (click)= "help('vid')"> ?</button>

        </label>
        <input type="text" class="form-control" id="vidLink" required [(ngModel)]="d.vidLink" name="vidLink">
      </div>

      
      <div  *ngIf = "enable('ansrRef')" class="form-group">
        <label for="ansrRef">ansrRef: </label>
        <input type="text" class="form-control" id="ansrRef" required [(ngModel)]="d.ansrRef" name="ansrRef">
      </div>

      <div  *ngIf = "enable('wireFrame')" class="form-group">
          <label for="wireFrame"> wireFrame: </label>
          <input type="string" class="form-control" id="wireFrame" required [(ngModel)]="d.wireFrame" name="wireFrame">
        </div>
      
      
    
     <div  *ngIf = "enable('headerId')" class="form-group">
        <label for="headerId"> headerId: </label>
        <input type="text" class="form-control" id="headerId" required [(ngModel)]="d.headerId" name="headerId">
      </div>

     

      <div  *ngIf = "enable('qstnId')" class="form-group">
        <label for="qstnId">qstnId: </label>
        <input type="text" class="form-control" id="qstnId" required [(ngModel)]="d.qstnId" name="qstnId">
      </div>

      

      <div  *ngIf = "enable('subqstnId')" class="form-group">
        <label for="subqstnId">subqstnId: </label>
        <input type="text" class="form-control" id="subqstnId" required [(ngModel)]="d.subqstnId" name="subqstnId">
      </div>

      <div  *ngIf = "enable('partId')" class="form-group">
        <label for="partId">partId: </label>
        <input type="text" class="form-control" id="partId" required [(ngModel)]="d.partId" name="partId">
      </div>

      <!--
      <div  *ngIf = "enable('instruction')" class="form-group">
        <label for="instruction">instruction: </label>
        <input type="text" class="form-control" id="instruction" required [(ngModel)]="d.instruction" name="instruction">
      </div>
      -->
      
       <div  *ngIf = "enable('vidInstruction')" class="form-group">
        <label for="instruction">Description: </label>
        <input type="text" class="form-control" id="instruction" [(ngModel)]="d.instruction" name="instruction">
      </div>
  
      <div  *ngIf = "enable('vidMarks')" class="form-group">
        <label for="marks">Score out of 5: </label>
        <input type="text" class="form-control" id="marks" [(ngModel)]="d.marks" name="marks" maxlength= "3">
      </div>



    
    <div  *ngIf = "enable('ansrRef')" class="form-group">
      <label class= "bg-warning" for="headerId"> Answer Ref#: </label>
      <input type="text" class="form-control" id="ansrRef" required [(ngModel)]="d.ansrRef" name="ansrRef">
    </div>

    
    <div  *ngIf = "enable('marks')" class="form-group">
      <label for="marks"> Marks: </label>
      <input type="string" class="form-control" id="marks" required [(ngModel)]="d.marks" name="marks">
    </div>
    


    <div  *ngIf = "enable('digInstrctn')" class="form-group">
      <label for="digInstrctn"> digInstrctns: </label>
      <input type="string" class="form-control" id="digInstrctn" required [(ngModel)]="d.digInstrctn" name="digInstrctn">
    </div>

    
    
    <div  *ngIf = "enable('digQstn')" class="form-group">
      <label for="digQstn"> digQstn: </label>
      <input type="string" class="form-control" id="digQstn" required [(ngModel)]="d.digQstn" name="digQstn">
    </div>

    <div  *ngIf = "enable('digOptns')" class="form-group">
      <label for="digOptns"> digOptns: </label>
      <input type="string" class="form-control" id="digOptns" required [(ngModel)]="d.digOptns" name="digOptns">
    </div>


    <div  *ngIf = "enable('digAnsr')" class="form-group">
      <label for="digAnsr"> digAnsr: </label>
      <input type="string" class="form-control" id="digAnsr" required [(ngModel)]="d.digAnsr" name="digAnsr">
    </div>

    <div  *ngIf = "enable('status')" class="form-group">
      <label for="digAnsr"> Status: </label>
      <input type="string" class="form-control" id="status" required [(ngModel)]="d.status" name="status">
    </div>

    <div  *ngIf = "enable('format')" class="form-group">
      <label for="formatr"> format: </label>
      <input type="string" class="form-control" id="format" required [(ngModel)]="d.format" name="format">
    </div>

    
    <div  *ngIf = "enable('id')" class="form-group">
      <label for="id">  id: </label>
      <input type="string" class="form-control" id="id" required [(ngModel)]="d.id" name="id">
    </div>

    <div  *ngIf = "enable('inactive')" class="form-group">
      <label for="inactive">  inactive: </label>
      <input type="boolean" class="form-control" id="inactive" [(ngModel)]="d.inactive" name="inactive">
    </div>

 <!-- put in upload.. not in add ???  -->
  <app-file-upload *ngIf = "enable('upload')" [iDetail] = d>
        </app-file-upload>
  
       

    <app-dtl-extra [d] = d 
    [edi]= edi [fldList]=fldList> 
        </app-dtl-extra>

    <div  *ngIf = "enable('addUser')" class="form-group">
    <label for="addUser">Added by: </label>
    <input type="text" class="form-control" id="addUser" required [(ngModel)]="d.addUser" name="addUser">
  </div>
  

      <!--
      <div class="form-group">
        <label for="message">Message/Query</label>
        
        <ckeditor editorUrl="\assets\ckeditor\ckeditor.js"
            #editor
            [(ngModel)]="comment.comment"
            id="message"
                      name="message"  >
          </ckeditor>
        --> 
  
        <!--
  
          type="divarea"
          [config]="{ toolbar: [ [ 'Bold' ] ] }"
  
        <input type="text" class="form-control" id="message" required [(ngModel)]="comment.comment" name="message">
        
      </div>
      -->
    <!-- check it later -->
    
    