

<div *ngIf= "cmnts.length>0" class = " text-center bg-light fixedHeight">
    <ngb-carousel>
    <div  *ngFor="let c of cmnts" class="col-11">
     <!-- *ngIf="recent(c.addDate)" -->
     <div>
      <ng-template ngbSlide>    
       <!--    {{addDate}} --{{today}}     -->
      <app-cmnt-view  [c]=c class= "row" > </app-cmnt-view>
      
      <app-cmnt-rspnse *ngIf= 'expanded' class= "row" [c] =c> </app-cmnt-rspnse>
       
    </ng-template>
    </div>

      </div>
    </ngb-carousel>
    </div>