import { Component, Input, OnInit } from '@angular/core';
import { ConfirmModalService } from '../../shared/confirm-modal.service';
import { ChptrService } from '../chptr-lstr/chptr.service';

@Component({
  selector: 'app-disabled-chptr',
  templateUrl: './disabled-chptr.component.html',
  styleUrls: ['./disabled-chptr.component.scss']
})
export class DisabledChptrComponent implements OnInit {
  @Input() header:any;
  i:number;
  constructor(public s:ChptrService, private hs: ConfirmModalService) { }

  ngOnInit(): void {
  }
  
  disabledInfo(){
  alert("Work is still in progress...")  
  }

  public help(fld?:string) {
    this.hs.help('Not eligible', 'Content is still in progress or locked currently (id: ' + this.header.id + ')');
  }

  

}
