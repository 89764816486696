import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HeaderService } from '../../tag/header.service';

@Component({
  selector: 'app-associated-rec',
  templateUrl: './associated-rec.component.html',
  styleUrls: ['./associated-rec.component.scss']
})
export class AssociatedRecComponent implements OnInit {
@Input() grpAsoId: any;
@Input() asoHdr:any;
@Input() slctType :string;

addAsoHdr:boolean;

get unsigned(){ return this.ths.cs.isUnsigned()}

data$: Observable<any>;

storeKey: string = "grpAsoId";


// please see if u want to make it obsolete and user aso-hdr-lstr instead.



  constructor(public ths: HeaderService) { }

  ngOnInit(): void {

 // this.data$ = this.ths.getUnitHeaders('type',this.grpAsoId);

 // new way based on site etc full parm change dataList in component too if flipping
 
  this.data$ = this.ths .fltrdHdrList('type','status',this.grpAsoId)

  }

  segment(list:any, slctType:string){
  if(slctType){ 
   //|| this.ths.cs.getGroupId() =="learner"
    if(this.ths.cs.isUnsigned()  ) {
    slctType = "tasgn-" + slctType; 
   }  

  return list.filter(x=> x.type.includes(this.slctType),[] ) ; 
  
} else{
    return list;
  }
  
}

  associate(){
  localStorage.setItem(this.storeKey, this.grpAsoId);
  this.ths.cs.navigate("dcstore", "");  
   }
  

}
