import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';



@Injectable({
  providedIn: 'root'
})
export class SeoService {




constructor( private titlecase: TitleCasePipe, private title: Title, private meta: Meta) { }


 updateTitle(title: string) {
   
title= title.replace('undefined','');
    
if(title && title[0]?.toLowerCase() == title[0])
{
  title= this.titlecase.transform(title);
}

    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    desc= desc.replace('undefined','');
    this.meta.updateTag({ name: 'description', content: desc })
  }

  updateRobots(value?: string)  {
    if(!value){
     // this.meta.removeTag('robots');
    value='noindex';
    } 

    this.meta.updateTag({ name: 'robots', content: value });
    return this;
  }

  updateTags(gen:any){
  this.meta.addTags(gen);
    
  }

}
