import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';
import { Detail } from '../../qstndtl/detail';
import { ClientInfoService } from '../../shared/client-info.service';
import { ConfigService } from '../../shared/config.service';
import { ConfirmModalService } from '../../shared/confirm-modal.service';
import { Edi } from '../dtl-extra/edi';


@Component({
  selector: 'app-qd-flds',
  templateUrl: './qd-flds.component.html',
  styleUrls: ['./qd-flds.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class QdFldsComponent implements OnInit {
@Input()  fldList:string[];
@Input()  d:Detail;
@Input() edi:Edi;
@Input() parentFormGroup: FormGroup;

get justOffering() {return this.cs.getJustOffering()}

constructor(private confirmModalService: ConfirmModalService, public cfg:ConfigService ,private cs:ClientInfoService) { }

ngOnInit(): void {
}

enable(fld: string){

  if(this.fldList?.includes('-')){
    if(this.fldList.includes('all')){return false}  
    return !this.fldList.includes(fld);
    }  

if(this.fldList?.includes('all')){
  if(fld.startsWith(this.justOffering)){return false}
  return true}  

  if(this.d.status && this.d.status.startsWith('dig')){
    if(fld.startsWith("dig")){return true}
    return false}  

    
return this.fldList && this.fldList.includes(fld);
}


public help(fld:string) {
  this.confirmModalService.help('Please enter char after "v="', ' https://www.youtube.com/watch?v=<span class="bg-info">bKKJkxqIg94</span>');
}


}
