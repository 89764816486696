

<div *ngIf= "c.rspnseUser && (c.comment|| c.response || c.digAnsr||c.correct) ; else notDone" class="message to" >
    {{c.rspnseUser}} --{{c.rspnseDate}} @ {{c.rspnseTime}}
   <hr> 
   
   <div class= "text-left" *ngIf= 'c.timeTaken' >time taken: {{c.timeTaken/60|number : '1.2-2' }} m </div>


   <ng-container *ngIf = "c.rspnseUser && (c.response || c.digAnsr)"> 
    
    <span *ngIf= "c.correct" class="text-white" > Correct </span>
    <span *ngIf= "c.cmntType=='Dans' && !c.correct" class="text-danger" > Incorrect </span>
  
  </ng-container>
    
  
  
  --

   <p *ngIf='c.cmntType == "Dans"' innerHTML = "{{c.response}} -- 
  
   
   marks:{{c.marks}} -- {{c.errorType=='NA'?'':c.errorType}}"  >  </p>
  
   <app-url-shower *ngIf="c.source" [extLink] = c.extUrl [title] = 'c.title' > </app-url-shower>
  
    <p *ngIf='c.cmntType != "Dans"' innerHTML = "{{c.response}} -{{c.errorType}}"  >  </p>
  
  </div>

  <ng-template #notDone >
    <span *ngIf='c.cmntType == "Dans"' class=" float-right text-info mx-3"> Not Evaulated </span>
    </ng-template>
  
  