import { Component, OnInit } from '@angular/core';

//npm install api-ai-javascript --save-dev

@Component({
  selector: 'app-askbestu',
  templateUrl: './askbestu.component.html',
  styleUrls: ['./askbestu.component.scss']
})
export class AskbestuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  
}
