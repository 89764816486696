
<div class= "container">
    
    <p *ngIf='q.groupId' > groupId: {{q.groupId}} </p>

<!--
<div  *ngIf= 'q.contentImages'  class= "text-center"> 
    <img  src= {{q.contentImages}} alt= "">
     <div>
-->
    <!--class="mx-auto d-block"   class= "text-center"-->
    </div>
