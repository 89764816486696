
<button *ngIf= 'hideList' (click)= 'hideList= !hideList'>Show Chapters</button>

<div *ngIf= '!hideList'  class="card card-primary">
  
    <div class="card-heading">
       
        <button (click)="addToggle()" type="button" class="btn btn-outline-primary" style="margin-left: 10px"> {{showAdd?'Hide Add':'+ Hdr'}} <i class="fa fa-book" aria-hidden="true"></i> </button>
     
     <!--   
      <app-show-header *ngIf="showHeader" [id]=id> </app-show-header>
      <app-update-header  *ngIf="showUpdate" [id]=id> </app-update-header>
    -->
    
      <!--
      <app-update-header  *ngIf="provideResponse" [responseMode]= true [id]=id> </app-update-header>
    
      <app-qstnhdr-show  *ngIf="showHeader" [id]=id> </app-qstnhdr-show>
  -->  
        
      </div>

       
     <app-qstnhdr-add  *ngIf="showAdd" [iHeader] = header> </app-qstnhdr-add>
  
      <div *ngIf= "headers" class="card-body">
        <table class="table table-striped">
          <thead class= "float-left">
            <tr>
              <th># </th>
              
              <th>class</th>
              <th>section</th>
              <th>subject</th>
              
              <th>chapter</th>
              <th>section</th>
              <th>Booster Set</th>
              <th>status</th>
              <th>Active Flag</th>
              <th>Header Id</th>
            </tr>
          </thead>
          <tbody>
            <div *ngFor="let header of headers;let i = index">
            <tr>
             <!-- | async -->
              <td>{{header.id}}</td>
              
              <td>{{header.course}}</td>
              <td>{{header.clsSec}}</td>
              <td>{{header.subject}}</td>
              <td>{{header.chapter}}</td>
              <td>{{header.section}}</td>
              <td>{{header.bstrSet}}</td>
              <td>{{header.status}}</td>
              <td>{{header.current}}</td>
              <td>{{header.headerId}}</td>
             
              <!--
              <td> <app-url-shower *ngIf="header.extUrl" [extLink] = header.extUrl [title] = header.title> </app-url-shower></td>
              
              -->  
         

          <td>

            <app-crud-btn [header] = header >   </app-crud-btn>

            <button *ngIf= "mode ==='select'" (click) = 'expand= !expand'>{{expand?Hide:Expand}} </button> 
         
            
            <app-view-lstr [hdr] = header> </app-view-lstr>

            <div *ngIf="expand">
            <ng-container *ngIf= "mode !=='!select' || (expand ==true) ">
            

          <button (click)="showUpload=!showUpload; headerforTD= header.id;setUpload= i;" class="btn btn-danger">Upload Doc</button>
          
          <app-file-upload *ngIf="showUpload && i==setUpload" [iHeader]= header></app-file-upload>    
           

          <button   class= "mx-2 btn btn-primary  badge text-center"  (click)="selectId(header.id)"> select </button>

          <button (click)="updateStatus(header.id,'status')" class="btn btn-info" style="margin-left: 10px"  >Update Status</button>
          
          <button  class= "mx-2  btn-md btn-info" (click)="listTagDtl = !listTagDtl; headerforTD= header.id;setDtl= i;">Tag Detail</button>

          
         <!--  Implement & show     
         
          <button (click)="deleteHeader(header.id)" class="btn btn-danger">Delete</button>
               
          <button (click)="updateHeader(header.id)" class="btn btn-info" style="margin-left: 10px"  >Update</button>
               
         <button (click)="headerHeader(header.id)" class="btn btn-info" style="margin-left: 10px">Show Headers</button>
        -->
        
        
         <!--       
        <button *ngIf="!header.response && header.message" (click)="respond(header.id)" class="btn btn-info" style="margin-left: 10px" >Respond</button>
              
               
       <a *ngIf="cs.enable('updateQstn') && header.qstnDtlId "  href = '{{cs.getRoot()}}/faces/content-dtl-update.xhtml?qstnId={{header.qstnDtlId}}'
       class="nav-link text-warning px-3 " > <span class= "">update Question  </span>
     </a>
     -->
            </ng-container>
            </div>
              </td>
                      
            </tr>
            
           <tr *ngIf= "i==setDtl" class="float-right">
            <!--
            <app-qstndtl-list *ngIf= 'listTagDtl' ></app-qstndtl-list>
            -->
            
          </tr>
        <!--[tagHdrId]= "headerforTD + ''"-->
          </div>
         
          </tbody>
        </table>
      </div>
  
    
    </div>

