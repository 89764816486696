import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';

import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';




@NgModule({
  declarations: [ChatDialogComponent,],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  exports: [ChatDialogComponent],
  
})
export class ChatModule { }
