import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QhMntComponent } from './qh-mnt/qh-mnt.component';
import { SharedModule } from '../shared/shared.module';
import { QhFldsComponent } from './qh-flds/qh-flds.component';
import { CmntFldsComponent } from './cmnt-flds/cmnt-flds.component';
import { CmntMntComponent } from './cmnt-mnt/cmnt-mnt.component';

import { DocMntComponent } from './doc-mnt/doc-mnt.component';
import { QdFldsComponent } from './qd-flds/qd-flds.component';
import { DataListerComponent } from './data-lister/data-lister.component';
import { MassUpdateComponent } from './mass-update/mass-update.component';
import { HdrExtraComponent } from './hdr-extra/hdr-extra.component';
import { DtlExtraComponent } from './dtl-extra/dtl-extra.component';
import { HdrFldChangerComponent } from './hdr-fld-changer/hdr-fld-changer.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { UdFldsComponent } from './ud-flds/ud-flds.component';
import { UhFldsComponent } from './uh-flds/uh-flds.component';
import { UrFldsComponent } from './ur-flds/ur-flds.component';


@NgModule({
  declarations: [QhMntComponent,QhFldsComponent, CmntFldsComponent, CmntMntComponent,QdFldsComponent,DocMntComponent, DataListerComponent, MassUpdateComponent, HdrExtraComponent, DtlExtraComponent, HdrFldChangerComponent, DropdownComponent, UdFldsComponent, UhFldsComponent, UrFldsComponent, ],
  imports: [
    CommonModule,SharedModule  
  ],
  exports:[QhMntComponent,CmntMntComponent, DocMntComponent,QdFldsComponent,DataListerComponent,MassUpdateComponent,HdrFldChangerComponent,
    DropdownComponent, UdFldsComponent, UhFldsComponent, UrFldsComponent]
})
export class OptionalModule { }
