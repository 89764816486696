export const environment = {
  production: true,
  premiumSignNeeded:true,
  rootUrl:'https://www.examsteppers.com',
  //localUrl:'http://134.209.156.121:8080',
  //localUrl:'http://localhost:8080',
  localUrl:'http://localhost',
  intraUrl:'http://192.168.1.2:8080',
  eduUrl:'https://www.edubestu.com',
  carUrl:'https://www.careersteppers.com',
  intraUrl1:'https://examsteppers',
  dialogflow: {
    angularBot:'bb7456e4a1c74924abf375f9626d3d1a'
    } ,

};
