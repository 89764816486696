import { Component, OnInit } from '@angular/core';
import { UserRole } from '../usrrole.service';

@Component({
  selector: 'app-add-usr-role',
  templateUrl: './add-usr-role.component.html',
  styleUrls: ['./add-usr-role.component.scss']
})
export class AddUsrRoleComponent implements OnInit {
 
  constructor() { }

  ngOnInit(): void {
    
  }

  getNew(){
    let  c: UserRole = new UserRole();
    c.userId='userId'; 
    c.role= 'role'
    c.headerId = null;
    return c;

  }

}
