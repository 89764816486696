import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-whole-qstn-btn',
  templateUrl: './whole-qstn-btn.component.html',
  styleUrls: ['./whole-qstn-btn.component.scss']
})
export class WholeQstnBtnComponent implements OnInit {
@Input() q :any;


  constructor(public cs:ClientInfoService) { }

  ngOnInit(): void {
  }


 
}
