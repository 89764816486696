import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
  @Input() q:any;
  
  constructor() { }

  ngOnInit(): void {
  
    
  }

}
