import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { CntxtService } from '../../pages/cntxt-menu/cntxt.service';
import { QstnDtlService } from '../qstn-dtl.service';

@Component({
  selector: 'app-qstndtl-list',
  templateUrl: './qstndtl-list.component.html',
  styleUrls: ['./qstndtl-list.component.scss']
})
export class QstndtlListComponent implements OnInit {
@Input() qstnHdrId:number;
qstns$: Observable<any>;
noCall :any;
expandI:number= -1;

//data:any;
calling:boolean;


showQDtl:boolean;
constructor(public qds:QstnDtlService,public cm:CntxtService) {
    
      //this.data = {"products":[{"name":"car","product":[{"name":"honda","model":[{"id":"civic","name":"civic"},{"id":"accord","name":"accord"},{"id":"crv","name":"crv"},{"id":"pilot","name":"pilot"},{"id":"odyssey","name":"odyssey"}]}]}]}

  }

  ngOnInit(): void {


if(this.qstnHdrId>0){
 this.calling= true;
 this.qstns$= this.qds.list(this.qstnHdrId) ;
  }
  
}

expand(c,i){
this.expandI==i ? this.expandI =-1: this.expandI=i;

}


getTitle(c){

 return [c.groupId,c.title,c.category,c.instruction].filter(Boolean).join("-"); 
}

}
