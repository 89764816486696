<body>

    <div class="container">
        
        <div class="page_content">
           
            <div class= "text-center" [innerHTML] = 'q.title|safe:"html"'> </div>
            
            <span  *ngIf= 'q.instruction' [innerHTML] = 'q.instruction|safe:"html"'> </span>
            <span *ngIf= 'q.question' [innerHTML] = 'q.question|safe:"html"'> </span>
           

        </div>

        <div class="image" *ngIf= 'q.answerImages;else hdrImg '>
            
            <img class="im" [src]= 'q.answerImages|safe:"resourceUrl"'
            alt="" >
                
        </div>
        
        <ng-template  #hdrImg >
           
            <img class="im" [src]= 'cs.eh.slideImage(h?.extra)|safe:"resourceUrl"'
            alt="" >

        </ng-template>
        
        


        <div class="footer">

            <p class= "pt-4"  [innerHTML] = 'q.answer|safe:"html"'> </p>


        </div>

    </div>

</body>

