import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserWindowService {

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  
  hasWindow(){
 
  return isPlatformBrowser(this.platformId);

  }
  
  getWindow(){
   
  if(isPlatformBrowser(this.platformId)){
    return window;
  } else{
   return null;

   }

}

}