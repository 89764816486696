import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '../message.service';
import { ClientInfoService } from '../shared/client-info.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
 
  constructor(public cs: ClientInfoService,public messageService: MessageService) { }

  ngOnInit() {
      }
  
}
