<ng-container *ngIf='hdr'>


  
  <ng-container [ngSwitch]="hdr.hdrFormat">
    
    <ng-container *ngIf='hdr.status == "asgn" || hdr.status == "wrk"  '>
    <app-test-view-btn *ngSwitchCase='"test"' [hdr]=hdr [i]=i>
     </app-test-view-btn>
    <app-test-view-btn *ngSwitchCase='"both"' [hdr]=hdr [i]=i>
    </app-test-view-btn>
    
    <app-test-view-btn *ngSwitchDefault [hdr]=hdr [i]=i>
    </app-test-view-btn>
   </ng-container>

    <app-hdr-url-shower *ngSwitchCase='"fullUrl"' [h]=hdr> </app-hdr-url-shower>

    <app-url-shower *ngSwitchCase='"url"' class="col-2" [extLink]=hdr.extUrl
      [title]='"-"'> </app-url-shower>
  </ng-container>

  <app-status-based-view [hdr]=hdr [i]=i> </app-status-based-view>

  <app-cntnt-availer [hdr]=hdr [i]=i> </app-cntnt-availer>


  <button *ngIf="(vs.cat.includes('-bldr') && !grpAsoId && 
  (enable('grpAso',i,hdr) ) ) " class="badge"
  (click)="vs.showGrpAso(hdr)">associated to Group</button>



  <!--
  <ng-container *ngIf="more">
    <button *ngIf="enable('moreBtn',i,hdr) " class="badge"
      (click)="more= !more">... </button>
  </ng-container>
  -->

</ng-container>