import { Component, Input, OnInit } from '@angular/core';
import { Detail } from '../../qstndtl/detail';
import { ClientInfoService } from '../../shared/client-info.service';
import { ConfigService } from '../../shared/config.service';
import { ConfirmModalService } from '../../shared/confirm-modal.service';
import { Edi } from '../dtl-extra/edi';

@Component({
  selector: 'app-ud-flds',
  templateUrl: './ud-flds.component.html',
  styleUrls: ['./ud-flds.component.scss']
})
export class UdFldsComponent implements OnInit {

  @Input()  fldList:string[];
  @Input()  ud:any;
  @Input() edi:Edi;
  

  get justOffering() {return this.cs.getJustOffering()}
  
  constructor(private confirmModalService: ConfirmModalService, public cfg:ConfigService ,private cs:ClientInfoService) { }
  
  ngOnInit(): void {
  }
  
  enable(fld: string){
  //return true;
    if(this.fldList?.includes('-')){
      if(this.fldList.includes('all')){return false}  
      return !this.fldList.includes(fld);
      }  
  
  if(this.fldList?.includes('all')){
    if(fld.startsWith(this.justOffering)){return false}
    return true}  
  
    if(this.ud.status && this.ud.status.startsWith('dig')){
      if(fld.startsWith("dig")){return true}
      return false}  
  
      
  return this.fldList.includes(fld);
  }
  
  
  public help(fld:string) {
    this.confirmModalService.help('Please enter char after "v="', ' https://www.youtube.com/watch?v=<span class="bg-info">bKKJkxqIg94</span>');
  }
  
  
  }
  
