
<ng-container *ngIf= 'cs.enable("ofrBtn","edit")'> 
  <!--
  debug? {{cs.enable("dflt","debug")}}
  -->
<button *ngIf= 'h && cs.enable("hdrViewBtn","editHdr",null,h)'  class="btn btn-outline-secondary badge border-0  mb-2 mr-2 float-right"
(click)= 'cs.navigate("crud",h.type + "/hdr/"+ h.id)' >h-{{h.id}}</button>


<button *ngIf= "d"  class="btn btn-outline-secondary badge border-0  mb-2 mr-2 float-right"
(click)= 'cs.navigate("crud","cfg" + "/dtl/"+ d.id)' >d-{{d.id}}</button>

<button *ngIf= "subDesc"  class="btn btn-outline-secondary badge border-0  mb-2 mr-2 float-right"
(click)= 'cs.navigate("crud",subDesc.type + "/hdr/"+ subDesc.id)' >subDesc-{{subDesc.id}}</button>

</ng-container>

<button *ngIf= "d && d.instruction"  class="btn btn-outline-info badge border-0  mb-2 mr-2 float-right" (click)="cs.help(longContent)">?</button>




<ng-template #longContent let-modal>
    <div class="modal-header">
      <h4 class="modal-title">{{d.instruction}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    
    <div class="modal-body" innerHTML= {{d.content}}>
    </div>
    <div class="modal-body" innerHTML= {{d.answer}}>
    </div>
    View Note:
    <div class="modal-body" innerHTML= {{d.digInstrctn}}>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
  </ng-template>
