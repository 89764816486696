

<!--
<div *ngIf = "jumboHdr && headerObj" class= "jumbotron text-center" innerHTML= "<h2>{{headerObj.title}} </h2>" > 
Keep watching new Video daily and upgrade!
</div>
-->


<app-headings-plain *ngIf = "jumboHdr && headerObj" [hdr] =headerObj ></app-headings-plain>

{{headerObj?.id}} 

<div class= "py-2">
<p  *ngIf="!jumboHdr && headerObj" class="lead text-center " [innerHTML] = 'headerObj.title'> </p>

<div class= "container row">
<li class= "col-6 list-unstyled" *ngFor="let q of questions; let i = index;">
  <span class="font-weight-bold"> #{{formatSeq(q.sequence,q.subSeq,q.part)}}
  </span>
   <p *ngIf="q.instruction" class="lead font-weight-bold font-italic" [innerHTML] = q.instruction></p>
 <p *ngIf="q.content " class="" [innerHTML] = "q.content"></p>
 <p *ngIf="q.wireFrame " class="" [innerHTML] = "q.wireFrame|safe :'html'"></p>

 <div *ngIf="q.vidLink" class="embed-responsive embed-responsive-16by9">
 
  <iframe class="embed-responsive-item" [src]="(utube + q.vidLink)|safe :'resourceUrl' " allowfullscreen></iframe>
 </div>

  <div id=[i] class="text-center" *ngIf="q.vidLink" style="display:none" >
    <a [href]="updateVideoUrl('tubeE',q.vidLink)"
     class="answersc text-nowrap" target="_blank" ><img   class= "normal-img" alt= "" src="https://img.youtube.com/vi/{{q.vidLink}}/hqdefault.jpg"/><br> Play Video</a>
  </div>

<div  *ngIf="q.extLink &&q.extLink.lastIndexOf('http', 0) === 0; else intlink" class="text-center"  >
 <a href={{q.extLink}}  target="_blank" >{{q.title}} link</a>
 </div>

 <ng-template class = "text-center text-yellow" #intlink>
   <div  *ngIf="q.extLink">
   <a routerLink={{q.extLink}}><button>{{q.title}} ! </button> </a>

 </div>
 </ng-template>

<div [id] = i  *ngIf="q.answer && (showA || showAnsI == i )" class="answersc  "
 innerHTML = " <br /> Ans : {{ q.answer }}  <br> ">
 </div>
</li>

</div>
</div>
