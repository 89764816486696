import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { CommentService } from '../comment.service';
import { Comment } from '../comment';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-crctn-rcmnd-add',
  templateUrl: './crctn-rcmnd-add.component.html',
  styleUrls: ['./crctn-rcmnd-add.component.scss']
})
export class CrctnRcmndAddComponent implements OnInit {
  @Input() i:number;
  @Input() hdrObj?:any;
  @Input() parentFormGroup:FormGroup; 
 submitted:boolean;

  
  
  @Input() cmntType: string;
  
  @Input() ovrClsSec?: any;
  @Input() parentDtlId?: number;
  


  @Output('update')
  change: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  c:Comment; 

  get offering() { return this.cs.getOffering()}


  constructor(private cmntSrv: CommentService,private cs:ClientInfoService) { 
    //console.log("hi " +this.cmntType);
    
    let c= new Comment();
    c.domain= cs.getSite();
    

    c.clsSec = this.ovrClsSec?this.ovrClsSec:this.cs.getCrtClsSec();
    c.parentDtlId= this.parentDtlId;
       
    c.parentHdrId = +cs.getChapter();
    c.rspnseUser= cs.getSignedUser();
    c.domain= cs.getSite();
    
    c.subject = cs.getSubject(); 
    c.course = cs.getCourse();  
    c.featureType = cs.getFeature()?cs.getFeature():cs.getOffering();
    
    this.c = c;
   
   // console.log(c); 
    } 
  

  ngOnInit(): void {

    this.c.cmntType= this.cmntType;
    this.c.owner= this.hdrObj.owner;
    

  }

  onSubmit(c) {
    this.cmntSrv.createComment(c)
      .subscribe(data => 
    {this.cs.addMsg((data as any).message)
    this.change.emit(true);
    this.submitted= true;
    }, error => console.log(error));
  }

}
