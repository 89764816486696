import { Component, Input, OnInit } from '@angular/core';
import { HeadingsService } from '../../shared/headings/headings.service';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss']
})

/**
   * set the product count by its type
   * @param {string} type : ProductType Name
   * @param {number} count : ProductType Count
   * @public
   */
export class SeoComponent implements OnInit {
  @Input() h:any;
  @Input() q: any;


  constructor(public hdng:HeadingsService) { }


  /**
 * @usageNotes
 * ### Retrieve a nested control
 * For home seo- title comes from menu-hdr
 * for other - ofr or hdr or combo gives
  */

  ngOnInit(): void {
    //console.log(this.hdng.cs.getCat(), this.h);
   
    if( this.hdng.cs.getCat()!="home" ||  
    
    (this.hdng.cs.getCat()=="home" && this.h.subject =="menu"
    )){
     // console.log(this.hdng.cs.getCat(), this.h.subject);
      //console.log(this.h.title, this.h.subject);
    this.hdng.setSEO(this.h,this.q)
    }
  }

  ngOnChanges(): void {
    if( this.hdng.cs.getCat()!="home" ||  
    
    (this.hdng.cs.getCat()=="home" && this.h.subject =="menu"
    )){
      
    this.hdng.setSEO(this.h,this.q)
  }
}


}
