<div class="text-center mb-1">
  <button class="btn btn-outline-primary bade border-0" type="button"
    (click)="showCrctn=!showCrctn">Correction & Continuous Improvement</button>
</div>

 
<nav *ngIf="!showCrctn"  class="nav1bar">

  <ng-container  >

<div [hidden]="submitted">
  <form (ngSubmit)="onSubmit()">

    <div class= "row"> 
      
         
     <!-- 
     <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="c.correct"  >
      <label ngbButtonLabel class="btn-primary mx-1" >
        <input ngbButton type="radio" [value]="true" (click) = 'respondNew(true)' > &#10004;
      </label>
     
      <label ngbButtonLabel class="btn-secondary text-danger">
        <input ngbButton type="radio" [value]="false" (click) = 'respondNew(false)'> &#10008;
      </label>
    </div>
    
    
    enable("evaluate")  style="margin-left: 10px" 
    -->
    <button  type= "button" (click)="respondNew(true)" class="mx-2 btn btn-lg btn-outline-secondary badge text-primary "  > Tick &#10004;</button>

   <button type= "button" (click)="respondNew(false)"class="btn btn-md  btn-outline-secondary text-danger" > Tick &#10008;</button>


      <ng-container *ngIf="!c.correct">
     
        <label class="ml-2"  for="errorType">Mistake-Type:</label>
       
        <div  class="col-3 form-group">
       
        <select class="form-control" id="mistakeType" [(ngModel)]="c.errorType"
        name='mistakeType' #mistakeType="ngModel" required>
       
        <option *ngFor="let t of mistakeTypes" [value]="t.name">{{t.desc}}</option>
      
      </select>
      
    </div>
    </ng-container>

      <label for="marks">Marks:</label>
      <div  class="col-3 form-group">
     <input class="form-control" type="number" step ="0.5"  id="marks" min="1.0" max="10.0" [(ngModel)]="c.marks" name="marks"  value = '5.0' placeholder = "multiple of 0.5">
    </div>
      

      <button  type= "button" (click)="showFeedback=!showFeedback;" class="btn badge btn-secondary mr-3" >add feedback </button>

      <button type="submit" class="btn btn-primary">Submit</button>
     
      </div>

      <div class= "row col-12" *ngIf= "showFeedback">
        <div class="form-group">
          <label for="response">.</label>
        <ckeditor *ngIf="ls.enable('ckeditor',comment)"  [(config)]="cfg.withWordConfig"
        #editor debounce="500"
          [(ngModel)]="c.response"
          id="response"
          name="response" >
        </ckeditor>
            
        <textarea *ngIf="!ls.enable('ckeditor',comment)" type="text" class="form-control" id="response" required [(ngModel)]="c.response" name="response">
        </textarea> 
        
        </div>
        
      </div>
       
      <!--
      <button type="button"  (click)="toggleUpload=!toggleUpload" class="btn btn-info mx-1">{{toggleUpload?'Hide':'Upload'}}</button>
      <app-file-upload *ngIf= 'toggleUpload' [iComment] = comment > </app-file-upload>
        -->   
          

      
      <!--
      <button type="button" (click)= "gotoList()"  class="btn btn-ino">Go Back</button>
      -->  

    </form>
  </div>
</ng-container>
</nav>
