
<!--
<span class= "badge,text-small,font-italic">  Student's Answer: </span>
-->

<app-comment-list  [featureType] = qs.offering [cmntType]= "'Dans'" [parentHdrId] = qs.chapter [parentDtlId] = q.id [commentsI] = q.ansCmnts [hideTitle] = true ></app-comment-list> 

<!--
<div class="answersc text-success" 
innerHTML = "Suggested Ans : {{ q.answer }}"  >
-->