import { Component, Input, OnInit } from '@angular/core';
import { Header } from '../../tag/header';

@Component({
  selector: 'app-show-hdrgrp',
  templateUrl: './show-hdrgrp.component.html',
  styleUrls: ['./show-hdrgrp.component.scss']
})
export class ShowHdrgrpComponent implements OnInit {
  @Input() hdr:any;
  
  constructor() { }
  ngOnInit(): void {
  }

}
