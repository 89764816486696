import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../client-info.service';
import { HeaderService } from '../../tag/header.service';
import { FormsModule } from '@angular/forms';

import { FixedDataService } from '../fixed-data.service';
import { Header } from '../../tag/header';
import { ExtraInfo } from './extra-info';
import { PrepAsgnrService } from './prep-asgnr.service';

@Component({
  selector: 'app-assigner',
  templateUrl: './assigner.component.html',
  styleUrls: ['./assigner.component.scss']
})
export class AssignerComponent implements OnInit {
  @Input() hdr:Header;
  @Input() status : string;
  @Input() store: boolean;
  
  ei:ExtraInfo; 
  success: boolean;
  
  constructor(public pa:PrepAsgnrService,private cs: ClientInfoService) { }

  ngOnInit(): void {
  
    if(this.hdr){
  this.ei= this.pa.prePrepEI(this.hdr,'asgn');
    }
    //dhw
  

 // console.log(this.ei);
  }

  
  submit() {
   let data= this.pa.postEI(this.hdr,this.ei).subscribe(data => {
    if ((data as any).status === 200) {
      this.cs.addMsg("successful", 'info');
    //  console.log(data);
      this.success= true;
      return data;
      
      } else {
      this.cs.addMsg("error,try later", 'warning');
    }
  },
  error=> {
    this.cs.addMsg("error,try later", 'warning');
      
  }
  
  );
   //alert("got response"+ data);
      }
    

}
