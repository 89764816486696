import { Component, Input, OnInit } from '@angular/core';
import { ViewService } from '../view-lstr/view.service';

@Component({
  selector: 'app-status-based-cmn-view',
  templateUrl: './status-based-cmn-view.component.html',
  styleUrls: ['./status-based-cmn-view.component.scss']
})
export class StatusBasedCmnViewComponent implements OnInit {

  @Input() hdr: any;
  @Input() i: number;
  
  constructor(public vs: ViewService ) { }


  
  ngOnInit(): void {
  }

}
