import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecSlctrService {

public static allSelectedI: Subject<boolean> = new Subject();


  constructor() { }
}
