

  import { Component, Input, OnInit } from '@angular/core';
  import { ClientInfoService } from '../../shared/client-info.service';
  import { HeaderService } from '../../tag/header.service';
  import { QstnhdrService } from '../qstnhdr.service';
  
  @Component({
    selector: 'app-cntnt-availer',
    templateUrl: './cntnt-availer.component.html',
    styleUrls: ['./cntnt-availer.component.scss']
  })
  export class CntntAvailerComponent implements OnInit {
    @Input() hdr: any;
    @Input() i: number;
    
  
    constructor(public cs: ClientInfoService,private ths: HeaderService, private qhs: QstnhdrService)
    {}
  
  
    ngOnInit(): void {
    }
  

    makeCmn(hdr: any, i: number) {
    let tagBased = false;
      if(tagBased) {
        this.makeCmnTH(hdr,i);
      }else{
        this.makeCmnQH(hdr,i);
      }
    
    }


    makeCmnQH(hdr: any, i: number) {
      hdr.site= null;
      hdr.school= null;
      hdr.location = null;
     this.qhs.update(hdr.id, hdr)
        .subscribe(
        data => {
             if ((data as any).status === 200) {
          console.log("Success:change status done");
          this.cs.addMsg("Resource made common for other branches");
        } else {
        this.cs.addMsg("response with error received","warning");
      };
       }
       ,error => console.log(error) );
       this.cs.addMsg("something went wrong");
    }
  

  makeCmnTH(hdr: any, i: number) {
      hdr.site = null;
      hdr.school = null;
      hdr.location = null;
      hdr.addUser = this.cs.getSignedUser();
  
  this.ths.update(hdr.id, hdr)
      .subscribe(error => console.log(error));
      this.cs.addMsg("Resource made common for all sites");
  }
  
  
  
    copy(hdr: any, i: number) {
      hdr.site = this.cs.getSite();
      hdr.school = null;
      hdr.location = null;
      hdr.addUser = this.cs.getSignedUser();
      //hdr.owner = "System"
      if (!hdr.prntHdrId) {
        hdr.prntHdrId = hdr.id;
        hdr.id = null;
      }
      this.qhs.create(hdr)
        .subscribe(error => console.log(error));
      this.cs.addMsg("Resource copied for new site");
    }
  
  
  }
  