import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsrhdrLstComponent } from './usrhdr-lst/usrhdr-lst.component';

import { FormsModule } from '@angular/forms';
import { UiModule } from '../ui/ui.module';
import { CKEditorModule } from 'ckeditor4-angular';
import { SharedModule } from '../shared/shared.module';
import { ManageSubscrptnComponent } from './manage-subscrptn/manage-subscrptn.component';
import { ChgPwdComponent } from './chg-pwd/chg-pwd.component';
import { UsrdtlUpdateComponent } from './usrdtl-update/usrdtl-update.component';
import {CaptchaModule} from 'primeng/captcha';
import { SignupComponent } from './signup/signup.component';
import { ManageUsrComponent } from './manage-usr/manage-usr.component';
import { SignuploginComponent } from './signuplogin/signuplogin.component';
import { UsrdtlListComponent } from './usrdtl-list/usrdtl-list.component';
import { AddSiteComponent } from './add-site/add-site.component';
import { UsrRoleComponent } from './usr-role/usr-role.component';
import { AddUsrRoleComponent } from './add-usr-role/add-usr-role.component';
import { OptionalModule } from '../optional/optional.module';
import { StaticModule } from '../static/static.module';
import { UpdateSiteComponent } from './update-site/update-site.component';

@NgModule({
  declarations: [UsrhdrLstComponent, ManageSubscrptnComponent, ChgPwdComponent, UsrdtlUpdateComponent,SignupComponent,ManageUsrComponent,SignuploginComponent, UsrdtlListComponent, AddSiteComponent, UsrRoleComponent, AddUsrRoleComponent, UpdateSiteComponent,],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    CKEditorModule,
    //app-manage-subscrptnUiModule,
    CaptchaModule,
    OptionalModule,
    StaticModule
  ],
  exports:[UsrhdrLstComponent,SignupComponent,ManageUsrComponent
    ,SignuploginComponent,ManageSubscrptnComponent,AddSiteComponent,UpdateSiteComponent ],
  providers: [],
  
})
export class UserModule { }
