

<div *ngIf="!unsigned && !hideSigned" class="text-center lead mt-0">
      Welcome {{signedUser}} , enjoy digibest features with AIMagic!

      <br>

      <a (click)="signout()"> <button><span
                        class="mx-2 btn btn-secondary badge">Sign
                        out</span></button> </a>


      <!--
<a routerLink= {{getSignUpUrl()}}><button > <span class="btn btn-primary badge">Parent Sign Up </span></button> </a>
-->

      <!--

<ng-container *ngIf= 'signedUser=="prabhash"'>
<a class="btn btn-info mx-2 " [routerLink] = "['/',site,'admin']" >Admin</a>
</ng-container>
-->


      <app-manage-subscrptn> </app-manage-subscrptn>

      <!--
<button type="button" (click) = 'showLearnMode =!showLearnMode' class="btn btn-primary badge" > change Learn-Mode </button> 
-->
</div>

<div *ngIf="unsigned" class="text-center  mt-0">


      
            <div class="ml-1 text-center">
                  <span class="mx-1">Free </span>
                  <a class="btn btn-primary badge mx-2 btn-md"
                        routerLink={{getSignUpUrl()}}> Sign Up </a>
                 
                 <span class="font-italic text-small"> for digiBest experience </span>
                 
                 <span *ngIf = "cat != 'mng'">
                  <span class="mx-1">or </span>
                  <a class="btn btn-md mx-2 btn-primary badge"
                        (click)="signin()"> Sign In </a>
                  </span>     
                  
            </div>

       </div>

         <!--
            <div class="col-4 col-md-5">
                  <span class="m-2">
                        <a class="btn btn-secondary-link badge "
                              routerLink={{togetherUrl()}}> personalized
                              learning </a>
                        </span>
            </div>
      -->

      <!-- font-italic -->
      <span class="text-center  text-primary"> </span>

      <!--
            <button type="button" (click) = 'showLearnMode =!showLearnMode'  class="btn btn-primary badge" >select Learn-Mode </button> 
            </p>
            
            <div class= "font-italic" *ngIf="cat !=='exams'" >** Note: we have time saving personalized Navigation like select exam(board & class) automatically etc. for signed in User </div>
      </div>
-->

      <!--
<app-learn-mode *ngIf= "showLearnMode">  </app-learn-mode>
-->
