import { Component, OnInit ,Input} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ExamDataService } from '../../exam-data.service';
import { BoardDataService } from '../../board-data.service';
import { TagService } from '../../shared/tag.service';
import { SimpleChanges } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { CommentService } from '../comment.service';
import { Comment } from '../comment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @Input() qstnId : string;
  @Input() headerId : string;
  @Input() source : string;
  @Input() type : string;
  @Input() myTopic : string;
 
  commentForm: FormGroup;
  submitted = false;
  success = false;
  comment :Comment;
  name1:string;
  dfltTopic:string= "Feedback";
  outData: any;
  get signedUser() {return this.cs.getSignedUser()}
  get unsigned() {return this.cs.isUnsigned()}
  
  
  constructor(private formBuilder: FormBuilder,private router:Router, private commentService: CommentService,private boardService: BoardDataService,private cs:ClientInfoService) {
  
    this.commentForm = this.formBuilder.group({
      name: ['', ],
      message: ['', ],
      email:['', ],
      topic:['', ],
      phone:['', ]
    }); 
  
  }

  ngOnInit() {


    //Validators.required
    
    //initialzing .. use patchValue or below 
    if(!this.unsigned){
    this.commentForm.get('name').setValue(this.signedUser);
    }
     
    if(this.myTopic){
      this.dfltTopic= this.myTopic;
    }
    this.commentForm.patchValue({
      topic: this.dfltTopic,
     // name: this.signedUser
   });

  }

  ngOnChanges(changes: SimpleChanges) {
   
    if(this.myTopic){
      this.dfltTopic= this.myTopic;
    }
    this.commentForm.patchValue({
      topic: this.dfltTopic,
     // name: this.signedUser
   });

  }


onSubmit() {

    this.submitted = true;
    
    if (this.commentForm.invalid) {
        return;
    }
    
    this.comment = new Comment();
    this.comment.addUser= this.signedUser;
   this.comment.addUser= this.commentForm.controls.name.value;
   this.comment.email= this.commentForm.controls.email.value;
   this.comment.comment= this.commentForm.controls.message.value;
   this.comment.phone= this.commentForm.controls.phone.value;
   this.comment.parentHdrId= +this.headerId;
   this.comment.parentDtlId= +this.qstnId;

   this.comment.source=this.source;
   this.comment.topic= this.commentForm.controls.topic.value;
   this.comment.cmntType= this.type?this.type:"Hnew";


    /*
    const commentPayload = {
      name: this.commentForm.controls.name.value,
      email: this.commentForm.controls.email.value,
      message: this.commentForm.controls.message.value ,
      phone: this.commentForm.controls.phone.value,
      parentId: this.qstnId,
      parentHdrId: this.headerId,
      parentDtlId: this.qstnId,

      source:this.source,
      topic: this.commentForm.controls.topic.value,
      type: this.type
    }
   */
 
    this.commentService.createComment(this.comment)
      .subscribe(data => 
        {
          console.log(data);
          this.outData= data;
    this.cs.addMsg((data as any).message)
    
    //this.comment = new Comment();

    }, error => console.log(error));
    

}

changePwd(ud:any){
  let route = [ud.domain,"usr","chgpwd",ud.headerId] 
this.router.navigate(route);

}

}
