import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BoardDataService } from '../../board-data.service';
import { ExtraHdrService } from '../../gen/extra-hdr.service';
import { ChptrService } from '../../pages/chptr-lstr/chptr.service';
import { ClientInfoService } from '../client-info.service';
import { HeadingsService } from '../headings/headings.service';

@Component({
  selector: 'app-headings-plain',
  templateUrl: './headings-plain.component.html',
  styleUrls: ['./headings-plain.component.scss']
})
export class HeadingsPlainComponent implements OnInit {
  @Input() hdr:any;
  @Input() ftrDescObj:any;
  @Input() q: any;
  @Input() noSeo :boolean;
  expand:boolean= true;
  updateSeo :boolean = true;

  get cat() { return this.cs.getCat()}
  get digibest() { 
    var principal = JSON.parse(localStorage.getItem('principal'));
    if(principal && principal.authorities){
    const index = principal.authorities.findIndex(item => item.authority === "digi_expert");
    //console.log("index is ", index)
    if (index > -1) {
    return true
    //this.cs.getUserGroup() && this.cs.getUserGroup().includes("digi_expert")} 
    } }
    else{
      return false;
    }
  }
    constructor(public cs:ClientInfoService ,private modalService: NgbModal,public cSrv:ChptrService,public eh:ExtraHdrService,public hdng:HeadingsService ,private dataService: BoardDataService
    ) { }

    ngOnInit(): void {
      
      if(this.noSeo || this.cat =='home'){this.updateSeo= false} 

      this.cs.log(this.hdr,'headings-plain,init');
      this.cs.log(this.q , 'headings-plain,init');
    this.hdng.updateData(this.hdr,this.q)
  }

  ngOnChanges(){
    if(this.noSeo || this.cat =='home'){ this.updateSeo= false}
    this.hdng.updateData(this.hdr,this.q);
  //this.hdng.setSEO(this.hdr,this.q)
 }
  
   

openScrollableContent(longContent) {
  this.modalService.open(longContent, { scrollable: true });
}

enable(btnId:string){
  return this.cs.enable('headings',btnId)
}



}
