
<nav class="navbar navbar-dark bg-primary text-warning  ">


   <a href="/" class="navbar-brand" ><img src="assets/img/es-c.png"> Edu Best U </a>
<!-- need to be fixed
 <a href="/" class="navbar-brand" ><img src="assets/img/es-c.png"> {{this.siteHead}} </a>
-->


   <a  *ngIf="board" class="nav-link text-light" [routerLink] = "['/eba','exams']"  >First Step</a>
<!--
   <a  class="nav-link text-light fa fa-umbrella Fs100 Red" routerLink="/es/features/all"> Step by Step</a>
   -->
   <a  class="nav-link text-light fa fa-umbrella Fs100 Red" routerLink="['/eba','learner']"> Step by Step</a>
   <a class="  nav-item text-light nav-link  " routerLink="/esa/ICSE/X/mpb" >Book in Side<!-- REVIEW:  --></a>

   <a   class="  nav-item text-light nav-link  " routerLink="thought">Energize</a>

 <a class="nav-link text-white  "   href="https://examsteppers.com/faces/index.xhtml">Contentize</a>
<!--
   <a  class="nav-link text-light" [routerLink] = "['esa/home']" fragment="" >  About Us </a>
-->

   <a class="nav-link text-white "   routerLink="/csa/*/*/car">Think Ahead</a>



 <button class="navbar-toggler btn-yellow " type="button"  (click)="toggleNavbar()">
    <span class="navbar-toggler-icon btn-outline-success btn-yellow "></span>
  </button>



  <div class="collapse navbar-collapse text-right text-warning "
[ngClass]="{'show': navbarOpen }"
  >





    <ul class="navbar-nav mr-auto "  (click)="toggleNavbar()" >
    <li class="nav-item">
      <a class="text-light  nav-item nav-link" routerLink="pyq">{{board}}-10 Yrs Questions & Answers</a>


<li><a  class="nav-link text-light" routerLink="hwh"> {{board}} Homework Helper</a>

</li>


<li>

<a  class="nav-link text-light" routerLink="/es"> Pick Board-Course </a>

        <a  class="nav-link text-light fa fa-umbrella Fs100 Red" routerLink="/es/features/all"> Our Offerings</a>

<a   class="  nav-item text-light nav-link  " routerLink="/esa/common/beyondbooks/vid/Motivation/17301">Motivator</a>

        <a class="nav-link text-white "   routerLink="/csa/*/*/car">Career Steppers</a>


      </li>
      <li><a class="  nav-item text-light nav-link  " routerLink="/esa/ICSE/X/mpb">Most Popular Books </a>


     <li class="nav-item" >
    <a   class="  nav-item text-light nav-link  " routerLink="esa/common/ /nws/ /726">News & Beyond</a>
</li>
        <li class="nav-item">
        <a class="nav-item nav-link text-white"  routerLink="thought">Thought of the day</a>
      </li>


     <li class="nav-item">
     <a class="nav-link text-white  "   href="https://examsteppers.com/faces/index7.xhtml?i=4">Alternate view</a>
     </li>

<!-- data-toggle and target open in new windows.  good for external link -->
<li class="nav-item">
        <a  data-toggle="collapse" data-target=".navbar-collapse.show" class="nav-link text-white"  href="https://www.officeholidays.com/countries/india/index.php"  target="_blank">Holiday List</a>
      </li>

      <li class="nav-item">
        <a class="nav-link text-white "   routerLink="contact">Contact Us</a>
      </li>





     <li class="nav-item">
        <a class="nav-link text-white "   routerLink="signup">Sign Up</a>
      </li>


    </ul>


  </div>



  <div style="display: none;" class="navbar-expand ml-auto navbar-nav">
    <div class="navbar-nav">

      <a class="nav-item nav-link" href="https://twitter.com/edubestu" target="_blank">
        <i class="fa fa-twitter"></i>
      </a>

    </div>
  </div>
</nav>
