  import { Injectable, Inject, Optional } from '@angular/core';
   import { DOCUMENT } from '@angular/common';
  //import { STYLE_HOST } from 'app/common';
  @Injectable({
    providedIn: 'root'
  })

  export class StyleService {
private stylesMap: Map<any, Node> = new Map();
private host: Node;
  constructor(
  //  @Optional() @Inject(STYLE_HOST) private host: Node
  ) {
    if (this.host === null) {
      this.host = document.head;
    }
  }

  createStyleNode(content: string): Node {
    const styleEl = document.createElement('style');
    styleEl.textContent = content;
    return styleEl;
  }

  has(key: any): boolean {
    return this.stylesMap.has(key);
  }

  addStyle(key: any, style: string): void {
    const styleEl = this.createStyleNode(style);
    this.stylesMap.set(key, styleEl);
    this.host.appendChild(styleEl);
  }

  removeStyle(key: any): void {
    //console.log(this.stylesMap);
    //console.log("....styleMap");
    const styleEl = this.stylesMap.get(key);
    if (styleEl) {
      this.stylesMap.delete(key);
      this.host.removeChild(styleEl);
    }
  }
}
