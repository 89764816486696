import { Component, OnInit, Input } from '@angular/core';
import { NgModule, ElementRef, Injectable } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { ReactiveFormsModule, FormControl, FormsModule } from "@angular/forms";
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { QuestionE } from '../../questione';

import { RouterEvent, NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';

import {
  HttpClientJsonpModule,
  HttpClientModule,
  HttpClient
} from "@angular/common/http";
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { environment } from './../../../environments/environment';
import { OfrDtlService } from '../../shared/headings/ofr-dtl.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalService } from '../../shared/confirm-modal.service';
import { RoleChangerService } from '../../user/role-changer.service';


@Component({
  selector: 'app-offeringlist',
  templateUrl: './offeringlist.component.html',
  styleUrls: ['./offeringlist.component.scss']
})
export class OfferinglistComponent implements OnInit {
  @Input() expanded: boolean;
  //category defaulted to url navigation, put category/feature value and it will show that.
  //by default feature will get category-submenu and feature listing..
  //if u want to override.. send feature in category below input parm.
  @Input() category: string;
  @Input() subMenu: boolean;
  @Input() hideTitle: boolean;
  @Input() showList: QuestionE[];
  categoryComp: string;
  readonly premiumSignNeeded = environment.premiumSignNeeded;
  hdrTitle: string;
  title: string;
  headerId: number;
  headerIdPrem: number;
  headerObj: any;
  questions: any;
  headerLearn: any;
  questionsLearn: any;
  headerPrem: any;
  questionsPrem: any;
  moreI: number;


  site: string;
  //cat: string;
  // board:string;
  //course:string;
  feature: string;
  offering: string;
  subject: string;
  chapter: string;
  content: string;
  showBoards: boolean;
  offering2: string;

  boardNeeded: boolean;
  navigate: boolean;
  ftrDescObj: any;
  ftrDescObjDummy: any;

  expanded1: boolean //false can be removed

  signedUser: string;


  get userGroup() { return this.cs.getUserGroup() }
  get usrSubject() { return this.cs.getUsrSubject() }
  get board() { return this.cs.getBoard() }
  get course() { return this.cs.getCourse() }
  get cat() { return this.cs.getCat() }

  get sem() { return this.cs.getSem() }

  get justSite() { return this.cs.getJustSite() }
  get unsigned() { return this.cs.isUnsigned() }
  get betaViewer() { return this.cs.betaViewer() }
  get engage() { return this.cs.enable("ofrList", "engager") }

  // only for ... text view
  isExpand: boolean = true;

  isExpandToggle() {
    this.isExpand = !this.isExpand;
  }

  constructor(private route: ActivatedRoute, public cs: ClientInfoService, private fd: FixedDataService, private modalService: NgbModal,
    private router: Router, private elementRef: ElementRef, private dataService: BoardDataService,
    public ods: OfrDtlService, private roleChanger: RoleChangerService
  ) {


  }


  openScrollableContent(longContent) {
    this.modalService.open(longContent, { scrollable: true });
  }

  isExternalLink(extLink: string) {
    return this.cs.isExternalLink(extLink);
  }

  more(i: number) {
    if (this.moreI == i) {
      this.moreI = -1
    } else { this.moreI = i; }

  }

  authorized() {
    if (!this.premiumSignNeeded || (this.signedUser && this.signedUser != 'Guest')) {
      return true;
    }
    else {
      return false;
    }
  }



  //index and record is optional
  enable(btnId: string, i?: number, rec?: any) {

    // special for multiple role based user
    if (this.cat == "roles") {
      let grpBtnInp = this.cs.ss.getRoleOptions();
      if (grpBtnInp && grpBtnInp.includes(btnId)) {
        return true
      }
      else {
        return false;
      }
    }


    if (btnId == "astore") {
      return false;
    }


    if (btnId == "owner" && this.cs.isOwner()) {
      return true;
    }


    if (rec.subSeq && rec.subSeq == 'NoOfrLst') {
      return false;
    }
    if (rec.status && rec.status == 'hide') {
      return false;
    }

    let component = 'ofrList';
    let enable = this.cs.enable(component, btnId, rec);
    return this.customEnable(btnId, enable, i, rec);
  }

  customEnable(btnId: string, enable: boolean, i?: number, q?: any) {

    if (enable) {

      if ((btnId === 'booster-cstm') && this.board) {
        if (!(this.cs.getCourse() == 'X' || this.cs.getCourse() == 'XII')) {
          return false;
        }
      }



      //-site .. sensitive to site check btn... let site true continue for other check
      if ((btnId && btnId.includes('-site')) && this.site) {
        let bc = this.justSite;
        if (q.vidLink) {
          if (q.vidLink.includes(bc) || q.vidLink == "site") {
            //return true;
          }
          else { return false }
        }
        if (q.wireFrame) {
          if (q.wireFrame.includes(bc)) {
            return false;
          } else {
            //  return true 
          }
        }
      }


      if ((btnId && btnId.includes('-cls')) && this.board) {
        //let bc = this.course + "-" + this.board;
        let bc = [this.course, this.sem, this.board].filter(Boolean).join("-");
        // console.log("bcs is " , bc);
        if (q.digOptns) { if (q.digOptns.includes(bc)) { return true; } else { return false } }
        if (q.digAnsr) { if (q.digAnsr.includes(bc)) { return false; } else { return true } }
      }







    }

    return enable;

  }

  get toolTipFlag() {
    if (this.userGroup && this.userGroup.includes('app_admin')) {
      return true;
    }
    else {
      return false;
    }
  }

  getTooltip(i: number, q: any) {
    if (this.toolTipFlag) {
      return [q.section, "-", q.groupId].filter(Boolean).join("");
    } else {
      return "";
    }
  }


  ownerGroup(part: string) {
    //this.userGroup= "[OWNER]";
    if (this.userGroup && this.userGroup.includes(part)) {
      return true;
    }
    else {
      return false;
    }
  }

  grey(q: any) {
    return this.cs.grey(q);
  }

  showEligible(q: any): boolean {
    if (q && q.extLink) {
      if (q.extLink == 'tdmnd-pbstr') {
        //alert("It is subscription only Feature");
        return true;
      }
      if (q.extLink.includes('pbooster')) {
        //alert("It is signedon Only Feature");
        return true
      }

      if (q.subSeq && (q.subSeq == 'NoListing')) {
        return true;
      } else {
        return false;
      }
    }

  }


  clickRouteUrl(category: string, extLink: string, q?: any, i?: number) {

    //this.ods.setIndexClicked(i);
    this.ods.setClickedOfrDtl(q);
    let msg: string;
    let greyState = this.grey(q);
    //console.log('a ' + greyState);
    if (greyState) {
      switch (greyState) {
        case 'new':
          msg = 'Feature coming soon';
          break;

        case 'paid':
          msg = 'It is subscription only Feature';
          break;

        case 'sign':
          msg = 'It is signed User Only Feature';
          break;
        default:
          msg = "not Eligible";
      }

      if (msg) {
        // alert(msg); 
        //console.log(msg);

        this.cs.addMsg(msg, 'warning')
      }
    }
    else {
      if (this.cat == 'steppers') {
        this.cs.addMsg("Exam defaulted to: " + this.board + "/" + this.course + " , change if needed", "toast");
      }
      this.router.navigateByUrl(this.getRouteUrl(q));
    }

  }

  clicker(question: any) {
    this.ods.setClickedOfrDtl(question);
    let cat_roles= ['roles','centerTypes','learnModes', 
      'esa_unsigned_learner','eba_unsigned_learner','ebc_personalized_roles','ebs_school_roles',
    'lwb_guide_roles','ebs_school_roles',
    'ebc_coahing_roles','lwp_mentor_roles',
    'lwt_mentor_roles'
  ]
    //&& question.category.startsWith('ftr/')
    if (cat_roles.includes(this.cs.getCat())) {
      let role = question.groupId.replace("ftr/", '');
      //role = role.replace("ftr/",'');
      //alert("role changing sir: "+ role)
      this.roleChanger.changeRole(role);
    }
  }

  getRouteUrl(q: any): string {

    /* enable this later 
    if(q.format =='fullNav'){
    return q.extLink;  
    }
   */

    let url = this.cs.getRouteUrl(q.category, q.extLink, q.format);

    let hasSubject = q.extLink && q.extLink.includes("/");
/* object bug test getting populated in subject  %5Bobject%20Object%5D  .. fix later
    
    if (this.usrSubject && !hasSubject && (!q.format && q.format != "skipSub") && q.extLink && !q.extLink.includes("subject") && this.usrSubject) {
      url = url + "/" + this.usrSubject;
    }
*/
    return url;

  }


  formatTitle() {
    if (this.headerObj) {
      //this.hdrTitle = [this.cs.getBoard(),this.cs.getCourse(),this.headerObj.year,this.headerObj.chapterId,this.headerObj.section,this.headerObj.title].filter(Boolean).join("-");

      //this.hdrTitle = [this.cs.getBoard(),this.cs.getCourse(),this.headerObj.title].filter(Boolean).join("-");

      this.hdrTitle = [this.headerObj.title].filter(Boolean).join("-");


      return this.hdrTitle;

    }
  }


  fetchData() {

    //  this.cs.updateParms(this.route);
    this.site = this.cs.getSite();
    //this.board = this.cs.getBoard();
   // this.cat = this.cs.getCat();
    //this.course = this.cs.getCourse();
    this.feature = this.cs.getFeature();
    this.offering = this.cs.getOffering();
    this.boardNeeded = this.cs.boardMust();
    // any component can call this with category and it should process that categories only.

    if (this.category && !this.offering) {
      //input of the component
      // alert(this.category + "input" );

      this.categoryComp = this.category;
    }
    else {
      if (this.cs.getFeature() && this.cs.getFeature()) {
        // alert(this.category + "feature" );
        this.categoryComp = this.cs.getFeature();
      } else {
        // alert(this.category + "getcat" );
        this.categoryComp = this.cs.getCat();
      }
    }

    //  console.log(this.categoryComp);
    this.questions = null;
    this.headerId = null;
    this.dataService.getCatHeaders(this.cs.getSite(), this.cs.getBoard(), this.cs.getCourse(), this.categoryComp, this.cs.getSubject()).subscribe(data => {
      this.headerObj = (data as any).dataList[0];

      if (this.headerObj && this.headerObj.id) {
        this.headerId = (data as any).dataList[0].id;
        //console.log('headerid', this.headerId);
        //this.offering removed as booster failing as searching tag file
        this.dataService.getCfgQuestions(this.site, this.board, this.course, this.headerObj.type, 'subj', this.headerId).subscribe(data => {
          this.questions = (data as any).dataList.filter(rec => rec.format != 'sub');

          //console.log(this.questions);

          if (this.questions) {
            if (true) {  //made feate same as offering and hence disabling..
              this.ftrDescObj = this.cs.getFtrDesc(this.questions);
              OfrDtlService.ftrDescObj.next(this.ftrDescObj);
              //  console.log("ftrDesc", this.ftrDescObj)
            }
            this.questions = this.cs.stripFtrDesc(this.questions);
            //cache ofr-dtls for headings and seo service.
            if (!this.feature && !this.offering) {
              //   console.log(this.categoryComp + " calling odt") 
              //this.ods.loadOfrDtls(this.categoryComp,this.questions)
            }
            // console.log(this.questions);
          }
        });

        if (this.showList) {
          this.questions = this.showList;
          // console.log("showList" + this.showList);


          this.ftrDescObj = this.cs.getFtrDesc(this.questions);
          this.questions = this.cs.stripFtrDesc(this.questions);

          //console.log(this.showList);

        }


      }

    });


  }



  ngOnInit() {

    this.signedUser = this.cs.getSignedUser();

    this.fetchData();
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.boardNeeded = this.cs.boardMust();
      this.fetchData();

    });











    /*
    this.headerId= null;
    //respect input category as demanded, else use cs url category as default..
    
    if(this.category){
    //this.headerId= this.fd.getFeatureHdrIdE(this.cs.getSite(),this.category,this.cs.getBoard(),this.cs.getCourse());
    this.categoryComp= this.category;
    }
    else{
    //dont override pass category/feature..
    //this.headerId= this.fd.getFeatureHdrIdE(this.cs.getSite(),this.cs.getCat(),this.cs.getBoard(),this.cs.getCourse());
    this.categoryComp= this.cs.getCat();
    if(this.cs.getFeature()){
    //alert("feature is: "+ this.cs.getFeature() )  ;
    this.categoryComp=  this.cs.getFeature();
    }
    }
    
    //this.headerId= this.fd.getFeatureHdrIdE(this.cs.getSite(),this.categoryComp,this.cs.getBoard(),this.cs.getCourse());
    
    //this.headerId= null;
    //if headerId not matched in FixedDataService, give a direct call to backend.
    //if(this.headerId){
    //offering is category/features name here..
    this.dataService.getCatHeaders(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.categoryComp,this.cs.getSubject()).subscribe(data =>
    {
    
      //hideTitle comes as parm and respect it by conditional populating headerObj
    if(!this.hideTitle){
    this.headerObj= (data as any).dataList[0];
    }
    //console.log('--1-');
    this.headerId= (data as any).dataList[0].id;
    //console.log(this.headerObj);
    //console.log("header is :" + this.headerId);
    //console.log('--1-');
    //console.log("Header id found for ");
    //console.log("Header id found for "+this.headerId +this.categoryComp );
    
    if(!this.headerId){
     // console.log("Header not found for "+this.categoryComp );
    alert("Header not found for "+this.categoryComp );
    }
    
    //this.cs.getOffering() removed to avoid tag based search for booster
    this.dataService.getCfgQuestions(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),'pyq',this.cs.getSubject(),this.headerId).subscribe(dataList => {
            this.questions = dataList;
    //console.log("unsorted", this.questions);
        this.questions= this.questions.sort((l,r):number =>{
            if(l.sequence<r.sequence) return -1;
            if(l.sequence>r.sequence) return 1;
              return 0;
          }  );
    
    //console.log("header is :" + this.questions[0].title);
    });
    
    //console.log('--11-');
    
    });
    
    */


    //}

    //alert("header is :" + this.headerId);
    //this.cs.getSite()= "es";
    //  if(this.cs.getBoard() && this.cs.getBoard().trim()== "features"){ remove later
    //this.title = "Offerings:";

    // alert("null and value check working");

    /*
     if(!this.hideTitle){
    this.dataService.getSiteHeaderById(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.cs.getOffering(),this.cs.getSubject(),this.headerId).subscribe(data => {
     //this.headerObj= (data as any).dataList[0];
    //console.log('--2-');
     //console.log(this.headerObj);
     //console.log("header is :" + this.headerObj.id)
    //console.log('--2-');
      });
    }
    */

    /*
    
    //below care only for headerid, rest parm is for future..
    this.dataService.getCfgQuestions(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.cs.getOffering(),this.cs.getSubject(),this.headerId).subscribe(dataList => {
            this.questions = dataList;
    //console.log("unsorted", this.questions);
        this.questions= this.questions.sort((l,r):number =>{
            if(l.sequence<r.sequence) return -1;
            if(l.sequence>r.sequence) return 1;
              return 0;
          }  );
          //console.log("sorted", this.questions);
    
    
    if(this.headerId===201831){
    
    this.questions.splice(0,1);
    //above working... but found bootstrap way...
    }
    //this.removeEmptyExtURL("");
        });
    */



  }

}




//Learning Features
//this.headerId= 20183;
/*
this.headerIdPrem= this.dataService.getFeatureHdrId(this.cs.getSite(),this.cs.getCat());
this.dataService.getSiteHeaderById(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.cs.getOffering(),this.cs.getSubject(),this.headerIdPrem).subscribe(data => {
    this.headerLearn= data;
   });
this.dataService.getCfgQuestions(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.cs.getOffering(),this.cs.getSubject(),this.headerIdPrem).subscribe(dataList => {
       this.questionsLearn = dataList;
   });
*/




//this.category= 'sf';
//alert(this.expanded + " " + this.category);


/*
  site:string;

  cat:string;
  board: string;
 course:string;
 offering:string;
 subject:string;
*/


//latest
//Premium Content
/*
this.headerIdPrem= 18054;
this.dataService.getSiteHeaderById(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.cs.getOffering(),this.cs.getSubject(),this.headerIdPrem).subscribe(data => {
this.headerPrem= data;
});
this.dataService.getCfgQuestions(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.cs.getOffering(),this.cs.getSubject(),this.headerIdPrem).subscribe(dataList => {
   this.questionsPrem = dataList;
});

this.dataService.getSignedUser().subscribe(response=> {
     if(response && response != "Guest") {
     this.signedUser = response as string;
   }
 });
*/


/*
this.dataService.getSiteHeaders(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.cs.getOffering(),this.cs.getSubject()).subscribe(dataList => {

 this.headers= dataList;

});

*/
//} remove later

//this.cs.getBoard() = this.elementRef.nativeElement.getAttribute('board');
//this.cs.getCourse() = this.elementRef.nativeElement.getAttribute('course');

/*
this.route.params.subscribe( params => {
this.cs.getSite()= params['site'];
this.cs.getCat()= params['cat'];
this.cs.getBoard()= params['board'];
this.cs.getCourse()= params['course'];
this.cs.getOffering()= params['offering'];
this.cs.getSubject()= params['subject'];
});
*/

//alert("board,course"+ this.cs.getBoard() + this.cs.getCourse());
