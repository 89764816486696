  
  
<app-dropdown *ngIf= 'enable("selectFormat")' [formatList] = formatList [dfltCode]="selectedFormat?.code" 
 (changedValue)="fetchData($event)"> </app-dropdown>

 
<app-qh-flds [fldList]=primary [h] = header [ei] = ei [parentFormGroup] = parentFormGroup> </app-qh-flds>


<div class= "text-right">
<button *ngIf="secondary" type="button" class="btn badge btn-secondary mx-2"
    (click)="expand=!expand"> Secondary Fields</button>

    <!--
    additional?{{enable('additional')}}-{{additional}} -->
 <button *ngIf="enable('additional') && additional" type="button" class="badge text-right btn btn-warning mx-2"
        (click)="additionalFlag=!additionalFlag"> Additional Fields</button>
</div>

        <!--for future
<button *ngIf="enable('jsonEditor') && additional" type="button" class="text-right btn btn-secondary mx-2"
(click)="updateJsonFlag=!updateJsonFlag"> Json updater</button>
</div>
-->


<app-qh-flds *ngIf="expand"  [fldList]=secondary [h] = header [ei] = ei [parentFormGroup] = parentFormGroup> </app-qh-flds>
    
<app-qh-flds *ngIf="additionalFlag"  [fldList]=additional [h] = header [ei] = ei  [parentFormGroup] = parentFormGroup> </app-qh-flds>


