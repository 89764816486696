
<!-- this shows for build with click pbstr-wip creation-->
<div *ngIf="calling; else noCall">
    
   
    <ng-container *ngIf= 'tl.enable("addHdr")'>
    <button type="button" class="btn btn-outline-primary" (click)="showAdd=!showAdd">
        Add Hdr Title{{showAdd?'-':'+'}}
    </button>

    <!-- upload component-->
    <app-dhw-bldr  *ngIf= "false && slctType.includes('dhw')" [crtHdr] = prepUploadCrt(crtHdr)> </app-dhw-bldr>


    <div *ngIf="showAdd">

        <ng-container [ngSwitch]="slctType">
           
       
            <app-qstnhdr-add *ngSwitchCase='"snote"' [iHeader]=crtHdr>
            </app-qstnhdr-add>


            <app-qstnhdr-add *ngSwitchCase='"doc"' [iHeader]=crtHdr>
            </app-qstnhdr-add>
           
            <app-qstnhdr-add *ngSwitchCase='"vid"' [iHeader]=crtHdr>
            </app-qstnhdr-add>
            
            <app-add-header *ngSwitchCase='"twip-dhw"' [iHeader]=crtHdr>
            </app-add-header>
            
            <app-add-header *ngSwitchDefault [iHeader]=crtHdr> </app-add-header>
            </ng-container>


    </div>

</ng-container>
<!--
    <div *ngIf="(data$ | async)?.dataList as list; else loading">
                   
                 data?.dataList
    -->
        <div *ngIf="data as list; else loading"> 
        <ng-container *ngIf="list.length; else noItems">
            <ng-container [ngSwitch]="slctType">
                <!--
                <app-slctr-view *ngSwitchCase='"twip-dhw"' [list1]=list>
                </app-slctr-view>
                <app-slctr-view *ngSwitchCase='"twip-eps"' [list1]=list>
                </app-slctr-view>
                <app-slctr-view *ngSwitchCase='"twip-pbstr"' [list1]=list>
                </app-slctr-view>

                <app-slctr-view *ngSwitchCase='"twip-tigp"' [list1]=list>
                </app-slctr-view>

                <app-slctr-view *ngSwitchCase='"tigp"' [list1]=list>
                </app-slctr-view>

                <app-slctr-view *ngSwitchCase='"twip-dps"' [list1]=list>
                </app-slctr-view>

                -->

  <!-- not doc doeesn't have selector perhaps so added above and it worked when select button was not coming-->
                <app-doc-view *ngSwitchCase='"tshr-doc"' [list1]=list>
                </app-doc-view>
                <app-doc-view *ngSwitchCase='"doc"' [list1]=list>
                </app-doc-view>

                 <!--  
                <app-doc-view *ngSwitchCase='"tasgn-tgubstr"'  [list1]=list> </app-doc-view>
                -->
                
                <app-slctr-view *ngSwitchDefault [list1]=list>
                </app-slctr-view>

           
            </ng-container>

        </ng-container>

        <ng-template #noItems>
            No item to list.
        </ng-template>

    </div>


    <ng-template #loading>loading......</ng-template>
</div>

<ng-template #noCall>!</ng-template>