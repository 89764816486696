<div class="container text-center"
    *ngIf="(clsSec$ | async)?.result?.subjectDtos as subjects; else loading">

    Subject List for section:

    <div class="row ">
        <div class=" list-group-item col-12 col-md-3 my-1 border-0"
            *ngFor="let s of subjects; let i = index;">
            <a *ngIf='showSub(s)' class="btn btn-primary col-md rounded"
                [routerLink]='getRoute(s.name)' (click)='cs.setUsrSubject(s)'>
                {{s.name}}
                <sup>{{s.status}} </sup>
            </a>

        </div>

    </div>

</div>



<div class='text-center'>
    <button id='"justSubject-subWithContent"'
        *ngIf='cs.enable("justSubject","subWithContent")' class='btn-secondary'
        (click)='loadSubject(); this.show = !this.show;'>Show Subjects with
        Content </button>
</div>


<div class="container text-center" *ngIf="(sl$ | async) as sl; else loading1">

    <ng-container *ngIf="show">
        <ng-container *ngIf="sl && sl.length; else noItems">

            Subjects:

            <div class="row ">

                <div class=" list-group-item col-12 col-md-3 my-1 border-0"
                    *ngFor="let s of sl; let i = index;">

                    <a class="btn btn-primary col-md rounded"
                        [routerLink]='getRoute(s)'
                        (click)='cs.setUsrSubject(s)'>
                        {{s}}</a>

                </div>

            </div>

        </ng-container>

    </ng-container>

    <ng-template #noItems>
        Data unavailable at the moment. Contact us for more details or
        clarifications.
    </ng-template>

</div>







<ng-template #loading>

</ng-template>


<ng-template #loading1>...</ng-template>