import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-s-pmagic',
  templateUrl: './s-pmagic.component.html',
  styleUrls: ['./s-pmagic.component.scss']
})
export class SPmagicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  
}
