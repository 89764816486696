
<app-headings-offering [subDesc] = subDesc> </app-headings-offering>
<!--
  {{statusTexts['wip']}}
<app-sub-desc [hdr] = subDesc> </app-sub-desc>    
-->

<!--
plain:
<app-headings-plain> </app-headings-plain>

 old:
 <app-headings> </app-headings>
-->


<div class="row mx-2 px-2"> Please select: 
  <div class= "ml-auto text-right">User: {{user}} </div>
  </div>
  
  <ng-container *ngIf= 'allowUserChange'>
    
     <button *ngIf= 'user != "owner" ' class = "col-4 col-md-3 col-lg-2" (click) = 'changeUser("owner")'> All users </button>
    <button *ngIf= 'user != owner 'class = "col-4 col-md-3 col-lg-2" (click) = 'changeUser(owner)'> only user </button>
    <!--
    <label class= "ml-auto text-right">User: {{user}} </label>
    -->
    <button class= "col-4 col-md-3 col-lg-2" (click)= 'refresh()'>refresh</button>
    <button class= "col-4 col-md-3 col-lg-2" (click)= 'chs.showWithStatus("")'> show All</button>
    
    <br>
    <ng-container *ngFor= 'let status of statusFilter; let i = index;'>
      <button class= "col-4 col-md-3 col-lg-2" (click)= 'chs.showWithStatus(status)'>
        {{getStatusTitle(status)}}
       </button>
    </ng-container>

    <!--
    <button class= "col-4 col-md-3 col-lg-2" (click)= 'chs.showWithStatus("shr")'> in progress</button>
    <button class= "col-4 col-md-3 col-lg-2" (click)= 'chs.showWithStatus("clr")'> clarify </button>
    <button class= "col-4 col-md-3 col-lg-2" (click)= 'chs.showWithStatus("rvsn")'>for revision </button>
    <button class= "col-4 col-md-3 col-lg-2" (click)= 'chs.showWithStatus("cmplt")'>completed</button>
    <button class= "col-4 col-md-3 col-lg-2" (click)= 'chs.showWithStatus("ready")'>ready</button>
    <button class= "col-4 col-md-3 col-lg-2" (click)= 'chs.showWithStatus("verified")'>verified</button>
    <button class= "col-4 col-md-3 col-lg-2" (click)= 'chs.showWithStatus("store")'>in store</button>
    <button class= "col-4 col-md-3 col-lg-2" (click)= 'chs.showWithStatus("avl")'>customize</button>
    -->

   

</ng-container>
   


<div class="mx-3"  *ngIf="calling; else noCall"> 
    <div *ngIf="(headers$) as catalog; else loading">
    <ng-container *ngIf="catalog.length; else noItems">
     
      
      <p  *ngFor="let header of catalog; let i = index;">
      
       
 <!-- -->

 <ng-container class="row" *ngIf= 'chs.checkForStatus(header)'> 
              <li class="col-12 block"  *ngIf= 'chs.eligibleShow(header,"",user) else disable' >
   
     <!-- {{checkForStatus(header)}} --{{header.status}}-{{forStatus}}
              -->
    <ng-container *ngIf= 'header.status && header.status != "hide"' > 
  <span  class = "ellipsis text-primary col-12 col-md-8 col-lg-6 badge text-left center" 
        innerHTML = {{chs.getTitle(header)}} > </span>   
   
        {{header.status}}
  <app-view-lstr class= "ml-3" [hdr] = header [i]= i></app-view-lstr>
  
  <app-hdr-btn-lstr class= "ml-3" [header] = header [i]= i > </app-hdr-btn-lstr>

  <app-dgtzr-btn *ngIf='enable("dgtzrBtns",i,header)' class= "ml-3" [header] = header [i]= i></app-dgtzr-btn>
 
  <app-gate-mover [header]=header [i]=i> </app-gate-mover>
   
</ng-container>
  
  <app-crud-btn  *ngIf="enable('crudCmnBtns-cstm',i,header)"  [header] = header [i] = i > </app-crud-btn>
      

   <div class="row col-12">
    <app-display-ad   *ngIf="(i!='0') && (i%9 =='0')">
    </app-display-ad>
   </div>
    
</li>
</ng-container>


<ng-template #disable>
   <!--           
  <app-disabled-chptr class="col-12"  *ngIf="!hide" [header]=header>
  </app-disabled-chptr>
  -->
  
</ng-template>


</p>   </ng-container>
    <ng-template #noItems>No Items!</ng-template>
    <!--
  <app-bstr-mkr> </app-bstr-mkr>
  -->
  
 
  <button *ngIf= "enable('makeBstr',header,i)" (click)="chs.buildNewBstr('100')" class="btn badge btn-info"
  style="margin-left: 10px">make new Bstr</button>


    </div>
   
<ng-template #loading>loading data...</ng-template>
</div>
<ng-template #noCall>c!</ng-template>


  
    
        <!--
          embed-responsive embed-responsive-16by9
          embed-responsive-item
  
          https://getbootstrap.com/docs/4.4/utilities/embed/ good example-->
  
      
  