<!--

<h5 *ngIf="false"> Association id: {{grpAsoId}} 

<button  type="button" class="mx-2 btn bade btn-outline-primary border-0" (click)='associate()'>
   select & associate  </button>

</h5>
-->
 
<div class="ng-container px-3 mx-3">

<ng-container *ngIf="(hdrs$ | async) as contents; else loading">
   <!--
  <h5 class= "text-center m-2" > {{title}} </h5>
    -->
      <button *ngIf= 'allowEdit'  class= "mx-3 btn btn-outline-primary badge "  (click)= 'showAdd =!showAdd'>
       Add Header </button>   
      <div *ngIf= "showAdd" class= "my-3 mx-5">
       <app-qstnhdr-add class="my-5"> </app-qstnhdr-add>
      </div>

    <table datatable [dtOptions]="dtOptions"  class="row-border hover">
       <thead>
       <tr>
         <th *ngFor="let fld of fldList,let i=index;">
            {{fld}}
         </th>
         
         <th>
           Feature
         </th>
       
        </tr>
       </thead>
       <tbody>
       <tr *ngFor="let c of contents?.dataList; let i=index">
        <ng-container *ngIf= 'checkClsSec(c)'>
         <td *ngFor="let val of getValList(c), let i=index;">
          <ng-container *ngIf="c.type != 'unit'">
          </ng-container>
      {{val}}
      </td>
            
       <td>
            <button *ngIf='allowEdit' class= "mx-3 btn btn-outline-primary badge "  (click)= 'expand(c,i)'>
             Expand </button>   
    
             <app-crud-btn [header]=c [i]=i>
            </app-crud-btn>
       </td>
         
         
       
            <td>

              <app-rec-slctr *ngIf="qh.cs.enable('cstmBstrmkr','makeUnitBstr',null,refHdr)"  [fldVal] = c.id [storeKey] = srcKey > </app-rec-slctr>
              <app-view-lstr [hdr] = c> </app-view-lstr>
            </td>
            
          </ng-container>
  
         </tr>
           
     </tbody>
   
    </table>
  
  </ng-container>
     
  <!--
  <app-bstr-mkr> </app-bstr-mkr>
  -->
  
  <app-cstm-bstr-mkr [bstrType] = '"unit"' [refHdr] = refHdr> </app-cstm-bstr-mkr>


  <div *ngIf= "allowEdit && expandI>=0" class= "my-3 mx-5">
    <app-my-json [table]= 'getTable(c)' class="my-5"  [q] = "c" > </app-my-json>
  </div>
  
   
   
   <ng-template #noCall>!</ng-template>
  </div>