
  <div class="jumbotron mb-0">
    <h3 class="display-4">Parent Engager</h3>
    <p class="lead">Parents/Mentor, Please don't believe others and turn in to just a mute spectator or inactive dummy partner to your kids. </p>
    
    <br>
      .....Rather , Engage and be a "digibest parent" to avoid anxiety or turning into a fruitless preacher for your kids.... Introducing "Parent Engager" to make you digibest parent of current world!!
      <br>
      <hr>

      All we need is your 10 minutes daily to add upto 10% marks to your ward using our "Learn with Parent" & Percentage Booster..
      We are not just talking :) , We have proven records,Let us do together....
    
    
</div>

<div>
         <h4 class="bg-warning text-primary"> Let us join and guide kids timely... </h4>


    <!--     
    <p>We provide day to day Digitally best homework helper & other learning App for your ease to help kids just in time from anywhere... 
    In Addition we have quick Percentage Booster for reality check, which kids can finish quickly with you in 5-10 minutes per subject and it has proven to be both preparation & Morale Booster for many happy Parents & kids.<br>
  </p>
      -->

    


  <span class="blockquote justify-center font-weight-bold  pb-1">
  Time and tide wait for none.. try today....<a  *ngIf= "!mentorId" class= "btn btn-primary " href = {{signupLink}} > signup Parent/Mentor</a>
</span> 
<div class= "small font-weight-bold text-primary text-center">
Believe us, parent/mentor can become "digibest" too easily ... 
</div>
<hr>

<!--
    <p class="lead">
      <a class="btn btn-primary btn-lg" routerLink ="/esa/te/features/pbstr" role="button">Check and assist kid(s) to go through BOOSTER daily</a>
    </p>

    -->
  </div>
