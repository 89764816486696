<div *ngIf = 'q.optionList; else digOptns'>
<ul *ngFor="let o of q.optionList;let j = index"
class="list-group list-group-flush">
<p *ngIf= 'o' class= "text-secondary" >
  <input type="radio" [value]= ts.getDigOptn(o,j+1) name="digAnsr" > 
  <!-- [(ngModel)]="q.ansCmnts[0].digAnsr"-->
  <span innerHTML= {{o}}> </span>
  </p>
</ul>

</div>

<ng-template #digOptns>
   <div [innerHTML] = "q.digOptns|safe :'html'"> </div>

  </ng-template>
    
