

  import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientInfoService } from '../../shared/client-info.service';
import { UsrdtlService } from '../../user/usrdtl.service';
import { CommentService } from '../../comments/comment.service';
import { PerformanceService } from '../performance.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  @Input() user:string;
  ul$: Observable<any>;

  
  get site(){return this.cs.getSite()}
  get board(){return this.cs.getBoard()}
  get course(){return this.cs.getCourse()}
  get clsSec(){return this.cs.getClsSec()}


 title = 'datatables';
 dtOptions: DataTables.Settings = {};
  


  constructor(private cs:ClientInfoService, private uds:UsrdtlService,private performSrvc:PerformanceService) { }
  
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
    };
    
    this.ul$ = this.uds.getStudentList(this.site,this.board,this.course,this.clsSec) ; 

  }

  showResult(u:any){
    let cat= "my";
    let extLink= "features/s-fdbck";
    this.cs.navigate(cat,extLink,'',u.userId);
    //this.cs.addMsg(u.userId);
  }

  
  showMistake(u:any){
    let cat= "my";
    let extLink= "features/s-mistake";
    this.cs.navigate(cat,extLink,'',u.userId);
    //this.cs.addMsg(u.userId);
  }


  showCIBooster(u:any){
    let cat= "mymkr";
    let extLink= "tbstr/subject~personalized";
    this.cs.navigate(cat,extLink,'',u.userId);
    //this.cs.addMsg(u.userId);
  }

}
