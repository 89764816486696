import { Component, OnInit } from '@angular/core';
import { CommentService } from "../comment.service";
import { Comment } from "../comment";
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-reaction',
  templateUrl: './reaction.component.html',
  styleUrls: ['./reaction.component.scss']
})
export class ReactionComponent implements OnInit {

  constructor(private commentService: CommentService,private cs:ClientInfoService) { }

  ngOnInit(): void {
    //51happy-55
  }
  
  addCount(reactType:string){
   console.log("adding:" + reactType) ;
  this.commentService.increaseCount(reactType,"").subscribe(
    data =>  error => console.log(error));
    this.cs.addMsg("your reaction updated","toast");
}
 


}
