
<!--
<p>custom-quill works!</p>

<button id="delta" (click) = 'clickDelta()'>Show delta in console</button>
-->


<button type= "button" class= "float-right btn btn-small btn-secondary badge " id="mathjax" (click) = 'clickMathjax()' >Formula</button>

<div id="editor-container" (onEditorChanged) = 'changedEditor($event)' 

> 
 </div>

 <div class= "float-center" >
<button type= "button" id="save" (click) = 'save()'> Save</button>
</div>


<hr>
<!--
<quill-view *ngIf= 'quill'  [content]="quill.root.innerHTML" format="html" theme="snow"></quill-view>
-->

<hr>

<hr>



<quill-view *ngIf= 'quill'  [content]="content" format="html" theme="snow"></quill-view>


<!--
https://stackoverflow.com/questions/44467204/how-do-i-post-contents-of-a-quill-editor-in-a-form

https://stackoverflow.com/questions/53999948/uncaught-error-cant-make-callback-from-given-data-using-mathjax-with-p5-and-ht

https://stackoverflow.com/questions/64436538/how-can-i-render-ngx-quill-editor-content-from-my-database-to-a-view-component
-->