

<ng-container *ngIf="(hdrs$ | async) as contents; else loading">
    <h5 class= "text-center m-2" > {{title}} </h5>
  
    <button *ngIf='grpAsoId' class= "mx-3 btn btn-outline-primary badge "  (click)= 'clearGrpAso()'>
      Clear Stored grpAso Id </button>   

      <button *ngIf= 'allowEdit'  class= "mx-3 btn btn-outline-primary badge "  (click)= 'showAdd =!showAdd'>
       Add Header </button>   
  
       
      <div *ngIf= "showAdd" class= "my-3 mx-5">
  
       <app-qstnhdr-add class="my-5"> </app-qstnhdr-add>
      
      </div>
   
  
    <table datatable [dtOptions]="dtOptions"  class="row-border hover">
       <thead>
       <tr>
         <th *ngFor="let fld of fldList1,let i=index;">
           
          
            {{fld}}

         </th>
         <th>
           Feature
         </th>
         <th *ngFor="let fld of fldList2,let i=index;">
           
          
          {{fld}}

       </th>
       </tr>
       </thead>
       <tbody>
  
         <tr *ngFor="let c of contents;let i=index">
       <td *ngFor="let val of getValList1(c), let i=index;">
      {{val}}
     
      </td>
            
           <td>
            
           
            <button *ngIf='allowEdit' class= "mx-3 btn btn-outline-primary badge "  (click)= 'expand(c,i)'>
             Expand </button>   

            <app-crud-btn [header]=c [i]=i>
            </app-crud-btn>
            <!--*ngIf='enable("dgtzrBtns",i,header)'-->
            <app-dgtzr-btn  class= "ml-3" [header] = c [i]= i></app-dgtzr-btn>
 
            <app-gate-mover [header]=c [i]=i> </app-gate-mover>
          
            
              
            </td>
         
            <td>
              <app-view-lstr [hdr] = c> </app-view-lstr>
          
            </td>
            <td *ngFor="let val of getValList2(c), let i=index;">
              {{val}}
             
              </td>
                    
              
  
         </tr>
           
     </tbody>
   
    </table>
  
  </ng-container>
   
   
  <div *ngIf= "allowEdit && expandI>=0" class= "my-3 mx-5">
    <app-my-json [table]= 'getTable(c)' class="my-5"  [q] = "c" > </app-my-json>
  </div>
  
   
   
   <ng-template #noCall>!</ng-template>
  