

<ngb-rating  [rate]="q.mark"></ngb-rating>


<!--
<ngb-rating [(rate)]= 'q.mark' (hover)="hovered=$event" (leave)="hovered=0" [readonly]="readonly"></ngb-rating>

<hr>
<pre>
Selected: <b>{{selected}}</b>
Hovered: <b>{{hovered}}</b>
</pre>
<button class="btn btn-sm btn-outline-{{readonly ? 'danger' : 'success'}}" (click)="readonly = !readonly">
  {{ readonly ? "readonly" : "editable"}}
</button>

-->