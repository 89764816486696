import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FixedDataService } from '../../shared/fixed-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { TaghdrListService } from '../../tag/taghdr-list/taghdr-list.service';
import { BtnEnablerService } from '../../shared/btn-enabler.service';

@Injectable({
  providedIn: 'root'
})
export class CntxtService {
  addVid: boolean;
  ovrHdrFormat: string;
  get site() { return this.cs.getSite() };
  get cat() { return this.cs.getCat() };
  get board() { return this.cs.getBoard() };
  get course() { return this.cs.getCourse() };
  get feature() { return this.cs.getFeature() };
  get offering() { return this.cs.getOffering() };
  get justOffering() { return this.cs.getJustOffering() };
  get subject() { return this.cs.getSubject() };
  get chapter() { return this.cs.getChapter() };
  get unsigned() { return this.cs.isUnsigned()}

  constructor(private router: Router, public cs: ClientInfoService, public fd: FixedDataService, public tl:TaghdrListService, private be:BtnEnablerService) { }

  
  
  //assumption is cat has build source ..for adder
  navigateCmnt(cmntType:any) {
     let newCat= 'cmnt-'+ cmntType;
    let url = this.router.url.replace(this.cs.getCat(),newCat)
    this.router.navigate([url]);
  }

  navigatePrnt(hdr:any) {
    let newChptr= hdr.prntHdrId;
   let url = this.router.url.replace(this.cs.getChapter(),newChptr)
   this.router.navigate([url]);
 }


 //assumption is cat has build source ..for adder
 changeCat(newCat:string) {
 let url = this.router.url.replace(this.cs.getCat(),newCat)
 this.router.navigate([url]);
}

buildWithClick() {
  //var cat= this.cs.offering + '-bldr';
  this.router.navigate(['/', this.cs.site, this.cs.offering + '-bldr']);

}
  
  //only use for mkr- cat and tavl- offer combo 
  addNew() {
    //var cat= this.cs.offering + '-bldr';
    var newCat= "my"+ this.cat; 
    //.replace("mkr-","");
    var newOfr= this.offering.replace("tstore-","");
    let url = this.router.url.replace(this.cat,newCat).replace(this.offering, newOfr)
    this.router.navigate([url]);
  }
    

  //only use for mkr- cat and tavl- offer combo 
  goStore() {
    //var cat= this.cs.offering + '-bldr';
    //var newCat= "my"+ this.cat; 
    var newCat= this.cat; 
    //.replace("mkr-","");
    var newOfr= this.offering.replace("tavl-","tstore-");
    let url = this.router.url.replace(this.cat,newCat).replace(this.offering, newOfr)
    this.router.navigate([url]);
  }
    



    /*
    let extUrl= "hwh";
    if(this.cs.subject){
    extUrl= extUrl+ "/" + this.subject; 
    }
    alert(extUrl);
    this.router.navigateByUrl(this.cs.getRouteUrl(cat,extUrl));  
   */


  
  //http://localhost/esadigi-best/abc/CBSE/XII/qdhw
  buildWithDdgtzr() {

    //offering can be used too easily??
    let cat = this.cs.getCat();
    if (cat.startsWith("mkr-")||cat.startsWith("mymkr-")) {
      cat = cat.replace("mymkr-", "").replace("mkr-", "");
      
      let qOfr = "q" + cat;

      let extUrl = qOfr;
      if (this.cs.getSubject()) {
        extUrl = extUrl + "/" + this.cs.getSubject();
      }

      var chptrUrl = this.cs.getRouteUrl('dgtz', extUrl);
      console.log(chptrUrl);
      this.router.navigateByUrl(chptrUrl);
    }
    else {
      this.cs.addMsg("Error:Digitizer couldn't be processed", "warning");
    }
  }

  

  skipBldr() {
    let cat = this.cs.getCat();
    if (cat.endsWith("-bldr")) {
      cat = cat.replace("-bldr", "");
      let qOfr = cat;
      if (['dhw','dps'].includes(cat)) {
        qOfr = "q" + qOfr;
      }

      let extUrl = qOfr;
      if (this.cs.getSubject()) {
        extUrl = extUrl + "/" + this.cs.getSubject();
      }

      var chptrUrl = this.cs.getRouteUrl('dgtz', extUrl);
      console.log(chptrUrl);
      this.router.navigateByUrl(chptrUrl);
    }
    else {
      this.cs.addMsg("Error:Digitizer couldn't be processed", "warning");
    }
  }



  getDgtzrName() {
    let cat:string = this.cs.getCat();
    if (cat.startsWith("mkr-")) {
      cat = cat.replace("mkr-", "");
      return cat.substr(1).toUpperCase();
    }
    if (cat.startsWith("mymkr-")) {
      cat = cat.replace("mymkr-", "");
      return cat.substr(1).toUpperCase();
    } 
  }


  selectAndAdd(){
     
 this.tl.selectId(this.chapter as any);
  let cat= this.offering.replace("twip-","")+"-bldr";
  this.navigate(cat); 
  }


  navigate(cat: string, link?: string) {
    //remove next line if cause negative impact.
    if(link && this.subject && !link.includes("http") && !link.includes("features") ){ link =link + "/"+this.subject}

    this.router.navigateByUrl(this.cs.getRouteUrl(cat, link));
  }



  getHdrName(){
  
    if(this.subject) {
      this.ovrHdrFormat= "";
      return 'chapter/Unit';
    } else{
      //
      //this.ovrHdrFormat= "subDesc";
      this.ovrHdrFormat= "cl"; //chapter-list
      return 'Subject';
    }
  }

   getQstnName(){
    switch (this.justOffering) {
      //build with click
      case 'vid':
        this.addVid= true;
        return 'Video';

      //digitize with upload 
      case 'doc':
        this.addVid= false;
        return 'Doc';

        default:
          this.addVid= false;
          return 'Question';
      
    }

    }
    

  enable(btnId: string, hdrRec?: any,i?:number) {

    if (btnId == 'showTDL' && !this.fd.tagBasedForCreate(this.justOffering)) {
      return false;
     }


    if (btnId == 'showQH' && this.fd.tagBasedForCreate(this.justOffering)) {
      return false;
     }

    

     if (btnId == 'showTH'  && this.unsigned) {
      return false;
     }
     if (btnId == 'showTH'   && !this.fd.tagBasedForCreate(this.justOffering)) {
      return false;
     }

    if (btnId == 'cntxtExpand' && this.feature) {
      return false;
    }

    let component = 'contextMenu';

 if(btnId=='show1TDL'){
 let enable = this.be.enable(component, btnId, hdrRec,false);
  return enable; 
  }
  
   let enable = this.cs.enable(component, btnId, null, hdrRec);
 
   if(enable){
    return this.customEnable(btnId, enable, i);
    }
    return enable;
  }

  customEnable(btnId: string, enable: boolean, i?: number, hdrRec?: any) {
//console.log(this.fd.tagBasedForCreate(this.offering) + this.offering);

    //if (this.cat.includes("mkr")) {
if (this.offering) {

    switch (btnId) {
      //build with click
      case 'bwc-cstm':
        return this.fd.tagBasedForCreate(this.offering)?true:false;

      //digitize with upload 
      case 'dwu-cstm':
        return this.fd.tagBasedForCreate(this.offering)?true:false;
      
      //associate n build .. using associate in setup ...change to cstm if needed
      case 'anb-cstm':
        return this.fd.tagBasedForCreate(this.offering)?false:true

    }

  }

  
  return enable;
}



}
