
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ClientInfoService } from '../shared/client-info.service';
import { ExtraInfo } from '../shared/assigner/extra-info';
import { TaghdrListService } from './taghdr-list/taghdr-list.service';
import { FixedDataService } from '../shared/fixed-data.service';
import { Header } from './header';
import { BstrMkr } from './bstr-mkr/bstr-mkr';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    //'Authorization': 'my-auth-token',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'HEAD,GET,POST,PUT,PATCH,DELETE',
    responseType: 'json',
  })
};




@Injectable({
  providedIn: 'root'
})

export class HeaderService {
 
  //Subject which will be pushed from child to advise parent to update List.
  public static updateList: Subject<boolean> = new Subject();
  public static updateDhwHdrId: Subject<number> = new Subject();
  private sServiceUrl;
  private baseUrl;
  private uploadUrl;
  //= 'http://192.168.1.2:8080/rs/cmnt';


 
 
  get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get chapter() { return this.cs.getChapter()};
  get clsSec() { return this.cs.getClsSec()};
  get offering() { return this.cs.getOffering()};

  get subject() { return this.cs.getSubject()};
  get school() { return this.cs.getSchool()};
  get schlLoc() { return this.cs.getSchlLoc()};
  get signedUser() { return this.cs.getSignedUser()};


  constructor(private http: HttpClient, public cs: ClientInfoService,private fd:FixedDataService) {
    this.baseUrl = this.cs.getRoot() + "/rs/th";
    this.uploadUrl = this.cs.getRoot() + "/rs/files";
    this.sServiceUrl = this.cs.getRoot() + "/rs/s";


  }



  get(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/header/${id}`);
  }

  list(type: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/byTypeWithClsSec/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getClsSec()}/${type}/${this.cs.getSubject()}`);

  }


  create(header: Header): Observable<Object> {
    console.log(header);
    return this.http.post(`${this.baseUrl}`, header);
  }
  makeBstr(refId: number, sourceIds:any) :
     Observable<any> {
      return this.http.post(`${this.baseUrl}/makeBstr/${refId}/${sourceIds}`, sourceIds, httpOptions);
    }
    

 
    makeCstmBstr(bstrMkr: BstrMkr) :
    Observable<any> {
    
    return this.http.post(`${this.baseUrl}/makeCstmBstr`, bstrMkr, httpOptions);
   }



  createHeaderFromAssigner(header: any): Observable<Object> {
    header.prntHdrId = header.id;
    console.log("prnt: " + header.prntHdrId + "-- " + header.id);
    header.clsSec = this.cs.getClsSec();
    if (header.clsSec == "clsSec") {
      header.clsSec = "cmn";
    }
    return this.http.post(`${this.baseUrl}` + '/byQhdr', header);
  }

  

  uploadFile(formData: any): Observable<Object> {
    console.log(formData);
    if (!formData.headerId) {
      //headerid not needed always
      //this.cs.addMsg("warning: header id is null? , pls check", "toast");
      if (this.cs.getChapter()) {
        formData.headerId = this.cs.getChapter();
      }
    }
    return this.http.post(`${this.uploadUrl}/upload/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getClsSec()}/${this.cs.getOffering()}/${this.cs.getSubject()}/${formData.headerId}`, formData, { responseType: 'text' });
  }

  /*
   uploadFileNcreateTag(fileForm:object,headerId:number){
   return this.http.get(`${this.uploadUrl}/uploadNCreate/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getClsSec()}/${this.cs.getOffering()}/${this.cs.getSubject()}/${headerId}`);
   }
  */


  //don't use , moving to change Status and backend..
  assign(hdrId: any, status: any, ei?: ExtraInfo): Observable<Object> {
    //let  id= this.cs.getChapter(); 

    /*
    let offering = this.cs.getOffering().replace(this.cs.getStatus(), status);
    
    if(offering.startsWith('q')){
    //later add t prefix to make it universal
     offering= ""+ offering.substr(1);
     hdr.type= offering;  
     return this.addTagForAssign(hdr);
    }
    */
    return this.http.put(`${this.baseUrl}/assign/${hdrId}/${status}`, ei, httpOptions);
  }
  //don't use  --moving to backend
  addTagForAssign(hdr: any): Observable<Object> {
    console.log(hdr);
    hdr.qstnHdrId = hdr.QstnHdrId ? hdr.QstnHdrId : hdr.id;

    //hdr.type = 't' + hdr.type.substr(1);

    hdr.status = "asgn";

    if (hdr.type && !hdr.type.includes('tasgn')) {
      hdr.type = 'tasgn-' + hdr.type;
    }
    console.log(hdr);
    return this.http.post(`${this.baseUrl}`, hdr, httpOptions);
  }



  //create tavl- tag records
  share(hdr: any): Observable<Object> {

    if (this.cs.tagBased(hdr.type)) {
      hdr.tagHdrId = hdr.tagHdrId ? hdr.tagHdrId : hdr.id;
    } else {
      hdr.qstnHdrId = hdr.QstnHdrId ? hdr.QstnHdrId : hdr.id;
    }


    hdr.type = hdr.type.replace(hdr.status, "shr");
    hdr.status = "shr";
    if (hdr.type && !hdr.type.includes('tshr')) {
      hdr.type = 'tshr-' + hdr.type;
    }

    return this.http.post(`${this.baseUrl}/share`, hdr, httpOptions);
  }


  //localhost/rs/th/changeStatus/111358/tasgn_dhw/asgn
  changeStatus(id: number, tagType: string, status: string, ei?: ExtraInfo): Observable<Object> {
    if (ei) {
      return this.http.put(`${this.baseUrl}/changeStatus/${id}/${tagType}/${status}`, ei, httpOptions);
    } else {
      return this.http.put(`${this.baseUrl}/changeOHStatus/${id}/${tagType}/${status}`, '', httpOptions);
    }

  }

  changeOHStatus(id: number, tagType: string, status: string): Observable<Object>{
    return this.http.put(`${this.baseUrl}/changeOHStatus/${id}/${tagType}/${status}`, '', httpOptions);
   }

   
  changeField(id: number, tagType: string,field:string, value: string): Observable<Object>{
    return this.http.put(`${this.baseUrl}/changeField/${id}/${tagType}/${field}/${value}`, '', httpOptions);
   }


  //localhost/rs/th/changeInactive/111358/tasgn_dhw/true
  changeInactive(id: string, tagType: string, inactive: boolean): Observable<Object> {
    return this.http.put(`${this.baseUrl}/changeInactive/${id}/${tagType}/${inactive}`, ' ', httpOptions);
  }

  update(id: number, value: any): Observable<Object> {
    return this.http.put(`${this.baseUrl}/${id}`, value, httpOptions);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, httpOptions);
  }

  getHeadersList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/prntdtlset/1`);
  }

  loadFilterQstnSet(id: number) {
    let bstrMkr:BstrMkr = new BstrMkr();
    bstrMkr.refBstrId= id; 
    return this.http.post(`${this.baseUrl}/filterQstnSet/${id}`,bstrMkr, httpOptions);
  }
  
  
  getHeadersByDomain(domain: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/byDomain/${domain}`);
  }



  //legacy

  getHeadersByType(type: string): Observable<any> {
    //alert("HI");

    //return this.http.get(`${this.baseUrl}/byTypeWithClsSec/esa/CBSE/X/A/dhw/Mathematics`);

    return this.http.get(`${this.baseUrl}/byTypeWithClsSec/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getClsSec()}/${type}/${this.cs.getSubject()}`);

    //return this.http.get(`${this.baseUrl}/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getOffering()}/cheaders/${this.cs.getSubject()}`);


  }




  getHeadersByTypeFtrHdrId(cmntType: string, table: string, parentHdrId: number) {
    //alert(this.cs.getSite());

    return this.http.get(`${this.baseUrl}/typeFtrHdrid/${this.cs.getSite()}/${cmntType}/${table}/${parentHdrId}`);
    //throw new Error("Method not implemented.");
  }

  getHeadersByTypeFtrDtlId(cmntType: string, table: string, parentHdrId: number, parentDtlId: number) {
    // alert ("dtl calling");
    return this.http.get(`${this.baseUrl}/typeFtrDtlid/${this.cs.getSite()}/${cmntType}/${table}/${parentHdrId}/${parentDtlId}`);
    //throw new Error("Method not implemented.");
  }

  //pass qstn-headerId/unit ID and get all related header for a type,headerId combo
    getUnitHeaders(type: string, unit: string, owner?: string) {

    if (this.fd.hdrGrpNeeded(type)) {
      //console.log("unit is "+ unit);
      if (!unit) {
        return new Observable<[]>();
      }
      if (owner) {
        return this.http.get(this.sServiceUrl + '/byHeaderId/' + type + '/' + unit + '/' + owner);
      }
      else{
        return this.http.get(this.sServiceUrl + '/byHeaderId/' + type + '/' + unit);
      }
     
      }


    else {
      console.log("calling getHeader By Type "+ unit);
      return this.http.get(`${this.sServiceUrl}/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${type}/cheaders/${this.cs.getSubject()}`);
 
    }

    //
  }

//new -way

       // http://localhost/rs/s/stts/esa/NEET/XII/clsSec/type/physics/owner/status/58901

       //return this.http.get(`${this.sServiceUrl}/stts/${this.cs.getSite()}/${this.cs.getBoard()}/${this.///cs.getCourse()}/${this.cs.getClsSec()}/${type}/${this.cs.getSubject()}/owner/status/${unit}`);


  
     //list based on Owner & Status
     filteredList(owner:string,status: string,ovrType?:string): Observable<any> {
      //note-- wip-pbstr can be in other like pyq type during build with click creation
      let offering = ovrType?ovrType:this.cs.getOffering();

      return this.http.get(`${this.cs.getRoot()}/rs/s/stts/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getDfltClsSec(offering)}/${offering}/${this.cs.getJustSubject()}/${owner}/${status}`);
       }
       



       //list based on Owner & Status & headerid
       fltrdHdrList(slctType:string,status:string,hdrId: number): Observable<any> {
         let dfltOwnr = "owner";
        if(slctType=='snote'){dfltOwnr = this.cs.getSignedUser()} 
        return this.http.get(`${this.cs.getRoot()}/rs/s/stts/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getDfltClsSec(this.cs.getOffering())}/${slctType}/${this.cs.getSubject()}/${dfltOwnr}/${status}/${hdrId}`);
         }
  
         
         
       //list based on Owner & Status & headerid
       hdrComboList(slctType:string,status:string,hdrId: number): Observable<any> {
        return this.http.get(`${this.cs.getRoot()}/rs/t/sttsCombo/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getDfltClsSec(this.cs.getOffering())}/${slctType}/${this.cs.getSubject()}/owner/${status}/${hdrId}`);
         }


         
   hdrWithDefault(header?: Header){
    if(!header){
    header = new Header();}
    header.board= this.cs.board;
    header.course= this.course;
    header.subject= this.subject;
    header.type= this.offering? this.offering:this.cs.getOffering()
    if(this.chapter){header.headerId= Number(this.chapter);  }
  //  header.site= this.cs.ss.getUsrSite();
    header.site= this.cs.getJustSite();
    header.school =this.school; 
    header.location =this.schlLoc; 
    header.addUser = this.signedUser;
    header.owner = this.signedUser;
    header.clsSec = this.cs.getCrtClsSec(header.type);
    
 
    if(header.type && !header.status){
     var typeStatus = header.type.split('-'); 
       if(typeStatus.length==2){
       header.status= typeStatus[0].substr(1);
       } else{
         header.status= "raw"; 
          } 
    }
     
    return header;
   }

}