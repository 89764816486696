
<!-- START THE FEATURETTES -->

<div   class="m-2" *ngIf='sp && sp.hdr && sp.dtls && sp.dtls.length>0'>
 
  
  <p class= "text-center pen40 ">   {{sp.hdr.title}}</p>

<ng-container *ngFor="let i = index; let s of sp.dtls;">
 
  <ng-container *ngIf='!s.status || (s.status && s.status != "hide" && (s.status == "beta"  && userGroup.includes("beta_viewer")))' >
    
   

    <hr class="featurette-divider">
  
  <div *ngIf='false && (i%2) === 0' id = "yes" class="row featurette" [ngClass]="{
    'esback': (site=='esa'),
    'ebBack': (site=='eba')
  }">
  <div class="col-md-8">
    <span  *ngIf= "s.status=='beta'|| s.status=='new'"  class="badge text-warning float-left"  > <sup>{{s.status}}</sup> </span>  
     <div>  
    <span class="pen30  featurette-heading " innerHTML= '{{s.instruction}}'> </span> 
     <span class="text-muted"> {{s.title}} </span>
    </div>
    
     <p class="lead " innerHTML= {{s.question}}></p>
   
     <p class="" innerHTML= {{s.answer}}></p>
  </div>
  <div class="col-md-4 text-center"
    innerHTML= '<img src="{{rootUrl}}{{s.questionImages}}" height="150px" width="150px"">'>
    </div>
</div>

<div *ngIf='true ||  (i%2) !== 0' id = "yes" class="row featurette">
  <div class="col-md-8 order-md-2">
    <span  *ngIf= "s.status=='beta'|| s.status=='new'"  class="badge text-warning float-left"  > <sup>{{s.status}}</sup> </span>  
    <div>
    <span class="pen30  featurette-heading" innerHTML= '{{s.instruction}}'> </span>
    <span class="text-muted"> {{s.title}} </span>
  </div>

    <p class="lead " innerHTML= {{s.question}}></p>
    
    <p class="lead " innerHTML= {{s.answer}}></p>
  </div>
  <div class="col-md-4 text-center order-md-1"
   >
<!-- innerHTML= '<img src="{{rootUrl}}{{s.questionImages}}" height="150px" width="150px"">'-->
    <img [src] = s.questionImages  height="150px" width="150px"> 

    </div>
</div>
</ng-container>

</ng-container>

<hr class="featurette-divider">
<app-section-divider [h]= 'sp.hdr'> </app-section-divider>


</div>

