import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { BoardDataService } from '../../board-data.service';
import { QuestionE } from '../../questione';
import { BrowserWindowService } from '../../gen/browser-window.service';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  public users: Array<QuestionE>;
    public totalUsersAmount: number = 0;
    private _currentPage: number = 1;
    private _currentSearchValue: string = '';
    searchTerm:string;
    sField:string;
    content:string = ''

    constructor(public bw:BrowserWindowService,
      private _myService: BoardDataService,private cs:ClientInfoService
    ) { }
    ngOnInit() {

      this._loadUsers(
        this._currentPage,
        this._currentSearchValue
      );
    }

    receiveMessage(sField:string, $event) {
      this.searchTerm = $event
      this.sField = sField;
    }

 openNewWindow(url:string){
    var params = [
    'menubar=1',
    'resizable=1',
    'height='+screen.height,
    'width='+screen.width,
    'fullscreen=yes' // only works in IE, but here for completeness
].join(',');
     // and any other options from
     // https://developer.mozilla.org/en/DOM/window.open
    if(this.cs.bw.hasWindow()){
var popup = window.open(url, 'online-test', params); 

popup.moveTo(0,0);
    }
}

    public filterList(searchParam: string): void {
      //idiot -- searchParam is even message.
      this._currentSearchValue = searchParam;
      this._loadUsers(
        this._currentPage,
        this._currentSearchValue
      );
    }


    public goToPage(page: number): void {
      this._currentPage = page;
      this._loadUsers(
        this._currentPage,
        this._currentSearchValue
      );
    }
    //page, searchParam
    private _loadUsers(
      page: number = 1, searchParam: string = ''
    ) {
      this._myService.getQuestions(
        37951)
      .subscribe((response) => {
        this.users = (response as any).dataList;
        this.totalUsersAmount = (response as any).dataList.length;
      }, (error) => console.error(error));
    }
  }
