<div class= "fullWidth" [ngSwitch]="cmntType.trim()">
  <ng-container *ngSwitchCase='"Hinfo"'>
    <div id="aa" *ngFor="let comment of comments;let i = index; ">
      <app-info-shower [c]=comment> </app-info-shower>
    </div>
  </ng-container>


  <ng-container *ngSwitchDefault>

    <app-cmnt-title *ngIf="!hideTitle" [crtCmnt]=addComment()> </app-cmnt-title>

    <p *ngIf="hideTitle" class="font-italic"> ** {{heading}} ** </p>



    <ng-container *ngIf="comments && (comments.length >0) "
      class="card card-primary border-0">



      <table class="table table-striped">

        <!--  
          <thead>
            <tr>
            <th>#</th>
            
            <th>note/query:</th>
            -->
        <!--
            <th>addDate</th>
            <th>addUser</th>
          </tr>
        </thead>
      -->

        <tbody>
          <div id="aa" *ngFor="let comment of comments;let i = index; ">
            
            <tr>

              <app-cmnt-view [c]=comment> </app-cmnt-view>
              <app-cmnt-rspnse [c]=comment> </app-cmnt-rspnse>

              <td>

                <app-url-shower *ngIf="comment.source" [extLink]=comment.source
                  [title]='uploaded-Cntnt'>
                </app-url-shower>

              </td>

            </tr>

            <tr>
              
              <app-cmnt-crud [c]=comment> </app-cmnt-crud>
              <app-cmnt-crctn *ngIf='enable("crctnBtn") || enable("selfCrctn")' [c]=comment [i]=i>
              </app-cmnt-crctn>
            </tr>

          </div>

        </tbody>


      </table>
    </ng-container>
    
  </ng-container>
</div>