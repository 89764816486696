import { Injectable } from '@angular/core';
import { MathService } from '../math/math.service';
declare var MathJax: any;

@Injectable({
  providedIn: 'root'
})
export class MathJaxService {
i:number=1;

  // https://docs.mathjax.org/en/latest/input/tex/macros/index.html

  constructor(private newMs:MathService) { }

  //non-mathml way
  typeSet(divId?: string) {
    if(MathJax && MathJax.typesetPromise){
    if(this.i >=  0){  
   //console.log("calling typeSet" +this.i++ )   
    MathJax.typesetPromise();
    }
  }

  }


  typeSet1(divId?: string) {
  this.newMs.typeset();
  }
}
