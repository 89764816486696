

<app-subject-lister *ngIf='slSrv.offering' [subjects] = slSrv.subjects > </app-subject-lister> 




<app-offering-lister *ngIf='cs.enable("context", "ofrList") && slSrv.subject' [offerings]= slSrv.offerings></app-offering-lister>


