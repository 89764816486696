<ng-container *ngIf="(hdrs$ | async) as contents; else loading">
    <h5 class= "text-center m-2" > {{title}} </h5>
  
    <button *ngIf= '!allSelected'  class= "mx-3 btn btn-outline-primary badge "  (click)= 'selectAll(contents)'>
      select All </button>   
      
      <button *ngIf= 'allSelected'  class= "mx-3 btn btn-outline-primary badge "  (click)= 'unselectAll(contents)'>
        clear selection(s) </button>   
    

      <button *ngIf= 'allowEdit'  class= "mx-3 btn btn-outline-primary badge "  (click)= 'showAdd =!showAdd'>
       Add Header </button>   
        
       
      <div *ngIf= "showAdd" class= "my-3 mx-5">
  
       <app-qstnhdr-add class="my-5"> </app-qstnhdr-add>
      
      </div>
   
  
    <table datatable [dtOptions]="dtOptions"  class="row-border hover">
       <thead>
       <tr>
         <th *ngFor="let fld of fldList,let i=index;">
           
          
            {{fld}}

         </th>
         <th>
           Feature
         </th>
       </tr>
       </thead>
       <tbody>
  
         <tr *ngFor="let c of contents;let i=index">
       <td *ngFor="let val of getValList(c), let i=index;">
      {{val}}
     
      </td>
            
           <td>
            
           
            <button *ngIf='allowEdit' class= "mx-3 btn btn-outline-primary badge "  (click)= 'expand(c,i)'>
             Expand </button>   

             <app-crud-btn [header]=c [i]=i>
            </app-crud-btn>
          
          
              
            </td>
         
            <td>
              <app-rec-slctr [fldVal] = c.id [storeKey] = 'multiStoreKey' > </app-rec-slctr>
              <app-view-lstr [hdr] = c> </app-view-lstr>
          
            </td>
           
  
         </tr>
           
     </tbody>
   
    </table>
  
  </ng-container>
   
  <!--exam special bstr from tag records ie booster itself -->
  
  <app-bstr-mkr *ngIf="qh.cat.startsWith('pbstr')"> </app-bstr-mkr>
  
  <!--chptr bstr from actual source like hwh etc.  -->
  
  <app-cstm-bstr-mkr *ngIf="qh.cat.startsWith('mkr-')|| qh.cat.startsWith('tgmbstr') || qh.cat.startsWith('tgubstr')" [bstrType]='"multi"'> </app-cstm-bstr-mkr>
  
  <!--
-->

  <div *ngIf= "allowEdit && expandI>=0" class= "my-3 mx-5">
    <app-my-json [table]= 'getTable(c)' class="my-5"  [q] = "c" > </app-my-json>
  </div>
  
   
   
   <ng-template #noCall>!</ng-template>
  