import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,of,Subject} from 'rxjs';
import { HttpHeaders,HttpResponse } from '@angular/common/http';
import { ClientInfoService } from '../shared/client-info.service';
import { Detail } from './detail';
import { DatePipe } from '@angular/common';
import { Time } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { MessageService } from '../message.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    //'Authorization': 'my-auth-token',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'HEAD,GET,POST,PUT,PATCH,DELETE',
    responseType: 'text',
  })
  };

  @Injectable({
    providedIn: 'root'
  })

  export class QstnDtlService {
//Subject which will be pushed from child to advise parent to update List.
public static updateList: Subject<boolean> = new Subject();
public static updateVidList: Subject<boolean> = new Subject();
public static updateDhwHdrId : Subject<number> = new Subject();
   
    


 
  get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get chapter() { return this.cs.getChapter()};
  get clsSec() { return this.cs.getClsSec()};
  get offering() { return this.cs.getOffering()};

  get baseUrl() {
     if(this.cat.startsWith("prd") || this.cs.getOffering() && this.cs.getOffering() == "cfg") 
  { return this.cs.getRoot()+ "/rs/cd";}
  else {return this.cs.getRoot()+ "/rs/qd";} };

  get subject() { return this.cs.getSubject()};
  get school() { return this.cs.getSchool()};
  get schlLoc() { return this.cs.getSchlLoc()};
  get signedUser() { return this.cs.getSignedUser()};

  

   constructor(private http: HttpClient,public cs:ClientInfoService,public datepipe: DatePipe,private messageService: MessageService) {
    
    
    
   }



  get(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/detail/${id}`);
  }


 getQstnSetNoneCache(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/qstnSet/crnt/${id}`);
 }

 getQstnSetForHdrDtl(id: number): Observable<any> {

return this.http.get(`${this.cs.getRoot()}/rs/s/qstnSetForHdrDtl/${this.chapter}/crnt/${id}`);
}

 getNextQstnSet(id: number): Observable<any> {
  return this.http.get(`${this.baseUrl}/qstnSet/next/${id}`);
}

getPrvsQstnSet(id: number): Observable<any> {
  return this.http.get(`${this.baseUrl}/qstnSet/prvs/${id}`);
}

  create(detail: Detail): Observable<Object> {
    //console.log(detail);

    const isNumber = (val: string | number) => !!(val || val === 0) && !isNaN(Number(val.toString()));

    if(!isNumber(detail.marks)){ 
      detail.marks= null;
      console.log("marks set to null");
    }
    return this.http.post(`${this.baseUrl}`, detail).pipe(
      catchError(this.handleError('create', []))
    );
  }
  
  populateDefault(detail: Detail, hdr?:any){
   //detail = new Detail();

   if(hdr){
     detail.headerId = hdr.id;
    } 
   else{
   if(this.chapter){detail.headerId= Number(this.chapter);  }
   }
   detail.addUser= this.signedUser;
   let myDate = new Date();
  //detail.addDate= this.datepipe.transform(myDate, 'yyyy/MM/dd')
  detail.addDate = myDate;
  detail.addTime = myDate.getTime();
   return detail;
  }

  update(id: number, value: any): Observable<Object> {
    return this.http.put(`${this.baseUrl}/${id}`, value,httpOptions).pipe(
      catchError(this.handleError('update', [])));
  }

     
  changeField(id: number, type: string,field:string, value: string): Observable<Object>{
    return this.http.put(`${this.baseUrl}/changeField/${id}/${type}/${field}/${value}`, '', httpOptions);
   }


  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`,httpOptions ).pipe(
      catchError(this.handleError('delete', [])));
  }

  // Lister interface gives raw data without any overriding of qstns
  list(hdrId: number): Observable<any> {
   return this.http.get(`${this.baseUrl}/list/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getClsSec()}/${this.cs.getOffering()}/${this.cs.getSubject()}/${hdrId}`).pipe(
    catchError(this.handleError('qstn-dtl-srvc-list', [])));
   }


   
 
  
//localhost/rs/qd/changeStatus/111358/tasgn_dhw/asgn
changeStatus(id: string, status:string): Observable<Object> {
  return this.http.put(`${this.baseUrl}/changeStatus/${id}/${status}`,' ' ,httpOptions).pipe(
    catchError(this.handleError('changeStatus', [])));;
}

//localhost/rs/qd/changeInactive/111358/tasgn_dhw/true
changeInactive(id: string, inactive:boolean): Observable<Object> {
  return this.http.put(`${this.baseUrl}/changeInactive/${this.cs.getChapter()}/${id}/${inactive}`,' ' ,httpOptions);
}


digitize(id: number): Observable<Object> {
  return this.http.put(`${this.baseUrl}/dgtz/${id}`, null,httpOptions).pipe(
    catchError(this.handleError('update', [])));
}


tblBldr(q:any): Observable<Object> {
  return this.http.put(`${this.baseUrl}/tblBldr/${q.id}`, q,httpOptions).pipe(
    catchError(this.handleError('update', [])));
}


split(id: number): Observable<Object> {
  return this.http.put(`${this.baseUrl}/split/${id}`, null,httpOptions).pipe(
    catchError(this.handleError('update', [])));
}


removeMCQ(id: number): Observable<Object> {
  return this.http.put(`${this.baseUrl}/remove-mcq/${id}`, null,httpOptions).pipe(
    catchError(this.handleError('update', [])));
}

mergeQstns(headerId: number,qstnIdList:number[]): Observable<Object> {
   return this.http.put(`${this.baseUrl}/mergeQstn/${headerId}/${qstnIdList}`, qstnIdList,httpOptions).pipe(
    catchError(this.handleError('update', [])));
}


private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {
    
    // TODO: send the error to remote logging infrastructure
    //console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

private log(message: string) {
  
  this.cs.addMsg("Some errors occured,please try again later");
  this.messageService.add(`qstn-dtl.service: ${message}`);
}



}