
<div class="container fullWidth px-0 mx-0">
    
    
    <ng-template #justQstn>
    <span  class="btn  font-weight-bold"> #{{qs.formatSeq(q.sequence,q.subSeq,q.part)}}
    </span>
    </ng-template>

    <!--class="btn btn-secondary border-0 badge " style="margin-left: 10px"-->
    
    <!--
    <a class="font-weight-bold" *ngIf='showLink(q) else justQstn' href="{{qs.navigateQstn(qs.chapter,q)}}" >
         #{{qs.formatSeq(q.sequence,q.subSeq,q.part)}}</a>
         
         showLink? {{showLink(q)}} {{q|json}}
        -->

         <a *ngIf='showLink(q) else justQstn' class= "font-weight-bold "  
         [routerLink] = 'qs.navigateQstn(qs.chapter,q)' 
         >Qstn #{{qs.formatSeq(q.sequence,q.subSeq,q.part)}} </a>
    
         
    <span *ngIf = 'q.marks' class= "float-right"> [{{q.marks}}] </span>

    <span class="badge text-danger btn-secondary"
        *ngIf="q.status == 'outSlbs21'"> Deleted for 21 </span>
        
        <span *ngIf= "q.format && qs.enable('showFormat')" class="badge text-danger btn-secondary"
        > {{q.format}} </span>

       
 
        <span class="badge text-info"
        *ngIf="qs.cat.includes('bldr')"> -  {{q.id}} </span>
  
   

    <div class="text-center" *ngIf="q.title">
        <span class=""  [innerHTML]= 'q.title' > </span>
    </div>

    <div class="text-center" *ngIf="q.section && !q.sequence">
        <span class="lead"> Section : {{q.section}} </span>
       
        <span *ngIf='q.groupId && qs.offering.includes("cfg")' class="lead"> /Group Id : {{q.groupId}} </span>

    </div>


    
    <div *ngIf="(q.vidLink && qs.offering != 'cfg')|| (q.wireFrame && q.format && q.format== 'vid')" class="my-2 text-center">
    
        <app-vid-dtl [q] = q> </app-vid-dtl>
    <!--
        <app-video class= "text-center mb-1 border-0" [q]=q [i]=i> ...</app-video>
    -->
    </div>

    <div *ngIf="q.wireFrame && (!q.format ||q.format != 'vid') " class="text-center embed-responsive embed-responsive-16by9">
        <!--
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
         -->   
        <span  class="embed-responsive-item" [innerHTML] = "q.wireFrame|safe :'html'"></span>
      </div>

   
    

    <div *ngIf="q.instruction" class=""
        [innerHTML]= "q.instruction|safe :'html'"></div>

    
    <!--&& !q.digQstn-->
    <div *ngIf="q.content" >
        <div [innerHTML] = "q.content|safe :'html'"> </div>
    </div>

    <div *ngIf="false && q.digQstn" >
    <a href="{{qs.navigateQstn(qs.chapter,q)}}" style="margin-left: 10px">
        <div [innerHTML] = "q.digQstn|safe :'html'"> </div>
    </a>
    <app-mcq-optn [q]= q > </app-mcq-optn>
            
    </div>

    <!-- works can be enabled  height= "200px" -->
    <div  *ngIf= 'q.contentImages'  class= "text-center"> 
        <img  [src] = "q.contentImages|safe:'resourceUrl'" height= "250px" alt= "">
    </div>
  

    <a *ngIf="q.extLink && q.extLink.includes('pdf')" [href] = 'qs.cs.getRoot() + q.extLink' > browser view </a>
    <hr> 

    <app-pdfviewer class= "text-center col-12"  *ngIf="q.extLink && q.extLink.includes('pdf')" src= '{{qs.cs.getRoot() + q.extLink}}' [startPage] = startPage > </app-pdfviewer>

    <app-url-shower class= "float-center" *ngIf="q.extLink" [format]=q.format [extLink]=q.extLink
    [title]=q.title [headerObj]=null>
    </app-url-shower>
    
    
    <div class= "float-right mr-3" *ngIf="q.wireFrame || q.vidLink || q.format=='intVid' "> 
        <app-rating class= "float-right" [q] = q> </app-rating> 
     </div>

     
    </div>