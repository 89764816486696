

<div [hidden]="submitted" >
    <h3>Create Detail:</h3>
    <form (ngSubmit)="onSubmit()">
                

      <div  class="form-group">
        <label for="sequence">Sequence# </label>
        <input type="text" class="form-control" id="sequence" required [(ngModel)]="detail.sequence" name="sequence">
      </div>
  
      <div  class="form-group">
        <label for="subSeq">SubSeq: </label>
        <input type="text" class="form-control" id="subSeq" required [(ngModel)]="detail.subSeq" name="subSeq">
      </div>
  
      <div  class="form-group">
        <label for="part">Part</label>
        <input type="text" class="form-control" id="part" required [(ngModel)]="detail.part" name="part">
      </div>
  
      <div  class="form-group">
          <label for="tagHdrId">tagHdrId# </label>
          <input type="number" class="form-control" id="tagHdrId" required [(ngModel)]="detail.tagHdrId" name="tagHdrId">
        </div>
  
        
      <div  class="form-group">
        <label for="qstnDtlId">QstnDtl# </label>
        <input type="text" class="form-control" id="qstnDtlId" required [(ngModel)]="detail.qstnDtlId" name="qstnDtlId">
      </div>

      

      <!--
      <div class="form-group">
        <label for="message">Message/Query</label>
        
        <ckeditor editorUrl="\assets\ckeditor\ckeditor.js"
            #editor
            [(ngModel)]="comment.comment"
            id="message"
                      name="message"  >
          </ckeditor>
        --> 
  
        <!--
  
          type="divarea"
          [config]="{ toolbar: [ [ 'Bold' ] ] }"
  
        <input type="text" class="form-control" id="message" required [(ngModel)]="comment.comment" name="message">
        
      </div>
      -->

      <button type="submit" class="btn btn-success">Submit</button>
    </form>
  </div>
  <div [hidden]="!submitted">
    <h4>You submitted successfully!</h4>
    <!-- <button class="btn btn-success" (click)="newComment()">Add</button> -->
  </div>