


import { CommentService } from '../comment.service';
import { Comment } from '../comment';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FileService } from '../../shared/file.service';
import { Subscription } from 'rxjs';
import { ClientInfoService } from '../../shared/client-info.service';
import { SecurityService } from '../../shared/security.service';
import { ERROR_COMPONENT_TYPE } from '@angular/compiler';
import { ConfigService } from '../../shared/config.service';


@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit, OnDestroy {
  @Input() iComment :Comment;
  @Input() i:number;

  @Input() cmntHeading:string;
  @Input() upload:boolean;
  askModes = ['1-1','classroom'];
  askMode= 'classroom';
  updateFileSub: Subscription;
 
  @Output('update')
  change: EventEmitter<boolean> = new EventEmitter<boolean>();
  askFlag: boolean;
  
get signedUser() { return this.sec.getSignedUser()}
get clsSec() { return this.cs.getCrtClsSec()}
get course() { return this.sec.getCourse()}
get offering() { return this.cs.getOffering()}
 new:boolean;

 public comment: Comment = null;
  submitted = false;
  constructor(private commentService: CommentService,private cs:ClientInfoService,private sec:SecurityService,
    public cfg:ConfigService,
    private router: Router) { }
    messageTxt: string;
    toggleUpload:boolean; //toggle
  ngOnInit() {
    this.createComment();
    if(this.comment.cmntType =="Dclr"){
    this.comment.status= "classroom";
    this.askFlag= true;
    }
    //this gets file upload url below asynchronously
    this.updateFileSub = FileService.updateUploadUrl.subscribe(res=>{
    //this.comment.source= res;
    if(res.includes('etl')){
    this.comment.comment= this.comment.comment +'<br> <img src = \"'+res + '\" />';
    }   
      //please change it to extUrl later
      //alert("Comment Service got the upload file" + res + this.comment.source);
     } );
    
  }

  enable(btnId:string,comment?:any){
  return this.commentService.enable(btnId,comment); 
   }

  
 enable1Delete(btnId:string,comment?:any){
 
 if(btnId=="attach") {
  if(this.iComment.cmntType =='Dclr'|| this.iComment.cmntType =='Dans'){
   return true;
  }  else{
  return this.upload;
  } }
  
  if(btnId=="ckeditor") {
    return comment.cmntType.includes('ans')||comment.cmntType.includes('clr')}
  
    
//trueFlag name for true Button
  if("this.iComment.cmntType).includes('cmnt')"){
    return false;
  }  else{
    return false;
    //return true;??
  }
    
  }

  createComment(): void {
    this.submitted = false;

    if(this.iComment){
    this.comment = this.iComment;
    
    } else{
     this.comment = new Comment();
    }
    this.comment.addUser= this.signedUser;
  this.loadLabelTxt(this.iComment.cmntType);  
  
  
  }

  loadLabelTxt(cmntType:string){
    switch(cmntType) { 
      case 'Dans' : { 
        this.messageTxt= "Answer:" 
        break; 
      } 
      case 'Dclr': { 
         //statements; 
         break; 
      } 
      default: { 
        this.messageTxt= "Message/Query:" 
         break; 
      } 
   } 
  }

  save() {
       
    this.commentService.createComment(this.comment)
      .subscribe(data => 
        {console.log(data);
    this.cs.addMsg((data as any).message)
          this.comment = new Comment();

    this.gotoList();
    }, error => {
      console.log(error);
      let msg= error.statusText?error.statusText:error.message();
      this.cs.addMsg(msg,"warning");
    } 
    )
  }

  onSubmit() {
    this.submitted = true;
    this.save();    
  }

  ngOnDestroy(): void {
    this.updateFileSub.unsubscribe()
}

  gotoList() {
    
  // alert(this.cmntHeading + " added");
   //the change way is stateful data passing..hence should be better
   this.change.emit(true);
   //CommentService.updateList.next(true);
   
   // this.router.navigate(['//eba//digibest//features//comments']);
  }
}
