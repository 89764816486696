import { Component, OnInit, Input } from '@angular/core';
import { Detail } from '../detail';
import { DetailService } from '../detail.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-show-detail',
  templateUrl: './show-detail.component.html',
  styleUrls: ['./show-detail.component.scss']
})
export class ShowDetailComponent implements OnInit {
 
 @Input()  id: number;
  detail: Detail;

  constructor(private route: ActivatedRoute,private router: Router,
    private detailService: DetailService) { }

  ngOnInit() {
    this.detail = new Detail();
   // this.id = this.route.snapshot.params['id'];
    
    this.detailService.getDetail(this.id)
      .subscribe(data => {
       // console.log(data)
        this.detail = data.result;
      }, error => console.log(error));
  }

  list(){
   // alert("detail added");
   DetailService.updateList.next(true);
    //this.router.navigate(['//eba//digibest//features//details']);
  }
}