import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';


import { UiModule } from './ui/ui.module';
import { PagesModule } from './pages/pages.module';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
//import { AdsenseModule } from 'ng2-adsense';
//import { MessagesComponent } from './messages/messages.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OfferinglistComponent } from './pages/offeringlist/offeringlist.component';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

//import { CodeEditorModule } from '@ngstack/code-editor';

//import { ChatModule } from './chat/chat.module';

//import { SanitizeHtmlPipe } from './shared/Sanitize-html.pipe';
//import { SafePipe } from './shared/safe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    
   // MessagesComponent,
   
    //SafePipe,

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    UiModule,
    PagesModule,
    HttpClientModule,
    // NgbModule,
    FormsModule,
    //CodeEditorModule.forRoot(),
    //NgxGoogleAnalyticsModule.forRoot('G-3PQQQEG267'),
    NgxGoogleAnalyticsRouterModule,
    NgxExtendedPdfViewerModule,
    SharedModule,
    
    /* moved to shared module
    AdsenseModule.forRoot({
        adClient: 'ca-pub-4808501513929067', //replace with your client from google adsense
        adSlot: 2882359303, //replace with your slot from google adsense
        //timeOutRetry:5000 ,
      }),
    */
     FontAwesomeModule,
    
    NgbModule,
    DataTablesModule,
        
    //ChatModule,
        
  ],
  exports:[OfferinglistComponent],
  
 
  bootstrap: [AppComponent]
})
export class AppModule { }
