import { Component, OnInit, Input } from '@angular/core';
import { Detail } from '../detail';
import { QstnDtlService } from '../qstn-dtl.service';
import { Router } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-add-qstn-dtl',
  templateUrl: './add-qstn-dtl.component.html',
  styleUrls: ['./add-qstn-dtl.component.scss']
})
export class AddQstnDtlComponent implements OnInit {

  
  @Input() iDetail :Detail;

  get offering() { return this.cs.getOffering()};
  expand: boolean;
  
  public detail: Detail = null;
  submitted = false;
  constructor(private DetailService: QstnDtlService,
    private router: Router,private cs:ClientInfoService) { }

  ngOnInit() {
    this.createDetail();
  }

  createDetail(): void {
    this.submitted = false;

    if(this.iDetail){
      this.detail = this.iDetail;
    } else{
     this.detail = new Detail();
     this.detail= this.DetailService.populateDefault(this.detail);
     }
  
    
  }

  save() {
       
    this.DetailService.create(this.detail)
      .subscribe(data => 
        {
          console.log(data);
          if((data as any).status === 200) {
  
          this.detail = new Detail();
         this.cs.addMsg((data as any).message,"info"); 
          }
         this.gotoList();
                 
        }, error => console.log(error));
     }

  onSubmit() {
    this.submitted = true;
    this.save();    
  }


  gotoList() {
   QstnDtlService.updateList.next(true);
   
   // this.router.navigate(['//eba//digibest//features//Details']);
  }
}
