import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ɵangular_packages_platform_browser_platform_browser_g } from '@angular/platform-browser';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';
import { CommentService } from '../../comments/comment.service';

@Component({
  selector: 'app-doubt-dstr',
  templateUrl: './doubt-dstr.component.html',
  styleUrls: ['./doubt-dstr.component.scss']
})


export class DoubtDstrComponent implements OnInit {
  @Input() q: any;
  @Input() i: number;
  showDoubt: boolean;
  showNoteI: number;

  //below is not used
  @Output('update')
  change: EventEmitter<boolean> = new EventEmitter<boolean>();

  askFlag: boolean;
  clarify: boolean;
  
  showRfcI: any;
  headerId: any;
  selfNote: boolean;
  dsrvsn: boolean;
  hasMentor: boolean = false; // get from student profile



  constructor(public qs: QstnListService, private commentService: CommentService) { }

  ngOnInit(): void {
    this.headerId = this.qs.chapter ? this.qs.chapter : this.q.headerId;
    // console.log(this.qstn);
  }

  showCmntList(cmntCode) {
    
    if (cmntCode == 'Dclr') {
      if (this.qs.unsigned) {
        this.qs.cs.addMsg("info: signin to avail personalization")
        return;
      } else {
        this.clarify = !this.clarify
      }
    }

    
    if (cmntCode == 'Dsrvsn') {
      if (this.qs.unsigned) {
        this.qs.cs.addMsg("info: signin to avail personalization")
        return;
      } else {
        this.dsrvsn = !this.dsrvsn;
      }
    }


    if (cmntCode == 'DselfNote') {
      if (this.qs.unsigned) {
        this.qs.cs.addMsg("info: signin to avail personalization")
        return;
      } else {
        this.selfNote = !this.selfNote;
      }
    }

  }

  tagQstn(tagType: string) {
    if (this.qs.unsigned) {
      this.qs.cs.addMsg("info: signin to avail personalization")
      return;
    } else{
      if (this.hasMentor) {
        this.qs.cs.addMsg("info: please add parent/teacher to avail this")
        return;
    }
  }

    let cmnt = this.qs.prepPostData(tagType, 'please explain this', this.q.id, this.i);

    this.commentService.createComment(cmnt)
      .subscribe(data => {
        if ((data as any).status === 200) {
          if (tagType == "Dbkt") {
            this.change.emit(true);
            if (!this.q.bkmCmnts) {
              this.q.bkmCmnts = [];
            }
            this.q.bkmCmnts.push((data as any).result);
          }
          this.qs.cs.addMsg("successful", 'toast')
        }

        else {
          this.qs.cs.addMsg("info: try later")
        }
      }
      );

  }


  processRfc(q, i) {
    this.showRfcI == i ? this.showRfcI = -1 : this.showRfcI = i;
  }



}
