import { Injectable } from '@angular/core';
import { resolveSanitizationFn } from '@angular/compiler/src/render3/view/template';
import { StatusChangerComponent } from './status-changer/status-changer.component';
import { MessageService } from '../message.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {


  constructor(public ms: MessageService) { }

  getUserDfltSubject(): string {
    if (this.principal) {
      return this.principal.subject;
    } 

  }
  
  getMentorId() {
    if (this.principal) {
      return this.principal.mentorId;
    } else {
      //return 'cmn';
    }
  }


  /*
    //not used 
    getMyNav(): string {
    
   if(this.myNav){ return this.myNav; }
  
   return localStorage.getItem('myNav') ;
  
    }
  */


  learnMode: string;
  groupId: string;
  userName: string;
  clsSec: string;
  myNav: string;
  

  get principal() { return JSON.parse(localStorage.getItem('principal')) };
  get site() { return JSON.parse(localStorage.getItem('site')) };  

  setClsSec(clsSec: string) {
    localStorage.setItem('clsSec', clsSec);
    this.clsSec = clsSec;
  }


  // for flipping role, keep seperate for features to work smoothly

  getRoleOptions() {

    if (this.principal) {
      return this.principal.roles;
    }
  }

  //non-essential ,not in use , use it for security later
  getUsrSite() {
  if (this.principal) {
    return this.principal.learnMode + [this.principal.school,this.principal.location].filter(Boolean).join('-') ;
   } 
   
  }


  getSchool() {

    if (this.principal) {
      return this.principal.school;
    } else {
      //return 'cmn';
    }
    
  }


  getSchlLoc() {
    if (this.principal) {
      return this.principal.location;
    } else {
      //return 'cmn';
    }
  }


  getShrGrp() {
    if (this.principal) {
      return this.principal.shrGrp;
    } else{
      return null;
    } 

  }



  getBoard() {
    if (localStorage.getItem('board')) {
      //return JSON.parse(localStorage.getItem('board'));
      return localStorage.getItem('board');
    } else {
      //return 'cmn';
     //this.ms.add("CBSE-X selected by default,please change if needed" ,"alert"); 
    //  return 'CBSE';
    }
  }


  getCourse() {

    if (localStorage.getItem('course')) {
      // return JSON.parse(localStorage.getItem('course'));
      return localStorage.getItem('course');

    } else {
      //  return 'cmn';
     // return 'X';
    }
  }


  // check it properly...
  // forstudent take principal clsSec, for teacher, take selectedClsSec
  //default clsSec or cmn?? for my clsSec working..

  getClsSec() {

    if (!this.clsSec && localStorage.getItem('clsSec')) { 
      this.clsSec= localStorage.getItem('clsSec');
     // console.log("clsSec is set to "+ this.clsSec)
    }
    return this.clsSec? this.clsSec:"clsSec" 
  }

  //original from db
  getUserClsSec() {
    if (this.principal && this.principal.clsSec) {
      return this.principal.clsSec;
    } else {
      return null;
    }
  }


  

  getSignedUser() {
    var principal = JSON.parse(localStorage.getItem('principal'));
    if (principal) {
      return principal.username;
    } else {
      return 'Guest';
    }
  }

  
  isUnsigned() {
    return this.getSignedUser() == 'Guest';
    }
    
  getDefaultSite() {
    var principal = JSON.parse(localStorage.getItem('principal'));
    if (principal) {
      return principal.domain;
    } else {
      return localStorage.getItem('domain');
    }
  }

   userDfltCat(){
    return  this.getUserMenu();
   }

  getUserMenu() {
    var principal = JSON.parse(localStorage.getItem('principal'));
 
  let dfltMnu = 'learner';

  if (principal) {

  dfltMnu = principal.dfltMnu?principal.dfltMnu:principal.groupId;
    }

  if(dfltMnu.includes("-") ){ dfltMnu= dfltMnu.split("-")[1]}

 //if(dfltMnu =='teacher' ||dfltMnu =='learner' ){dfltMnu= dfltMnu.substr(0,dfltMnu.length-2);}
  return dfltMnu;  
  }


  

  signin(principal: any,remember?:boolean) {

    
        //localStorage.clear();
        if(remember){
          localStorage.setItem('user', principal.userId);
        }

    if (principal.domain) {
      localStorage.setItem('domain', principal.domain);
    }

    localStorage.setItem('principal', JSON.stringify(principal));

    localStorage.setItem('signedUser', principal.userId);
    //localStorage.setItem('authorities',JSON.stringify(data.result.authorities));
    if (principal.learnMode) {
      localStorage.setItem('learnMode', principal.learnMode);
    }
    if (principal.groupId) {
      localStorage.setItem('groupId', principal.groupId);
    }


    /* 
    let pr = principal;  
    //this.myNav = [pr.learnMode+pr.school +"-" +pr.location, pr.groupId,pr.board,pr.grade];
    this.myNav = ["/", pr.learnMode+pr.school +"-" +pr.location, pr.groupId,pr.board,pr.grade].filter(Boolean).join('/');
    localStorage.setItem('myNav', this.myNav);
   */

    //localStorage.setItem('userDetail',JSON.stringify(principal));     
    if (principal) {
      if (principal.board) {
      localStorage.setItem('board', principal.board);
      }
      if (principal.grade) {
      localStorage.setItem('course', principal.grade);
      }
      if(principal.clsSec){
      localStorage.setItem('clsSec', principal.clsSec);
       console.log("sign in set clssec to "+ principal.clsSec); 
    }  
    }
  }


  
  clearLocalStorage(){
  
   localStorage.removeItem('principal');
   localStorage.removeItem('signedUser');
   localStorage.removeItem('learnMode');
   localStorage.removeItem('groupId');
   localStorage.removeItem('clsSec');

   // introduced to clear default domain
   localStorage.removeItem('domain');
   localStorage.removeItem('userMenu');
   localStorage.removeItem('myPage');
  }

  


  //main role

  getGroupId() {

    this.groupId = localStorage.getItem('groupId');

    if (!this.groupId) {
      var principal = JSON.parse(localStorage.getItem('principal'));
      if (principal) {
        return principal.groupId;
      } else {
        return 'UNSIGNED';
      }
    }
    return this.groupId;
  }

  //return First Name
  getUserName() {

    this.userName = localStorage.getItem('userName');

    if (!this.userName) {
      var principal = JSON.parse(localStorage.getItem('principal'));
      if (principal) {
        return principal.firstName;
      } else {
        return '';
      }
    }
    return this.userName;
  }

  getId() {
    var principal = JSON.parse(localStorage.getItem('principal'));
    if (principal) {
      return principal.id;
    }
    else {
      return '';
    }
  }

  

  getHeaderId() {
    var principal = JSON.parse(localStorage.getItem('principal'));
    if (principal) {
      return principal.headerId;
    }
    else {
      return '';
    }
  }


  getUserDomain() {
    var principal = JSON.parse(localStorage.getItem('principal'));
    if (principal) {
      return principal.domain;
    }
    else {
      return '';
    }
  }

  getRoles() {
   
    if (this.principal) {
      return this.principal.roles;
    }
  }

  getLearnMode() {
    this.learnMode = localStorage.getItem('learnMode');
    if (!this.learnMode) {
      var principal = JSON.parse(localStorage.getItem('principal'));
      if (principal && principal.learnMode) {
        this.learnMode = principal.learnMode;
      } else {
        this.learnMode = 'LearnMode?';
      }

    }
    return this.learnMode;

  }

  setLearnMode(learnMode: string) {
    localStorage.setItem('learnMode', learnMode);
    this.learnMode = learnMode;

  }

  setGroupId(groupId: string) {
    localStorage.setItem('groupId', groupId);
    this.groupId = groupId;

  }


  getLearningMode1() {
    var auth = localStorage.getItem('principal');
    //SelfLrnr,PrntCntrl,TutCntrl
    if (auth) {
      // console.log(auth) ;

      if (auth.includes('SelfGuided')) {
        return "SelfGuided";
      }

      if (auth.includes('ParentGuided')) {
        return "ParentGuided";
      }


      if (auth.includes('user')) {
        return "SmartMode";
      }

      if (auth.includes('TutorGuided')) {
        return "TutorGuided";
      }


      if (auth.includes('teacher')) {
        return "TutorGuided";
      }
    }

    return "Excellence:)";

  }

//give all authority list
  getUserGroup() {
    //alert("inside userGroup");
    var userGroup: string[] = [];
    var principal = JSON.parse(localStorage.getItem('principal'));
    if (principal && principal.groupId) {
      //main group Id Of User..
      userGroup.push(principal.groupId)
      var authorities: [] = principal.authorities;
    }
    if (authorities) {
      (authorities as any).forEach(elem => {
        // console.log(elem + " / "  + elem.authority);
        userGroup.push(elem.authority);
      });
    }
    else {
    }
    
     //console.log("from get user Group:" +userGroup);

    return userGroup;

  }

}
