import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SeoTempMapperService {

  constructor(public router: Router){

  }

  
  siteMapper(site: string){
   var siteMap : Map<string,string> ;
    siteMap = new Map<string, any>();
    siteMap.set('esa-es', 'esa');
    siteMap.set('es', 'esa');
    siteMap.set('esa-home', 'esa');
    siteMap.set('esa-ins', 'esa');
    
    

    if(siteMap.has(site)){
    let value = siteMap.get(site); 
     this.redirect(site,value)
    }
  }
  
  redirect(key:string,value:string){
   let url = this.router.url
   //console.log('url fetched', url)
   if(url.endsWith("/"+key)){
    url = this.router.url.replace("/"+key,"/"+value);
   } else{
     url = this.router.url.replace("/"+key +"/","/"+value+ "/");
   } 
   
  //console.log('redirecting to', url)
  this.router.navigate([url]);
  }

  catMapper(cat: string){

    let catMap : Map<string,string> ;
    catMap = new Map<string, any>();
    catMap.set('ee', 'special');
    
    if(catMap.has(cat)){
      let value = catMap.get(cat); 
      this.redirect(cat,value)
      }
      
  }

  
}
