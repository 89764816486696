obsolete??

<div [hidden]="submitted" >
   
        <form #ngform (ngSubmit)="onSubmit()">
     
       <div *ngIf='uti' >  
       char after v:   https://www.youtube.com/watch?v=<span class='bg-info'>bKKJkxqIg94</span>
      </div>

          <div  class="form-group">
            <label for="vidLink">YouTube Vid Id: 
              <button type= "button" (click) = 'uti=!uti'> help</button>
             </label>
            <input type="text" class="form-control" id="vidLink" required [(ngModel)]="detail.vidLink" name="vidLink">
                    </div>
          
          
         
          
      <div  class="form-group">
          <label for="wireFrame">wireFrame: </label>
          <input type="string" class="form-control" id="wireFrame"  [(ngModel)]="detail.wireFrame" name="wireFrame">
        </div>
      
                <div  class="form-group">
            <label for="label">Title/Label: </label>
            <input type="text" class="form-control" id="label" required [(ngModel)]="detail.title" name="label">
          </div>  
      
          <div  class="form-group">
            <label for="instruction">Description: </label>
            <input type="text" class="form-control" id="instruction" [(ngModel)]="detail.instruction" name="instruction">
          </div>
      
          <div  class="form-group">
            <label for="marks">Score out of 5: </label>
            <input type="text" class="form-control" id="marks" [(ngModel)]="detail.marks" name="marks">
          </div>


    <button type= "button" class="mx-2 btn btn-info" (click) = "expand=!expand"> {{expand?"-": "+"}}</button>

    <!-- Field expander start -->
    
    <div *ngIf= "expand"> 

     
      
      <app-file-upload  (uploadedObj)='getChildOutput($event)'>
      </app-file-upload>
    

     
      <div *ngIf= "detail.extLink" class="form-group">
        <label for="extLink">Upload Url-int: </label>
        <input type="text" class="form-control" id="extLink" required [(ngModel)]="detail.extLink" name="extLink">
      </div>
      

        <div  class="form-group">
            <label for="category">category: </label>
            <input type="text" class="form-control" id="category" required [(ngModel)]="detail.category" name="category">
          </div>
    
      
          
     <div  class="form-group">
        <label for="headerId"> headerId: </label>
        <input type="text" class="form-control" id="headerId" required [(ngModel)]="detail.headerId" name="headerId">
      </div>

      <div  class="form-group">
        <label for="groupId">groupId </label>
        <input type="text" class="form-control" id="groupId" required [(ngModel)]="detail.groupId" name="groupId">
      </div>
      
      <div  class="form-group">
        <label for="section">Section: </label>
        <input type="text" class="form-control" id="section" required [(ngModel)]="detail.section" name="section">
      </div>

      <div  class="form-group">
        <label for="qstnId">qstnId: </label>
        <input type="text" class="form-control" id="qstnId" required [(ngModel)]="detail.qstnId" name="qstnId">
      </div>

      

      <div  class="form-group">
        <label for="subqstnId">subqstnId: </label>
        <input type="text" class="form-control" id="subqstnId" required [(ngModel)]="detail.subqstnId" name="subqstnId">
      </div>

      <div  class="form-group">
        <label for="qstnId">partId: </label>
        <input type="text" class="form-control" id="partId" required [(ngModel)]="detail.partId" name="partId">
      </div>

      
      <div  class="form-group">
        <label for="instruction">instruction: </label>
        <input type="text" class="form-control" id="instruction" required [(ngModel)]="detail.instruction" name="instruction">
      </div>

      <div  class="form-group">
        <label for="question">question: </label>
       
        <input *ngIf="false" type="text" class="form-control" id="question" required [(ngModel)]="detail.question" name="question">

        <ckeditor *ngIf="true" editorUrl="\assets\ckeditor\ckeditor.js"
          #editor
          [(ngModel)]="detail.question"
          id="question"
					name="question"  >
        </ckeditor>

       
     

      </div>

      <div  class="form-group">
        <label for="answer">answer: </label>
        <input type="text" class="form-control" id="answer" required [(ngModel)]="detail.answer" name="answer">
      <input *ngIf="false" type="text" class="form-control" id="answer" required [(ngModel)]="detail.answer" name="answer">

      <ckeditor *ngIf="true" editorUrl="\assets\ckeditor\ckeditor.js"
        #editor
        [(ngModel)]="detail.answer"
        id="answer"
        name="answer"  >
      </ckeditor>
    </div>

    
    <div  class="form-group">
      <label class= "bg-warning" for="headerId"> Answer Ref#: </label>
      <input type="text" class="form-control" id="ansrRef" required [(ngModel)]="detail.ansrRef" name="ansrRef">
    </div>

    
    
    <div  class="form-group">
      <label for="mark"> Marks: </label>
      <input type="string" class="form-control" id="mark" required [(ngModel)]="detail.mark" name="mark">
    </div>

    <div  class="form-group">
      <label for="id">  id: </label>
      <input type="string" class="form-control" id="id" required [(ngModel)]="detail.id" name="id">
    </div>

 <!-- put in upload.. not in add ???  -->   
  <app-file-upload [iDetail] = detail>
        </app-file-upload>

      

     <div  class="form-group">
    <label for="addUser">Added by: </label>
    <input type="text" class="form-control" id="addUser" required [(ngModel)]="detail.addUser" name="addUser">
  </div>
  
  </div> 

      
  

      <!--
      <div class="form-group">
        <label for="message">Message/Query</label>
        
        <ckeditor editorUrl="\assets\ckeditor\ckeditor.js"
            #editor
            [(ngModel)]="comment.comment"
            id="message"
                      name="message"  >
          </ckeditor>
        --> 
  
        <!--
  
          type="divarea"
          [config]="{ toolbar: [ [ 'Bold' ] ] }"
  
        <input type="text" class="form-control" id="message" required [(ngModel)]="comment.comment" name="message">
        
      </div>
      -->
    <!-- check it later -->
    
     <button type="submit" class="btn btn-success">Submit</button>
    
    </form>
  </div>
  
  
  <div [hidden]="!submitted">
    <h4>submitted!</h4>
    </div>
