import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";

import { BrowserModule, Title,Meta } from "@angular/platform-browser";
import { Location } from '@angular/common';
import { filter, first } from 'rxjs/operators';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { environment } from '../../../environments/environment';
import { FixedDataService } from '../../shared/fixed-data.service';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { ViewportScroller } from '@angular/common';
import { OfrDtlService } from '../../shared/headings/ofr-dtl.service';
import { Observable } from 'rxjs';
import { SecurityService } from '../../shared/security.service';
import { UsrhdrService } from '../../user/usrhdr.service';
import { ExtraHdrService } from '../../gen/extra-hdr.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
 // changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit , AfterViewInit,AfterContentChecked {
  faCoffee = faCoffee;
  
qstns$: Observable<any>;
 
rootUrl:string;
  //readonly rootUrl = environment.rootUrl;
 isNavbarCollapsed=true;
 isNavbarCollapsed1=false;
  
  navbarOpen = false;
  boardTitle: string;
  data$: any;
  
   
  
  get site() {return  this.cs.getSite()} ;
  get justSite() {return  this.cs.getJustSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get signedUser() { return this.cs.getSignedUser()};
  get learnMode() { return this.cs.getLearnMode() }
  get clsSec() {  return this.cs.getClsSec()!='null'?this.cs.getClsSec():''}
  get unsigned() {return this.cs.isUnsigned()}
  get groupId(){ return this.cs.getGroupId()};
  get usrSubject(){ return this.cs.getUsrSubject()};
  get enableMenu() { return this.cs.enable("site","menu-site")}
  get collapsedMenu() { return this.cs.enable("site","collapsedMenu-site")}
  get siteObj() {return  this.cs.siteObj} ;
   // get myNav() { return this.cs.getMyNav()};
  //cat:string;
  siteHead: string;
  //board:string;
  //course:string;
  //offering:string;
  //subject:string;
  titleHead : string;
  titleDesc: string;
  strArr: string[];
  domain:string;
  category:string;
 // signedUser:string;
signInUrl:string;
signOuturl:string;
currentUrl:string;
twitterA: string;

questions:any;
headerId: number;
headerObj:any;
examStored:string;
userGroup:string;


/*
myMenuLink:any;

goMyNav(){
  
var pr = JSON.parse(localStorage.getItem('principal'));
   if(pr){
this.myMenuLink= [pr.learnMode+pr.school +"-" +pr.location, pr.groupId,pr.board,pr.grade];
//this.router.navigate(myMenu);
return this.myMenuLink;
}
}
*/

  constructor( private cdr: ChangeDetectorRef,private route: ActivatedRoute,private location: Location,
    private router: Router, private titleService: Title, public ods:OfrDtlService,
    private metaTagService: Meta,private dataService: BoardDataService,public cs:ClientInfoService,private ss:SecurityService,private uhs: UsrhdrService,public ehs :ExtraHdrService,
     private fd:FixedDataService, private viewportScroller: ViewportScroller ) {
      this.rootUrl= this.cs.getRoot();

    if(this.site && this.site.startsWith('es')){
      this.twitterA= 'ExamSteppers'
    }
    if(this.site && this.site.startsWith('eb')){
      this.twitterA= 'Edubestu'
    }

}


ngOnInit() {

  //this.setSiteHead();
   this.route.params.subscribe( params => {
    this.cs.updateParms(this.route);
    this.fetchData();
    });

    this.isNavbarCollapsed= this.collapsedMenu;



   //replace undefined with ''
//this.board = this.board !== undefined ? this.board :'' ;
//this.course = this.course !== undefined ? this.course :'' ;

//alert(this.board);


/*
   this.dataService.getSignedUser().subscribe(response=> {
       this.signedUser = (response as any).user as string;
       if(this.signedUser){
         this.dataService.getUserGroup().subscribe(response=> {
           this.userGroup = (response as any).userGroup as string;
             });
}
     });
*/

     

     this.router.events.pipe(
       filter((event: RouterEvent) => event instanceof NavigationEnd)
     ).subscribe(() => {
       if(this.examStored != this.cs.getSite()+this.cs.getBoard()+this.cs.getCourse()){
       this.fetchData();
     }
     });



   this.router.events.pipe(
     filter((event: RouterEvent) => event instanceof NavigationEnd)
   ).subscribe(() => {
     this.splitSite();
      this.setSiteHead();
   });

   //this.splitSite();

   this.splitSite();
    this.setSiteHead();


if(this.site){
this.titleHead = this.siteHead ;
}
if(this.board){
  this.titleHead = this.titleHead + ' ' + this.board;
  }

  if(this.course){
    this.titleHead = this.titleHead + ' ' + this.course;
    }

/*
  this.titleService.setTitle(this.titleHead);
  this.metaTagService.updateTag({ name: 'description', content: this.siteHead + ' ' + this.titleDesc });
*/
  //alert(this.siteHead);
}



//for fragments scrolling

getUsrHome(){

  return this.cs.getUserHomeNav()

}

ngAfterViewInit(): void {

/* 
 this.route.fragment.pipe(
    first()
  ).subscribe(fragment => this.viewportScroller.scrollToAnchor(fragment));
*/

}

ngAfterContentChecked(): void {

  let unsigned = this.cs.isUnsigned();
    if (unsigned != this.unsigned) { // check if it change, tell CD update view
      this.cdr.detectChanges();
    }

}  



public onClick(elementId: string): void { 
  this.viewportScroller.scrollToAnchor(elementId);
}

enable(btnId:string,q?:any){
  
  

  if(btnId ==='user' && !this.unsigned) {
   q.title= this.signedUser + "@"+ this.learnMode + '<div class="fa fa-caret-down"></div>';  
    }
  

    

  if(btnId ==='my1Menu'){
 //console.log(q.category+ this.groupId +" -- "  + this.cs.getGroupId())
   if(this.groupId) {
   //console.log(this.cat);  
   
  q.extLink= "features/"+ this.groupId ;
   //q.category= "learner";  
  // q.extLink= q.extLink.replace("digibestu",this.groupId) ;
  // console.log('changed cat to'+ this.groupId);
  } else{
   // console.log("please setup user's menu"); 
  } 
  }

  if(btnId ==='signed' && !this.unsigned ) {
    
    q.instruction= this.signedUser + "@"+ this.cs.getGroupId();  
    
    switch (this.groupId){
      
      case 'teacher':
      q.title= '<i class="fa fa-user text-white" aria-hidden="true"></i>'; 
      break;
      
      case 'crclm_mngr':
      q.title= '<i class="fa fa-user text-warning" aria-hidden="true"></i>'; 
      break;

      default:
      q.title= '<i class="fa fa-user text-info" aria-hidden="true"></i>'; 
    }
    
    }


  let showSignIn = ['esa','ebcsure-shot',];
    if(btnId ==='signIn') {
    if( !this.authorized() ){
     //showSignIn.includes(this.site) &&
      // console.log("signIn authorized" +this.cat +this.authorized()); 
      return true;
    } else{
      return false;
    }
  }

  if(btnId ==='signOut') {
    if(this.authorized() ){
      //showSignIn.includes(this.site) && 
      //console.log("signIn authorized" +this.cat +this.authorized()); 
      return true;
    } else{
      return false;
    }
  }

  // btnid-groupId setup means you must be a signed User
  /*
  
  if(q && q.groupId && q.groupId=='selectExam' && this.board ){
  q.title= 'Change '+ this.board + "-" + this.course+':';  
 
  }
  
  if(!q.part && this.cs.getSignedUser() =='Guest' ){
    return true;
  }
  if(!q.part && this.cs.getSignedUser()!='Guest' ){
   return true;
  }
  if((q.part && this.ownerGroup(q.part))){
  return true; 
 }
*/

 if(q && q.part){
 return this.cs.enable('menu',btnId,q);  
 }


 //enable new has user-group issue
 return this.cs.enable('menu',btnId);
 }


ownerGroup(part:string){
  //this.userGroup= "[OWNER]";
 // alert(this.userGroup);
  if(this.userGroup && this.userGroup.includes(part) ){
   return true;
  }
  else{
    return false;
  }
}


   toggleNavbar() {
      this.navbarOpen = !this.navbarOpen;
     }

hasBoard(){
if(this.board){
  return true;
}  else{
  alert("Please select Class/Exam");
  return false;
}
}



getSignInUrl(){
this.signInUrl= this.rootUrl + "/faces/unsecured/login.xhtml";
this.currentUrl= this.router.url;
  return this.signInUrl + '?'+ 'fromUrl='+ this.currentUrl;


}

getSignOutUrl(){
this.signInUrl= this.rootUrl + "/faces/unsecured/logout.xhtml";
this.currentUrl= this.router.url;
  return this.signInUrl + '?'+ 'fromUrl='+ this.currentUrl;


}

showEE(){
  if(this.hasBoard()){
  this.router.navigate([this.site,this.board,this.course,'type'],{ fragment: 'offerings' } );
}
else{
//alert("Please select exam/class for Exam Essential Features");
//this.router.navigate(['/esa/'],{ fragment: 'boards' } );
}
}




showPYQ(){
  if(this.hasBoard()){
  this.router.navigate([this.site,this.board,this.course,'pyq']  );
}
else{
alert("Please select exam/class for Popular Books");
this.router.navigate(['/esa/'],{ fragment: 'boards' } );
}
}

showVID(){
  if(this.hasBoard()){

  this.router.navigate([this.site,this.board,this.course,'vid'] );
}
else{
alert("Please select exam/class for vid");
this.router.navigate(['/eba/'] );
}
}

showMPB(){
  if(this.hasBoard()){
  //this.router.navigate(['/esa/icse/x/vid'] );
  this.router.navigate([this.site,this.board,this.course,'mpb'],{ fragment: 'boards' } );
}
else{
//alert("Please select exam/class for Popular Books");
this.router.navigate(['/esa/'],{ fragment: 'boards' } );
}
}

showHWH(){
  if(this.hasBoard()){
  //this.router.navigate(['/esa/icse/x/vid'] );
  this.router.navigate([this.site,this.board,this.course,'hwh']  );
}
else{
//alert("Please select exam/class for Popular Books");
this.router.navigate(['/esa/'],{ fragment: 'boards' } );
}
}

showCAR(){
//  if(this.hasBoard()){
  //this.router.navigate(['/esa/icse/x/vid'] );
  this.router.navigate([this.site,'*','*','car']  );
/*
}
else{
alert("Please select exam/class for Popular Books");
this.router.navigate(['/esa/'],{ fragment: 'boards' } );
}
*/
}

splitSite(){
  //this.ref.markForCheck();
  if(this.strArr){

    this.strArr= this.site.split("-");
  if(this.strArr.length >= 1){
 this.domain=  this.strArr[0];
 this.category = this.strArr[1];

} else{
 this.category= "dft";
}
} else{
   this.category= "dft";
}


//alert(this.category);

}

setSiteHead(){
//fixed later
  //this.siteHead=this.domain;
  //this.siteHead= "Exam Steppers";

  if(this.cs.getSite() == 'eba'){
  this.siteHead= "Edu Bestu";
  }

   if(this.domain){

   if(this.domain == 'esa' || this.site == 'es'){
   this.siteHead= "Exam Steppers";
 }

   if(this.site == 'csa'){
   this.siteHead= "Career Steppers";
   }

   //alert(this.siteHead);
 }




}

authorized(){
  if(this.signedUser && this.signedUser!= 'Guest'){
    return true;
  }
  else{
    return false;


  }
}

fetchData1(){
this.questions= this.fd.getMenuData(this.site );
//this.questions= this.dataService.getMenuQuestions(this.site);
//alert((this.questions as any).dataList.length);
}


fetchData(){

  if(!this.cs.siteObj && localStorage.getItem('siteObj-'+this.site)){
    this.cs.siteObj= JSON.parse(localStorage.getItem('siteObj-'+this.site));  
  }
  else{
  this.uhs.domainRec(this.site).subscribe(data =>{
    if(data.status==200){
    let siteObj= data.result;
    localStorage.setItem('siteObj-'+this.site , JSON.stringify(siteObj));
    this.cs.siteObj= siteObj;
    }
    else {
      this.siteObj.name= "EduBestu*"
    }
  
  });
  }


 //absolutely working fine for dynamic mode-- disabling for performance.
  this.dataService.getCatHeaders(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),'menu',this.cs.getSubject()).subscribe(data =>
      {
  this.headerObj= (data as any).dataList[0];
  if(this.headerObj){

  this.siteHead= this.headerObj.title;
  this.headerId= (data as any).dataList[0].id;
  
  //alert(this.headerId);
//this.cs.getOffering() removed as booster going to tag file for menu details!!
  this.qstns$ = this.dataService.getCfgQuestions(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),'cfg','subj',this.headerId);
 
  this.examStored= this.cs.getSite()+this.cs.getBoard()+this.cs.getCourse();
  //,this.clsSec removing it
  this.boardTitle = [this.board,this.course].filter(Boolean).join("-");
  
//cache ofr-dtls for headings and seo service.
if(true){
  this.ods.setMenuHdr(this.headerObj);
}
  

} else{
  this.cs.addMsg("Contact support to setup 'menu' for the site");  
  }
});
}




}
