import { Injectable } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { Header } from '../header';
import { HeaderService } from '../header.service';
import { BstrMkr } from './bstr-mkr';

@Injectable({
  providedIn: 'root'
})
export class BstrService {
 
  key:string= "cmpltChptrs";

  
constructor(public ths: HeaderService,public cs:ClientInfoService) { }


getSrcHdrids(bstrType:string){
return  localStorage.getItem(bstrType + "-srcIds");

}

getHdr(bstrType?:string):Header{

  let newHdr:Header = this.ths.hdrWithDefault();
  newHdr.owner = null;
  //newHdr.book = bstrType;
  newHdr.hdrFormat = "both";
  return newHdr;

}



  

}
