import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-aso-hdr-btn',
  templateUrl: './aso-hdr-btn.component.html',
  styleUrls: ['./aso-hdr-btn.component.scss']
})
export class AsoHdrBtnComponent implements OnInit {
  @Input() grpAsoId: any;
  @Input() asoHdr:any;
  @Input() slctType :string;
  
  
  addAsoHdr:boolean;
  storeKey: string = "grpAsoId";
// for associator from associated records
  constructor(public cs:ClientInfoService) { }

  ngOnInit(): void {
  }

  associate(){
    localStorage.setItem(this.storeKey, this.grpAsoId);
    this.cs.navigate("dcstore", "");  
     }
  
     getTitle(asoHdr:any){
     return [asoHdr.subject ,asoHdr.section,asoHdr.title].filter(Boolean).join("-") 
     }
     
}
