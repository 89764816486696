import { Component, Input, OnInit } from '@angular/core';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';


@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  @Input()  q :any;
  @Input()  i :number;
  showCommentI:number;
  constructor(public qs:QstnListService) { }

  ngOnInit(): void {
 
  }

  }
