
<div class="fullWidth">
    
    <button *ngIf='ts.cs.enable("testHdrBtn","evaluate")' type="button" class='text-right mx-5 btn btn-info my-2' (click)="
    showResult()"> Submit for Evaluation </button>

</div>

<div class="mt-5 float-center">

    <button type="button"
        *ngIf='!ts.showResult &&  !ts.cs.enable("testHdrBtn","evaluate")'
        class="btn badge float-right btn-danger mx-2" (click)="
     loadResult()">
        Finish Test
    </button>


    <!--
     <button  type="button"
     class="btn badge float-right btn-secondary mx-2" (click)="
     storeAndClose()">
     store & show rslt 
     </button>
     
    -->


    <button *ngIf=" false && !ts.showResult" type="button" class="disabled"
        class="btn badge float-right btn-secondary mx-2"
        (click)="storeAnsrsInDB()">
        save ansrs
    </button>

</div>
<app-show-result class="mx-3" class="text-center" *ngIf='ts.showResult'
    [h]='ts.hdr?ts.hdr:h' [list]=ts.qstns> </app-show-result>