

    <ng-container *ngIf='showAnsr'>
           
      <ng-container *ngIf='(q.question ||q.content) && (q.ansCmnts[0])'>
        <h5> {{"User's Answer"}} </h5>
       <div class= "container">
        <ckeditor [(ngModel)]='q.ansCmnts[0].comment'
          [(config)]="cfg.editorConfig" name="comment">
        </ckeditor>
      </div>
      </ng-container>
    </ng-container>
    
  
    <button *ngIf='ts.qstns' type="button"
    class="btn badge float-left btn-primary border-0 mx-2" (click)="ts.previous(q)">
    {{ts.showQstn > 0 ?'previous':'first'}} 
  </button>


<ng-container *ngIf= 'q.question ||q.content|| q.answer||q.digAnsr||q.ansrRef'>
        

    <button *ngIf= "q.ansCmnts && q.ansCmnts[0].status!='review'" type= "button" class="btn badge text-left btn-outline-info border-0 mx-3" (click) = 'setStatus("review")'> Mark For Review</button>
    
    <button *ngIf= "q.ansCmnts && q.ansCmnts[0].status=='review'" type= "button" class="btn badge  float-center btn-outline-info border-0" (click) = 'setStatus(" ")'> Unmark From Review</button>
    
    <button *ngIf="false"  type="button" class="btn badge btn-info float-left  "
      (click)='flup=!flup'>Attach</button>
     
     <ng-container *ngIf= '!q.optionList'> 
      
     <button   *ngIf='(!q.ansCmnts[0] || !q.ansCmnts[0].comment)' type="button" class="btn btn-info badge "
      (click)='showAnsr=!showAnsr'>{{showAnsr?'close window':'Type Answer'}} </button>
      
    <button  *ngIf='(q.ansCmnts[0] && q.ansCmnts[0].comment)' type="button" class="btn btn-info badge "
      (click)='showAnsr=!showAnsr'>{{showAnsr?'Hide Ansr Window':'update Answer'}} </button>
    </ng-container>  

    <!--*ngIf='showAnsr && q.ansCmnts[0] && (q.ansCmnts[0].digAnsr && q.ansCmnts[0].message)'-->
    
   


    <button *ngIf='showAnsr||q.optionList'
      type="submit" class="btn btn-primary badge mx-2 "> {{ts.updateNeeded ?
      'update Ansr': 'Submit Ansr' }} </button>
    
   </ng-container>
  
   <button *ngIf='ts.qstns' type="button"
    class="btn badge float-right btn-primary border-0 mx-2" (click)="ts.next(q)">
    {{ts.showQstn < ts.qstns.length-1?'skip to Next':'last'}} </button>
    
     
    <button *ngIf='false'
      type="button" class="btn btn-secondary badge mx-5 " (click) = 'showCmnt= !showCmnt'> {{showCmnt ?
      'Hide Add': 'Add cmnt' }} </button>
    
      <app-comments [source]= q.source [headerId]= chapter [qstnId]= q.id [type] = "CMNT" *ngIf = "showCmnt" > </app-comments>




