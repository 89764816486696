
  import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
  import { Detail } from '../detail';
  import { QstnDtlService } from '../qstn-dtl.service';
  import { Router } from '@angular/router';
  import { ClientInfoService } from '../../shared/client-info.service';
import { Subscription } from 'rxjs';
  
  
@Component({
  selector: 'app-add-vid',
  templateUrl: './add-vid.component.html',
  styleUrls: ['./add-vid.component.scss']
})
export class AddVidComponent implements OnInit {

  @Input() iDetail :Detail;

  @Input() hdr :any;
  
  updateFileSub: Subscription;
  uti:boolean;
  
  @Output('update')
  change: EventEmitter<boolean> = new EventEmitter<boolean>();
  
    

  get offering() { return this.cs.getOffering()};
  expand: boolean;
  
  public detail: Detail = null;
  submitted = false;
  constructor(private DetailService: QstnDtlService,
    private router: Router,private cs:ClientInfoService) { }

  ngOnInit() {
    console.log("from init");
    this.createDetail();
  }

  
  
  createDetail(): void {
    this.submitted = false;

    if(this.iDetail){
      this.detail = this.iDetail;
    } else{
     this.detail = new Detail();
     this.detail= this.DetailService.populateDefault(this.detail,this.hdr);
    
     }
  
    console.log(this.detail);
  }

  save() {
       
    this.DetailService.create(this.detail)
      .subscribe(data => 
        {
          if((data as any).status === 200) {
            //console.log(data);
  
          this.detail = new Detail();
         this.cs.addMsg("added","toast"); 
          }
         this.gotoList();
                 
        }, error => console.log(error));
     }

  onSubmit() {
    this.submitted = true;
    this.save();    
  }


  gotoList() {
   QstnDtlService.updateVidList.next(true);
   
   // this.router.navigate(['//eba//digibest//features//Details']);
  }


  getChildOutput(res: any) {
    this.detail.extLink= res;
    this.detail.format= "intVid";

        }
  

}
