import { Injectable, Inject, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from "@angular/router";
import { SeoService } from './seo.service';
import { FixedDataService } from './fixed-data.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SecurityService } from './security.service';


import { MessageService } from '../message.service';
import { skip } from 'rxjs/operators';
import { MathJaxService } from './math-jax.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExtraHdrService } from '../gen/extra-hdr.service';
import { ExtraDtlService } from '../gen/extra-dtl.service';
import { BtnService } from '../gen/btn.service';
import { LoggerService } from '../gen/logger.service';
import { ConfirmModalService } from './confirm-modal.service';
import { BrowserWindowService } from '../gen/browser-window.service';
import { SeoTempMapperService } from './seo-temp-mapper.service';
import { UsrhdrService } from '../user/usrhdr.service';
import { Observable, of } from 'rxjs';
import { LocalstorageExpiryService } from '../auth/localstorage-expiry.service';

@Injectable({
  providedIn: 'root'
})

export class ClientInfoService {
  public page: number;
  loadingBtnMap: boolean;
  loadedBtnMap: boolean;
  siteObj: any;
  checkOldCount: number = 0;
  setDeviceFirst: any;
  mobile: boolean;
  slideDataKey: any;


  get debug() { return this.enable('dflt', 'debug') }

  getPage() {
    return this.page;
  }

  isOwner() {
    //console.log("school loc",this.ss.getSchlLoc() )  
    //console.log("user id ",this.ss.getHeaderId() )  
    return this.ss.getSchlLoc() && this.ss.getSchlLoc() == this.ss.getHeaderId()
  }

  sem: any;
  static debug = false;

  toggleDebug() {

    if (ClientInfoService.debug) {
      localStorage.setItem('enableDebug', 'false');
    } else {
      localStorage.setItem('enableDebug', 'true');
    }

    ClientInfoService.debug = !ClientInfoService.debug
    this.addMsg('debug toggled', 'toast');
  }


  log(msg: any, method?: string) {

    if (ClientInfoService.debug) {
      console.log(method);
      console.log(msg)
    }
  }

  userId: any;
  chapterNum: number;
  qstnId: any;
  grpAsoId: number;




  constructor(private http: HttpClient, public router: Router, public seo: SeoService, public fd: FixedDataService, public ss: SecurityService, public ms: MessageService, public hs: ConfirmModalService,
    public mj: MathJaxService, public modalService: NgbModal, public eh: ExtraHdrService, public ed: ExtraDtlService, public lgr: LoggerService, @Inject(PLATFORM_ID) private platformId: any, public bw: BrowserWindowService, private stm: SeoTempMapperService, private oldCheck: LocalstorageExpiryService,

  ) {
    if (this.checkOldCount == 0) {
      if (this.oldCheck.check() == "cleared") {
        this.addMsg("app data refreshed,login fresh", 'toast');
      }
      this.checkOldCount = 1;
    }

    /* not working universal  for Hostname- fix in future
     private injector: Injector, @Inject(PLATFORM_ID) private platformId,
    
          if (isPlatformServer(this.platformId)) {
              let req = this.injector.get('request');
                console.log("host: " + req.get('host'));
              this.hostName= req.get('host');
                    } else {
                    console.log('we\'re rendering from the browser, there is no request object.');
                      }
                      */

  }



  getShrGrp() {
    return this.ss.getShrGrp();
  }


  insertSignUpMsg(q: any) {
    let url = this.getRouteUrl("ftr/benefit", "");

    if (this.isUnsigned() && q.status && q.status == 'sign') {
      return '<hr>This feature needs signup, please click here for additional signup benefit<a *ngIf= "question.answer"  class="btn btn-outline-info badge border-0  mb-2 mr-2 float-right" href =' + this.getRoot() + url + '>signup advantage</a> <hr>';
    }

  }

  getDfltClsSec(offering: string) {
    if (this.fd.clsSecBased(offering)) {
      return this.getClsSec() == '' ? "clsSec" : this.getClsSec();
    }
    return "clsSec";
  }

  getCrtClsSec(offering?: string) {
    if (this.fd.clsSecBased(offering) && this.getClsSec() != 'clsSec') {
      return this.getClsSec();
    } else {
      return null;
    }

  }



  grpAuthFast(btnId: string, hdrRec: any) {
    // across the component button
    let crudBtns = ['updateQstnGlobal', 'updateGlobal', 'updateLocal', 'updateHdr', 'updateDtl', 'updateTagGS', 'updateHeader', 'update', 's11delete', 'changeStatus', 'allowEdit', 'addQDtl', 'crudBtns-cstm'];
    // crud should be allowed for owned record..

    if (crudBtns.includes(btnId)) {
      let usrGrp = this.ss.getUserGroup();
      
      if (hdrRec && hdrRec.shrGrp && usrGrp) {
        if (hdrRec.shrGrp == 'public' || usrGrp.includes(hdrRec.shrGrp)) return true;
      }
  
    }


  }


  getUsrSubject() {
    let dfltSubject = this.ss.getUserDfltSubject();
    return this.usrSubject ? this.usrSubject : dfltSubject;
  }

  usrSubject: string;


  setUsrSubject(subject: string) {
    //console.log(subject + " set in click in cs")
    this.usrSubject = subject;
    //throw new Error('Method not implemented.');
  }

  setCourse(course: string) {
    this.course = course;
    localStorage.setItem('course', course);

    let courseSem = course.split(course);
    if (courseSem.length = 2) {
      this.course = courseSem[0];
      localStorage.setItem('course', course);
      this.sem = courseSem[1];
    }


  }

  setBoard(board: string) {
    this.board = board;
    localStorage.setItem('board', board);

    //throw new Error('Method not implemented.');
  }



  getDomain(): string {

    return this.getSite();
  }






  getSiteObjByDomain(domain: string): Observable<any> {
    return this.http.get(`${this.getRoot()}/rs/sites/list/${domain}`);
  }

  async getSiteObj(site: string) {
    let key = 'siteObj-' + this.site;
    if (this.siteObj && key in localStorage) {
      return this.siteObj;
    }

    if (!this.siteObj && localStorage.getItem('siteObj-' + this.site)) {
      return JSON.parse(localStorage.getItem('siteObj-' + this.site));
    }
    else {
      //let fetched = await  this.getSiteObjByDomain(this.site);
      let data = await this.getSiteObjByDomain(this.site).toPromise();
      if (data && (data as any).status == 200 && (data as any).result) {
        this.siteObj = (data as any).result;
        localStorage.setItem('siteObj-' + this.site, JSON.stringify(this.siteObj));
        return this.siteObj;
      }
      //throw("error in getting siteObj");

    }

  }


  onlySubject(subject: string) {
    return subject.split('~', 1)[0];
  }

  isUnsigned() {
    return this.getSignedUser() == 'Guest';
  }

  signPaid(): boolean {
    throw new Error("Method not implemented.");
  }

  /*
  getMyNav() {
    return this.ss.getMyNav();
  }
  */

  getUserHomeNav() {

    let dfltSite = this.getDfltSiteCode();
    if (dfltSite) {
      return ['/', dfltSite, 'home'].filter(Boolean).join('/');
    }
    else {
      return ['/', this.ss.getUserDomain(), 'home'].filter(Boolean).join('/');
    }


    let justSite = this.getJustSite();
    if (true || this.isUnsigned()) {
      let justSite = this.getJustSite();

      if (this.site.startsWith("eb")) {
        justSite = "eba";
      }
      if (this.site.startsWith("es")) {
        justSite = "esa";
      }
      if (this.site.startsWith("cs")) {
        justSite = "csa";
      }
      return ["/", justSite].filter(Boolean).join('/');
    }
    else {
      //signed user Home

      return ["/", this.ss.getDefaultSite()].filter(Boolean).join('/');

    }

  }

  addMsg(msg: string, type?: string, title?: string) {
    this.ms.clear();
    this.ms.add(msg, type, title);
    /*
        if(type){
         this.ms.add(msg,type);
        }else{
          this.ms.add(msg);
        }
      */
  }


  tagBased(offering: string) {
    return this.fd.tagBased(offering);
  }


  tagBasedForCreate(offering?: string) {
    return this.fd.tagBasedForCreate(this.getJustOffering(offering));
  }



  readonly production = environment.production;

  readonly rootUrl = environment.rootUrl;
  readonly intraUrl = environment.intraUrl;
  readonly intraUrl1 = environment.intraUrl1;
  readonly localUrl = environment.localUrl;
  readonly eduUrl = environment.eduUrl;
  readonly carUrl = environment.carUrl;
  site: string;
  cat: string;
  board: string;
  course: string;
  feature: string;
  offering: string;
  subject: string;
  chapter: string;
  content: string;

  titleHead: string;
  titleDesc: string;
  dfltBoard: string;
  siteHead: string;
  hostName: string;
  boardNeeded: boolean;
  showAds: boolean = false;

  school: string;
  schlLoc: string;
  btnMap: Map<string, Array<string>>;
  overrideMap: Map<string, Array<string>>;
  grpBtn: any;
  usrGrp: any;
  mode: string;
  clsSec: string = 'clsSec';
  learnMode: string;


  help(longContent) {
    this.modalService.open(longContent, { scrollable: true });
  }


  typeSet(divId?: string) {
    return this.mj.typeSet(divId);
  }

  grey(q: any) {
    //new is transition from beta to actual hence show to beta viewer but keep gry for other but show unlike beta-which is hide to non-beta user.

    //accomodating empty record
    if (q.difficulty) {
      return 'empty'
    }

    if (q.status == 'new') { return false }

    if (q.status && (['sign', 'paid', 'beta'].includes(q.status))) {
      if (q.status == 'new') { return 'new' }

      if (q.status == 'beta' && !this.betaViewer()) {
        // this.addMsg("Feature needs signup");
        return 'beta'
      }

      if (q.status == 'sign' && this.isUnsigned()) {
        // this.addMsg("Feature needs signup");
        return 'sign'
      }
      if (q.status == 'paid' && !this.subscribed(q)) { return 'paid' }
    }

  }
  subscribed(q: any) {
    return false;
  }


  getMode() {
    return this.mode;
  }




  isProd() {
    if (isPlatformBrowser(this.platformId)) {
      //showAds flag has bug hence trying simple enable for examsteppers

      const url = window.location.hostname;
      if (url) {
        if (url.includes('examsteppers.com')) { return true; }
      }

    }

    else {
      return false
    }

    return this.production;
  }



  getLearnMode() {
    return this.ss.getLearnMode();
  }


  setLearnMode(learnMode: string) {
    this.ss.setLearnMode(learnMode);
  }

  //main role
  getGroupId(): string {
    return this.ss.getGroupId();
  }


  setGroupId(groupId: string) {
    this.ss.setGroupId(groupId);
  }



  updateParms(route: ActivatedRoute) {
    route.params.subscribe(params => {
      //alert(params);
      //console.log(params);
      this.site = params['site'];
      //temp fix for seo url
      if (this.site) {
        // this.stm.siteMapper(this.site);
      }
      // alert(this.site);

      //note-- introduce getAllSite('all')  and get list of all site from siteObj.

      if (this.site && !(['eb', 'es', 'cs', 'si', 'cm', 'ge'].includes(this.site.substring(0, 2)))) {
        this.addMsg(this.site + " seems to be invalid! sending you to home page", "toast");
        //this.stm.redirect(this.site,this.ss.getDefaultSite());
        // this.site = "";
      }


      this.cat = params['cat'];
      if (this.cat) {
        //this.stm.catMapper(this.cat);
      }
      if (!this.site || !this.cat) {
        this.setDfltSiteCat();
      }
      let cBoard = params['board'];
      if (cBoard) {
        this.board = params['board'];
        this.course = params['course'];

        if (!this.fd.validBoard(cBoard)) {
          this.addMsg("new board " + cBoard + "?,", "toast");
        }


      }

      this.offering = params['offering'];
      this.feature = params['feature'];
      this.subject = params['subject'];
      this.chapter = params['chapter'];
      this.chapterNum = params['chapter'];
      this.page = params['page'];
      this.content = params['content'];
      this.mode = params['mode'];
      this.userId = params['userId'];
      this.qstnId = params['qstnId'];
      this.grpAsoId = params['grpAsoId'];
      this.slideDataKey = params['slideDataKey'];
      

      /*
        if((params['school']) ){
          this.school= params['school'];
          //alert(this.school)
          }
      
          if((params['schlLoc']) ){
            this.schlLoc= params['schlLoc'];
            }
      */


      //this.boardMust(); commenting now.
      this.dfltBoard = this.getBoard();
      this.updateLocalStorge()

      /*update titleHead and description */
      this.updateDescription();
      this.setupTitle();
    });

  }

  getUserId() {
    return this.userId;
  }

  async getHomeCat(site: string) {
    // loginto user  menu if loggedin by default
    let cat = '' //"home" //universal default

    //override for site
    let siteObj = await this.getSiteObj(this.site);
    if (this.siteObj && this.siteObj.dfltCat) {
      cat = this.siteObj.dfltCat;
    }
    //override for signed user
    if (!this.isUnsigned() && this.ss.userDfltCat()) {
      cat = this.ss.userDfltCat();
    }
    return cat;
  }




  //dflthome
  getHomeCatDelete() {
    // loginto user  menu if loggedin by default

    if (!this.isUnsigned()) {
      return this.ss.getUserMenu();
    }

    //override if needed but examsList and menudflt need change
    // opting for simple home for all type
    switch (this.getSite().substring(0, 2)) {

      case 'es':
        //  return 'learner';
        return 'home';

        break;
      case 'cs':
        return 'home';
        break;

      case 'eb':
        return 'home';
        break;

      case 'ge':
        return 'signin';
        break;
    }
    //return this.getSite().substring(0,2)+ "home";
    //alert("getHomeCat is called");
    return "home";

  }


  getJustSite() {
    if (this.getSite()) {
      if (this.getSite() != "site")
        return this.getSite().substring(0, 3);
    } else {
      return "site";
    }
    return "";
  }

  enableNew(page: string, question: any) {
    // btnid-groupId setup means you must be a signed User
    if (!question.part && this.getSignedUser() == 'Guest') {
      return true;
    }
    if (!question.part && this.getSignedUser() != 'Guest') {
      return false;
    }
    if ((question.part && this.ownerGroup(question.part))) {
      return true;
    }
  }

  ownerGroup(part: string) {
    //this.userGroup= "[OWNER]";
    // alert(this.userGroup);
    if (this.getUserGroup() && this.getUserGroup().includes(part)) {
      return true;
    }
    else {
      return false;
    }
  }

  stripFtrDesc(ftrData: Array<any>): any {
    const index = ftrData.findIndex(item => item.groupId === 'ftrDesc');
    // console.log(" index is: "+ index);
    if (index > -1) {
      //ftrData.splice(index, 1);
      return ftrData;
      //is.ftrDescObj = this.questions.dataList[index]; 
    } else {
      return ftrData;
    }
  }

  // For Excel- Feature etc.       
  getFtrDesc(ftrData: Array<any>): any {

    // console.log(ftrData);

    const index = ftrData.findIndex(item => (item.groupId === 'ftrDesc' && this.authorized('', item.groupId, item.part)));

    // console.log(" index is: "+ index);
    if (index > -1) {
      //console.log(ftrData[index]);
      return ftrData[index];
    }
    return null;
  }
  //btnDtlWithSetup --  currentlt btndtl is not passed at all.. for future also not needed just an info...
  //



  exclude(component: string, btnId: string, hdrRec?: any, btnDtlWithSetup?: any) {

    if (btnId == 'subMenu') {
      //&& qs.enable('buildFtr')
      //console.log("hey, inclusionStatus is : " + inclusionStatus + 'status is ' + hdrStatus);
      // console.log(hdrRec);
    }

    let hdrStatus = this.getStatus();
    let hdrOfr = this.getOffering();
    let hdrCat = this.cat;

    if (hdrRec) {
      if (hdrRec.status) {
        hdrStatus = hdrRec.status.trim();
      }
      //subMnu needs to take url offering always
      if (component != "subMnuHardC") {
        hdrOfr = hdrRec.type;
      }
    }

    let bd = null;
    // menu or submnu should have format or section as "withAuth"

    //let bd = this.fd.getBtnDetail(component, btnId,this.getGroupId());
    //&& hdrRec && hdrRec.hdrFormat && hdrRec.hdrFormat == ""
    if (btnDtlWithSetup && ((hdrRec && hdrRec.hdrFormat && hdrRec.hdrFormat == "withAuth") || (btnDtlWithSetup.section && (btnDtlWithSetup.format == "withAuth" || btnDtlWithSetup.section == "withAuth")))) {
      bd = btnDtlWithSetup;
    } else {
      bd = this.fd.getBtnDetail(component, btnId, this.getGroupId());
    }



    if (bd) {

      //future hook based on following type with data 
      //this.slSrv.getTypeList(this.subject)

      let inclusionSite = bd.vidLink;
      // let inclusionCat = bd.content;
      let inclusionCat = bd.content ? bd.content : bd.question;

      let inclusionOfr = bd.answer;
      let inclusionStatus = bd.digInstrctn;
      let exclusionSite = bd.wireFrame;
      //let exclusionCat = bd.contentImages
      let exclusionCat = bd.contentImages ? bd.contentImages : bd.questionImages;

      let exclusionOfr = bd.answerImages;
      let exclusionStatus = bd.digQstn;

      // btn-site handling
      if ((btnId && btnId.includes('-site')) && this.site) {
        let bc = this.getJustSite();
        if (btnId == 'about-site') {
          //console.log(inclusionSite + bc); 
        }
        if (inclusionSite && !(inclusionSite.includes(bc) || inclusionSite === 'site')) {
          return true;
        }

        if (exclusionSite && (exclusionSite.includes(bc))) {
          return true;
        }
      }

      /*
            if (btnId == 'report' && (hdrRec as any).id == 33291) {
              //console.log("hey, inclusionStatus is : " + inclusionStatus + 'status is ' + hdrStatus);
              //console.log(hdrRec);
            }
      */

      //if hdrStatus is empty then inclusion should fail it by default as only included status allowed..
      if (!hdrStatus && inclusionStatus) {
        return true; // no need to check extra as inclusion has not that state 
      }


      if (hdrStatus) {

        /*
        if (btnId == 'inSlbs21' && (hdrRec as any).id == 18202) {
          console.log("hey, inclusionStatus is : " + inclusionStatus + 'status is ' + hdrStatus);
        }
        */


        if (inclusionStatus) {
          if (!inclusionStatus.includes(hdrStatus)) {
            return true; // no need to check extra as inclusion has not that state 
          }
        }

        if (exclusionStatus) {
          if (exclusionStatus.includes(hdrStatus)) {
            return true; // no need to check extra as exclusion has the state 
          }
        }
      }

      if (hdrCat) {




        if (inclusionCat) {
          //special handling for partial pattern -bldr...          
          if (hdrCat.includes('-bldr')) {

            if (!inclusionCat.includes('-bldr')) { return true }
          }
          //special handling for partial pattern -bldr...          
          else
            if (hdrCat.includes('mkr-')) {
              if (!inclusionCat.includes('mkr-')) { return true }
            }


            else {
              if (!inclusionCat.includes(hdrCat)) {
                return true; // no need to check extra as inclusion has not that state 
              }
            }
        }

        if (exclusionCat) {
          if (exclusionCat.includes(hdrCat)) {
            return true; // no need to check extra as exclusion has the state 
          }


          if (hdrCat != 'tigp-bldr' && hdrCat.includes('-bldr')) {
            if (exclusionCat.includes('-bldr')) { return true }
          }
          else
            if (hdrCat.includes('mkr-')) {
              if (exclusionCat.includes('mkr-')) { return true }
            }


        }
      }

      if (hdrOfr) {

        if (inclusionOfr) {
          if (!inclusionOfr.includes(hdrOfr)) {
            return true; // no need to check extra as inclusion has not that state 
          }
        }

        if (exclusionOfr) {
          if (exclusionOfr.includes(hdrOfr)) {
            return true; // no need to check extra as exclusion has the state 
          }
        }
      } else {
        //if ofr not there and inclusion ofr required then set exclusion to true
        if (component == "subMnuHardC" && inclusionOfr) { return true }
      }


    }


    return false;
  }

  betaViewer() {
    return (this.getUserGroup().includes('beta_viewer') ||
      this.getUserGroup().includes('booster_maker'))
  }

  //fail fast
  preChecker(component: string, btnId: string, detailRec?: any, hdrRec?: any) {

    // "forChptr should disable if url has no chapter "
    if (detailRec && detailRec.format &&
      (detailRec.format == 'forChptr' || detailRec.format == 'withChptr') && !this.getChapter()) { return false; }
    if ((detailRec && detailRec.format && detailRec.format == 'forSub') && !this.getSubject()) { return false; }


    // move to subscription service later
    /*
    if ((detailRec && detailRec.status == 'beta') && !this.getUserGroup().includes('beta_viewer')) { return false; }
    */

    // console.log(hdrRec);
    /* enable if needed 
    if ((btnId == 'crudBtns-cstm' && (hdrRec && hdrRec.school != this.getSchool()))) {
      if (this.getUserGroup().includes("app_admin")) { return true }
      if (this.getUserGroup().includes("eb_coe")) { return true }
      return false;
    }
     */


    //digitizer button only in  dgtz cat..
    //automatizer...
    /*
     if(btnId=='showDetail' && (hdrRec as any).id == 131384 ){
     console.log("hey, exclude is : " + this.exclude(component,btnId,hdrRec));
    }
    if(btnId=='showDetailTea' && (hdrRec as any).id == 131384 ){
      console.log("hey, exclude is : " + this.exclude(component,btnId,hdrRec));
     }
   */
    // complex chapter needed for all type ..
    if (btnId == 'complex' && (this.getOffering() == 'type')) {
      return true;
    }

    if (this.exclude(component, btnId, hdrRec, detailRec)) { return false; }


    let catList = ['dgtz', 'link', 'upld', 'cntnt', 'admin'];

    switch (component) {

      case 'cmntList':
        {
          if (btnId == "crctnBtn" && this.getStatus() != 'crct') { return false; }
          if (btnId == "resolve" && !(this.getStatus() == 'clr' || this.getStatus() == 'shr')) { return false; }


        }


      case 'vidBand':
        {
          let list = ['CRUD', 'tagHdrList', 'showTD', 'updateQstn',
            'updateTag', 'tagHdrList', ''];
          if ((list.includes(btnId) && !catList.includes(this.getCat()))) {
            return false;
          }
          break;
        }
      /*
    case 'qstnList':
    { 
    //'updateQstn','updateTag' ,'changeInactive', 'addPBstr','outOfSyl'
    let list = [
     ,'allowEdit'];
     if((list.includes(btnId) && !catList.includes(this.getCat() ))){
     return false; }
  
     /*
     if(btnId.includes("outOfSyl") && !this.getCat().includes('sylbs') ){
      return false; }
      
      let noSearchmenuCat= ['pb','nwsrvw'];
      if(btnId === "searchMenu" && noSearchmenuCat.includes(this.cat)){
        return false;
      } 
      
      
      if(btnId.includes("changeStatus") && (this.getCat().includes('bldr') || this.getCat().includes('fresh'))){
        return false; }
        
        if(btnId.includes("answer") && this.getOffering().includes('tcrct-thw') ){
          return false; } 
          
          
          
          let excludedOfr= ['nws','rvw','btm','thw','dhw','dtm','eps','tps'];
          if( (btnId.includes('practiceMode') && (excludedOfr.includes(this.getOffering() ) ) )  ) {
            return false; }
            let buildCat = ['dhw-bldr','ubstr-bldr','pbstr-bldr','eps-bldr'];
            if((btnId== "buildFtr" && !buildCat.includes(this.getCat() ))){
              return false; }
              break;
            }
  */
      /*/
      
      
         /*
        case 'chptrList':{
      
          if(btnId.includes("outOfSyl") && !this.getCat().includes('sylbs') ){
            return false; }
            
            //'updateHeader'
          let list = ['changeInactive',,'updateFile',];
          if((list.includes(btnId) && !catList.includes(this.getCat())) ){
          return false; }
          
          if((btnId.includes('showDetail+') && (!catList.includes(this.getCat())) && this.getCat() != "sylbs")){
            return false; }
          
            let excludeCat= ['nws','rvw','btm',''];
            if( (btnId.includes('practiceMode') && (excludeCat.includes(this.getCat())))) {
              return false; }
          
      
         if(btnId.includes("outOfSyl")  && !this.getCat().includes('sylbs') ){
         return false; }
          
      
          break;
        }
      */



      default:
        return true;
    }
    return true;
  }
  /*
  grpAuthFastDlt(btnId:string,hdrRec:any){
    // across the component button
    let crudBtns = ['updateQstnGlobal','updateGlobal','updateLocal','updateGS','updateHeader', 'sdelete', 'changeStatus', 'allowEdit','addQDtl','crudBtns-cstm'];
    // crud should be allowed for owned record..
    
      if(crudBtns.includes(btnId)){
    if (this.cat == 'my') { return true; }
    this.usrGrp = this.ss.getUserGroup();
    if (hdrRec && hdrRec.shrGrp && this.usrGrp) {
     if(hdrRec.shrGrp=='public'||this.usrGrp.includes(hdrRec.shrGrp)) return true; }
    }
  }
  */

  enable(component: string, btnId: string, detailRec?: any, hdrRec?: any): boolean {


    if (btnId == 'enableM12enu-site' || btnId == 'go-12site') {

      //console.log(detailRec);
    }

    // note- below important due to user-share group logic.
    if (this.grpAuthFast(btnId, hdrRec)) { return true }



    //if (btnId == 'showSource') {
    //&& qs.enable('buildFtr')
    //console.log("hey, inclusionStatus is : " + inclusionStatus + 'status is ' + hdrStatus);
    //  console.log(detailRec);
    //}

    let grpBtnInp: any;
   

    //console.log('detailRec sent');
    //console.log(detailRec);

    // add formatcheck if needed..
   
    if (detailRec) {
      grpBtnInp = detailRec.part ? detailRec.part : detailRec.partId
   };
   
   
    if (!this.preChecker(component, btnId, detailRec, hdrRec)) { return false }
    //if(btnId=='vid'){ console.log(this.preChecker(component,btnId))}
    let authFlag = this.authorized(component, btnId, grpBtnInp);
    //if(btnId=='vid'){console.log(authFlag + '--'+ this.customAuth(component,btnId,authFlag))};
    return this.customAuth(component, btnId, authFlag);
  }


  customAuth(component: string, btnId: string, authFlag: boolean): boolean {
    //example
    // console.log(btnId + " "+ this.getLearnMode() );



    if (btnId == 'hide' || btnId == 'ftrDesc') {
      // console.log("kutta" + component + btnId + this.authorized(component,btnId));
      return false;
    }


    let learnModeControlled = ["LearnSmart", "TeachSmart", "SmartParent"];

    if (this.getLearnMode() && learnModeControlled.includes(btnId)) {

      if (this.getLearnMode() == 'TutorGuided') {
        if (btnId == 'TeachSmart') {
          return true;
        }

      }

      if (this.getLearnMode() == 'ParentGuided') {
        if (btnId == 'SmartParent') {
          // console.log('true');
          return true;
        }

      }



    }





    if ((component == "chptrList") && btnId == "CRUD") {
      // console.log(authFlag);
      //return true;
    }


    return authFlag;
  }

  authorized(component: string, btnId: string, grpBtnInp?: string): boolean {

    // this is for cfg detail view and edit which need to ignore authrity as it is setup for others.
    if ((["app_admin", "site_admin", "siteowned", "admin"].includes(this.cat)) && this.offering == "cfg" && this.chapter) {
      if (this.ss.getUserGroup().includes('site_manager')) {
        return true;
      }




      /*
      if ((this.offering && this.offering == "cfg")) {
        return true;
      }
      */
      //introduce special auth check here if needed.
    }


    //only to make all config visible for change??





    let found: boolean = false;
    //alert(btnId);
    if (grpBtnInp) {
      this.grpBtn = grpBtnInp.split(",");
    } else {

      if (!this.btnMap || this.btnMap.size == 0) {
        let btnData = JSON.parse(localStorage.getItem(this.site + '-btnData'));

        if (btnData) {
          this.fd.loadBtnData(btnData);
          this.btnMap = this.fd.loadBtnAuthMap();
          if (this.btnMap?.size > 0) {
            //console.log('API CALL avoided. getting btnMap loaded in client-info at line 1087,, but why??  with size: ' + this.btnMap.size);

          }
        }


      }

      /*
      if (!this.btnMap || this.btnMap?.size < 1) {
        //if btnmap not loaded disable all button by default. 
        setTimeout(() => {
          if (this.btnMap?.size < 1) {
            console.log("Serious issue:btnMap not loaded, please check as all //features will get disabled by default to protect system")

            return false;
          }
        }, 2000);

      }
*/

      if (this.btnMap && this.btnMap?.size > 0) {
        let groupId = this.getGroupId() ? this.getGroupId() : "UNSIGNED";
        if (this.btnMap?.has(component + "-" + btnId + "-" + groupId)) {
          btnId = btnId + "-" + groupId;
        }
        //['COWNER','TEACHER']
        this.grpBtn = this.btnMap.get([component, btnId].filter(Boolean).join("-"));

      }

    }


    if (!this.grpBtn) {

      // console.log("WARNING: " + component + "-"+ btnId + ' not set in auth List' );
      /*
       secured = ['update','edit'];
      if (this.secured.includes(btnId)){
        console.log("WARNING: " + component + "-"+ btnId + 'not set in auth List hence disabled due to being secured button' );
        return false} else{
         //by default allowed
          console.log("WARNING: " + component + "-"+ btnId + 'not set in auth List hence shown by default' );
        }
        */
      return true;
    }

    if (this.grpBtn.includes('LOGGED')) {
      if (this.getSignedUser() && (this.getSignedUser() !== 'Guest')) {
        return true;
      } else {
        return false;
      }

    }



    if (this.grpBtn.includes('PVT') && (!this.offering || this.offering != "cfg")) {
      // console.log("offering",this.offering);
      return false;
    }




    if (this.grpBtn.includes('UNSIGNED') || this.grpBtn.includes('unsigned')) {
      //console.log( btnId + "has unsigned");
      if (this.getSignedUser() && (this.getSignedUser() === 'Guest')) {
        //console.log( btnId + "has unsigned11");
        found = true;
      } else {
        //console.log( btnId + "has unsigned22");
        found = false;
      }

    }

    //console.log(this.grpBtn);
    //console.log("authoroty PRM : "+ this.getUserGroup());

    //note- userGroup has {authority:group_name} as list item.hence elem.authority is just group name1
    // button has just groupid not grpid.authority setup



    this.usrGrp = this.getUserGroup();
    if (this.usrGrp && this.usrGrp.length > 0) {
      // console.log(this.usrGrp + " kutta btn:  " + this.grpBtn);
      (this.usrGrp as any).forEach(elem => {
        //console.log(elem);
        //console.log(elem.authority);
        if (this.grpBtn && this.grpBtn.length > 0 && ((this.grpBtn.indexOf(elem) > -1) || (this.grpBtn.indexOf(elem.authority) > -1))) {
          //console.log(elem.authority);
          found = true;
          return;
        };
      });
    }
    return found;
  }


  openNewWindow(url: string) {
    console.log(url);
    var params = [
      'menubar=0',
      'resizable=0',
      'height=' + screen.height,
      'width=' + screen.width,
      'fullscreen=yes' // only works in IE, but here for completeness
    ].join(',');
    // and any other options from
    // https://developer.mozilla.org/en/DOM/window.open
    if (this.bw.hasWindow()) {
      var popup = window.open(url, "'online-test'", params);
      popup.moveTo(0, 0);
    }
  }


  mapCat(site: string, cat: string) {
    switch (cat) {
      /*
          case "digibestu":{
      
            if(this.ss.getGroupId()){
             return this.ss.getGroupId()
            }
            return cat;
            break;
            }
        */
      default: {
        return this.fd.mapCat(site, cat)
      }
    }
  }

  addSchool(site: string) {
    if (site && !site.includes("-") && (this.getSchool() && this.getSchool() != 'cmn')) {
      return site + this.getSchool() + "-" + this.getSchlLoc()
    }
    return site;
  }

  async setDfltSiteCat() {

    this.site = this.getDfltSiteCode()
    if (this.site && !this.cat) {
      this.cat = await this.getHomeCat(this.site);
      //alert("hello from client info 261");
    }
    this.site = this.addSchool(this.site);
    this.router.navigate([`${this.site}`, `${this.cat}`]);
  }

  //don't use
  async setDfltSiteCatOld() {

    // changed gen to right site category for gen or mobile /gen only navigation..
    if (!this.site ||
      (!this.isUnsigned() && this.site == 'gen' && !this.cat)) {
      this.site = this.ss.getDefaultSite();
    }

    if (!this.site) {
      // console.log("from setDeafult of universal info compo: "+ this.hostName);
      if (this.bw.hasWindow() && !this.hostName) {
        this.hostName = window.location.hostname;
        //console.log("from setDeafult of client info srvc: "+ this.hostName);
      }
      const findTerm = (term) => {
        if (this.hostName && this.hostName.includes(term)) {
          return this.hostName;
        }
      };
      switch (this.hostName) {
        case findTerm('localhost'): {
          this.site = 'esa';
          //this.cat = this.getHomeCat('esa');

          console.log('Welcome to ExamSteppers...');
          break;
        }

        case findTerm('examsteppers.com'): {
          this.site = 'esa';
          // this.cat = this.getHomeCat('esa');
          this.showAds = true;
          console.log('Welcome to ExamSteppers...');
          break;
        }

        case findTerm('examsteppers'): {
          this.site = 'esa';
          //this.cat = this.getHomeCat('esa');

          console.log('Welcome to ExamSteppers...');
          break;
        }
        case findTerm('edubestu'): {
          this.site = 'eba';
          // this.cat = this.getHomeCat('eba');
          console.log('Welcome to Edubestu...');
          break;
        }

        case findTerm('careersteppers'): {
          this.site = 'csa';
          //this.cat = this.getHomeCat('csa');

          console.log('Welcome to CareerSteppers...');
          break;
        }

        default: {
          // console.log('Please setup site in client-info-service...');
          //change to cmn later
          //this.site = "esa";
          if (!this.site) {
            this.site = "eba";
          }


          //alert("hello from client info 251");
          break;
        }
      } //switch ends
      //  this.cat = this.getHomeCat(this.site);
    }

    //defalt category
    if (this.site && !this.cat) {

      this.cat = await this.getHomeCat(this.site);
      //alert("hello from client info 261");


    }

    this.site = this.addSchool(this.site);
    this.router.navigate([`${this.site}`, `${this.cat}`]);

  }

  getRoot() {

    var base;

    let win = this.bw.getWindow();

    if (win) {
      const url = window.location.hostname;
      if (url.includes('localhost')) {
        base = this.localUrl;
      } else
        if (url.includes('192.168.1.')) {
          base = this.intraUrl;

        } else
          if (url.includes('examsteppers.com')) {
            base = this.rootUrl;
          } else

            if (url.includes('examsteppers')) {
              base = this.intraUrl1;
            } else
              if (url.includes('edubestu')) {
                base = this.eduUrl;
              } else
                if (url.includes('careersteppers')) {
                  base = this.carUrl;
                }
                else {
                  base = url;

                }
    }

    //console.log("URL for config file from getRoot envi file: "+ base );
    return base;
  }


  boardMust(): boolean {
    //default feature for a category is kinda group features hence no board needed..
    /* trying to make generic if feature no board/course...
      if(((this.cat=='te') && (this.feature=='pbstr')  ) ||
        ( this.cat=='ti') ||
        (this.cat=='et') ||
        (this.cat=='cs') ||
        ( (this.cat=='fa') &&  (this.feature=='car')  )
          )
      {
        return false;
      }
    */
    if (this.feature) {
      if (
        (this.feature == 'pbooster')
        || (this.feature == 'hwh1')
        || (this.feature == 'pyq1')
        || (this.feature == 'special')
      ) {
        if (!this.board || this.board === ' ' || this.board === 'cmn' || this.board === 'board') {
          //alert("board is must");
          return true;
        }
      }
      return false;
    }


    if (this.cat == 'exams1' || ((this.offering || this.feature) && (!this.board || this.board === ' ' || this.board === 'cmn' || this.board === 'board'))) {
      //alert("Please select Board & Class/Exam.. ")  ;
      return true; // to show boardList
    } else {

      return false;
    }
  }

  isFeature(extLink: string): boolean {
    if (extLink && extLink.includes('features')) {
      return true;
    } else {
      return false;
    }

  }

  isExternalLink(extLink: string): boolean {
    //alert(extLink);
    if (extLink && extLink.includes('http')) {
      return true;
    } else {
      return false;
    }
  }

  getClsSec() {
    return this.ss.getClsSec();
  }

  setClsSec(clsSec: string) {
    this.ss.setClsSec(clsSec);
  }


  getFeature() {
    return this.feature;
  }

  setFeature(feature: string) {
    this.feature = feature;
  }

  /*
  setBoard(board:string){
    this.board=board;
  }
  
  setCourse(course:string){
    this.course=course;
  }
  */

  getTitle() {
    return [this.board, this.course, this.subject
    ].filter(Boolean).join("-");

  }

  getDescription() {
    //return this.site + " " + this.board + "" + this.offering + "" + this.subject;

  }

  setTitle(headerObj: any) {
    return "abc"
  }

  setTitleStop(headerObj: any) {
    if (!headerObj) {
      //console.log("headerObj not passed to setTitle");

    }

    if (headerObj) {
      this.titleHead = [headerObj.dataList[0].year, headerObj.dataList[0].chapterId, headerObj.dataList[0].section, headerObj.dataList[0].title].filter(Boolean).join("-");

      //this.setupTitle();

      //console.log(this.getTitle());
      this.titleHead = [this.getTitle(), this.titleHead].filter(Boolean).join(":");
      console.log(this.titleHead);

      this.titleDesc = [headerObj.dataList[0].summary, this.getDescription()].filter(Boolean).join(" : ");
      console.log(this.titleDesc);
      this.seo.updateTitle(this.titleHead);
      this.seo.updateDescription(
        this.titleDesc);
      //console.log("setting: "+ this.titleHead);
      return this.titleHead;

    }


  }

  setupTitle() {
  }

  setupTitleStop() {

    this.titleHead = [this.getTitle(), this.titleHead].filter(Boolean).join(":");

    this.titleDesc = [this.titleDesc, this.getDescription()].filter(Boolean).join(" : ");
    this.seo.updateTitle(this.titleHead);

    this.seo.updateDescription(' ' +
      this.titleDesc);

  }

  getParticipantName() {
    var participant = JSON.parse(localStorage.getItem('participant'));
    if (participant) {
      return participant.name;
    };
    var user = localStorage.getItem('signedUser');
    if (user) {
      var signedUser = JSON.parse(localStorage.getItem('signedUser'));
      if (signedUser) {
        return signedUser;
      }
    }
    return 'guest';
  }



  getSite() {
    if (this.site) {
      return this.site;
    } else {
      return this.getDefaultSite();

    }
  }
  getDefaultSite() {
    if (localStorage.getItem('domain')) {
      this.site = localStorage.getItem('domain');
      return localStorage.getItem('domain');
    } else {
      if (this.isUnsigned()) {
        return this.getDfltSiteCode();
      } else {
        return this.ss.getDefaultSite();
      }
    }
  }


  getDfltSiteCode() {
    let dfltSite = this.site;
    let win = this.bw.getWindow();
    if (win && this.isUnsigned()) {
      const url = window.location.hostname;
      if (url.includes('examsteppers.com')) {
        dfltSite = "esa";
      } else
        if (url.includes('localhost')) {
          dfltSite = "esa";
        }
        else
          if (url.includes('careersteppers.com')) {
            dfltSite = "csa";
          }
          else
            if (url.includes('edubestu.com')) {

              if (!this.setDeviceFirst) {
                const userAgent = navigator.userAgent;
                console.log(userAgent);
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                  // true for mobile device
                  //document.write("mobile device");
                  this.mobile = true;
                  this.setDeviceFirst = true;
                  console.log('mobile set to true')
                } else {
                  // false for not mobile device
                  //document.write("not mobile device");
                  this.mobile = false;
                  console.log('mobile set to false')

                  this.setDeviceFirst = true;
                }
              }

              if (this.mobile) {
                // dfltSite = "gen";
                dfltSite = "eba";
              }
              else {
                dfltSite = "eba"
              }
            } else{
              dfltSite = "eba"; //universal
              console.log('dfltSite for universal site used??: eba')
            }
    }

    return dfltSite;
  }



  getBoard1() {
    /*
    if(this.site=="eba"){
      return '*';
    }
    */

    return this.board;
  }

  getSignedUser() {
    return this.ss.getSignedUser()
  }

  getUserGroup() {

    return this.ss.getUserGroup();

  }


  getuserClsSec() {
    return this.ss.getClsSec();
  }

  getSchool() {
    if (this.school) {
      return this.school
    }
    else {
      return this.ss.getSchool();
    }
  }

  getSchlLoc(): string {
    if (this.schlLoc) {
      return this.schlLoc
    }
    else {
      return this.ss.getSchlLoc();
    }
  }



  getBoard() {
    if (this.board) {
      return this.board
    }
    else {

      return this.ss.getBoard();
    }
  }

  getCourse() {

    if (this.course) {
      return this.course
    }
    else {
      return this.ss.getCourse();
    }
  }

  getSem() {
    return this.sem;
  }


  getCat() {
    return this.cat;
  }



  setCat(cat: string) {
    this.cat = cat;
  }

  getOffering() {
    return this.offering;
  }

  getStatus(ovrOfr?: string): string {
    ovrOfr = ovrOfr ? ovrOfr : this.offering;
    if (ovrOfr) {
      let typeStatus = ovrOfr.split('-');
      if (typeStatus.length == 2) {
        return typeStatus[0].substring(1);
      }
    }
    else {
      return "";
    }

  }

  getJustSubject(subject?: string): string {
    subject = subject ? subject : this.subject;
    if (subject) {
      let subBookBranch = this.subject.split('~');
      if (subBookBranch.length > 0) {
        return subBookBranch[0];
      }
    }

  }

  getJustOffering(offering?: string): string {
    offering = offering ? offering : this.getOffering();
    if (offering) {
      let typeStatus = offering.split('-');
      if (typeStatus.length == 2) {
        return typeStatus[1].substring(0);
      } else {
        return offering;
      }

    }
    else {
      return "";
    }

  }



  getSubject() {
    return this.subject;
  }

  getChapter() {
    return this.chapter;
  }


  getChapterNum() {
    return this.chapterNum;
  }


  /*
  //special for board List put under cat "exams" as exam already has board/course link..
  if(this.getCat()=="exams"){
  /* don't make too complicated
  //For exams type, let current cat prevail and link should be set to board and course..
  //convention-- board-course to be given in link to avoid confusion of link vs special case..
  //var category = "board--course" to work..
  var splitted = extLink.split("--", 2);
  this.board = splitted[0];
  this.course =splitted[1];
  category= this.getCat();
  extLink= "";
  
  category= "ee";
  url= ['/',this.getSite(),category,extLink].filter(Boolean).join('/');
  }
  else{
  */
  // to be developed..
  getRouteUrl1(component: string, category: string, extLink: string,): string {

    var url: string;

    switch (component) {
      case "boardList": {
        url = ['/', this.getSite(), category, extLink].filter(Boolean).join('/');
        break;
      }
      case "ofrList": {
      }
      default: {
        url = ['/', this.getSite(), category, this.getBoard(), this.getCourse(), extLink].filter(Boolean).join('/');
      }

    }
    return url
  }


  openInNewTab(router: Router, namedRoute) {
    let newRelativeUrl = router.createUrlTree([namedRoute]);
    let baseUrl = window.location.href.replace(router.url, '');

    window.open(baseUrl + newRelativeUrl, '_blank');
  }

  openNewTab(url) {


    window.open(url, '_blank');
  }

  /* just for reference
  
    showChptr(t: any, overCat?:string) {
      let cat = "";
      if(overCat){cat= overCat;}
  
  
      var chptrUrl = this.cs.getRouteUrl(cat, t.type + "/" + t.subject + "/" + t.id);
      this.router.navigateByUrl(chptrUrl);
    }
    */



  navigate(cat: string, extLink: string, format?: string, userId?: string) {
    let url = this.getRouteUrl(cat, extLink, format);

    if (format == "fullNav" && url && url.startsWith('http')) {

      // https://www.examsteppers.com
      this.gotoExternalDomainInSameTab(url);
      return;
    }

    //   console.log("url: ", url);
    if (userId) { url = url + "/ovrUsr/" + userId }
    if (cat && cat.includes("::")) {
      this.openNewTab(url);
    } else {
      this.router.navigateByUrl(url);
    }
  }

  gotoExternalDomainInSameTab(extUrl: string) {
    (window as any).open(extUrl);
  }

  gotoExternalDomain(extUrl: string) {
    (window as any).open(extUrl, "_blank");
  }


  navigateToNewWindow(cat: string, extLink: string, format?: string, userId?: string) {
    //console.log("extLink",extLink);
    let url = this.getRouteUrl(cat, extLink, format);
    if (userId) { url = url + "/ovrUsr/" + userId }
    //  console.log("url:",url);  
    if (!format || format != "fullNav") {
      url = this.getRoot() + url;
    }
    this.openNewTab(url);
  }

  getRouteUrl(category: string, extLink: string, format?: string, groupId?: string): string {

    if (groupId) {
      if (groupId == 'cstm_userMenu') { category = this.ss.getUserMenu() }

    }


    //console.log(category + extLink + format);
    if (format && format == 'fullNav') {
      return extLink;
      //navigate to full url in new windows later
    }

    //for now feature and offering are inter-weavable.. change it later..


    //trying..
    if (!category) {
      //don't show carry forward submenu till set 
      //category= 'abc';
      category = this.getCat();


      if (this.getCat()?.includes("-bldr")) {
        category = this.getCat();
      } else {
        // category= "abc"
      }


      //console.log("getRouteUrl has no category for extLink: " + extLink);
    }
    var url: string;

    if (category === 'digibestu') {
      if (this.ss.getGroupId()) {
        category = this.ss.getGroupId();
        //extLink= extLink.replace("digibestu",category) ;
      }
      //digibestu should be mapped to default menu..
    }

    if (extLink && category === 'learner' && this.ss.getGroupId()) {
      extLink = extLink.replace("digibestu", this.ss.getGroupId());
    }

    if (!extLink && (format == "jstTypSub" || category === 'my1mkr') && this.getJustOffering()) {
      extLink = this.getJustOffering();

      if (extLink && !(extLink.includes('cfg') || extLink.includes('nws')
        || extLink.includes('info') || extLink.includes('nws'))) {
        if (this.subject) {
          extLink = extLink + "/" + this.subject;
        }
      }
    }


    //else{
    //if(this.site.startsWith('es')){category= "LWS-STUDENT";}
    //}

    //alert(category);
    var site;

    if (category && category.includes("::")) {
      site = category.split("::")[0];
      category = category.split("::")[1];
      //console.log(site + category);

      if (format && format != 'siteWithNoSignOut') {
        this.addMsg("You have been signed out,Please signIn with " + site.substr(0, 3) + this.getSignedUser().substr(0, 3), "info");
        this.ss.clearLocalStorage();
      }

      //url = ['/', site,].filter(Boolean).join('/');
      //return url;
    }
    else {
      site = this.getSite();
    }

    /*
    let exclusion = ['mark1','mark2','mark3','ebcsure-shot'];
    
    if(exclusion.includes(category)){
    url = ['/',category].filter(Boolean).join('/');
    return url;
    }
    */
    //Exams defaulted to ee?? it has some problems..

    if ((format && format == 'skipBoard') || this.getCat() == "exams" || this.getCat() == "exm") {
      url = ['/', site, category, extLink].filter(Boolean).join('/');
    } else
      if (format && format == 'withSubject') {

        url = ['/', site, category, this.getBoard(), this.getCourse(), this.getOffering(), this.getSubject()].filter(Boolean).join('/');
      } else
        if (format && format == 'withChptr') {

          url = ['/', site, category, this.getBoard(), this.getCourse(), this.getOffering(), this.getSubject(), this.getChapter()].filter(Boolean).join('/');
        }


        else {
          if (this.isFeature(extLink)) {
            // alert(extLink + ' is Feature: ' + this.isFeature(extLink) );
            url = ['/', site, category, extLink, this.getBoard(), this.getCourse()].filter(Boolean).join('/');
          } else {
            if (category) {
              //if(extLink.trim().indexOf('/')>1)
              url = ['/', site, category, this.getBoard(), this.getCourse(), extLink].filter(Boolean).join('/');
            }
            else {
              //as default as current category
              url = ['/', site, this.getCat(), this.getBoard(), this.getCourse(), extLink].filter(Boolean).join('/');
            }

            //alert(url);
          }

        }


    return url.replace('//', '/');
  }




  updateLocalStorge() {
    if (this.board && (this.board !== '1board')) {
      //localStorage.setItem('board', JSON.stringify(this.board));
      localStorage.setItem('board', this.board);
    }

    if (this.course && (this.course !== '1course')) {
      //localStorage.setItem('course', JSON.stringify(this.course));
      localStorage.setItem('course', this.course);
    }


    if (this.school && (this.school !== 'school')) {
      localStorage.setItem('school', this.school);
    }

    if (this.schlLoc && (this.schlLoc !== 'schlLoc')) {
      localStorage.setItem('schlLoc', this.schlLoc);
    }

  }

  updateSiteHead(site: string) {

    switch (site) {
      case "esa": {

        this.siteHead = "Exam Steppers";
        this.titleDesc = 'Exam Steppers Home!';
        break;
      }

      case 'eba': {
        this.siteHead = "Edu Bestu";
        this.titleHead = "EduBestu- Simply the Best Digital Education Platform";
        break;
      }

      case 'csa': {
        this.siteHead = "Career Steppers";
        this.titleHead = "Career Steppers -Each Steps carved carefully";
        break;
      }

    }

  }

  updateDescription() {

    this.updateSiteHead(this.site);

    //160 lines!! change it faster...
    //console.log("updateDescription called"+this.site+this.cat+this.board+this.course+this.feature+this.offering);

    //console.log("before this.feature:" + this.feature);
    if (this.feature) {
      this.feature = this.feature.trim();
      switch (this.feature) {
        case "thought": {
          this.titleHead = "Thought & Motivator";
          this.titleDesc = ' ';
          break;
        }
        case "excel": {
          this.titleHead = "Percentage Booster ... Excellence at best :ES";
          this.titleDesc = 'Percentage Booster ... Excellence at best :ES';
          break;
        }
        default: {
          //SEO debug
          // console.log(this.feature+ " : setup feature in client-info");
          this.titleHead = this.feature;
          this.titleDesc = this.feature;
          break;
        }
      }
      this.titleHead = this.titleHead + ":" + this.siteHead;
      return;
    }


    //console.log("after this.offering:" + this.offering);

    if (this.offering) {
      this.offering = this.offering.trim();

      switch (this.offering) {

        case 'pyq': {

          this.titleHead = "Previous Years Questions with Answer";
          this.titleDesc = 'Last 10 Years questions and Answers...';
          break;
        }

        case 'pbstr': {
          this.titleHead = "Percentage Booster";
          this.titleDesc = 'Practice daily - 5 Questions for 5 % boost in score!';
          break;
        }
        case 'hwh': {
          this.titleHead = "Homework Helper";
          this.titleDesc = 'Textbook solutions';
          break;
        }


        case 'nws': {

          this.titleHead = "News and Info that Matters";
          this.titleDesc = 'Only relevant Educational News and Info ...';
          break;
        }

        case 'vid': {

          this.titleHead = "Carefully Chosen Videos";
          this.titleDesc = 'Only important and releavnt Videos';
          break;
        }

        case 'tips': {

          this.titleHead = "Tips & Tricks";
          this.titleDesc = ' A must for scoring high';
        }

        case 'sbs': {

          this.titleHead = "Latest Syllabus";
          this.titleDesc = 'Being up to date does matter!!';
          break;
        }

        case 'mpb': {

          this.titleHead = "Recommended & Popular Books";
          this.titleDesc = 'Only buy Latest Version of Books for new Syllabus!!';
          break;
        }


        case 'car': {

          this.titleHead = "Career Steppers";
          this.titleDesc = 'Career Planning done with widest Exposure and latest Trends';
          break;
        }
        default: {
          // uncomment when working on seo
          //console.log("setup offering in client-info");
          this.titleHead = this.offering;
          this.titleDesc = this.offering;
          break;
        }
      }

      // this.titleHead = this.titleHead + ":" + this.siteHead;
      return;
    }

    //console.log("after this.cat:" + this.cat);
    if (this.cat) {
      //alert("inside this.cat:" + this.cat);
      this.cat = this.cat.trim();
      //this.titleHead = "Exam Steppers : Home";

      switch (this.cat) {
        case 'exams': {
          this.titleHead = "Supported Exam/Board-class List: ES";
          this.titleDesc = 'Supported Exam/Board-class List: ES';
          break;
        }

        case 'ee': {
          this.titleHead = "Exam Essentials: ES";
          this.titleDesc = 'Exam Essentials, Exam feature List';
          break;
        }
        case 'sf': {
          this.titleHead = "Special Features: ES";
          this.titleDesc = 'Special Features for Exam, Exam Specials';
          break;
        }

        case 'te': {
          this.titleHead = "??: ES";
          this.titleDesc = '??, Exam Specials';
          break;
        }



        default: {
          //SEO debug
          //console.log(this.cat + " : setup cat in client-info");
          this.titleHead = this.cat;
          this.titleDesc = this.cat;;
          break;
        }

      }
      this.titleHead = this.titleHead + ":" + this.siteHead;
      return;
    };

    //console.log("after this.site:" + this.site);
    if (this.site) {
      this.site = this.site.trim();

      switch (this.site) {

        case 'esa': {
          this.siteHead = "Exam Steppers";
          this.titleHead = "Exam Steppers";
          break;
        }
        case 'csa': {
          this.siteHead = "Career Steppers";
          this.titleHead = "Career Steppers";
          break;
        }

        case 'eba': {
          this.siteHead = "Edu Bestu";
          this.titleHead = "Edu Bestu";
          break;
        }
        default: {
          console.log("setup site in client-info");
          this.siteHead = "New Site!!";
          break;
        }
      }
      return;
    }

  }





}
