
<div *ngIf='h.summary && !titleFlag'>
<div class="text-primary text-quote font-italic text-center bg-white" [innerHTML] = 'h.summary'>  
</div>
</div>



   <div *ngIf='h.title && titleFlag'>
    <div class="text-primary text-quote font-italic text-center bg-white" [innerHTML] = 'h.title'>  
    </div>
    </div>
    