import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../client-info.service';

@Component({
  selector: 'app-display-ad',
  templateUrl: './display-ad.component.html',
  styleUrls: ['./display-ad.component.scss']
})
export class DisplayAdComponent implements OnInit {
  
  get prod(){ return this.cs.isProd()};
  
  get enable() {return this.cs.enable('displayAd','switchOn')}

  
  disableAdvertising: boolean = localStorage.getItem('disableAdvertising') == 'true';
  
  
  constructor(private cs:ClientInfoService) { }
  loaded:boolean;

  ngOnInit(): void {
    //console.log("ads enabled? " +this.enable );
    //console.log( "is prod: "+ this.prod);

    if(this.prod && this.enable && !this.loaded){

      this.load();
      
    }
  }

  /*
ngOnChanges(){
  if(this.prod && this.enable){
    this.load();
  }
}

*/


  load() {
  //console.log("loading script");
    var script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    //script.integrity="sha256-IhcxNQZPeVUaOuOM6+yjQ1Q00mG/zvHxSZOvNL/cmO4="; 
    //script.crossOrigin="anonymous"
    script.async = true;
    document.head.appendChild(script);
    this.loaded= true;
  };

  
}
