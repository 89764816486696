


<ng-container *ngIf=" !subject">
     
<app-home 
*ngIf='!cat || (cat.includes("home") || cat.includes("platform") ) && !offering'>
</app-home>


<app-exam-list *ngIf ="cat== 'exams'"> </app-exam-list> 

</ng-container>



<div *ngIf = '!offering && cat && cat != "home"' [ngSwitch]="cat.trim()" id= "offerings">
    
    
    <!--
    <app-excel *ngSwitchDefault >...</app-excel>
    <app-display-ad>
    </app-display-ad>
-->
    
    <!-- moving to footer in future -->
     <div *ngIf = "false" class="lead"> Popular Features:
    <app-offeringlist [expanded] = false [category] = "'hh'" [hideTitle] = true *ngSwitchCase= "'home'">
    </app-offeringlist >
    <app-offeringlist [expanded] = false [category] = "'ee'" [hideTitle] = true  *ngSwitchCase= "'home'">
    </app-offeringlist >
    <app-offeringlist [expanded] = false [category] = "'te'" [hideTitle] = true *ngSwitchCase= "'home'"> </app-offeringlist >
    
    <app-offeringlist [expanded] = false [category] = "'sf'" [hideTitle] = true *ngSwitchCase= "'ee'">
    </app-offeringlist >
    <app-offeringlist [expanded] = false [category] = "'pbstr'" [hideTitle] = true  [hideTitle] = false *ngSwitchCase= "'ee'">
    </app-offeringlist >
    
    <app-offeringlist [expanded] = false [category] = "'ee'" [hideTitle] = true *ngSwitchCase= "'sf'">
    </app-offeringlist>
    <app-offeringlist [expanded] = false [category] = "'te'" [hideTitle] = true  *ngSwitchCase= "'sf'">
    </app-offeringlist >
    </div>
   </div>


    <div>
    