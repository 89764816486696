
<div class= "bg-info jumbotron text-center" >
<h2  style="color: gold;background-color:;"> {{board}}-{{course}} Exam Special </h2>
<i class= "text-white"> Dedicated to  2020 Exam and Results etc. </i>
</div>

<div class="bg-light text-center">

<!--
<button type="button" (click)="goToSpecificUrl('http://stackoverflow.com/')">Open URL</button>

<button type="button" (click)="gotoLink()">Open English  Paper</button>
 <h3>2019 Official Sample Papers</h3>
-->
<!--
<app-contact> </app-contact>

-->




<app-board *ngIf= "board==='ICSE'">
    <div>Loading...</div>
</app-board>


<br> <br><br>

<ul *ngIf="headers" class="row">
<!-- vidshower major bug if you don't put above type of criteria in navigation-->

<li  class="col-12 col-md-12 py-2" *ngFor="let header of headers.dataList; let i = index;">
  
  <app-vidshower *ngIf= "headerObj"  [headerId] = header.id>
    </app-vidshower>


</li>
</ul>



<!--

<app-vidshower *ngIf= "headerObj"  [headerId] = headerObj.id>
  </app-vidshower>

past

<app-vidshower *ngIf= "pastHeaderObj" [headerId] = pastHeaderObj.id>
  </app-vidshower>

-->


<app-syllabus *ngIf= "board==='ICSE'"> </app-syllabus>


<div class="text-left bg-info">
<app-contact> </app-contact>
</div>


<!--
http://www.cisce.org/pdf/ICSE-Class-X-Specimen-Question-Papers-2019/Specimen%20for%20letter%20writing.pdf

http://www.cisce.org/pdf/ICSE-Class-X-Specimen-Question-Papers-2019/GUIDELINES%20FOR%20NOTICE%20AND%20EMAIL%20WRITING.pdf
-->

<!--
<li *ngFor="item of itemList" (click)="goToSpecificUrl(item.link)"> // (click) don't enable pointer when we hover so we should enable it by using css like: **cursor: pointer;**
-->
</div>
