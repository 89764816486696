
<app-header> </app-header>

<app-list-page> </app-list-page>


<!--
<app-multi-hdr-slctr> </app-multi-hdr-slctr>
<app-ngs-monaco> </app-ngs-monaco>

https://jsfiddle.net/Imabot/svcq6eby/

https://stackoverflow.com/questions/53999948/uncaught-error-cant-make-callback-from-given-data-using-mathjax-with-p5-and-ht

https://stackoverflow.com/questions/42541353/how-do-i-retrieve-the-contents-of-a-quill-text-editor/42541886#42541886


<app-custom-quill  *ngIf= 'bw.hasWindow()' [content] = 'content'> </app-custom-quill>
-->

<!--
output test:
<p [innerHTML] = content> </p>

<hr>
<app-quill [fileData]= content > </app-quill>
-->


