import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdBannerComponent } from './ad-banner/ad-banner.component';
import { JobAdComponent } from './job-ad/job-ad.component';
import { ProfileComponent } from './profile/profile.component';
import { StaticModule } from '../static/static.module';
import { AdSliderComponent } from './ad-slider/ad-slider.component';
import { CarModule } from '../car/car.module';
import { CommentsModule } from '../comments/comments.module';


@NgModule({
  declarations: [
    AdBannerComponent,
    JobAdComponent,
    ProfileComponent,
    AdSliderComponent
  ],
  imports: [
    CommonModule,
    StaticModule,
    CarModule,
    CommentsModule
  ],
  exports: [
    AdBannerComponent,
  ]
})
export class DynamicModule { }
