import { Component, OnInit } from '@angular/core';
import { ExamDataService } from '../../exam-data.service';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-search-qstn',
  templateUrl: './search-qstn.component.html',
  styleUrls: ['./search-qstn.component.scss']
})
export class SearchQstnComponent implements OnInit {
  questions: any ;
  gotResponse: boolean;
  
get site() {return  this.cs.getSite()} ;
get cat() {return this.cs.getCat() };
get board() {return this.cs.getBoard()};
get course() { return this.cs.getCourse()};
get feature() { return this.cs.getFeature()};
get offering() { return this.cs.getOffering()};
get subject() { return this.cs.getSubject()};
get groupId() { return this.cs.getGroupId()};
get signedUser() { return this.cs.getSignedUser()};
get shrGrp() { return this.cs.getShrGrp()};

  constructor(private data: ExamDataService, public cs:ClientInfoService,public qs:QstnListService) { }
  key: string = '';
  ngOnInit(): void {
  }

  search(key:string){
    this.gotResponse = false;
    let offering = this.offering?this.offering:"type";

    this.data.search(this.site,this.board,this.course,offering,this.subject,this.key).subscribe(dataList => {
      this.questions = dataList;
      this.gotResponse = true;
  });
  }

  ngAfterViewChecked(){
    this.qs.typeSet('');
  }
  navigateQstn(chapter:number,q:any) {
    let url = this.cs.router.url+"/" + chapter + "/view-qstn/" + q.id;
    return url;
  }


}
