
<div *ngIf= "prod && enable  && !disableAdvertising">
<ng-adsense>
</ng-adsense>
</div>

<div *ngIf= "!enable">
    <app-book-list> </app-book-list>
</div>
<!--
<div *ngIf= "!prod">
 <div *ngIf= "!enable"></div>
</div>
-->
