import { Component, OnInit } from '@angular/core';
import { Thought } from '../../thought';
import { BoardDataService } from '../../board-data.service';

@Component({
  selector: 'app-thoughtoftheday',
  templateUrl: './thoughtoftheday.component.html',
  styleUrls: ['./thoughtoftheday.component.scss']
})
export class ThoughtofthedayComponent implements OnInit {
thought:Thought;
  constructor(private dataService: BoardDataService) { }

  ngOnInit() {
    
  }

  ngOnInit1() {

let thoughtStr = localStorage.getItem('thoughtStr');
this.thought= JSON.parse(thoughtStr);

if(!this.thought){
  this.dataService.getThought().subscribe(response=> {
          if(response) {
          this.thought = response as any;
        localStorage.setItem('thoughtStr', JSON.stringify(this.thought));
          }   });
};
  
// return this.thought;
}
}

