import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {User} from '../../user';
import { ExamDataService } from '../../exam-data.service';
import { Router, ActivatedRoute }     from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';



@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {

  fromUrl: string;
  rootUrl:string;
  signOutUrl:string;
  currentUrl:string;
  signedUser:string;
  get site() {return this.cs.getSite()};
  get cat() { return this.cs.getCat()};
  get homeCat() { return this.cs.getHomeCat(this.cs.getSite())};

  constructor(private cs:ClientInfoService ,private route:ActivatedRoute,private router: Router, private formBuilder: FormBuilder,private dataService: ExamDataService) {
   }

  ngOnInit() {

    this.rootUrl= this.cs.getRoot();

    this.route.queryParams.subscribe(params=>{
          this.fromUrl=params['fromUrl'];
        });
   this.signout();      

  }


  
signinUrl(){
  return  this.rootUrl + `/${this.fromUrl?this.fromUrl:'eba'}/mng`;
}


  signout(){
   
   this.cs.ss.clearLocalStorage();

  this.signOutUrl= this.rootUrl + "/faces/unsecured/logout.xhtml";
  this.currentUrl= this.router.url;


  //return this.signOutUrl + '?'+ 'fromUrl='+ this.currentUrl;

 /* this will move to EB default site  
 let dfltJustSite= '';
 if(this.site.startsWith("eb")) {
    dfltJustSite= "eba";
 }
 if(this.site.startsWith("es")) {
  dfltJustSite= "esa";
}
if(this.site.startsWith("cs")) {
  dfltJustSite= "csa";
}
 this.router.navigate([dfltJustSite]);
*/

// go to user home default site
//this.router.navigate([this.site]);


 // this.cs.alert({severity:'info', summary:'Success: You are signed out, visit soon... '  ,detail:'' , sticky: true});
 this.cs.navigate("mng", ""); 
 this.cs.addMsg('Success: You are signed out, visit soon','toast');

  }

}
