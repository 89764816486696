


<div class="modal-header">
    
  <h5 class="modal-title">{{ title }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body" [innerHTML]= 'message'>
      {{ message }}
    </div>
    <!--
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
      <button type="button" *ngIf= "btnOkText" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
    </div>
    -->