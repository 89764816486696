import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiModule } from '../ui/ui.module';
import { ChatModule } from '../chat/chat.module';
//import { CommentsModule } from '../comments/comments.module';

import {RouterModule} from '@angular/router';

import { PBoosterComponent } from '../pages/pbooster/pbooster.component';
import { LayoutComponent } from '../ui/layout/layout.component';
import { HeaderComponent } from '../ui/header/header.component';
import { FooterComponent } from '../ui/footer/footer.component';
import { ContactComponent } from './contact/contact.component';


import { ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './notfound/notfound.component';
import { PrvsyrsqstnComponent } from './prvsyrsqstn/prvsyrsqstn.component';
import { HomeworkhelperComponent } from './homeworkhelper/homeworkhelper.component';

import { SpecialComponent } from './special/special.component';


import { HolidayComponent } from './holiday/holiday.component';
import { PhysicsComponent } from './physics/physics.component';
import { AdminComponent } from './admin/admin.component';

import { BstrformComponent } from './bstrform/bstrform.component';
import { PrimefaceComponent } from './primeface/primeface.component';
import { FeatureComponent } from './feature/feature.component';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule} from '@angular/forms';

import { SubjectComponent } from './subject/subject.component';
import { BoardComponent } from './board/board.component';
//import { SignupComponent } from '../user/signup/signup.component';
import { SyllabusComponent } from './syllabus/syllabus.component';
//import { BoardlistComponent } from './boardlist/boardlist.component';
import { SubjectlistComponent } from './subjectlist/subjectlist.component';
import { BranchlistComponent } from './branchlist/branchlist.component';


import { CourselistComponent } from './courselist/courselist.component';
import { ChapterlistComponent } from './chapterlist/chapterlist.component';
import { BooklistComponent } from './booklist/booklist.component';
import { ContentlistComponent } from './contentlist/contentlist.component';
import { SiteComponent } from './site/site.component';
import { Board1Component } from './board1/board1.component';
import { Subject1Component } from './subject1/subject1.component';
import { OfferinglistComponent } from './offeringlist/offeringlist.component';


//import { MenuComponent } from '../static/menu/menu.component';
import { TrailerComponent } from './trailer/trailer.component';
import { RegisterComponent } from './register/register.component';
import { QuizComponent } from './quiz/quiz.component';
import { ResultComponent } from './result/result.component';
import { NavbarComponent } from './navbar/navbar.component';
//import { DashComponent } from '../perform/dash/dash.component';
import { SubmenuComponent } from './submenu/submenu.component';
//import { VidshowerComponent } from '../car/vidshower/vidshower.component';
import { VideoComponent } from './video/video.component';
//import { SafePipe } from './../shared/safe.pipe';
import { ExcelComponent } from './excel/excel.component';
import { ParentsComponent } from './parents/parents.component';
import { EbofferComponent } from './eboffer/eboffer.component';
import { FeaturelistComponent } from './featurelist/featurelist.component';


import { SigninComponent } from './signin/signin.component';
import { SignoutComponent } from './signout/signout.component';

import { ExperimentComponent } from './experiment/experiment.component';




import { SearchComponent } from './search/search.component';
import { SearchPipe } from '../shared/search.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { TestComponent } from './test/test.component';
import { NoRightClickDirective } from '../shared/no-right-click.directive';
import { TaggerComponent } from './tagger/tagger.component';
import { AddtagComponent } from './addtag/addtag.component';
import { TinyComponent } from './tiny/tiny.component';



import { FileviewerComponent } from './fileviewer/fileviewer.component';
import { AskbestuComponent } from './askbestu/askbestu.component';
import { UnitComponent } from './unit/unit.component';
import { TagModule } from '../tag/tag.module';
import { UserModule } from '../user/user.module';
import { QstnModule } from '../qstn/qstn.module';
import { QstndtlModule } from '../qstndtl/qstndtl.module';
import { SharedModule } from '../shared/shared.module';
import { ExtLinkComponent } from './ext-link/ext-link.component';
import { DtSelectorComponent } from './dt-selector/dt-selector.component';
import { ThSelectorComponent } from './th-selector/th-selector.component';
import { TestModule } from '../test/test.module';
import { CommentsModule } from '../comments/comments.module';
//import { DoubtClarifierComponent } from './doubt-clarifier/doubt-clarifier.component';
import { ResultShowerComponent } from './result-shower/result-shower.component';


import { NarrowBandComponent } from './narrow-band/narrow-band.component';

import { ViewSelectorComponent } from './view-selector/view-selector.component';
import { QstnBldrComponent } from './qstn-bldr/qstn-bldr.component';
import { ChptrLstrComponent } from './chptr-lstr/chptr-lstr.component';
import { RvsnSpdrComponent } from './rvsn-spdr/rvsn-spdr.component';
import { SimpleChptrComponent } from './simple-chptr/simple-chptr.component';
import { ComplexChptrComponent } from './complex-chptr/complex-chptr.component';
import { QstnLstrComponent } from './qstn-lstr/qstn-lstr.component';
import { SimpleQstnComponent } from './simple-qstn/simple-qstn.component';
import { SimpleVidComponent } from './simple-vid/simple-vid.component';
import { QstnListComponent } from './qstn-list/qstn-list.component';
import { QstnPageComponent } from './qstn-page/qstn-page.component';

import { QstnBtnsComponent } from './qstn-btns/qstn-btns.component';
import { BstrViewComponent } from './bstr-view/bstr-view.component';
import { UnitMenuComponent } from './unit-menu/unit-menu.component';
import { GroupAddonComponent } from './group-addon/group-addon.component';
import { CntxtMenuComponent } from './cntxt-menu/cntxt-menu.component';
import { TaghdrSelectorComponent } from './taghdr-selector/taghdr-selector.component';
import { DhwBldrComponent } from './dhw-bldr/dhw-bldr.component';
import { GenReviewComponent } from '../qstn/gen-review/gen-review.component';
import { PerformModule } from '../perform/perform.module';

import { CarModule } from '../car/car.module';

import { DemoComponent } from './demo/demo.component';
import { StaticModule } from '../static/static.module';
import { ExpandedOfrlistComponent } from './expanded-ofrlist/expanded-ofrlist.component';
import { MySubjectComponent } from './my-subject/my-subject.component';
import { ProductDescComponent } from './product-desc/product-desc.component'
import { DocViewComponent } from './doc-view/doc-view.component';
import { BookNameComponent } from './book-name/book-name.component';

import { AssociatedRecComponent } from './associated-rec/associated-rec.component';
import { DisabledChptrComponent } from './disabled-chptr/disabled-chptr.component';
import { CmntViewerComponent } from './cmnt-viewer/cmnt-viewer.component';
import { SystemComponent } from './system/system.component';
import { SiteListComponent } from './site-list/site-list.component';
import { OptionalModule } from '../optional/optional.module';
import { SearchQstnComponent } from './search-qstn/search-qstn.component';
import { DtlViewShowerComponent } from './dtl-view-shower/dtl-view-shower.component';
import { BtnEnablerTesterComponent } from './btn-enabler-tester/btn-enabler-tester.component';
import { FtrRouterComponent } from './ftr-router/ftr-router.component';
import { TestAnsrComponent } from './test-ansr/test-ansr.component';
import { AsgnmntServiceComponent } from './asgnmnt-service/asgnmnt-service.component';
import { ChptrViewSelectorComponent } from './chptr-view-selector/chptr-view-selector.component';
import { NewSubMnuComponent } from './new-sub-mnu/new-sub-mnu.component';
import { AsoHdrCrtrComponent } from './aso-hdr-crtr/aso-hdr-crtr.component';
import { AsoHdrLstrComponent } from './aso-hdr-lstr/aso-hdr-lstr.component';
import { AsoHdrBtnComponent } from './aso-hdr-btn/aso-hdr-btn.component';
import { WholeQstnViewerComponent } from './whole-qstn-viewer/whole-qstn-viewer.component';
import { ProgressLstrComponent } from './progress-lstr/progress-lstr.component';
import { DynamicModule } from '../dynamic/dynamic.module';
import { FeatureShowerComponent } from './feature-shower/feature-shower.component';
//import { PrdComboComponent } from '../static/prd-combo/prd-combo.component';
@NgModule({
  declarations: [PBoosterComponent, ContactComponent, HomeComponent, NotFoundComponent,
     PrvsyrsqstnComponent, HomeworkhelperComponent,  SpecialComponent,  HolidayComponent, PhysicsComponent, AdminComponent,  BstrformComponent, PrimefaceComponent, FeatureComponent, SubjectComponent, BoardComponent,  SyllabusComponent,SubjectlistComponent, BranchlistComponent,  QstnLstrComponent, CourselistComponent, ChapterlistComponent, BooklistComponent, ContentlistComponent, SiteComponent, Board1Component,
     Subject1Component, OfferinglistComponent,  
      TrailerComponent,TaghdrSelectorComponent,
      DocViewComponent,
      
       RegisterComponent, QuizComponent,
       ResultComponent, NavbarComponent, 
       SubmenuComponent,VideoComponent,
          //SafePipe,
          ExcelComponent,
          ParentsComponent,
          EbofferComponent,
          FeaturelistComponent,
          
          
          SigninComponent,
          SignoutComponent,
          
          ExperimentComponent,
          
         
         SearchComponent,
          SearchPipe,
          PaginationComponent,
          TestComponent,
          NoRightClickDirective,
          TaggerComponent,
          AddtagComponent,
          TinyComponent,
         
          FileviewerComponent,
          AskbestuComponent,
          UnitComponent,
          ExtLinkComponent,
          DtSelectorComponent,
          ThSelectorComponent,
          //DoubtClarifierComponent,
          ResultShowerComponent,
          
          
          NarrowBandComponent,
          
          ViewSelectorComponent,
          DtlViewShowerComponent,
          QstnBldrComponent,
          ChptrLstrComponent,
          RvsnSpdrComponent,
          SimpleChptrComponent,
          ComplexChptrComponent,
          SimpleQstnComponent,
          SimpleVidComponent,
          QstnListComponent,
          QstnPageComponent,
          UnitMenuComponent,
          QstnBtnsComponent,
          
          BstrViewComponent,
          
          GroupAddonComponent,
          
          CntxtMenuComponent,
          
          DhwBldrComponent,
          GenReviewComponent,
          
          
          DemoComponent,
          ExpandedOfrlistComponent,
          MySubjectComponent,
          ProductDescComponent,
          BookNameComponent,
          AssociatedRecComponent,
          DisabledChptrComponent,
          CmntViewerComponent,
          SystemComponent,
          SiteListComponent,
          SearchQstnComponent,
          BtnEnablerTesterComponent,
          FtrRouterComponent,
          TestAnsrComponent,
          AsgnmntServiceComponent,
          ChptrViewSelectorComponent,
          NewSubMnuComponent,
          AsoHdrCrtrComponent,
          AsoHdrLstrComponent,
          AsoHdrBtnComponent,
          WholeQstnViewerComponent,
          ProgressLstrComponent,
          FeatureShowerComponent,
         // PrdComboComponent,
         
                 
          
      ],
  exports: [PBoosterComponent,ContactComponent,NotFoundComponent,NavbarComponent,OfferinglistComponent,GenReviewComponent,SimpleQstnComponent,NewSubMnuComponent
   ,FtrRouterComponent,FeatureShowerComponent,
],

  imports: [
   CommonModule,
   FormsModule,
   ReactiveFormsModule,
   UiModule,
   ChatModule,
   CommentsModule,
   TagModule,
   UserModule,
   QstnModule,
   QstndtlModule,
   SharedModule,
   TestModule,
   RouterModule,
   PerformModule,
   CarModule,
   StaticModule,
   OptionalModule,
   DynamicModule,
      ]
})
export class PagesModule { }
