
<div class="badge btn nav-link btn-outline-secondary border-0 link-secondary px-1 ml-3" (click) = 'system = !system'>system control </div>

<ng-container *ngIf="system" >


<button  type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'GDAll()'  > Synch Google Drive</button>


<button  type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'clearCache("cfg")'  > Clear Cfg Cache</button>

<!--
<button type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'clearCache("qstn")'  > Clear qstn Cache</button>

<button type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'clearCache("oth")'  > Clear other Cache</button>


<button type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'cs.toggleDebug()'  > {{cs.debug? 'disable':'enable'}} debug </button>



-->


<button type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'clearLocal()'  > clear Local Cache </button>


<button type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'tweaklocalTracking()'  > {{disableTracking? 'enable':'disable'}} web tracking </button>


<button type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'tweakAdvertising()'  > {{disableAdvertising? 'enable':'disable'}} advertisement </button>

<app-asgnmnt-service> </app-asgnmnt-service>

</ng-container>

