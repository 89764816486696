
<!--
<p class= "lead">detailed Data:</p>
Subject -- workType--id--marks-Comment
<div  *ngFor="let m of (mts$ | async)?.result as data ">
{{m[0]}}--{{m[1]}}--{{m[2]}}--{{m[3]}}--{{m[5]}}
</div>
-->


<div  *ngIf = "(mts$ | async)?.result as m">
  <p class= "lead">Student's data: </p>
  <app-data-table [posts]= m> </app-data-table>

</div>


<div  *ngIf = "(mtC$ | async) as m">

  

    <div class="chart-wrapper">
    
      <canvas baseChart 
      [data]="m.values"
      [labels]="m.set"
      [chartType]="'line'">
      </canvas>
    
    </div>
    
    </div>
    
    