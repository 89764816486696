import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamDataService } from '../../exam-data.service';
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { NgModule, ElementRef, Injectable } from "@angular/core";
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';

//import {MathJaxDirective} from '../../mathjax.directive';
declare var MathJax:any;

@Component({
  selector: 'app-pbooster',
  templateUrl: './pbooster.component.html',
  styleUrls: ['./pbooster.component.scss']
})

export class PBoosterComponent implements OnInit {


  fractionString: string = 'Inside Angular one half = $\\frac 12$';
  index: number = 3;




bstrForm: FormGroup;
fields:any;
  submitted = false;
  success = false;
appTitle: string = 'Percent App';
site:string;
cat:string;
board: string ;
course: string ;
subject: string ;
subjects: Object;
users : Object;
users2: Object;
title = 'ES- Percentage Booster'
questions: Object ;
showA: boolean = false;
showCA: boolean = false;
showT: boolean = false;
showR: boolean = false;
questionArray : FormArray;
//signedUser: any;
b : string;
s:string;
board11:string;
  frequency: any;
  calling: boolean;
  vids$: any;
  message: string;
  offering: any;
  chapter :any;

get signedUser() { return this.cs.getSignedUser()}
//get offering() { return this.cs.getOffering()}

   update () {
     this.fractionString = 'Inside Angular one third = $\\frac 1'+this.index+'$';
     //MathJax.Hub.Queue(["Typeset",MathJax.Hub,"MathJax"]);
     this.index++;
   }

  constructor(private boardData: BoardDataService, private route: ActivatedRoute,
    private router: Router, private elementRef:ElementRef,private data: ExamDataService,private formBuilder: FormBuilder,private titleService: Title,
    private metaTagService: Meta,
    private cs: ClientInfoService) {

this.route.params.subscribe( params => {
this.site= params['site'];
this.cat= params['cat'];
this.board= params['board'];
this.course= params['course'];
this.subject= params['subject'];
this.offering= params['offering'];
this.subject= params['subject'];
this.chapter = params['chapter'];

});
//alert("Board Retreived from URL: " + this.board);
if(this.board == null){
this.board = this.elementRef.nativeElement.getAttribute('board');
//alert("from parm pass " + this.board);
};



//this.board = 'ICSE';
 //this.subject = 'Chemistry';
   }

   ngOnChanges() {

    
       // MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
       // alert("View typeset Retried! on changes - data load ");
        }



   
  ngOnInit() {
    
 

    this.board11= this.board;

  //not working rather set in center
  this.titleService.setTitle('ES: '+ this.board + ' Percentage Booster');
    this.metaTagService.updateTag({name: 'description', content: '5 questions with answers updated daily very important for ICSE 2019 Students!'});

  this.b = "5";
 /*
   this.data.getUsers().subscribe(data => {
        this.users = data;


    });

*/

this.bstrForm = this.formBuilder.group({
answerIn: ['', Validators.required],
/*
type: this.formBuilder.group({
      dyna: this.formBuilder.array([]) // create empty form array

    })
    */

    });
/*
this.patch();


    this.data.getUsers2().subscribe(data => {
        this.users2 = data;
       // console.log(this.users);
      });


  */

  /* move to this in future */

  //http://localhost/rs/t/esa/ICSE/X/A/tasgn-pbstr/Guest/cheaders/subject
  //this.boardData.getSiteSubjects(this.site,this.board,this.course,"tasgn-pbstr")
  
  let offering = 'tasgn-pbstr';
  offering = this.offering?this.offering:'tasgn-pbstr';
      let sub= this.subject?this.subject:"subject";
  this.boardData.getSiteHeaders(this.site,this.board,this.course,offering,sub)
        .subscribe(data => {
                this.subjects = (data as any).dataList;
                this.frequency = (data as any).refresh;
              
                if(this.chapter){
                  this.fetchQstns() 
                }
                
              });

  }
  

/*
patch() {
  // the formarray
  const control = <FormArray>this.bstrForm.controls.control['dyna'];
  // iterate your object and pushes new values
  this.fields.type.dyna.forEach(x => {
    control.push(this.patchValues(x.label, x.value))
  })
}

// assign the values
patchValues(label, value) {
  return this.formBuilder.group({
    label: [label],
    value: [value]
  })
}

*/
firstClick() {
    this.data.firstClick();

  }

showAns() {

   if(this.cat=='prnt' && this.cs.isUnsigned()){
   // console.log("sending msg for signed Prnt" );
   this.message= "*For signedin Parent,we hide answer key to student,*a proven effectiveness booster";
   this.cs.addMsg(this.message, "toast"); 
  }
  if(this.cs.isUnsigned()){
    //console.log("sending msg for time management" );
    this.message="*Take online test for time check etc.";
    this.cs.addMsg(this.message, "toast"); 
   }
    this.showA= true;
    this.typeSet();
  }

  
hideAns() {
  if(this.cat=='pbstr'){
    //console.log("sending msg for confidence" );
    this.message= "*Booster in supervised(Parent/Teacher)  has better impact";
    this.cs.addMsg(this.message,'toast'); 
  }
  
    this.showA= false;
  }


showTest() {
    this.showT= true;
   // this.typeSet();
  }

hideTest() {
  
  
    this.showT= false;
    //this.typeSet();
  }


showResult() {
    this.showR= true;
    this.typeSet();
  }




  fetchQstns() {
    this.showA= false;
    this.calling = true;   
    this.vids$= this.boardData.getSiteQuestions(this.site,this.board,this.course,this.offering,this.subject,this.chapter) ; 
   }

    

percentageBooster(h:any) {
this.subject= h.subject;
this.showA= false;
this.calling = true;   
let hdrid= this.chapter?this.chapter:h.id;
this.vids$= this.boardData.getSiteQuestions(this.site,this.board,this.course,h.type,this.subject,h.id) ; 

}



onSubmit() {
    this.showCA= true;
  }


  typeSet(){
  //alert("Hello World");
  //MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
 // alert("View typeset Retried! Thanks from tyepset ");

}

 ngAfterViewChecked(){
  //alert("Hello World");
  //MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
  //MathJax.typesetPromise();
  //alert("View typeset Retried- after view checked..! Thanks ");
  this.cs.typeSet('');
}


getTitle(h:any){
  return [h.subject,h.section,h.title].filter(Boolean).join(":");
}
}
