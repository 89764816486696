

<ng-container>
  <p class= "text-info">Select multiple options,if correct</p>
    <ul *ngFor="let o of q.optionList;let j = index"
      class="list-group list-group-flush">
    
      <p *ngIf= 'o' class= "text-secondary" >
        
        <label class="btn-primary" ngbButtonLabel>
         
          <input type="checkbox" [value]= ts.getDigOptn(o,j+1)   (change)="onCheckboxChange($event,o,j+1)" 
           [checked]= checked(o,j+1)  id ={{j+1}}  >         
        
        </label>

        <span innerHTML= ' {{o}}'> </span>
      
        <!--  [(ngModel)]="ts.digAnsrs[j]"  [(ngModel)]="ts.digAnsrs[j]"
        <input type="radio" value={{j+1}} name="digAnsr" [(ngModel)]="q.ansCmnts[0].digAnsr" > 
        <span innerHTML= {{o}}> </span>
            -->

    </p>


        <!-- {{j+1}}-
      <button type="button" class="btn btn-outline-primary"
       innerHTML={{o}} (click)="setDigAnsr(j)">
      </button>
       --> 
    </ul>

    <!--
     <p *ngIf = "ts.digAnsrs"> selected: {{ts.digAnsrs|json}} </p>
     
     <p *ngIf='q.ansCmnts[0].comment'> saved Answer:  {{q.ansCmnts[0].comment}}</p>
    -->
    
    <p *ngIf='q.ansCmnts[0].digAnsr'> selected Answer:  {{q.ansCmnts[0].digAnsr}}</p>


    <!--
    Selected:
    <ol>
        <li *ngFor="let ansr of ts.digAnsrs"> {{ ansr }} </li>
     </ol>
    
     </ng-container>
-->