   
<button *ngIf= "show"  type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'goStore()'  > visit Store</button>


  <!--
<button *ngIf= "showMyMkr"  type= "button" class="badge nav-link btn-outline-primary button px-1 float-right ml-3" (click) = 'myMkr()'  > content Maker</button>
-->


 
<button *ngIf= "showBWC"  type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'BuildWithClick()'  > Build With Click</button>