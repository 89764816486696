<div class= "bg-info" *ngIf="messageService.alerts.length">

  <!--
  <h2>Messages</h2>
  
  <button class="clear"
          (click)="messageService.clear()">clear</button>
  -->
<div class="justify-center" *ngFor='let alert of messageService.alerts'> 
 
<ngb-alert class= "hostMsg" *ngIf= "alert.type!= 'toast'" [type]="alert.type" (close)="messageService.close(alert)">{{ alert.message }}</ngb-alert>

<ngb-toast class= "bg-info text-light  hostToast" *ngIf= "alert.type== 'toast'"
  [header]="alert.title" [autohide]="true" [delay]="alert.delay || 5000"
  (hide)="messageService.close(alert)"
>{{alert.message}}</ngb-toast>

</div>


</div>