import { Component, Input, OnInit } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { QstnhdrService } from '../qstnhdr.service';

@Component({
  selector: 'app-gate-mover',
  templateUrl: './gate-mover.component.html',
  styleUrls: ['./gate-mover.component.scss']
})
export class GateMoverComponent implements OnInit {
  @Input() header:any;
  @Input() i :number;;
  get signedUser(){return this.cs.getSignedUser()}
  
  
  constructor(private qh: QstnhdrService,private ds: BoardDataService,private cs:ClientInfoService) { }

  ngOnInit(): void {
  }

  finalizeDraft(hdr:any,i:number){
    this.ds.finalizeDraft(hdr).subscribe(data => {
      if((data as any).status === 200) {
      this.cs.addMsg("Success:posting done:" + (data as any).result ,"toast");
      //console.log(data);
    }else {
      this.cs.addMsg("error,try later "+ (data as any).result ,"error");
       }
    });
  }



  copyGDUser(hdr:any,i:number){
    this.ds.copyGDUser(hdr).subscribe(data => {
      if((data as any).status === 200) {
        this.cs.addMsg("Success:posting done:" + (data as any).result,'toast' );
      //console.log(data);
    }else {
      this.cs.addMsg("error,try later "+ (data as any).result ,"error");
    }
    });
  }


  promoteGate(hdr:any,i:number){
    this.ds.promoteGate(hdr).subscribe(data => {
      if((data as any).status === 200) {
        this.cs.addMsg("Success:posting done:" + (data as any).result,'toast' );
      console.log(data);
    }else {
      console.log(data);
    
      this.cs.addMsg("error,try later "+ (data as any).result ,"error");
    }
    });
  }
  
  enable(btnId:string,i?:number,hdrRec?:any){
    //return true;
    let component = 'gateMvrList';
     let enable = false;
     enable= this.cs.enable(component,btnId,'',hdrRec);
     return enable;
    }


}
