import { Component, OnInit } from '@angular/core';
import { CntxtService } from '../../../pages/cntxt-menu/cntxt.service';
import { HeaderService } from '../../../tag/header.service';

@Component({
  selector: 'app-mass-bldr',
  templateUrl: './mass-bldr.component.html',
  styleUrls: ['./mass-bldr.component.scss']
})
export class MassBldrComponent implements OnInit {

  //don't pass below two use default else it is provisioned for override.  
  ovrOffering :string;
  ovrHdrFormat: string;

  showQHdr:boolean;
 
  hdrName: string;
  addDigHW: boolean;
  hdr: any;
  show:boolean;
  tagBased: boolean;


  constructor(public cm :CntxtService,public ths:HeaderService) { }

  ngOnInit(): void {
    
   
    this.hdr= this.ths.hdrWithDefault();
    
    let ofr= this.ovrOffering?this.ovrOffering:this.cm.offering;
    //console.log(ofr);
    this.tagBased = this.cm.fd.tagBasedForCreate(ofr);
    this.pickHdrFormat();
    this.hdr.hdrFormat= this.ovrHdrFormat;
  }


  
  pickHdrFormat(){
  
    
switch(this.cm.cat){
  case 'mkr-pbstr':
    this.hdrName = "multi Set";
    if(!this.ovrHdrFormat){this.ovrHdrFormat = "bl"}
    break;
 
  default: 
  this.hdrName = "multi .. ";
  if(!this.ovrHdrFormat){this.ovrHdrFormat = "bl"}
   }
    
 return this.ovrHdrFormat;
 }

 
  
  }

