<div [hidden]="submitted" >
    
    <form #ngform (ngSubmit)="onSubmit(fileName,false)">

<div  class="form-group">
    <label for="fileName">fileName: </label>
    <input type="text" class="form-control" id="fileName" required [(ngModel)]="fileName" name="fileName">
  </div>

  <button type="submit" class="badge btn-outline-primary">Create New File</button>

  <button *ngIf= 'cs.enable("createFile","dryRun")'type="button" (click)="onSubmit(fileName,true) " class="badge btn-outline-primary">dry run-check Create Folder</button>

    </form>