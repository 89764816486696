

<div *ngIf= "asoHdr" class= "h5 text-center mt-4">{{getTitle(asoHdr)}} </div>

<p class="text-right text-muted small">grphdr: {{grpAsoId}} </p>

    <button *ngIf="cs.enable('asoHdrBtn','associate',null,asoHdr)"  type="button" class="mx-2 btn bade btn-outline-primary border-0"
        (click)='associate()'>
        select & associate </button>

    <button *ngIf="cs.enable('asoHdrBtn','createAssociate',null,asoHdr)"  type="button" class="mx-2 btn bade btn-outline-primary border-0"
        (click)='addAsoHdr = !addAsoHdr'>
        create associate </button>

<app-aso-hdr-crtr *ngIf="addAsoHdr " [asoHdr]='asoHdr'> </app-aso-hdr-crtr>
