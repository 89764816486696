



<div class="text-center my-5 py-5" >  You are logged out now</div>


<!--
<a href = {{signinUrl()}}>  
    
    <button class="btn btn-primary border-0 btn-lg btn-block ">
        <div class="badge border-0 px-2"> manage user </div> </button> 
</a>

-->
    