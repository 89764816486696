<!--don't remove else top margin disaapears despite putting class in below main div-->

<div class="m-2">

  <br>
  <br>
  .
</div>

<div *ngIf="true" class="text-center float-center m-3 ">


  <app-cmnt-list *ngIf='headerId' [cmntType]='"Dqchat"'
    [featureType]="'discuss'" [parentHdrId]=headerId [parentDtlId]='q.id'>
  </app-cmnt-list>

  <!--
  <app-comment-list *ngIf='headerId'
   [cmntType]= '"Dqchat"' [featureType] = "'discuss'" [parentHdrId]=headerId
    [parentDtlId] = 'q.id'  
    > </app-comment-list>
  --> 
</div>


<div class="mr-2">

  <button class="float-right" *ngIf='qs.enable("tagQstn")'
     class="btn-secondary btn-sm-4 badge mx-2" (click)="tagQstn('Dbkt')">tag
    Teacher</button>

  <span class="bd-highlight"> <span  class="badge mx-2 btn-sm-2 bg-warning"
      *ngIf= "q.bkmCmnts && q.bkmCmnts.length> 0"> count:
      {{q.bkmCmnts.length}} </span>
  </span>

  <button class="btn-md btn-secondary badge mx-2"
    *ngIf='false && qs.enable("rfc")' (click)="processRfc(q,i)">raise doubt
  </button>


  <button  *ngIf='qs.enable("tagSelfRvsn")'
     class="btn-secondary badge mx-2 btn-sm-2" (click)="tagQstn('Dsrvsn')">tag
    for own review</button>




  <button  *ngIf='qs.enable("selfNote")'
     class="btn-secondary badge mx-2 btn-sm-2"
    (click)="showCmntList('DselfNote')">note for self</button>



  <button  *ngIf='false && qs.enable("askTeacher")'
     class="btn-secondary badge mx-5 btn-sm-2"
    (click)="showCmntList('Dclr')">clarifications</button>

</div>

<!--
<span class="p-2 bd-highlight"> 
  <button   *ngIf='true || qs.enable("askTeacher")' class = "btn-secondary badge mx-5 btn-sm-2"
  (click)="showCmntList('Dsrvsn')">clarifications</button>
</span>
-->
<!--         
<app-cmnt-list *ngIf='qs.enable("askTeacher")'
    [featureType]='qs.status=="rvsn"?"rvsn-spdr":qs.offering'
    [cmntType]="'Dclr'" [parentHdrId]=qs.chapter 
     > </app-cmnt-list>
         
[parentDtlId]=q.id [commentsI]=q.clrCmnts 
qs.status=="rvsn"?"rvsn-spdr":qs.offering
-->


<div class="text-center float-center">
  <app-comment-list *ngIf='selfNote'     [featureType]=qs.offering
        [cmntType]="'DselfNote'"  [parentHdrId]=qs.chapter [parentDtlId]=q.id
        [commentsI]=q.selfNote> </app-comment-list>
</div>


<div class="text-center float-center">
  <app-comment-list *ngIf='clarify'     [featureType]=qs.offering
        [cmntType]="'Dclr'"  [parentHdrId]=qs.chapter [parentDtlId]=q.id
        [commentsI]=q.clrCmnts> </app-comment-list>
</div>


<!--
-->

<!-- add-qstn-cmnt has just add function not list-->
<app-add-qstn-cmnt *ngIf="false && showRfcI == i && headerId"
  [cmntType]='"Dqchat"' [featureType]="'discuss'" [parentHdrId]=headerId
  [parentDtlId]=q.id> </app-add-qstn-cmnt>