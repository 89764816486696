import { Component,Input, OnInit } from '@angular/core';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';
@Component({
  selector: 'app-slbs-mpr',
  templateUrl: './slbs-mpr.component.html',
  styleUrls: ['./slbs-mpr.component.scss']
})
export class SlbsMprComponent implements OnInit {

  @Input() q:any;
  @Input() i:number;

  changeAddI:number;
  success:boolean;
  showAddI: number;

  
  constructor(public qs:QstnListService) { }




  ngOnInit(): void {
  }



}

