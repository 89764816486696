


<div  *ngIf = "qhf.enable(fldList,'extra')" class="form-group">
  <label for="extra">extra-json:  </label>
  {{edi|json}}
  <!--input type="text" class="form-control" id="extra" [(ngModel)]="d.extra" name="extra"-->
</div>



  
<div *ngIf='qhf.enable(fldList,"image")' class="form-group">
  <label for="image">title-image URL</label>
  <input type="string" class="form-control" [(ngModel)]="edi.image" value= "edi.image"
  name= "image" >
</div>




<div *ngIf= 'qhf.enable(fldList,"extraJson")'>
  {{edi.subjects|json}}  
  <app-my-json class="my-5" [table]= "'qstndtl'" [q] = "d" [justOneField]= "'extra'"> </app-my-json>
  
  </div>









<ng-container >
  <div *ngIf='qhf.enable(fldList,"testTime")' class="form-group">
      <label for="testTime">Test Time in Minutes</label>
      <input type="text" class="form-control" 
      [(ngModel)]="edi.testTime" 
      name= "testTime"   placeholder="25">
    </div>
  
    
  
  
  <div *ngIf='qhf.enable(fldList,"dueDate")' class="form-group">
      <label for="dueAng">Due date</label>
      {{edi.dueDate|date:'dd:MMM'}}
      <input type="date" class="form-control" [(ngModel)]="edi.dueDate" value= "edi.dueDate"
      name= "dueAng"  placeholder="dd/mm/yyyy">
    </div>
  
    
    
  <div *ngIf='qhf.enable(fldList,"cfgFldr")' class="form-group">
    <label for="cfgFldr">Config Folder</label>
    <input type="string" class="form-control" [(ngModel)]="edi.cfgFldr" value= "edi.cfgFldr"
    name= "cfgFldr" >
  </div>
  
  
 
  
  </ng-container>
  