/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./book-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./book-list.component";
var styles_BookListComponent = [i0.styles];
var RenderType_BookListComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_BookListComponent, data: {} });
export { RenderType_BookListComponent as RenderType_BookListComponent };
export function View_BookListComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_BookListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-book-list", [], null, null, null, View_BookListComponent_0, RenderType_BookListComponent)), i1.ɵdid(1, 114688, null, 0, i2.BookListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookListComponentNgFactory = /*@__PURE__*/ i1.ɵccf("app-book-list", i2.BookListComponent, View_BookListComponent_Host_0, {}, {}, []);
export { BookListComponentNgFactory as BookListComponentNgFactory };

