
<div class ="row"> 
 
 
 <button class= "text-center btn btn-primary  btn-lg " (click) = 'showRslt()'> Show-Result </button>  



<app-cmnt-list *ngIf= "h" class= "text-right" cmntType= "Htest" [parentHdrId] = h.id [featureType] = h.type  > </app-cmnt-list>

</div>

<br>
<br>
<br>

