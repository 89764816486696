  


import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
    

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
@Input() posts:any;

  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  //posts;
   
  constructor(private http: HttpClient) { }
   
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
    };
   /*
    this.http.get('http://jsonplaceholder.typicode.com/posts')
      .subscribe(posts => {
        this.posts = posts;
    });
   */

  }
   
}

