<div class="mx-3">

<a class="col-2 " *ngIf="qstnId"
routerLink="/{{site}}/{{cat}}/{{board}}/{{course}}/{{offering}}/{{subject}}/{{chapter}}"
class="px-2 btn btn-outline-primary badge border-0"> <span
    class="" innerHTML="{{'show Unit/Chapter'}}"> </span></a>



<a class="col-2 " *ngIf="chapter"
    routerLink="/{{site}}/{{cat}}/{{board}}/{{course}}/{{offering}}/{{subject}}"
    class="px-2 btn btn-outline-primary badge border-0"> <span class=""
        innerHTML="{{'change Unit'}}"> </span></a>

   



<a class="col-2 " *ngIf="subject"
    routerLink="/{{site}}/{{cat}}/{{board}}/{{course}}/{{offering}}"
    class="px-2 btn btn-outline-primary badge border-0"> <span
        class="" innerHTML="{{'change Subject'}}"> </span></a>


<!--class="badge    btn btn-outline-secondary border-0"-->
<a  class="px-2 btn btn-outline-secondary badge border-0" 
    *ngIf="offering" routerLink="/{{site}}/{{cat}}/{{board}}/{{course}}/"> <span
        class="" innerHTML="{{'Last Menu'}}"> </span></a>

        

<!--class="badge    btn btn-outline-secondary border-0"-->
<a  *ngIf='cm.enable("content")' class="px-2 btn btn-outline-primary badge border-0" 
 routerLink="/{{site}}/{{'content'}}/{{board}}/{{course}}/"> <span
    class="" innerHTML="{{'content Store'}}"> </span></a>



<span *ngIf='groupId && cm.enable("cfgSetup")' >
        <button *ngIf= "cat && !feature && !cm.subject" type="button" class="px-2 btn btn-outline-primary badge border-0" (click)='cm.navigate("admin", "cfg/" + cat)'> Config
       </button>
</span>
</div>
      