/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./admin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./admin.component";
import * as i3 from "../../board-data.service";
import * as i4 from "../../exam-data.service";
import * as i5 from "@angular/forms";
import * as i6 from "../../shared/client-info.service";
var styles_AdminComponent = [i0.styles];
var RenderType_AdminComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_AdminComponent, data: {} });
export { RenderType_AdminComponent as RenderType_AdminComponent };
export function View_AdminComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_AdminComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin", [], null, null, null, View_AdminComponent_0, RenderType_AdminComponent)), i1.ɵdid(1, 114688, null, 0, i2.AdminComponent, [i3.BoardDataService, i4.ExamDataService, i5.FormBuilder, i6.ClientInfoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminComponentNgFactory = /*@__PURE__*/ i1.ɵccf("app-admin", i2.AdminComponent, View_AdminComponent_Host_0, {}, {}, []);
export { AdminComponentNgFactory as AdminComponentNgFactory };

