

<div class= "mx-2 px-2 full11Width">
  
  <div *ngIf = "enable('comment')" class="form-group py-1">
    <label for="comment">
       pls enter:</label>
     
    <button type="button" class="btn badge  btn-outline-info float-right mr-3 mr-2"
      (click)='htmlcomment =!htmlcomment'>enter advanced </button>

      <!-- flipWindow = -1 is used for quill format, later add one field in comment for format-->
      <button type="button" *ngIf = "quill && this.bw.hasWindow()" class="btn badge  btn-outline-info float-right mr-3"
      (click)='quillComment =!quillComment; c.flipWindow = -1;'> new text+ </button>
  
    <br>
    <!--
    -->
    <app-custom-quill *ngIf="quillComment"  [content] = 'c.comment' [table] = '"comment"'
        (output)="fillValue('comment',$event)">
    </app-custom-quill>
    <br>

    <input *ngIf="!htmlcomment else ck2" type="text" class="form-control pr-2" id="comment"
    required [(ngModel)]="c.comment" name="comment">
    
    <ng-template #ck2>
    
     <ckeditor *ngIf="htmlcomment" [(config)]="cfg.withWordConfig" #editor
      debounce="500" [(ngModel)]="c.comment" id="comment" name="comment">
    </ckeditor>
  

    <br>
    Actual view:
    <p innerHTML={{c.comment}}> </p>
  </ng-template>

  </div>

  
<ng-container *ngIf='askFlag'>
  <div class="form-group row mx-3">
    <label for="askMode">Ask Mode: </label>
    <ul id= 'askMode' *ngFor="let o of askModes;let j = index" >
  <span class= 'col-md-4'>
    <input  type="radio" value={{o}} name="digAnsr" [(ngModel)]="comment.status" > {{o}}
  </span> 
  </ul>
  
</div>
</ng-container>


  <ng-container *ngIf= "c.cmntType == 'Hmtg'">
    <div  class="form-group">
      <label for="course">Course/Class</label>
      <input type="text" class="form-control" id="course" required [(ngModel)]="c.course" name="course" value= course >
    </div>
    <div  class="form-group">
      <label for="clsSec">section/Batch</label>
      <input type="text" class="form-control" id="clsSec" required [(ngModel)]="c.clsSec" name="clsSec" value= clsSec >
    </div>
    </ng-container>


<div *ngIf = "enable('response')" class="form-group py-1">
    <label for="response">response: </label>
    <button type="button" class="btn badge  btn-outline-info float-right mr-3"
      (click)='htmlresponse =!htmlresponse'> text+ </button>
    <br>
    <input *ngIf="!htmlresponse else ck2" type="text" class="form-control" id="response"
    required [(ngModel)]="c.response" name="response">
    
    <ng-template #ck2>
    <ckeditor *ngIf="htmlresponse" [(config)]="cfg.withWordConfig" #editor
      debounce="500" [(ngModel)]="c.response" id="response" name="response">
    </ckeditor>
    <br>
    Actual view:
    <p innerHTML={{c.response}}> </p>
  </ng-template>

  </div>

  <div *ngIf= "enable('extLink')" class="form-group">
    <label for="extLink">route: </label>
    <input type="text" class="form-control" id="extLink" required [(ngModel)]="c.extLink" name="extLink">
  </div>

  <div *ngIf= "enable('cmntType')" class="form-group">
    <label for="cmntType">cmnt-type: </label>
    <input type="text" class="form-control" id="cmntType" required [(ngModel)]="c.cmntType" name="cmntType">
  </div>

  <div *ngIf= "enable('rspnseUser')" class="form-group">
    <label for="rspnseUser">Response User: </label>
    <input type="text" class="form-control" id="rspnseUser" required [(ngModel)]="c.rspnseUser" name="rspnseUser">
  </div>

  <div *ngIf= "enable('addUser')" class="form-group">
    <label for="addUser">Add User: </label>
    <input type="text" class="form-control" id="addUser" required [(ngModel)]="c.addUser" name="addUser">
  </div>

  
  <div *ngIf= "enable('owner')" class="form-group">
    <label for="owner">Owner: </label>
    <input type="text" class="form-control" id="owner" required [(ngModel)]="c.owner" name="owner">
  </div>

  
  
  <div *ngIf= "enable('marks')" class="form-group">
    <label for="marks">Obtained Marks: </label>
    <input type="text" class="form-control" id="marks" required [(ngModel)]="c.marks" name="marks">
  </div>


  <button type="button" *ngIf= 'enable("attach")' (click)="toggleUpload=!toggleUpload" class="btn badge btn-outline-secondary" style="margin-left: 10px">{{toggleUpload?'Hide':'Upload Image'}}</button>
  
</div>


<app-file-upload *ngIf= 'toggleUpload' [iComment] = c > </app-file-upload>













  