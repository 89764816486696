import { EventEmitter, Injectable, Output } from '@angular/core';
import { CommentService } from '../../comments/comment.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { Detail } from '../../tag/detail';
import { DetailService } from '../../tag/detail.service';
import { Comment } from '../../comments/comment';
import { HeaderService } from '../../tag/header.service';
import { QstnDtlService } from '../../qstndtl/qstn-dtl.service';
import { Subject } from 'rxjs';
import { FixedDataService } from '../../shared/fixed-data.service';
import { BoardDataService } from '../../board-data.service';
import { MathJaxService } from '../../shared/math-jax.service';
declare var MathJax: any;

@Injectable({
  providedIn: 'root'
})
export class QstnListService {
  public searchTerm: any;
  public searchField: string;
  public page: number = 1;

  public static emptyVid: Subject<boolean> = new Subject<boolean>();
  qstnHdr: any;
  
  
  pageSize:number;

  
  get site() { return this.cs.getSite() };
  get cat() { return this.cs.getCat() };
  get board() { return this.cs.getBoard() };
  get course() { return this.cs.getCourse() };
  get feature() { return this.cs.getFeature() };
  get offering() { return this.cs.getOffering() };
  get justOffering() { return this.cs.getJustOffering() };
  get subject() { return this.cs.getSubject() };
  get chapter() { return Number(this.cs.getChapter() ? this.cs.getChapter() : 0) }
  get mode() { return this.cs.getMode() };
  get qstnId() { return this.cs.qstnId };
  get status() { return this.cs.getStatus() };
  get groupId() { return this.cs.getGroupId() };
  get signedUser() { return this.cs.getSignedUser() }
  get unsigned() { return this.cs.isUnsigned() }
  get dfltExpandedAnsrs() {return this.cs.enable('qstnList','expandedAnsrs')}
  /*
  //rba mathml is 5, remove it later 
  get pageSize() {if (this.cs.getOffering() == "r1ba") {return 5} else {if (this.cs.getCat() == "my" || this.cs.getCat() == "merge" || this.cs.getCat() == "dgtz") return 100; else { return 10 } }}
*/

  expandedAnsrs:boolean;
  onlyQstn: boolean;
  filteredList = [];
  originalList = [];
  bldrSet: number;
  public format = "test";
  constructor(private commentService: CommentService, public cs: ClientInfoService, private detailService: DetailService, public qstnDtlService: QstnDtlService,public fd:FixedDataService , private dataService: BoardDataService,private mj:MathJaxService) {
    HeaderService.updateDhwHdrId.subscribe(res => {
      this.bldrSet = res;
      //console.log(this.bldrSet);
    });
if(this.cs.getUserGroup().includes("ao_mngr")) {this.pageSize= 25} else{
this.pageSize = 15;
}
  }

  tagBased(offering: string) {
    return this.cs.tagBased(offering);
  }


  formatSeq(sequence: string, subSeq: string, part: string) {
    return [sequence, subSeq, part].filter(Boolean).join('-');
  }
 i= 1;
  typeSet(divId: string) {
    if(this.i>=1){
    this.mj.typeSet(divId);
    this.i++;
    }
    else{
      console.log("calling too many times");
    }
    /*
    //MathJax.Hub.Queue(["Typeset", MathJax.Hub, divId]);
    if(MathJax && MathJax.typesetPromise){
    MathJax.typesetPromise();
    }
    */

  }

  prepPostData(type: string, tagName: string, qstnId: number, k: number) {
    let comment: Comment = new Comment();
    comment.source = tagName,
      comment.parentDtlId = qstnId,
      comment.parentHdrId = this.chapter,
      comment.parentDtlId = qstnId,
      comment.cmntType = type,
      comment.featureType = this.offering,
      comment.domain = this.site;
    comment.addUser = this.signedUser;
    return comment;
  }

  flipExpandAllAnsrs() {
    this.expandedAnsrs = !this.expandedAnsrs;
  }
  
  navigateQstn(chapter:number,q:any) {
    let url = this.cs.router.url+ "/view-qstn/" + q.id;
   return url;
    // this.cs.router.navigate([url]);
  }



  postTag(tagHdrId: number, qstnId: number, k: number) {
    if (!tagHdrId) {
      alert("Failed: PLease create/select tag Hdr id and then try");
      return;
    } else {

      let tagDtl = new Detail();
      tagDtl.tagHdrId = tagHdrId;
      tagDtl.qstnDtlId = qstnId;

      this.detailService.createDetailWithAutoSeq(tagDtl)
        .subscribe(data => {
          if ((data as any).status === 200) {
            console.log(data);
            this.cs.addMsg((data as any).message, 'toast')
          } else {
            this.cs.addMsg("Failure; try later")
          }
        },error => {
          console.error();
         this.cs.addMsg("Failure: " +  error.status)
        }
        );
    }
  }


  //hdrRec is the header's rec, detailRec is third parm..
  enable(btnId: string, detailRec?: any, hdrRec?: any,i?: number) {
    let component = 'qstnList';
    
    if(btnId=="showAsoRec"){
  //  console.log("hello");
   //detailRec= null;
    }

    //gpb-tasgn-pbstr is for guest-pb hence answer eligible.
    
    
    if (btnId == "viewAnsrEligible" && this.offering == 'tasgn-pbstr' && (this.unsigned || this.cat == 'gpb')) {
      return true;
    }
    
    

    if (btnId == "studentView") {
     return this.cs.enable('qstnList', 'studentView')
    }


    if (!this.preChecker(btnId, i, "")) { return false }


    if (btnId == "textFileEdit") {
      return true;
    }
    /*
      if( btnId == "viewAnsrEligible"){
        if(this.cat.includes("-bldr")){
        return false;
      }}
      */


    if (btnId == "displayAllAnswer") {
      return this.expandedAnsrs;
    }




    if (btnId == "writeAnsr") {
      if ((this.offering && this.offering.includes('twrk')) && this.cat != "te") {
        return true;
      }
      else {
        return false;
      }
    }

    let assgnOffering = ['twip-dhw', 'tavl-dhw', 'twip-hwh', 'tavl-hwh', ''];
    if (btnId === "assign" && !(this.offering.includes('tavl') || this.offering.includes('twip'))) {
      return false;
    }

    let ansrNotNeeded = ['vid','nws'];
    if (detailRec && btnId === "clarify" && (!detailRec.answer && !ansrNotNeeded.includes(this.justOffering))) {
      return false;
    }



    
    let enable = false;
    
    //consider just header and not detail for cfg change 
    if ((["app_admin", "site_admin", "siteowned", "admin"].includes(this.cat)) && this.offering == "cfg" && this.chapter) {
      if (this.cs.ss.getUserGroup().includes('site_manager')) {
       detailRec= null;
       
      }

    }

    enable = this.cs.enable(component, btnId, detailRec, hdrRec);

    return this.customEnable(btnId, enable,detailRec);

  }

  filterList(cmntType?: string) {
    if (!cmntType) {
      //console.log(this.originalList) ; 
      this.filteredList = this.originalList;
      return this.originalList;

    }
    if (this.originalList) {
      let filteredList = this.originalList.filter(item => {
        switch (cmntType) {
          case 'Dbkt':
            if (item.bkmCmnts && item.bkmCmnts.length > 0) {
              return true;
            }
            break;
          case 'Dclr':
            if (item.clrCmnts && item.clrCmnts.length > 0) {
              return true;
            }
            break;
          case 'Dcmnt':
            if (item.genCmnts && item.genCmnts.length > 0) {
              return true;
            }
            break;

            case 'Dsrvsn':
              //console.log("Dsrvsn hit");
              //console.log(item.cmntsData);
              if (item.cmntsData && item.cmntsData.Dsrvsn && item.cmntsData.Dsrvsn.length > 0) {
                return true;
              }
              break;
          
            case 'Dans':
              if (item.ansCmnts && item.ansCmnts.length > 0 ) {
                return true;
              }
              break;

            case 'Dans::correct':
              if (item.ansCmnts && item.ansCmnts.length ==1 && item.ansCmnts[0].correct ) {
                return true;
              }
              break;
              case 'Dans::incorrect':
                if (item.ansCmnts && item.ansCmnts.length ==1 && !item.ansCmnts[0].correct ) {
                  return true;
                }
                break;
  
            default: {
            return false;
          }
        }

      });
      this.filteredList = filteredList;
      //console.log(this.filteredList) ; 
      return filteredList;
    }

  }



  fldFltrList(fldKey?:string,value?: string) {
    if (!fldKey) {
      //console.log(this.originalList) ; 
      this.filteredList = this.originalList;
      return this.originalList;

    }
    if (this.originalList) {
      let filteredList = this.originalList.filter(item => {
        switch (fldKey) {
          case 'mcqNoDigAnsr':
            if (item.format && item.format.includes("MCQ")  && !item.digAnsr) {
              return true;
            }
            break;

            case 'mcqNoFormattedArray':
              if (item.format && item.format.includes("MCQ")  && 
              (!item.digOptns || !item.digOptns.startsWith("{"))){
                return true;
              }
              break;

              
          case 'format':
            if (item.format && item.format.includes(value)) {
              return true;
            }
            break;

            default: {
              return false;
            }
          }
  
        });
        this.filteredList = filteredList;
        //console.log(this.filteredList) ; 
        return filteredList;
      }
  
    }

    

    customEnable(btnId: string, enable: boolean, q:any) {return enable}


  customDeleteEnable(btnId: string, enable: boolean, q:any) {

if(enable){
//console.log(q);
//updateQstnLocal , Global is the intent

// Idea is school should be able to update their own record only...
// global is for master record in case their and local is avl- record to create custom/local copy.
// delete is tied to local only for the time being. check in future.
// move it to crud component ideally 

if(btnId.includes("update1Qstn")){

if (q && q.headerId && (!this.qstnHdr || (q.headerId != this.qstnHdr.id)) ){ 

 }
if(this.qstnHdr && (this.qstnHdr.school != this.cs.getSchool())){

//if internal customer like digi and cmn? then put exception
//give super admin all authority
//if(this.cs.getUserGroup().includes("app_admin")){return true}
//if(!this.qstnHdr.school && this.cs.getUserGroup().includes("crclm_mngr")){return true}
return false;   

}

}
}

    return enable;
  }


  preChecker(btnId: string, i?: number, detailRec?: any) {
   
   
   
    /*
    if(btnId === "practiceMode" && (this.offering== 'tasgn-pbstr')){ return false}
   */
    //this.cat &&this.cat.includes('-bldr') ||
    if (btnId === "ansrData" && (this.offering && this.offering.includes('crct') && (this.cs.getUserGroup() && this.cs.getUserGroup().includes('learner')))) {
      return false;
    }

    let noAnsrOfr = ['tasgn-dhw', 'twrk-thw', 'tasgn-eps', 'twrk-tps'];

    if (btnId === "ansrData" && noAnsrOfr.includes(this.offering)) {
      return false;
    }
    return true;
  }


  getSearchTerm(sField: string, $event) {
    this.searchTerm = $event;
    this.searchField = sField;
    this.page = 1; //reset page for filter
  }

  changeDtlStatus(id: any, status: string) {
    const payload = {
      parentId: this.chapter,
    };

    this.qstnDtlService.changeStatus(id, status).subscribe(data => {
      if ((data as any).status === 200) {
        console.log(data);
         this.cs.addMsg((data as any).result + " changed to " + status);
        return true;
      } else {
        alert("error,try later");
        return false;
      }

    }
    );

  }

  
}
