import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JustSubjectListComponent } from './just-subject-list/just-subject-list.component';


import {RouterModule} from '@angular/router';
import { OfrTitleComponent } from './ofr-title/ofr-title.component';
import { OfrBtnComponent } from './ofr-btn/ofr-btn.component';
import { HdrUrlShowerComponent } from './hdr-url-shower/hdr-url-shower.component';
import { ParmLoaderComponent } from './parm-loader/parm-loader.component';
import { MailSenderComponent } from './mail-sender/mail-sender.component';
import { FormsModule } from '@angular/forms';
import { NgsMonacoComponent } from './ngs-monaco/ngs-monaco.component';
//import { CodeEditorModule } from '@ngstack/code-editor';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { CustomHdrComponent } from './custom-hdr/custom-hdr.component';
//import { QuillModule } from 'ngx-quill';
import { QuillComponent } from './quill/quill.component';
import { CustomQuillComponent } from './custom-quill/custom-quill.component';
import { QuillModule } from 'ngx-quill';
import { SeoComponent } from './seo/seo.component';
import { DayCounterComponent } from './day-counter/day-counter.component';
import { RecSlctrComponent } from './rec-slctr/rec-slctr.component';
import { BackDirective } from './back.directive';
import { ShortUrlPipe } from './short-url.pipe';
import { PaginatorComponent } from './paginator/paginator.component';
import { MaximizeDirective } from './maximize.directive';
import { UnitNavigatorComponent } from './unit-navigator/unit-navigator.component';
import { SubDescComponent } from './sub-desc/sub-desc.component';
import { BookListComponent } from './book-list/book-list.component';

const monacoConfig: NgxMonacoEditorConfig = {
  baseUrl: './assets', // configure base path for monaco editor default: './assets'
  defaultOptions: { scrollBeyondLastLine: false }, // pass default options to be used
  //onMonacoLoad: () => { console.log((<any>window).monaco); } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};

@NgModule({
  declarations: [JustSubjectListComponent, OfrTitleComponent, OfrBtnComponent, HdrUrlShowerComponent, ParmLoaderComponent, MailSenderComponent, NgsMonacoComponent, CustomHdrComponent, QuillComponent, CustomQuillComponent, SeoComponent, DayCounterComponent, RecSlctrComponent, BackDirective, ShortUrlPipe, PaginatorComponent, MaximizeDirective, UnitNavigatorComponent, SubDescComponent, BookListComponent],
  imports: [
    CommonModule,RouterModule,FormsModule,
    //CodeEditorModule
    //MonacoEditorModule.forRoot() // use forRoot() in main app module only.
    MonacoEditorModule.forRoot(monacoConfig),
    QuillModule.forRoot()
  ],
  exports:[
    JustSubjectListComponent ,
    OfrTitleComponent,
    HdrUrlShowerComponent,
    ParmLoaderComponent,
    MailSenderComponent,
    NgsMonacoComponent ,
    CustomHdrComponent,
    CustomQuillComponent,
    QuillComponent,
    QuillModule,
    SeoComponent,
    DayCounterComponent,
    RecSlctrComponent,
    ShortUrlPipe,
    PaginatorComponent,
    UnitNavigatorComponent,
    SubDescComponent,
    BookListComponent
    
  ]
})
export class GenModule { }
