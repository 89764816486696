import { Component,Input, OnInit } from '@angular/core';
import { Header } from '../../qstn/header';
import { QstnListService } from '../qstn-list/qstn-list.service';

@Component({
  selector: 'app-qstn-btns',
  templateUrl: './qstn-btns.component.html',
  styleUrls: ['./qstn-btns.component.scss']
})
export class QstnBtnsComponent implements OnInit {
  @Input() q:any;
  @Input() i:number;
  @Input() h:Header;
  s:boolean;
  show:boolean = false;
  
  constructor(public qs:QstnListService) { }

  ngOnInit(): void {
  }
   
  }
