
How you feel on our site today?
<br>
<br>

<div class= "d-flex row  img-fluid">
<button (click)='addCount("happy")' class="btn btn-primary mx-5" style="margin-left: 10px">
        <img src="..\..\..\assets\img\reaction\Happy.svg"> <br> Happy
    </button>
    

    <button (click)='addCount("amused")' class="btn btn-primary mx-5" style="margin-left: 10px">
    <img src="..\..\..\assets\img\reaction\Amused.svg"> <br> Amused
    </button>

    <button (click)='addCount("excited")' class="btn btn-primary mx-5" style="margin-left: 10px">
        <img src="..\..\..\assets\img\reaction\Excited.svg"> <br> Excited
    </button>

    <button (click)='addCount("angry")' class="btn btn-primary mx-5" style="margin-left: 10px">
        <img src="..\..\..\assets\img\reaction\Angry.svg"> <br> Angry
    </button>

    <button (click)='addCount("sad")' class="btn btn-primary mx-5" style="margin-left: 10px">
        <img src="..\..\..\assets\img\reaction\Sad.svg"> <br> Sad
    </button>

</div>    