


<div *ngIf="ch">   
<p  class= "row text-center m-5">hello : {{ch.userId}} please change password</p>

<div class= "row text-center ml-5" >
    <form [formGroup]="cpForm" (ngSubmit)="onSubmit()">

        <input class="form-control mb-2" type="password" formControlName="password" placeholder="password">


        <div *ngIf="submitted && cpForm.controls.password.errors" class="error">
        <div *ngIf="cpForm.controls.password.errors.required">Password is required</div>
      
      </div>

  
      <input class="m-2 btn btn-primary btn-lg" type="submit" value="Submit" >

</form>
</div>

</div>
