export class Header {
    
    id: number;
    site:string;
    board:string;
    course:string;
    clsSec:string;
    type:string;
    subject: string;
    book:string;
    branch:string;
    chapterId:string;
    section: string;
    qstnHdrId:number;
    tagHdrId:number;
    owner:string;
    bstrSet:string;
    status:string;
    headerId:number;
    prntHdrId:number;
    title:string;
    addUser:string;
    addDate:string;
  summary: any;
  school: any;
  location: string;
  extra:string;
  hdrFormat: string;
  year:number;
    
  
    }
