import { Component, Input, OnInit } from '@angular/core';
import { ConfirmModalService } from '../../shared/confirm-modal.service';
import { BoardDataService } from '../../board-data.service';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';
import { TagService } from '../../shared/tag.service';
import { QstnDtlService } from '../qstn-dtl.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { BtnService } from '../../gen/btn.service';

@Component({
  selector: 'app-crud-qstndtl',
  templateUrl: './crud-qstndtl.component.html',
  styleUrls: ['./crud-qstndtl.component.scss']
})
export class CrudQstndtlComponent implements OnInit {
  @Input() q: any;
  @Input() i: number;
  @Input() h: any;

  showUpdate: boolean;
  showUpdateGNum: number;
  showUpdateLNum: number;
  showUpdateTNum: number;
  changeSpan: any;
  changeAddI: number;
  ownUpdate: boolean;
  get chapter() { return this.qs.cs.getChapter() }
  constructor(private tagService: TagService, private qdServe: QstnDtlService, public qs: QstnListService, private dataService: BoardDataService, private confirmModalService: ConfirmModalService, public cs:ClientInfoService, public b:BtnService) { }
  success: boolean;
  showAddI: number;
  addDigId: any;
  successI: number;
  expandI: number;
  showUpdateI: number;
  showRfcI: number;
  headerId :number;

  
  ngOnInit(): void {
     this.headerId=  this.qs.chapter? this.qs.chapter : this.q.headerId;
    
    if(!this.h && this.q.headerId){
      this.dataService.getHdrObj(this.q.headerId).subscribe(data => {
       let h:any = data;
        if(h.status==200 && h.result){
          this.h = h.result;
          } else{
            console.log("hdrObj for " + this.q.headerId + " couldn't be retrieved for crud-qstndtl");     
          }
      });
    }  
    //note- this is for local set owned by user and allowed in main enable.
    if(this.h && this.q.headerId == +this.chapter && 
      (this.h.owner ==this.cs.ss.getSignedUser() ||(!this.h.owner && this.h.addUser ==this.cs.ss.getSignedUser()) ) ){
     this.ownUpdate = this.cs.enable('qstnDtl','updateLocal'); 
    }

      
  }


  expand(i: number) {
    this.expandI == i ? this.expandI = -1 : this.expandI = i;
  }

  show(btnId){
  if(this.qs.enable('update')){  
  return this.b.show('crudQstnDtl',btnId, this.h); 
  }
  return false;
  }

  
  updateId:number;

  processUpdate(dtl: any, i: number, changeSpan) {
     
    this.changeSpan = changeSpan;
    this.showUpdate = !this.showUpdate;
    
    if (changeSpan == 'local') {
    this.updateId= dtl.id;   
    }
    
    if (changeSpan == 'global') {
      this.updateId= dtl.id;   
    }
    if (changeSpan == 'orgnl') {
      this.updateId= dtl.prntDtlId;   
    }


   
  }



  processUpdateExisting(dtl: any, i: number, changeSpan) {
    this.showUpdate = !this.showUpdate;
    
    if (changeSpan == 'local') {
      this.showUpdateLNum = i;
      this.showUpdateGNum = -1;
    }
    
    if (changeSpan == 'global') {
      this.showUpdateLNum = -1;
      this.showUpdateGNum = i;
    }

    
    this.changeSpan = changeSpan;
  }


  tagBasedForCreate() {
    return this.tagService.tagBasedForCreate(this.qs.cs.getJustOffering());
  }

  processRfc(q, i) {
    this.showRfcI == i ? this.showRfcI = -1 : this.showRfcI = i;
  }

  flipTagDtl(i: number) {
    this.showUpdateTNum == i ? this.showUpdateTNum = -1 : this.showUpdateTNum = i;
    // alert(this.showUpdateTNum);
  }


  changeInactive(id, inactive, i?: number) {
    const payload = {
      parentId: this.qs.chapter,
    };

    this.qdServe.changeInactive(id, inactive).subscribe(data => {
      if ((data as any).status === 200) {
        //console.log(data);
        this.qs.cs.addMsg("deleted", 'toast');
        this.success = true;
      } else {
        alert("failure");
        this.success = false;
        this.qs.cs.addMsg("failure", 'warning');
        alert("error,try later");
      }
    }
    );

  }


  postBstrQstn(type: string, source: string, qstnId: number, k: number) {
    const commentPayload = {
      //name: '',
      email: '',
     // message: source + ' -- ' + qstnId + "//" + type,
      //phone: '',
      //parentId: qstnId,
      parentHdrId: this.addDigId,
      parentDtlId: qstnId,
      //source: source,
      //topic: '',
      cmntType: type
    };
    this.tagService.addBstrQstn(commentPayload).subscribe(data => {
      if (data.status === 200) {
        this.qs.cs.addMsg(data.result + data.message)
        //alert(data.result.name + data.result.message+data.result.phone);
        this.success = true;
        this.successI = k;
        this.showAddI = k;
      } else {
        this.success = false;
        this.successI = k;
        alert("error,try later");
      }
    }
    );
  }


  public clickMethod(q:any,inactive:boolean,i:number) {
    

    this.confirmModalService.confirm("",'Do you really want to delete?' )
    .then((confirmed) => {console.log('User confirmed:', confirmed);
    if(confirmed){
       this.changeInactive(q.id,inactive,i); 
       this.changeAddI= i;
       } }
  
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  
  //qs.changeDtlStatus(q.id,'hide',i)
  public clickToHide(q:any,status:string,i:number) {
    
    this.confirmModalService.confirm('Please confirm..', 'do you really want to ' + status + ' ?' )
    .then((confirmed) => {console.log('User confirmed:', confirmed);
    if(confirmed){
      this.qs.changeDtlStatus(q.id,status);
       this.changeAddI= i;
       } }
  
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  

}
