import { Injectable, Type } from '@angular/core';
//import { ProfileComponent } from './profile/profile.component';
//import { JobAdComponent } from './job-ad/job-ad.component';


@Injectable({ providedIn: 'root' })
export class AdService {
  getAds() {
    return [
    
        {
          component: 'profile',
         inputs: { name: 'Shalini', bio: 'Brave as they come' },
        },
          {
            component: 'job-ad',
              inputs: {
                headline: 'Openings in all departments',
                body: 'Apply today',
              },
            }
        
      
    ] 
    //as {component: Type<any>, inputs: Record<string, any>}[];
  }
}
