import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-ad',
  templateUrl: './job-ad.component.html',
  styleUrls: ['./job-ad.component.scss']
})
export class JobAdComponent implements OnInit {
  @Input() headline!: string;
  @Input() body!: string;
  component: string;
  
  constructor() { 
   this.component=  this.constructor.name;
  }

  ngOnInit(): void {
  }

}
