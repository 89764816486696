import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThoughtofthedayComponent } from './thoughtoftheday/thoughtoftheday.component';
import { ThoughtComponent } from './thought/thought.component';
import { VidshowerComponent } from './vidshower/vidshower.component';
import { SharedModule } from '../shared/shared.module';
import { EdubestuComponent } from './edubestu/edubestu.component';
import { MindEngagerComponent } from './mind-engager/mind-engager.component';
import { DukComponent } from './duk/duk.component';
import { FreshQuizComponent } from './fresh-quiz/fresh-quiz.component';
import { QstndtlModule } from '../qstndtl/qstndtl.module';
import { NewsViewerComponent } from './news-viewer/news-viewer.component';
import { StoryOfTheDayComponent } from './story-of-the-day/story-of-the-day.component';
import { DailyDetailComponent } from './daily-detail/daily-detail.component';


@NgModule({
  declarations: [ThoughtofthedayComponent,ThoughtComponent,VidshowerComponent,EdubestuComponent,  MindEngagerComponent, DukComponent, FreshQuizComponent, NewsViewerComponent, StoryOfTheDayComponent, DailyDetailComponent],
  imports: [
    CommonModule,
    SharedModule,
    QstndtlModule,
  ],
  exports:[ThoughtofthedayComponent,ThoughtComponent,VidshowerComponent,EdubestuComponent,MindEngagerComponent,DukComponent,FreshQuizComponent,StoryOfTheDayComponent,DailyDetailComponent ]
})
export class CarModule { }
