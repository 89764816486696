  import { Observable, Subject } from "rxjs";
  import {QstnhdrService } from "../qstnhdr.service";
  import { Header } from "../header";
  import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core";
  import { Router, RouterEvent, NavigationEnd, ActivatedRoute } from '@angular/router';
  import { ClientInfoService } from '../../shared/client-info.service';
  import { FixedDataService } from '../../shared/fixed-data.service';
  
  import { filter } from 'rxjs/operators';
  import { get } from 'http';
  
  @Component({
    selector: 'app-qstnhdr-list',
    templateUrl: './qstnhdr-list.component.html',
    styleUrls: ['./qstnhdr-list.component.scss']
  })
  
  export class QstnhdrListComponent implements OnInit {



/* please don't provide headerById if you want entire type like show wip in list */

  @Input() headerById :any;
  @Input() type:string;
  
  heading : string  ;
  showUpload:boolean;
  public header:Header = null;
  
  // headers: Observable<Header[]>; for async..
  headers: Header[];
  headerId:string;
  showAdd:boolean;
  showUpdate:boolean;
  showHeader:boolean;
  provideResponse:boolean;
  headerForTd: any;
  id:number;

  get clsSec(){ return  this.cs.getClsSec()};
  get signedUser(){ return  this.cs.getSignedUser()};
  get chapter(){ return this.cs.getChapter()};
  get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  hideList:boolean;
  expand:boolean;  
  constructor(private route: ActivatedRoute,
    private router: Router, private QstnhdrService: QstnhdrService,public cs: ClientInfoService,private fd: FixedDataService
    ) {
    
   
    }

  ngOnInit() {
    //alert("inside comnt srvce-ngOnInit ");
    
    this.fetchData();


    
    /* this may not be needed as @Input was not working due to absense of "' cons'" input parm 
     
    this.router.events.pipe(
    filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event) => {

      this.fetchData();

    });
   */

  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.headerById || changes.offering  ) {
     this.fetchData();
    }    
  }
  
   resetVar(){
    this.showAdd= false;
    this.showUpdate=false;
    this.showHeader= false;
    this.provideResponse=false;
    this.headers= null;
    this.heading= this.fd.getTagHeading(this.offering);
    
      } 

  fetchData() {
   
      this.resetVar();
      if(!this.headerById){
      this.QstnhdrService.list(this.offering).subscribe(data => {
      this.headers  = data; 
     // console.log(data);
      if(!data ||data.length==0){
      this.showAdd = true;  
      }
    });
      } 
      
// below method expects headerid!! not id....
      if(this.headerById){
        this.headerId= String((this.headerById as any).dataList[0].headerId);
       // console.log(this.headerId + this.offering);
        if(!this.headerId){
        //console.log("Unit HeaderID is null!! using just id");
        this.headerId = String((this.headerById as any).dataList[0].Id);
      }

       

         }
        }


/* async pipe in html not working
let d  = this.QstnhdrService.getHeadersList();
this.headers = (d as any) .dataList;
*/
      

  delete(id: number) {
    this.id = id;
    this.QstnhdrService.delete(id)
      .subscribe(
        data => {
          //console.log(data);
          this.fetchData();
        },
        error => console.log(error));
  }

  
  updateStatus(id: number,status:string){
    this.id = id;
    this.showUpdate = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  
  update(id: number){
    this.id = id;
    this.showUpdate = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  respond(id: number){
    this.id = id;
    this.provideResponse = true;

    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  provideResponseHeader(id: number){
    this.provideResponse = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  addToggle(){
  if(!this.showAdd){
    this.add();
  }
  this.showAdd= !this.showAdd; 
}

  add(){
  if(this.headerById){
  this.header= (this.headerById as any).dataList[0] ;
  } 
  
   //this.showAdd = true;
    //this.router.navigate(['eba','digibest','features','cmnt-add']);
  }

  
  selectId(id:number){
    
    QstnhdrService.updateDhwHdrId.next(id);
    this.hideList= true;
    this.cs.addMsg(id + " selected,please add questions..","toast");
   }

  
}

