    import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
    import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";
    import { BoardDataService } from '../../board-data.service';
    import { ClientInfoService } from '../../shared/client-info.service';
    import { FixedDataService } from '../../shared/fixed-data.service';
    import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
    import { Observable } from 'rxjs';
import { BtnService } from '../../gen/btn.service';
    
    @Component({
      selector: 'app-prd-intro',
      templateUrl: './prd-intro.component.html',
      styleUrls: ['./prd-intro.component.scss']
    })
    export class PrdIntroComponent implements OnInit {
    @Input() ovrSub:string;
    
    slides: any;
    headerObj:any;
    get site() {return  this.cs.getSite()} ;
    get cat() {return this.cs.getCat() };
    get board() {return this.cs.getBoard()};
    get course() { return this.cs.getCourse()};
    get offering() { return this.cs.getOffering()};
    get subject() { return this.cs.getSubject()};
    get feature() { return this.cs.getFeature()};
    get rootUrl() {return  this.cs.getRoot() };
    
      headerId:number;
      key:string;
      data$: Observable<any>;
      calling:boolean;
      constructor(config: NgbCarouselConfig, private route: ActivatedRoute,
        private router: Router, public cs:ClientInfoService, private ds: BoardDataService,private fd:FixedDataService, public b:BtnService) { 
           }
    
              
    show(q:any){ return this.b.showQstn("intro",q)}
    
        
      ngOnInit() {
       let key =  this.ovrSub;
       
       if(!key){
       key = this.feature?this.feature:this.cat + "~slides";
       }
    
    
    this.calling = true;
    this.data$ =  this.ds.getCfgData(this.site,this.board,this.course,"cfg",key);
    
    }
      
    
    
    }
    