import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { ScriptLoaderService } from '../footer/script-loader.service';

@Component({
  selector: 'app-footer-label',
  templateUrl: './footer-label.component.html',
  styleUrls: ['./footer-label.component.scss']
})
export class FooterLabelComponent implements OnInit {

  constructor(public cs:ClientInfoService , private scriptLoaderService:ScriptLoaderService) { }

  ngOnInit(): void {
  
  }

  

}

