import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SecurityService } from './security.service';
//import { ClientInfoService } from './client-info.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  get signedUser() {return this.ss.getSignedUser();};
  get userGroup() {return this.ss.getUserGroup();};
  get school() {return  this.ss.getSchool()} ;
  get loc() {return this.ss.getSchlLoc()};
  

  constructor(private ss:SecurityService) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {
      //id_token
const idToken = localStorage.getItem("signedUser");
 
//alert(idToken + "being set") ;      
if (idToken) {
  const cloned = req.clone({
      headers: req.headers.set("Authorization",
          "Bearer " + idToken)
  });

  return next.handle(cloned);
}
else {
  return next.handle(req);
}
}
}




