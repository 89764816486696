<div [hidden]="submitted">

  <form (ngSubmit)="onSubmit()" #thForm="ngForm">

    <app-qh-mnt [header] = header [ei] =ei  [offering] = 'header.type' [parentFormGroup] = thForm > </app-qh-mnt>

    <!--

    <div class="form-group">
      <label for="label">title: </label>
      <input type="text" class="form-control" id="title" required
        [(ngModel)]="header.title" name="label">
    </div>


    <button type="button" class="btn btn-secondary px-3" (click)="expand=!expand">
      {{expand?'hide':'Expand Fields'}}</button>



    <div *ngIf='expand'>


      <div class="form-group">
        <label for="type">Tag Type </label>
        <input type="text" class="form-control" id="type" required
          [(ngModel)]="header.type" name="type" #type="ngModel">

          <div [hidden]="type.valid || type.pristine"
          class="alert alert-danger">
          Type is required
        </div>

      </div>



      <div class="form-group">
        <label for="qstnHdrId">hdr-Grp Id: </label>
        <input type="text" class="form-control" id="qstnHdrId"
          [(ngModel)]="header.qstnHdrId" name="qstnHdrId">
      </div>

      <div class="form-group">
        <label for="tagHdrId">Tag Header Id: </label>
        <input type="text" class="form-control" id="tagHdrId"
          [(ngModel)]="header.tagHdrId" name="tagHdrId">
      </div>

      <div class="form-group">
        <label for="subject">Subject</label>
        <input type="text" class="form-control" id="subject" required
          [(ngModel)]="header.subject" name="subject" #subject="ngModel">

        <div [hidden]="subject.valid || subject.pristine"
          class="alert alert-danger">
          Subject is required
        </div>


      </div>

      <div class="form-group">
        <label for="owner">Teacher </label>
        <input type="string" class="form-control" id="owner" required
          [(ngModel)]="header.owner" name="owner">
      </div>
         </div>
  -->

    <button type="submit" [disabled]="!thForm.form.valid"
      class="btn btn-primary mx-2">Submit</button>


  </form>
</div>

<div [hidden]="!submitted">

</div>

