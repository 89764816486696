<h3>Update Query/Comment</h3>
<div [hidden]="submitted" style="width: 400px;">
 
  <form (ngSubmit)="onSubmit()">

    <div class="form-group">
        <label for="id">id# </label>
        <input type="text" class="form-control" id="id" required [(ngModel)]="detail.id"  name="id">
      </div>
  

    <div  class="form-group">
      <label for="sequence">Sequence# </label>
      <input type="text" class="form-control" id="sequence" required [(ngModel)]="detail.sequence" name="sequence">
    </div>

    <div  class="form-group">
      <label for="subSeq">SubSeq: </label>
      <input type="text" class="form-control" id="subSeq" required [(ngModel)]="detail.subSeq" name="subSeq">
    </div>

    <div  class="form-group">
      <label for="part">Part</label>
      <input type="text" class="form-control" id="part" required [(ngModel)]="detail.part" name="part">
    </div>

    <div  class="form-group">
        <label for="tagHdrId">tagHdrId# </label>
        <input type="number" class="form-control" id="tagHdrId" required [(ngModel)]="detail.tagHdrId" name="tagHdrId">
      </div>

      
    <div  class="form-group">
      <label for="qstnDtlId">QstnDtl# </label>
      <input type="text" class="form-control" id="qstnDtlId" required [(ngModel)]="detail.qstnDtlId" name="qstnDtlId">
    </div>

    
    <!--
    -->
      <button type="submit" class="btn btn-success">Submit</button>
      <button type="button" (click)= "gotoList()"  class="btn btn-ino">Go Back</button>
   
    </form>
  </div>
