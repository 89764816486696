

<div class= "fullWidth mx-2 px-2">

<app-cmnt-flds [fldList]=primary [c] = c [parentFormGroup] = parentFormGroup  [title] =title > </app-cmnt-flds>



<div class= "text-right">

 <button *ngIf="secondary && secondary.length>=1" type="button" class="btn btn-secondary badge mx-2"
     (click)="expand=!expand"> Optional Fields</button>

     <button *ngIf="enable('additional')  " type="button" class="text-right btn btn-secondary mx-2 badge"
         (click)="additionalFlag=!additionalFlag"> Additional Fields</button>
 
 </div>
 
 
 
 <app-cmnt-flds *ngIf="expand"  [fldList]=secondary [c] = c [parentFormGroup] = parentFormGroup> </app-cmnt-flds>
 
     
 <app-cmnt-flds *ngIf="additionalFlag"  [fldList]=additional [c] = c [parentFormGroup] = parentFormGroup> </app-cmnt-flds>
 
 
</div>