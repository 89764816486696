import { Component, OnInit } from '@angular/core';
import { CntxtService } from '../../../pages/cntxt-menu/cntxt.service';
import { QstnListService } from '../../../pages/qstn-list/qstn-list.service';
import { BtnEnablerService } from '../../../shared/btn-enabler.service';
import { ClientInfoService } from '../../../shared/client-info.service';

@Component({
  selector: 'app-gen-nav',
  templateUrl: './gen-nav.component.html',
  styleUrls: ['./gen-nav.component.scss']
})
export class GenNavComponent implements OnInit {

    
get site() {return  this.cs.getSite()} ;
get cat() {return this.cs.getCat() };
get board() {return this.cs.getBoard()};
get course() { return this.cs.getCourse()};
get feature() { return this.cs.getFeature()};
get offering() { return this.cs.getOffering()};
get status() {return this.cs.getStatus() }
get subject() { return this.cs.getSubject()};
get onlySubject() {return this.cs.onlySubject(this.subject) }
get chapter(){return Number(this.cs.getChapter()?this.cs.getChapter():'')}
get justOffering() { return this.cs.getJustOffering() };
get groupId() { return this.cs.getGroupId() };
get qstnId() { return this.cs.qstnId };


showQDL:boolean;
addVid:boolean;
grpAsoId:number;

  constructor(public cm :CntxtService, public cs:ClientInfoService,public qs:QstnListService, public be:BtnEnablerService) { }


  

  ngOnInit(): void {
  }

}
