
<button *ngIf="cm.chapter && cm.enable('addQDtl')"  (click)="showQDtl= !showQDtl" class="badge btn btn-outline-primary border-0 ml-2" style="margin-left: 10px"> {{showQDtl?'Hide Add':'Add '+ cm.getQstnName()}} 
      <i class="fa fa-book" aria-hidden="true"></i>
       </button>

       <div id="collapseQDtl" *ngIf="showQDtl"   [ngbCollapse]="!showQDtl">
            <div class="card">
             <div class="card-body">
            
            <app-add-qstn-dtl *ngIf="showQDtl" ></app-add-qstn-dtl>
            </div>
            </div>
            </div>
            

<div *ngIf="calling ; else noCall">


    <div id="aa" appNoRightClick is-info is-fullheight is-bold
      class="card min-vw-75" *ngIf="(qstns$ | async) as contents; else loading">
<!--
      <json-editor [options]="editorOptions" [data]="contents" (change)="getData($event)"></json-editor>
-->

      <div *ngFor="let c of contents;let i=index"  >
       
    <li class="row form-inline">
     
      <div class= "ml-2 col-2">  {{c.id}} </div>
     <div class= "ml-2 col-2">  {{c.section}} </div>
     <div class= "ml-2 col-2">  {{c.groupId}} </div>
 
    

    <button type= "button" class= "col-3 mx-3 btn btn-md btn-secondary badge "  (click)= 'expand(c,i)'> {{getTitle(c)}} </button>
  

    <app-field-changer *ngIf='qds.cat != "list-crud"'  class= "col-8" [q] = c [fieldName] = '"digAnsr"'> </app-field-changer>  
  
    </li>
      <div *ngIf= "expandI ==i"class= "my-3">
      <app-my-json class="my-5 mx-2 px-2 "  [q] = "c" > </app-my-json>
     </div>
     </div>

     </div>



     





 <ng-template #noCall>!</ng-template>
