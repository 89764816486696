     
      
      <div   ngbDropdown class="d-inline-block">
        
        
        <button  class="btn btn-outline-primary badge" id="dropdownForm1" ngbDropdownToggle>Assign</button>
        
       

        <button *ngIf= 'store' class="btn btn-outline-primary badge" id="dropdownForm1" ngbDropdownToggle>Store & Assign</button>
        

        <div *ngIf= "!success"  ngbDropdownMenu aria-labelledby="dropdownForm1">
          <form class="px-4 py-3" (ngSubmit)="submit()" #assignForm="ngForm">
            
              <div class="form-group">
                <label for="sectAng1">Section</label>
                <input type="text" class="form-control" 
                [(ngModel)]="ei.clsSec" 
                name= "sectAng1"   placeholder="cmn">
              </div>

              <div class="form-group">
                <label for="testTime">Test Time in Minutes</label>
                <input type="text" class="form-control" 
                [(ngModel)]="ei.testTime" 
                name= "testTime"   placeholder="25">
              </div>

              
            <div class="form-group">
                <label for="dueAng">Due date</label>
                {{ei.dueDate|date:'dd:MMM'}}
                <input type="date" class="form-control" [(ngModel)]="ei.dueDate" value= "ei.dueDate"
                name= "dueAng"  placeholder="dd/mm/yyyy">
              </div>

            <div class="form-check">
              <input type="checkbox" class="form-check-input"   [(ngModel)]="ei.mustFlag" name= "mustAng1" >
              <label class="form-check-label" for="mustAng1">
                Must have
              </label>
            </div>

            <div class="form-check">
              <input type="checkbox" class="form-check-input"   [(ngModel)]="ei.createNew" name= "create New flag" >
              <label class="form-check-label" for="createNew">
               create new?
              </label>
            </div>


            <div class="form-group">
              <label for="infoAng1">info</label>
              <input type="text" class="form-control" 
              [(ngModel)]="ei.info" 
              name= "infoAng1" name= "infoAng1"  placeholder="Additional Info">
            </div>

            <div class="form-group">
              <label for="testTime">Assigned to</label>
              <input type="text" class="form-control" 
              [(ngModel)]="ei.owner" 
              name= "owner"   placeholder="all/studentId">
            </div>

            <div class="form-check">
              <input type="checkbox" class="form-check-input"   [(ngModel)]="ei.automate" name= "automate" >
              <label class="form-check-label" for="automate">
                Automate
              </label>
            </div>

            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
          <!--
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>New around here? Sign up</button>
          <button ngbDropdownItem>Forgot password?</button>
        </div>
        -->

      </div>
    </div>