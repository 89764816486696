import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FixedDataService } from '../../shared/fixed-data.service';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { HeaderService } from '../header.service';

@Injectable({
  providedIn: 'root'
})
export class TaghdrListService {
  hideList: boolean;
  selectedMsg: string;
  public selectedId: number;
  grpAsoId: any;
  get site() { return this.cs.getSite() };
  get cat() { return this.cs.getCat() };
  get board() { return this.cs.getBoard() };

  get course() { return this.cs.getCourse() };
  get feature() { return this.cs.getFeature() };
  get offering() { return this.cs.getOffering() };
  get subject() { return this.cs.getSubject() };
  get chapter() { return Number(+this.cs.getChapter()) };
  get owner() { return this.cs.getSignedUser() };

  constructor(public ths: HeaderService, private ds: BoardDataService, public cs: ClientInfoService, private router: Router, public fd:FixedDataService) { }

  selectId(t:any) {
    HeaderService.updateDhwHdrId.next(t.id);
    this.hideList = true;
    //this.selectedMsg= id + " selected,show List";
    this.selectedMsg = "show List";
    this.selectedId= t.id;
    this.cs.addMsg(t.id + " selected,please add questions..", "info");
    this.grpAsoId= t.headerid? t.headerId:t.id; 
  }


  enable(btnId:string,i?:number,hdrRec?:any){
    //return true;
    let component = 'crudBtn';
    return this.cs.enable(component,btnId,'',hdrRec);
     }
 
  

  showChptr(t: any, overCat?:string,newTab?:boolean ) {
    //needed to avoid hdr in build with click cat with -bldr 
  
    let cat = this.cat? this.cat.replace("-slctr","-bldr"):"";
    if(overCat){cat= overCat;}
    var chptrUrl = this.cs.getRouteUrl(cat, t.type + "/" + t.subject + "/" + t.id);
    if(newTab){
      window.open(this.cs.getRoot()+chptrUrl, "_blank");
    }else{
      this.router.navigateByUrl(chptrUrl);
    }
  }


  //let url= this.cs.getRouteUrl('',t.type+ "/" + t.subject + "/"+t.id );
  //window.open(url, "_blank");

  //alert('??'+ this.cs.site);
  //console.log("showing result...");
  //let type= this.cs.offering.replace('wrk','crct');
  //this.router.navigate(['/', this.cs.site, 'abc', this.cs.board, this.cs.course,t.type ,t.subject,t.id]);
  //  alert('got it');   



  showChptrDelete1(t: any) {
    this.router.navigate(['/', this.cs.site, 'abc', this.cs.board, this.cs.course, t.type, t.subject, t.id]);
    window.open('users', '_self');
  }

 

}
