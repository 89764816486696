
        
      <div   ngbDropdown class="d-inline-block">
        
        
        <button class="btn btn-outline-primary badge" id="dropdownForm1" ngbDropdownToggle>Share</button>
        
        <div *ngIf= "!success"  ngbDropdownMenu aria-labelledby="dropdownForm1">
          <form class="px-4 py-3" (ngSubmit)="submit()" #assignForm="ngForm">
            
            <div class="form-group">
              <label for="secAng2">Section</label>
              <input type="text" class="form-control" 
              [(ngModel)]="ei.clsSec" 
              name= "secAng2"  id= "secAng2" placeholder="cmn">
            </div>

            <div class="form-group">
                <label for="due">Completion date: </label>
                {{ei.dueDate|date:'dd:MMM'}}
              <input type="date" class="form-control" [(ngModel)]="ei.dueDate" value= "ei.dueDate"
                name= "dueAng2"  id= "due" placeholder="dd/mm/yyyy">
              </div>

            <div class="form-check">
              <input type="checkbox" class="form-check-input"   [(ngModel)]="ei.mustFlag" name= "mustAng2" >
              <label class="form-check-label" for="mustAng2">
                Must have
              </label>
            </div>

            <div class="form-group">
              <label for="infoAng2">info</label>
              <input type="text" class="form-control" 
              [(ngModel)]="ei.info" 
              name= "infoAng2"  id= "infoAng2" placeholder="Additional Info">
            </div>
            <div class="form-check">
              <input type="checkbox" class="form-check-input"   [(ngModel)]="ei.createNew" name= "create New flag" >
              <label class="form-check-label" for="createNew">
               create new?
              </label>
            </div>

            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
          <!--
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>New around here? Sign up</button>
          <button ngbDropdownItem>Forgot password?</button>
        </div>
        -->

      </div>
    </div>

   