import { Component, OnInit ,Input} from '@angular/core';
import { Detail } from '../detail';
import { QstnDtlService } from '../qstn-dtl.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { ConfigService } from '../../shared/config.service';
import { Edi } from '../../optional/dtl-extra/edi';

@Component({
  selector: 'app-upd-qstn-dtl',
  templateUrl: './upd-qstn-dtl.component.html',
  styleUrls: ['./upd-qstn-dtl.component.scss']
})
export class UpdQstnDtlComponent implements OnInit {
  @Input()  id: number;
  @Input() changeSpan:string;
  @Input()  h: any;
  
  submitted:boolean;
  detail:any;
  edi:Edi;
  updator: string;
  expandBtn:boolean;
  jsonUpd:boolean;
  
  expandQstn: boolean;
  expandLink: boolean;
  msg: string;
  get clsSec(){ return  this.cs.getClsSec()};
  get signedUser(){ return  this.cs.getSignedUser()};
  get chapter(){ return this.cs.getChapter()};
  get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};


  constructor(private detailService: QstnDtlService,private route: ActivatedRoute,
    private router: Router, public cs:ClientInfoService , public cfg:ConfigService) { }

  ngOnInit() {
    this.submitted = false;
    
    if(this.offering && ( this.offering.includes("pyq")
    ||this.offering.includes("hwh")
    ||this.offering.includes("rbs")
    )){
      this.expandQstn= true;
    } 
    
    if(this.offering && ( this.offering.includes("vid")
    ||this.offering.includes("doc")
    ))
    {
       this.expandLink= true;
     }


   // this.detail = new Detail();
     if(!this.id){
      this.id= this.route.snapshot.params['id'];
      this.cs.offering = this.route.snapshot.params['offering'];
      
     }

     this.detailService.get(this.id)
      .subscribe(data => {
       
        this.detail = data;
        if(this.detail.extra){
          this.edi= new Edi();
          this.edi = this.edi.parse(this.detail.extra);
          //console.log(this.edi);
         }
        if(this.detail.inactive){
          //bug fix
          this.detail.inactive= false;
          console.log(this.detail + " was with inactive status in db, customized will be active" )
        }
        
      }, error => console.log(error));

/*
    FileService.updateUploadUrl.subscribe(res=>{
      //alert("inside comnt srvce-updte lst: "+ res);
      this.detail.extUrl = res})
*/

  }

  

  onSubmit() {
    this.submitted = true;
    this.update();
  }


  gotoList() {
  // alert("detail added");
   QstnDtlService.updateList.next(true);

   // this.router.navigate(['//eba//digibest//features//details']);
  }

  update() {
    //trying upsert -- 
    console.log(this.detail.headerId + "--" + this.chapter);
    
    // create new copy and change for local else change main qstn.
    // fix logic ....
    
    if(this.changeSpan == "orgnl"){
     
    } 
    //local is customized record creation...
    if(this.changeSpan == "local"){
    if(this.detail.headerId != +this.chapter){
      this.detail.prntHdrId= this.detail.headerId;
      this.detail.headerId = +this.chapter;
      this.detail.prntDtlId= this.detail.id;
      this.detail.id = null;
      
      this.detailService.create(this.detail)
      .subscribe(data => 
        {
         // console.log(data);
          if((data as any).status === 200) {
  
          this.detail = new Detail();
         this.cs.addMsg((data as any).message,"info"); 
          }
         this.gotoList();
                 
        }, error => console.log(error));
        return;
     }
    } // decide local/global  
     // if above untrue then update..

     if(this.edi){
      this.detail.extra= JSON.stringify(this.edi);
     }
   this.detailService.update(this.id, this.detail)
      .subscribe(error => console.log(error));
    this.detail = new Detail();
    this.msg= "detail updated";
    this.cs.addMsg("detail updated","toast");
    this.gotoList();
  }

  delete(id:number) {
    this.detailService.delete(this.id)
       .subscribe(error => console.log(error));
     alert("detail id: "+ id + " deleted");
     this.gotoList();
   }
 

}

