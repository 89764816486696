

import { Component, OnInit } from '@angular/core';

import { BoardDataService } from '../../board-data.service';
import { QstnDtl } from './qstndtl';

@Component({
  selector: 'app-duk',
  templateUrl: './duk.component.html',
  styleUrls: ['./duk.component.scss']
})
export class DukComponent implements OnInit {

  q:QstnDtl;
  constructor(private dataService: BoardDataService) { }

  ngOnInit() {

let dukStr = localStorage.getItem('dukStr');
this.q= JSON.parse(dukStr);

if(!this.q){
  this.dataService.getDuk().subscribe(response=> {
          if(response) {
          this.q = response as any;
         
        localStorage.setItem('dukStr', JSON.stringify(this.q));
          }   });
};
  
// return this.duk;
}


formatSeq(sequence: string, subSeq: string, part: string) {
  return [sequence, subSeq, part].filter(Boolean).join('-');
}

}

