
<div *ngIf="calling; else noCall"> 

    <div *ngIf="(data$ | async) as combo; else loading">
        
      <!--
        <ngb-carousel  id="myCarousel">
        -->
    
        <ng-container *ngFor="let d of combo; let di= index;">
  
      <ng-container *ngIf="d.dtls && d.dtls.length; else noItems">
        
        <div>
                
          <div *ngFor="let s of d.dtls; let i= index;" class="container row">
            
            <div *ngIf= "show(s)">
           
                <!--
              <ng-template ngbSlide>
                -->

                <div class="bg-primary  text-light text-quote text-center" [innerHTML] = d.hdr.title>  
                </div>
        
                <app-problem-space  *ngIf = 'cs.enable("ebhome","problem")' [h] = d.hdr  [q] = s> </app-problem-space>
               
                <!--  
                Combo set - {{di}} of {{combo.length}}  slide: --{{i}} of {{d.dtls.length}}
                -->
              <div>
               <span text-center> {{getCurrent(combo,di,i)}} </span>
              </div> 
              
              <br>

              <!--
              <div class=" text-light text-quote font-italic text-center bg-primary"> 
                {{getCurrent(combo,di,i)}} 
              </div>
              -->

              <!--
                  </ng-template>
                  -->
            </div>
              </div> 
    
      </div>
    
    </ng-container>
  </ng-container>

   
        <a class="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
          <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#myCarousel" role="button" data-slide="next">
          <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
      </a>         
    
      <!--
     </ngb-carousel>
     -->
        <ng-template #noItems>*</ng-template>
       </div>
       <ng-template #loading>loading slides...</ng-template>
    </div>
    <ng-template #noCall>!</ng-template> 
  
  
  
 