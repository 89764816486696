import { Component, OnInit,Input, SimpleChanges } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { ExamDataService } from '../../exam-data.service';
import { QuestionE} from '../../questione';
import { Title, Meta,DomSanitizer,SafeUrl } from '@angular/platform-browser';
import { ClientInfoService } from '../../shared/client-info.service';
import {SafePipe} from '../../shared/safe.pipe';

@Component({
  selector: 'app-vidshower',
  templateUrl: './vidshower.component.html',
  styleUrls: ['./vidshower.component.scss']
})
export class VidshowerComponent implements OnInit {
  @Input()  questionsI : any;
  @Input()  headerId : number;
  @Input() jumboHdr :boolean 
  @Input() headerObj:any;
  
  //headerId:number;
  questions:QuestionE[];
  unsafeUrl:string;
  safeUrl:SafeUrl;
  
  utube= "https://www.youtube.com/embed/";
  vimeoUrl= "https://player.vimeo.com/video/137857207";

  constructor(private cs:ClientInfoService, private dataService: BoardDataService,private data: ExamDataService,private sanitizer: DomSanitizer) {

   }

   ngOnChanges(changes: SimpleChanges) {
    //alert("fetching data");
    if (changes.headerId) {

     this.fetchData();
    }    
  }
  

  ngOnInit() {
    this.fetchData();
  }


    fetchData() {

    //this.cs.getOffering() - avoid booster tag bug
    if(this.headerId){
      // console.log("headerid is" ,this.headerId);

this.dataService.getSiteHeaderById(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),'pyq',this.cs.getSubject(),this.headerId).subscribe(dataList => {
        this.headerObj = (dataList as any).dataList[0];
              //  console.log(this.headerObj);
});
      

    this.dataService.getSiteQuestions(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),'pyq',this.cs.getSubject(),this.headerId).subscribe(dataList => {
            this.questions = (dataList as any).dataList;
            //alert("hello" + this.questions);
            //console.log(this.questions);
        });

 
          }
          else{
  this.questions= this.questionsI;
          }    

  }


  santize(source:string) {
  //alert("working on santization of Question");
  return this.sanitizer.bypassSecurityTrustResourceUrl(source);
  }

  updateVideoUrl(source:string , id: string) {
    // Appending an ID to a YouTube URL is safe.
    // Always make sure to construct SafeValue objects as
    // close as possible to the input data so
    // that it's easier to check if the value is safe.


    if(source.trim()=="tubeE"){
    this.unsafeUrl = 'https://www.youtube.com/embed/' + id +"?modestbranding=1&autohide=1&showinfo=0&controls=0" + 'frameborder="0" allowfullscreen';
    } else {
    if(source.trim()=="amzn"){
    //alert("Prabhash");
    this.unsafeUrl = "//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&amp;OneJS=1&amp;Operation=GetAdHtml&amp;MarketPlace=IN&amp;source=ac&amp;ref=tf_til&amp;ad_type=product_link&amp;tracking_id=examsteppers-21&amp;marketplace=amazon&amp;region=IN&amp;placement=8177092324&amp;asins=8177092324&amp;linkId=13d942768f3ab0bb86b36c6ecc038fed&amp;show_border=false&amp;link_opens_in_new_window=false&amp;price_color=333333&amp;title_color=0066c0&amp;bg_color=ffffff";
    }
    else{
    this.unsafeUrl = 'https://www.youtube.com/embed/' + id;
    }
   }

    //this.unsafeUrl = id;
   // this.unsafeUrl= "https://youtu.be/5TI2jIL7pSQ?t=117"; not working because watch doesn't work from outside
   //this.unsafeUrl= 'https://www.youtube.com/embed/5TI2jIL7pSQ'; working

    this.safeUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(this.unsafeUrl);
        //console.log( this.safeUrl);
        //alert(this.safeUrl);
        return this.safeUrl;
  }


  
  formatSeq(sequence: string, subSeq: string, part: string) {
    return [sequence, subSeq, part].filter(Boolean).join('-');
  }
  
}
