import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../user';
import { ExamDataService } from '../../exam-data.service';

import { AppComponent } from '../../app.component';
import { ClientInfoService } from '../../shared/client-info.service';
import { Observable } from 'rxjs';


interface learn {
  code: string;
  desc: string;
}


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  avl$: Observable<any>;
  signupForm: FormGroup;
  submitted = false;
  success = false;
  user: User;
  name1: string;
  validSiteCode: any;
  userGroup: any;

  get justSite() { return this.cs.getJustSite() };
  get site() { return this.cs.getSite() };
  get board() { return this.cs.getBoard() };
  get course() { return this.cs.getCourse() };
  get justSubject() { return this.cs.getJustSubject() };

  get school() { return this.cs.getSchool() };
  get location() { return this.cs.getSchlLoc() };

  get cat() { return this.cs.getCat() };
  get groupId() { return this.cs.getGroupId() }
  get root() { return this.cs.getRoot() };
  get unsigned() { return this.cs.isUnsigned() };

  idAvailable = null;

  learnList: learn[];
  selectedMode: any;
  selectedLearnType: learn;


  constructor(private app: AppComponent, private formBuilder: FormBuilder, private dataService: ExamDataService, private cs: ClientInfoService) {
  }

  formControlValueChanged() {
    this.signupForm.get('userId').valueChanges.subscribe(
      (mode: string) => {
        console.log(mode);
      });
  }

  ngOnInit() {


    this.learnList = this.loadLearnList();
    this.userGroup = this.loadUsergroup();

    this.signupForm = this.formBuilder.group({
      overSite: ['',],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      userId: ['', Validators.required],
      password: ['', Validators.required],
      board: ['', ],
      course: ['', ],
      email: ['',Validators.required],
      phone: ['', Validators.required],
      groupId: ['',],
      clsSec: ['',],
      shrGrp: ['',],
      site: ['',],
      roles: ['',],
      mentorId: ['',],
      mainCenter: ['',],
      userLoc: ['',],
      extra: ['',],
      school: ['',],
    });

    this.formControlValueChanged();

  }


  showResponse(event) {

  }

  isNewsActive() {

  }

  isAvl(domain: string, userId: string) {
    const formValue = this.signupForm.value;
    this.idAvailable = null;
    this.dataService.isIdAvailable(domain, formValue.userId).
      subscribe((val) => { this.idAvailable = val; });

  }

  validateSiteCode(ovrSite: string) {
    const formValue = this.signupForm.value;
    this.validSiteCode = null;
    this.dataService.validateSiteCode(formValue.ovrSite).
      subscribe((val) => { this.validSiteCode = val; });

  }


  /*
  isAvl(domain:string,userId:string){
   return this.avl$= this.dataService.isIdAvailable(domain,userId); 
   */

  onSubmit() {

    this.submitted = true;

    if (this.signupForm.invalid) {
      return;
    }

    var site = this.site;

    if (site.length == 3) {
      if (this.cat == "lwb" || this.cat == "lwp" || this.cat == "lwt") {
        site = site + this.cat + "-cmn";
      }
      else {
        if(this.justSite=='csa'){
          site = site + "step" + "-cmn";
        } else{
        site = site + "lwb" + "-cmn";
        }
      }

    }

    if (this.signupForm.controls.overSite.value) {
      site = this.signupForm.controls.overSite.value;
    }

    const userPayload = {
      firstName: this.signupForm.controls.firstName.value,
      lastName: this.signupForm.controls.lastName.value,
      userId: this.signupForm.controls.userId.value,
      password: this.signupForm.controls.password.value,
      // board: this.signupForm.controls.board.value,
      //grade: this.signupForm.controls.course.value,
      board: this.board,
      grade: this.course,
      subject:this.justSubject,
      emailId: this.signupForm.controls.email.value,
      phone: this.signupForm.controls.phone.value,
      domain: site,
      groupId: this.signupForm.controls.groupId.value,
      roles: this.signupForm.controls.roles.value,
      clsSec: this.signupForm.controls.clsSec.value,
      shrGrp: this.signupForm.controls.shrGrp.value,
      mentorId: this.signupForm.controls.mentorId.value,
      mainCenter: this.signupForm.controls.mainCenter.value + "-" + this.signupForm.controls.userLoc.value,
      extra: this.signupForm.controls.extra.value,
    }

    //alert('user  json is : '+ userPayload.firstName);

    /*
     this.dataService.addComment(commentPayload).subscribe(data => {
           //alert(data.status + data.message+data.result.name+ data.result.message+data.result.phone);
         )};
   
   */

    this.populateDefault(userPayload);

    this.dataService.addUser(userPayload).subscribe(data => {
      //alert("calling Welcome to ExamSteppers, You are all set" );
      let msg: string = (data as any).message;
      console.log(data);
      if (data.status === 200) {
        //alert(data.result.name + data.result.message+data.result.phone);
        //alert("Welcome to ExamSteppers, You are all set");
        this.cs.addMsg((data as any).message);
        if (msg.startsWith("ERROR:")) {
          this.success = false;
        } else {
          this.success = true;
        }
        /*
      localStorage.clear();
      localStorage.setItem('participant',JSON.stringify(data));
      //console.log(JSON.stringify(data));
      this.route.navigate(['/quiz']);
 */

        //window.localStorage.setItem('token', data.result.token);
        //this.router.navigate(['list-user']);
      } else {
        this.success = false;
        //alert("Sorry we got duplicate UserID,please try again with unique userId or email us at examsteppers@gmail.com")
        alert(data.message);
      }

    }



    );


  }
  populateDefault(userPayload: { firstName: any; lastName: any; userId: any; password: any; board: any; grade: any; emailId: any; phone: any; domain: any; groupId: any; roles: any; clsSec: any; shrGrp: any; mentorId: any; mainCenter: string; extra: any; }) {

    if (this.unsigned && !userPayload.groupId) {
      switch (this.cat) {
        case 'lws':
          userPayload.groupId = 'learner';
          break;
        case 'lwp':
          userPayload.groupId = 'parent';
          break;

        case 'lwt':
          userPayload.groupId = 'tutor';
          break;

      }
    }

  }



  loadLearnList() {

    let lm = new Array<learn>();

    switch (this.cat) {
      case 'lws':
        break;

      case 'lwp':
        //lm.push({"code":"slf", "desc": "self-guided"});
        //lm.push({"code":"prnt", "desc": "Parent"});
        //lm.push({"code":"tut", "desc": "Tutor"});
        break;
      case 'lwt':
        //lm.push({"code":"slf", "desc": "self-guided"});
        //lm.push({"code":"prnt", "desc": "Parent"});
        //lm.push({"code":"tut", "desc": "Tutor"});
        break;

      default:
        //if (this.site != "esa"){
        if (this.school != "lwp" && this.school != "lwt") {
          lm.push({ "code": "slf", "desc": "self/BestuBot" });
          lm.push({ "code": "prnt", "desc": "Parent" });
          lm.push({ "code": "tut", "desc": "Tutor" });
        }
    }
    //}
    return lm;

  }

  loadUsergroup(): any {
    let ug = new Array<learn>();

    //let userGroup = null;
    switch (this.justSite) {
      case 'esa':
        ug.push({ "code": "learner", "desc": "learner" });
        break;
      case 'eba':
        ug.push({ "code": "teacher", "desc": "teacher" });
        break;
      case 'csa':
        ug.push({ "code": "learner", "desc": "learner" });
        break;
      default:
        ug.push({ "code": "learner", "desc": "learner" });
        break;
    }
    return ug;
  }


  loadUsergroupOld(): any {
    let ug = new Array<learn>();


    //let userGroup = null;

    if (this.justSite == "esa") {
      ug.push({ "code": "learner", "desc": "learner" });


      switch (this.cat) {

        case 'lws':
          if (!this.unsigned) {
            ug.push({ "code": "parent", "desc": "Parent" });
            ug.push({ "code": "tutor", "desc": "Tutor" });
          }
          ug.push({ "code": "learner", "desc": "learner" });
          break;
        case 'lwp':
          if (!this.unsigned) {
            ug.push({ "code": "learner", "desc": "learner" });
          }
          ug.push({ "code": "parent", "desc": "Parent" });
          //ug.push({"code":"tutor", "desc": "Tutor"});
          break;
        case 'lwt':
          if (!this.unsigned) {
            ug.push({ "code": "learner", "desc": "learner" });
            ug.push({ "code": "parent", "desc": "Parent" });
          }
          ug.push({ "code": "tutor", "desc": "Tutor" });

          break;

        default:
          if (this.school != "lwp") {
            ug.push({ "code": "tutor", "desc": "Tutor" });
          }

          ug.push({ "code": "learner", "desc": "learner" });
          ug.push({ "code": "parent", "desc": "Parent" });

          if (this.groupId && this.groupId.includes('admin')) {
            ug.push({ "code": "crclm_mngr", "desc": "COE Digitizer" });
            ug.push({ "code": "digi_expert", "desc": "digi-Expert" });
          }

      }


    }

    else {
      if (this.justSite.startsWith("eb")) {
        ug.push({ "code": "director", "desc": "Director" });
        ug.push({ "code": "crclm_mngr", "desc": "Curriculum Manager" });
      }

      ug.push({ "code": "teacher", "desc": "teacher" });
      ug.push({ "code": "learner", "desc": "learner" });
      ug.push({ "code": "digi_expert", "desc": "teacher with Digitizer" });

    }

    return ug;


  }

}
