



<app-status-based-cmn-view [hdr] = hdr [i]=i> </app-status-based-cmn-view>
   
   
<ng-container [ngSwitch]="viewGroup" >

<app-status-based-student-view *ngSwitchCase='"learner"' [hdr] = hdr [i]=i> </app-status-based-student-view>
  
<app-status-based-teacher-view *ngSwitchCase='"teacher"' [hdr] = hdr [i]=i> </app-status-based-teacher-view>

</ng-container>

<!--
<ng-container *ngIf='vs.groupId  === "learner" then studentTemplate else teacherTemplate'>
    
 <ng-template #studentTemplate>
  </ng-template>
    
    <ng-template #teacherTemplate>
    
    </ng-template>
</ng-container>
-->


    
    