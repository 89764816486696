





<!-- unsigned user unconditional-->       

<span *ngIf= 'vs.show("practice",i,hdr) && (!(hdr.hdrFormat && (["worksheet","test","both"].includes(hdr.hdrFormat) )))'>  
  <button  
       class= "btn-md btn-secondary badge mr-1"  
  (click)="vs.showChptr(hdr)"> practice</button>
 
  </span>
  
  
  

<!--test module-->



<ng-container  [ngSwitch]="hdr.hdrFormat">

 
  <span *ngSwitchCase='hdr.hdrFormat == "both" || hdr.hdrFormat =="test"' >

       
    <button  *ngIf= "vs.show('mockTest',i,hdr)"  class= "btn-md btn-primary badge mr-1"  (click)="vs.createNBrowse(hdr,i,'wrk','te',true)"> Mock Test </button>
         
    <button *ngIf= "dueNow() && (vs.show('takeTest',i,hdr,true)) "   class= "btn-md btn-primary badge mr-1"  (click)="vs.createNBrowse(hdr,i,'wrk','te',true)"> Take Test </button>
    
    <button *ngIf= "!dueNow() && vs.show('takeTest',i,hdr,true) "   class= "btn-md btn-outline-info badge mr-1"  (click)="msg()"> Take Test </button>

    <button  *ngIf= "vs.show('wrkTest',i,hdr) "  class= "btn-md btn-primary badge mr-1"  (click)="vs.showChptr(hdr,'te') ">Continue with Test</button>
   
  </span>

  <span *ngSwitchCase='"worksheet"'>  
   
    <button *ngIf= "vs.show('mockTest',i,hdr)"  class= "btn-md btn-primary badge mr-1"  (click)="vs.createNBrowse(hdr,i,'wrk','hwrk',true)"> worksheet </button>
   
   <button *ngIf= "vs.show('takeTest',i,hdr) "  class= "btn-md btn-primary badge mr-1"  (click)="vs.createNBrowse(hdr,i,'wrk','hwrk',true)"> worksheet</button>
  <!--{{hdr.id}}-{{hdr.status}}  -->
   <button  *ngIf= "vs.show('wrkTest',i,hdr) "  class= "btn-md btn-primary badge mr-1"  (click)="vs.showChptr(hdr,'hwrk') ">continue with work</button>
   
   </span>

   <span *ngSwitchDefault class="bg-color ">

    <button *ngIf= "vs.show('takeTest',i,hdr) "  class= "btn-md btn-primary badge mr-1"  (click)="vs.createNBrowse(hdr,i,'wrk','hwrk',true)"> worksheet</button>
  
    <button *ngIf= "dueNow() && (vs.show('takeTest',i,hdr,true)) "   class= "btn-md btn-primary badge mr-1"  (click)="vs.createNBrowse(hdr,i,'wrk','te',true)"> Test </button>
   

<a *ngIf= 'false && vs.show("takeTest",i,hdr) ' [routerLinkActive]="['active']" 
routerLink = '{{cs.getRouteUrl("basic/features/pbooster","","withChptr")}}/{{hdr.id}}'  class="mx-2 btn btn-secondary badge border-0"> Practice-alternate View</a>

</span>

</ng-container>

    