import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {User} from '../../user';
import { ExamDataService } from '../../exam-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { Router, ActivatedRoute }     from '@angular/router';
import { SecurityService } from '../../shared/security.service';
import { data } from 'jquery';
import { BtnService } from '../../gen/btn.service';
import { ApiResponse } from '../../api-response';
import { RoleChangerService } from '../../user/role-changer.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
signinForm: FormGroup;
  submitted = false;
  success = false;
 fromUrl: string;
  user :User;
  name1:string;
  failure =false;
  expand:boolean;
  get site(){ return  this.cs.getSite()};
  get justSite(){ return  this.cs.getJustSite()};
  get cat(){ return  this.cs.getCat()};
  remember: boolean;
  showForm: boolean = false;

  
//private ms: MessageService,
  constructor(public ss:SecurityService,public bs:BtnService, public cs:ClientInfoService,private route:ActivatedRoute,private router: Router, private formBuilder: FormBuilder,private dataService: ExamDataService, public rcs:RoleChangerService) {

    this.showForm= false;
   }

   ngOnInit() {

    this.route.queryParams.subscribe(params=>{
          let fromUrl=params['fromUrl'];
        });

  if(this.ss.principal){   
  this.rcs.goHome(this.ss.principal);
  return;
} else{
  this.showForm = true;
}

  this.signinForm = this.formBuilder.group({
      userId: ['', Validators.required],
      password: ['', Validators.required],
      remember: ['', ],
      });
      this.signinForm.controls.userId.setValue(localStorage.getItem("user") );
}


// signin work flow
  // onSubmit calls backend and if success then store detail in ss.signin method and finally call rolechanger- gohome  routine
onSubmit() {
    this.submitted = true;
    if (this.signinForm.invalid) {
        return;
    }
    this.remember = this.signinForm.controls.remember.value;
    const userPayload = {
      userId: this.signinForm.controls.userId.value,
      domain: this.cs.getSite(),
      password: this.signinForm.controls.password.value,
      }
 this.dataService.loginUser(userPayload).subscribe(data => {
      if(data.status === 200 ) {
        if(data.result && data.result.authenticated){
      //this.messageService.clear('all');
      // console.log('Authenticated user:' + data.result.principal.firstName + " " + data.result.principal.lastName );
       //console.log(this.cs.getRoot().includes("localhost"));
      let p= data.result.principal;
      
      //inter site login constrained
      
      if(!(this.cs.getRoot().includes("localhost") || this.cs.getRoot().includes("edubestu") || this.cs.getRoot().includes("examsteppers") || this.cs.getSite() == "gen") && p.learnMode.substring(0,2) !=this.cs.getJustSite().substring(0,2)){
        this.cs.addMsg("Mismatach in domain,signin not allowed");
        return;
      }
      
/*
      if(this.cs.getSite() != "gen"){
        this.cs.addMsg("Mismatach in domain,signin not allowed");
        return;
      }
*/
      this.ss.signin(data.result.principal,this.remember);

        this.success = true;
        this.failure = false;
       
     //alert("Success: You are signed In " + data.result.principal.firstName); 
      //this.cs.alert({severity:'Success', summary:'Success: You are signed In ' + data.result.principal.firstName ,detail:'',sticky: true});
      this.cs.addMsg('Success: You are signed In ' + data.result.principal.firstName,'toast');
      /*
      this.router.navigate([this.justSite+data.result.principal.school +"-" +data.result.principal.location, 'home',data.result.principal.board,data.result.principal.grade]);
      */
      let pr =data.result.principal;

      this.rcs.goHome(pr,pr.dfltMnu );
     


    //this.router.navigateByUrl('/eba/digibestu');
//pr.learnMode+pr.school +"-" +pr.location
//     this.router.navigateByUrl(this.ss.getMyNav());

    } 
            else{
              this.cs.addMsg('Log in Failure','warning'); 
         this.router.navigate([this.cs.getSite(), this.cs.getCat(),]);
        }

        return;
      }


  this.success = false;
  this.failure = true;
  //success, info warn, error 
  //this.cs.alert({severity:'error', summary:'Failure:Log in Failure',detail:'',sticky: false});
  this.cs.addMsg('Log in Failure','warning');
   
}
 
  );
}

 
  private goHomeDelete(data?: ApiResponse) {
   //deleted subroutine -archive
    let pr =data? data.result.principal:this.ss.principal;


    //let myMenu= [pr.domain,'learner','features', pr.groupId,pr.board,pr.grade];
    //console.log(pr);
    let dfltMnu = pr.dfltMnu ? pr.dfltMnu : pr.groupId;
    
    if (!dfltMnu) {
      dfltMnu = "learner";
    }

    let usrDomain = pr.learnMode;
    if (pr.school) {
      usrDomain = usrDomain + pr.school;
    }
    if (pr.location) {
      usrDomain = usrDomain + "-" + pr.location;
    }

    //pr.domain
    let myMenu = [usrDomain, dfltMnu, pr.board, pr.grade];

    localStorage.setItem('myPage',myMenu.filter(Boolean).join('/'));
    this.router.navigate(myMenu);
  }

showResponse(event) {
  //this.ms.add({severity:'info', summary:'Success', detail: 'User Responsed',sticky: true});
  //this.cs.alert({severity:'info', summary:'Success:User Responsed',detail:'',sticky: true});
  this.cs.addMsg('Success:User Responsed','toast');
}


togetherUrl(){
return this.cs.getRouteUrl("ftr/digibest","");

}


}

