

<ng-container [ngSwitch]="hdr.status" >
    
    <!-- moving to test view hence commenting for now but teacher view has edit 

        <button  *ngSwitchCase='"asgn"'   class= "btn-md btn-primary badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr) " >Practice</button>
    
    -->

    
    <button  *ngSwitchCase='"verify"'   class= "btn-md btn-primary badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr) " >show</button>

 </ng-container>