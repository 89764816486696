import { Component, Input, OnInit } from '@angular/core';
import { TagService } from '../../shared/tag.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { HeaderService } from '../../tag/header.service';
import { BoardDataService } from '../../board-data.service';
import { ConfirmModalService } from '../../shared/confirm-modal.service';
import { BtnService } from '../../gen/btn.service';

@Component({
  selector: 'app-crud-btn',
  templateUrl: './crud-btn.component.html',
  styleUrls: ['./crud-btn.component.scss']
})
export class CrudBtnComponent implements OnInit {
  @Input() header:any;
  @Input() i :number;
  showAddI: number;
  ownUpdate: boolean;
  grpAsoId: any;
  selfOwned: boolean;
;
 @Input() chapter:any;
 component = 'crudBtn';
 expandI:number = -1;
showHdrGrp:boolean;

get site() {return this.cs.getSite()}

showUpdate:boolean;
  showUpdateNum: number;
  success: boolean;
  chptrSrcSlctr:boolean;


  constructor( private ths: HeaderService,private ds: BoardDataService,private cs:ClientInfoService,public ts:TagService, private confirmModalService: ConfirmModalService ,public b:BtnService ) { }

  ngOnInit(): void {
   
  
  if(this.header && 
   (
    this.header.owner ==this.cs.ss.getSignedUser() ||
  (!this.header.owner && this.header.addUser ==this.cs.ss.getSignedUser())) 
  ){
     this.selfOwned= true;
  }

  this.grpAsoId = localStorage.getItem("grpAsoId") ;

  }

  expand(i:number){
    this.expandI== i? this.expandI=-1 : this.expandI =i;
  }

 tagBased(offering:string){
  return this.cs.tagBased(offering);
}

// should not be used
enable(btnId:string,i?:number,hdrRec?:any){
  let component = 'crudBtn';
  return this.cs.enable(component,btnId,null,hdrRec);
   }
  


   show(btnId,h:any,i?:number){
    let component = 'crudBtn';
    

    if(this.selfOwned){  
    return this.cs.enable(component,"self-"+btnId,null,h); 
    }
    
    else{
    return this.b.show(component,btnId,h); 
    }

    return false;
    }
   
  processUpdate(hdr:any,i:number){
    this.showUpdate= !this.showUpdate;
    this.showUpdateNum= i;
   }
   
   changeInactive(id,offering,inactive,i?:number){
    this.showAddI= i;
    const payload = {
      parentId: this.chapter,
      };
      this.ths.changeInactive(id,offering,inactive).subscribe(data => {
        if((data as any).status === 200) {
       this.cs.addMsg((data as any).message + (data as any).result,'toast');
         this.success = true;
          }else {
        this.success = false;
        alert("error,try later");
        }
      }
      );
 
 }


 //good and simple method.. restore it if needed.
 clickMethod1(header:any,i:number) {
  if(confirm("Are you sure to delete "+header.title)) {
    this.changeInactive(header.id,header.type,true,i)
    
     }
}
 

public clickMethod(header:any,i:number) {
  this.confirmModalService.confirm('', 'Do you really want to delete ? '+ header.type + "-"+ header.title)
  .then((confirmed) => {console.log('User confirmed:', confirmed);
  if(confirmed){this.changeInactive(header.id,header.type,true,i) } }

  )
  .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
}


buildPercentageBstr(hdr:any){
  localStorage.setItem("templateSrc",JSON.stringify(hdr));  
  localStorage.setItem("refBstrId", hdr.id);  
 // let bldrCat= this.ths.cat.replace("mkr-","") + "-multiSlctr";
    
 let bldrCat= this.ths.cat.replace("mkr-","") + "-src-slctr";
 let extLink =  "type/"+hdr.subject; 
 this.cs.navigate(bldrCat,extLink);
 }

 buildTermBstr(hdr:any){
  localStorage.setItem("templateSrc",JSON.stringify(hdr));  
  localStorage.setItem("refBstrId", hdr.id);  
 // let bldrCat= this.ths.cat.replace("mkr-","") + "-multiSlctr";
    
 let bldrCat= this.ths.cat.replace("mkr-","") + "-src-slctr";
 let extLink =  "tcmplt-type/"+hdr.subject; 
 this.cs.navigate(bldrCat,extLink);
 }


buildChptrBstr(hdr:any){
  localStorage.setItem("templateSrc",JSON.stringify(hdr));  
  localStorage.setItem("refBstrId", hdr.id);  
 // this.chptrSrcSlctr= !this.chptrSrcSlctr;
 let extUrl = [this.cs.getOffering(),this.cs.getSubject(),"grpAso",hdr.id].join("/"); 
 this.cs.navigate('pbstr-slctr',extUrl);
  }

//full method call for backend
makeChptrBstr(refBstrId:string,sourceIds:string){
 
  this.ths.makeBstr(+refBstrId,sourceIds)
      .subscribe(
        data => {
        this.ths.cs.addMsg( data + " : new record added ") ; 

        },
        error => {console.log(error);
        this.ths.cs.addMsg( " request failed,check log ") ; 
  } );
}   


 
changeField(h:any,field:any,value:any,i?:number){
  this.ths.changeField(h.id,h.type,field,value).subscribe(data =>{
     if ((data as any).status === 200) {
      localStorage.removeItem("grpAsoId");
  
      this.cs.addMsg((data as any).message, 'info');         
} else {
this.cs.addMsg('error,try later', 'error');
}  
});


 }
// alert(this.header.id + this.grpAsoId);


}
