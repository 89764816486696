


  import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { Comment } from '../../comments/comment';
import { ListerService } from '../../chat/lister.service';

@Component({
  selector: 'app-cmnt-mnt',
  templateUrl: './cmnt-mnt.component.html',
  styleUrls: ['./cmnt-mnt.component.scss']
})
export class CmntMntComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  
  @Input() c :Comment;
  @Input() offering:string;
  @Input() i:number;
  @Input() responseMode

  primary : string[];
  secondary : string[];

  expand:boolean;
  additional:string[];
  additionalFlag: boolean;
  title: string;



  
  constructor(private fd:FixedDataService,private cs:ClientInfoService,public ls:ListerService) { }

  ngOnInit(): void {
 
 if(this.c && this.c.cmntType){
  this.title = this.fd.getCmntHeading(this.c.cmntType,this.cs.getGroupId());
 }
    //let justType = this.cs.getJustOffering(this.offering);
 let status = this.cs.getStatus();
  
 let key = this.c.cmntType;
 //console.log(this.c);

 if(this.responseMode){ key = "rspnd"}
 if(status=='crctn'){ key = "crctn"}

 //console.log('cmnt-flds key is: ',key);
 if(true){
  this.ls.getKeyFlds('cmnt-flds',key).subscribe((data:any) => {
    //console.log(data);
    let flds  = data.result;
    let StrArray = flds.primary?.split(",");
    this.primary = StrArray;
    //console.log(this.primary);
    this.secondary= flds.secondary?.split(",");
    this.additional = ['-'].concat(this.primary).concat(this.secondary);
    
  });
} else{
  let d  = this.fd.selectCmntFlds(key);
  this.primary= d.p;
  this.secondary= d.s;
  this.additional = ['-'].concat(d.p).concat(d.s);
   
  }
  }
  
  enable(btnId:string){
    return this.cs.enable('cmnt-mnt',btnId);
  }


}
