<button *ngIf= 'hideList' (click)= 'hideList= !hideList'>{{selectedMsg}}</button>

  
  <div *ngIf= '!hideList'  class="card card-primary">
  
    <div class="card-heading">
       <br> 
      <p>{{heading}} 
                 
          <button *ngIf= "!showAdd" (click)="addHeader()" class="btn btn-info" style="margin-left: 10px"  ngbTooltip='select below/create New'>Add <i class="fa fa-book" aria-hidden="true"></i> </button>
          
           <button *ngIf= "showAdd" (click)="showAdd = false" class="btn btn-info" style="margin-left: 10px">Hide Add</button>
          
        </p>
      
     <app-add-header [iHeader]= header *ngIf="showAdd"> </app-add-header>
     <!--   
      <app-show-header *ngIf="showHeader" [id]=id> </app-show-header>
      <app-update-header  *ngIf="showUpdate" [id]=id> </app-update-header>
    -->
    
      <!--
      <app-update-header  *ngIf="provideResponse" [responseMode]= true [id]=id> </app-update-header>
    
      <app-show-header  *ngIf="showHeader" [id]=id> </app-show-header>
  -->  
        
      </div>
  
      
  
      <div *ngIf= "headers && headers.length>0" class="card-body">
        <table class="table table-striped">
          <thead class= "float-left">
            <tr>
              
              <th># </th>
              <th>title</th>
              <th>Added By</th>
              <th>Add Date</th>
              <th>class</th>
              <th>section</th>
              <th>subject</th>
              
              <th>chapter</th>
              <div *ngIf="false" class= ".d-none">
              <th>section</th>
              
              <th>Booster Set</th>
              <th>status</th>
              <th>Active Flag</th>
              <th>Header Id</th>
            </div>
            </tr>
          </thead>
          <tbody>
            <div *ngFor="let header of headers;let i = index">
            <tr class= "row col-12">
             <!-- | async -->
             
             <td>
             <button  *ngIf='enable("select")' class= "mx-2 btn btn-primary  badge text-center"  (click)="selectId(header.id)"> select </button>
             
             <button  *ngIf='enable("select")' class= "mx-2 btn btn-primary  badge text-center"  (click)="loadFilterQstnSet(header.id)"> load filter-qstns </button>
             
             <td>{{header.id}}    </td>
              <td>{{header.title}} </td>
              <td>{{header.addUser}} </td>
              <td>{{header.addDate|date}} </td>
              <td>{{header.course}}</td>
              <td>{{header.clsSec}}</td>
              <td>{{header.subject}}</td>
              <td>{{header.chapter}}</td>
              
              <div *ngIf="false" class= ".d-none">
              <td>{{header.section}}</td>
              <td>{{header.bstrSet}}</td>
              <td>{{header.status}}</td>
              <td>{{header.current}}</td>
              <td>{{header.headerId}}</td>
            </div>
              <!--
              <td> <app-url-shower *ngIf="header.extUrl" [extLink] = header.extUrl [title] = header.title> </app-url-shower></td>
              
              -->  
           
          <td>
        
      <a   class = "btn-md badge btn-outline-primary mr-1"   [routerLink]= "getChapterUrl('',header) " >Show Selected</a>
            
      
          
      
       <button class= "badge btn-danger px-3 " *ngIf= "mode ==='select'" (click) = 'expand= !expand'> {{ expand? ' - ':' + '}} </button> 
         
        <div *ngIf="expand">
        <ng-container *ngIf= "mode !=='!select' || (expand ==true) ">
        
          <button (click)="deleteHeader(header.id)" class="btn btn-danger px-2 ">Delete</button>  
        
          <button (click)="showUpload=!showUpload; headerforTD= header.id;setUpload= i;" class="btn btn-info">Upload Doc</button>
        <app-file-upload *ngIf="showUpload && i==setUpload" [iHeader]= header></app-file-upload>    
              
          
            <!--
            <button (click)="updateStatus(header.id,'status')" class="btn btn-info" style="margin-left: 10px"  >Update Status</button>
            -->
            <!--
            <app-status-changer *ngIf="enable('changeStatus')"></app-status-changer>
            -->  
            <!--*ngIf="enable('showDetail',i,header) "-->
            
            <!--
            <a   class = "btn-md badge btn-primary mr-1"   [routerLink]= "getChapterUrl('',header) " >Show Details</a>
            -->
             <button  class= "mx-2  btn-md btn-info" (click)="listTagDtl = !listTagDtl; headerforTD= header.id;setDtl= i;">Tag Detail</button>
           
           
           <!--  Implement & show -->    
           
                 
          <button (click)="updateHeader(header.id)" class="btn btn-info" style="margin-left: 10px"  >Update</button>
                 
           <button (click)="headerHeader(header.id)" class="btn btn-info" style="margin-left: 10px">Show Headers</button>
          
           
          
           <!--       
          <button *ngIf="!header.response && header.message" (click)="respond(header.id)" class="btn btn-info" style="margin-left: 10px" >Respond</button>
                
                 
         <a *ngIf="cs.enable('updateQstn') && header.qstnDtlId "  href = '{{cs.getRoot()}}/faces/content-dtl-update.xhtml?qstnId={{header.qstnDtlId}}'
         class="nav-link text-warning px-3 " > <span class= "">update Question  </span>
       </a>
       -->
          </ng-container>

        </div>
          
        
              </td>
                      
            </tr>
            
           <tr *ngIf= "i==setDtl" class=" row col-12 float-right">
           <app-list-tagdtl *ngIf= 'listTagDtl'[tagHdrId]= "headerforTD + ''" ></app-list-tagdtl>
          </tr>
        
          </div>
         
          </tbody>
        </table>
      </div>
  
    
    </div>
