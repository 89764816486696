<h4>{{cmntHeading}} Details</h4> 

<hr/>
<div *ngIf="comment">
  <div>
    <label><b>#: </b></label> {{comment.id}}-{{id}}
  </div>
  <div>
    <label><b>query/comment: </b></label> {{comment.comment}}
  </div>
  <div>
    <label><b>raised by </b></label> {{comment.name}}
  </div>  
  <div>
    <label><b>raised Date</b></label> {{comment.addDate}}
  </div>  

</div>

<br>
<br>
<button (click)="list()" class="btn btn-primary">Back to Query List</button><br>