import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { BtnService } from '../../gen/btn.service';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { ListerService } from '../../chat/lister.service';

@Component({
  selector: 'app-prd-combo-slide-pdf',
  templateUrl: './prd-combo-slide-pdf.component.html',
  styleUrls: ['./prd-combo-slide-pdf.component.scss']
})
export class PrdComboSlidePdfComponent implements OnInit {
  ovrSub: any;
  totalSize: number;
  currentSize: number;
  dataComboPdf: any;
  get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get feature() { return this.cs.getFeature()};
  get rootUrl() {return  this.cs.getRoot() };

  data$: Observable<any>;
  calling:boolean;
  
  constructor( private ds: BoardDataService,public cs:ClientInfoService,private route: ActivatedRoute,private b:BtnService,public ls:ListerService) { }

  ngOnInit(): void {
  
    this.cs.updateParms(this.route);

   let key =  this.ovrSub;
   if(!key){
   key = this.feature?this.feature:this.cat;
   }
     
    this.calling = true;
    // this.data$ =  this.ds.getCfgComboData(this.site,this.board,this.course,"cfg",key);
    this.ls.getKeyFlds('slidesDataKey',key).subscribe((data:any) => {
  
      this.dataComboPdf  = data.result?.value;
      this.data$ =  this.ds.getCfgComboDataNew(this.site,this.board,this.course,"cfg",this.dataComboPdf);
    });

    //let keyCombo = "platform~slides:platform~usp:digistore:digiEngage:assignment";
     //this.data$ =  this.ds.getCfgComboDataNew(this.site,this.board,this.course,"cfg",keyCombo);
    

  }

  show(q:any){ return this.b.showQstn("slide",q)}

  getCurrent(d:any,di:number,i:number){
  
    let totalSize= 0;
     
    for (let c = 0; c < d.length; c++) {
       totalSize= totalSize + d[c].dtls?.length;
     }
   this.totalSize= totalSize;
   
   let currentSize= 0;
   for (let c = 0; c <di; c++) {
     currentSize = currentSize + d[c].dtls?.length;
   }
   this.currentSize= currentSize+ i +1;
   
   return this.currentSize + " of " +this.totalSize;
   
   }
     
  }


