import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { QstnDtlService } from '../qstn-dtl.service';

@Component({
  selector: 'app-qstn-analyzer',
  templateUrl: './qstn-analyzer.component.html',
  styleUrls: ['./qstn-analyzer.component.scss']
})
export class QstnAnalyzerComponent implements OnInit {
  @Input() q: any;
  @Input() fieldName :string;
  @Input() h: any;
  @Input() i:number;
  
  extra:Map<string,number>;
  voted: boolean;

   constructor(public cs:ClientInfoService, private ds: QstnDtlService) { }
  
  ngOnInit(): void {
    
    
  }

  
  increaseCount(key:string){
    this.voted= true;
    
    if(this.q.extra){
     let jsonObject = JSON.parse(this.q.extra);
      let map = new Map<string, number>();
for (var value in jsonObject) {
  map.set(value, jsonObject[value]);
}

      this.extra= map;
    } else{
      this.extra = new Map<string,number>();
    } 
     if(this.extra && this.extra.has(key)){
     this.extra.set(key,this.extra.get(key)+1);
     console.log("updated",this.extra);
    }
     else{
      this.extra.set(key,1) 
      console.log("added",this.extra);
    }
    
    //const result = Object.fromEntries(map); not working

    let jsonObject = {};
    this.extra.forEach((value, key) => {
      jsonObject[key] = value;
    });

    this.changeQDField("extra",JSON.stringify(jsonObject))
  }

changeQDField(fieldName:string,value:any){

  //ideally type = this.h.type if passed
let type = "type";
this.ds.changeField(this.q.id,type,fieldName,value).subscribe(data =>{
if ((data as any).status === 200) {
this.cs.addMsg((data as any).message, 'info');         
} else {
this.cs.addMsg('error,try later', 'error');
}  
});
}


}
