import { Component, Input, OnInit } from '@angular/core';
import { Type } from '../../qstn/type';
import { ConfigService } from '../../shared/config.service';
import { CmntListService } from '../cmnt-list/cmnt-list.service';
import { Comment } from '../comment';
import { CommentService } from '../comment.service';

@Component({
  selector: 'app-cmnt-crctn',
  templateUrl: './cmnt-crctn.component.html',
  styleUrls: ['./cmnt-crctn.component.scss']
})
export class CmntCrctnComponent implements OnInit {
  @Input() c:any;
  @Input() i: number;

 cmntHeading:string = "Crctn";
 submitted:boolean;
 responseMode:boolean;
 respondI:number;
 showCrctn: boolean;
 
 mistakeTypes :Type[]= [new Type('NA','Not-Applied'),new Type('ovrsght','Oversight'),
    new Type('clcln','Calculation'), new Type('cncpt','shallow Concept'),new Type('othr','Other')];

 toggleUpload:boolean;
 
  heading: string = "Answer";
  
  showFeedback:boolean;
   id: number;

  constructor(public ls: CmntListService,private cms: CommentService, public cfg:ConfigService) { }
  ngOnInit(): void {
  }

  ngOnChanges(){

    if(this.c.correct){
      this.c.marks= '5.0';
     this.c.errorType= "NA"; 
    } else{
      this.c.marks= '0.0';
      this.c.errorType= "ovrsght"; 
    }
  }

onSubmit(){
  
    this.c.rspnseUser= this.ls.signedUser;
    
  this.cms.updateComment(this.c.id, this.c)
      .subscribe(
        data =>  error => console.log(error));
    //this.c = new Comment();
    //alert("comment updated");
    this.ls.cs.addMsg("info updated");
    this.gotoList();
}
gotoList(){
  //this.submitted= true;
  this.showFeedback= false;
  
  //check
}

respond(id: number,i:number){
  this.submitted= false;

  this.id = id;
  this.respondI= i;
  
  //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
}

respondNew(tick:boolean){
  
//console.log(this.c);
  if(tick){
    this.c.correct= true;
    this.c.marks= '4.0';
   this.c.errorType= "NA"; 
  } else{
    this.c.correct= false;
    this.c.marks= '0.0';
    this.c.errorType= "ovrsght"; 
 // auto booster addition.. 
this.cms.addSimilar(this.c)
.subscribe(
  data => { error => console.log(error);
//this.ls.cs.addMsg(this.heading + " done","toast");
});


  }

}


respondTick(id: number,i:number,c:Comment){
  
    c.rspnseUser= this.ls.signedUser;
  
this.cms.updateComment(id, c)
    .subscribe(
      data =>  error => console.log(error));
  //this.c = new Comment();
  this.ls.cs.addMsg(this.heading + " updated","toast");
  //this.fetchData(); //refresh List
}

provideResponseComment(id: number){
 
  //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
}

}
