<nav class="blue-orange">
    <div class="nav-wrapper">


<button class="btn btn-primary border-0 btn-lg ">
<span class="badge border-0 px-2" (click) ="refresh()" >
 <span >Back to Root </span> </span></button>

<!--
<a class="nav-link  px-3 " *ngIf="board" class="navbar-brand" href = "esa/te/{{board}}/{{course}}/pbstr">ExamSteppers </a>
<a class="nav-link  px-3 " *ngIf="!board" class="navbar-brand" href = "esa/home">Go Back </a>
-->

<!--
  <img src="assets/img/es-c.png">
  -->

      <a href="/quiz" class="brand-logo center">Digital Booster</a>

        <ul *ngIf= "quizService.getParticipantName()" id="nav-mobile" class="right hide-on-med-and-down">
        <li>
          <div>Welcome {{quizService.getParticipantName()}}</div>
          </li>
        <li>
          <a  *ngIf="quizService.getParticipantName() !='Guest'" (click)="SignOut()">Signout</a>
        </li>
      </ul>

    </div>
  </nav>
