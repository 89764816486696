

<br>
<div  *ngIf= "(headers$ | async)?.dataList[0] as data" class="my-4 text-center">

	
<app-vidshower [headerId]= "data.id" >
		</app-vidshower>
</div>



