import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TemplateComponent } from './template/template.component';
import { AlertComponent } from './alert/alert.component';
import { UserModule } from '../user/user.module';

import { TopicListComponent } from '../ui/topic-list/topic-list.component';
import { FooterLinkComponent } from './footer-link/footer-link.component';
import { FooterLabelComponent } from './footer-label/footer-label.component';
import { PageEndFooterComponent } from './page-end-footer/page-end-footer.component';
import { ScreenEndFooterComponent } from './screen-end-footer/screen-end-footer.component';


@NgModule({
  declarations: [LayoutComponent, HeaderComponent, FooterComponent, TemplateComponent, AlertComponent, TopicListComponent, FooterLinkComponent, FooterLabelComponent, PageEndFooterComponent, ScreenEndFooterComponent],

  exports: [LayoutComponent, HeaderComponent, FooterComponent, TemplateComponent,
    AlertComponent, FooterLinkComponent, FooterLabelComponent,PageEndFooterComponent , ScreenEndFooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    UserModule,

  ],
  

})
export class UiModule { 
  
}
