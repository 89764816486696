import { Component, Input, OnInit } from '@angular/core';
import { ExtraHdrService } from '../../gen/extra-hdr.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { ViewService } from '../view-lstr/view.service';

@Component({
  selector: 'app-test-view-btn',
  templateUrl: './test-view-btn.component.html',
  styleUrls: ['./test-view-btn.component.scss']
})
export class TestViewBtnComponent implements OnInit {
  @Input() hdr: any;
  @Input() i: number;
  extraHdrObj: any;
  
  constructor(public vs: ViewService,public eh:ExtraHdrService,private cs:ClientInfoService ) { }
  
  ngOnInit(): void {
  this.extraHdrObj = this.eh.extraHdr(this.hdr.extra);
  
  }
  
dueNow(){
  let today= new Date();
  //.toLocaleDateString()
let actiondate= new Date(this.hdr.actionDate);
if(!actiondate || actiondate <= today){
  return true;
} else{
  return false;
}
}

  
msg(){
this.cs.addMsg("Asgnment will be available on 'Set Date'" ,'toast')
}

}
