


import { Observable, Subject } from "rxjs";
import { Header } from "../header";
import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core";
import { Router, RouterEvent, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';

import { filter } from 'rxjs/operators';
import { get } from 'http';
import { UsrhdrService } from "../usrhdr.service";

@Component({
  selector: 'app-usrhdr-lst',
  templateUrl: './usrhdr-lst.component.html',
  styleUrls: ['./usrhdr-lst.component.scss']
})
export class UsrhdrLstComponent implements OnInit {

  @Input() domain:string;
  //domain1:string= "esadigi-best";
  @Input() cmntType:string;
  @Input() featureType: string;
  @Input() parentHdrId: number;
  @Input() parentDtlId: number;
  heading : string  ;

  public header:Header = null;
  cmntType1:string;
  // headers: Observable<Header[]>; for async..
  headers: Header[];
  
  showAdd:boolean;
  showUpdate:boolean;
  showHeader:boolean;
  provideResponse:boolean;
  id:number;
  site:string;
  usrNum: number;
  get signedUser(){ return  this.cs.getSignedUser()};
  get chapter(){ return this.cs.getChapter()};
  get cat(){ return this.cs.getCat()};
  
  constructor(private route: ActivatedRoute,
    private router: Router, private usrhdrService: UsrhdrService,public cs: ClientInfoService,private fd: FixedDataService
    ) {
      UsrhdrService.updateList.subscribe(res=>{
        //alert("inside comnt srvce-updte lst ");
        this.fetchData();})
   
    }
changePwd(ch){
  //this.headerService.currentCh= ch;
 // console.log(ch);
  this.router.navigate([this.site,'usr','chgpwd',ch.id]);
}

changePwdEmail(ch){
  this.usrhdrService.changePwdEmail(ch).subscribe(res=>{
   console.log(ch);
  this.cs.addMsg("Email sent");
  }
  );

}

changeUD(h:any){
  //this.headerService.currentCh= ch;
 // console.log(ch);
  this.router.navigate([this.site,'usrdtl','upd',h.id]);
}


  ngOnInit() {
    //alert("inside comnt srvce-ngOnInit ");
    
    this.fetchData();


    
    /* this may not be needed as @Input was not working due to absense of "' cons'" input parm 
     
    this.router.events.pipe(
    filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event) => {

      this.fetchData();

    });
   */

  }



  ngOnChanges(changes: SimpleChanges) {
    if (changes.cmntType) {

     this.fetchData();

    }    
  }
  
   resetVar(){
    this.showAdd= false;
    this.showUpdate=false;
    this.showHeader= false;
    this.provideResponse=false;
    this.headers= null;
    this.heading= "User Management"
      } 

  fetchData() {
    this.route.params.subscribe( params => {
      this.site= params['site'];
      this.usrNum= params['usrNum'];
    });
    //alert("inside comnt srvce-fetchdata- ");
    this.resetVar();
 //alert(this.site);
    (this.usrhdrService.getHeadersByDomain(this.site)).subscribe(data => {
      this.headers  = data;
});
        }




/* async pipe in html not working
let d  = this.headerService.getHeadersList();
this.headers = (d as any) .dataList;
*/
      

  deleteHeader(id: number) {
    this.id = id;
    this.usrhdrService.deleteHeader(id)
      .subscribe(
        data => {
         // console.log(data);
          this.fetchData();
        },
        error => console.log(error));
  }

  disable(hdr:any){
   hdr.enabled= 0;
   //hdr.userId= "Dlt"+  hdr.userId;
   this.usrhdrService.updateHeader(hdr.id,hdr).subscribe(data => {
     console.log(data);
    this.cs.addMsg("Disabled");
});

  }

  reEnable(hdr:any){
    hdr.enabled= 1;
    //hdr.userId= "Dlt"+  hdr.userId;
    this.usrhdrService.updateHeader(hdr.id,hdr).subscribe(data => {
      console.log(data);
     this.cs.addMsg("Disabled");
 });
 
   }
 
   updateHeader(id: number){
     this.id = id;
     this.showUpdate = true;
     //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
   }

   
  respond(id: number){
    this.id = id;
    this.provideResponse = true;

    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  provideResponseHeader(id: number){
    this.provideResponse = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  addHeader(){
    this.header = new Header();
    //this.header.tagHdrId= this.chapter as unknown as number;
    /*
    this.header.cmntType= this.cmntType;
    this.header.featureType= this.featureType;
    this.header.parentHdrId= this.parentHdrId;
    this.header.parentDtlId= this.parentDtlId;
    this.header.domain= this.cs.getSite();
    */
   
     this.showAdd = true;
    //this.router.navigate(['eba','digibest','features','cmnt-add']);
  }

  headerHeader(id: number){
    this.id = id;
    this.showHeader = true;
    //this.router.navigate(['eba','digibest','features','cmnt-dtl','byId', id]);
  }

}

