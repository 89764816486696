
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,Subject} from 'rxjs';
import { HttpHeaders,HttpResponse } from '@angular/common/http';
import { ClientInfoService } from '../shared/client-info.service';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    //'Authorization': 'my-auth-token',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'HEAD,GET,POST,PUT,PATCH,DELETE',
    responseType: 'text',
  })
  };

@Injectable({
  providedIn: 'root'
})
export class UsrdtlService {
  baseUrl: string;

  constructor(private http: HttpClient,private cs:ClientInfoService) {
    this.baseUrl= this.cs.getRoot()+ "/rs/ud-v2";
   }



 get(id: number): Observable<any> {
  return this.http.get(`${this.baseUrl}/usrNum/${id}`);
}


getStudentList(domain:string,board:string,course:string,clsSec:string): Observable<any> {
  return this.http.get(`${this.baseUrl}/list/student/${domain}/${board}/${course}/${clsSec}`);
}



update(usrNum: number, value: any): Observable<Object> {
 return this.http.put(`${this.baseUrl}/usrNum/${usrNum}`, value,httpOptions);
}



}



