import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { ChptrService } from '../chptr-lstr/chptr.service';

@Component({
  selector: 'app-book-name',
  templateUrl: './book-name.component.html',
  styleUrls: ['./book-name.component.scss']
})
export class BookNameComponent implements OnInit {
@Input() h:any;
bookName: any;
  
  constructor(private router: Router, public cs: ClientInfoService,public chs: ChptrService) { }

  ngOnInit(): void {}

  getBookName(){
    let title = "";
    if(this.cs.getCourse() && this.cs.getCourse()=="course"){
      title = [this.h.board,this.h.course].filter(Boolean).join("-");
      if(title) {
       title=  title + ":";
      }

    } 
    title = title + [this.h.subject,this.h.book,this.h.branch].filter(Boolean).join("~");
    return title;
  }

  navigateBook() {
   let url = this.router.url.replace(this.cs.getSubject(),this.getBookName())
   this.router.navigate([url]);
 }

  

}
