import { Component, OnInit, Input } from '@angular/core';
import { ClientInfoService } from '../client-info.service';

@Component({
  selector: 'app-headings',
  templateUrl: './headings.component.html',
  styleUrls: ['./headings.component.scss']
})
export class HeadingsComponent implements OnInit {
@Input() hdr:any;
styles: object;

get site() { return this.cs.getSite()}
get chapter() { return this.cs.getChapter()}

constructor(public cs:ClientInfoService) {  }

ngOnChanges(){
if(this.chapter){  
this.fetchChapterStyle();
} else{
  this.fetchOtherStyle();  
}
}

ngOnInit(): void {
  //this.fetchStyle()
  }

fetchChapterStyle(){
  if(this.hdr && this.hdr.book ){
    let bgImageUrl = 'assets/img/'+ this.hdr.book;
    
    this.styles = {
     'background-image': `url(${bgImageUrl})`,
     'color': 'midnightblue',
     //'background-color': 'midnightblue',
     'background-size': 'contain',
     'background-repeat': 'repeat' ,
     'background-position': 'center'
     };
   }
   
}



fetchOtherStyle(){
  if(this.hdr){
  let bgImageUrl = 'assets/img/es1.png'; 
   if(this.hdr && this.hdr.book ){
   //bgImageUrl = '/assets/img/'+ this.hdr.book;
  console.log(bgImageUrl); 
  }

   this.styles = {
     'background-image': `url(${bgImageUrl})`,
     'color': 'midnightblue',
     //'background-color': 'midnightblue',
     'background-size': 'strtch',
     'background-repeat': 'repeat' ,
     'background-position': 'center',
        };
   }
  }

formatContentHeader(){
  if(this.chapter){
  let hdrTitle = [this.hdr.year,this.hdr.chapterId,this.hdr.section,this.hdr.title].filter(Boolean).join("-");
 return hdrTitle;
  } else{
    return this.hdr.title;
  }
    
 }


}
