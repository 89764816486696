
<app-navbar> </app-navbar>
{{ansrCount}}
<div class="row">
  <div class="col s8 offset-s2">

    <!-- Qn with correct answer -->
    <h3>Questions with correct answer</h3>
    <ng-container *ngFor="let qn of quizService.qns;let i=index">
        <div class="card blue-grey darken-1">
            <div class="card-content white-text">
              <span class="card-title">
              {{qn.qnId}}-
              {{qn.qnSubId}}
              </span>
              <!--
                <span class="card-title">{{i+1}}</span>
                -->


                <p innerHTML= {{qn.instruction}}></p>
                <p innerHTML= {{qn.qn}}></p>
                <div *ngIf="qn.ImageName!=null">
                    <img class="center" [src]="quizService.rootUrl+'/Images/'+qn.ImageName" style="width:350px;height:200px">
                </div>
            </div>

            <div *ngIf= "qn.qn" class="card-action">
                <ul class="collection">
                    <ng-container *ngFor="let option of qn.options;let j = index">
                        <li *ngIf="option != null" class="collection-item"
                        innerHTML= {{option}}
                          >
                      
                            <span class="badge" *ngIf="qn.correct==j">
                                <i class="material-icons green-text">check_circle</i>
                            </span>
                            <span class="badge red-text" *ngIf="qn.answer==j && qn.correct!=j">Incorrect</span>

                        </li>
                    </ng-container>
                </ul>

                <div class="card-content yellow-text" innerHTML = "Answer: {{qn.answerD}}" > </div>
            </div>
        </div>


    </ng-container>

    <h2 class="header">Completed!</h2>
    <div class="card horizontal">
      <div class="card-image">
        <img src="../../assets/img/trophy.png" style="width: 250px;height : 250px;">
      </div>
      <div class="card-stacked">
        <div class="card-content">

          <h4>{{quizService.getParticipantName()}}</h4>


          <h3 *ngIf = "ansrCount>0" >{{quizService.correctAnswerCount}}/{{qstnCount}}</h3>
          <span>Time Elapsed : {{quizService.displayTimeElapsed()}}</span>
        </div>
        <div class="card-action">
          <button class="btn" (click)="OnSubmit()">Submit</button>
        </div>
      </div>
      <a class="btn-floating halfway-fab waves-effect waves-light red" (click)="restart()">
        <i class="material-icons">replay</i>
      </a>
    </div>

  </div>

</div>
