

<ul class="p-1 mb-0" *ngFor="let q of list;let i=index">

    <div> 
        <app-simple-qstn [q]=q [i]=i> </app-simple-qstn>
        
        <app-crctn *ngIf="true|| qs.enable('crctedAnsr')" [q]=q [i]=i> </app-crctn>
        
        <app-ansr-view *ngIf='true ||qs.enable("viewAnsrEligible")'
            [showA]= false  [q]=q [i]=i>
        </app-ansr-view>
        </div>


</ul>