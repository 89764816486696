

import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';
import { ClientInfoService } from '../../shared/client-info.service';
import { ConfigService } from '../../shared/config.service';
import { ConfirmModalService } from '../../shared/confirm-modal.service';
import { Comment } from '../../comments/comment';
import { BrowserWindowService } from '../../gen/browser-window.service';

@Component({
  selector: 'app-cmnt-flds',
  templateUrl: './cmnt-flds.component.html',
  styleUrls: ['./cmnt-flds.component.scss']
})
export class CmntFldsComponent implements OnInit {

@Input() parentFormGroup: FormGroup;
@Input()  fldList:string[];
@Input()  c:Comment;
@Input()  title:string;
htmlcomment: boolean;
quillComment:boolean;

get unsigned() {return this.cs.isUnsigned()};

get quill() {return this.cs.enable("cmnt-fld","quill")};
get justOffering() {return this.cs.getJustOffering()}
toggleUpload:boolean;
askFlag: boolean;

constructor(private confirmModalService: ConfirmModalService, public cfg:ConfigService ,private cs:ClientInfoService, public bw:BrowserWindowService) { }

ngOnInit(): void {
this.htmlcomment =this.cs.enable('cmntFlds','edit+')

if(this.c && this.c.cmntType == "Dnote"){
  this.htmlcomment = true;
}  

}

fillValue(field:string, $event:any){
 this.c.comment= $event; 
}

enable(fld: string){
  /*
  if(fld=='addUser'){
   return this.unsigned; 
  }
*/

  if(this.fldList && this.fldList.includes('-')){
    if(this.fldList && this.fldList.includes('all')){return false}  
    return this.fldList && !this.fldList.includes(fld);
    }  

if(this.fldList && this.fldList.includes('all')){
  if(fld.startsWith(this.justOffering)){return false}
  return true}  

return this.fldList && this.fldList.includes(fld);
}


public help(fld:string) {
  this.confirmModalService.help('Please enter char after "v="', ' https://www.youtube.com/watch?v=<span class="bg-info">bKKJkxqIg94</span>');
}

getCmntTitle(cmntType:string){

}

}
