import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpHlprService } from '../shared/http-hlpr.service';

interface ChartData {
  set: string[];
  values:string[];
  
}


@Injectable({
  providedIn: 'root'
})
export class PerformanceService {


 constructor(private h:HttpHlprService) { }



//getMistakeTypes(domain: string, user: string, board: string,course:string) {

getMistakeTypes(user: string) {
user= user?user:this.h.us;
let url = `${this.h.ba}/perform/mistakeTypes/${this.h.si}/${this.h.bo}/${this.h.co}/${user}`;

return this.h.c.get(url).
    pipe(
       map((data: any[]) => {
         return this.mapData((data as any).result);
       }), catchError( error => {
         return throwError( 'Something went wrong!' );
       })
    )
}
         
      
      
  
  mapData(list:any){
    let cd:ChartData = {set:[], values :[]}; 
    list.forEach((item :any[]) =>
    {
       cd.set.push(item[1]);
       cd.values.push(item[2]);
       });
  return cd;
      }

   
 

/* Raw data */  

    getMistakeList(user: string) {
      user= user?user:this.h.us;
      let url = `${this.h.ba}/perform/mistakeList/${this.h.si}/${this.h.bo}/${this.h.co}/${user}`;
      return this.h.c.get(url).
          pipe(
             map((data: any[]) => {
               return data; 
               //this.mapData((data as any).result);
             }), catchError( error => {
               return throwError( 'Something went wrong!' );
             })
          )
      }
       



      getFeedbackList(user: string) {
        user= user?user:this.h.us;
        let url = `${this.h.ba}/perform/feedbackList/${this.h.si}/${this.h.bo}/${this.h.co}/${user}`;
        return this.h.c.get(url).
            pipe(
               map((data: any[]) => {
                 return data; 
                //  return this.feedbackMapData((data as any).result);
               }), catchError( error => {
                 return throwError( 'Something went wrong!' );
               })
            )
        }
    

        getFeedbackChartData(user: string) {
          user= user?user:this.h.us;
          let url = `${this.h.ba}/perform/feedbackList/${this.h.si}/${this.h.bo}/${this.h.co}/${user}`;
          return this.h.c.get(url).
              pipe(
                 map((data: any[]) => {
                  // return data; 
                   return this.feedbackMapData((data as any).result);
                 }), catchError( error => {
                   return throwError( 'Something went wrong!' );
                 })
              )
          }
      
  

    feedbackMapData(list:any){
      let cd:ChartData = {set:[], values :[]}; 
      list.forEach((item :any[]) =>
      {
         cd.set.push(item[2]);
         cd.values.push(item[3]);
         });
    return cd;
        }
          
      }