
<ng-container *ngIf= 'hdr'>
  <!--  
<app-status-changer class= 'float-right' *ngIf= 'cs.enable("hdrViewBtn","editHdr",null,hdr)'
 [hdr]=hdr></app-status-changer>
-->

<button *ngIf= 'hdr?.prvs' class="btn btn-outline-primary badge border-0  mb-2 mr-2 float-left"
(click)= 'navigateNewUnit(hdr.prvs)' >Previous Unit</button>


<button *ngIf= 'cs.enable("hdrViewBtn","digiSetup",null,hdr)' class="btn btn-outline-info badge border-0  mb-2 mr-2 float-left"
(click)= 'navigateDigiSetup()' >Digitizer Setup - {{extraObj?.cfgFldr}}</button>
 


<span *ngIf= 'cs.enable("qstnList","changeStatus",null,hdr)' class= 'float-center'> 
  <app-status-changer [hdr]= hdr> </app-status-changer>
  </span>



<button *ngIf= '(cs.enable("hdrViewBtn","editHdr",null,hdr))' class="btn btn-outline-info badge border-0  mb-2 mr-2 float-right"
(click)= 'cs.navigate("crud",hdr.type + "/hdr/"+ hdr.id)' >h{{hdr.id}}  </button>


<button *ngIf= 'hdr?.next' class="btn btn-outline-primary badge border-0  mb-2 mr-2 float-right"
(click)= 'navigateNewUnit(hdr.next)' >Next Unit </button>


</ng-container>