import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ExamDataService } from '../../exam-data.service';
import { CommentService } from '../../comments/comment.service';
import { QstnDtlService } from '../../qstndtl/qstn-dtl.service';
import { UsrroleService } from '../../user/usrrole.service';
import { HeaderService } from '../../tag/header.service';
import { ClientInfoService } from '../client-info.service';
import { QstnhdrService } from '../../qstn/qstnhdr.service';
declare var JSONEditor: any;

@Component({
  selector: 'app-my-json',
  templateUrl: './my-json.component.html',
  styleUrls: ['./my-json.component.scss']
})
export class MyJsonComponent implements OnInit, AfterViewInit {
  @Input() q: any;
  @Input() justOneField: any;

  @Input() table: string;
  @Input() create: boolean;
  editor: any;
  changedData: any;
  changed: boolean;

  constructor(private qds: QstnDtlService, private cms: CommentService, private us: ExamDataService, private ur: UsrroleService, private ths: HeaderService, private qhs: QstnhdrService) {

    MyJsonComponent
  }

  ngOnInit(): void {

  }

  ngOnChange() {
    let container = document.getElementById("jsoneditor")
    let options = {}
    this.editor = new JSONEditor(container, options);

    if (this.q && this.justOneField) {
      let inp = JSON.parse(this.q[this.justOneField]);
      this.editor.set(inp)
    }
    else {
      this.editor.set(this.q)
    }
  }

  
  ngAfterViewInit() {
    // create the editor
    let container = document.getElementById("jsoneditor")
    let options = {}
    this.editor = new JSONEditor(container, options);

    /*
       const initialJson = {
        "Array": [1, 2, 3],
        "Boolean": true,
        "Null": null,
        "Number": 123,
        "Object": {"a": "b", "c": "d"},
        "String": "Hello World"
    }
    */
    if (this.q &&  this.justOneField) {
      let inp = JSON.parse(this.q[this.justOneField]);

      this.editor.set(inp)
    }
    else {
      this.editor.set(this.q)
    }
  }



  submit() {
    this.changedData = this.editor.get();
    if (this.changedData) { this.update(this.changedData) }
  }

  copy() {
    this.changedData = this.editor.get();
    if (this.changedData) { this.createDup(this.changedData) }
  }


  createRec() {
    let c = this.editor.get();

    if (!c.role) {
      c.role = "new";
    }

    this.ur.create(c)
      .subscribe(error => console.log(error));
    this.cms.cs.addMsg("new role added", "toast");
    this.changed = true;
  }


  createDup(c: any) {
    switch (this.table) {
      case 'userRole':
        c.role = "new";
        this.ur.create(c)
          .subscribe(error => console.log(error));
        this.cms.cs.addMsg("User role duplicated", "toast");
        this.changed = true;
        break;
      default:
        this.cms.cs.addMsg("Sorry, feature not enabled", "info");

    }

  }

  update(inp: any) {
    let c = inp; //c is current


    if (this.justOneField) {
      this.q[this.justOneField] = JSON.stringify(inp);
      c = this.q;
    }


    switch (this.table) {


      case 'comment':
        this.cms.updateComment(c.id, c)
          .subscribe(error => console.log(error));
        this.cms.cs.addMsg("Comment updated", "toast");
        this.changed = true;
        break;

      case 'site':
        this.us.updateSite(c.id, c)
          .subscribe(error => console.log(error));
        this.cms.cs.addMsg("Comment updated", "toast");
        this.changed = true;
        break;


      case 'userRole':
        this.ur.update(c.id, c)
          .subscribe(error => console.log(error));
        this.cms.cs.addMsg("Comment updated", "toast");
        this.changed = true;
        break;

      case 'qstnhdr':
        this.qhs.update(c.id, c)
          .subscribe(error => console.log(error));
        this.cms.cs.addMsg("Header updated", "toast");
        this.changed = true;
        break;


      case 'taghdr':
        this.ths.update(c.id, c)
          .subscribe(error => console.log(error));
        this.cms.cs.addMsg("Header updated", "toast");
        this.changed = true;
        break;
      
      case 'qstnDtl':
      case 'qstndtl':
        //console.log(c);
        this.qds.update(c.id, c)
        .subscribe(error => console.log(error));
        this.qds.cs.addMsg("detail updated", "toast");
        break;

      default:
     
      this.qds.update(c.id, c)
          .subscribe(error => console.log(error));
    
          this.qds.cs.addMsg(`updated using dflt as ${this.table} not setup for update`, "warn");
      //working enable it if want to shrink
      // this.changed= true;

    }


  }

}





