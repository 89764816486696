
<app-signuplogin></app-signuplogin>


<button *ngIf= "cs.enable('manageUsr','modifyUsr')" (click) = 'changeUsr()'>modify User </button>


<div *ngIf = '!unsigned' class= "py-3 px-3 text-center">

  <h5>want to add friend/other student/parent/tutor? </h5>

  <a class= "btn btn-primary badge mx-5" href = {{signupLink}} >Signup here</a>

  or 
<button class= "btn btn-outline-primary" (click) = 'showLink = !showLink' >Share Signup-Link</button>


<div *ngIf= showLink> 
<app-mail-sender> </app-mail-sender>

<h5>Please share following link: </h5>
  {{signupLink}}
  <!--
<h5 class= "my-2"> or give userDomain: </h5> 
{{userDomain}}
-->
</div>
</div>
