<div *ngIf='cs.enable("newsRoom","show")'>

   <div *ngIf="(data$ | async) as d;">
      
      
      <app-cmnt-list *ngIf='d.dataList && d.dataList[0]' [featureType]="'home'"
         [cmntType]="'HnewInfo'" [parentHdrId]=d.dataList[0].id hideTitle=true>
      </app-cmnt-list>
   

   </div>

</div>