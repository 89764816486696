
<div class="fullWidth text-left  ">
.  
</div>



<div class="card   shadow-sm">

    <div class="card-title">
        <p class="card-text" innerHTML= {{q.title}}></p>
     </div>   

     <div class="card-body">
     <div *ngIf="q.vidLink && q.status != 'hide' && !q.inactive"
            class="embed-responsive embed-responsive-16by9 border-0">
            <iframe [src]="(utube + q.vidLink)|safe :'resourceUrl' "
              allowfullscreen>
            </iframe>
            </div>

            <!-- for wireFrame-->
            
            <div *ngIf="q.wireFrame "  class="embed-responsive embed-responsive-16by9">
              <!--
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
              -->
              <p class="embed-responsive-item" [innerHTML] = "q.wireFrame|safe :'html'"></p>
            </div>

           
        <div  class="card-body">
            <p class="card-text" innerHTML= {{q.instruction}}></p>
            <p class="card-text" innerHTML= {{q.question}}></p>
              
            <div class="d-flex justify-content-between align-items-center">
              <div *ngIf="true" class="btn-group">
                
                <button  type="button" class="btn btn-sm btn-outline-secondary" (click)="openScrollableContent(longContent)" >View</button>

                <button *ngIf='cs.enable("vid-dtl","edit")'  type="button" class="btn btn-sm btn-outline-secondary" (click) = "showUpdate = !showUpdate" >Edit</button>

              </div>
              <small class="text-muted">{{q.digInstrctn}}</small>
              
              <div class= "float-right mr-3"> 
                   <app-rating class= "float-right" [q] = q> </app-rating> 
                </div>
            </div>
        </div>        
    
    
   </div>



</div>

<!--
<app-qstn-btns class="" [q]=q [i]=i></app-qstn-btns>

<app-my-json *ngIf='(showUpdate && cs.enable("vid-dtl","json-editor"))' [q] = 'q' > </app-my-json>

-->
<br>


<app-upd-qstn-dtl class= "text-left" *ngIf= "showUpdate" [id] = q.id> </app-upd-qstn-dtl>


