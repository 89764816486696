<ng-container *ngIf= "qs.enable('sylbsBtn',i,q)">
    <button *ngIf= "qs.enable('outOfSyl21',i,q) && !(q.status && q.status.includes('outSlbs'))" class= "float-right  col-3 col-md-2 badge col-2 btn-danger"   (click)="qs.changeDtlStatus(q.id,'outSlbs21',i); changeAddI= i;">Tag-Out Of Syllabus-21 </button>
 
    
    <button *ngIf= "qs.enable('outOfSyl21',i,q)&& (q.status && q.status.includes('outSlbs'))" class= "float-right  col-3 col-md-2 badge col-2 btn-secondary"   (click)="qs.changeDtlStatus(q.id,'backSlbs21',i); changeAddI= i;">Untag-Out Of Syllabus-21 </button>
 
</ng-container>
 
    <div *ngIf = "(changeAddI == i)"> changed: {{success}} </div>
    
    <div *ngIf = "success && (showAddI == i)  " class= "float-right text-success">  Successfully Reported
    </div>
 
    
    <!--

     <button *ngIf= "qs.enable('outOfSyl') && q.status != 'outSlbs'" class= "float-right  col-3 col-md-3  col-2 btn-info"   (click)="postData('OUT',q.source,q.id,i); showAddI= i;">Out of Syllabus</button>
     
     <button *ngIf= "qs.enable('outOfSyl')&& q.status == 'outSlbs'" class= "float-right  col-3 col-md-3  col-2 btn-info"   (click)="postData('OUT',q.source,q.id,i); showAddI= i;">Bring in Syllabus</button>
  
  
  
    -->

    