
  import { Component, OnInit, Input } from '@angular/core';
  import { Header } from '../header';
  import { QstnhdrService } from '../qstnhdr.service';
  import { Router } from '@angular/router';
import { FileService } from '../../shared/file.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { Type } from '../type';
import { NgForm } from '@angular/forms';
import { ExtraInfo } from '../../shared/assigner/extra-info';

@Component({
    selector: 'app-qstnhdr-add',
    templateUrl: './qstnhdr-add.component.html',
    styleUrls: ['./qstnhdr-add.component.scss'],
    providers : [NgForm]
  })
  export class QstnhdrAddComponent implements OnInit {

    @Input() iHeader :Header;

    // override hdrFormat
    @Input() ovrHdrFormat :string;
    ei:ExtraInfo;
    

    types :Type[]= [new Type('pyq','previous Year papers'), new Type('qeps','Question Paper Set'),
    new Type('hwh','textbook Solutions'), new Type('rbs','Refernce Book  Solutions'),new Type('vid','Video'),new Type('qdhw','Homework/Assignment'),new Type('doc','Study Materials/doc')];
    inp:string = "link";
    expand:boolean;
    expandVid:boolean;
    upload:boolean;
    public header: Header = null;
    submitted = false;
    

    constructor(private headerService: QstnhdrService,
      private router: Router,private cs:ClientInfoService) { }

    ngOnInit() {
      this.createHeader();
      this.ei= new ExtraInfo();
      

    }

    createHeader(): void {
      this.submitted = false;
      
      if(this.iHeader){
        this.header = this.iHeader;
      } else{

       this.header = new Header();
       
       console.log("no hdr passed");
       this.header= this.headerService.populateDefault(this.header);
       }
       if(this.header.type =='vid'){
        this.expandVid= false;
       }
       

       if (this.ovrHdrFormat){
         this.header.hdrFormat= this.ovrHdrFormat;
         }
        
    this.header.clsSec = this.cs.getCrtClsSec(this.header.type);
     console.log(this.header.clsSec);
    }

    save() {
      if(this.inp=='new'){
        this.header.newDraft= true;
      }

      
      if(this.ei){
        this.header.extra= JSON.stringify(this.ei);
       }

      this.headerService.create(this.header)
        .subscribe(data =>
          {   
            console.log(data);         
            if(data.status== 200){
              this.cs.addMsg("header id: " +   data.message);
            } else{
              this.cs.addMsg("header id: " + data.message);
              
            }
        
          } , error => {
            console.log(error);
            this.cs.addMsg("header id: " + this.header.id + " - " +  error.message,"danger");
            //this.header = new Header();
             }
          );
        }

    onSubmit() {
      this.submitted = true;
      console.log("calling add new");
      this.save();
    }


    gotoList() {
     //this.cs.addMsg("header added");
     QstnhdrService.updateList.next(true);

     // this.router.navigate(['//eba//digibest//features//headers']);
    }
  }
