import { Component, Input, OnInit } from '@angular/core';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';

@Component({
  selector: 'app-gencmnt',
  templateUrl: './gencmnt.component.html',
  styleUrls: ['./gencmnt.component.scss']
})
export class GencmntComponent implements OnInit {
  @Input()  q :any;
  @Input()  i :number;
  showCommentI:number;
  constructor(public qs:QstnListService) { }

  ngOnInit(): void {
  //this.showCommentI = this.i;  
  }

  }
