<!-- may be in future
    
    <button (click) = 'showQstnStts = !showQstnStts' > +
    </button>
    
    {{qs.cs.getPage()}}-{{qs.page}}-{{page}}
    
-->

<div class=" container fullWidth p-1 ">
    <app-list-page *ngIf="qs.cat=='test'"> </app-list-page>

<app-qstn-status *ngIf="showQstnStts && qs.cat =='hwrk'" [qstnList]='list'>
</app-qstn-status>

<ngb-pagination
    *ngIf="enable ('topPagination') && qs.filteredList && qs.filteredList.length > qs.pageSize"
    class="d-flex justify-content-center" [(page)]="qs.page"
    [pageSize]="qs.pageSize" [maxSize]="7"
    [collectionSize]="qs.filteredList.length" [rotate]="true"
    [boundaryLinks]="true" (pageChange)="loadPage($event)">
</ngb-pagination>

<!-- p1 was not helping in right margin on phone hence p2-->


<ul class="px-1 mx-0"
    *ngFor="let q of qs.filteredList|search :qs.searchField:qs.searchTerm:'contains'| slice: (qs.page-1) * qs.pageSize : (qs.page-1) * qs.pageSize + qs.pageSize; let i = index;">

    <div *ngIf="(i>'0') && (i%5=='0')">
        <app-display-ad> </app-display-ad>
    </div>

    <li div class="container-fluid list-group  border-0 px-0 list-group-flush"
        *ngIf="enable('showQstn',q)">

        <ng-container
            *ngIf='!(qs.enable("hideBtn") && q.status && q.status == "hide")'>

            <!--
            viewType: {{viewType(h,q)}}
-->

            <!-- important: use viewType and develop it for future rest all should be removed-->

            <div [ngSwitch]='viewType(h,q)'>

                <!-- new detail digitizer view-->
                <app-dtl-view-shower *ngSwitchCase='"dgtl"' [q]=q [i]=i [h]=h>
                </app-dtl-view-shower>

                <app-vid-dtl *ngSwitchCase='"video"'
                    class="text-center mb-1 border-0" [q]=q [i]=i> ...
                </app-vid-dtl>

                <app-bstr-view *ngSwitchCase='"gpb"' class="list-group-item "
                    [q]=q [i]=i> </app-bstr-view>


                
                <app-test-qstn *ngSwitchCase='"hwrk"' class="list-group-item p-0"
                    [q]=q [i]=i [worksheet]='true'> </app-test-qstn>

                <app-slide *ngSwitchCase='"slide"'
                    class="text-center mb-1 border-0" [q]=q [i]=i> ...
                </app-slide>

                <ng-container *ngSwitchCase='"cfg"'>
                    <app-cfg-dtl [q]=q [i]=i>
                    </app-cfg-dtl>
                    <app-simple-qstn [q]=q [i]=i>
                    </app-simple-qstn>
                </ng-container>
                <!--
                <app-qstn-btns class="" [q]=q [i]=i [h]=h></app-qstn-btns>
                    -->
                <ng-container *ngSwitchDefault>
                    <app-simple-qstn [q]=q [i]=i>
                    </app-simple-qstn>

                    <app-crctn *ngIf="qs.enable('crctedAnsr')" [q]=q [i]=i>
                    </app-crctn>
                    
                    <app-ansr-view *ngIf='qs.enable("viewAnsrEligible")'
                        [showA]=showA  [q]=q [i]=i>
                    </app-ansr-view>
                    
                    <app-whole-qstn-btn *ngIf='qs.enable("wholeQstnBtn")'
                        [q]='q'> </app-whole-qstn-btn>
                </ng-container>

                <app-qstn-btns class="" [q]=q [i]=i [h]=h></app-qstn-btns>









                
                <span *ngIf='false'>
                    <ng-container *ngSwitchDefault>
                        <div [ngSwitch]='qs.cat'>


                            <app-bstr-view *ngSwitchCase='"gpb"'
                                class="list-group-item " [q]=q [i]=i>
                            </app-bstr-view>



                            <app-test-qstn *ngSwitchCase='"hwrk"'
                                class="list-group-item " [q]=q [i]=i
                                [worksheet]='true'> </app-test-qstn>


                            <!--
           <app-vid-dtl *ngSwitchCase='"vid"' class="text-center mb-1 border-0"
                [q]=q [i]=i> ...</app-vid-dtl>
            -->

                            <app-slide *ngSwitchCase='"slide"'
                                class="text-center mb-1 border-0" [q]=q [i]=i>
                                ...
                            </app-slide>

                            <!--  <app-subjective-qstn class="list-group-item " [q]=q [i]=i> </app-subjective-qstn>        -->

                            <!-- remove border-0  if u want box-->

                            <div *ngSwitchDefault
                                class="list-group-item border-0 mb-0">

                                <!-- idea is format should drive in future-->
                                <div [ngSwitch]='h.hdrFormat'>

                                    <app-cfg-dtl *ngSwitchCase='"cfg"' [q]=q
                                        [i]=i>
                                    </app-cfg-dtl>

                                    <!--
                <app-vid-dtl *ngSwitchCase='"vid"' class="text-center mb-1 border-0"
                [q]=q [i]=i> ...</app-vid-dtl>
                -->

                                    <app-slide *ngSwitchCase='"slide"'
                                        class="text-center mb-1 border-0" [q]=q
                                        [i]=i> ...
                                    </app-slide>


                                    <div *ngSwitchDefault>
                                        <!--
               <app-vid-dtl *ngIf='(qs.offering.includes("vid") || q.format =="vid" )' class="text-center mb-1 border-0"
                    [q]=q [i]=i> ...</app-vid-dtl>    
                -->

                                        <ng-container
                                            *ngIf='!(qs.offering.includes("vid") || q.format =="vid")'>

                                            <app-simple-qstn [q]=q [i]=i>
                                            </app-simple-qstn>

                                            <app-crctn
                                                *ngIf="qs.enable('crctedAnsr')"
                                                [q]=q [i]=i> </app-crctn>

                                            <app-ansr-view
                                                *ngIf='qs.enable("viewAnsrEligible")'
                                                [showA]=false [q]=q [i]=i>
                                            </app-ansr-view>
                                             
                                            
                                            <app-whole-qstn-btn
                                                *ngIf='qs.enable("wholeQstnBtn")'
                                                [q]='q'>
                                            </app-whole-qstn-btn>
                                            

                                        </ng-container>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-container>
                </span>

            </div>





        </ng-container>
        <!-- beed crud to be out as unhide is in crud-qstndtl for hidden record
        <app-qstn-btns class="" [q]=q [i]=i [h]=h></app-qstn-btns>
-->



        <hr>




        <!--the below has been moved to crctn component  qs.enable('crctn') &&
        <app-usr-ansr *ngIf="qs.status=='rslt'" [q]=q [i]=i> </app-usr-ansr>   
        show crctedAnsr: {{qs.enable('crctedAnsr')}}
    -->





    </li>

</ul>


<div class="float-right mx-5" *ngIf="qs.enable('setPageSize','',h)">
    <label for="pageSize">items per page &nbsp;</label>
    <input id="pageSize" type="text" size="3" [ngModel]="qs.pageSize"
        (ngModelChange)="valueChange($event)" />

  
        <app-status-changer class= "float-center pb-5" [hdr]= h  ></app-status-changer>
</div>

<ngb-pagination *ngIf="qs.filteredList && qs.filteredList.length > qs.pageSize"
    class="d-flex justify-content-center" [(page)]="qs.page"
    [pageSize]="qs.pageSize" [maxSize]="7"
    [collectionSize]="qs.filteredList.length" [rotate]="true"
    [boundaryLinks]="true" (pageChange)="loadPage($event)">
</ngb-pagination>

<div class="text-center">
<a *ngIf="enableNextPageSEO()" class="btn btn-secondary badge text-center px-5"
    href='{{nextPageUrl()}}'> next page </a>
</div>
<!--
        <ng-template ngbPaginationFirst>First</ng-template>
        <ng-template ngbPaginationLast>Last</ng-template>
        <ng-template ngbPaginationPrevious>Prev</ng-template>
        <ng-template ngbPaginationNext>Next
                 </ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}

        </ng-template>
     -->
    </div>