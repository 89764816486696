import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';

import { CKEditorModule } from 'ckeditor4-angular';
import {ClientInfoService} from './client-info.service';
import { FormsModule, ReactiveFormsModule , FormGroup} from '@angular/forms';
import { TestModule } from '../test/test.module';
import { CommentsModule } from '../comments/comments.module';
import { UrlShowerComponent } from './url-shower/url-shower.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PdfviewerComponent } from './pdfviewer/pdfviewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LowerCasePipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import { HtmlViewerComponent } from './html-viewer/html-viewer.component';
import { TextEdtrComponent } from './text-edtr/text-edtr.component';
import { LearnModeComponent } from '../user/learn-mode/learn-mode.component';
import { DidYouKnowComponent } from './did-you-know/did-you-know.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './interceptor.service';
import { MessagesComponent } from '../messages/messages.component';
import { SafePipe } from './safe.pipe';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { AdsenseModule } from 'ng2-adsense';
import { StatusChangerComponent } from './status-changer/status-changer.component';
import { StatusPickerPipe } from './status-picker.pipe';
import { HeadingsComponent } from './headings/headings.component';
import { RouterModule } from '@angular/router';
import { DisplayAdComponent } from './display-ad/display-ad.component';
//import { EmbedVideo } from 'ngx-embed-video';
import { MathJaxModule } from 'ngx-mathjax';
import { AssignerComponent } from './assigner/assigner.component';
import { ShareComponent } from './share/share.component';
import { CreateFileComponent } from './create-file/create-file.component';
import { VidPlayerComponent } from './vid-player/vid-player.component';
import { ShowHdrgrpComponent } from './show-hdrgrp/show-hdrgrp.component';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ExamSelectorComponent } from './exam-selector/exam-selector.component';

import { HeadingsPlainComponent } from './headings-plain/headings-plain.component';
import { HeadingsOfferingComponent } from './headings-offering/headings-offering.component';
import { VisionComponent } from './vision/vision.component';
import { MyJsonComponent } from './my-json/my-json.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { MathModule } from '../math/math.module';
import { Mathml2latexComponent } from './mathml2latex/mathml2latex.component';
import { HelpComponent } from './help/help.component';

import { ContextComponent } from './context/context.component';
import { SubjectListerComponent } from './subject-lister/subject-lister.component';
import { OfferingListerComponent } from './offering-lister/offering-lister.component';
import { DataTableComponent } from './data-table/data-table.component';
import { DataTablesModule } from 'angular-datatables';
import { BookListComponent } from './book-list/book-list.component';
import { GenModule } from '../gen/gen.module';
import { QstnShowerComponent } from './qstn-shower/qstn-shower.component';
import { HeadingsQstnComponent } from './headings-qstn/headings-qstn.component';
import { HdrViewSlctrComponent } from './hdr-view-slctr/hdr-view-slctr.component';
import { BtnMapComponent } from './btn-map/btn-map.component';
import { HdrViewBtnsComponent } from './hdr-view-btns/hdr-view-btns.component';
//import { NgJsonEditorModule } from 'ang-jsoneditor';


@NgModule({
  declarations: [SafePipe,UrlShowerComponent,FileUploadComponent, PdfviewerComponent, HtmlViewerComponent, TextEdtrComponent, LearnModeComponent, DidYouKnowComponent,MessagesComponent, StatusChangerComponent, StatusPickerPipe, HeadingsComponent, DisplayAdComponent, AssignerComponent, ShareComponent, CreateFileComponent, VidPlayerComponent, ShowHdrgrpComponent, ExamSelectorComponent,  HeadingsPlainComponent, HeadingsOfferingComponent, VisionComponent, MyJsonComponent, ConfirmModalComponent, Mathml2latexComponent, HelpComponent, ContextComponent, SubjectListerComponent, OfferingListerComponent, DataTableComponent, BookListComponent, QstnShowerComponent, HeadingsQstnComponent, HdrViewSlctrComponent,  BtnMapComponent, HdrViewBtnsComponent],
  imports: [
    CommonModule,
   // NgJsonEditorModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxExtendedPdfViewerModule,
    NgbModule,
    MathModule,
    //EmbedVideo,
    DataTablesModule,
    GenModule,
    RouterModule,
    NgxPageScrollCoreModule.forRoot({duration: 2500}),
      
    AdsenseModule.forRoot({
      adClient: 'ca-pub-4808501513929067', //replace with your client from google adsense
      adSlot: 2882359303 //replace with your slot from google adsense
  }),
  
/*
  MathJaxModule.forRoot({
    version: '2.7.7',
    config: 'TeX-AMS_HTML,../../assets/mathConfig.js',
    hostname: 'cdnjs.cloudflare.com',

  }),
*/
    //MessagesComponent
  ],
 exports: [
  HtmlViewerComponent,
  UrlShowerComponent,
  FileUploadComponent,
  PdfviewerComponent,
  LearnModeComponent,
  MessagesComponent,
  StatusChangerComponent,
  HeadingsComponent,
  DisplayAdComponent,
  TextEdtrComponent,
  AssignerComponent, 
  ShareComponent,
  ExamSelectorComponent,
  Mathml2latexComponent,
  HdrViewSlctrComponent,
  NgxPageScrollCoreModule,
  
  //MathJaxModule,
  AdsenseModule,
  CommonModule,
  CKEditorModule,
  FormsModule,
  ReactiveFormsModule,
  FontAwesomeModule,
  //NgJsonEditorModule,
  NgbModule,
  RouterModule,
  MathModule,
  SafePipe,
  CreateFileComponent,
  ShowHdrgrpComponent ,
  HeadingsPlainComponent,
  HeadingsOfferingComponent,
  VisionComponent,
  MyJsonComponent,
  
  ContextComponent,
  DataTableComponent,
  DataTablesModule,
  GenModule,
  QstnShowerComponent,
  BtnMapComponent,
  
],
  
  providers: [ClientInfoService,DatePipe,LowerCasePipe,StatusPickerPipe,TitleCasePipe,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    
  ],

  entryComponents: [ ConfirmModalComponent,HelpComponent ],
})
export class SharedModule { }
