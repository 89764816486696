import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { CommentService } from '../../comments/comment.service';
import { TestService } from '../test.service';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';
import { FileService } from '../../shared/file.service';
import { Subscription } from 'rxjs';
import { Comment } from "../../comments/comment";
import { ConfigService } from '../../shared/config.service';
import { MathJaxService } from '../../shared/math-jax.service';


//declare var MathJax:any;


@Component({
  selector: 'app-test-qstn',
  templateUrl: './test-qstn.component.html',
  styleUrls: ['./test-qstn.component.scss']
})
export class TestQstnComponent implements OnInit {
  @Input()  q :any;
  @Input()  i :number;
  @Input() worksheet: boolean;
  @Input() storeLocal :boolean;
  
  //c: any;
  flup:boolean;
  showAnsr:boolean;

  updateFileSub: Subscription;
  
  @Output('update')
  change: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  
  submitImage: boolean;
  updateFlag: boolean;

  /*
  CKEDITOR.editorConfig = function( config ) {

    //   config.enterMode = 2; //disabled <p> completely
         config.enterMode = CKEDITOR.ENTER_BR // pressing the ENTER KEY input <br/>
         config.shiftEnterMode = CKEDITOR.ENTER_P; //pressing the SHIFT + ENTER KEYS input <p>
         config.autoParagraph = false; // stops automatic insertion of <p> on focus
     };
*/
  constructor(public qs:QstnListService, public ts:TestService,public cfg:ConfigService,public mj:MathJaxService) { }
  
  //res is output from file system.
  getChildOutput(res: any) {
    
    if(res.includes('etl')){
      this.q.ansCmnts[0].extUrl= res;
      //since iamge uplaod update answer field hence 
      this.showAnsr = true;
   this.q.ansCmnts[0].message= this.q.ansCmnts[0].message +'<br> <img src = \"'+res + '\" />';
   console.log(this.q.ansCmnts[0].messagees);
    }
    
}


  ngOnInit(): void {

    

  }


  typeSet(){
    //alert("Hello World");
    //MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
   // alert("View typeset Retried! Thanks from tyepset ");
  
  }
  
   ngAfterViewChecked(){
    //console.log("Hello World");
    this.mj.typeSet();
    //MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
    //console.log("View typeset Retried- after view checked..! Thanks ");
  
  }

  //file upload
 /* upload file moved to @Output for single subscription 

    if(!this.q.ansCmnts){  
  this.q.ansCmnts.push(this.ts.giveUsrAns(this.q));
  } else{
    console.log('not calling')
  }

 this.updateFileSub = FileService.updateUploadUrl.subscribe(res=>{
  //this.q.ansCmnts[0]omment.source= res;
  if(res.includes('etl')){
    this.q.ansCmnts[0].extUrl= res;
    //since iamge uplaod update answer field hence 
    this.showAnsr = true;
 this.q.ansCmnts[0].message= this.q.ansCmnts[0].message +'<br> <img src = \"'+res + '\" />';
 console.log(this.q.ansCmnts[0].messagees);
  }
  });
  upload file ends */



  ngOnChanges(changes:SimpleChange): void {
    this.ts.setUsrAnsModel(this.q)
    //update Needed or insert

    this.updateFlag = this.ts.updateNeeded;
    
    if (!this.worksheet &&(!this.q || !this.q.optionList)){
      this.showAnsr = true;
    }
this.qs.typeSet("aa");
}


  onSubmit() {

// console.log( `Form submit, model: ${ JSON.stringify( this.q.ansCmnts[0] ) }` );


//this.q.ansCmnts[0].comment = this.ts.digAnsrs.toString();
//console.log(this.q.ansCmnts[0].comment);

if(this.storeLocal){
  return this.stayLocal(this.q);
  
  // don't delete below -important stuff
} else {
  this.q = this.ts.updateDuration(this.q);
this.ts.cServe.upsert(this.q.ansCmnts[0],this.updateFlag)
.subscribe(
  data => 
 {
 // this.ts.cs.addMsg("Error in saving,pls try aagain"); 
 // console.log(data);
  //this.writeAnsr = false;
  this.showAnsr = !this.showAnsr;
  this.ts.cs.addMsg("Answer saved...", "toast");
  if(!this.worksheet){
  this.ts.next();
  }
  this.change.emit(true);
  this.showAnsr = false;
 // this.ts.showQstn= this.ts.showQstn+1;
 },
error => {console.log(error),
this.ts.cs.addMsg("Error in saving,pls try again");
});
//MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
}
this.mj.typeSet();

  }

stayLocal(q:any) {
  this.showAnsr = !this.showAnsr;
  localStorage.setItem('qstns-'+this.ts.cs.getChapter(),JSON.stringify(this.ts.qstns) )
  this.ts.cs.addMsg("Answer saved...", "toast");
  if(!this.worksheet){   this.ts.next(q); }
 // localStorage.qstns = this.ts.qstns;
}


 setDigAnsr(option){
 this.q.ansCmnts[0].digAnsr = option;
 this.q.ansCmnts[0].flipWindow=  this.ts.count;
 this.q.ansCmnts[0].timeTaken = (this.ts.seconds-this.ts.lastSeconds);
 this.onSubmit();
  }


  setStatus(status:string){
    this.q.ansCmnts[0].status = status;
    //this.q.ansCmnts[0].flipWindow=  this.ts.count;
    //this.q.ansCmnts[0].timeTaken = (this.ts.seconds-this.ts.lastSeconds);
    this.onSubmit();
     }


  answerDelete(qstnId,choice,sequence) {
  if(choice){
  let  c = this.q.ansCmnts[0]; 
  c.digAnsr =choice;
  c.flipWindow=  this.ts.count //"Window Change Frequency: "
  c.timeTaken = (this.ts.seconds-this.ts.lastSeconds);
  this.ts.cServe.createComment(c)
            .subscribe(data => 
              {console.log(data);
          //c = new Comment();
         // console.log("answer submitted: " , qstnId +" / " + choice);
          
         }, error => console.log(error));
        }
 this.ts.showQstn++;  
 this.q.ansCmnts[0]= this.ts.qstns[this.ts.showQstn];     
 }


 
}


