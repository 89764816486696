import { Component, Input, OnInit } from '@angular/core';
import { ViewService } from '../view-lstr/view.service';

@Component({
  selector: 'app-status-based-student-view',
  templateUrl: './status-based-student-view.component.html',
  styleUrls: ['./status-based-student-view.component.scss']
})
export class StatusBasedStudentViewComponent implements OnInit {
  @Input() hdr: any;
  @Input() i: number;
  

  constructor(public vs: ViewService ) { }


  ngOnInit(): void {
  
  }

}
