import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamDataService } from '../../exam-data.service';
import { BoardDataService } from '../../board-data.service';

import { Title, Meta } from '@angular/platform-browser';
import { ClientInfoService } from '../../shared/client-info.service';


declare var MathJax:any;


@Component({
  selector: 'app-prvsyrsqstn',
  templateUrl: './prvsyrsqstn.component.html',
  styleUrls: ['./prvsyrsqstn.component.scss']
})
export class PrvsyrsqstnComponent implements OnInit {
appTitle: string = 'Percent App';
//board: string ;

yearName: string;
subject: string ;
subjectO: Object ;
subjects: Object;
//users : Object;
//users2: Object;
header: Object;
headers: Object;
title = 'ES- Percentage Booster'
questions: Object ;
showA: boolean = false;
showY: boolean = false;
showQ: boolean = false;
showS: boolean = true;
b : string;
questionHeaderE: Object;
site:string;
board:string;
course:string;




  constructor(private data: ExamDataService,private boardData: BoardDataService ,private titleService: Title,
    private metaTagService: Meta,private cs:ClientInfoService) {
this.site = cs.getSite();
this.board = cs.getBoard();
this.course= cs.getCourse();
 //this.subject = 'Chemistry';
   }


    ngOnChanges() {

      this.cs.typeSet();
        // MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
        alert("View typeset Retried! on changes - data load ");
        }



  ngOnInit() {

 this.b = "5";
//  this.board= "ICSE";

   this.titleService.setTitle('ES: ICSE 2019 10 Years Questions with Answers');
    this.metaTagService.updateTag({name: 'description', content: ' previous 10 years questions and answers in clean format for ICSE 2019 Exam!'});
    this.metaTagService.updateTag({name: 'keywords', content: ' previous years, 10 years , Question Paper, Questions & Answer, ICSE 2019 Exam'});
/*
   this.data.getUsers().subscribe(data => {
        this.users = data;

    });



    this.data.getUsers2().subscribe(data => {
        this.users2 = data;
        console.log(this.users);
      });

*/
/*
  this.data.getTagQuestions(1).subscribe(dataList => {
        this.subject = "Physics";
        this.questions = dataList;
        console.log(this.questions);
       // MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
        //alert("View typeset Retried! initial data load ");
      });
*/

/* move to this in future */
      this.boardData.getSiteSubjects(this.site,this.board,this.course,"pyq").subscribe(dataList => {
              this.subjects = dataList;
            //  alert("subjects retrieved ");
              //console.log(this.site+this.board+this.course);
              //console.log(this.subjects);

            });

/* original-- filtering this...
this.data.getPyqSubjects(this.board,this.course).subscribe(dataList => {
        this.subjects = dataList;
        alert("subjects retrieved ");
        console.log(this.subjects);

      });
*/

  }



firstClick() {
    this.data.firstClick();

  }

showAns() {
    this.showA= true;
    this.typeSet();
  }

showSubject() {
    this.showS= true;
    //this.typeSet();
  }

showYears() {
    this.showY= true;
    //this.typeSet();
  }


prvsyrsqstn(subject: string,headerId: number,tagHdrId: number,subjectO: Object) {
    this.subject= subject;
   this.showY= true;
   this.headers = (subjectO as any).questionHeaderEs;
   this.showQ= false;
   this.showS= false;
    this.data.getQuestions(headerId).subscribe(dataList => {
        this.questions = dataList;

    });

this.data.getTagQuestions(tagHdrId).subscribe(dataList => {
        this.questions = dataList;

    });


  }


loadQuestion(subject: string,headerId: number,tagHdrId: number,header:Object) {
    this.yearName= (header as any).year;
   this.showQ= true;
   this.showY= false;
   this.showS= false;
   this.showA= true;
   this.header  = (header as any);

   this.data.getQuestions(headerId).subscribe(dataList => {
        this.questions = dataList;

    });


  }




hideAns() {
    this.showA= false;
  }

  typeSet(){
  //alert("Hello World");
  MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
 // alert("View typeset Retried! Thanks from tyepset ");

}

 ngAfterViewChecked(){
  //alert("Hello World");
  MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
  //alert("View typeset Retried- after view checked..! Thanks ");

}

}
