
<hr>

<div *ngIf= "markets  && markets.dataList && markets.dataList.length>0"  class="my-5 container marketing " >


<!--markets.hdr.extUrl|safe:"resourceUrl"'-->


  <p class= "pen25  text-center" [innerHTML] = 'headerObj.title' > --  </p>
   
  
  <ul class="hdrRepeat  row my-3"  
  
  [ngStyle]="{'background-image':(markets.hdr.extUrl) ?'url(' + markets.hdr.extUrl + ')' : '' }"  >
  
  <ng-container *ngFor="let i = index; let m of markets.dataList;">
    

    <!--img [src]='m.contentImages' class="rounded-circle" width="140" height="140"-->

    <div *ngIf= 'show(m)'    
    [ngClass] = '(markets.dataList.length/2 == 0)? " dtlNoRepeat text-center my-5 col-12 col-md-4": "dtlNoRepeat text-center my-5 col-12 col-md-6"'
    
    >

       
      <div class="text-center bg-dark  text-white  my-1 " [innerHTML]= "m.instruction|safe :'html'"> </div>

      
      
 
<div [ngStyle]="{'background-image':(m.contentImages) ?'url(' + m.contentImages + ')' : '' }">
 <button *ngIf= "m.title" class=" btn btn-primary mx-2 rounded-pill" (click) = cs.navigate(m.category,m.extLink,m.format)>{{m.title}}</button>
</div>

 <div  class="small  text-center bg-light text-info  " [innerHTML]="m.content|safe :'html'"> </div>
  <!--
      <a  *ngIf='m.category || m.extLink'  routerLink = {{getRouteUrl(m.category,m.extLink,m.format)}}
        class="btn btn-outline-light"  role="button">{{m.title}}... &raquo;</a>
    --> 
          
  <!--
        <img [src]='rootUrl + m.contentImages' class="rounded-circle" width="140" height="140">
        <h4 class="text-center text-warning my-1 " [innerHTML]= "m.instruction"> </h4>
        <p  class="small  text-left  " [innerHTML]=m.content> </p>
      -->
  <!--
      <p><a *ngIf='m.extLink' class="btn btn-secondary" routerLink={{m.extLink}} role="button">View details &raquo;</a></p>
      
      <br> <br> <br>
      count: {{markets.dataList.length}}
    -->
    </div>
  
  
  </ng-container>
  </ul>

  <app-section-divider [h]= 'headerObj'> </app-section-divider>

  </div>