import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { Observable } from 'rxjs';

import { PerformanceService } from '../performance.service';


@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})

export class DoughnutChartComponent implements OnInit {
 @Input() user:string;
 mts$: Observable<any>;
 showData:boolean;
 
 /*
 doughnutChartLabels: Label[] = ['Calculation', 'Concept', 'oversight'];
  
 doughnutChartData: MultiDataSet = [
    [55, 25, 20]
  ];
  
  */
  doughnutChartType: ChartType = 'doughnut';
  labels: any;
  data: any;


  constructor(private perfSrv:PerformanceService) { }

  ngOnInit(): void {
     this.mts$ = this.perfSrv.getMistakeTypes(this.user) ; 
  }


}
