
<head>
  <title>ES- {{board}}-{{course}} previous Years Question Paper </title>
</head>
<!--
<script type="text/x-mathjax-config">
MathJax.Ajax.config.path["mhchem"] =
  "https://cdnjs.cloudflare.com/ajax/libs/mathjax-mhchem/3.3.0";

  MathJax.Hub.Config({
    tex2jax: {
        inlineMath: [['``','``'], ['\\(','\\)']],
        processEscapes: true
        },
        TeX: {
    extensions: ["[mhchem]/mhchem.js",],
   },
  });

</script>

 <script type="text/javascript"
        src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-MML-AM_CHTML">

       MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
        </script>

<script src="ng-mathjax.js"></script>

-->

<div class="bg-success jumbotron" >
   <h3 class="mx-auto" style="width: 850px;">{{board}} Previous Year's Question Paper & Answer</h3>
    <p class="mx-auto" style="width: 400px; ">
     Practice at Natural Pace with Steppers


 <!--
    <span> <h2> Click Below to select Subject: </h2></span>

<button (click)="percentageBooster('Physics',4)">Physics </button>
<button (click)="percentageBooster('Chemistry',6)">Chemistry </button>
<button (click)="percentageBooster('Mathematics',14)">Mathematics </button>
<button (click)="percentageBooster('Biology',15)">Biology </button>
-->

 </p>

 </div>


 <div>
 <button class= "badge"  *ngIf="!showS" (click)="showSubject()">Click here for picking new Set of Question Paper and Answer </button>


<!-- oldd
<ul *ngIf="subjects">

  <li *ngFor="let subject of subjects.dataList"  style="width: 50px; ">
  <p > <button class= "badge" (click)="percentageBooster(subject.name,subject.headerId, subject.tagHdrId)">{{subject.name}} </button>
  </p>

  </li>
</ul>



   </div>
-->






<!-- comment this -->

<!--
<ul *ngIf="subjects" class=" subjects" >

  <h3>Click Subject:</h3>

  <li class="thumbnail" *ngFor="let subject of subjects.dataList"

   (click)="prvsyrsqstn(subject.name,subject.headerId, subject.tagHdrId)">
  <div>   <button> <span class="badge">{{subject.name}}</span> </button>
</div>

  </li>
</ul>

<!-- comment this -->
<!-- -->




<!-- original vertical list below-->

<ul *ngIf="subjects && showS" class=" subjects" >

  <h3>Pick Subject:</h3>

  <li class="thumbnail" *ngFor="let subject of subjects.dataList"

   (click)="prvsyrsqstn(subject.name,subject.questionHeaderEs[0].id, subject.tagHdrId,subject)">
  <div>   <button> <span class="badge">{{subject.name}}</span> </button>
   </div>

  </li>
</ul>


<!-- working on laptop
<div *ngIf="showS" >
<h3>Pick Subject:</h3>
<ul *ngIf="subjects "  >


  <div class="d-flex flex-row flex-wrap p-2 bg-secondary text-white  " >
  <li class="thumbnail" *ngFor="let subject of subjects.dataList"

   (click)="prvsyrsqstn(subject.name,subject.questionHeaderEs[0].id, subject.tagHdrId,subject)">
  <div class="p-2 bg-info">   <button> <span class="badge">{{subject.name}}</span> </button>
   </div>

  </li>
</div>
</ul>
</div>
-->






<div *ngIf="showY" >
<h3 >Select Year of subject : {{subject}}</h3>
<ul *ngIf="headers">
  <div class="d-flex flex-wrap flex-row p-2 bg-secondary text-white  " >

  <li class = " " *ngFor="let header of headers"

   (click)="loadQuestion(subject.name,header.id, subject.tagHdrId,header)">
    <div class="p-2 bg-info">
       <button> <span class="badge">{{header.year}}</span> </button>
   </div>
  </li>
</div>
</ul>
</div>


<!-- dflex.. -->
<!--

<div *ngIf="showY" >
<ul *ngIf="subjects">
  <div class="d-flex flex-row p-2 bg-secondary text-white  " >
  <h3 >Select Years:</h3>
  <li class = " " *ngFor="let questionHeaderE of subjects.dataList[0].questionHeaderEs"

   (click)="percentageBooster(subject.name,subject.headerId, subject.tagHdrId,subject)">
    <div class="p-2 bg-info">
       <button> <span class="badge">{{questionHeaderE.year}}- {{subject.name}}</span> </button>
   </div>
  </li>
</div>
</ul>
</div>
-->


  <div *ngIf ="b == '5'&& showQ" id = "aa" is-info is-fullheight is-bold>

<h2>{{subject}}-{{yearName}} Board Question & Answer </h2>

 <button (click)="typeSet()">Refresh View </button>
 <button *ngIf="!showA" (click)="showAns()">Show Answer </button>
 <button *ngIf="showA" (click)="hideAns()">Hide Answer </button>






<br>
<!--
<h1>Users </h1>
<ul *ngIf="users">
  <li *ngFor="let user of users.data">
    <img [src]="user.avatar">
    Question :
    <p>{{ user.first_name }} </p>
    <br/>
    Answer:
    <p>{{ user.last_name }}</p>

  </li>
</ul>
-->

<ul *ngIf="questions.dataList  ">

  <li *ngFor="let question of questions.dataList;">

    #: {{question.sequence }}-{{question.subSeq}}-{{question.part}} <br>

<div *ngIf="question.instruction" class="instruction" innerHTML = " {{  question.instruction }}">

</div>
    <br/>

    <div *ngIf="question.content" class="questionsc" innerHTML = " {{  question.content }}"></div>
    <br/>


    <div *ngIf="question.answer && showA" class="answersc" innerHTML = "<br> Ans : {{ question.answer }}"></div>

    <!--
    <p>{{ question.answer }}</p>
-->

  </li>
</ul>


<!--
-->
</div>


<!--
<h1>Users page#  {{users2.page }}</h1>

<ul *ngIf="users2">
  <li *ngFor="let user of users2.data">
    <img [src]="user.avatar">
    <p>{{ user.id }} </p>
    <p>{{ user.first_name }} </p>
    <p> {{ user.last_name }}</p>
  </li>
</ul>







// script function put in this html doesn't work ...
  <script language="javascript" type="text/javascript">

  pbooster works!

function typeSet(){
  alert("Hello World");
  MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
  alert("typeset tried ");

}


</script>
-->
