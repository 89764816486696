
<app-navbar></app-navbar>


<div class="row">
  <div class="col s6 offset-s3">
  <div class="card">
    <div class="col">
      <h3 class="text-center">Quiz Registration</h3>
      </div>
    <br>

    <div class="col s3">
      <img src="/assets/img/logo.png" style="width:150px;height:150px;" class="offset-s3">
    </div>

<br>

<br>

      <div class="card-content">
          <form #registerForm="ngForm" (ngSubmit)="OnSubmit(name.value,email.value)">
            <div class="row">
              <div class="input-field col s12">
                <i class="material-icons prefix">account_circle</i>
                <input type="text" name="name" #name ngModel required placeholder="Name">


              </div>
            </div>
            <div class="row">
              <div class="input-field col s12">
                <i class="material-icons prefix">mail_outline</i>
                <input type="text" name="email" #email ngModel required [pattern]="emailPattern" placeholder="Email">
                <!--label>Email</label-->
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12">
                <button class="btn-large btn-submit" type="submit" [disabled]="!registerForm.valid">Start</button>
              </div>

            </div>
            <div  class="row" >
              <br>
                Note: Please use signup screen if you are a regular user else just register for one time use.
                <br>
          </div>
          </form>
      </div>
    </div>
  </div>
</div>
