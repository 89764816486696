import { Injectable } from '@angular/core';
import { ListerService } from '../../chat/lister.service';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../client-info.service';
import { OfrDtlService } from './ofr-dtl.service';
import { ExtraHdrService } from '../../gen/extra-hdr.service';

@Injectable({
  providedIn: 'root'
})
export class HeadingsService {
  //hdr: any;
  topTitle: string;
  mainTitle: string;
  summary: any;
  extraSeo: string;
  rslt: any;

  constructor(public cs: ClientInfoService, public hdng: HeadingsService, private dataService: BoardDataService, public ofrDtl: OfrDtlService, public lstr: ListerService, private extraHdrService: ExtraHdrService) { }

  updateData(h: any, d?: any) {
    this.extraSeo = "";
    this.topTitle = this.transform(this.getTopTitle(h, d));
    this.mainTitle = this.transform(this.getMainTitle(h, d));
    this.summary = this.transform(this.getSummary(h, d));


  }

  setSEO(h: any, d?: any) {
    //console.log('updating seo',h);

    /*
     if(!h && this.cs.getChapter()){
       console.log('header was not passed?')
       h= this.loadHdr();}
      
      if(!d && this.cs.getOffering()){
      let r= this.getFtrDtl();
       h = r.hdr;
       d= r.dtls[0];
       }
      */

    this.updateData(h, d);

    //console.log('top',this.topTitle);
    //console.log('main',this.mainTitle);
    //console.log('sum',this.summary);

    let seoTitle = "";
    if (this.cs.qstnId) {
      seoTitle = [this.extraSeo, this.mainTitle, this.topTitle].filter(Boolean).join(" - ");
    } else {
      seoTitle = [this.mainTitle, this.topTitle, this.extraSeo,].filter(Boolean).join(" : ");
    }

    seoTitle = seoTitle.split("<br>").join(" ");


    let robotContent = 'index,follow';
    if (this.cs.getOffering()?.includes("cfg")) {
      robotContent = 'noindex'
      console.log('robot set to noindex');
    }
    this.cs.seo.updateRobots(robotContent);


    this.cs.seo.updateTitle(seoTitle);

    this.cs.seo.updateDescription(this.topTitle + " " + this.mainTitle + " " + this.extraSeo + " " + this.summary);
    this.setTags(h, d);
  }

  transform(line: string) {
    if (line) {
      line = line.replace("%", " percent ")
      line = line.replace("", "");
      line = line.replace("null", " ");
      line = line.replace("-cmn", "");
      line = line.replace("GRE-prep", "GRE-hello");
      line = line.replace("$user", this.cs.getSignedUser());
      line = line.replace("user", this.cs.getSignedUser());
    }
    return line;
  }


  getFtrDtl() {
    this.lstr.getFtrDtl().subscribe(data => {
      // console.log(data);
      if ((data as any).status == 200 && (data as any).result) {
        this.rslt = (data as any).result;
        //console.log(this.rslt);
      } else {
        //console.log("error");

      }
    }, error => {
      // console.log(error);

    });
    //console.log(this.rslt);
    return this.rslt;

  }


  getTopTitle(h: any, d?: any) {


    //note board-course needs to be hidden for below 
    if (this.cs.getFeature() == "exams") {
      //return "Success in steps";
      return "";
      let h = this.ofrDtl.getMenuHdr();
      let t = 'hs:Learn fast with digiBest Steps';
      return h.title ? h.title : t;
    }

    if (h && h.extra) {

      let extraJson = this.extraHdrService.extraHdr(h.extra)
      if (extraJson && extraJson.hideTopTitle && extraJson.hideTopTitle == "true") { return "" }
    }

    if (this.cs.getCat() == "home") {
      return "";
    }

    let sub = this.cs.getSubject() == 'subject' ? h.subject : this.cs.getSubject();
    if (this.cs.enable("headings", "showBoard")) {
      let t = [this.cs.getBoard(), this.cs.getCourse(), sub, this.cs.getSem()].filter(Boolean).join('-');
      return t;
    }

  }

  getMainTitle(h: any, d?: any) {
    let t = '';
    if (h) {
      t = [h.section, h.title].filter(Boolean).join('-');
      if (h.year && h.year > 10) { t = t + " year:" + h.year }
      if (h.chapterId) { t = "" + h.chapterId + ": " + t }
      if (h.bstrSet) { t = t + " Set:" + h.bstrSet }
    }

    if (d) {

      t = [d.title, t].filter(Boolean).join(':');

      let extra = '';
      if (d.digQstn) {
        extra = [d.digInstuction, d.digQstn].filter(Boolean).join('-');
      } else {
        extra = [d.instruction, d.question].filter(Boolean).join('-');
      }
      if (d.groupId && (d.qstnId || d.sequence)) {
        extra = [" qstnId ", d.groupId, , extra].filter(Boolean).join('-')
      }

      this.extraSeo = extra;
      //this.extraSeo= d.digQstn? d.digQstn:extra;
    }


    if (!t && this.cs.getOffering()) { t = t + ":" + this.cs.titleHead; }

    return t;

  }

  getSummary(h: any, d: any) {

    let summary = h ? h.summary : "";
    summary = summary ? summary + " - " : ""
    if (d && d.instruction) {
      summary = [d.instruction, summary].filter(Boolean).join(':');
    }

    if (this.cs.isUnsigned() && this.cs.getOffering() && this.cs.getOffering() == "hwh") {

      if (this.cs.getBoard() == 'CBSE') {
        summary = summary ? summary : "" + ' digibest Free NCERT Solutions with no signup needed'
      }
      if (this.cs.getBoard() == 'ICSE') {
        summary = summary ? summary : "" + ' digibest Free Selina ICSE  Solutions with no signup needed'
      }
    }
    //if(!summary){ summary= this.cs.titleDesc}
    //console.log(summary + this.cs.getOffering()+ this.cs.getBoard());
    //alert(summary);

    if (this.cs.getPage()) {
      summary = summary + " page " + this.cs.getPage();
    }

    return summary;

  }


  loadHdr() {
    console.log("pls pass hdr obj");
    let hdrId: any = this.cs.getChapter();
    this.dataService.getHdrObj(hdrId).subscribe(data => {
      if ((data as any).status == 200 && (data as any).result) {
        let hdr = (data as any).result;
        return hdr;
      }
    });
  }



  setTags(hdr: any, d?: any) {
    if (hdr) {
      let content = [hdr.board, hdr.course, hdr.subject, hdr.title, hdr.topic, hdr.summary].filter(Boolean).join(',')

      let tags = [
        { name: 'keywords', content: content },
        //{name: 'description', content: 'Angular Universal Example'},
        // {name: 'robots', content: 'index, follow'}
      ]
      this.cs.seo.updateTags(tags);
    }
  }


}
