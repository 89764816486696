
<div  class="bg-white text-white"  >
<canvas id="myLineChart"  width="600" height="400"> </canvas>
</div>


<div  class="bg-white text-white"  >
  <canvas id="myBarChart"  width="600" height="400"> </canvas>
  </div>

 