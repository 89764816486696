

<div *ngIf="(data$ | async) as d; else loading">
      
  <ng-container *ngIf="d.dtls && d.dtls.length; else noItems">
    
<div *ngIf= "d.hdr" class="my-5 container marketing ">
   
  <p class= "pen40  text-center">   {{d.hdr.title}}</p>
  
  <ul class="row" >
  <ng-container *ngFor="let i = index; let m of d.dtls;">
    
    

    <div *ngIf= 'show(m)' [ngClass]="d.dtls.length %3===0 ? 'col-lg-4 ' : 'col-lg-6'"  class="col-12 text-center mb-3">
     
      <div class="btn btn-rounded" (click) = navigate(m.category,m.extLink,m.format) >   
    
      <img [src]='m.questionImages' class="rounded-circle" width="140" height="140">
  </div>

  
      <div class= "my-2 small">
        
      <a  *ngIf='m.category || m.extLink ; else plain'  routerLink = {{getRouteUrl(m.category,m.extLink,m.format)}}
      
        class="btn btn-primary badge border-1"  role="button"> <span class="pen25" [innerHTML] = m.title> </span> &raquo;</a>
        <ng-template #plain>
          
        <div class="pen25 font-weight-bold text-center my-2 " [innerHTML]= "m.title"> </div>
      </ng-template>
    </div>
      <div>
      <div  class=" text-center small font-weight-bold  " [innerHTML]=m.instruction> </div>
      <div  class="text-center text-info " [innerHTML]=m.question> </div>
      <!-- d-none small font-weight-bold-->
    </div>
      <ng-container *ngIf= "forPdf">
      <p  class="text-left  " [innerHTML]=m.answer> </p>
      <br><br> 
      <div class="modal-body" innerHTML= {{m.digInstrctn}}></div>
      <br>
      <br>
      
    </ng-container>

    <!--
      <p><a *ngIf='m.extLink' class="btn btn-secondary" routerLink={{m.extLink}} role="button">View details &raquo;</a></p>
    -->
    <p>
      
      
        <button *ngIf= "m.answer"  class="btn btn-outline-info badge border-0  mb-2 mr-2 float-right" (click)="openScrollableContent(longContent)">...</button>
      </p>
    </div>
  
  
  
  
  
    <ng-template #longContent let-modal>
      <div class="modal-header">
        <h4 class="modal-title" [innerHTML] = 'm.title'>  </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <p  class="text-center text-warning  " [innerHTML]=m.question> </p>
      <br><br> 
      <div class="modal-body" innerHTML= {{m.answer}}>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
      </div>
    </ng-template>
  
  
  </ng-container>
  </ul>
    
  <app-section-divider [h]= 'd.hdr'> </app-section-divider>
   
    <button *ngIf='d.hdr.extUrl'  class="btn btn-outline-info badge border-0  mb-2 mr-2 float-right" (click)="navigatePrdDesc(headerObj)">more</button>

 
  
</div>
 