
<div  *ngIf='cs.enable("footer","tag-site")'  class ='label' >
           
    <span class="float-left">

        <!--style="color: yellowgreen; font-size: 10px;"  inline direct style working if needed-->

        <div class = 'label'> <i>Powered by IITian
                &
                NITian </i> </div>
     </span>
     
    <span class="float-right px-2">

        <div class='label'> &copy;
                2018-2024 EduBestu Ltd. </div>
    </span>
   
</div>