import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../tag/header.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { UsrhdrService } from '../usrhdr.service';


@Component({
  selector: 'app-chg-pwd',
  templateUrl: './chg-pwd.component.html',
  styleUrls: ['./chg-pwd.component.scss']
})
export class ChgPwdComponent implements OnInit {
  ch: any;
  cpForm:FormGroup;
  submitted:boolean;
  usrNum: any;
  site:string;
  
  
  constructor(private router: Router, private route: ActivatedRoute,private cs: ClientInfoService,private chServe:UsrhdrService ,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
   
    this.route.params.subscribe( params => {
      this.site= params['site'];
      this.usrNum= params['usrNum'];
    this.chServe.getUserByNum(this.usrNum).subscribe( res=>{
        this.ch = res;  
        console.log(this.ch);
    });
    }) ;

     this.cpForm = this.formBuilder.group({
     // firstName: ['', Validators.required],
      //lastName: ['', Validators.required],
      //userId: ['', Validators.required],
      password: ['', Validators.required],
      //board:['', ],
      //course:['', ],
      //email:['',Validators.required],
      //phone:['', ]
    });


}
 

  onSubmit(){

    if (this.cpForm.invalid) {
      alert("error"); 
      return;
    }

    this.ch.password= this.cpForm.controls.password.value, 
   this.chServe.changePwd(this.ch).subscribe(data => {
     console.log(data);
         if((data as any).status === 200) {
        // this.userDetail = (data as any).userDetail;
        this.cs.addMsg((data as any).message+ " " +(data as any).result);   
          this.router.navigate([""]);
         }else {
          alert((data as any).message);
         }
          
      });
   
  
  }

}
