
export class UserRole {
  id:number;
  userId: string;
  headerId:number;
  role: string;
  }




import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,Subject} from 'rxjs';
import { HttpHeaders,HttpResponse } from '@angular/common/http';
import { ClientInfoService } from '../shared/client-info.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    //'Authorization': 'my-auth-token',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'HEAD,GET,POST,PUT,PATCH,DELETE',
    responseType: 'text',
  })
  };

@Injectable({
  providedIn: 'root'
})


export class UsrroleService {


  baseUrl: string;
  
  constructor(private http: HttpClient,private cs:ClientInfoService) {
    this.baseUrl= this.cs.getRoot()+ "/rs/ur-v2";
   }



 get(id: number): Observable<any> {
  return this.http.get(`${this.baseUrl}/usrNum/${id}`);
}


getAllUserRoleList(headerId:number): Observable<any> {
  return this.http.get(`${this.baseUrl}/list/role`);
}


getUserRoleList(headerId:number): Observable<any> {
  return this.http.get(`${this.baseUrl}/list/role/${headerId}`);
}


getGroupRoleList(groupName:string): Observable<any> {
  return this.http.get(`${this.baseUrl}/list/role/groupName/${groupName}`);
}


getConsolidatedGroupRoleList(groupName:string): Observable<any> {
  return this.http.get(`${this.baseUrl}/list/role/consolidatedGroupName/${groupName}`);
}

update(id: number, value: any): Observable<Object> {
 return this.http.put(`${this.baseUrl}/role/${id}`, value,httpOptions);
}

create(ur: Object): Observable<Object> {
 return this.http.post(`${this.baseUrl}`, ur);
}

 

}



