import { Component, OnInit } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';

@Component({
  selector: 'app-featurete',
  templateUrl: './featurete.component.html',
  styleUrls: ['./featurete.component.scss']
})
export class FeatureteComponent implements OnInit {
  images: string[];
  sp: any;
  
  i:number;
  j:number;

  get rootUrl() {return  this.cs.getRoot() };

  get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get userGroup() { return this.cs.getUserGroup()};

  headerId:number;
  headerObj:any;
  constructor(public cs:ClientInfoService, private ds: BoardDataService,private fd:FixedDataService) { }

  ngOnInit() {
  
  let key =  this.cat + "~differentiator";
  this.ds.getCfgData(this.site,this.board,this.course,"cfg",key).subscribe(data => { 
    this.sp= data;
    });

}

  ngOnInitDelete() {

    //this.sp= this.fd.getMarketData(this.site);
    //selling points
    
  
    //important--? type is not cfg but home.. idea is cfg is anyway new file now hence type could be category to differntiate??
    this.ds.getCfgHeaders(this.site,this.board,this.course,this.cat,'featurete' ).subscribe(data => { 
      this.headerObj= (data as any).dataList[0]
      if( this.headerObj){
      this.headerId= (data as any).dataList[0].id;
    

      
    this.ds.getSiteQuestions(this.site,this.board,this.course,'cfg',this.subject,this.headerId).subscribe(data => {
    this.sp= data;
    });
  }
  });

    }

     

  getRouteUrl(category:string, extLink: string): string{
  return this.cs.getRouteUrl(category, extLink);
  }


}

  




