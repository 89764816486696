import { Component, OnInit, Input } from '@angular/core';
import { ClientInfoService } from '../client-info.service';
import {LowerCasePipe} from '@angular/common';
import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs';
import { SafePipe } from '../safe.pipe';
import { ExtraHdrService } from '../../gen/extra-hdr.service';

@Component({
  selector: 'app-url-shower',
  templateUrl: './url-shower.component.html',
  styleUrls: ['./url-shower.component.scss']
})
export class UrlShowerComponent implements OnInit {
  @Input()  extLink: string;
  @Input()  title: string;
  @Input()  format:any;
   showTextEditor:boolean; 
  @Input() headerObj:any;

  //iurl= "http://localhost/etl/image/83592572_122572735722042_8610180463449918370_n.jpg";
 // iurl= "https://www.economist.com/img/b/800/800/90/sites/default/files/cf_images/images-magazine/2020/11/28/SQ/20201128_SQ_US.jpg"
  //iurl2= "https://drive.google.com/file/d/1RVOGkKW9RkyqbafzUy9bfd3MyAfWQ-MX/edit";
  
  contentType: string;
  isExtrnlLink:boolean;
  link:string;
  showPdf :boolean;
  showImg:boolean;
  showHtml:boolean = true;
  showText:boolean = false;intVidUrl: any;
;
  showVid:boolean;
  showCmntView:boolean;
  startPage : number;
  get rootUrl() { return this.cs.getRoot()};
  get showEdit() {return this.cs.enable('textEdtr', 'allowEdit') };

  constructor(private cs:ClientInfoService, private lowerCasePipe: LowerCasePipe ) { }

 
  ngOnInit(): void {
    
    if(this.extLink){
    this.processExtLink(this.extLink,this.title);
    }
  }

  
  
  ngOnChanges() {
    this.processExtLink(this.extLink,this.title);
    
  }


/*
  getStartPage(extra:string){
    //console.log(extra + " hi");
       let extraJson = JSON.parse(extra);
    if(extraJson){
      //console.log(extraJson.startPage + " hey");
    return extraJson.startPage;
    } else{
    return 1;
 
    }
  }  
*/



  processExtLink(extLink:string,title:string){
    this.setContentType(extLink);
    this.makeAbsoluteUrl(extLink);
  }



  setContentType(extLinkI:string){
    if(!extLinkI){
      return null;
    }

    if(this.headerObj && this.headerObj.hdrFormat =='cmntView'){
      this.contentType= "cmnt";
      this.showCmntView= true;
      return; 
    }

    if(this.headerObj && this.headerObj.hdrFormat =='intVid'){
      this.contentType= "intVid";
      this.intVidUrl=  this.headerObj.extUrl;
       return; 
    }

    if(this.headerObj && this.headerObj.hdrFormat =='text'){
      this.contentType= "text";
     // this.intVidUrl=  this.headerObj.extUrl;
       return; 
    }

    let extLink= this.lowerCasePipe.transform(extLinkI);
   // alert(extLink);
    
   
 
   if(this.isValidImageUrl(extLink) || this.format== 'IMG' ){
    this.contentType= "IMG";
    this.showImg= true;
    return;
  }
   //console.log(this.headerObj);
   //alert('following working ' + this.headerObj.hdrFormat);
   //&& this.headerObj.hdrFormat =='pdfUrl'
    
   if(this.headerObj && this.headerObj.extra){
    this.startPage = this.cs.eh.startPage(this.headerObj.extra);
   }
   if(this.isValidPdfUrl(extLink) ){
    this.contentType= "PDF";
    this.showPdf= true;
    return;
    }  
   

    if(this.isValidVidUrl(extLink)){
    this.contentType= "VID";
    this.showVid= true;
    return;
    }  
   
    if(this.isValidExtUrl(extLink)){
      this.contentType= "EXT";
      //this.showImg= true;
      return;
    }
   

      if(this.isValidHtmlUrl(extLink)){
      this.contentType= "HTML";
      return;
      } 
      
      if(this.isValidTextUrl(extLink)){
        this.contentType= "TEXT";
        return;
        } 

      this.contentType= "OTHR" 

    }
 
    isValidExtUrl(str: string) {
      if ( typeof str !== 'string' ) return false;
      return !!str.match('^http');
    
    }

    
  isValidHtmlUrl(str: string) {
    if ( typeof str !== 'string' ) return false;
    return !!str.match(/\w+\.(html|htm|txt|ts)$/gi);
  
  }

   
  isValidTextUrl(str: string) {
    if ( typeof str !== 'string' ) return false;
    return !!str.match(/\w+\.(txt|ts|json)$/gi);
  
  }


  isValidImageUrl(str){
      if ( typeof str !== 'string' ) return false;
     //return str.includes('.jpg');
      return str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
  }

  isValidPdfUrl(str){
    if ( typeof str !== 'string' ) return false;
    return !!str.match(/\w+\.(pdf)$/gi);
  }

  isValidVidUrl(str){
    if ( typeof str !== 'string' ) return false;
    return !!str.match(/\w+(youtube)$/gi);
  }

  
  makeAbsoluteUrl(str){
    //alert(str);
   if(str && (this.lowerCasePipe.transform(str)).includes('http')){
    this.isExtrnlLink= true;
    this.link = str;
    //console.log(this.link)
    }
    else{
    this.link=  this.rootUrl + str;
     }
    this.link=  this.link.replace('c:/ES/upload' ,'etl').replace('opt/ES/upload' ,'etl');  
    }
    
 }
    


