import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-test-ansr',
  templateUrl: './test-ansr.component.html',
  styleUrls: ['./test-ansr.component.scss']
})
export class TestAnsrComponent implements OnInit {
  list: any;
  h: any;

  get cat(){return this.cs.getCat()}

  constructor(private router: Router,private cs:ClientInfoService,private route: ActivatedRoute,) {

    
     
    const navigation = this.router.getCurrentNavigation();

    console.log(navigation);
    const state = navigation.extras.state as{
     h: '',
     list: ''
     }
   ;
   
   this.h= state.h;
   this.list= state.list;
   console.log(state.h);

   }

  ngOnInit(): void {

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
      ).subscribe((event) => {
          this.cs.updateParms(this.route);
     });


  }



}
