import { Component, OnInit } from '@angular/core';
import { ExamDataService } from '../../exam-data.service';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { HttpHlprService } from '../../shared/http-hlpr.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Component({
  selector: 'app-edubestu',
  templateUrl: './edubestu.component.html',
  styleUrls: ['./edubestu.component.scss']
})
export class EdubestuComponent implements OnInit {
questions: Object ;
 baseUrl = "/qd";

  constructor(private data: ExamDataService, private http: HttpClient, private h:HttpHlprService) { }

  ngOnInit() {
  this.list(674).subscribe(dataList => {
        this.questions = dataList;
    
    });
  }
  list(hdrId: number): Observable<any> {
//filter it based on role and others....like student/teacher
    return this.http.get(`${this.h.ba}/qd/list/${this.h.si}/${this.h.bo}/${this.h.co}/${this.h.clse}/${this.h.ty}/${this.h.su}/${hdrId}`).pipe(
     catchError(this.h.handleError('page-getter service', [])));
    }
  
}
