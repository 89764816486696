import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from './message.service';
import { Comment } from './comment';
import { ApiResponse } from './api-response';

import { Observable, of, Subject } from 'rxjs';
import { environment } from './../environments/environment';
import { isPlatformBrowser, Location } from '@angular/common';
import { ClientInfoService } from './shared/client-info.service';
import { Cacheable } from 'ngx-cacheable';
import { FixedDataService } from './shared/fixed-data.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token',
    'Access-Control-Allow-Origin': '*',
  })
};

const httpOptionPlainHtml = {

  headers: new HttpHeaders({
    'Content-Type': 'text/html',
    'Authorization': 'my-auth-token',
    'Access-Control-Allow-Origin': '*',
  })
  ,

  'response-Type': 'text',

};


const httpOptionsNoCache = {
  headers: new HttpHeaders({
    'ResponseType': 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  })
};


const site = "Exam Steppers";let count: number = 0;


@Injectable({
  providedIn: 'root'
})

//fix localhost issue
export class BoardDataService {

  public static updateDhwHdrId: Subject<number> = new Subject();

  headerId: number;
  rootUrl: string;
  
 
  // readonly rootUrl = environment.rootUrl;
  //readonly intraUrl = environment.intraUrl;
  //readonly localUrl = environment.localUrl;
  //readonly rootUrl = 'http://localhost';
  //readonly rootUrl = 'https://examsteppers.com';

  constructor(private loc: Location, private http: HttpClient, private messageService: MessageService, private cs: ClientInfoService, private fd: FixedDataService) {
    
    this.rootUrl = this.cs.getRoot();
    
  }


  getSite() {
    return site;
  }
  /* good example for future...
  window.location.hostname = example.com
  window.location.host = example.com:80
  window.location.protocol = https
  window.location.port = 80
  window.location.pathname = /a/b/c
  window.location.href     = https://example.com:80/a/b/c
  
  const angularRoute = this.loc.path();
    const url = window.location.href;
  
    const domainAndApp = url.replace(angularRoute, '');
  
  */

  getList(site: string) {
    //var base ;
    //base must have www.
    var base = this.cs.getRoot();

    
    if(this.cs.bw.hasWindow()) {
    var url = window.location.href;

    
    if (url.includes('www.')) {
      base = base;
      //alert("url with www:" + url);
    } else {
      base = base.replace('www.', '');
      //alert("url without www:" + base);
    }
    console.log("URL for config file: " + base);

    if (url.includes('localhost')) {
      console.log("list-data CORS issue at localhost hence not trying");
      return null;
    }
  
    return this.http.get(base + '/etl/list-data.txt', httpOptions)
      .pipe(
        catchError(this.handleError('getList', []))
      );
    }
  }


  getCatFeatures(site: string, cat: string, board: string, course: string) {
    //this.messageService.add('BoardDataService: fetching subjects');
    return this.http.get(this.rootUrl + '/rs/s/' + site + '/' + cat + '/' + board + '/' + course + '/').pipe(
      catchError(this.handleError('getCatFeatueres', []))
    );
  }


  //my method
  firstClick() {
  return console.log('clicked ok');
  }




  getThought() {
    // this.messageService.add('ExamDataService: fetching Thought of the day');
    //{responseType: 'text'}
    return this.http.get(this.rootUrl + '/rs/thought/today', { responseType: 'json' }).pipe(
      catchError(this.handleError('getThought', []))
    );



  }

  getVideo() {
    // this.messageService.add('ExamDataService: fetching Video of the day');
    return this.http.get(this.rootUrl + '/rs/thought/vid', { responseType: 'json' }).pipe(
      catchError(this.handleError('getVideo', []))
    );
  }



  getDtlOfTheDay(h:any, type:string) {
    return this.http.get(this.rootUrl + `/rs/thought/dtlOfTheDay/${h.si}/${h.bo}/${h.co}/${type}`, { responseType: 'json' }).pipe(
      catchError(this.handleError('getStory', []))

    );
  }


  getDailyDetail(h:any, type:string,subject:string) {
    return this.http.get(this.rootUrl + `/rs/thought/dailyDetail/${h.si}/${h.bo}/${h.co}/${type}/${subject}`, { responseType: 'json' }).pipe(
      catchError(this.handleError('getDailyDetail', []))

    );
  }


  getDuk() {
    return this.http.get(this.rootUrl + '/rs/thought/duk', { responseType: 'json' }).pipe(
      catchError(this.handleError('getDuk', []))
    );
  }

  
  getQuiz() {
    return this.http.get(this.rootUrl + '/rs/thought/quiz', { responseType: 'json' }).pipe(
      catchError(this.handleError('getQuiz', []))
    );
  }



  getUsers() {
    //return this.http.get('http://reqres.in/api/users')
  }

  getUsers2() {
    //return this.http.get('http://reqres.in/api/users?page=2')
  }

  getQuestions(x: number) {
    return this.http.get(this.rootUrl + '/rs/qstnppr/raw/' + x)
  }

  getQuestionsRaw(x: number) {
    return this.http.get(this.rootUrl + '/rs/qstnppr/' + x)
  }

  // for Percentage Booster subjects...

  getSubjects(site: string, board: string, course: string, offering: string) {
    //this.messageService.add('ExamDataService: fetching subjects');
    return this.http.get(this.rootUrl + '/rs/pbstr/' + site + '/' + board + '/' + course + '/' + offering + '/subjects').pipe(
      catchError(this.handleError('getSubjects', []))
    );

  }

  getPyqSubjects() {
    return this.http.get(this.rootUrl + '/rs/qstnppr/subjects/ICSE')

  }

  getSubSubjects(board: string) {
    return this.http.get(this.rootUrl + '/rs/bi/subjects/' + board)

  }



  // for core subjects...  !='dhw' if more than introduce section every where??
  getSiteSubjects(site: string, board: string, course: string, offering: string) {

    if ((offering && offering == "cfg")) {
      // this.messageService.add('CfgService: fetching subjects');
      return this.http.get(this.rootUrl + '/rs/cfg/' + site + '/' + board + '/' + course + '/' + offering + '/csubjects/').pipe(
        catchError(this.handleError('getSubjects1', []))
      );
    }

    if (!this.fd.clsSecBased(offering)) {
      
      //this.messageService.add('BoardDataService: fetching subjects');
      return this.http.get(this.rootUrl + '/rs/s/' + site + '/' + board + '/' + course + '/' + offering + '/csubjects/').pipe(
        catchError(this.handleError('getSubjects2', []))
      );
    } else {

      let owner = this.cs.getSignedUser();
      //assumption q converted to t for individual record
      if (offering.includes('-t') && this.cs.getUserGroup().includes('teacher')) {
        owner = "owner";
      }
      if (offering == 'tshr-doc') {
        owner = "owner";
      }


      //this.messageService.add('BoardDataService: fetching subjects with Class-section');
      return this.http.get(this.rootUrl + '/rs/t/' + site + '/' + board + '/' + course + '/' + this.cs.getuserClsSec() + '/' + offering + '/' + owner + '/csubjects/').pipe(
        catchError(this.handleError('getSubjects3', []))
      );
    }
  }


  getSiteHWSubjects(site: string, board: string, course: string, clsSec: string, offering: string, user: string) {
    //this.messageService.add('BoardDataService: fetching subjects');
    return this.http.get(this.rootUrl + '/rs/t/' + site + '/' + board + '/' + course + '/' + clsSec + '/' + offering + '/' + user + '/csubjects/').pipe(
      catchError(this.handleError('getHWSubjects', []))
    );

  }


  /*
  getTagSubjects(site:string, board:string, course:string, offering:string) {
  this.messageService.add('BoardDataService: fetching subjects');
      return this.http.get('    '+ site +'/' + board  + '/' + course +'/'+ offering +'/tsubjects/').pipe(
        catchError(this.handleError('getSubjects', []))
      );
  
    }
  */
  @Cacheable()
  getMenuQuestions(site: string) {
    if (site == 'esa') {
      return this.http.get(this.rootUrl + '/rs/s/esa/b/c/pyq/s/contents/27');
      //+ site +'/' + board  + '/' + course +'/'+ offering +'/'+ subject + '/contents/'+ x);
    } else {
      return this.http.get(this.rootUrl + '/rs/s/eba/b/c/pyq/s/contents/28');
    }
  }




  @Cacheable()
  getSiteQuestions(site: string, board: string, course: string, offering: string, subject: string, x: number) {
    if (offering == "cfg") {
      return this.getCfgQuestions(site, board, course, offering, subject, x);
    }
    return this.http.get(this.rootUrl + '/rs/s/' + site + '/' + board + '/' + course + '/' + offering + '/' + subject + '/contents/' + x);
  }

  

  @Cacheable()
  getCfgQuestions(site: string, board: string, course: string, offering: string, subject: string, x: number) {
    return this.http.get(this.rootUrl + '/rs/cfg/' + site + '/' + board + '/' + course + '/' + offering + '/' + subject + '/detail/' + x);
  }


  /*
  getSite1Headers(site:string, board:string, course:string, offering:string, subject:string) {
      return this.http.get(this.rootUrl + '/rs/qstnhdr/'+ subject + '/hwh');
  
    }
  */
  //please note... pbstr doesn't give ehader due to qstn/tag switch? perhaps hence use pbstr.
  //please note to avoid extra subject listing of catList we are adding offering as subject in parent type-cfg

  //@Cacheable()
  getCatHeaders(site: string, board: string, course: string, offering: string, subject: string) {
    //optimize later.
    //console.log(offering + " // "+ subject); 
    subject = offering;
    offering = "cfg";
    return this.getCfgHeaders(site, board, course, offering, subject)
    //for Cat/ConfigHeader - offering is hardcoded as "cfg" subject =offering
    //return this.http.get(this.rootUrl + '/rs/s/'+ site +'/' +  board  + '/' + course +'/'+ "cfg" +'/cheaders/' + offering);
    //return this.http.get('http://localhost:8080/rs/s/esa/CBSE/XII/cfg/cheaders/sellingpoints');
  }

  getCfgHeaders(site: string, board: string, course: string, offering: string, subject: string) {
    if (!this.fd.clsSecBased(offering)) {
      return this.http.get(this.rootUrl + '/rs/cfg/' + site + '/' + board + '/' + course + '/' + offering + '/headers/' + subject);
    } else {
      //this.messageService.add('BoardDataService: fetching Chapter List with Class-section');
      let owner = this.cs.getSignedUser();
      //assumption q converted to t for individual record
      if (offering.includes('-t') && this.cs.getUserGroup().includes('teacher')) {
        owner = "owner";
      }
      return this.http.get(this.rootUrl + '/rs/t/' + site + '/' + board + '/' + course + '/' + this.cs.getuserClsSec() + '/' + offering + '/' + owner + '/cheaders/' + subject).pipe(
        catchError(this.handleError('getCfgHeaders', []))
      );
    }

  }

  //latest
  @Cacheable()
  getCfgData(site: string, board: string, course: string, offering: string, subject: string) {
    //offering= "cfg";
    //subject=offering;
    return this.http.get(this.rootUrl + '/rs/cfg/' + site + '/' + board + '/' + course + '/' + offering + '/ftrData/' + subject);
    
    //for Cat/ConfigHeader - offering is hardcoded as "cfg" subject =offering
    //return this.http.get(this.rootUrl + '/rs/s/'+ site +'/' +  board  + '/' + course +'/'+ "cfg" +'/cheaders/' + offering);
    //return this.http.get('http://localhost:8080/rs/s/esa/CBSE/XII/cfg/cheaders/sellingpoints');
  }


  @Cacheable()
  getCfgComboData(site: string, board: string, course: string, offering: string, subject: string) {
    //offering= "cfg";
    //subject=offering;
  return this.http.get(this.rootUrl + '/rs/cfg/' + site + '/' + board + '/' + course + '/' + offering + '/comboData/' + subject);
  }

  

  @Cacheable()
  getCfgComboDataNew(site: string, board: string, course: string, offering: string, subject: string) {
    //offering= "cfg";
    //subject=offering;
  return this.http.get(this.rootUrl + '/rs/cfg/' + site + '/' + board + '/' + course + '/' + offering + '/comboDataNew/' + subject);
  }

  getSiteHeaders(site: string, board: string, course: string, offering: string, subject: string) {
    
    let cheaders = "cheaders";
    
    if(this.cs.getCat() =="astore"){
      cheaders = cheaders + "-" + this.cs.getCat(); 
    }

    if (offering == "cfg") {
      return this.getCfgHeaders(site, board, course, offering, subject);
    }
    // console.log("hello" + offering);
    if (!this.fd.clsSecBased(offering)) {
      return this.http.get(this.rootUrl + '/rs/s/' + site + '/' + board + '/' + course + '/'  + this.cs.getuserClsSec() + '/'+ offering + '/owner/' + cheaders + '/' + subject);
    } else {
      //this.messageService.add('BoardDataService: fetching Chapter List with Class-section');
      let owner = this.cs.getSignedUser();
      //assumption q converted to t for individual record
      if ((offering.startsWith("c") || offering.includes('-t')) && (this.cs.getUserGroup().includes('teacher')||this.cs.getUserGroup().includes('paren1t'))) {
        console.log("I am assigning owner "+ offering + offering.includes('-t'));
        owner = "owner";
      }
      if (offering == 'tshr-doc') {
        owner = "owner";
      }
      //very importtant as assigner should be for the other overriding record for owner only
      // actual tasgn- may be for owner or not u need to decide in backend. 
      if (offering == 'tasgn-pbstr') {
        owner = this.cs.getSignedUser();
      }
      
      // console.log( this.cs.getuserClsSec() +owner );
      return this.http.get(this.rootUrl + '/rs/s/' + site + '/' + board + '/' + course + '/' + this.cs.getuserClsSec() + '/' + offering + '/' + owner + '/cheaders/' + subject).pipe(
        catchError(this.handleError('getSiteHeaders', []))
      );
    }

  }

  getHdrObj(id:number){
    
    if(isNaN(+id)){ return of(null)}
    if(this.cs.getOffering()?.includes("cfg") ){
      return this.http.get(this.rootUrl + '/rs/cfg/hdrObj/'+ id).pipe(

        catchError(
          this.handleError('getHdrObj+ id ', [id])
          )
      );
      
    } else{
    return this.http.get(this.rootUrl + '/rs/s/hdrObj/'+ id).pipe(
      catchError(
        this.handleError('getHdrObj', []))
    );
   }
  }
   
  getSiteHeaderById(site: string, board: string, course: string, offering: string, subject: string, headerId: number) {
    return this.http.get(this.rootUrl + '/rs/s/' + site + '/' + board + '/' + course + '/' + offering + '/cheaders/' + subject + '/' + headerId).pipe(
      catchError(this.handleError('getSiteHeadersById', []))
    );
  }

  

  //true headerId... 
  // Note this is handled at site level hence not c or t!!
  getTypeListByHeaderId(site: string, board: string, course: string, offering: string, subject: string, headerId: number) {
    return this.http.get(this.rootUrl + '/rs/s/typeListforHeader/' + site + '/' + board + '/' + course + '/' + offering + '/' + subject + '/' + headerId);

  }


  getSiteHeaderByNum(headerId: number) {
    return this.http.get(this.rootUrl + '/rs/s/' + 'site' + '/' + 'board' + '/' + 'course' + '/' + 'offering' + '/cheaders/' + 'subject' + '/' + headerId);
  }

  getWrkTagHeaderByNum(headerId: number, signedUser: string) {
    return this.http.get(this.rootUrl + '/rs/s/' + 'site' + '/' + 'board' + '/' + 'course' + '/' + 'offering' + '/wrkTag/' + 'subject' + '/' + headerId + '/' + signedUser);
  }



  //unit is qstnhdr.id to keep it simple in UI
  getVidData1(type: string, unit: string) {
    return this.http.get(this.rootUrl + '/rs/t/' + 'detailByUnit/' + type + '/' + unit);
  }

  getVidData(site: string, board: string, course: string, offering: string, subject: string, type: string, unit: string) {
    return this.http.get(this.rootUrl + '/rs/s/' + site + '/' + board + '/' + course + '/' + offering + '/' + subject + '/detailByUnit/' + type + '/' + unit);
  }



  //pass qstn-headerId/unit ID and get all related header for a type,headerId combo
  getUnitHeaders(type: string, unit: string, user?: string) {
    return this.http.get(this.rootUrl + '/rs/s/' + 'byHeaderId/' + type + '/' + unit + '/' + user);
  }


  processDraft(header: any): Observable<Object> {
    // console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/processDraft', header).pipe(
      catchError(this.handleError('processDraft', []))
    );
  }

  parse(header: any): Observable<Object> {
    // console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/parse', header).pipe(
      catchError(this.handleError('processDraft', []))
    );
  }

  finalizeDraft(header: any): Observable<Object> {
    //console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/finalizeDraft', header);
  }


  
  onDemand(header: any): Observable<Object> {
    //console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/transform', header);
  }

  copyGDUser(header: any): Observable<Object> {
    //console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/copyGDUser', header);
  }

  
  GDAll(): Observable<Object> {
    //console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/GDAll',null);
  }


  
  clearCache(key:string, type:string): Observable<Object> {
    //console.log(header);
    return this.http.post(this.rootUrl + `/rs/cache/clear/${type}/${key}`, "");
  }



  hdrSetTrial(header: any): Observable<Object> {
    // console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/hdrSetTrial', header).pipe(
      catchError(this.handleError('hdrSetTrial', []))
    );
  }
  
  hdrSetCrtr(header: any): Observable<Object> {
    // console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/hdrSetCrtr', header).pipe(
      catchError(this.handleError('hdrSetCrtr', []))
    );
  }

  

  bstrSetTrial(header: any): Observable<Object> {
    // console.log(header);
    return this.http.post(this.rootUrl + '/rs/th/bstrSetTrial', header).pipe(
      catchError(this.handleError('hdrSetTrial', []))
    );
  }
  
  bstrSetCrtr(header: any): Observable<Object> {
    // console.log(header);
    return this.http.post(this.rootUrl + '/rs/th/bstrSetCrtr', header).pipe(
      catchError(this.handleError('hdrSetCrtr', []))
    );
  }

  promoteGate(header: any): Observable<Object> {
    //console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/promoteGate', header);
  }

  checkMerge(header: any): Observable<Object> {
    //console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/checkMerge', header);
  }


  processMerge(header: any): Observable<Object> {
    //console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/processMerge', header);
  }

  cleanupQstn(header: any): Observable<Object> {
    console.log(header);
    return this.http.post(this.rootUrl + '/rs/qh/cleanupQstn', header);
  }

  
  redigitize(header: any): Observable<Object> {
    //console.log(header);
    return this.http.post(this.rootUrl + `/rs/qh/redigitize/${header.id}`, header);
  }

  
  checkImage(header: any): Observable<Object> {
    //console.log(header);
    return this.http.post(this.rootUrl + `/rs/qh/checkImage/${header.id}`, header);
  }


  ///byQhdr        

  getTagHeaders(site: string, board: string, course: string, offering: string, subject: string) {
    return this.http.get(this.rootUrl + '/rs/s/' + site + '/' + board + '/' + course + '/' + offering + '/theaders/' + subject);

  }


  @Cacheable()
  getBtnMap(site:string) {
    return this.http.get(this.rootUrl + '/rs/cfg/fixedData/btnMap/'+site )
    .pipe(
      catchError(err => {
       //console.log('Handling error locally and rethrowing it...', err);
       if(true || err.status in[500,503]){'btnMap load error - '+ this.cs.addMsg(err.statusText,"warning");
       this.cs.loadedBtnMap= true; 
      }
        return of([]);
    })
    )

    //return this.http.get(this.rootUrl + '/rs/c/fixedData/btnMap')
    // note .. normal below doesn't have btnmap hence don't use
    //return this.http.get(this.rootUrl + '/rs/cfg/cmn/cmn/cmn/cfg/btnMap/detail/123541');
  }

  getHWSubjects() {
    return this.http.get(this.rootUrl + '/rs/hw/subjects/ICSE')

  }


  getHtmlFile(url: any) {
    return this.http.get(this.rootUrl + url, { responseType: 'text' })

  }

  getTextFile(url: any) {
    return this.http.get(`${this.rootUrl}/rs/files/file/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getClsSec()}/${this.cs.getOffering()}/${this.cs.getSubject()}/${this.cs.getChapter()}`, { responseType: 'text' }).pipe(
      catchError(this.handleError('fileData', []))
    );


    //return this.http.get( this.rootUrl +  url, {responseType: 'text'})

  }

  postTextFile(fileData: any): Observable<Object> {
    return this.http.post(`${this.rootUrl}/rs/files/file/${this.cs.getSite()}/${this.cs.getBoard()}/${this.cs.getCourse()}/${this.cs.getClsSec()}/${this.cs.getOffering()}/${this.cs.getSubject()}/${this.cs.getChapter()}`, fileData).
      pipe(
       catchError(this.handleError('fileData', []))
      );

  }
  //note: if {responseType: 'text'} then u don't get formatted message so msg will not work

  getSignedUserDlt() {

    return this.cs.getSignedUser();

    //console.log(this.rootUrl + "inside signedUser of boarddata"  )//alert("inside signed User method");

    /*
    this.messageService.add('ExamDataService: fetching signed user');
    return this.http.get(this.rootUrl + '/faces/rs/pbstr/signedUser', httpOptionsNoCache).pipe(
    catchError(this.handleError('getSignedUser', []))
        );
    */
  }

  getSignedUserNew() {
    return this.cs.getSignedUser();
  }

  getUserGroup() {
    //console.log(this.rootUrl + "inside userGroup of boarddata"  )//alert("inside signed User method");
    // this.messageService.add('ExamDataService: fetching signed user');

    return this.http.get(this.rootUrl + '/faces/rs/auth/userGroup', httpOptionsNoCache).pipe(
      catchError(this.handleError('getSignedUser', []))
    );
  }


  addComment(commentPayload): Observable<ApiResponse> {
    //alert("inside add comments method");
    return this.http.post<any>(this.rootUrl + '/rs/pbstr', commentPayload).
      pipe(
        catchError(this.handleError('addComment', []))
      );

  }

  addUser(userPayload): Observable<ApiResponse> {
    //alert("inside Board data -add User method");
    return this.http.post<any>(this.rootUrl + '/rs/us/add', userPayload).
      pipe(
        catchError(this.handleError('addUser', []))
      );

  }



  changeBstrSet(board: string, bstrSetId: string) {
    return this.http.get(this.rootUrl + '/rs/pbstr/chgbstrset/' + board + '/' + bstrSetId)

  }

  /*
  changeBstrSetPut(board:string, bstrSetId:string) :Observable<HttpResponse<BstrSet>> {
      return this.http.put(this.rootUrl + '/rs/pbstr/chgbstrset/'+board +'/'+ bstrSetId,bstrSet,httpOptions).pipe(
        catchError(this.handleError('updateBstr', bstrSet))
      );
  
    }
  */





  getTagQuestions(x: number) {
    return this.http.get(this.rootUrl + '/rs/pbstr/bytag/' + x)
  }


  getPyqQuestions(x: number) {
    return this.http.get(this.rootUrl + '/rs/pbstr/bytag/' + x)
  }



  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  //count:number;
  private   handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

     count = count +1; 
    
     if(error.status== 503 && count == 3){ this.cs.addMsg('internet/backend problem,try again',"warning");}


      //alert("inside error");
      // TODO: send the error to remote logging infrastructure
      //console.error(error); // log to console instead
      //console.error(error.statusText);
      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);
    //  if(this.count==5){
    //}  
       // this.log(`${operation} failed`);
       //this.cs.addMsg(`${operation} failed : ${error.statusText}`);
      //   this.count= this.count + 1;
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(`Board DataService: ${message}`, message);
   // this.messageService.add(`Board DataService: ${message}`);
  
  }

}
