import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders,HttpResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Comment } from '../comment';
import { ApiResponse } from '../api-response';
import { Observable, of } from 'rxjs';
import { environment } from './../../environments/environment';
import { MessageService } from '../message.service';
import { ClientInfoService } from './client-info.service';
import { FixedDataService } from './fixed-data.service';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token'
  })
  };



@Injectable({
  providedIn: 'root'
})
export class TagService {
  rootUrl:string;
  constructor(private http: HttpClient,private messageService: MessageService,
    private cs:ClientInfoService,private fd:FixedDataService
) {
    this.rootUrl= this.cs.getRoot();
   }
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }


    /** Log a HeroService message with the MessageService */
      private log(message: string) {
        this.messageService.add(`ExamDataService: ${message}`);
      }

      //legacy of comment
      addComment(commentPayload) : Observable<ApiResponse> {
        //alert("inside add comments method");
          return this.http.post<any>(this.rootUrl + '/rs/pbstr',commentPayload).
             pipe(catchError(this.handleError('addComment', []))
          );
        }



      addTagger(tagPayload) : Observable<ApiResponse> {
        //alert("inside add comments method");
          return this.http.post<any>(this.rootUrl + '/rs/pbstr',tagPayload).
             pipe(catchError(this.handleError('addComment', []))
          );
        }

        addBstrQstn(boosterPayload) : Observable<ApiResponse> {
          //alert("inside add comments method");
            return this.http.post<any>(this.rootUrl + '/rs/pbstr/qstn',boosterPayload).
               pipe(catchError(this.handleError('addComment', []))
            );
          }

          tagBased(offering:string):boolean{
          return this.fd.tagBased(offering);  
          }

          tagBasedForCreate(offering:string):boolean{
            return this.fd.tagBasedForCreate(offering);  
            }

            
          makeCurrent( payload){
           return this.http.post<any>(this.rootUrl + '/rs/pbstr/current/'+payload.parentId ,payload).
               pipe(catchError(this.handleError('addComment', []))
            );

          }

          addHW( payload){

            return this.http.post<any>(this.rootUrl + '/rs/pbstr/addHW/'+payload.parentId ,payload).
               pipe(catchError(this.handleError('addComment', []))
            );

          }


    }
