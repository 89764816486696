import { Component, Input, OnInit } from '@angular/core';
import { BtnEnablerService } from '../../shared/btn-enabler.service';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';
import { Detail } from '../detail';
import { QstnDtlService } from '../qstn-dtl.service';

@Component({
  selector: 'app-digitize',
  templateUrl: './digitize.component.html',
  styleUrls: ['./digitize.component.scss']
})
export class DigitizeComponent implements OnInit {
  @Input() q: Detail;
  @Input() i: number;
  @Input() h: any;
  showMkrI:number=-1;

  constructor(private qds: QstnDtlService,public qs:QstnListService, public be:BtnEnablerService) { }

  ngOnInit(): void {
  }

  digitize(q:Detail){
    this.qds.digitize(q.id).subscribe(data => {
    
       if ((data as any)) {
        this.qds.cs.addMsg("updated", 'toast');
        //this.success = true;
      } else {
        alert("failure");
        //this.success = false;
        this.qds.cs.addMsg("failure", 'warning');
        //alert("error,try later");
      }
    
    }
    );
  }

    


  split(q:Detail){

    this.qds.split(q.id).subscribe(data => {
      
      if((data as any).status==200 && (data as any).result){
        
        this.qds.cs.addMsg("splitted"+(data as any).result , 'toast');
        
      } else {
        //alert("failure");
        //this.success = false;
        this.qds.cs.addMsg("failure" +(data as any).result, 'warning');
        //alert("error,try later");
      }
    
    }
    );

  }

//idea is btn in ui  should decide what it wants to pass hence send q or h optionally not always
enable(btnId :string, q?:any,h?:any) {
return this.be.enableDtl("digitizer", btnId,q,h);

}


removeMC(q:Detail){

  this.qds.removeMCQ(q.id).subscribe(data => {
    
    if((data as any).status==200 && (data as any).result){
      
      this.qds.cs.addMsg("splitted"+(data as any).result , 'toast');
      
    } else {
      //alert("failure");
      //this.success = false;
      this.qds.cs.addMsg("failure" +(data as any).result, 'warning');
      //alert("error,try later");
    }
  
  }
  );

}
}

