
 <!--{{icrclm_mngr,digi_expert}} -->
 

<ng-container>
<span class= "px-3"> &#160; </span>  

<button  *ngIf= "(!header.owner || (header.owner == header.addUser && header.owner != signedUser) ) &&  enable('assignToMe',i,header) " class= "btn-md btn-outline-secondary border-0 badge mx-2"  (click)="changeField(header,'owner', signedUser,i)" > assign to me </button>

<button  *ngIf= "enable('makeDraft',i,header) "  class= "btn-md btn-primary badge mr-1"  (click)="processDraft(header,i)"> Make Drft </button>

<a *ngIf= "getOriginal(header,i,'orginal') && enable('showOriginal',i,header) "  href=  '{{getOriginal(header,i,"orginal")}}' class="btn-md btn-outline-secondary  badge " target= "_blank" > <span>show Original</span> 
</a>


<button  *ngIf= "enable('validateDraft',i,header) "  class= "badge mr-1"  (click)="parse(header,i)"> Parse Format </button>


<!-- first click failing hence reverting to old one try in free time
<button *ngIf= "enable('validateDraft',i,header) "  (click) = 'parsedReport(header,i)'
class="btn-md btn-primary  badge " target= "empty_blank" > <span>Parse-Report </span> </button>
-->

<a *ngIf= "header.extUrl && enable('validateDraft',i,header) "  href=  '{{parsedReport(header,i,"Report")}}'
class="btn-md btn-primary  badge " target= "_blank" > <span>Parse-Report </span> 
</a>



<button  *ngIf= "enable('onDemand',i,header) "  class= "px-2 badge  btn-success"  (click)="onDemand(header,i)"> Customized Convertor</button>


<button  *ngIf= "enable('ml2tex',i,header) "  class= "px-2 badge  btn-success"  (click)="processDraft(header,i)"> Convert to TeX</button>


<button  *ngIf= '!rootUrl.includes("localhost") && (header.extUrl?.includes("sysdata") || header.extUrl?.includes("prc")) && enable("loadToDBProd",i,header) '  class= "px-2 badge  btn-success"  (click)="processDraft(header,i)"> prod-load to DB  </button>


<button  *ngIf= 'rootUrl.includes("localhost") && (header.extUrl?.includes("system") || header.extUrl?.includes("prc")) && enable("loadToDBDev",i,header) '  class= "px-2 badge  btn-success"  (click)="processDraft(header,i)"> dev-load to DB  </button>

<span  *ngIf="enable('uploadImage',i,header)"  type= "button"  (click)="showUpload=!showUpload" class="btn-md badge mr-1">Upload Image</span>


      <app-file-upload *ngIf="showUpload" [iHeader]=header [uploadType]='"imageForHeaderFile"'>
      </app-file-upload>



<a  *ngIf= "enable('deleteAllQstn',i,header) "  class= "btn-md btn-warning  mr-1"  (click)="cleanupQstn(header,i)">Delete-AllQstns</a>



<a  *ngIf= "enable('redigitize',i,header) "  class= "btn-md btn-warning  mr-1"  (click)="redigitize(header,i)">redigitize</a>


<a  *ngIf= "enable('checkImage',i,header) "  class= "btn-md btn-warning  mr-1"  (click)="checkImage(header,i)">check Images</a>


</ng-container>

<ng-container *ngIf= 'header && (header.status =="cl"||header.status =="CL" || header.hdrFormat =="cl")' >

    <button  *ngIf= "enable('hdrSetCrtr',i,header) "  class= "btn-md btn-primary badge mr-1"  (click)="hdrSetTrial(header,i)"> Hdr Set Trial </button>

    <button  *ngIf= "enable('hdrSetCrtr',i,header) "  class= "btn-md btn-primary badge mr-1"  (click)="hdrSetCrtr(header,i)"> Hdr Set Creator</button>

</ng-container>

<ng-container *ngIf= 'header && (header.status =="ul" || header.hdrFormat =="ul")' >

    <button  *ngIf= "enable('usrSetCrtr',i,header) "  class= "btn-md btn-primary badge mr-1"  (click)="usrSetTrial(header,i)"> Usr Set Trial </button>

    <button  *ngIf= "enable('usrSetCrtr',i,header) "  class= "btn-md btn-primary badge mr-1"  (click)="usrSetCrtr(header,i)"> Usr Set Creator</button>

</ng-container>



<!--
<button  *ngIf= "!(header.extUrl) && enable('bstrSetTrial',i,header) "  class= "btn-md btn-primary badge mr-1"  (click)="bstrSetTrial(header,i)"> make Auto Bstr Set </button>
-->

<ng-container *ngIf= 'header && (header.extUrl) && (header.status =="bl"|| header.hdrFormat =="bl")' >


<button  *ngIf= "enable('bstrSetTrial',i,header) "  class= "btn-md btn-primary badge mr-1"  (click)="bstrSetTrial(header,i)"> Bstr Set Trial </button>
    
<a *ngIf= "header.extUrl && enable('validateList',i,header) "  href=  '{{parsedReport(header,i,"bstr")}}'
class="btn-md btn-primary  badge " target= "_blank" > <span>Bstr-Report </span> 
</a>

<button  *ngIf= "enable('bstrSetCrtr',i,header) "  class= "btn-md btn-primary badge mr-1"  (click)="bstrSetCrtr(header,i)"> Bstr Set Creator</button>

</ng-container>
