
   
<div *ngIf= 'slctType && slctType.includes("tgubstr")'>
     
<p *ngIf= "unsigned" class= "font-italic" >
10 minutes can boost your percentage by 10% 
</p>

</div>

<div *ngIf = "!unsigned && !slctType">

<h5 >Association id: {{grpAsoId}} 
<button  type="button" class="mx-2 btn bade btn-outline-primary border-0" (click)='associate()'>
 select & associate  </button>

 <button  type="button" class="mx-2 btn bade btn-outline-primary border-0" (click)='addASoHdr= !addAsoHdr'>
    create associate </button>
    <app-aso-hdr-crtr *ngIf="addASoHdr " [asoHdr] = 'asoHdr'> </app-aso-hdr-crtr>
</h5>

</div>


 <!--
<div *ngIf="(data$ | async)?.dataList as list; else loading">
-->
    
<div *ngIf="(data$ | async) as list; else loading">

    <ng-container *ngIf="list.length; else noItems">

    
<ng-container *ngFor="let header of segment(list,slctType); let i = index;">

    <p  class="col-12">
   <app-complex-chptr [header]=header [i] = i  >
   </app-complex-chptr>
    </p>
     
    <!--
    <app-slctr-view [list1]= segment(list,slctType)>
    </app-slctr-view>
-->
    </ng-container>
</ng-container>

    <ng-template #noItems>
        No item to list.
    </ng-template>

</div>

<ng-template #loading>
    Loading...
</ng-template>