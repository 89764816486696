
<!--
<app-headings-plain *ngIf= 'hdr' [hdr] = 'hdr' > </app-headings-plain>
   
<app-prd-intro  [ovrSub] = '"home~slides"'> </app-prd-intro>

<app-usp [ovrSub] = '"home~usp"' [forPdf] = true> </app-usp>

-->

<app-product [forPdf] = true [showSiteInfo] = true> </app-product>