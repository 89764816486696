import { Component, Input, OnInit } from '@angular/core';
import { HeaderService } from '../header.service';
import { NavbackService } from '../../gen/navback.service';
import { BackDirective } from '../../gen/back.directive';
import { BstrMkr } from './bstr-mkr';

@Component({
  selector: 'app-bstr-mkr',
  templateUrl: './bstr-mkr.component.html',
  styleUrls: ['./bstr-mkr.component.scss']
})
export class BstrMkrComponent implements OnInit {
  count: number = 5;

 constructor(public ths: HeaderService,private back:NavbackService) { }
@Input() hdr:any;
key:string= "cmpltChptrs";
refBstrId: string ;
ngOnInit(): void {
  this.refBstrId = localStorage.getItem("refBstrId") ;
}

  makeBstr(refBstrId:string,key?:string){
    let sourceIds= localStorage.getItem(key);
    this.ths.makeBstr(+refBstrId,sourceIds)
        .subscribe(
          data => {
           // console.log(data);
          this.ths.cs.addMsg( data?.result.id + " : new record added ") ; 

          },
          error => {console.log(error);
          this.ths.cs.addMsg( " request failed,check log ") ; 
    } );
  }   
    
  getSourceList(key :string){
  return localStorage.getItem(key);
  }

  // move to bstrObject later 
  makeCstmBstr(refBstrId:string,key?:string){
    let sourceIds= localStorage.getItem(key);
    let bstrMkr= new BstrMkr();
    bstrMkr.createNew = true;
    bstrMkr.srcHdrIds= sourceIds;
    bstrMkr.setCount= this.count;
    bstrMkr.refBstrId = +refBstrId;
    this.ths.makeCstmBstr(bstrMkr)
    //this.ths.makeBstr(+refBstrId,sourceIds)
        .subscribe(
          data => {
           // console.log(data);
          this.ths.cs.addMsg( data?.result.length + " : new record added ") ; 

          },
          error => {console.log(error);
          this.ths.cs.addMsg( " request failed,check log ") ; 
    } );
  }   
   
process(){
  localStorage.removeItem(this.key);
  localStorage.removeItem("refbstrId");
  this.back.back();
  
  }
  changeCount(c:number){
    this.count= 1;
  }
  
}

