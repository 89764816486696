

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { BoardDataService } from '../../board-data.service';
import { BtnEnablerService } from '../../shared/btn-enabler.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { HttpHlprService } from '../../shared/http-hlpr.service';
import { CntxtService } from '../cntxt-menu/cntxt.service';

@Component({
  selector: 'app-new-sub-mnu',
  templateUrl: './new-sub-mnu.component.html',
  styleUrls: ['./new-sub-mnu.component.scss']
})
export class NewSubMnuComponent implements OnInit {
  @Input() hdr: any;
  @Input() menuType: string;

  data$: Observable<any>;
  loading: boolean;
  showOld: boolean;

  get justSubject() { return this.cs.getJustSubject()};
  
  constructor(public cm :CntxtService, public cs: ClientInfoService, public h: HttpHlprService,
    public be: BtnEnablerService, private ds: BoardDataService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(({ cat }) => {
      //let key =   "cntxt-mnu~" + this.h.cat;
      //let key = "newSubMnu~cmn";
      
      let key = "sub-menu~cmn";
     if(this.menuType){
      key = this.menuType;
     }  
     
      this.data$ = this.ds.getCfgData(this.h.si, this.h.bo, this.h.co, "cfg", key);
    });
  }
/*
  enable(btnId: string,dtl?:any, hdr?: any, debug?: boolean) {
    let component = "subMnuHardC";
    return this.be.enableDtl(component, btnId,dtl, hdr, debug)
  }
*/

  open(m:any) {
    let url = this.cs.getRoot() + this.cs.getRouteUrl("mymkr", "list/subject");
    
    window.open(url, '_blank');
  }


  }
