import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddHeaderComponent } from './add-header/add-header.component';
import { AddDetailComponent } from './add-detail/add-detail.component';
import { TagHdrListComponent } from './taghdr-list/taghdr-list.component';
import { UpdateHeaderComponent } from './update-header/update-header.component';
import { UpdateDetailComponent } from './update-detail/update-detail.component';
import { FormsModule } from '@angular/forms';
import { CKEditorModule } from 'ckeditor4-angular';
import { DetailService } from './detail.service';
import { SharedModule } from '../shared/shared.module';
import { ListTagDtlComponent } from './list-tagdtl/list-tagdtl.component';
import { ShowDetailComponent } from './show-detail/show-detail.component';
import { ShowHeaderComponent } from './show-header/show-header.component';

import { ReactiveFormsModule} from '@angular/forms';


import { ListTemplateComponent } from './list-template/list-template.component';
import { SlctrViewComponent } from './slctr-view/slctr-view.component';
import { TagOptnComponent } from './tag-optn/tag-optn.component';
import { CrudComponent } from './crud/crud.component';
//import { DocViewComponent } from '../pages/doc-view/doc-view.component';
import { TaghdrCrudComponent } from './taghdr-crud/taghdr-crud.component';
import { OptionalModule } from '../optional/optional.module';
import { BstrMkrComponent } from './bstr-mkr/bstr-mkr.component';
import { CstmBstrMkrComponent } from './cstm-bstr-mkr/cstm-bstr-mkr.component';





@NgModule({
  declarations: [AddHeaderComponent, AddDetailComponent, TagHdrListComponent, UpdateHeaderComponent, UpdateDetailComponent, ListTagDtlComponent, ShowDetailComponent, ShowHeaderComponent, ListTemplateComponent, SlctrViewComponent, TagOptnComponent, CrudComponent, TaghdrCrudComponent, BstrMkrComponent, CstmBstrMkrComponent ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    CKEditorModule,
    ReactiveFormsModule,
    OptionalModule
   
  ],
  exports:[ListTagDtlComponent,
    AddDetailComponent,
    ShowDetailComponent,
    UpdateDetailComponent,
    AddDetailComponent,
    TagHdrListComponent,
    UpdateHeaderComponent,
    ListTemplateComponent,
    AddHeaderComponent,
    SlctrViewComponent,
    TagOptnComponent,
    TaghdrCrudComponent,
    BstrMkrComponent,
    CstmBstrMkrComponent
    

      ],
  providers: [DetailService],

})
export class TagModule { }
