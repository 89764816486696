<!--
<h3>Update {{cmntHeading}}</h3>
-->

<div [hidden]="submitted" style="width: 400px;">
  <form #cmntForm (ngSubmit)="onSubmit()">

    <app-cmnt-mnt [c] = comment [parentFormGroup]=cmntForm  [responseMode]= responseMode  > </app-cmnt-mnt>

    <button type="submit" class="btn btn-success">Submit</button>
    </form>
  </div>