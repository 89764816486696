import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { HeaderService } from '../../tag/header.service';

@Component({
  selector: 'app-sylbs-mapr-btn',
  templateUrl: './sylbs-mapr-btn.component.html',
  styleUrls: ['./sylbs-mapr-btn.component.scss']
})
export class SylbsMaprBtnComponent implements OnInit {
  @Input() header:any;
  @Input() i :number;;
 @Input() chapter:any;
 showAddI:number;
 
 constructor(private tagHdrService: HeaderService,private cs:ClientInfoService) { }

  
 success:boolean;
  ngOnInit(): void {
  }
  
  enable(btnId:string){

if(btnId =='outSlbs21' && (this.header.status!== 'outSlbs21')){
return true;
}

   let component = 'sylbsList';
  return this.cs.enable(component,btnId,'',this.header);
  
}

  changeStatus(id,offering,status,i?:number){
    //alert('called');
    const payload = {
      parentId: this.chapter,
      };
  
     this.tagHdrService.changeStatus(id,offering,status).subscribe(data => {
         if((data as any).status === 200) {
         this.cs.addMsg((data as any).message + (data as any).result,'toast');
          this.success = true;
           }else {
         this.success = false;
         alert("error,try later");
         }
       }
       );
  
  }
  
}
