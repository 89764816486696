import { Component, OnInit } from '@angular/core';
import { QuizService } from '../../shared/quiz.service';
import { Router } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  site:string;
  cat:string;
  board:string;
  course:string;
  feature: string;
  offering: string;
  subject: string;
  chapter: string;
  content: string;
  showBoards:boolean;
  offering2: string;
  boardNeeded:boolean;
  headerId:number;
  headerObj:any;
  questions:any;
  categoryComp:string;

  constructor(public quizService : QuizService,private router : Router, private _router : Router,private cs:ClientInfoService,private fd:FixedDataService) { }

  ngOnInit() {
    this.site= this.cs.getSite();
    this.board= this.cs.getBoard();
    this.course= this.cs.getCourse();
    this.feature= this.cs.getFeature();
    this.boardNeeded= this.cs.boardMust();

  }

  reloadComponent() {
    /*
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/esa/home/CBSE/X']);
*/

    this.router.navigateByUrl('esa/home', { skipLocationChange: true }).then(() => {
    this.router.navigate(['esa/ee']);

  this.refresh();

});



}

refresh(): void {
  if(this.cs.bw.hasWindow()) {
  window.location.assign('/esa/ee');
  }
  
}

  SignOut() {
   this.cs.ss.clearLocalStorage();
    //localStorage.clear();
    clearInterval(this.quizService.timer);
    this.router.navigate(['/register']);
  }

}
