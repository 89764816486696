
<div  *ngIf = "(mts$ | async) as m">

<div class="chart-wrapper">

  <canvas baseChart 
  [data]="m.values"
  [labels]="m.set"
  [chartType]="doughnutChartType">
  </canvas>

</div>

</div>



<button type= "button"  class= "btn btn-secondary" (click) = "showData = !showData"> show data
</button>
 
<div *ngIf ="showData">
  <app-data-view  [user] = user> </app-data-view>
  </div>
