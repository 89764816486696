<div *ngIf="bw.hasWindow()" [hidden]= success  style= "white-space:pre-wrap;"> 
 
  <div *ngIf= "quill">
  <app-custom-quill [content]=fileData [table]= '"qstnHdr"'> </app-custom-quill>
</div>

  

<ng-container *ngIf= "!quill">
  <form #ngform (ngSubmit)="onSubmit()">
    <div  maximize #maximize="maximize">

    <button class= "btn btn-info" type = 'button' (click) = 'addMath("``")'> Formula-start</button>
    
    <button type = 'button' (click) = 'addMath("``\\ce{    ")'> chem-start</button>
    
    

    <button class= "btn btn-info float-right" type = 'button' (click) = 'addMath("``")'> formula-end</button>

    <button class= "float-right" type = 'button' (click) = 'addMath("}``")'> chem-end</button>
    
      <button type= "button" class="min-button"
          (click)="maximize.minimize()"> minimize
      </button>
      <button type= "button" class="max-button"
          (click)="maximize.maximize()"> maximize
      </button>
      
       
     
      <button class= "btn btn-secondary mx-2" type="submit">  Save</button>    
      
      <button type="button"   class= "btn btn-secondary mx-2"   (click) = 'close()' >close</button>    

      <ngx-monaco-editor debounce="500" [options]= options (ngModelChange)="onCodeChanged($event)" [(ngModel)]="fileData"  name= 'fileData' (onInit)="onInit($event)">
      </ngx-monaco-editor>
      
   



<!--(init)="onInitEditor($event, 'largeEditor')"-->
  <div *ngIf= "false" class="row">    
         
          <div class="col-3 col-md-3 mb-3 mx-3">    
            <button type="button" class="btn btn-primary badge"
            (click) = 'close()'
            >close</button>    
         </div>    
       
         <div class="col-3 col-md-3 mb-3">    
          <button type="submit" class="btn btn-primary badge">Save</button>    
        </div>    


      </div>
      
      
    </div>
</form>


<hr>
<div *ngIf="false" class= "scroll" [innerHTML] = 'fileData'></div>
<hr>

</ng-container>
