
<app-group-addon *ngIf='hdr && qs.cs.getChapter() && qs.cs.enable("contextMenu","groupMenu","",hdr) ' class= "ml-2"  [hdr] =hdr> </app-group-addon>
<!-- groupon putting below above search is hiding search for unknown reason-->


<nav class="p-0 navbar navbar-expand-lg navbar-light bg-light">
   
  <!--
<span *ngIf='hdr && qs.enable("groupMenu",i,"",hdr)' >
  <app-group-addon [hdr] =hdr> </app-group-addon>
  </span>
-->




<ng-container *ngIf='!qs.onlyQstn' class="mx-1"> 
  <span class="align-self-left mr-auto">
    <app-search *ngIf='qs.enable("searchMenu")'
    class="btn-md btn-outline-primary"
    placeholder="Search by Id#"
    (delayedWord)="qs.getSearchTerm('sequence',$event)"> </app-search>
  </span>
</ng-container>


  


  
  <ng-container *ngIf='qs.chapter && qs.enable("formatHlpr")'> 
    <a  class="badge nav-link btn-secondary button px-1 float-right ml-3" target="_blank" href = 'http://visualmatheditor.equatheque.net/VisualMathEditor.html?codeType=Latex&encloseAllFormula=false&equation=%5Cvec%7BF%7D%20=%20%5Cfrac%7Bd%20%5Cvec%7Bp%7D%7D%7Bdt%7D%20=%20m%20%5Cfrac%7Bd%20%5Cvec%7Bv%7D%7D%7Bdt%7D%20=%20m%20%5Cvec%7Ba%7D'  > Format helper</a>

    <button class="badge nav-link btn-outline-secondary btn px-1 float-right ml-3" (click)= "qs.cs.navigate('mymkr','features/math')" >formula Checker</button>
     </ng-container>


  <!--
<ng-container *ngIf='qs.enable("showSource",i,"",hdr)'>
  <app-show-info [hdr]='hdr'> </app-show-info>
</ng-container>
-->


<!--
<button *ngIf='qs.enable("showVid",i,"",hdr)' type="button"
  class="btn btn-outline-primary" (click)="showVid= !showVid">
  Toggle Vid
</button>
<span *ngIf= "hdr">hi  --{{hdr.id}}  && {{hdr.status}} </span>
-->

<app-cntnt-availer *ngIf='hdr' [hdr] = hdr > </app-cntnt-availer>

<ng-container
*ngIf="qs.enable('changeStatus')">
  <!--(hdr.status== "wip")   ||qs.enable('changeStatusStu'))-->
  

  <app-status-changer *ngIf='hdr && qs.enable("changeStatusUnit",i,"",hdr)' [hdr]=hdr></app-status-changer>
  
  
  <!--
  <app-assigner *ngIf='hdr'  [hdr]=hdr> </app-assigner>
  <app-share *ngIf='hdr && (hdr.status== "avl"|| hdr.status== "store") ' [hdr]=hdr> </app-share>
-->

</ng-container>


<!--
<ng-container *ngIf='qs.enable("CRUD")'>
  
  <app-qstnhdr-list class="float-right" *ngIf='!qs.tagBased(qs.offering)'>
  </app-qstnhdr-list>
 
  
  <app-taghdr-list *ngIf='qs.tagBased(qs.offering)' [tagType]=qs.offering>
  </app-taghdr-list>
</ng-container>
-->



<ng-container *ngIf="qs.enable('viewAnsrEligible')"
class="d-flex justify-content-center">
 
<!-- special button new-->
<div class="btn-group btn-group-toggle">
  <input type="radio" id ="yesChoice" [(ngModel)] ="qs.expandedAnsrs" [value]=true  >
  <label for="yesChoice" ngbButtonLabel>Learn-with visible answer</label>
  
  <br>
  
  <input type="radio" id ="noChoice"  [(ngModel)] ="qs.expandedAnsrs" [value]=false >
  <label for="noChoice" ngbButtonLabel>practice-show answer on demand</label>
  </div>
<!-- end- special button new-->

<button *ngIf='qs.enable("onlyQstnView")' (click) = 'qs.onlyQstn=!qs.onlyQstn'> Only Qstn View </button> 



<ng-container *ngIf='qs.enable("isAnsr") && qs.enable("studentView")'>
 <button  class=" navbar-nav mx-auto badge btn-md btn-primary"ngbTooltip='Shrink Answers :ideal during Practice &Show All Answers: better during Learning'  placement="bottom"
  (click)="qs.flipExpandAllAnsrs()"> {{qs.expandedAnsrs?'hide Answers':'Show All Answers'}} </button>
</ng-container>

<!--<span class= "">Flip To</span>- -->
<!--<button *ngIf='qs.enable("teacherView")' class="badge btn-md btn-outline-primary border-0" ngbTooltip='Practice:ansr btn at qstn level, Learn: inline qstn & Ansr' placement="bottom"
  (click)="qs.flipExpandAllAnsrs()">{{qs.expandedAnsrs?'Answer on Click':'show All Answers'}} </button>
--> 
             
  <button *ngIf='qs.enable("isAnsr") && qs.enable("teacherView")' class="  mx-5 badge btn-md  btn-primary border-0" ngbTooltip='Shrink answrs and give option to view at qstn level'  placement="bottom"
  (click)="qs.flipExpandAllAnsrs()">{{qs.expandedAnsrs ?'hide Answers ':'show Answers'}} </button>

</ng-container>



<div   *ngIf='qs.enable("filterDoubts") || qs.enable("filterAnsrs")' class="mx-2 btn-group btn-outline-primary">

  <button  type="button" class="badge bg-white btn-outline-seconary border-0 ">Filter:</button>
  
  <div *ngIf='qs.enable("filterDoubts")' class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
    <button class="btn badge btn-outline-primary  dropdown-toggle-split" ngbDropdownToggle>Doubt</button>
    <div class="dropdown-menu" ngbDropdownMenu>
      <button ngbDropdownItem (click)='qs.filterList("Dclr")'>Qstns with doubts</button>
      <button ngbDropdownItem (click)='qs.filterList("Dbkt")'>with Tag-teacher</button>
      <button ngbDropdownItem (click)='qs.filterList("Dcmnt")'>Qstns with Comment</button>
      <button ngbDropdownItem (click)='qs.filterList("Dsrvsn")'>Marked for Self</button>
      
            <button ngbDropdownItem (click)='qs.filterList()'>Unfilter</button>
    </div>
  </div>
  
  <div *ngIf='qs.enable("filterAnsrs")' class="btn-group px-2 " ngbDropdown role="group" aria-label="Button group with nested dropdown">
    <button class="btn badge btn-outline-primary  dropdown-toggle-split" ngbDropdownToggle>Answer</button>
    <div class="dropdown-menu" ngbDropdownMenu>
      <button ngbDropdownItem (click)='qs.filterList("Dans")'>Attempted Qstns</button>
      <button ngbDropdownItem (click)='qs.filterList("Dans::correct")'>Qstns with Correct Answer</button>
      <button ngbDropdownItem (click)='qs.filterList("Dans::incorrect")'>Qstns with Incorrect Answer</button>
      <button ngbDropdownItem (click)='qs.filterList()'>Unfilter</button>
    </div>
  </div>
</div>


<div *ngIf='qs.enable("digFltr",null,hdr)' class="btn-group px-2 " ngbDropdown role="group" aria-label="Button group with nested dropdown">
  <button class="btn badge btn-outline-primary  dropdown-toggle-split" ngbDropdownToggle>filter</button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <button ngbDropdownItem (click)='qs.fldFltrList("mcqNoDigAnsr")'>MCQ with No DigAnsr</button>
    <button ngbDropdownItem (click)='qs.fldFltrList("mcqNoFormattedArray")'>MCQ having options issue</button>
    <button ngbDropdownItem (click)='qs.fldFltrList("format", "MCQ")'>All MCQ</button>
    <button ngbDropdownItem (click)='qs.fldFltrList()'>Unfilter</button>
  </div>
</div>





<ng-container *ngIf='!qs.onlyQstn' class="mx-1"> 
  <span class="align-self-end ml-auto">
    <app-search *ngIf='qs.enable("searchMenu")'
    class="btn-md btn-outline-primary"
    placeholder="Filter by Section#"
    (delayedWord)="qs.getSearchTerm('section',$event)"> </app-search>
  </span>
</ng-container>


<ng-container  class="mx-1"> </ng-container>



<ng-container *ngIf='!qs.onlyQstn' class="mx-1"> 
  <span class="align-self-start mx-1">
    <app-search *ngIf='qs.enable("searchMenu")'
    class="btn-md btn-outline-primary"
    placeholder="Search by word"
    (delayedWord)="qs.getSearchTerm('*',$event)"> </app-search>
  </span>
</ng-container>


  

<!--
<div *ngIf="showVid">
  <div class="card">
    <div class="card-body">
      <app-dt-selector [hdr]='hdr' [unitId]=hdr.id></app-dt-selector>
    </div>
  </div>
</div>

-->


</nav>
