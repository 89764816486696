import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-problem-space',
  templateUrl: './problem-space.component.html',
  styleUrls: ['./problem-space.component.scss']
})
export class ProblemSpaceComponent implements OnInit {
@Input() q:any;
@Input() h:any;
  constructor(public cs:ClientInfoService) { }

  ngOnInit(): void {
  }

}
