<!--
<div id= "thought" class= " jumbotron text-center "   >
 <h3>Edu Fresh </h3>
  <i >Believe it or not.. but fact is ... One thought can change life..</i>
</div>
-->


<app-daily-detail [type] = '"fresh"' [subject] = '"thought"' > </app-daily-detail>

<app-daily-detail [type] = '"fresh"' [subject] = '"didyouknow"' > </app-daily-detail>

<app-daily-detail [type] = '"fresh"' [subject] = '"quiz"' > </app-daily-detail>

<app-daily-detail [type] = '"fresh"' [subject] = '"Story"' > </app-daily-detail>


<!--
***********


<div id ="thought" >
  <app-thoughtoftheday *ngIf= 'enable("thought")' class=" text-center my-1">... </app-thoughtoftheday>
  </div> 

<app-fresh-quiz> </app-fresh-quiz>

<div *ngIf= 'true || enable("vid")'  id="motivator" class="my-5" (onload)="alertMsg('hello')">
  
  Video of the day:
  
  <div class="embed-responsive embed-responsive-16by9">
    <iframe class="embed-responsive-item" [src]="(utube + utubeId)|safe:'resourceUrl'" allowfullscreen></iframe>
  </div>
</div>

-->


 

  
 

<!--
http://192.168.1.2:8080/rs/s/esa/ICSE/X/pyq/Biology/contents/20202
-->
<!--working all



  <div id="edunews" class= "jumbotron text-center"> <h2>Edu Bestu </h2>
News that matter to Educationist!
</div>

<div  class="py-5">
  <app-contentlist [questions]= questions [headerById]= headerById>
  </app-contentlist>

</div>
-->

<br>
<br>
<hr>
