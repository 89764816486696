
<a  *ngIf= "header.extUrl&& !header.extUrl.includes('stage') && enable('requestLoad',i,header) "  class= "btn btn-md  text-danger badge mr-2"  (click)="finalizeDraft(header,i)"> Finalize & stage </a>


<!-- this will move entire folder hence to be done at last-->
<button  *ngIf= "enable('archiveInput',i,header) "  class= "btn btn-md btn-primary badge mr-1"  (click)="finalizeDraft(header,i)"> Archive Input </button>


<a  *ngIf= "enable('moveGate',i,header) "  class= "btn btn-md  text-danger badge mr-2"  (click)="promoteGate(header,i)"> promote folder</a>

<a  *ngIf= "enable('moveGate',i,header) "  class= "btn btn-md  btn-primary  badge mr-2"  (click)="copyGDUser(header,i)"> Copy Google Folder</a>

