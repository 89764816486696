

<app-menu *ngIf= "offering==='tasgn-tgpzl'" > </app-menu>

<head>
  <title>ES- {{board}} Percentage Booster </title>
</head>


<!--
<script type="text/x-mathjax-config">
MathJax.Ajax.config.path["mhchem"] =
  "https://cdnjs.cloudflare.com/ajax/libs/mathjax-mhchem/3.3.0";

  MathJax.Hub.Config({
    tex2jax: {
        inlineMath: [['``','``'], ['\\(','\\)']],
        processEscapes: true
        },
        TeX: {
    extensions: ["[mhchem]/mhchem.js",],
   },
  });

</script>
 <script type="text/javascript"
        src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TEX-AMS_HTML">

       MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
        </script>

<script src="ng-mathjax.js"></script>
-->
<!--TeX-MML-AM_CHTML-->


<div *ngIf= "offering==='tasgn-pbstr'" class="jumbotron text-center" >
    <!--- 
   {{board}} -{{course}}-{{subject}} -->
   
   <h3> {{board}}-{{course}}: Percentage Booster
    </h3>
    <i>
      Practice daily - 5 Questions for 10 % boost in score!
 </i>

 </div>

 
<div *ngIf= "offering==='tasgn-tgpzl'"  class="jumbotron text-center" >
  <!--- 
 {{board}} -{{course}}-{{subject}} -->
 
 <h3> {{board}}-{{course}}: Puzzle Corner
  </h3>
  <i>
    Refreshed each Sunday
</i>

</div>


<div>

<!--
 <textarea #txt></textarea>
 <span [MathJax]="fractionString">{{fractionString}}"></span>
</div>
-->

<!--
<ngb-toast *ngIf="message" header=" "  (hide)="message = ''" delay= "3000">
  {{ message }}
</ngb-toast>
-->


<!-- validated  -->
  <div *ngIf="subjects" class= "d-flex">
   <div class="col-md-6 d-flex justify-content-start"><h5>Click Subject & Practice </h5>
  </div>

  <div *ngIf= 'subjects && subjects.length>0' class="col-md-6 text-info text-small  font-italic d-flex justify-content-end"> 
    Data Updated on : {{subjects[0].formattedActionDate}} Refresh Frequency: Every {{frequency}} day(s)
  </div>
</div>


<ul *ngIf="subjects" class="row subjects" >

  <li class="col-md-3 thumbnail" *ngFor="let h of subjects"

   (click)="percentageBooster(h)">
  <div>   <button> <span class="badge py-2">{{getTitle(h)}}</span> </button>
</div>
{{h.dataList}}
  </li>
</ul>

<!--

<script src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
    <script>
      (adsbygoogle = window.adsbygoogle || []).push({
        google_ad_client : "ca-pub-4808501513929067",
        enable_page_level_ads : true
      });
    </script>

   --> 
    <!-- Problem area -->

  <div *ngIf ="b == '5'"  id = "aa" is-info is-fullheight is-bold>






    <h5>{{subject}} Practice Questions: </h5>

    <div *ngIf="calling; else noCall">

      <div *ngIf="(vids$ | async)?.dataList as catalog; else loading">
        <ng-container *ngIf="catalog.length; else noItems">
    
        <div id = "yes" *ngIf="catalog">

                <ul *ngIf="catalog">
              <button (click)="typeSet()">Refresh View </button>
               <button *ngIf="!showA" (click)="showAns()">Show Answer </button>
              
               <button *ngIf="showA" (click)="hideAns()">Hide Answer </button>
               <!--  -->
              
                <li *ngFor="let question of catalog; let i = index;">
              
                  Question#: {{question.sequence }}-{{question.subSeq}}-{{question.part}} <br>
              
                  <div *ngIf="question.content" class="questionsc" innerHTML = "{{  question.instruction }}<br> {{  question.content }}"></div>
                  <br/>
                  <div>
                  <div *ngIf="question.answer && showA" class="answersc" innerHTML = "<br> Ans : {{ question.answer }}">
                      <!--&& enable('viewSource')"-->
                   </div>
                    <div *ngIf= "question.source && showA" class= "text-muted float-right">
                        {{question.source}}
                  </div>
                </div> 
                </li>
              
              
              </ul>
              </div>

              </ng-container>
              
              <div *ngIf= "signedUser == 'Guest'" class="bg-dark" >
                <app-signuplogin > </app-signuplogin>
                </div>
              </div>
              
             
      

    
    <ng-template #noItems>*</ng-template>
  </div>
  <ng-template #loading>loading......</ng-template>
</div>
<ng-template #noCall>!</ng-template>




</div>


<!-- Problem area -->

 <div  *ngIf="showT" >

    <textarea [formControlName]="i"> </textarea >

    </div>

<div *ngIf="showR" >
       value of text area  {{i}} is
       <span>{{bstrForm.controls.i.value}}</span>

    </div>

<!-- validated  -->

<!--

<br>
<br>
<app-comments> </app-comments>

<br>
<br>

-->




<!--
<p class="text-center bg-dark text-orange" *ngIf= "signedUser == 'Guest'">

  <a class="nav-item nav-link" href="https://examsteppers.com/faces/unsecured/login.xhtml" >
    <button type="button"> Log In</button> for Perseonalized AI driven experience..</a>

</p>
-->

<!--
<app-trailer></app-trailer>
-->
