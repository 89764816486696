import { Component, Input, OnInit } from '@angular/core';
import { HeaderService } from '../../tag/header.service';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-hdr-fld-changer',
  templateUrl: './hdr-fld-changer.component.html',
  styleUrls: ['./hdr-fld-changer.component.scss']
})
export class HdrFldChangerComponent implements OnInit {

  
  @Input() h: any;
  @Input() i:number;
  @Input() fieldName :string;

  constructor(public cs:ClientInfoService, public hs: HeaderService) { }

  ngOnInit(): void {
  }

  submit(data:any){
    this.changeQHField(data.fieldValue)
   // this.cs.addMsg("changed " + data.fieldValue)
  }

  
  changeQHField(value:any){
    //ideally type = this.h.type if passed
    let type = "type";

    this.hs.changeField(this.h.id,type,this.fieldName,value).subscribe(data =>{
       if ((data as any).status === 200) {
 this.cs.addMsg((data as any).message, 'info');         
 } else {
 this.cs.addMsg('error,try later', 'error');
 }  
 });
 
   }
 

   
}
