


<div [ngSwitch]='qs.cat'>
    <app-bstr-view *ngSwitchCase='"gpb"' class="list-group-item " [q]=q
        [i]=i> </app-bstr-view>

     <app-test-qstn *ngSwitchCase='"dgt1z"' class="list-group-item " [q]=q
        [i]=i [worksheet]='true'> </app-test-qstn> 
     
    <app-test-qstn *ngSwitchCase='"hwrk"' class="list-group-item " [q]=q
        [i]=i [worksheet]='true'> </app-test-qstn>

     <app-vid-dtl *ngSwitchCase='"vid"' class="text-center mb-1 border-0"
        [q]=q [i]=i> ...</app-vid-dtl>

        <app-slide *ngSwitchCase='"slide"' class="text-center mb-1 border-0"
        [q]=q [i]=i> ...</app-slide>

    <!--  <app-subjective-qstn class="list-group-item " [q]=q [i]=i> </app-subjective-qstn>        -->

    <!-- remove border-0  if u want box-->
    
    <div *ngSwitchDefault class="list-group-item border-0 mb-0">
        <!-- idea is format should drive in future-->
        <div [ngSwitch]='h.hdrFormat'> 
            <!--
        <app-cfg-dtl *ngSwitchCase='"cfg"' [q]=q [i]=i>
        </app-cfg-dtl>
        -->

        <app-vid-dtl *ngSwitchCase='"vid"' class="text-center mb-1 border-0"
        [q]=q [i]=i> ...</app-vid-dtl>

        <app-slide *ngSwitchCase='"slide"' class="text-center mb-1 border-0"
        [q]=q [i]=i> ...</app-slide>

        <div *ngSwitchDefault> 

   <app-dtl-top [q]= q [i] = i> </app-dtl-top> 
      
    <ng-container [ngSwitch]='q.format'> 
       
    <app-mcq-qstn *ngSwitchCase='"MCQ"' [q]=q [i]=i> </app-mcq-qstn>    
    
    
    <app-mcq-ma *ngSwitchCase='"MCQM"' [q]=q [i]=i> </app-mcq-ma>    
        
    <ng-container *ngSwitchDefault>
    
  <app-short-qstn class="" [q]=q [i]=i> </app-short-qstn> 
 
        <!-- enable if needed  list-group-item border-0 mt-0 
   <app-simple-qstn  [q]=q [i]=i> </app-simple-qstn>
   <app-crctn *ngIf="qs.enable('crctedAnsr')" [q]=q [i]=i> </app-crctn>   
      -->

   </ng-container>
   
    <app-ansr-view *ngIf='qs.enable("viewAnsrEligible")' [showA]= false  [q]=q [i]=i>  </app-ansr-view>
    </ng-container>



    <!--
    <app-qstn-btns class="" [q]=q [i]=i [h] = h></app-qstn-btns>
    
    <app-dtl-ftr *ngIf= "qstnId" [q]=q [i]=i>    </app-dtl-ftr>
-->
    
    </div>
    </div>

  </div>
</div>