
<!--
<app-comment-list  *ngIf= 'qs.enable("genFeedback")' [featureType] = qs.offering [cmntType]= "'Dcmnt'" [parentHdrId] = qs.chapter [parentDtlId] = q.id  [commentsI] = q.genCmnts></app-comment-list> 
 -->

 

 <!-- comment view starts && showCommentI !== i -->   


 
 <button type= "button" *ngIf="true || q.answer && qs.enable('genFeedback') && showCommentI !== i" class= "float-right bg-transparent border-0  mx-2 "  (click)="showCommentI = i"  ngbTooltip="report error/feedback" placement= "left" > 
     <!--q.genCmnts.length {{q.cmntsData.Drfc.length}}-->
     
  <span *ngIf="(q.cmntsData && q.cmntsData.Drfc && q.cmntsData.Drfc.length>0)">{{q.cmntsData.Drfc.length}} + </span> 
  <i class="fa fa-comment-o" aria-hidden="true"></i>  </button>
  
 <button *ngIf="showCommentI ==i" class= "float-right  border-0  bg-transparent  mx-2"  (click)="showCommentI = null"  >hide- <i class="fa fa-comment-o"></i> </button>
 

 
 <app-cmnt-list *ngIf= "showCommentI==i"  [cmntType]= '"Drfc"' [featureType] = "'rfc'"  [parentHdrId] = qs.chapter [parentDtlId] = q.id [mode]= '"list"' > </app-cmnt-list>

 <!--
  <app-comment-list   *ngIf= "showCommentI==i"   [featureType] = qs.offering [cmntType]= "'Dcmnt'" [parentHdrId] = qs.chapter [parentDtlId] = q.id [hideTitle] = false [commentsI]=q.genCmnts ></app-comment-list> 
  -->

  <!-- comment view ends-->  


  
