
  <h3 class="text-center"> User Detail: </h3>

<div *ngIf= "ud" [hidden]="submitted" class= "mx-3" style="width: 400px;">
 
  <form (ngSubmit)="onSubmit()">

    <app-dropdown *ngIf= 'enable("selectFormat")' [formatList] = formatList [dfltCode]="selectedFormat?.code"  (changedValue)="fetchData($event)"
 > </app-dropdown>

<app-ud-flds [fldList]=primary [ud]=ud > </app-ud-flds>

<div class="my-3 text-right">
        <button *ngIf="secondary && secondary.length>1" type="button"
                class="btn btn-secondary mx-2" (click)="expand=!expand">
                Secondary Fields</button>

        <button *ngIf="enable('additional') && additional" type="button"
                class="text-right btn btn-secondary mx-2"
                (click)="additionalFlag=!additionalFlag"> Additional
                Fields</button>

</div>

<app-ud-flds *ngIf="expand" [fldList]=secondary [ud]=ud> </app-ud-flds>
<app-ud-flds *ngIf="additionalFlag" [fldList]=additional [ud]=ud>
</app-ud-flds>



<button type="button" class="btn btn-secondary mx-3 " (click) = 'showRole= !showRole'> show Role</button>  
<button type="submit" class="btn btn-success">Submit</button>
  <button type="button" (click)= "gotoList()"  class="btn btn-ino">Go Back</button>

</form>
</div>
<app-usr-role [headerId] = ud.headerId *ngIf= "showRole">  </app-usr-role>












<ng-container *ngIf="false"> 
    <div class="form-group">
        <label for="id">id# </label>
        <input type="text" class="form-control" id="id" required [(ngModel)]="ud.id"  name="id">
      </div>
  

    <div  class="form-group">
      <label for="lasName">last Name# </label>
      <input type="text" class="form-control" id="lastName" required [(ngModel)]="ud.lastName" name="lastName">
    </div>

    <div  class="form-group">
      <label for="firstName">firstName: </label>
      <input type="text" class="form-control" id="firstName" required [(ngModel)]="ud.firstName" name="firstName">
    </div>

    <div  class="form-group">
      <label for="board">board</label>
      <input type="text" class="form-control" id="board" required [(ngModel)]="ud.board" name="board">
    </div>

    <div  class="form-group">
        <label for="course">course/Class# </label>
        <input type="text" class="form-control" id="course" required [(ngModel)]="ud.grade" name="course">
      </div>

      <div  class="form-group">
        <label for="clsSec">cls-Section# </label>
        <input type="text" class="form-control" id="clsSec" required [(ngModel)]="ud.clsSec" name="clsSec">
      </div>

      
    <div  class="form-group">
      <label for="dfltMnu">dflt Menu</label>
      <input type="text" class="form-control" id="dfltMnu" required [(ngModel)]="ud.dfltMnu" name="dfltMnu">
    </div>

      
    <div  class="form-group">
      <label for="subject">Dflt subject </label>
      <input type="text" class="form-control" id="subject" required [(ngModel)]="ud.subject" name="subject">
    </div>

      <div  class="form-group">
        <label for="groupId">User Type# </label>
        <input type="text" class="form-control" id="groupId" required [(ngModel)]="ud.groupId" name="groupId">
      </div>
       
      <div  class="form-group">
        <label for="roles">Additional Role(s) </label>
        <input type="text" class="form-control" id="roles" required [(ngModel)]="ud.roles" name="roles">
      </div>
       

      
      <div  class="form-group">
        <label for="shrgrp">Share/Project Grp </label>
        <input type="text" class="form-control" id="shrgrp" required [(ngModel)]="ud.shrGrp" name="shrgrp">
      </div>

    <div  class="form-group">
      <label for="emailId">EmailId# </label>
      <input type="text" class="form-control" id="emailId" required [(ngModel)]="ud.emailId" name="emailId">
    </div>

    <div  class="form-group">
        <label for="phone">phone# </label>
        <input type="text" class="form-control" id="phone" required [(ngModel)]="ud.phone" name="phone">
      </div>
    

     
    
    <div  class="form-group">
        <label for="learnMode">learnMode# </label>
        <input type="text" class="form-control" id="learnMode" required [(ngModel)]="ud.learnMode" name="learnMode">
      </div>
    
      <div  class="form-group">
        <label for="domain">domain </label>
        <input type="text" class="form-control" id="domain" required [(ngModel)]="ud.domain" name="domain">
      </div>
     
      
      <div  class="form-group">
        <label for="mentorId">mentor Id </label>
        <input type="text" class="form-control" id="mentorId" required [(ngModel)]="ud.mentorId" name="mentorid">
      </div>
     
    </ng-container>


     <!--
    -->
    
    