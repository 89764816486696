import { Component, Input, OnInit } from '@angular/core';
import { FixedDataService } from '../../shared/fixed-data.service';
import { CmntListService } from '../cmnt-list/cmnt-list.service';
import { Comment } from "../comment";

@Component({
  selector: 'app-cmnt-title',
  templateUrl: './cmnt-title.component.html',
  styleUrls: ['./cmnt-title.component.scss']
})
export class CmntTitleComponent implements OnInit {
   @Input() cmntType : string;
   @Input() hdrObj?:any;
  
  @Input() crtCmnt : Comment;
  @Input() CRUD: boolean;
  @Input() hideTitle : boolean;
 
  showAdd: boolean;
  showUploadFile: boolean;
  title: string;
  
  constructor(public ls: CmntListService,public fd:FixedDataService) { }

  
  ngOnInit(): void {
  
  }

  ngOnChanges(){

  this.title = this.fd.getCmntHeading(this.crtCmnt.cmntType,this.ls.cs.getGroupId());
  if(this.crtCmnt.cmntType == "Dclr"){
    
    switch(this.ls.cs.getStatus()){

     case 'shr':
      this.title = "ask teacher"; 
      break;
     case 'clr':
      this.title = "raise doubt"; 
      break;
      
      case 'rvsn':
      case 'cmplt':
     
    this.title = "prepExp:quick help "; 
    break;
    default :
      this.title = "ask "; 
      break;
        
    }
   
  }

  
  }

  addComment(){
     this.showAdd != this.showAdd;

    //this.router.navigate(['eba','digibest','features','cmnt-add']);
  }

  uploadFile(){
    this.showUploadFile = true;
    //this.router.navigate(['eba','digibest','features','cmnt-add']);
  }
  

  fetchData(x:any){
    console.log("Update after change  not implemented yet");
  }
}
