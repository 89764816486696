   
   

    <!--*ngIf='!chapter && cat.startsWith("mkr-")'

    
<button  type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'cm.addNew()'  > Add New</button>

<i class="fa fa-book" aria-hidden="true"></i>
-->


<button *ngIf="cm.chapter && cm.enable('addQDtl')"  (click)="showQDtl= !showQDtl" class="badge btn btn-outline-primary border-0 ml-2" style="margin-left: 10px"> {{showQDtl?'Hide Add':'*add '+ cm.getQstnName()}} 
    <i class="fa fa-book" aria-hidden="true"></i>
     </button>
   
    
  
<button *ngIf="cm.offering && !cm.chapter && (cm.enable('showQH') || cm.enable('mycontent'))"  (click)="showQHdr= !showQHdr" class="badge border-0 btn-outline-primary btn  px-1 float-right ml-3" style="margin-left: 10px"> {{showQHdr?'Hide Add':'Add ' + cm.getHdrName()}} <i class="fa fa-book" aria-hidden="true"></i> </button>

<button *ngIf="(cm.offering && !cm.chapter && (cm.enable('showTH')))"  (click)="showTHdr= !showTHdr" class="badge border-0  btn-outline-primary btn px-1 float-right ml-3" style="margin-left: 10px"> {{showTHdr?'Hide Add':'Add t ' + cm.getHdrName()}} <i class="fa fa-book" aria-hidden="true"></i> </button>


 
<button *ngIf= 'cm.offering && cm.enable("showTHL")' type="button" class="btn badge btn-outline-primary" (click)="showTHL = !showTHL">Tag Hdr List</button>


<button *ngIf="cm.chapter && cm.enable('showTDL')" type="button" class="btn badge btn-outline-primary border-0 " (click)="showTDL = !showTDL"
[attr.aria-expanded]="showTDL" aria-controls="collapseTD">
Toggle Tag Detail
</button>



<button *ngIf="cm.offering && cm.enable('showQHL')" type="button" class="badge btn btn-outline-primary" (click)="showQHL = !showQHL"
[attr.aria-expanded]="showQHL" aria-controls="collapseQH">
Toggle Qstn Hdr
</button>


<button *ngIf="cm.chapter && cm.enable('showQDL')" type="button" class="btn btn-outline-primary border-0" (click)="showQDL = !showQDL"
[attr.aria-expanded]="showQDL" aria-controls="collapseQDL">
dig answer updater
</button>


<app-mass-bldr *ngIf='cm.subject && !cm.chapter && cm.enable("massBldr")' class= "float-right" >  </app-mass-bldr>
    



<app-qstnhdr-add  *ngIf="showQHdr" [ovrHdrFormat] = 'ovrHdrFormat' > </app-qstnhdr-add>

  
<app-add-header  *ngIf="showTHdr"  > </app-add-header>


<div id="collapseQDtl" *ngIf="showQDtl"   [ngbCollapse]="!showQDtl">
<div class="card">
 <div class="card-body">

<app-add-qstn-dtl *ngIf="showQDtl" ></app-add-qstn-dtl>
</div>
</div>
</div>


<div id="collapseTD" *ngIf="showTDL"   [ngbCollapse]="!showTDL">
    <div class="card">
     <div class="card-body">
        
  <app-list-tagdtl [tagHdrId]= cm.chapter [tagDtlId] = ''> </app-list-tagdtl>
     </div>
    </div>
    </div>
  