import { Component, Input, OnInit } from '@angular/core';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';
import { Header } from '../../qstn/header';
import { QstnDtlService } from '../../qstndtl/qstn-dtl.service';

@Component({
  selector: 'app-dtl-view-shower',
  templateUrl: './dtl-view-shower.component.html',
  styleUrls: ['./dtl-view-shower.component.scss']
})
export class DtlViewShowerComponent implements OnInit {
  @Input()  detailId: number;
  @Input()  q: any;
  @Input() i:number;
  @Input() h:Header;
  
  constructor(public qs:QstnListService,private detailService: QstnDtlService) { }

  ngOnInit(): void {
    if(!this.q && this.detailId){
      this.getDetail(this.detailId)
     } 
 
     
  }


  getDetail(id:number){
    this.detailService.get(id)
   .subscribe(data => {
   //  console.log(data)
     this.q = data;
     
     //console.log(this.detail)
     
   }, error => console.log(error));

 }

}
