import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-t-pmagic',
  templateUrl: './t-pmagic.component.html',
  styleUrls: ['./t-pmagic.component.scss']
})
export class TPmagicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
