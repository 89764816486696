
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,Subject} from 'rxjs';
import { HttpHeaders,HttpResponse } from '@angular/common/http';
import { ClientInfoService } from '../shared/client-info.service';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    //'Authorization': 'my-auth-token',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'HEAD,GET,POST,PUT,PATCH,DELETE',
    responseType: 'text',
  })
  };
 
  @Injectable({
    providedIn: 'root'
  })

  export class UsrhdrService {
//Subject which will be pushed from child to advise parent to update List.
public static updateList: Subject<boolean> = new Subject();
    currentCh: any;

    getHeadersByTypeFtrHdrId(cmntType: string, table: string, parentHdrId: number) {
      //alert(this.cs.getSite());

      return this.http.get(`${this.baseUrl}/typeFtrHdrid/${this.cs.getSite()}/${cmntType}/${table}/${parentHdrId}`);
      //throw new Error("Method not implemented.");
    }

    getHeadersByTypeFtrDtlId(cmntType: string, table: string, parentHdrId: number,parentDtlId:number) {
     // alert ("dtl calling");
      return this.http.get(`${this.baseUrl}/typeFtrDtlid/${this.cs.getSite()}/${cmntType}/${table}/${parentHdrId}/${parentDtlId}`);
      //throw new Error("Method not implemented.");
    }



  private baseUrl ;
  //= 'http://192.168.1.2:8080/rs/cmnt';


  constructor(private http: HttpClient,private cs:ClientInfoService) {
    this.baseUrl= this.cs.getRoot()+ "/rs/uh-v2";
   }


  getHeader(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/header/${id}`);
  }

  getUserByNum(usrNum: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/usrNum/${usrNum}`);
  }


  createHeader(header: Object): Observable<Object> {
    //console.log(header);
    return this.http.post(`${this.baseUrl}`, header);
  }

  updateHeader(id: number, value: any): Observable<Object> {
    return this.http.put(`${this.baseUrl}/usrNum/${id}`, value,httpOptions);
  }

  changePwd(ch: any): Observable<Object> {
    return this.http.put(`${this.baseUrl}/chgpwd`, ch,httpOptions);
  }

  changePwdEmail(ch: any): Observable<Object> {
    return this.http.post(`${this.baseUrl}/sendEmail`, ch,httpOptions);
  }


  deleteHeader(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`,httpOptions );
  }

  getHeadersList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/prntdtlset/1`);
  }

  getHeadersByDomain(domain: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/byDomain/${domain}`);
  }


  getSitesByDomain(domain: string): Observable<any> {
    return this.http.get(`${this.cs.getRoot()}/rs/sites/list/${domain}`);
  }

  findAll(domain: string): Observable<any> {
    return this.http.get(`${this.cs.getRoot()}/rs/sites/listObj/${domain}`);
  }

  domainRec(domain: string): Observable<any> {
    return this.http.get(`${this.cs.getRoot()}/rs/sites/header/byDomain/${domain}`);
  }


  //legacy
  getHeadersByType(type: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/type//${this.cs.getSite()}/${type}`);
  }

}
