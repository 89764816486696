import { Component, Input, OnInit } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import {Router} from '@angular/router';
//import { environment } from './../../../environments/environment';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-signuplogin',
  templateUrl: './signuplogin.component.html',
  styleUrls: ['./signuplogin.component.scss']
})
export class SignuploginComponent implements OnInit {
@Input() hideSigned:boolean;
signInUrl:string;
currentUrl: string;
rootUrl:string;
get site() {return  this.cs.getSite()} ;
get cat() {return this.cs.getCat() };
get board() {return this.cs.getBoard()};
get course() { return this.cs.getCourse()};
get offering() { return this.cs.getOffering()};
get subject() { return this.cs.getSubject()};
get signedUser() {return this.cs.getSignedUser()} ;
get unsigned(){ return this.cs.isUnsigned()}
get feature() { return this.cs.getFeature()};
showLearnMode:boolean = false;

//readonly rootUrl = environment.rootUrl;
//readonly rootUrl = 'http://localhost';
//readonly rootUrl = 'https://examsteppers.com';

constructor(private data: BoardDataService,private router: Router,private cs:ClientInfoService) {
  this.rootUrl= cs.getRoot();
  }

  ngOnInit() {

    //this.data.getSignedUser().subscribe(response=> {
    //this.signedUser = (response as any).user as string;
    //window.localStorage.setItem('signedUser', this.signedUser);

     // console.log("inside signupsignin  init");
      //this.signedUser = this.data.getSignedUserNew()
      //window.localStorage.setItem('signedUser', this.signedUser);

      /*
          if(response && response != "Guest") {
          this.signedUser = response;
        window.localStorage.setItem('signedUser', this.signedUser);



        }
          //alert("signed User  retrieved "+ this.signedUser);
          //console.log("signed User is " +this.signedUser);
*/

       // });

  }
/*
getSignedUser(){

  this.data.getSignedUser().subscribe(response=> {
    if(response && response != "Guest"){
    this.signedUser = response;
  }
      });
    console.log("inside signup getsigneduser");
    this.signedUser = this.data.getSignedUserNew()
    return this.signedUser;
  }
*/

  getCurrentUrl(){
    alert(this.router.url);
  return this.router.url;
  }

  getSignInUrl(){
  this.signInUrl= this.rootUrl+"/faces/unsecured/login.xhtml";
  this.currentUrl= this.router.url;
  return this.signInUrl + '?'+ 'fromUrl='+ this.currentUrl;
  }

  signin(){
  //this.router.navigate(['/',this.site,this.cat,'features','signin'], { queryParams: { fromUrl: 'esa'} });
  this.router.navigate(['/',this.site,'signin']);
    
}
    
  signout(){
    this.router.navigate(['/',this.site,this.cat,'features','signout'], { queryParams: { fromUrl: 'esa'} });
    }

  getSignUpUrl(){
    if(this.site=="gen"){
      return this.cs.getRouteUrl('ftr/signup','');
   
    }else{
    return this.cs.getRouteUrl(this.cat,'features/signup');
    }
    }


    
togetherUrl(){
 
  return this.cs.getRouteUrl("together","");

  }

  
}
