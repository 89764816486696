


<ng-container [ngSwitch]="hdr.status" >
    
 <a  *ngSwitchCase='"wip"' 
 class = "btn-md badge btn-primary mr-1"   [routerLink]= "vs.getChapterUrl(hdr,'mkr') " >maintain</a>

<button  *ngSwitchCase='"asgn"'   class= "btn-md btn-primary badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr,'mkr') " >check</button>

<button  *ngSwitchCase='"verify"'   class= "btn-md btn-primary badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr) " >Verify</button>

<button  *ngSwitchCase='"verify"'   class= "btn-md btn-primary badge mr-1"  (click) = 'vs.changeStatus(hdr, "store")' >store</button>

<a  *ngSwitchCase='"store"' 
 class = "btn-md badge btn-primary mr-1"   [routerLink]= "vs.getChapterUrl(hdr,'mkr') " >maintain</a>

</ng-container>