
<!--
<app-signuplogin> </app-signuplogin>


<button type="button" (click) = 'showLearnMode =!showLearnMode' class="btn btn-outline-primary" > change Role </button> 

{{enable('demoUser')}}
-->

<div *ngIf = "enable('demoUser')"  ngbDropdown class="d-inline-block">
  
  <button class="mx-2 btn btn-outline-primary badge text-info " id="dropdown" ngbDropdownToggle>{{ selectedMode?selectedMode.desc:"change Role" }}</button>
  <div ngbDropdownMenu aria-labelledby="dropdown">
    <div id= "dropdown" *ngFor="let sub of subscriptionList;" >
      <button ngbDropdownItem id="option" on-click="rcs.dropdownClickV2(sub.mode)">{{sub.desc}}
      </button>
    </div>
  </div>


</div>
