import { Time } from '@angular/common';

export class Detail {
    
    id: number;
    headerId:number;
    groupId:string;
    section:string;
    qstnId:number;
    //subQstnId:string;
    partId:string;
    instruction:string;
    question:string;
    answer:string;
    category:string;
    extLink:string;
    title:string;
    format:string;
    questionImages:string;
    answerImages:string;
    wireFrameLstring;
    addUser:string;
    addDate:Date;
    addTime:any;
    subqstnId:string;
    vidLink:string;
    wireFrame;
    marks:number;
    status:string;
    inactive:boolean;

}