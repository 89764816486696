

<div class="container" *ngIf="(questions$ | async) as response; else loading">



  <div *ngIf="response.result as q">
    
    
    <div  class="text-center"> Qstn# {{q.groupId}}
  
    <button class= "btn btn-secondary float-left " (click) = 'getPrvsQstnSet(q.id)' >Prvs Qstn-{{q.prvs}} </button>
    <button class= "btn btn-secondary float-right" (click) = 'getNextQstnSet(q.id)' >Next Qstn </button>
  
  </div> 
    
  
     <hr/>
  
     <app-dtl-view-slctr  *ngIf= 'q.format && q.format== "vid" else general'
    [q]=q [i]=i [hdr]=hdr>
      </app-dtl-view-slctr>
  
  
      <ng-template  #general>
    <div *ngIf='q.instruction' >
      <label><b>Ins: </b></label> 
      <span innerHTML= {{q.instruction}}></span>
    </div> 
  
    <div>
      <label><b>qstn:</b></label> 
      <span innerHTML= {{q.question?q.question:q.content}}></span>
    </div>  
  
    <div>
      <label><b>Answer:</b></label> 
      <span innerHTML= {{q.answer}}></span>
    </div>  
    
  </ng-template>
  
  
  
  <br>
  <br>
  <br>
  
  <app-crud-qstndtl   [q] = q [i] = i ></app-crud-qstndtl>
  
  <hr/>
  <app-whole-qstn-btn *ngIf= 'q' [q] = 'q' > </app-whole-qstn-btn>
  </div>

  </div>






  -----

<div class="container" *ngIf="false && (questions$ | async) as questions; else loading">
  

    <!--[q] = 'whole(questions)' ideally qstn viewer should not use qstn itself .. had screwedup title of seo for qstn view hence restored-->
  <app-headings-plain *ngIf= "hdr" [hdr] = hdr [q] = 'whole(questions)' > </app-headings-plain>


<ng-container *ngFor= 'let q of questions, let i = index' > 
  
  
  
  <div *ngIf="q">
    
  <div *ngIf= "i==0" class="text-center"> Qstn# {{q.groupId}}

  <button class= "btn btn-secondary float-left " (click) = 'getPrvsQstnSet(q.id)' >Prvs Qstn </button>
  <button class= "btn btn-secondary float-right" (click) = 'getNextQstnSet(q.id)' >Next Qstn </button>

</div> 
  

   <hr/>

   <app-dtl-view-slctr  *ngIf= 'q.format && q.format== "vid" else general'
  [q]=q [i]=i [hdr]=hdr>
    </app-dtl-view-slctr>


    <ng-template  #general>
  <div *ngIf='q.instruction' >
    <label><b>Ins: </b></label> 
    <span innerHTML= {{q.instruction}}></span>
  </div> 

  <div>
    <label><b>qstn:</b></label> 
    <span innerHTML= {{q.question?q.question:q.content}}></span>
  </div>  

  <div>
    <label><b>Answer:</b></label> 
    <span innerHTML= {{q.answer}}></span>
  </div>  
  
</ng-template>



<br>
<br>
<br>

<app-crud-qstndtl   [q] = q [i] = i ></app-crud-qstndtl>

<hr/>
</div>
</ng-container>

<app-whole-qstn-btn *ngIf= 'questions' [q] = 'whole(questions)' > </app-whole-qstn-btn>



</div>

<ng-template #loading>loading data...</ng-template>
