
<div  class="mb-0" id= "site">

  
  
  
  <div class= "d-none d-md-block">
    <app-sliders class="" > </app-sliders>
  </div>

    
   
    
  
   <!--  
  <app-mcloud *ngIf="(site.includes('csa'))"> </app-mcloud>
-->



<!--
<div class= "pt-5 mt-5">
</div>
-->

<app-thoughtoftheday class=" text-center">... </app-thoughtoftheday>
<app-exam-list   *ngIf='cs.enable("site","examList")'>
  Exam/Board List:
</app-exam-list>

<app-usp *ngIf='cs.enable("home","usp")'></app-usp>


  

  <!--
  <app-our-products> </app-our-products>
-->


<app-platform-set> </app-platform-set>
  
<app-featurete> </app-featurete>
  
  <!--
  <app-our-customers> </app-our-customers>
  
  <app-affiliates *ngIf='cs.enable("site","affiliate")' ></app-affiliates>
-->
  

<app-cmnt-rctn> </app-cmnt-rctn> 



  <!--
<br>
<br>
<br>
<app-reaction *ngIf="cat=='home'"> </app-reaction>
<br>
<br>
<br>

   <ng-container *ngIf= "true" class='text-center'>
   
    
    <p class= "lead, text-center mx-5 mt-1 font-weight-bold font-italic "> We would love your feedback</p>
    
    <app-cmnt-list [featureType] = cat [cmntType]= "'H'+cat" [parentHdrId] = chapter?chapter:0 >      </app-cmnt-list>
       
    </ng-container>
  -->


  </div>


    
    <!--
    <app-comment-list *ngIf="!offering && cat"  [featureType] = cat [cmntType]= "'H'+cat" [parentHdrId] = chapter?chapter:0 > </app-comment-list>
    
    <app-comment-list *ngIf="offering && !chapter"  [featureType] = cat [cmntType]= "'H'+cat" [parentHdrId] = chapter?chapter:0 > </app-comment-list>
    <app-comment-list *ngIf="offering  && chapter"  [featureType] = cat [cmntType]= "'H'+cat" [parentHdrId] = chapter?chapter:0 > </app-comment-list>
    -->
    