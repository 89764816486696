

  import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {User} from '../../user';
import { ExamDataService } from '../../exam-data.service';

import { AppComponent } from '../../app.component';
import { ClientInfoService } from '../../shared/client-info.service';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss']
})
export class AddSiteComponent implements OnInit {

  avl$: Observable<any>;
  siteForm: FormGroup;
  submitted = false;
  success = false;
  user :User;
  name1:string;
  validSiteCode: any;
  get site(){ return this.cs.getSite()};
  get root(){ return this.cs.getRoot()};
  idAvailable = null;
  
  constructor(private app: AppComponent, private formBuilder: FormBuilder,private dataService: ExamDataService,private cs:ClientInfoService) {
   }

   formControlValueChanged() {
    this.siteForm.get('owner').valueChanges.subscribe(
        (mode: string) => {
            console.log(mode);
        });
}

  ngOnInit() {

  this.siteForm = this.formBuilder.group({
      name: ['', Validators.required],
      location: ['', Validators.required],
      owner: ['', Validators.required],
      branchType:['',  ],
      type:['',  ],
      email:['',],
      phone:['',  ],
      address:['',  ],
      status:['', ],
      extra:['',],
      domain:['',],
      ovrSite:['',],
    });

    this.formControlValueChanged();
 
}


showResponse(event) {

}

isNewsActive() {
  
}

isAvl(domain:string,owner:string){
const formValue = this.siteForm.value;
this.idAvailable = null;
this.dataService.isIdAvailable(domain,formValue.owner).
subscribe((val) => {this.idAvailable = val;});

}

validateSiteCode(ovrSite:string){
  const formValue = this.siteForm.value;
  this.validSiteCode = null;
  this.dataService.validateSiteCode(formValue.ovrSite).
  subscribe((val) => {this.validSiteCode = val;});
  
  }

  
/*
isAvl(domain:string,owner:string){
 return this.avl$= this.dataService.isIdAvailable(domain,owner); 
 */

onSubmit() {

    this.submitted = true;

    if (this.siteForm.invalid) {
        return;
    }
    
    var site= this.site;
    if(site.length ==3){
      site= site+ "self-cmn";
    }

    /*
    if(this.siteForm.controls.overSite.value){
    site= this.siteForm.controls.overSite.value; 
    }
*/

    const userPayload = {
      name: this.siteForm.controls.name.value,
      location: this.siteForm.controls.location.value,
      owner: this.siteForm.controls.owner.value,
      //password: this.siteForm.controls.password.value,
      //branchType: this.siteForm.controls.branchType.value,
      //grade: this.siteForm.controls.type.value,
      email: this.siteForm.controls.email.value,
      phone: this.siteForm.controls.phone.value,
      domain: site,
      address:this.siteForm.controls.address.value,
      //roles:this.siteForm.controls.roles.value,
      //status:this.siteForm.controls.status.value,
      //shrGrp:this.siteForm.controls.shrGrp.value,
      //mentorId:this.siteForm.controls.mentorId.value,
      //mainCenter:this.siteForm.controls.mainCenter.value,
      //extra:this.siteForm.controls.extra.value,
    }

//alert('user  json is : '+ userPayload.name);

 /*
  this.dataService.addComment(commentPayload).subscribe(data => {
        //alert(data.status + data.message+data.result.name+ data.result.message+data.result.phone);
      )};

*/


 this.dataService.addSite(userPayload).subscribe(data => {
 //alert("calling Welcome to ExamSteppers, You are all set" );
let msg: string= (data as any).message;
      console.log(data);
      if(data.status === 200) {
       //alert(data.result.name + data.result.message+data.result.phone);
       //alert("Welcome to ExamSteppers, You are all set");
       this.cs.addMsg((data as any).message);
      if(msg.startsWith("ERROR:")){
        this.success = false; 
      }else{
       this.success = true;
      }
         /*
       localStorage.clear();
       localStorage.setItem('participant',JSON.stringify(data));
       //console.log(JSON.stringify(data));
       this.route.navigate(['/quiz']);
  */

        //window.localStorage.setItem('token', data.result.token);
        //this.router.navigate(['list-user']);
      }else {
        this.success = false;
        //alert("Sorry we got duplicate owner,please try again with unique owner or email us at examsteppers@gmail.com")
        alert(data.message);
      }

    }



    );


}

}
