
<!-- search component
<app-courselist  > </app-courselist>
-->



<div class="row px-5">
  <div class="column"></div>
  <div class="column column-50">
      <chat-dialog></chat-dialog>
  </div>
  <div class="column"></div>
</div>

