import { Component, OnInit,Input,Output,EventEmitter,OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  template: `
    <input
      type="text"
      [placeholder]="placeholder"
      (keyup)="updateSearch($event.target.value)"
    />`
})
export class SearchComponent implements OnDestroy {
  // Optionally, I have added a placeholder input for customization
  @Input() readonly placeholder: string = '';
  //delayedWord has debounced search term which parent can get directly..
  @Output() delayedWord: EventEmitter<string> = new EventEmitter();
  private _searchSubject: Subject<string> = new Subject();
  constructor() {
    this._setSearchSubscription();
  }
  //get value update from UI
  public updateSearch(searchTextValue: string) {
//emit value to private searchSubject
console.log(searchTextValue);
    this._searchSubject.next( searchTextValue );
  }
  private _setSearchSubscription() {
    //delayed value is emitted to @output which parent can get
    //note: only debounced value from private subject is emitted
    this._searchSubject.pipe(
      debounceTime(500)
    ).subscribe((searchValue: string) => {
      this.delayedWord.emit( searchValue );
    });
  }
  ngOnDestroy() {
    this._searchSubject.unsubscribe();
  }
}
