import { Component, Input, OnInit } from '@angular/core';
import { ChptrService } from '../chptr-lstr/chptr.service';

@Component({
  selector: 'app-simple-chptr',
  templateUrl: './simple-chptr.component.html',
  styleUrls: ['./simple-chptr.component.scss']
})
export class SimpleChptrComponent implements OnInit {
  @Input() header:any;
  i:number;
  constructor(public s:ChptrService) { }

  ngOnInit(): void {
  }

  

}
