

<div *ngIf='filter(subjects) && filter(subjects).length>1' class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
  <!--btn btn-outline-light-->
  <button class="mx-2" ngbDropdownToggle> 
    <!--
      {{selected?selected:"Subject"}} 
    -->
book
      </button>
        
        <div class="dropdown-menu" ngbDropdownMenu >

          <!--
          <button  ngbDropdownItem  (click) = 'browse("")' > show list</button>  
          
          <button  *ngIf= "false" ngbDropdownItem  (click) = 'browse(allSub)' > show All</button>  
        -->
          
          <button  *ngFor = 'let sub of filter(subjects)' ngbDropdownItem  (click) = 'browse(sub)' >
            {{getBookBranch(sub)}} </button>  
           
    </div>
      </div>