import { Component, OnInit } from '@angular/core';
import { NgModule, ElementRef, Injectable } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { ReactiveFormsModule, FormControl, FormsModule } from "@angular/forms";
import { BoardDataService } from '../../board-data.service';
import {
  HttpClientJsonpModule,
  HttpClientModule,
  HttpClient
} from "@angular/common/http";
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-featurelist',
  templateUrl: './featurelist.component.html',
  styleUrls: ['./featurelist.component.scss']
})
export class FeaturelistComponent implements OnInit {
  readonly premiumSignNeeded = environment.premiumSignNeeded;
  title : string;
  headerId: number;
  headerIdPrem: number;
 header:any;
  questions:any;
  headerLearn:any;
  questionsLearn:any;
  headerPrem:any;
  questionsPrem:any;

    site:string;
    cat:string;
  board: string;
   course:string;
   feature:string;
   offering:string;
   subject:string;
  signedUser:string;

  constructor(private route: ActivatedRoute,
      private router: Router, private elementRef:ElementRef, private dataService: BoardDataService) {
   this.board = this.elementRef.nativeElement.getAttribute('board');
  //this.course = this.elementRef.nativeElement.getAttribute('course');

  this.route.params.subscribe( params => {
  this.site= params['site'];
  this.cat= params['cat'];

  //alert(this.site);
  this.board= params['board'];
  this.course= params['course'];
  this.feature= params['feature'];
  this.offering= params['offering'];
  this.subject= params['subject'];
  });

    //alert("board,course"+ this.board + this.course);
  }


  ngOnInit() {
    //Learning Features

    this.headerIdPrem= 20183;
    /* check later
    this.dataService.getCfgHeaderById(this.site,this.board,this.course,"cfg",this.subject,this.headerIdPrem).subscribe(data => {
        this.headerLearn= data;
       });
    */
       this.dataService.getCfgQuestions(this.site,this.board,this.course,"cfg",this.subject,this.headerIdPrem).subscribe(dataList => {
           this.questionsLearn = dataList;
       });



       //Premium Content

       this.headerIdPrem= 33901;
       /*check later
       this.dataService.getCfgHeaderById(this.site,this.board,this.course,"cfg",this.subject,this.headerIdPrem).subscribe(data => {
           this.headerPrem= data;
          });
          */
       this.dataService.getCfgQuestions(this.site,this.board,this.course,"cfg",this.subject,this.headerIdPrem).subscribe(dataList => {
              this.questionsPrem = dataList;
          });
/*
          this.dataService.getSignedUser().subscribe(response=> {
                if(response && response != "Guest") {
                this.signedUser = response as string;
              }
            });

*/

  }


  showEE(category:string, instruction:string){
    if(!this.board){
      alert("Please select exam/Class first");
        this.router.navigate([this.site,'exams','board','course',instruction],{ fragment: 'boards' } );
        return false;
    } else{
      this.router.navigate([this.site,category,this.board,this.course,instruction]  );
    }
  }


  authorized(){

    if(!this.premiumSignNeeded || (this.signedUser && this.signedUser!= 'Guest'))  {
      return true;
    }
    else{
      return false;


    }
  }


}
