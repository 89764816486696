export class Detail {
    id: number;
    sequence: string;
    subSeq: string;
    part:string;
    qstnTbl:string;
    tagHdrId:number;
    qstnDtlId: number;

    

    
    /*
    message: string;
    name: string;
    domain:string;
    role:string;
    status:string;
    featureType: string;
    cmntType: string;
    parentHdrId:number;
    parentDtlId: number;
    parentTable:string;
    addDate: string;
    addTime:string;
    active: boolean;
    response:string;
    rspnseUser:string
    rspnseDate:string;
    rspnseTime:string;
*/
    }
