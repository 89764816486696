<div class=" badge btn-group">
    
    <div class=" btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
      <button class="btn btn-outline-light  " ngbDropdownToggle>
        <span > {{selectedExam?selectedExam.board :"Select Exam/Board"}}</span>  </button>
      
        <div class="dropdown-menu" ngbDropdownMenu >
        <button *ngFor = 'let exam of list' ngbDropdownItem  (click) = 'selectedExam = exam; browseClass(selectedExam,selectedClass?.course)'>
          {{exam.board}} </button>
       </div>
    </div>

    
    <!--
      {{selectedExam|json}}
      
      class="text-muted"-->

    <div *ngIf= "selectedExam" class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
      <button class="btn btn-outline-light  " ngbDropdownToggle> 
        {{selectedClass?selectedClass.course :"Class"}}  
        </button>
      <div class="dropdown-menu" ngbDropdownMenu >
        <button  *ngFor = 'let c of selectedExam.courses' ngbDropdownItem  (click) = 'selectedClass = c; browseClass(selectedExam,selectedClass.course)'> <span innerHTML= '{{c.course}}'> </span></button>
        <!--browseClass(selectedExam.board,selectedClass.course)  {{c.course}}-->
    </div>
    
</div>


<!-- don't list just cmn classSec-->


<ng-container  *ngIf= 'selectedClass && selectedClass.sections && 
( showSection || !(selectedClass.sections.length==1 &&selectedClass.sections[0].clsSec == "cmn" ) )'>

<!--!section?{{showSection}}!--clsSec?{{showClsSec(selectedClass)}}-->

 <ng-container  *ngIf= 'selectedClass.sections.length >0 || showClsSec(selectedClass)'
  >
<div *ngIf= "showSection && selectedClass" class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
  
    <button class="btn btn-outline-light  " ngbDropdownToggle> 
        {{selectedSection?getSecText(selectedSection.clsSec) :"Section"}} 
        </button>
    <div class="dropdown-menu" ngbDropdownMenu >
      <button  *ngFor = 'let sec of selectedClass.sections' ngbDropdownItem  (click) = 'selectedSection = sec; setClsSec(sec.clsSec)' >{{getSecText(sec.clsSec)}} </button>
      
  </div>
  
</div>
</ng-container>
</ng-container> 

<!-- 
{{selectedSection|json}}
-->
  <!--&& betaViewer-->
<div *ngIf=  "(selectedSection)" class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
    <button class="btn btn-outline-light  " ngbDropdownToggle> 
        {{selectedSubject?selectedSubject.name :"Subject"}} </button>
         <div class="dropdown-menu" ngbDropdownMenu >
          
          <ng-container *ngFor = 'let sub of selectedSection.subjectDtos'>
          <button *ngIf= "showSub(sub)"  ngbDropdownItem  (click) = 'selectedSubject = sub; browseSubject(sub.name)' >{{sub.name}}
          <span *ngIf="showTeacher">-{{sub.teacher}} </span>  
          </button>    
        </ng-container>
          
        <button  ngbDropdownItem  (click) = 'selectedSubject = null; browseSubject("")' > show list</button>  
        <div *ngIf = "showAll123">
        <button  ngbDropdownItem  (click) = 'selectedSubject = allSub; browseSubject("subject")' > show All</button>  
      </div>

        </div>
        
          <app-context  > </app-context>
      </div>
    

  </div>
