import { Component, OnInit } from '@angular/core';
import { NgModule, ElementRef, Injectable } from "@angular/core";
import { BrowserModule, Title,Meta } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { ReactiveFormsModule, FormControl, FormsModule } from "@angular/forms";
import { BoardDataService } from '../../board-data.service';
import { Board } from '../../board';
import { ClientInfoService } from '../../shared/client-info.service';
import {
  HttpClientJsonpModule,
  HttpClientModule,
  HttpClient
} from "@angular/common/http";
/*
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
*/

import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FixedDataService } from '../../shared/fixed-data.service';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements OnInit {
  site:string;
  cat:string;
  board:string;
  course:string;
  offering: string;
  feature:string;
  subject: string;
  chapter: string;
  content: string;
  boardNeeded:boolean;
  
// Declaring the Promise, yes! Promise!
btnMapLoaded: Promise<boolean>;
  count: any;

  constructor(private route: ActivatedRoute,
    private router: Router, private elementRef:ElementRef, private dataService: BoardDataService, private titleService: Title,
    private metaTagService: Meta, private cs:ClientInfoService, public fd:FixedDataService) {


      let btnData= JSON.parse(localStorage.getItem(this.site + '-btnData'));

if(btnData){
fd.loadBtnData(btnData);
this.btnMapLoaded = Promise.resolve(true);
// console.log('API CALL avoided. getting btnMap');
}

//!fd.updatedBtnMap
if(!this.btnMapLoaded ){
//console.log('calling overrideMap');
this.dataService.getBtnMap(this.site).subscribe(
data=> {
 // console.log(data);
 if((data as any).dataList  && (data as any).dataList.length >0 ) {
//let map= (data as any).map;
//fd.loadBtnAuthMap(map);
//console.log('API CALL. getting btnMap');
fd.loadBtnData(data);
localStorage.setItem(this.site +'-btnData', JSON.stringify(data));
this.btnMapLoaded = Promise.resolve(true); // Setting the Promise as resolved after I have the needed data
} else{
 
 this.count = this.count +1; 
 if(this.count > 2){ this.cs.addMsg('internet/backend problem,try again',"warning");}

}
});
}

  }


  
random(){
  if(Math.floor(Math.random()*100)/5 == 0){
    return true;
  } else{
    return false;
  }
 }
 
  ngOnInit() {

    this.router.events.pipe(
    filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.boardNeeded=this.cs.boardMust();
      //this.fetchData();

        this.cs.updateParms(this.route);
        this.boardNeeded=this.cs.boardMust();
        this.site=this.cs.getSite();
        this.board=this.cs.getBoard();
        this.course=this.cs.getCourse();
        this.cat=this.cs.getCat();
        this.feature=this.cs.getFeature();
        this.offering=this.cs.getOffering();

    });


    this.cs.updateParms(this.route);
     this.route.params.subscribe( params => {
      this.feature=this.cs.getFeature();
    //alert("from" +  params);
    //console.log(params);
  //  this.site= params['site'];
    //this.cat= params['cat'];
  //  this.board= params['board'];
  //  this.course= params['course'];
    //this.feature= params['feature'];
  //  this.offering= params['offering'];
  //  this.subject= params['subject'];
  //  this.chapter= params['chapter'];
  //  this.content= params['content'];
    //this.cs.setFeature(this.feature);
    this.boardNeeded=this.cs.boardMust();
    this.site=this.cs.getSite();
    this.board=this.cs.getBoard();
    this.course=this.cs.getCourse();
    this.cat=this.cs.getCat();
    this.feature=this.cs.getFeature();
    this.offering=this.cs.getOffering();
    });

  }

  enable(btnId:string,i?:number,rec?:any){
    let component = 'vidBand';
    return this.cs.enable(component,btnId);
    }
    

}
