
<!--
  <h5 class="pt-5" >
    Feeling something?<i class="fa fa-handshake-o" aria-hidden="true"></i>..
    <small class="text-muted">Leave Feedback please...</small>
   </h5>
  -->

<span *ngIf= "!hideTitle" class= "jumbotron-fluid" innerHTML = {{this.title}} >  </span>
  <button *ngIf= 'ls.enable("addComment",crtCmnt.cmntType)' class="btn btn-outline-primary badge border-0 " (click) = "showAdd = !showAdd" > {{ showAdd ? 'Hide' : 'Add' }} <i class="fa fa-plus-circle" aria-hidden="true"></i> </button>


<ng-container *ngIf= "showAdd"> 

<app-crctn-rcmnd-add  *ngIf='crtCmnt.cmntType == "Hcrct"; else addCmnt;' [cmntType] ='"Hcrct"' [hdrObj]= hdrObj > </app-crctn-rcmnd-add>

<ng-template  #addCmnt>
 <app-add-comment   [iComment]= crtCmnt [cmntHeading] = title  > </app-add-comment>
</ng-template>


</ng-container>

<!--
<app-file-upload [iComment]= crtCmnt *ngIf="showUploadFile"> </app-file-upload>
-->
