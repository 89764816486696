import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { RecSlctrService } from './rec-slctr.service';

@Component({
  selector: 'app-rec-slctr',
  templateUrl: './rec-slctr.component.html',
  styleUrls: ['./rec-slctr.component.scss']
})
export class RecSlctrComponent implements OnInit {
@Input() fldVal: any;
@Input() storeKey:string
storedVal:string;
showUnselect: boolean = false;

constructor(private  loc:Location ) {
  
  RecSlctrService.allSelectedI
    .subscribe(res=>{
     //console.log("hello all-lctd", res); 
    this.showUnselect= res; })
}

  ngOnInit(): void {
    
  }
store(){
this.storedVal = localStorage.getItem(this.storeKey);
//this.q= JSON.parse(quizStr);
this.storedVal = [this.storedVal,this.fldVal].filter(Boolean).join(",");
localStorage.setItem(this.storeKey, this.storedVal);
this.showUnselect= true;
}

remove(){
  this.storedVal = localStorage.getItem(this.storeKey);
  //this.q= JSON.parse(quizStr);
  this.storedVal = this.storedVal.replace(","+this.fldVal,"");

  localStorage.setItem(this.storeKey, this.storedVal);
  this.showUnselect= false;
  }
 
}

