import { Component, Input, OnInit } from '@angular/core';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';



@Component({
  selector: 'app-news-viewer',
  templateUrl: './news-viewer.component.html',
  styleUrls: ['./news-viewer.component.scss']
})
export class NewsViewerComponent implements OnInit {

  @Input()  q :any;
  @Input()  i:any;
  constructor(public qs:QstnListService) { }
  showA:boolean;
  showCmntI:number;
  showCommentI:number;
  ngOnInit(): void {
  }

showLink(q:any){
  if(this.qs.cs.tagBasedForCreate(this.qs.cs.offering)){return false}

  // if((q.sequence && !q.subSeq )&& (q.instruction || q.question||q.content) && !q.digQstn)
  if((q.sequence && !q.subSeq )&& (q.instruction || q.question||q.content)){return true} else{ return false;}

}
  

}
