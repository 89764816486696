

<div class= "bg img-fluid">
<h2 class= "text-center text-info ">Scientific Formatter</h2>
<h5 class= "my-2"> .</h5>
<br>

<section class="layout">
  <section>
  <textarea [(ngModel)]="mathml" name="mathml" id="mathml" cols="30" rows="20" placeholder="type/paste your tex/mathml expression"></textarea>
  </section>
  <section>
    
    <p [innerHTML]='mathml'> </p>

<!--[appMath]="mathml"-->

  <p class="text-primary mt-5"  *ngIf="mathml;else noExpression" > </p>
    <ng-template #noExpression>
      <div class="no-expression">Expression unavailable</div>
    </ng-template>
  </section>
</section>





</div>


<!--
<img class="img-fluid"  src= "/assets/img/mathteacher.jfif">


<img alt="" class="ue uf" src="https://miro.medium.com/max/9616/0*hOVkfmR0kVtzfhMG" role="presentation" width="4808" height="7208">
-->
