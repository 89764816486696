import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { HeaderService } from '../../tag/header.service';
import { QstnhdrService } from '../qstnhdr.service';

@Component({
  selector: 'app-njson-qstnhdr-list',
  templateUrl: './njson-qstnhdr-list.component.html',
  styleUrls: ['./njson-qstnhdr-list.component.scss']
})
export class NjsonQstnhdrListComponent implements OnInit {

    @Input() headerId: number;
  
     // siteList: any;
      showAdd:boolean;
      title = 'Chapter/Unit List';

  hdrs$: Observable<any>;
  noCall :any;
  expandI:any;
  calling:boolean;
  showPage: true;   
  dtOptions: DataTables.Settings = 
       {
        paging: false,
        pagingType: 'full_numbers',
        pageLength: 7,
        processing: true
    };
  c: any;
    
  fldList1 = [ 'title'];
  fldList2 = [ 'options','batch/sec','status','id','owner'];
  
  dataList: any;
  loading:boolean;  
  grpAsoId: string;
 
      
  constructor(private qh:QstnhdrService,private th:HeaderService, private activatedRoute:ActivatedRoute) {
      
  }
 
     
     
  get allowEdit() {return this.qh.cs.enable('json-qstn-hdr','allowEdit')};
  
  
    ngOnInit(): void {
    
      if(this.qh.offering=='type'){

      }
     
     this.grpAsoId =  localStorage.getItem("grpAsoId");
      this.activatedRoute.params.subscribe(({ subject }) => {
        let offering = this.qh.cs.getOffering();
         if(offering.includes("pyq")){
          this.title = " Previous Question Papers with Answers"
         }

        this.calling = true; 
        if(this.qh.cs.tagBased(offering)){
          this.hdrs$=  this.th.list(offering)
        } else
        {
        this.hdrs$=  this.qh.list(offering)
        };
      }
      );

     if(this.allowEdit) {

     }
   

   this.calling= true;
   
    
  }
  


   
    
  getValList1(c:any, listType?:string ){
        
    return [
           
            this.getTitle(c),
          
           ];
   
   }
   
   getValList2(c:any, listType?:string ){
       
     return [
       c.clsSec,
       c.status,
            c.id,
            c.owner 
        ];
    
    }
   
   
  expand(c:any,i){
    this.c= c;
    this.expandI==i ? this.expandI =-1: this.expandI=i;
    }
  
    clearGrpAso(){
    localStorage.removeItem("grpAsoId");
    }
  
  getTitle(c){
   let title = [c.year,c.chapter,c.chapterId,c.section,c.book,c.branch,c.title].filter(Boolean).join("-"); 
   if(this.qh.offering=='subject'){
   title= [c.subject,c.title].filter(Boolean).join("-");  
   }
   if(this.qh.offering=='type'){
    title= [c.type,c.title].filter(Boolean).join("-");  
    } 
  
   return title;
  }

  getTable(c){
  if(this.qh.cs.tagBased(c.type)){
    return "taghdr"
  } else{
    return "qstnhdr"
    
  }

  }
  }
  