

<div class="footer">
    <app-page-end-footer *ngIf='cs.enable("footer","fixedFooter-site")'  [site] = site> </app-page-end-footer>

    <app-screen-end-footer  *ngIf='cs.enable("footer","stickyFooter-site")' [site] = site> </app-screen-end-footer>
       
    <div class="float-right" id= 'footerNew'> </div>
    <span *ngIf='disableTracking'> Tracking off  </span> 
    <span *ngIf='disableAdvertising'> Advertising off </span> 
</div>

























<div  *ngIf='false && showFixed'> 
<app-signuplogin *ngIf='(cs.getFeature() && cs.getFeature()=="signin") ||
(cs.getCat() && cs.getCat() != "home" && cs.enable("footer","signup-site") )' >
</app-signuplogin>

<!--working below -->

<!--
<app-display-ad> </app-display-ad>

<app-topic-list class=""> </app-topic-list>  
-->

<!-- d-none not working check later-->

<!-- button can't stay here
    
<button *ngIf= 'true|| cs.enable("fixedFooter")' class="float-right mx-5" (click) = 'showFixed=!showFixed'> ...  </button> 
-->


<span *ngIf='false && showFixed'>

<ng-container class="d-block d-md-none">
<app-footer-label  class="d-block d-md-none">  </app-footer-label> 


<span class="my-1 d-block d-md-none"><br> </span>
</ng-container>

<!--https://stackoverflow.com/questions/49494146/bootstrap-4-hide-on-xs-and-sm-not-working
-->

<div   class="float-right" id= 'footerNew'>
 
</div>
  
<!--
<footer>
    <app-footer-label  class='d-none d-md-block' >  </app-footer-label> 
    <app-footer-link [menuType] = "'fixedFooter'" [site]=site > </app-footer-link>
</footer>
-->

</span>
</div>