import { Component, Input, OnInit } from '@angular/core';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';

@Component({
  selector: 'app-dtl-top',
  templateUrl: './dtl-top.component.html',
  styleUrls: ['./dtl-top.component.scss']
})
export class DtlTopComponent implements OnInit {
  @Input()  q :any;
  @Input()  i :number;
  link:boolean;

  constructor(public qs:QstnListService) { }

  ngOnInit(): void {
  
  if(!this.q.subSeq && this.q.content)
  this.link = true;  
  }

}
