import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { SecurityService } from '../../shared/security.service';

@Component({
  selector: 'app-manage-usr',
  templateUrl: './manage-usr.component.html',
  styleUrls: ['./manage-usr.component.scss']
})
export class ManageUsrComponent implements OnInit {

 signupLink :string;
 userDomain:string;
 showLink: boolean;
 get signedUser() {return this.cs.getSignedUser()} ;
get unsigned(){ return this.cs.isUnsigned()}

  constructor(public cs:ClientInfoService,private ss:SecurityService, private router: Router) { }

  ngOnInit(): void {
 
   this.userDomain = this.cs.ss.getUserDomain()?this.cs.ss.getUserDomain():this.cs.getSite() ;

  this.signupLink= [this.cs.getRoot(),this.userDomain,"mng","features","signup"].join("/");

  }

  changeUsr(){
    //headerid is actual user id
   let  id =  this.ss.getHeaderId();
   if(id){
    //localhost/esa/usrdtl/upd/140292
    let usrUrl= [this.userDomain,"usrdtl","upd",id];
    this.router.navigate(usrUrl);
    console.log(usrUrl);
    //    localhost/esa/usrdtl/upd/" + id ;
   }

  } 

 
  

}
