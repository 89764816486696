import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { Comment } from "../comment";

@Component({
  selector: 'app-add-qstn-cmnt',
  templateUrl: './add-qstn-cmnt.component.html',
  styleUrls: ['./add-qstn-cmnt.component.scss']
})
export class AddQstnCmntComponent implements OnInit {
  @Input() cmntType: string;
  @Input() hideTitle: boolean; 
  @Input() featureType: string;
  @Input() parentHdrId: number;
  @Input() parentDtlId: number;
  @Input() mode:string; //pass slides,list
  comment:Comment;
  showAdd:boolean;
    
  constructor(private cs:ClientInfoService) { }

  ngOnInit(): void {
  }

  addComment(){
    this.comment = new Comment();
    this.comment.cmntType= this.cmntType;
    this.comment.featureType= this.featureType;
    this.comment.parentHdrId= this.parentHdrId;
    this.comment.parentDtlId= this.parentDtlId;
    this.comment.domain= this.cs.getSite();
    if(this.featureType== 'trvsn-type' || this.featureType== 'trfc-type'){
      this.comment.subject = this.cs.getSubject(); 
      this.comment.clsSec = this.cs.getCrtClsSec("");
      this.comment.course = this.cs.getCourse();  
     console.log(this.comment); 
    } 
    this.showAdd = true;
    return this.comment;
    //this.router.navigate(['eba','digibest','features','cmnt-add']);
  }


}
