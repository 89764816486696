import { Component, Input, OnInit } from '@angular/core';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';
import { TestService } from '../test.service';

@Component({
  selector: 'app-qstn-status',
  templateUrl: './qstn-status.component.html',
  styleUrls: ['./qstn-status.component.scss']
})
export class QstnStatusComponent implements OnInit {
@Input() qstnList: any;
showQstn: number ;
  
constructor(public ts:TestService,public qs:QstnListService,) { }

ngOnInit(): void {
  }
  
  color(q:any){
    let c= null;
    if(q.ansCmnts && q.ansCmnts.length>0){
     c= q.ansCmnts[0]; 
   }
  if(c) {
    if (c.status &&c.status=='review') { return "orange"} 
    if (c.digAnsr || c.comment) { return "primary"} 
  }

}
  
changeQstn(q:any,i:number){
  q = this.ts.updateDuration(q);
  this.ts.showQstn= i;
}
}
