 
  
import { Component, OnInit, Input } from '@angular/core';
import { Detail } from '../detail';
import { ActivatedRoute, Router } from '@angular/router';
import { DetailService } from '../detail.service';

@Component({
  selector: 'app-update-detail',
  templateUrl: './update-detail.component.html',
  styleUrls: ['./update-detail.component.scss']
})
export class UpdateDetailComponent implements OnInit {
  
  @Input()  id: number;

  detail: Detail;
  submitted:any;

  constructor(private route: ActivatedRoute,private router: Router,
    private detailService: DetailService) { }

  ngOnInit() {
    this.detail = new Detail();
    //alert("hello: " + this.id )
   // this.id = this.route.snapshot.params['id'];
    
    this.detailService.getDetail(this.id)
      .subscribe(data => {
        console.log(data)
        this.detail = data.result;
      }, error => console.log(error));
  }
//data => console.log(data),
  updateDetail() {
    this.detailService.updateDetail(this.id, this.detail)
      .subscribe( error => console.log(error));
    this.detail = new Detail();
    alert("detail updated");
    this.gotoList();
  }

  onSubmit() {
    this.updateDetail();    
  }

  gotoList() {
    DetailService.updateList.next(true);
    //this.router.navigate(['//eba//digibest//features//details']);
  }
}