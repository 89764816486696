
<!--
<div class= "nav navbar row my-2 mx-1 bg-light"> 
</div>
-->
    



<button *ngIf="false && enable('upload')" type="button" class="btn mx-1 btn-outline-primary border-0"
(click)="cardCollapsed=cardCollapsed == 'dos'?null:'dos'"
[attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
{{qsUploadTitle}}
</button>


<!-- cuatro is for builder -->
<ng-container *ngIf='enable("forBldr") && (qs.cat && qs.cat.includes("-bldr"))'>
<!--
  <span  class ="px-1"> | </span>
-->

<button  type="button" class="btn mx-1 btn-outline-primary border-0"
(click)="cardCollapsed=cardCollapsed == 'cuatro'?null:'cuatro'"
[attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
{{qsTitle}}
</button>
</ng-container>





<button *ngIf="enable('video')"  type="button" class="btn mx-1 btn-outline-primary border-0"
(click)="cardCollapsed=cardCollapsed == 'uno'?null:'uno'">
Video
</button>

<button *ngIf='enable("buildFtr") && !(qs.cat && qs.cat.includes("-bldr"))' type="button" class="btn mx-1 btn-outline-primary border-0"
(click)="cardCollapsed=cardCollapsed == 'dos'?null:'dos'"
[attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
 Availble HW
</button>


<button *ngIf="enable('docs')"  type="button" class="btn mx-1 btn-outline-primary border-0"
(click)="cardCollapsed=cardCollapsed == 'tres'?null:'tres'"
[attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
 Study Materials
</button>




<button *ngIf="enable('snote')"  type="button" class="btn mx-1 btn-outline-primary border-0"
(click)="cardCollapsed=cardCollapsed == 'cinco'?null:'cinco'"
[attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
 my Notes
</button>




<button *ngIf="enable('showAsoRec')"  type="button" class="btn mx-1 btn-outline-primary border-0"
(click)="cardCollapsed=cardCollapsed == 'seis'?null:'seis'"
[attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
 Associated Contents
</button>




<button *ngIf="enable('chapterBstr')"  type="button" class="btn mx-1 btn-outline-primary border-0"
(click)="cardCollapsed=cardCollapsed == 'siete'?null:'siete'"
[attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
 Chapter Booster
</button>


<app-btn-map [section]='"qstnList"'> </app-btn-map> 


<!-- Cinco Seis -->


<!-- smart Homework -->
<!--!collapseVid ||-->

<div class= "vw-100"> 
  <ng-container>
    <div id="collapseExample" [ngbCollapse]=" (cardCollapsed != 'uno')">
      <div class="card">
        <div class="card-body">
          <div>
            <div class="card">
              <div class="card-body">
                
                <app-taghdr-selector *ngIf="enable('videoLogged') && cardCollapsed == 'uno'"  [hdr] = hdr [crtHdr] = 'prepHdr(hdr,vidGrp)' 
                [slctType] = vidGrp> </app-taghdr-selector> 
                
                
                <ng-container *ngIf= "enable('videoUnsigned')">
                                 <app-dt-selector *ngIf="cardCollapsed == 'uno'" [hdr]='hdr' [slctType]= "'vid'" [unitId]=hdr.id></app-dt-selector>

                                 <p *ngIf= "unsigned" class= "font-italic" >Note: Please signup/signin free to get personalized experience.</p> 

              </ng-container>

              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      
      <div id="collapseExample" [ngbCollapse]="cardCollapsed != 'dos'">
      <div class="card">
        <div class="card-body">
         
    <app-dhw-bldr  *ngIf= "qs.cat.includes('dhw')" [crtHdr] = 'prepHdr(hdr,dhwGrp,true)'> </app-dhw-bldr>

          <!--
        <app-taghdr-selector *ngIf="cardCollapsed == 'dos'"  [hdr] = hdr [crtHdr] = 'prepHdr(hdr,dhwGrp,true)'  [slctType]= '"twip-"+ dhwGrp'> </app-taghdr-selector> 
        -->

      </div>
      </div>
      </div>
      
             
    
      <div id="collapseExample" [ngbCollapse]="cardCollapsed != 'tres'">
        <div class="card">
          <div class="card-body">
           <!-- {{qs.groupId}}--{{docGrp}}-->
            <app-taghdr-selector *ngIf="cardCollapsed == 'tres'"  [hdr] = hdr [crtHdr] = 'prepHdr(hdr,docGrp)' 
            [slctType] = docGrp> </app-taghdr-selector> 
                 
        </div>
        </div>
        </div>


        <div id="collapseExample" [ngbCollapse]="cardCollapsed != 'cuatro'">
          <div class="card">
            <div class="card-body">
             
              <!--[slctType]= '"twip-"+qsGrp'-->
              
          <app-taghdr-selector *ngIf="cardCollapsed == 'cuatro'"  [hdr] = hdr [crtHdr] = 'prepHdr(hdr,qsGrp)' [slctType]= '"twip-"+qsGrp'   > </app-taghdr-selector> 

             

          </div>
          </div>
          </div>


          <div id="collapseExample" [ngbCollapse]="cardCollapsed != 'cinco'">
            <div class="card">
              <div class="card-body">
               <!-- {{qs.groupId}}--{{docGrp}}-->
                <app-taghdr-selector *ngIf="!unsigned && cardCollapsed == 'cinco'"  [hdr] = hdr [crtHdr] = 'prepHdr(hdr,noteGrp)' 
                [slctType] = noteGrp> </app-taghdr-selector> 
                <p *ngIf= "unsigned" class= "font-italic" >Note: Please signup/signin free to get personalized experience.</p> 
      
            </div>
            </div>
            </div>

            <div id="collapseAsoRec"   [ngbCollapse]="cardCollapsed != 'seis'">
              <div class="card">
               <div class="card-body">
  
     
<app-aso-hdr-lstr *ngIf="!autoMaker" [grpAsoId]= getgrpAsoId() > Associated Records...</app-aso-hdr-lstr>
         
  <!--
  <app-chptr-src-slctr *ngIf="!autoMaker"  [grpAsoId] = getgrpAsoId() [refHdr]=hdr >  </app-chptr-src-slctr>
  -->

               </div>
              </div>
              </div>
          
          
              <div id="collapseAsoRec"   [ngbCollapse]="cardCollapsed != 'siete'">
                <div class="card">
                 <div class="card-body">
    
                   <!--
       
       
       <app-taghdr-selector   [hdr] = hdr [crtHdr] = 'prepHdr(hdr, "tgubstr" )' [slctType]= '"tasgn-tgubstr"'   > </app-taghdr-selector> 
       
                  -->

       

    <app-associated-rec *ngIf=" !autoMaker" [grpAsoId]= getgrpAsoId() [slctType] = '"tasgn-tgubstr"' [asoHdr] = 'hdr' >  Associated Contents...</app-associated-rec>
    
    <p *ngIf= "unsigned" class= "font-italic" >Note: Please signup/signin free to get personalized experience.</p>

    <!--
     <button *ngIf= "!unsigned"  (click) = "autoMaker = !autoMaker">auto-maker  </button>
     
     <app-chptr-src-slctr *ngIf="autoMaker"  [grpAsoId] = getgrpAsoId() [refHdr]=hdr >  </app-chptr-src-slctr>
    -->
     
  
                 </div>
                </div>
                </div>


      </ng-container>

      <app-hdr-fld-changer *ngIf= 'enable("changeType")' [h] = hdr [fieldName] = '"type"' > </app-hdr-fld-changer>
      
</div>
     
    
       
        
