import { Injectable } from '@angular/core';
import { ClientInfoService } from '../shared/client-info.service';
import { FixedDataService } from '../shared/fixed-data.service';
import { PropFromUrlService } from '../shared/prop-from-url.service';
import { Format } from './format';

@Injectable({
  providedIn: 'root'
})
export class FldsService {
  private prop: PropFromUrlService;
  list: any;

  constructor(private fd: FixedDataService, private cs: ClientInfoService) { }


  ngOnInit(): void {
    // to be done......incomplete
    let propFile = 'ExamList.json';
    this.prop.getPropList(propFile).subscribe(data => {
      (this.list as any) = data;
    }

    );
  }




  getFlds(offering: string) {
    let justType = this.cs.getJustOffering(offering);
    let d = this.fd.selectQDFlds(justType);
    //this.primary= d.p;
    //this.secondary= d.s;
    //this.additional = ['-'].concat(d.p).concat(d.s);
    return d;
  }




  getFormatList(justType: string) {

    let formatList: Format[] = new Array();
   // formatList.push(new Format('qstn', 'normalqstn-ansr type'))

    switch (justType) {
      case 'vid':
        formatList.push(new Format('vid', 'video'))
        formatList.push(new Format('vidLink', 'link based video'))
        break;
        
        case 'cfg':
          formatList.push(new Format('course', 'Course'))
          formatList.push(new Format('btnAuth', 'btn Auth'))
          break;
        case 'doc':
        formatList.push(new Format('doc', 'Document'))
        formatList.push(new Format('pyq', 'prvs year qstns'))
        formatList.push(new Format('hwh', 'Textbook Solution'))
        break;
      
        case 'snote':
          formatList.push(new Format('snote', 'normal QA type '))
          formatList.push(new Format('doc', 'Document'))
          formatList.push(new Format('vid', 'video'))
          break;

        default:
        formatList.push(new Format('vid', 'Video'))
        formatList.push(new Format('doc', 'Document'))
        break;
    }

    return formatList;

  }


}