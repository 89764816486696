

  import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { HeaderService } from '../../tag/header.service';
import { QstnhdrService } from '../qstnhdr.service';

@Component({
  selector: 'app-chptr-src-slctr',
  templateUrl: './chptr-src-slctr.component.html',
  styleUrls: ['./chptr-src-slctr.component.scss']
})
  export class ChptrSrcSlctrComponent implements OnInit {

    @Input() grpAsoId: string;
    @Input() hdr:any;
    storeKey: string = "grpAsoId";
    @Input() refHdr:any;
   
     // siteList: any;
      showAdd:boolean;
      title = 'Chapter/Unit List';

  hdrs$: Observable<any>;
  noCall :any;
  expandI:any;
  calling:boolean;
  get usrClsSec() {return this.qh.cs.getClsSec()}

  
  dtOptions1: DataTables.Settings = 
       {
        searching:true,
        paging: true,
        info:true,
        pagingType: 'full_numbers',
        pageLength: 7,
        processing: true
    };


//enable above for normal case.. 
    dtOptions2: DataTables.Settings = 
    {
     searching:false,
     paging: false,
     info:false,
     //pagingType: 'full_numbers',
     //pageLength: 7,
     //processing: true
 };

  c: any;
    
  fldList = [ 'title','type','Batch','status','option'];

  dataList: any;
  loading:boolean;  
  //grpAsoId: any = 58651;
  srcKey: string;
  dtOptions: DataTables.Settings;
 
      
  associate(){
    localStorage.setItem(this.storeKey, this.grpAsoId);
    this.ths.cs.navigate("dcstore", "");  
     }
  
     
      
    
  getValList(c:any, listType?:string ){
        
     return [
           // c.id,
            this.getTitle(c),c.type,c.clsSec,c.status 
        ];
    
    }
  checkClsSec(c:any){
    return !c.clsSec || c.clsSec == this.usrClsSec

  }
  
  get allowEdit() {return this.qh.cs.enable('json-qstn-hdr','allowEdit')};
  
  constructor(public qh:QstnhdrService,public ths: HeaderService, private activatedRoute:ActivatedRoute) {
    
    const bstrType = "unit";
    this.srcKey= bstrType + "-srcIds";

  }
  
    ngOnInit(): void {
      if(this.qh.cs.chapter){
        this.dtOptions = this.dtOptions1;
      }else{
        this.dtOptions = this.dtOptions2;
      }

      this.activatedRoute.params.subscribe(({ subject }) => {
        let offering = this.qh.cs.getOffering();
         if(offering.includes("pyq")){
          this.title = " Previous Question Papers with Answers"
         }

        this.calling = true; 
        
         this.hdrs$ = this.ths.getUnitHeaders('type',this.grpAsoId);

        /*
        if(this.grpAsoId){
         this.hdrs$ = this.ths.getUnitHeaders('type',this.grpAsoId);
        } else{
        
          this.hdrs$=  this.qh.list(offering)
        }
        */

        });
        

     if(this.allowEdit) {

     }
   

   this.calling= true;
   
    
  }
  
   
  expand(c:any,i){
    this.c= c;
    this.expandI==i ? this.expandI =-1: this.expandI=i;
    }
  
  
  getTitle(c){
  
   return [c.year,c.chapter,c.section,c.book,c.branch,c.title].filter(Boolean).join("-"); 
  }
  getTable(c){
  if(this.qh.cs.tagBased(c.type)){
    return "taghdr"
  } else{
    return "qstnhdr"
    
  }

  }
  }
  
