
<div *ngIf="calling; else noCall" class= "" >
    
    <app-cmnt-title [crtCmnt] = 'prepNewCmnt(cmntType)' [hideTitle] = hideTitle [hdrObj] = hdrObj ></app-cmnt-title>
         
        
    <div  *ngIf="(cList$ | async)?.dataList as cmnts; else loading">

        <button *ngIf= "cat && cat.startsWith('cmnt')"   (click)= 'showDataTable= !showDataTable'> Data Table</button>

        <div  *ngIf = "cmnts && showDataTable">
           <p class= "lead">Tabular Data: </p>
           
           <!--
           <app-cmnt-table [list]= cmnts> </app-cmnt-table>
         -->
         <app-data-lister [dataList]=cmnts > </app-data-lister>
        
        </div>

       
        <ng-container *ngIf="cmnts.length  ; else noItems">
        <app-feedbacks *ngIf= "mode=='slides' && cmntType != 'HnewInfo'" [cmnts] = cmnts>  </app-feedbacks>

        <app-info-center *ngIf= "mode=='slides' && cmntType == 'HnewInfo'" [cmnts] = cmnts>  </app-info-center>
        

        <ng-container  class="bg-info  row " *ngIf="mode== 'list'; else noItems">
            <tr>
               
            <td>
                
            <div class = " " *ngFor="let c of cmnts" class="col-12">
                <ng-container  [ngSwitch]="cmntType.trim()">

                    <div *ngSwitchCase='"Hinfo"' class=" row">
                        <app-info-shower [c]=c> </app-info-shower>
                    </div>

                    <div *ngSwitchDefault class="bg-color ">
                        <app-cmnt-view  [c]=c class= "row" > </app-cmnt-view>
                        <app-cmnt-rspnse class= "row" [c] =c> </app-cmnt-rspnse>
                    </div>
                    
                     <app-cmnt-crud *ngIf= "ls.enable('crud',cmntType)"[c]=c >  </app-cmnt-crud>   




                </ng-container>

            </div>
        </td>
        </tr>

        </ng-container>

        
    </ng-container>   

        <ng-template #noItems>
    
          <!-- by default show add?  
<app-add-comment *ngIf= "!cmnts || cmnts.length==0" [iComment]= 'prepNewCmnt(cmntType)' [cmntHeading] = title (update)="fetchData($event)" > </app-add-comment>
-->
            </ng-template>
    </div>
    <ng-template #loading>loading......</ng-template>
</div>
<ng-template #noCall>!</ng-template>
