import { Component, Input, OnInit } from '@angular/core';
import { ViewService } from '../view-lstr/view.service';

@Component({
  selector: 'app-status-based-view',
  templateUrl: './status-based-view.component.html',
  styleUrls: ['./status-based-view.component.scss']
})
export class StatusBasedViewComponent implements OnInit {
  @Input() hdr: any;
  @Input() i: number;
    
  teacherView = ['teacher','crclm_mngr','tutor'];
  viewGroup: string;

  constructor(public vs: ViewService ) { }
  
  ngOnInit(): void {
    if(this.vs.groupId){
   if(this.teacherView.includes(this.vs.groupId)){
     this.viewGroup = 'teacher';
   } else{
    this.viewGroup = 'learner';
   }
  }
  }

}
