import { Component, Input, OnInit } from '@angular/core';
import { ViewService } from '../../qstn/view-lstr/view.service';
import { NavbackService } from '../../gen/navback.service';
import { BstrService } from '../bstr-mkr/bstr.service';
import { Header } from '../header';
import { HeaderService } from '../header.service';
import { BstrMkr } from '../bstr-mkr/bstr-mkr';


@Component({
  selector: 'app-cstm-bstr-mkr',
  templateUrl: './cstm-bstr-mkr.component.html',
  styleUrls: ['./cstm-bstr-mkr.component.scss']
})
export class CstmBstrMkrComponent implements OnInit {
  
  @Input() bstrType:string;
  @Input() refHdr:any;
  

  constructor(private back:NavbackService, public vs: ViewService, public bs:BstrService) { }

  ngOnInit(): void {
      }

  makeCstmBstr(bstrType: string, ovrStatus?:string,nav?:boolean){
    

    let bstrMkr= new BstrMkr();
    let newHdr= this.bs.getHdr(bstrType); 

localStorage.getItem("templateSrc");
let temp = localStorage.getItem('templateSrc');
0
let tempSrc = JSON.parse(temp);
if(tempSrc){
newHdr.title = tempSrc.title;
newHdr.chapterId= tempSrc.chapterId;
} else{
  newHdr.title = this.refHdr?.title;
  newHdr.chapterId= this.refHdr?.chapterId;
}

switch(bstrType){
  case "unit":
newHdr.type= "twip-tgubstr";
newHdr.status= "wip";
break;
case "term":
  newHdr.type= "twip-tgmbstr";
  newHdr.status= "wip";
  break;
  case "special":
    newHdr.type= "twip-tgpbstr";
    newHdr.status= "wip";
    break;
  
}

console.log(newHdr);
bstrMkr.template= newHdr;
bstrMkr.srcHdrIds = this.bs.getSrcHdrids(bstrType);
bstrMkr.setCount= 1;


    this.bs.ths.makeCstmBstr(bstrMkr)
        .subscribe(
          data => {
          if(data.status==200){
          this.bs.cs.addMsg(data.result.length + " : new record added ") ; 
          localStorage.removeItem(bstrType + "-srcIds");
          if(nav){this.vs.showChptr(data.result,"te");}
          } else{
            this.bs.cs.addMsg( data + " : something went wrong, please try later") ; 
          }

        },
          error => {console.log(error);
          this.bs.cs.addMsg( " request failed,check log ") ; 
    } );
  }   


    
process(){
  localStorage.removeItem(this.bstrType + "-srcIds");
  localStorage.removeItem("refbstrId");
  localStorage.removeItem("templateSrc");

  this.back.back();
  
  }
}
