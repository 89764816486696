import { Component, Input, OnInit } from '@angular/core';
import { BtnEnablerService } from '../../shared/btn-enabler.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { QstnListService } from '../qstn-list/qstn-list.service';
import { CntxtService } from './cntxt.service';

@Component({
  selector: 'app-cntxt-menu',
  templateUrl: './cntxt-menu.component.html',
  styleUrls: ['./cntxt-menu.component.scss']
})
export class CntxtMenuComponent implements OnInit {
  @Input() hdr:any;
  @Input() i:number;
  
  showHinfo:boolean;
  showCmnt:boolean;
  showTHL:boolean;
  showTDL:boolean;
  showQDtl:boolean;
  showQHL:boolean;
  cntntMnu:boolean ;
  maintenance:boolean;
  showQHdr:boolean;
  showTHdr:boolean;
  showTH:boolean;
  
  hdrName: string;
  ovrHdrFormat: string;
  showAsoRec:boolean;
  
get site() {return  this.cs.getSite()} ;
get cat() {return this.cs.getCat() };
get board() {return this.cs.getBoard()};
get course() { return this.cs.getCourse()};
get feature() { return this.cs.getFeature()};
get offering() { return this.cs.getOffering()};
get status() {return this.cs.getStatus() }
get subject() { return this.cs.getSubject()};
get onlySubject() {return this.cs.onlySubject(this.subject) }
get chapter(){return Number(this.cs.getChapter()?this.cs.getChapter():'')}
get justOffering() { return this.cs.getJustOffering() };
get groupId() { return this.cs.getGroupId() };


showQDL:boolean;
addVid:boolean;
grpAsoId:number;

  constructor(public cm :CntxtService, public cs:ClientInfoService,public qs:QstnListService, public be:BtnEnablerService) { }

  ngOnInit(): void {
    }

    getgrpAsoId(){
    console.log('passed hdr is',this.hdr); 
    if (this.hdr.headerId && this.hdr.headerId>0) {return this.hdr.headerId}
     return this.chapter;
    }


    getHdrName(){
  
    if(this.subject) {
      this.ovrHdrFormat= "";
      return 'chapter/Unit';
    } else{
      //
      //this.ovrHdrFormat= "subDesc";
      this.ovrHdrFormat= "chptrList"; //chapter-list
      return 'Subject';
    }
  }

   getQstnName(){
    switch (this.justOffering) {
      //build with click
      case 'vid':
        this.addVid= true;
        return 'Video';

      //digitize with upload 
      case 'doc':
        this.addVid= false;
        return 'Doc';

        default:
          this.addVid= false;
          return 'Question';
      
    }

    }

  
}

