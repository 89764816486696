
<ng-container *ngIf= 'dataList'>
<table datatable [dtOptions]="dtOptions" class="row-border hover">
    <thead>
    <tr>
      <th *ngFor="let fld of fldList,let i=index;">
         {{fld}} 
      </th>
      <th>
        Feature
      </th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let c of dataList;let i=index">
    <td *ngFor="let val of getValList(c,listType), let i=index;">
   {{val}}
  
   </td>

         
        <td>
        <button class= "mx-3 btn btn-outline-primary badge "  (click)= 'expand(c,i)'>
          Expand </button>   
       </td>
      

      </tr>
  </tbody>

  </table>
</ng-container>


  <div *ngIf= "expandI>=0" class= "my-3 mx-5">
    <app-my-json class="my-5" [table]= "'comment'" [q] = "c" > </app-my-json>
  </div>

