import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,Subject} from 'rxjs';
import { HttpHeaders,HttpResponse } from '@angular/common/http';
import { ClientInfoService } from '../shared/client-info.service';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    //'Authorization': 'my-auth-token',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'HEAD,GET,POST,PUT,PATCH,DELETE',
    responseType: 'text',
  })
  };


  @Injectable({
    providedIn: 'root'
  })

  export class DetailService {
//Subject which will be pushed from child to advise parent to update List.
public static updateList: Subject<boolean> = new Subject();

    getDetailsByTypeFtrHdrId(cmntType: string, table: string, parentHdrId: number) {
      //alert(this.cs.getSite());
     
      return this.http.get(`${this.baseUrl}/typeFtrHdrid/${this.cs.getSite()}/${cmntType}/${table}/${parentHdrId}`);
      //throw new Error("Method not implemented.");
    }

    getDetailsByTypeFtrDtlId(cmntType: string, table: string, parentHdrId: number,parentDtlId:number) {
     // alert ("dtl calling");
      return this.http.get(`${this.baseUrl}/typeFtrDtlid/${this.cs.getSite()}/${cmntType}/${table}/${parentHdrId}/${parentDtlId}`);
      //throw new Error("Method not implemented.");
    }



  private baseUrl ;
  //= 'http://192.168.1.2:8080/rs/cmnt';

  
  constructor(private http: HttpClient,private cs:ClientInfoService) {
    this.baseUrl= this.cs.getRoot()+ "/rs/td";
   }


  getDetail(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/detail/${id}`);
  }

  createDetail(detail: Object): Observable<Object> {
   // console.log(detail);
    return this.http.post(`${this.baseUrl}`, detail);
  }

  createDetailWithAutoSeq(detail: Object): Observable<Object> {
   // console.log(detail);
    return this.http.post(`${this.baseUrl}/withAutoSeq`, detail);
  }

  updateDetail(id: number, value: any): Observable<Object> {
    return this.http.put(`${this.baseUrl}/${id}`, value,httpOptions);
  }

  flipDetail(id: number, value?: any): Observable<Object> {
    return this.http.put(`${this.baseUrl}/flip/${id}`, value,httpOptions);
  }


  deleteDetail(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`,httpOptions );
  }

  getDetailsList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/prntdtlset/1`);
  }

  getDetailsByTagHdr(tagHdrId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/byTagHdr/${tagHdrId}`);
  }



  //legacy
  getDetailsByType(type: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/type/${this.cs.getSite()}/${type}`);
  }

}