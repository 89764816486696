<h2>Tag Details</h2> 

<hr/>
<div *ngIf="detail">
  <div>
    <label><b>#: </b></label> {{detail.id}}-{{id}}
  </div>
  <div>
    <label><b>sequence : </b></label> {{detail.sequence}}
  </div>
  <div>
    <label><b>subSeq </b></label> {{detail.subSeq}}
  </div>  
  <div>
    <label><b>Part</b></label> {{detail.part}}
  </div>  

  <div>
    <label><b>qstn# </b></label> {{detail.qstnDtlId}}
  </div>  


</div>

<br>
<br>
<button (click)="list()" class="btn btn-primary">Back to Tag List</button><br>
