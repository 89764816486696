<div *ngIf="qd" class="container px-1 text-center"> 

 <div class="pen20 h5 my-3 text-primary"> {{titleMap[key]}}


 </div>

<!--
<div class="container fullWidth px-0 mx-0">
  <div *ngIf="qd.question" >
    <div [innerHTML] = "qd.question|safe :'html'"> </div>
</div>
   
</div>
-->



<blockquote *ngIf= "qd.question" class="blockquote pb-1" [innerHTML] = "qd.question|safe :'html'"> 
</blockquote>

<div class= "text-small text-muted font-italic text-right" > {{qd.instruction}} </div>    

<div class="container fullWidth px-0 mx-0">
<ng-container *ngIf= "qd.questionImages">
<img  src = {{qd.questionImages}} alt= "{{qd.questionImages}}">
</ng-container>
</div>


<div *ngIf= "qd.vidLink" class="embed-responsive embed-responsive-16by9">
    <iframe class="embed-responsive-item" [src]="(utube + qd.vidLink)|safe:'resourceUrl'" allowfullscreen></iframe>
</div>


<nav *ngIf= 'cs.getFeature()=="story" && cs.enable("daily","pastVid")'>

  <button class="float-left btn-outline-primary mx-5" (click) = 'showPastVid=!showPastVid'> Past Videos  </button> 
  
  <button *ngIf= 'cs.enable("daily","vimeo")' class="float-right mx-5" (click) = 'showVimeo=!showVimeo'> Show MidNight Sun?  </button> 

  
  <div  id="motivator" *ngIf="showPastVid" class="py-4">
  <app-vidshower [headerId] = 20202  [jumboHdr]= false   (onload)="alertMsg('loaded below')"> </app-vidshower>
  </div>
 
  <div *ngIf= 'showVimeo' class="embed-responsive embed-responsive-16by9">
    <iframe class="embed-responsive-item" [src]="vimeoUrl|safe:'resourceUrl'" allowfullscreen></iframe>
  </div>

</nav>  


  
  <!--vimeo-->
  
 


<hr>  

</div>
