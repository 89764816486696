import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
  providers: [NgbCarouselConfig]
})
export class AboutusComponent implements OnInit {

showNavigationArrows = false;
  showNavigationIndicators = false;

  /*
  images = [1, 2, 3].map(() => `https://picsum.photos/1900/500?random&t=${Math.random()}`);
*/

images =['Teacher.png','2a.png','3.jpg','4.jpg','5.jpg','6.jpg','7a.png','8.jpg','9a.png','10.jpg'];
	
teacher= "Teacher.png"

constructor(config: NgbCarouselConfig, private _http: HttpClient) { 
config.showNavigationArrows = true;
      config.showNavigationIndicators = true;
  }

  ngOnInit() {
  }

}
