import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { ScriptLoaderService } from './script-loader.service';
import { ListerService } from '../../chat/lister.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges {
  @Input() site:string;
  @Input() menuType:string
  
  twitterA: string= 'edubestu';
  //showFixed:boolean= false;
  processedSite: any;
  urlDisableTerm: any;
  globalDisable: boolean;
  disableTracking: boolean = localStorage.getItem('disableTracking') == 'true';

  disableAdvertising: boolean = localStorage.getItem('disableAdvertising') == 'true';
  
  get groupId() { return this.cs.getGroupId()};
  get cat() { return this.cs.getCat()};
 // get site() { return this.cs.getSite()};
  get showContact(){return this.cs.enable('footer','contact-site')}
  get showLabel(){return this.cs.enable('footer','showLabel-site')}
  get showFixed(){return this.cs.enable('footer','showFixed-site')}

  constructor(public cs:ClientInfoService,private scriptLoaderService:ScriptLoaderService,public ls:ListerService) { 
    
    if(this.site && this.site.startsWith('es')){
      this.twitterA= 'ExamSteppers'
    }
    if(this.site && this.site.startsWith('eb')){
      this.twitterA= 'Edubestu'
    }
        
    //scriptLoaderService.load('https://rf.revolvermaps.com/0/0/3.js?i=0y1xx4kh4na;b=0;s=21;m=2;cl=ffffff;co=010020;cd=aa0000;v0=60;v1=60;r=1')
    //this.cs.log("Hello Prabhash- script loaded from constructor....");
  }

  ngOn1Init(){
   
    if (this.cs.getRoot() && (!(this.cs.getRoot().includes('edub1estu')||this.cs.getRoot().includes('local1host')))
    
    ){
      this.scriptLoaderService.load('//rf.revolvermaps.com/0/0/3.js?i=0y1xx4kh4na&b=0&s=21&m=2&cl=ffffff&co=010020&cd=aa0000&v0=60&v1=60&r=1');
    }

    //https://medium.com/ngconf/dynamically-execute-script-in-an-angular-component-e4dbcf9a34

    //https://www.htmlgoodies.com/javascript/loading-external-scripts-dynamically-in-angular/
    
       
   
   //this.scriptLoaderService.removeFooter();

    
    //this.cs.log("Hello Prabhash- script loaded from init....");
  }
    
  ngOnInit(){
  this.load();
  }

  load(){
    
    this.ls.getKeyFlds('footerTracking',"all").subscribe((data:any) => {
      this.cs.log(data);
      let flds  = data.result;
      let StrArray = flds.urlDisableTerm.split(",");
      this.urlDisableTerm = StrArray;
      //this.cs.log(this.primary);
      this.globalDisable= flds.globalDisable;
      this.cs.log('globalDisable', this.globalDisable.toString());
      this.cs.log('urlDisableTerm: ',this.urlDisableTerm.toString());
      //!this.globalDisable
      if (!this.disableTracking && !this.globalDisable &&  this.cs.getRoot() && (!(this.cs.getRoot().includes(this.urlDisableTerm[0])||this.cs.getRoot().includes(this.urlDisableTerm[1])))
      
      ){
        this.scriptLoaderService.load('//rf.revolvermaps.com/0/0/3.js?i=0y1xx4kh4na&b=0&s=21&m=2&cl=ffffff&co=010020&cd=aa0000&v0=60&v1=60&r=1');
      }

    });
    
    
  }
  ngOnChanges(){
  this.load();
  }

  ngOn1Changes(){
    
    if (this.cs.getRoot() && (!(this.cs.getRoot().includes('edu1bestu')||this.cs.getRoot().includes('local1host')))
    
    ){
      this.scriptLoaderService.load('//rf.revolvermaps.com/0/0/3.js?i=0y1xx4kh4na&b=0&s=21&m=2&cl=ffffff&co=010020&cd=aa0000&v0=60&v1=60&r=1');
    }
    this.processedSite = this.site;
    //import('../../../assets/js/revolver.js');
       //this.cs.log("Hello Prabhash- script loaded from afterviewInit....");
       
       //ScriptLoaderService.loadGoogleAnalytics('myGATrackingID');

    }

    ngAfterViewCheckedNOT() {
    if(this.processedSite != this.site){
      
      if (this.cs.getRoot() && (!(this.cs.getRoot().includes('edubestu')||this.cs.getRoot().includes('localhost')))
    
      ){
        this.scriptLoaderService.load('//rf.revolvermaps.com/0/0/3.js?i=0y1xx4kh4na&b=0&s=21&m=2&cl=ffffff&co=010020&cd=aa0000&v0=60&v1=60&r=1');
      }
     this.cs.log("Hello Revolver");
     this.processedSite != this.site;
    }
  }

    }

   

