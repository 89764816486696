
<!-- hdr based information only-->

{{c.featureType}}
--
<a href= "{{c.source}}" target="_blank"> {{c.title? c.title:'link'}} </a>


<!--don't use as below expect headerObj
<app-url-shower *ngIf="c.source" [extLink] = c.source [title] = 'uploaded-Cntnt' > </app-url-shower>
--
<app-url-shower *ngIf="c.extUrl" [extLink] = c.extUrl [title] = "c.title" > </app-url-shower>

-->

