

<ngb-carousel id="myCarousel">
    

<ng-template ngbSlide>

    <img [src]='q.contentImages' alt= '{{i}}' class="center-block img-fluid">

    <!--for text on image-->
    <div class="carousel-caption">
      <div class = "h4 esSlide1" >
      <span class= "" [innerHTML]="q.instruction|safe :'html'">  </span>  
      </div>    

      <span class = "" [innerHTML]="q.content|safe :'html'"> </span>    

    <div class="py-1 text-center">
    <a *ngIf='q.extLink'  class="btn btn-md btn-primary"    routerLink = {{cs.getRouteUrl(q.category,q.extLink)}} fragment=""
    [routerLinkActive]="['is-active']"
     ngbTooltip="" placement="bottom"> {{q.title}} </a>
    </div>

      <div class="text-right" > 
          <span [innerHTML] = "q.answer|safe :'html'"></span> 
      </div>

  </div>  
  <!--End of text on image-->

</ng-template>   
</ngb-carousel>

