
<blockquote class= "vis" >

    <h2 class ="text-center" style="color: Purple;background-color:lightblue;">Our Vision</h2>
   
    <div class= "text-center">
   
   Powered by AI & Digitization,Driven by Teacher, our vision is to bring best of education system for our students <br> by providing optimized personalized teaching experience ,assisted by technology for teacher in easiest manner.
   
   <div>

   <p class= "my-3 font-italic" style=""> Our mission is to enable student to bring best in competitive exam, assisted by digital steps at natural pace. </p>
  
  </div>

     
    </div>

  </blockquote>
