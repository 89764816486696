import { Component, Input, OnInit } from '@angular/core';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';

@Component({
  selector: 'app-ansr-view',
  templateUrl: './ansr-view.component.html',
  styleUrls: ['./ansr-view.component.scss']
})
export class AnsrViewComponent implements OnInit {
@Input() q:any;
@Input() i: number;
@Input() showA : boolean;



  constructor(public qs:QstnListService) { }

  ngOnInit(): void {
  }
  showAns() {
    this.showA= true;
    //this.qs.typeSet('ansDiv');
   }
 
   hideAns() {
     this.showA= false;
   }
 
      
displayAnsUI(q:any){
 return  (q.answer|| q.digAnsr) &&  ((!this.qs.enable('isAnsr')) || this.qs.enable('displayAllAnswer') || this.showA);
}

}
