import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { FldsService } from '../flds.service';
import { Format } from '../format';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() formatList: Format[];
  @Input() dfltCode: string;
  selectedFormat:Format;
  
  @Output('changedValue')
  changedFormat: EventEmitter<Format> = new EventEmitter<Format>();

  constructor(private fd:FixedDataService,private cs:ClientInfoService,private fldsrv:FldsService) { }

  ngOnInit(): void {
   this.selectedFormat = this.formatList.find(e => e.code === this.dfltCode);
  }
  
  changeFormat(format:Format): void {
    this.selectedFormat= format;
    this.changedFormat.emit(format);
    }
  


}
