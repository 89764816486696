export class Type {

  public name: string; //name
  public desc: string;
    constructor(
      name: string, //name
      desc: string
    ) 
    
    { 
      this.name= name;
      this.desc= desc;
     }
  
  }