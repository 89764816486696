
<app-parm-loader></app-parm-loader>
<app-menu></app-menu>
<app-product> </app-product>

<!--
<app-signuplogin [hideSigned]= true> </app-signuplogin>
-->

<nav
  class="footColor mb-0 py-0 float-center mt-auto navbar navbar-expand-sm  navbar-light sticky-bottom">
  
  <app-footer-link [menuType]="'floatingFooter'" [site]=site> </app-footer-link>
  <app-footer [site]=site></app-footer>

</nav>
