<!-- moved to dropdown component
<div *ngIf="formatList" class="btn-group" ngbDropdown role="group"
        aria-label="Button group with nested dropdown">
        <span class= 'mr-1'>format:</span> 
        <button type ="button"  class="btn btn-outline-info border-0" ngbDropdownToggle>
                {{selectedFormat?selectedFormat.name :"select"}}
        </button>
        <div class="dropdown-menu" ngbDropdownMenu>
                <button *ngFor='let c of formatList' ngbDropdownItem type= "button"
                        (click)='changeFormat(c)'> <span innerHTML='{{c.name}}'>
                        </span></button>

        </div>
</div>
--> 
<app-dropdown *ngIf= 'enable("selectFormat")' [formatList] = formatList [dfltCode]="selectedFormat?.code"  (changedValue)="fetchData($event)"
 > </app-dropdown>

<app-qd-flds [fldList]=primary [d]=detail [edi]=edi [parentFormGroup] = parentFormGroup> </app-qd-flds>

<div class="text-right">
        <button *ngIf="secondary && secondary.length>1" type="button"
                class="btn btn-secondary mx-2" (click)="expand=!expand">
                Secondary Fields</button>

        <button *ngIf="enable('additional') && additional" type="button"
                class="text-right btn btn-secondary mx-2"
                (click)="additionalFlag=!additionalFlag"> Additional
                Fields</button>

</div>
<app-qd-flds *ngIf="expand" [fldList]=secondary [d]=detail [parentFormGroup] = parentFormGroup> </app-qd-flds>
<app-qd-flds *ngIf="additionalFlag" [fldList]=additional [d]=detail [parentFormGroup] = parentFormGroup>
</app-qd-flds>