import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders,HttpResponse } from '@angular/common/http';

import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from './message.service';
import { Comment } from './comment';
import { ApiResponse } from './api-response';

import { Observable, of } from 'rxjs';
import { environment } from './../environments/environment';
import { ClientInfoService } from './shared/client-info.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token'
  })
  };
  
@Injectable({
  providedIn: 'root'
})


export class ExamDataService {
rootUrl:string;
    //this.rootUrl = environment.rootUrl;
      //readonly rootUrl = 'https://examsteppers.com';


  constructor(private http: HttpClient,private messageService: MessageService,private cs:ClientInfoService
) {
    this.rootUrl= this.cs.getRoot();

   }

  //my method
  firstClick() {

    return console.log('clicked ok');

  }

  isIdAvailable(domain:string ,userId:string ) {
  
   return this.http.get(`${this.rootUrl}/rs/uh-v2/isAvailable/${domain}/${userId}`,{responseType: 'text'});
  }

  validateSiteCode(domain:string ) {
  
   return this.http.get(`${this.rootUrl}/rs/uh-v2/validateDomain/${domain}`,{responseType: 'text'});
   }


getUsers() {
    //return this.http.get('https://reqres.in/api/users')
  }

getUsers2() {
    //return this.http.get('https://reqres.in/api/users?page=2')
  }

  search(site:string, board:string,course:string,offering:string,subject:string,key:string) {
    if(!subject){subject= "subject"} 
    if(!offering){offering= "type"} 
    return this.http.get(this.rootUrl + '/rs/search/' +site+ '/'+ board+'/'+ course+'/'+ subject + "/" +offering + "/"+key ).pipe(
      catchError(this.handleError('getSubjects', []))
    );

    }


getQuestions(x:number) {
    return this.http.get(this.rootUrl + '/rs/qstnppr/raw/'+ x)
  }

getQuestionsRaw(x:number) {
    return this.http.get(this.rootUrl + '/rs/qstnppr/'+ x)
  }

// for Percentage Booster subjects ONLY...
getSubjects(site:string, board:string,course:string,offering:string) {
//this.messageService.add('ExamDataService: fetching subjects');
    return this.http.get(this.rootUrl + '/rs/pbstr/'+site+ '/'+ board+'/'+ course+'/'+offering+'/subjects' ).pipe(
      catchError(this.handleError('getSubjects', []))
    );

  }

getPyqSubjects(board:string,course:string) {
    return this.http.get(this.rootUrl + '/rs/qstnppr/subjects/'+board+'/'+ course)

  }

  getSubSubjects(board:string) {
    return this.http.get(this.rootUrl + '/rs/bi/subjects/'+board)

  }

getHWSubjects(board:string,course:string) {
    return this.http.get(this.rootUrl + '/rs/hw/subjects/'+board+'/'+ course)

  }

  getThought() {
      return this.http.get(this.rootUrl + '/rs/thought')

    }


getSignedUserDlt() {
this.cs.getSignedUser()

//alert("inside signed User method");
//this.messageService.add('ExamDataService: fetching signed user');
/*   
return this.http.get(this.rootUrl + '/faces/rs/pbstr/signedUser',{responseType: 'json'}).pipe(
      catchError(this.handleError('getSignedUser', []))
    );
*/

  }


addComment(commentPayload) : Observable<ApiResponse> {
  //alert("inside add comments method");
    return this.http.post<any>(this.rootUrl + '/rs/pbstr',commentPayload).
       pipe(
      catchError(this.handleError('addComment', []))
    );

  }

addUser(userPayload) : Observable<ApiResponse> {
   // alert("inside add User method");
       return this.http.post<any>(this.rootUrl + '/rs/us/add',userPayload).
    pipe(
      catchError(this.handleError('addUser', []))
    );

  }

  
addSite(userPayload) : Observable<ApiResponse> {
  
      return this.http.post<any>(this.rootUrl + '/rs/us/addSite',userPayload).
   pipe(
     catchError(this.handleError('addUser', []))
   );

 }

 
 updateSite(id: number, value: any): Observable<Object> {
  return this.http.put(`${this.rootUrl}/rs/us/updateSite/${id}`, value,httpOptions).pipe(
    catchError(this.handleError('update', [])));
}




usrSetTrial(header: any): Observable<Object> {
  // console.log(header);
  return this.http.post(this.rootUrl + '/rs/us/usrSetTrial', header).pipe(
    catchError(this.handleError('usrSetTrial', []))
  );
}

usrSetCrtr(header: any): Observable<Object> {
  // console.log(header);
  return this.http.post(this.rootUrl + '/rs/us/usrSetCrtr', header).pipe(
    catchError(this.handleError('usrSetCrtr', []))
  );
}


//main method to login -- add site later??
//userpayload is userdto... 
//removing '/faces/rs

loginUser(userPayload) : Observable<ApiResponse> {
     // console.log("inside examservice-login User method");
     // let urlStr= this.rootUrl +  "/faces/rs/us/login";
      let urlStr= this.rootUrl +  "/rs/us/login";
     
      //console.log(urlStr);
return this.http.post<any>(urlStr,userPayload).
 pipe(
        catchError(this.handleError('loginUser', []))
      );
}

changeBstrSet(board:string, bstrSetId:string) {
    return this.http.get(this.rootUrl + '/rs/pbstr/chgbstrset/'+board +'/'+ bstrSetId)
}



startAutoRoller(site:string, board:string,course:string,clsSec:string,offering:string,subject:string) {
  return this.http.post(this.rootUrl + '/rs/pbstr/startAutoRoller/'+site+ '/'+ board+'/'+ course+'/'+clsSec + "/" + offering+'/subject',null ).pipe(
    catchError(this.handleError('autoRollAsgnmnt', []))
  );

}





autoRollAsgnmnt(site:string, board:string,course:string,clsSec:string,offering:string,subject:string) {
      return this.http.post(this.rootUrl + '/rs/pbstr/autoRoll/'+site+ '/'+ board+'/'+ course+'/'+clsSec + "/" + offering+'/subject',null ).pipe(
        catchError(this.handleError('autoRollAsgnmnt', []))
      );
  
    }

    


    
/*
changeBstrSetPut(board:string, bstrSetId:string) :Observable<HttpResponse<BstrSet>> {
    return this.http.put(this.rootUrl + '/rs/pbstr/chgbstrset/'+board +'/'+ bstrSetId,bstrSet,httpOptions).pipe(
      catchError(this.handleError('updateBstr', bstrSet))
    );

  }
*/





getTagQuestions(x:number) {
    return this.http.get(this.rootUrl + '/rs/pbstr/bytag/'+x)
  }


getPyqQuestions(x:number) {
    return this.http.get(this.rootUrl + '/rs/pbstr/bytag/'+x)
  }



/**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}


/** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`ExamDataService: ${message}`);
  }

}
