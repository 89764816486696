
<div class="mx-0 h5 text-center bg-info text-light my-3"> {{getBookName()}}
    <span class="text-muted small">
        <button class="badge float-right" (click)='navigateBook()'>open as page </button>


        <button class="badge float-right mx-3 " type="button" (click)='chs.shrink(h)'>
            {{chs.shrinkBook==h.book+h.branch?'+':'-'}} {{bookName}}</button>

    </span>
</div>