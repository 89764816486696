import { Component, Input, OnInit } from '@angular/core';
import { TestService } from '../test.service';

@Component({
  selector: 'app-mcq-ma',
  templateUrl: './mcq-ma.component.html',
  styleUrls: ['./mcq-ma.component.scss']
})
export class McqMaComponent implements OnInit {
  @Input()  q :any;
  @Input()  i :number;
  @Input() worksheet: boolean;
  
  

  constructor( public ts:TestService) { }

  ngOnInit(): void {

    // this.ts.digAnsrs = this.q.ansCmnts[0].comment;
   // this.ts.digAnsrs[3] = true;
  }

  checked(o:string,ind:string){
   let val=  this.ts.getDigOptn(o,ind);
   if(this.q.ansCmnts[0].digAnsr && this.q.ansCmnts[0].digAnsr.includes(val)){
   //console.log(ind + " is already selected"); 
    return true;
   } else{
     return false;
   }

  }


  onCheckboxChange(e:any,o:string,j:number) {
    if(e.target.checked){ 
    //console.log(e.target.value + "set to true");
     if(!this.q.ansCmnts[0].digAnsr) {this.q.ansCmnts[0].digAnsr =e.target.value}
     else{
      if(! this.q.ansCmnts[0].digAnsr.includes(e.target.checked)) {
      this.q.ansCmnts[0].digAnsr= this.q.ansCmnts[0].digAnsr + "," + e.target.value
      }}
    } 
    else{
   // console.log(e.target.value  + " set to false");
    this.q.ansCmnts[0].digAnsr = this.q.ansCmnts[0].digAnsr.replace("," + e.target.value,"").
    replace(e.target.value + ",","").  
    replace(e.target.value,"");
    }
    
    //console.log(this.q.ansCmnts[0].digAnsr) ;
    //console.log(o);
    // console.log(j);
     

    /*
    if (e.target.checked) {
      website.push(new FormControl(e.target.value));
    } else {
       const index = website.controls.findIndex(x => x.value === e.target.value);
       website.removeAt(index);
    }
    */

  }

    
}
