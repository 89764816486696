import { Component, Input, OnInit } from '@angular/core';
import { QstnListService } from '../qstn-list/qstn-list.service';

@Component({
  selector: 'app-bstr-view',
  templateUrl: './bstr-view.component.html',
  styleUrls: ['./bstr-view.component.scss']
})
export class BstrViewComponent implements OnInit {
@Input() q:any;
@Input() i:number;

  constructor(public qs:QstnListService) { }

  ngOnInit(): void {
   //expandedAnsrs decided whether default answer will apear or stay hidden.
    this.qs.expandedAnsrs= false;
  }

}
