import { Injectable } from '@angular/core';
import { BtnService } from '../gen/btn.service';
import { ClientInfoService } from './client-info.service';
import { FixedDataService } from './fixed-data.service';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class BtnEnablerService {
btnMap:any;
debug:boolean ;

constructor(public c: ClientInfoService,public b:BtnService) { }

log(msg:any){
  if(this.debug){
    console.log(msg)
  }
}

enable(component: string, btnId: string, hdr?:Object , debug?:boolean){
 this.debug = debug;

let authorized = false;


this.log( " cs.enable: " + this.c.enable(component,btnId,hdr));
this.log(btnId);
this.log(hdr);


if(hdr && this.b.grpAuthFast(btnId,hdr)){
  this.log('auth-fast enabled' + btnId );

  return true 
} else{
    //this.log(hdr);
    this.log('auth-fast did not enable' + btnId );
    let shrGrp= "no hdr";
    if(hdr){shrGrp = (hdr as any).shrGrp}
    this.log(" shrGrp: " + shrGrp)   
  }

if (!this.btnMap) {this.btnMap = this.c.fd.loadBtnAuthMap()}

if (!this.btnMap) { this.log("btnMap not loaded"); return false;}
this.log(this.btnMap);

//this.log(this.c.getGroupId());
let groupId= this.c.getGroupId()?this.c.getGroupId():"UNSIGNED";
//this.log(this.c.getGroupId() + " " + groupId);   
let key = component + "-" + btnId  + "-" + groupId;
//this.log(this.btnMap)}

if (this.btnMap.has(key)){authorized = true;} 
else{
this.log(key + " : usrGroup-btnDtl is absent")
key = component + "-" + btnId
}

if (this.btnMap.has(key)){
if(this.btnMap.get(key).includes(groupId)){   
this.log(key + " : is present in normal btnDtl -authorized group")
authorized = true;
} else{
this.log(key + " : is not present in normal btnDtl -authorized group")
 authorized = false;
}
}else{
this.log(key + "btnDtl not found"); //return true
}

groupId= this.c.getGroupId()?this.c.getGroupId():"UNSIGNED";
let bd = this.c.fd.getBtnDetail(component, btnId, groupId)
if(debug){
  if(bd){this.log(bd)}
    else{
      this.log("btnid Detail not found!, pls check setup");
    }
  };


if(!authorized && !this.c.authorized(component,btnId)) {
  this.log("authorization failed");
  return false
}

if(this.c.exclude(component,btnId,hdr)) { this.log("excluded for component"); return false}

return true;

} 

enableDtl(component: string, btnId: string,dtl:any,hdr?:any,debug?:boolean){
return this.c.enable(component,btnId,dtl,hdr)
 }

 
}