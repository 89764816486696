import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { PropFromUrlService } from '../../shared/prop-from-url.service';

@Component({
  selector: 'app-cubehor',
  templateUrl: './cubehor.component.html',
  styleUrls: ['./cubehor.component.scss']
})
export class CubehorComponent implements OnInit {
  data:any;
  list: any;
 
  get cat() { return this.cs.getCat()}

  constructor(private prop: PropFromUrlService, private cs:ClientInfoService) { }

  ngOnInit() {
    let propFile = 'cube.json';
    if(this.cs.enable("setup","cubehor-site")){
    this.prop.getPropList(propFile).subscribe(data => {
      //this.httpClient.get(url).subscribe(data =>{
      // console.log(data);
      let cat= "home";
      (this.data as any) = data;
     // console.log(data);
      this.loadList();
        }
    );
  }
}

  ngOnChanges(){
   
  }

loadList(){

  this.list =  this.data[this.cat];
  /*
  switch(this.cat){
    case 'home':
     this.list =  this.data.home;
     break;
    case 'prd-sch':
      this.list =  this.data.school;
      break;
    case 'prd-col':
        this.list =  this.data.college;
        break;
    case 'prd-coach':
      this.list =  this.data.coaching;
      break;
    
          
   } 
   */

}

}

