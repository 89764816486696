<div [hidden]="submitted" class="fullWidth  px-3 ">

  <form #ngform (ngSubmit)="onSubmit()">

    
    <ng-container [ngSwitch]="offering.trim()">
      
      <app-doc-mnt *ngSwitchCase='"vid"' [offering]= offering [detail]=detail> </app-doc-mnt>
     
      <app-doc-mnt *ngSwitchCase='"doc"' [offering]= offering [detail]=detail> </app-doc-mnt>
     
      <app-doc-mnt *ngSwitchDefault [offering]= offering [detail]=detail> </app-doc-mnt>
        <p *ngSwitchDefault> Please setup in detail </p>
     
    </ng-container>
  


    <button type="submit" class="btn btn-success">Submit</button>

  </form>

</div>


<!--
<div [hidden]="!submitted">

  <h4>You submitted successfully!</h4>

</div>

-->