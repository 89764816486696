import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ClientInfoService } from './client-info.service';

@Injectable({
  providedIn: 'root'
})
export class HttpHlprService {



public get ba()  {return this.cs.getRoot()+ "/rs"}
public get si(){return this.cs.getSite()}
public get justSite(){return this.cs.getJustSite()}
public get bo(){return this.cs.getBoard()}
public get co(){return this.cs.getCourse()}
public get ty(){return this.cs.getOffering()}
public get justType(){return this.cs.getJustOffering()}
public get su(){return this.cs.getSubject()}
public get cat(){return this.cs.getCat()}
public get st(){return this.cs.getStatus()}
public get clse(){return this.cs.getClsSec()}
public get ch(){return this.cs.getChapter()}

public get us(){return this.cs.getSignedUser()}

constructor(private cs:ClientInfoService,public c: HttpClient) { }




public handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {
    this.log(`${operation} failed: ${error.message}`);
    return of(result as T);
  };
}

private log(message: string) {
 this.cs.addMsg("Some errors occured,please try again later");
}


}
