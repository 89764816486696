
 
<div *ngIf= "hdr"  >
  
  <button *ngIf= "enable('toggleTitle')" type= "button" class='btn-outline-secondary border-0'  (click)= 'expand=!expand' > {{expand?'-':'+'}}</button>
  <div *ngIf= "expand" class="m-0 jumbotron text-center"> 

  <!--
  <app-day-counter *ngIf= "enable('dayCounter')" [h] = hdr> </app-day-counter>
-->

  <app-seo *ngIf="updateSeo"  [h] = hdr [q] = q> </app-seo> 

  <!--
                      {{hdng.title}}
                      {{hdng.summary}}
   <h5 *ngIf="cs.getChapter() && hdr.book" class="" [innerHTML]= 'hdng.getTopTitle(hdr)' > </h5>
cSrv.getTitle(hdr)
  -->

  <h5 *ngIf="enable('showBoardCourse')" class="" innerHTML= {{hdng.topTitle}} >  </h5>

  <h3 class="" innerHTML= {{hdng.mainTitle}} >  </h3>
     
   
      <div  *ngIf= "enable('showImage')  && hdr && hdr.extra && eh.titleImage(hdr.extra)">
    <img   width = "600px"  height= "155px" max-height= "175px"  src= '{{eh.titleImage(hdr.extra)}}' >
    <br>
    </div>

    
    <app-hdr-view-btns [hdr] = hdr> </app-hdr-view-btns>
    

  <i  innerHTML = {{hdng.summary}} ></i>
  <!--
  <br>
  <div *ngIf='digibest'  class= "rainbow-text badge  float-center">**welcome digibest star **</div> 
-->

  <button *ngIf= "ftrDescObj && ftrDescObj.instruction && !enable('description')"  class="btn btn-outline-info badge border-0  mb-2 mr-2 float-right" (click)="openScrollableContent(longContent)">?</button>
  <button *ngIf= "enable('description')"  class="btn btn-outline-info badge border-0  mb-2 mr-2 float-right"
   (click)= 'cs.navigate("prd-"+cs.getCat(),"")' >Description </button>
  <div>
    
    <button *ngIf= "!cs.getChapter() && enable('knowFtr')"  class="btn btn-outline-info badge border-0  mb-2 mr-2 float-right"
    (click)= 'cs.navigate("ftr/"+cs.getCat().replace("mkr-",""),"")' >about Feature</button>
   <div>
  
    <!--
    <button *ngIf= "enable('edit')"  class="btn btn-outline-info badge border-0  mb-2 mr-2 float-right"
    (click)= 'cs.navigate("crud",hdr.type + "/hdr/"+ hdr.id)' >h{{hdr.id}}</button>
   -->
  
    
    <button *ngIf= "q && cs.debug"  class="btn btn-outline-info badge border-0  mb-2 mr-2 float-right"
    (click)= 'cs.navigate("crud",hdr.type + "/dtl/"+ q.id)' >d{{q.id}}</button>
     
  <ng-template #longContent let-modal>
    <div class="modal-header">
      <h4 class="modal-title" innerHTML = {{ftrDescObj.instruction}}>Title</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    
    <div class="modal-body" innerHTML= {{ftrDescObj.content}}>
    </div>

    <div class="modal-body" innerHTML= {{ftrDescObj.answer}}>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
  </ng-template>

  
     
<!--
  <img *ngIf= "!cs.getChapter() && hdr && hdr.extUrl" class="img-fluid" width = "600px"  height= "200px"  src= 'etl/esadigi-best/content/system/stage/cbse-xii-cmn/rbs-math-rds-i/{{eh.image(hdr.extra)}}' >
-->

 

  <!--
  <img style="height:725px;max-width:500px;width: expression(this.width > 500 ? 500: true);" id="img_DocPreview" src="Images/empty.jpg" />
-->

</div>

  <!--  width = "600px"  
  <img width = "600px"  height= "400px"  src= '/etl/image/booster.jpg' alt="img">
  -->
</div>

</div>
</div>

  