import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { UsrhdrService } from '../../user/usrhdr.service';

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss']
})
export class SiteListComponent implements OnInit {
  siteList: any;
  showAdd:boolean;
  title = 'Site List';
 
    dtOptions: DataTables.Settings = 
   {
    pagingType: 'full_numbers',
    pageLength: 5,
    processing: true
  };
  expandI: any;
  c: any;

  fldList = [ 'domain','name','owner','useEBstore','enabled' ];
  dataList: any;
  domain: string;

  constructor(private usrhdrService: UsrhdrService,private router: Router,private cs:ClientInfoService
,private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    
this.route.params.subscribe( params => {
   this.domain= params['domain'];
   //alert(this.domain +  'hey');
   if(!this.domain) {
    this.domain= 'all';
  }
  this.usrhdrService.findAll(this.domain).subscribe(data => {
    this.dataList  = data;
}) ;
  
    
});
  }

  

getValList(c:any, listType?:string ){
    
 return [
        c.domain
        ,c.name 
        , c.owner
        , c.useEBstore
        , c.enabled
    ];

}

   
expand(c:Comment,i){
  this.c= c;
  this.expandI==i ? this.expandI =-1: this.expandI=i;
  }


  
  navigate(cat: string, link?: string) {
    this.router.navigateByUrl(this.cs.getRouteUrl(cat, link,"siteWithNoSignOut"));
  }

  
}



