

<div class= "fullWidth mr-2" [hidden]="submitted" >

  <form  (ngSubmit)="onSubmit()">


  <!--#ngForm = 'addForm' [parentFormGroup] = addForm add in future--> 
    
<app-cmnt-mnt  [c] =comment  [i]= [i]  [offering] = offering > </app-cmnt-mnt>
   
    
    <br> 
    
    <button type="submit" class="btn btn-secondary mx-5">Submit</button>
  </form>
</div>
<div [hidden]="!submitted">
  <!--
  <h4>You submitted successfully!</h4>
   <button class="btn btn-success" (click)="newComment()">Add</button> -->
</div>
