import { Component,Input, OnInit } from '@angular/core';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';


@Component({
  selector: 'app-set-bldr',
  templateUrl: './set-bldr.component.html',
  styleUrls: ['./set-bldr.component.scss']
})
export class SetBldrComponent implements OnInit {
  @Input() q:any;
  @Input() i:number;
  filterQstn: boolean;
  
  get status() { return this.qs.cs.getStatus()}

  constructor(public qs:QstnListService) { }

  ngOnInit(): void {
    
    let fqs = localStorage.getItem('filterQstns');
    if(fqs && fqs.includes(this.q.id)){
      this.filterQstn = true;
    }
      
  }

}
