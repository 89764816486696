import { Injectable } from '@angular/core';
import { CanActivate,ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { ClientInfoService } from '../shared/client-info.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  readonly premiumSignNeeded = environment.premiumSignNeeded;
  signedUser:string;

  constructor(private router: Router,private cs:ClientInfoService) { }
      canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
          debugger
        if (localStorage.getItem('participant') != null){
          return true;
        }  else if (localStorage.getItem('signedUser') != null){
          this.signedUser = localStorage.getItem('signedUser');
         // console.log('signedUser',this.signedUser);
            return true;
          }


            if(!this.premiumSignNeeded || (this.signedUser && this.signedUser!= 'Guest'))  {
                  return true;
                }
                else{
/*
                else{
                  return false;

                }
*/
        this.router.navigate(['eba/mng/features/signup']);        
       // this.router.navigate(['/register']);
                      
        // this.router.navigate(['esa/et/features/login']);
        return false;
      }
    }
}
