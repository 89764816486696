
import { Observable, Subject } from "rxjs";
import { DetailService } from "../detail.service";
import { Detail } from "../detail";
import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core";
import { Router, RouterEvent, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';

import { filter } from 'rxjs/operators';
import { get } from 'http';

@Component({
  selector: 'app-list-tagdtl',
  templateUrl: './list-tagdtl.component.html',
  styleUrls: ['./list-tagdtl.component.scss']
})
export class ListTagDtlComponent implements OnInit {

@Input() tagHdrId: number;
@Input() tagDtlId: number;

 @Input() cmntType:string;

  @Input() featureType: string;
  @Input() parentHdrId: number;
  @Input() parentDtlId: number;
  heading : string  ;

  public detail:Detail = null;
  cmntType1:string;
  // details: Observable<Detail[]>; for async..
  details: Detail[];
  
  showAdd:boolean;
  showUpdate:boolean;
  setUpdate:number;
  showDetail:boolean;
  setDetail:number;
  provideResponse:boolean;
  id:number;
  get signedUser(){ return  this.cs.getSignedUser()};
  get chapter(){ return this.cs.getChapter()};

  constructor(private route: ActivatedRoute,
    private router: Router, private detailService: DetailService,public cs: ClientInfoService,private fd: FixedDataService
    ) {
      DetailService.updateList.subscribe(res=>{
        //alert("inside comnt srvce-updte lst ");
        this.fetchData();})
   
    }

  ngOnInit() {
    //alert("inside comnt srvce-ngOnInit ");
    
    this.fetchData();

    
    /* this may not be needed as @Input was not working due to absense of "' cons'" input parm 
     
    this.router.events.pipe(
    filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event) => {

      this.fetchData();

    });
   */

  }

  
  navigateQstn(qstnId) {
    let url = this.router.url+ "/view-qstn/" + qstnId;
    this.router.navigate([url]);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.cmntType) {

     this.fetchData();
    }    
  }
  
   resetVar(){
    this.showAdd= false;
    this.showUpdate=false;
    this.showDetail= false;
    this.provideResponse=false;
    this.details= null;
    //this.heading= this.fd.getCmntHeading(this.cmntType);

      } 

  fetchData() {
   // alert("inside comnt srvce-fetchdata " +  this.tagHdrId);
    this.resetVar();
 if(!this.tagHdrId && this.chapter){
        this.tagHdrId =+this.chapter;
     } else{
// console.log("both taghdr and chapter is not number??"+ this.chapter +" // " + this.tagHdrId)
     }
     

if(!this.tagDtlId && this.tagHdrId > 0 ){
 (this.detailService.getDetailsByTagHdr(this.tagHdrId)).subscribe(data => {
      this.details  = data;
});
} else{     
  if(this.tagDtlId>0){
this.detailService.getDetail(this.tagDtlId as any)
      .subscribe(data => {
        //console.log(data)
        this.detail = data.result;
        this.details= new Array(1);
        this.details[0] = this.detail;
      }, error => console.log(error));
    }
  else{
  console.log("Both taghdr and tagdtl NaN, hence not calling") 
}
}

      } 




/* async pipe in html not working
let d  = this.detailService.getDetailsList();
this.details = (d as any) .dataList;
*/
      

  deleteDetail(id: number,i:number) {
    this.id = id;
    this.detailService.deleteDetail(id)
      .subscribe(
        data => {
          console.log(data);
          this.fetchData();
        },
        error => console.log(error));
  }


  flipDetail(id: number,i:number,detail:any) {
    this.id = id;
    this.detailService.flipDetail(id,detail)
      .subscribe(
        data => {
          console.log(data);
          this.fetchData();
        },
        error => console.log(error));
  }

  updateDetail(id: number,i:number){
    this.id = id;
    this.showUpdate = true;
    this.setUpdate=i;
    this.setDetail=-1;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  respond(id: number){
    this.id = id;
    this.provideResponse = true;

    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  provideResponseDetail(id: number){
    this.provideResponse = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  addDetail(){
    this.detail = new Detail();
    this.detail.tagHdrId= this.chapter as unknown as number;
    /*
    this.detail.cmntType= this.cmntType;
    this.detail.featureType= this.featureType;
    this.detail.parentHdrId= this.parentHdrId;
    this.detail.parentDtlId= this.parentDtlId;
    this.detail.domain= this.cs.getSite();
    */
   
     this.showAdd = true;
    //this.router.navigate(['eba','digibest','features','cmnt-add']);
  }


  detailDetail(id: number,i:number){
    this.id = id;
    this.showDetail = true;
    this.setDetail=i;
    this.setUpdate= -1;
    //this.router.navigate(['eba','digibest','features','cmnt-dtl','byId', id]);
  }

}

