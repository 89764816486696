import { Component, OnInit } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { HttpHlprService } from '../../shared/http-hlpr.service';

@Component({
  selector: 'app-story-of-the-day',
  templateUrl: './story-of-the-day.component.html',
  styleUrls: ['./story-of-the-day.component.scss']
})
export class StoryOfTheDayComponent implements OnInit {
  utubeId: any;
  utube= "https://www.youtube.com/embed/";
  constructor(private dataService: BoardDataService,private h:HttpHlprService) { }

  ngOnInit(): void {
     //video
     this.dataService.getDailyDetail(this.h,'fresh','story').subscribe(response=> {
      if(response) {
      let qd = response as any;
      this.utubeId = qd.vidLink;
    localStorage.setItem('utubeId', this.utubeId);
    } });  
  }

}
