import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { BoardDataService } from '../../board-data.service';
import { Board } from '../../board';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';



@Component({
  selector: 'app-special',
  templateUrl: './special.component.html',
  styleUrls: ['./special.component.scss']
})
export class SpecialComponent implements OnInit {
showP: boolean = true;
showE: boolean = true;
showD: boolean = false;

site:string;
cat:string;
board:string;
course:string;
offering:string;
subject:string;
chapter:string;
content:string;

headerObj:any;
pastHeaderObj:any;
detail:any;
headers:any;

private output: { [name: string]: any };

constructor(private titleService: Title,private dataService: BoardDataService
  ,private cs:ClientInfoService,private fd:FixedDataService,
    private metaTagService: Meta) { }

  ngOnInit() {
  this.titleService.setTitle('ES: ICSE 2020 Exam Special');
    this.metaTagService.updateTag({name: 'description', content: ' Notes, Tips, Tricks and Exam Schedules for ICSE 2020 Exam!'});
    this.metaTagService.updateTag({name: 'keywords', content: ' Notes, Tips, Tricks, ICSE 2020 Exam, ICSE 2019 Schedule'});

this.board= this.cs.getBoard();
this.course= this.cs.getCourse();
this.fetchData(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),"type","2021Exam");
  }


fetchData(site,board,course,type,subject){

  this.dataService.getSiteHeaders(site,board,course,type,subject).subscribe(data =>
  {
  this.headers= data;
   this.headerObj= (data as any).dataList[0];
   this.pastHeaderObj= (data as any).dataList[1];
   if(this.headerObj){
  var headerId= this.headerObj.id;
  //this.offering not used as booster going to tag file for submenu details
  this.dataService.getSiteQuestions(site,board,course,type,subject,this.headerObj.id).subscribe(data => {
   this.detail=data;
          //console.log('yyy');
          //console.log(dataList);
  });

}
  });
}

gotoLink() : void {
    (window as any).open('www.cisce.org/pdf/ICSE-Class-X-Specimen-Question-Papers-2019/English%20Language%20(English%20Paper%20-%201).pdf', "_blank");
  };

showPapers(){
	this.showP= true;
}

hidePapers(){
	this.showP= false;
}

showEngSpecs(){
	this.showE= true;
}

hideEngSpecs(){
	this.showE= false;
}

showDate(){
	this.showD= true;
}

hideDate(){
	this.showD= false;
}

}
