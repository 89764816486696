<div *ngIf="calling; else noCall">

  <div *ngIf="(vids$ | async) as list; else loading">
    <ng-container *ngIf="list.dataList.length; else noItems">
   

      <div class="container">

        <div *ngFor="let a of list.dataList" [ngClass] = '(a.status && a.status === "hide") ?"d-none": "col-12 col-md-6 col-lg-6"' >
         <!-- fix video hide -1 line gap issue later-->
          <app-vid-dtl *ngIf= '!a.status || a.status !== "hide"' [q] = a> </app-vid-dtl>

          <!-- moved to vid-dtl
          <div *ngIf="a.vidLink && a.status != 'hide' && !a.inactive"
            class="embed-responsive embed-responsive-16by9">
            <iframe [src]="(utube + a.vidLink)|safe :'resourceUrl' "
              allowfullscreen>
            </iframe>
          </div>


          <div *ngIf="a.extLink && a.format== 'intVid' && a.status != 'hide' && !a.inactive"
          class="embed-responsive embed-responsive-16by9">
          <video controls muted [src]="(a.extLink)|safe :'resourceUrl' "
            allowfullscreen>
          </video>
          </div>
          
          
          <p *ngIf="a.wireFrame " class="" [innerHTML] = "a.wireFrame|safe :'html'"></p>

           <div>
           
           
            {{a.title}}

            <div class= "float-right"> 
            <app-rating> </app-rating> </div>
          </div>

          <p class="text-info">{{a.instruction}} </p>
        --> 

        </div>

        </div>
        
      
    </ng-container>
    
    <button *ngIf="list.hdr && unitId && enable('addVid')" (click)="showAdd= !showAdd"
    class="btn btn-outline-primary" style="margin-left: 10px"> 

    {{showAdd?'Hide Add':'Add vid-Dtl'}}
    
    <i class="fa fa-book" aria-hidden="true"></i> </button>
    
    
    <app-add-vid [hdr] = list.hdr *ngIf="showAdd"></app-add-vid>
     
    <!--
    <app-share class = "mx-2" *ngIf= 'list.hdr' [hdr] = 'list.hdr' > </app-share>
  -->
  
 <ng-template #noItems>
  
  <!--
  <button *ngIf="!list.hdr && unitId && enable('addVid')" (click)="showAddHdr =!showAddHdr"
  class="btn btn-outline-primary" style="margin-left: 10px"> 
  {{showAddHdr?'Hide':'Add Vid Hdr'}} <i class="fa fa-book" aria-hidden="true"></i> </button>

<app-qstnhdr-add [iHeader] = prepAddHdr(hdr) *ngIf="showAddHdr"></app-qstnhdr-add>
 -->

</ng-template>
  </div>
  <ng-template #loading>
    loading......</ng-template>
</div>

<ng-template #noCall>!</ng-template>

<!--
<app-qstnhdr-add [iHeader] = prepAddHdr(hdr) *ngIf="showAddHdr"></app-qstnhdr-add>
-->