
<!--
<button class= "badge mx-1 "  (click) = 'expand(i)'> {{ expandI === i ? ' - ':' + '}} </button> 

*ngIf= "expandI== i"
-->


<ng-container > 

   <button  class="btn btn-outline-primary border-0 badge"   *ngIf="enable('updateHeader',i,header)" (click)="processUpdate(header,i)"
   ><span> {{(showUpdateNum == i && showUpdate)?'Hide ':'Edit'}}</span> 
   </button>
  
   <button *ngIf= "enable('sdelete',i,header)" class="btn mx-3 btn-outline-danger border-0 badge" (click)="changeInactive(header.id,header.type,true,i); showAddI= i;"> <i class="fa fa-trash" aria-hidden="true"></i> </button>
   
   <!--
   <app-status-changer *ngIf= "enable('crudBtns',i,header)" [hdr] = header [i] = i> </app-status-changer>
   -->


<div *ngIf = "success &&(showAddI== i)"> changed: {{success}} </div>

<button *ngIf= "enable('asoGrp',i,header)" (click) = 'showHdrGrp=!showHdrGrp' 
class="btn btn-outline-primary border-0" 
>HdrGrp</button>

</ng-container>

<app-update-header class="row col-12" *ngIf="showUpdate && (showUpdateNum == i) && !tagBased(header.type)" [id]= 'header.id' ></app-update-header>

<app-update-header class="row col-12" *ngIf="showUpdate && (showUpdateNum == i) && tagBased(header.type)" [id]= 'header.id' ></app-update-header>


<app-show-hdrgrp *ngIf= 'showHdrGrp' [hdr] = header> </app-show-hdrgrp> 
