import { Component, HostListener, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-cube3d',
  templateUrl: './cube3d.component.html',
  styleUrls: ['./cube3d.component.scss']
})
export class Cube3dComponent implements OnInit {
  scrolled: boolean;

  constructor(public cs:ClientInfoService) { }

  ngOnInit() {
    if(this.cs.bw.hasWindow()) {
    this.scrolled = window.pageYOffset > 36;
    }
  }

   
@HostListener('window:scroll', [])
onWindowScroll() {
  if(this.cs.bw.hasWindow()) {
  this.scrolled = window.pageYOffset > 36;
}
}
  
}
