
                    <!-- Knowlege manager's menu-->



   

   <!--                                
<button  *ngIf= 'cat=="mkr"'  type= "button" class="badge nav-link btn-primary button px-1 float-right ml-3" (click) = 'showStore("mymkr")'  > Content Maker</button>
-->

                                   
<button  *ngIf= 'cat=="fresh"'  type= "button" class="badge nav-link btn-primary button px-1 float-right ml-3" (click) = 'showStore("mymkr-fresh")'  > News Maker</button>


<button  *ngIf= 'cat == "mkr-dhw"'  type= "button" class="badge nav-link btn-primary button px-1 float-right ml-3" (click) = 'showStore("mymkr-dhw")'  > Homework Maker</button>



<button  *ngIf= 'cat == "mkr-pbstr"'  type= "button" class="badge nav-link btn-primary button px-1 float-right ml-3" (click) = 'showStore("mymkr-pbstr")'  > Booster Maker</button>


<button  *ngIf= 'cat == "mkr-dps"'  type= "button" class="badge nav-link btn-primary button px-1 float-right ml-3" (click) = 'showStore("mymkr-dps")'  > Question Set Maker</button>


<!--
<button  *ngIf= 'cat!="istore"'  type= "button" class="badge nav-link btn-primary button px-1 float-right ml-3" (click) = 'showStore("istore")'  > My Org's Content </button>

<button  *ngIf= 'cat!="ebstore"' type= "button" class="badge nav-link btn-primary button px-1 float-right ml-3" (click) = 'showStore("ebstore")'  > EB-COE's Content </button>

<button  *ngIf= 'cat!="cstore"'  type= "button" class="badge nav-link btn-primary button px-1 float-right ml-3" (click) = 'showStore("cstore")'  > Combined Content </button>
-->

