


<!-- moving to doubt-duster for homogenity
<button class= "btn-md btn-warning badge mr-1" *ngIf = 'qs.enable("rfc")' (click) = "processRfc(q,i)" >  Request For Change </button>


<button class= "badge btn-outline-primary border-0   mx-5"  (click) = 'expand(i)'> {{ expandI === i ? ' - ':' +'}} </button> 
-->

<ng-container *ngIf='true || expandI == i'> 
 
  <a *ngIf="false && qs.enable('updateQstnJSF')"  href = '{{rootUrl}}/faces/content-dtl-update.xhtml?qstnId={{q.id}}'
  class= "mx-2 badge btn-primary" > <span class= "">JSF update</span>
  </a>

  
  <app-digitize [q] = q  *ngIf = 'qs.enable("digitizerComp",q,h)' > </app-digitize>
  
  <!--

    {{q|json}}
    {{q.headerId != chapter}}-{{q.headerId}} !=  {{chapter}}
    {{(q && q.headerId != +chapter) && qs.enable('updateQstnGlobal',i,q)}}


    
<div *ngIf= "q.headerId == +chapter" >
  main set
</div>
<div *ngIf= "h.addUser ==cs.getSignedUser()" >
  owned by user {{cs.ss.getSignedUser()}}
</div>
{{ownUpdate}}


  -->   
    
<!--don't pass q in enable as for cfg "UNSIGNED recs get problem"-->

<ng-container *ngIf = 'qs.enable("status")' class= "mx-1"> {{q.status}} </ng-container>

<ng-container *ngIf = 'qs.enable("hideBtn")'> 
<button class= "btn-md btn-warning badge mr-1" *ngIf= '(!q.status || q.status != "hide")'  (click) = "clickToHide(q,'hide',i)" > Disable/Hide in this set</button>
<button class= "btn-md btn-warning badge mr-1" *ngIf = 'q.status && q.status == "hide"' (click) = "clickToHide(q,'show',i)" > show/unhide in this set</button>
</ng-container>


<!--
<span *ngIf= 'show("updateDtl")'>GS</span>

<span *ngIf= 'show("updateSS")'>SS</span>

{{ownUpdate}} -{{show("updateDtl")}}- {{qs.enable("updateQstnGlobal",q,h)}}- 

{{cs.enable('qstnDtl','updateLocal')}}

-->

<ng-container *ngIf= 'ownUpdate  || ( show("updateDtl") && qs.enable("updateQstnGlobal",q,h) )'> 

  <button class= "mx-2 badge btn-warning"   (click)="processUpdate(q,i,'global')"> update {{q.prntDtlId && q.prntDtlId>0? 'customized': ""}} <i class="fa fa-edit" aria-hidden="true"></i> </button>  

  <button class= "mx-2 badge btn-warning"   (click)="clickMethod(q,true,i); changeAddI= i;">{{q.prntDtlId?'restore Orgnl': "Delete"}} </button>

<ng-container *ngIf= '(q.prntDtlId && q.prntDtlId>0)'>
  <button class= "mx-2 badge btn-warning"  (click)="processUpdate(q,i,'orgnl')">Orgnl/COE view</button>
</ng-container>

</ng-container>


<ng-container *ngIf= "q.headerId != +chapter">
  <button class= "mx-2 badge btn-warning"  *ngIf= 'show("updateDtl") && qs.enable("updateQstnLocal")'  (click)="processUpdate(q,i,'local')"> {{q.prntDtlId && q.prntDtlId>0? 'update customized': "customize"}} <i class="fa fa-edit" aria-hidden="true"></i> </button>  
 </ng-container>





   
   <button class= "btn-primary badge mr-1" *ngIf="qs.enable('tagDtl',i,q) && tagBasedForCreate()"    (click)="flipTagDtl(i)"  > <span >{{(showUpdateTNum == i)?'Hide tag Dtl':'maintain Tag Dtl'}}<i class="fa fa-edit" aria-hidden="true"></i> </span>
   </button>

  
<div *ngIf = "success &&(showAddI== i)"> changed: {{success}} </div>


<button *ngIf="qs.enable('addPBstr') && !(q.status && q.status.includes(outSlbs))"  class= "btn-md btn-info badge mr-1" (click)="postBstrQstn('pbstr',q.source,q.id,i); showAddI= i;">Add to Booster  </button>


<button *ngIf="false && qs.enable('addImpQstn') && !(q.status && q.status.includes(outSlbs))"  class= "btn-md btn-info badge mr-1" (click)="postBstrQstn('tsrvsn',q.source,q.id,i); showAddI= i;">Add to Important Set</button>

<br>

<div class= "fullWidth mx-3" > 

  <app-upd-qstn-dtl *ngIf="showUpdate" [id] = 'updateId' [h] = h [changeSpan] = changeSpan class="px-3"></app-upd-qstn-dtl>
 
<app-list-tagdtl *ngIf= "showUpdateTNum == i && headerId"  [tagHdrId]= headerId [tagDtlId] = 'q.srcDtlId'> </app-list-tagdtl>




</div>

</ng-container>

