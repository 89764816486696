import { Component, OnInit,Input } from '@angular/core';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';

@Component({
  selector: 'app-crctn',
  templateUrl: './crctn.component.html',
  styleUrls: ['./crctn.component.scss']
})
export class CrctnComponent implements OnInit {
  @Input()  q :any;
  @Input()  i :number;
  constructor(public qs:QstnListService) { }

  ngOnInit(): void {
  }

}
