
<!--
<div class="text-center"  *ngIf="(ftrDtls$ | async).result as ftrDtl; else loading"> 

  <div class= "lead" *ngIf="ftrDtl.hdr && ftrDtl.hdr.title">{{ftrDtl.hdr.title}}</div>
  <div class= "h5" *ngIf="ftrDtl.dtls && ftrDtl.dtls[0]">{{ftrDtl.dtls[0].title}}</div>

</div>

<hr>
-->


<!--  
1
  <div class= "lead" *ngIf="h && h.title">{{h.title}}</div>
  <div class= "h5" *ngIf="d">{{d.title}}</div>
2
<app-seo *ngIf="updateSeo"  [h] = h [q] = d> </app-seo> 
-->


<div class="jumbotron text-center" >
<h5 class="small" innerHTML= {{hdng.topTitle}} >  </h5>
<div class="font-weight-bold lead" innerHTML= {{hdng.mainTitle}} >  </div>

 
<!--cs.enable('showImage')  &&-->
<div  *ngIf= " d && d.extra && ed.image(d.extra)">
  <img     width = "450px"  height= "198px" max-height= "175px"  src= '{{ed.image(d.extra)}}' >
  <br>
  </div>
  <i class="font-italic small text-muted" innerHTML= {{d?.instruction}} >  </i>
  <i *ngIf ="!d ||!d.instruction" class="font-italic small text-muted" innerHTML= {{hdng.summary}} >  </i>

<!-- subDesc needs to be thought through properly
  hdng.summary
  <i class="font-weight-bold font-italic small text-muted" innerHTML= {{subDesc?subDesc.summary:hdng.summary}} >  </i>
-->

<br>

<app-ofr-btn [h]=h [d]=d [subDesc] = subDesc> </app-ofr-btn>
<!--subDesc is added above pls check later-->


<div *ngIf= "d && d.topic" [hidden] = 'true' class= "small text-center" >
  <!--you may also find : -->
  <span class= "text-info small  font-italic " innerHTML= {{d.topic}}></span>
   <!-- materials on our site  -->
 </div>

</div>



<ng-template #loading> ...</ng-template>