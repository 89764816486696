
  import { Component, Input, OnInit } from '@angular/core';
  import { Router } from '@angular/router';
  import { ClientInfoService } from '../../shared/client-info.service';
  import { UsrroleService } from '../usrrole.service';
  @Component({
    selector: 'app-usr-role',
    templateUrl: './usr-role.component.html',
    styleUrls: ['./usr-role.component.scss']
  })

  export class UsrRoleComponent implements OnInit {
  @Input() headerId: number;

    siteList: any;
    showAdd:boolean;
    title = 'Role List';
    //showRole:boolean;
    
  dtOptions: DataTables.Settings = 
     {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
  };
    expandI: any;
    c: any;
  
    fldList = [ 'id','userId','headerId','role'];
    dataList: any;
  
    constructor(private usrroleService: UsrroleService,private router: Router,private cs:ClientInfoService) { }
  
    ngOnInit(): void {
     if(this.headerId){ 
      this.usrroleService.getUserRoleList(this.headerId).subscribe(data => {
        this.dataList  = data;
  });}
  else{
  this.usrroleService.getAllUserRoleList(this.headerId).subscribe(data => {
    this.dataList  = data;
  });
  }
    }
  
    
  
  getValList(c:any, listType?:string ){
      
   return [
          c.id
          ,c.userId 
          , c.headerId
          , c.role
      ];
  
  }
  
     
  expand(c:Comment,i){
    this.c= c;
    this.expandI==i ? this.expandI =-1: this.expandI=i;
    }
  
  
    
    navigate(cat: string, link?: string) {
      this.router.navigateByUrl(this.cs.getRouteUrl(cat, link,"siteWithNoSignOut"));
    }
  
    
  }
  
  
  
  