
<!--
class="jumbotron mb-0 text-center"
-->
<!--
<app-sliders *ngIf= 'feature =="institute"' > </app-sliders>
<app-sliders *ngIf= 'feature =="coaching"' > </app-sliders>
<app-sliders *ngIf= 'feature =="tuition"' > </app-sliders>
{{headerObj|json}}
-->


<app-hdr-view-slctr *ngIf='headerObj'  [hdr] = headerObj > </app-hdr-view-slctr>
     
<!--[ftrDescObj] = ftrDescObj-->
<!--
<app-headings-plain *ngIf='headerObj'  [hdr] = headerObj ></app-headings-plain>
-->

<!--
<app-experiment *ngIf= 'feature==="pbstr"'> </app-experiment>
<app-cubehor>  </app-cubehor>
-->


<div id= "boards" *ngIf= 'boardNeeded'>
<app-exam-list> </app-exam-list>
<br>
</div>


<div *ngIf="ftrDescObjDummy" class=" text-indigo font-weight-bold bg-warning  font-italic my-2" [innerHTML]= ftrDescObjDummy.instruction> </div>

<div class="text-indigo mt-1"  *ngIf="ftrDescObjDummy" [innerHTML]= ftrDescObjDummy.content>
</div>


<app-offeringlist  [showList] =questions   [expanded] = true  [hideTitle] = true>
</app-offeringlist >




<div *ngIf="ftrDescObjDummy" [innerHTML]= ftrDescObjDummy.answer >
</div>

<!--
<ng-container *ngIf= "enable('products')">
  <app-featurete>  </app-featurete>
  <app-sliders *ngIf= "enable('slides')">  </app-sliders>
   
    <app-demo *ngIf="enable('demo')" > </app-demo>
</ng-container>
-->


<!--
<app-offeringlist [expanded] = true [category] = "'ebhome'" [noHeader] = true>
</app-offeringlist >
-->

<!--
<div [ngSwitch]="feature">
<app-offeringlist [expanded] = true [category] = [feature] [noHeader] = true *ngSwitchCase= "'pyq'">
</app-offeringlist >
<app-offeringlist [expanded] = true [category] = [feature] [noHeader] = true *ngSwitchCase= "'hwh'">
</app-offeringlist >

pyq or hwh works due to qstn related,pbstr caused tag confusion hence pbstr needed.

<app-offeringlist [expanded] = false [category] = "'pbstr'" [noHeader] = true *ngSwitchCase= "'pbstr'" >
</app-offeringlist >


<app-offeringlist [expanded] = true [category] = [feature] [noHeader] = true *ngSwitchCase= "'car'">
</app-offeringlist >

<app-offeringlist [expanded] = true [category] = [feature] [noHeader] = true *ngSwitchCase= "'newsinfo'">
</app-offeringlist >

<app-offeringlist [expanded] = true [category] = [feature] [noHeader] = true *ngSwitchCase= "'ti'">
</app-offeringlist >

mention value for standalone single page else default to feature.


<app-offeringlist [expanded] = false [category] = "'exams'" [noHeader] = false *ngSwitchCase= "'exams'" >
</app-offeringlist >

<app-offeringlist [expanded] = true [category] = [feature] [noHeader] = false *ngSwitchCase= "'teacher'" >
</app-offeringlist >
</div>
-->



<!--
<div class = "my-5 h3 lead text-left text-warning font-weight-bold"  > Why Percentage Booster? </div>

<p class="lead"> The very word "Exam" brings 'exam fear' "exam stress',"exam fever" or "not serious" companion and impacts most of the students
      </p>


  hide
  Well if any of the above 4 words not hit you yet,give yourself a pat on back for being in fortunate 4% group of students
  but if you experience the same, you are not alone and may be we can be  a companion till your exam and may be beyond that :)

  In a  special survey based research among students during last few months of exams showed ~96% get somewhat unhealthy
  companion to exam.....Hence we went into questions.dataList and found root causes for unwelcome companions were: <br>

      1. Many were casual and overestimated themselves <br>
      2. Few were underestimating themselves and <br>
      3. Overwhemningly were not sure about their prep level and needed some external help<br>
All three groups had a common attribute though: <strong class="bg-warning">stress/panick</strong>
 Unfortunately it kept increasing while exam came near and near.. ..
 Well not anymore as we have taken advantage of Years of Teaching experiences with latest AI and Digital Technologies..
-->
  

<!--
<a (click) = "showEE('lm','pbstr')" class="btn btn-primary badge" mmrole="button">Learn more by navigating Objective and Subjective unit</a>

<hr class="my-4">
<p class="lead">
  <a class="btn btn-primary btn-lg" href="/esa/te/features/pbooster#" role="button">Learn more by navigating Objective and Subjective unit</a>
</p>
-->

<!--

  Digital Booster for Objective items(multiple-choice
    , true-false,
     matching and completion,


    <hr class="my-4">
    <p>subjective items include
    short-answer essay,
    extended-response essay,
    problem solving  and
    performance test items.
</p>
    <p class="lead">
      <a class="btn btn-primary btn-lg" href="/esa/te/features/pbooster#" role="button">Learn more by navigating Objective and Subjective unit</a>
    </p>
  </div>
-->
