
import { Component, OnInit, Input } from '@angular/core';
import { Comment } from '../comment';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentService } from '../comment.service';
import { SecurityService } from '../../shared/security.service';
import { Subscription } from 'rxjs';
import { FileService } from '../../shared/file.service';
import { ConfigService } from '../../shared/config.service';
import { Type } from '../../qstn/type';
import { ClientInfoService } from '../../shared/client-info.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-update-comment',
  templateUrl: './update-comment.component.html',
  styleUrls: ['./update-comment.component.scss'],
  providers : [NgForm]
})
export class UpdateCommentComponent implements OnInit {
  @Input()  responseMode:boolean;
  @Input()  id: number;
  @Input() cmntHeading:string;
   comment: Comment;
   toggleUpload:boolean;

  submitted:any;
  updateFileSub: Subscription;

  get signedUser() { return this.sec.getSignedUser()}

  constructor(private route: ActivatedRoute,private router: Router,
    private cms: CommentService,private cs: ClientInfoService,
    private sec:SecurityService,
    public cfg:ConfigService
    ) {

//file upload
this.updateFileSub = FileService.updateUploadUrl.subscribe(res=>{
  //this.comment.source= res;
  if(res.includes('etl')){
    if(this.responseMode){
      this.comment.response= this.comment.response +'<br> <img src = \"'+res + '\" />';
    }else {
  this.comment.comment= this.comment.comment +'<br> <img src = \"'+res + '\" />';
  }   
}
   } );
  

     }

  ngOnInit() {
    this.comment = new Comment();

   // this.id = this.route.snapshot.params['id'];
    
    this.cms.getComment(this.id)
      .subscribe(data => {
       // console.log(data)
        this.comment = data.result;
      }, error => console.log(error));
  }
  
  ngOnDestroy(): void {
    this.updateFileSub.unsubscribe()
}

enable(btnId:string,comment?:any){
  return this.cms.enable(btnId,comment); 
   }

   
  updateComment() {
    //console.log(this.comment);
    //console.log(this.comment.response);
    if(this.responseMode){
    this.comment.rspnseUser= this.signedUser;
  } else {
    this.comment.addUser= this.signedUser;
  }
this.cms.updateComment(this.id, this.comment)
      .subscribe(
        data =>  error => console.log(error));
    this.comment = new Comment();
    //alert("comment updated");
    this.cs.addMsg("info updated", 'toast');
    this.gotoList();
  }

  onSubmit() {
    this.updateComment();    
  }

  gotoList() {
    CommentService.updateList.next(true);
    //this.router.navigate(['//eba//digibest//features//comments']);
  }

}