
  
  
  <div class="card card-primary">
  
    <div class="card-heading">
       <br> 
      <p>TD: {{heading}} 
                 
          <button *ngIf= "!showAdd" (click)="addDetail()" class="btn btn-info" style="margin-left: 10px">Add Tag-Detail<i class="fa fa-book" aria-hidden="true"></i> </button>
          
           <button *ngIf= "showAdd" (click)="showAdd = false" class="btn btn-info" style="margin-left: 10px">Hide TD-Add</button>
          
        </p>
        <app-add-detail [iDetail]= detail *ngIf="showAdd"> </app-add-detail>
     <!--
      <app-update-detail  *ngIf="provideResponse" [responseMode]= true [id]=id> </app-update-detail>
    
      <app-show-detail  *ngIf="showDetail" [id]=id> </app-show-detail>
  -->  
        
      </div>
  
      
  
      <div *ngIf= "details" class="card-body">
        <table class="table table-striped">
          <thead>
            <tr class= "row">
              
              <th>#</th>
              
              <th>sequence</th>
              <th>subSeq</th>
              <th>Part</th>
              <th>base Table </th>
              <th>qstnDtlId</th>

            </tr>
          </thead>
          <tbody>
            <div *ngFor="let detail of details;let i=index;">
             <!-- | async -->
            <tr class= "row">    
              <td>{{detail.id}}</td>
              <td>{{detail.sequence}}</td>
              <td>{{detail.subSeq}}</td>
              <td>{{detail.part}}</td>
              <td>{{detail.qstnTbl}}</td>
              <td>{{detail.qstnDtlId}}</td>
               
              <td>
                
          <button (click)="deleteDetail(detail.id,i)" class="btn btn-danger">Delete</button>
          
          <button (click)="flipDetail(detail.id,i,detail)" class="btn btn-danger" style="margin-left: 10px">Flip Qstn</button>
               
          <button (click)="updateDetail(detail.id,i)" class="btn btn-info" style="margin-left: 10px"  >Update</button>
               
         <button (click)="detailDetail(detail.id,i)" class="btn btn-info" style="margin-left: 10px">Details</button>
                
         <button (click)="navigateQstn(detail.qstnDtlId)" class="btn btn-secondary" style="margin-left: 10px">Update Question</button>

         <!--       
        <button *ngIf="!detail.response && detail.message" (click)="respond(detail.id)" class="btn btn-info" style="margin-left: 10px" >Respond</button>
        -->      
         
      <!--  
    <a *ngIf="cs.enable('updateQstnJSF') && detail.qstnDtlId "  href = '{{cs.getRoot()}}/faces/content-dtl-update.xhtml?qstnId={{detail.qstnDtlId}}'
       class="nav-link text-warning px-3 " > <span class= "">update Question  </span>
     </a>
     -->

              </td>
              
            </tr>
         

     <div class= "row col-12">       
     
     <app-show-detail *ngIf="showDetail && setDetail==i" [id]=id> </app-show-detail>
     <app-update-detail  *ngIf="showUpdate && setUpdate==i" [id]=id> </app-update-detail>
    </div>
  </div>
          </tbody>
        </table>
      </div>
  
    
    </div>
