
  import { Component, Input, OnInit } from '@angular/core';
  import { Observable } from 'rxjs';
  import { SelectorService } from '../exam-selector/selector.service';
  
  @Component({
    selector: 'app-offering-lister',
    templateUrl: './offering-lister.component.html',
    styleUrls: ['./offering-lister.component.scss']
  })
  export class OfferingListerComponent implements OnInit {

  @Input() offerings:any[];
  
    
  selected:string;
  
   constructor(private slSrv:SelectorService) { }
  
  ngOnInit(): void {
    if(!this.selected && this.slSrv.offering){
      this.selected= this.slSrv.offering;
    }
     }
  
  browse(ofr:string){
    console.log(this.slSrv.selectedSubject);
 let selSub= this.slSrv.selectedSubject?this.slSrv.selectedSubject:'subject';
    console.log(selSub);
  this.slSrv.getSubjectList(ofr);
  this.slSrv.browseOffering(ofr);
  }
  }
  