import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { OfrDtlService } from '../../shared/headings/ofr-dtl.service';

@Component({
  selector: 'app-prd-combo',
  templateUrl: './prd-combo.component.html',
  styleUrls: ['./prd-combo.component.scss']
})
export class PrdComboComponent implements OnInit {
  hdr: any;

  constructor(private cs:ClientInfoService,private route: ActivatedRoute,public ods:OfrDtlService ) { }

  ngOnInit(): void {
    this.cs.updateParms(this.route);
    this.hdr= this.ods.getMenuHdr();
  }
  
  
}
