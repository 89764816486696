
     <div class="card mb-4 shadow-sm  fixedHeight">
      
        
        <!--
          <div  class="small card-title">  
            <p class="card-text title " innerHTML= {{s.title}}></p>
          </div>
          -->
              
         
               
         <div class="card-body ">
          <p class="card-text" innerHTML= {{s.instruction}}></p>
          <img [src]='getImageLink(h,s)' alt= '{{i}}' class="center-block img-fluid">
         </div>

         
          <div *ngIf = "menuType" class= "text-center"> 
            <div *ngIf='s.category || s.extLink ; else plain'>
          
             <a  *ngIf= '!s.format || s.format != "fullNav"'   routerLink = {{getRouteUrl(s.category,s.extLink,s.format)}}
            class="btn btn-primary badge border-1"  role="button"> <span class="pen25" [innerHTML] = s.title> </span></a>
              
            <a  *ngIf= 's.format && s.format == "fullNav"'  href = {{getRouteUrl(s.category,s.extLink,s.format)}}
            
            class="btn btn-primary badge border-1"  role="button"> <span class="pen25" [innerHTML] = s.title> </span></a>

          </div>


            <ng-template #plsin>
              &raquo;
            <div class="pen20  text-center" [innerHTML]= "s.title"> </div>
          </ng-template>

        

        </div>

         

      <!-- {% include icons/placeholder.svg width="100%" height="225" background="#55595c" color="#eceeef" class="card-img-top" text="Thumbnail" %}-->
      <div class= "card-body">
      <p class="card-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(s.question)">
       </p>
      </div>
      
       <!--  <p class="" innerHTML= {{s.question}}></p>-->


        <div  class="d-flex justify-content-between align-items-center">
          <div class="btn-group">

            <!--
            <button *ngIf= "enable('update')" (click)="navigateQstn(s)" class="btn btn-sm btn-outline-secondary badge border-0" style="margin-left: 10px">update</button>
            -->  
            
            <!--
            <button *ngIf= "enable('edit')" type="button" class="btn btn-sm btn-outline-secondary" (click) = "showUpdate = !showUpdate" >Edit</button>

            <button   type="button" class="btn btn-sm btn-outline-secondary" (click)="openScrollableContent(longContent)" >more</button>
           
            <button *ngIf= "true || s.answer"  class="btn btn-outline-info badge border-0  mb-2 mr-2 float-right" (click)="openScrollableContent(longContent)">?</button>
          
            <button  type="button" class="btn btn-sm btn-outline-secondary" (click) = "showUpdate = !showUpdate" >Edit</button>
          -->
          </div>
          
           <button  *ngIf= "!menuType && ((s.category || s.extLink) && (enable('go-site')) )" type="button" class="btn badge btn-outline-primary rounded-pill" (click) = 'cs.navigate(s.category,s.extLink,s.format)'><span [innerHTML]= 's.title'></span> </button>
            

          <small *ngIf= "s.answer  && enable('more')" class="text-muted">
           <button  type="button" class="btn border-0 btn-sm btn-outline-primary" (click)="openScrollableContent(longContent)" >...</button>
          </small>
        </div>


      </div>
    
  

    <!-- modal ..-->

<ng-template #longContent let-modal>
  <div class="modal-header">
    <h5 class="modal-title" innerHtml ={{s.instruction}}></h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" innerHTML= {{s.answer}}>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>



<!--

<app-my-json *ngIf= "showUpdate" [q] = 's' > </app-my-json>

  <br>
  <app-upd-qstn-dtl *ngIf= "showUpdate" [id] = s.id> </app-upd-qstn-dtl>
-->
  