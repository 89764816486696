import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BoardDataService } from '../../board-data.service';
import { PrepAsgnrService } from '../../shared/assigner/prep-asgnr.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { OfrDtlService } from '../../shared/headings/ofr-dtl.service';
import { TagService } from '../../shared/tag.service';
import { Header } from '../../tag/header';
import { HeaderService } from '../../tag/header.service';
//import { Header } from '../header';
import { QstnhdrService } from '../qstnhdr.service';

@Injectable({
  providedIn: 'root'
})
export class ViewService {

  get signedUser() { return this.cs.getSignedUser() }
  get site() { return this.cs.getSite() }
  get cat() { return this.cs.getCat() }
  get board() { return this.cs.getBoard() }
  get course() { return this.cs.getCourse() }
  get offering() { return this.cs.getOffering() }
  get subject() { return this.cs.getSubject() }
  get chapter() { return this.cs.getChapter() }

  get school() { return this.cs.getSchool() }
  get schlLoc() { return this.cs.getSchlLoc() }
  get learnMode() { return this.cs.getLearnMode() }
  get status() { return this.cs.getStatus() }
  get unsigned(){ return this.cs.isUnsigned() }
  get groupId() { return this.cs.getGroupId() }
  activeRec: any;

  constructor(private route: ActivatedRoute, private router: Router, private dataService: BoardDataService, public pa: PrepAsgnrService, public ods: OfrDtlService,
    public cs: ClientInfoService, private fd: FixedDataService, public ts: TagService, private tagHdrService: HeaderService, private qstnhdrService: QstnhdrService) { }


  show(btnId: string, i?: number, h?: any) {

    if (!h.type) {
      console.log("hdr type is missing ")
    }

    if (btnId == 'avail') {
      if (h && h.type && h.type.startsWith("t") && h.status !='store' ) { return false }
    }

    //return true;
    let component = 'viewList';
    // && !this.cs.enable(component, "needAvail", '', h)
    return this.cs.enable(component, btnId, '', h);

  }


  enable(btnId: string, i?: number, hdrRec?: any) {
    let component = 'viewList';
    if (btnId == "revise" && hdrRec.status == "rvsn") { return false }
    //console.log(hdrRec)
    return this.cs.enable(component, btnId, "", hdrRec);
  }


  //send testMode like cat- 'te'/' 'hwrk'
  createNBrowse(hdr: Header, i: number, status: string, overCat: string,nav?:boolean) {
    // console.log(hdr);
    this.pa.autoPost(hdr, status).subscribe(data => {
      if ((data as any).status === 200) {
          console.log(data);
        console.log("Success:posting done");
       let  newHdr= (data as any).result
       
       //bestubot's work  //|| this.school=='lwp' ?? what if prtn want to customize content..
       if(this.school=='lwb'){
          if(status=="avl"){
         let newStatus= "clr";
         let newType= newHdr.type.replace(newHdr.status,newStatus);   
         //alert(newType);
          this.tagHdrService.changeStatus(newHdr.id,newType,newStatus).subscribe(data => {
          if ((data as any).status === 200) {
            //  console.log(data);
            console.log("Success:change status done");
         } else {
          alert("error in change status");
        }
      }); 
        } }

        
        if(nav){
          this.showChptr(newHdr, overCat)
        }else{
        this.cs.addMsg("personalized view for success in steps enabled");
        }
      
      }
      else {
        alert("error,try later");
      }
    });

  }


  changeStatus(hdr:any,newStatus:string){
         let newType= hdr.type.replace(hdr.status,newStatus);   
          this.tagHdrService.changeStatus(hdr.id,newType,newStatus).subscribe(data => {
          if ((data as any).status === 200) {
            this.cs.addMsg("Success:change status done","info");

         } else {
          alert("error in change status");
        }
      });
  }
  

  getChapterUrl(t: any, overCat?: string, overType?: string, overId?: number) {
    var cat = "";
    var type = t.type;
    var id = t.id;

    if (overId) { id = overId; }
    if (overCat) { cat = overCat; }
    if (overType) { type = overType; }


    let sub = this.subject//t.subject;
    
    if(!sub  || this.cat== "mkr-pbstr"){
        sub = [t.subject,t.book,t.branch].filter(Boolean).join("~");
      }  
    
    var chptrUrl = this.cs.getRouteUrl(cat, type + "/" + sub + "/" + id);
   // console.log(chptrUrl);
    return chptrUrl;
  }

           
  showChptr(t: any, overCat?: string, overType?: string, overId?: number) {
   
   let chptrUrl =  this.getChapterUrl(t,overCat,overType,overId);
    //this.ods.setChapHdr(t);
    this.router.navigateByUrl(chptrUrl);
  }

  showGrpAso(t: any, overCat?: string, overType?: string, overId?: string) {
   
    let chptrUrl =  this.getChapterUrl(t,overCat,overType,+overId);
   
    if(!overId){
      overId= t.headerId?t.headerId:t.id;
    }
    chptrUrl = chptrUrl.replace(t.id, "grpAso/"+overId)
     this.router.navigateByUrl(chptrUrl);
   }



  
  storeToAvail(hdr: any, i: number) {
    this.pa.autoPost(hdr, 'avl').subscribe(data => {
      if ((data as any).status === 200) {
        console.log(data);
        console.log("Success:posting done");
        this.router.navigate([this.getChapterUrl('', (data as any).result)]);
      }
      else {
        alert("error,try later");
      }

    });
  }

  canAvail(){
    
  }

}
