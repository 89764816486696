import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CntxtService } from '../../pages/cntxt-menu/cntxt.service';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-mind-engager',
  templateUrl: './mind-engager.component.html',
  styleUrls: ['./mind-engager.component.scss']
})
export class MindEngagerComponent implements OnInit {

  showNews:boolean;
  showDuk:boolean;
  showQuiz:boolean;

  constructor(public cs:ClientInfoService,private router: Router,public cm :CntxtService) { }

  ngOnInit(): void {
  }
    
  browse(cat:string,extUrl:string) {
    var chptrUrl = this.cs.getRouteUrl(cat,extUrl );
    this.router.navigateByUrl(chptrUrl);
   }

}
