
<!--*ngIf='header.status=="loaded"'-->
<!-- merge options...-->

<ng-container >
   
<button  *ngIf= "enable('check',i,header)"  class= "btn-md btn-primary text-white  badge mr-1"  (click)="checkMerge(header,i)"> Check-Merge</button>

<a *ngIf= "header.extUrl && enable('report',i,header) "  href = '{{mergeReport(header,i)}}'
class="btn-md btn-info  badge " target= "empty" > <span>Merge-Report </span>
</a>

<a  *ngIf= "enable('process',i,header) "  class= "btn-md btn-danger badge mr-1"  (click)="processMerge(header,i)"> Process-Merge</a>



</ng-container>


