import { Component, Input, OnInit, inject } from '@angular/core';
import { AdService } from '../ad.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { HttpHlprService } from '../../shared/http-hlpr.service';
import { BoardDataService } from '../../board-data.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-ad-banner',
  templateUrl: './ad-banner.component.html',
  styleUrls: ['./ad-banner.component.scss']
})
export class AdBannerComponent implements OnInit {
  component: string;
  data$: Observable<any>;
  @Input() ovrSub:string;

  get cat() {return this.cs.getCat() };
  get feature() { return this.cs.getFeature()};

  constructor(public adService: AdService, public cs: ClientInfoService, public h: HttpHlprService,private ds: BoardDataService) { 
    this.component=  this.constructor.name;
  }
//https://stackblitz.com/angular/kkmynjleonk?file=src%2Fapp%2Fad-banner.component.ts

  ngOnInit(): void {
   
    
   let key =  this.ovrSub;

   if(!key){
   key = this.feature?this.feature:this.cat + "~config";
   }

    this.data$ = this.ds.getCfgData(this.h.si, this.h.bo, this.h.co, "cfg", key);
  }
  public adList = this.adService.getAds();
  
  private currentAdIndex = 0;
//
  get currentAd() {
    return this.adList[this.currentAdIndex];
  }


  displayNextAd() {
    
    this.currentAdIndex++;
    // Reset the current ad index back to `0` when we reach the end of an array.
    if (this.currentAdIndex === this.adList.length) {
      this.currentAdIndex = 0;
    }
  }


}
