import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-lister',
  templateUrl: './data-lister.component.html',
  styleUrls: ['./data-lister.component.scss']
})
export class DataListerComponent implements OnInit {
  @Input() pageLength :any;
  @Input() dataList :any;
  
  @Input() iFldList :any;
  @Input() listType :any; 
  title = 'datatables';
 
  dtOptions: DataTables.Settings = {};
  
  fldList= ['HdrId','DetailId','comment','type','domain','title'];
   
  expandI: any;
  c:any;

  constructor() { }

  ngOnInit(): void {

    if(this.iFldList){
      this.fldList= this.iFldList;
    }

    let pageLength= this.pageLength? this.pageLength:10;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
  };
   
  }


  getValList(c:any, listType?:string ){
    
    if(listType == 'siteList') {
      return [
        c.domain
        ,c.name 
        , c.owner
        , c.useEBstore
        , c.enabled
    ];

    } else {
    return [c.parentHdrId,c.parentDtlId 
        , c.comment
        , c.cmntType
        , c.domain,
        c.title
    ];
  }

}

   
expand(c:Comment,i){
  this.c= c;
  this.expandI==i ? this.expandI =-1: this.expandI=i;
  }

  
}
