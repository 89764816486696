import { Component, OnInit } from '@angular/core';
import { ChartData } from '../../shared/chart.data';
import { QuizService } from '../../shared/quiz.service';
import { Chart } from 'chart.js';
import { ClientInfoService } from '../../shared/client-info.service';

import { BoardDataService } from '../../board-data.service';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss']
})
export class DashComponent implements OnInit {
chartData: ChartData[];
testSetArray: string[] = [];
testScoreArray:  Number[] = [];
myLineChart:any;
myBarChart:any;

get signedUser() { return this.dataService.getSignedUserNew() }

course: string = "X";

canvas: any;
  ctx: any;
dataPrime: any;
optionsPrime: any;

options: any;
chartData1: any;
constructor(public quizService: QuizService, private cs:ClientInfoService,private dataService: BoardDataService) { }

  ngOnInit() {
this.chartData = this.quizService.getChartData();
//alert(this.chartData.length);
//alert(this.chartData[2].set);

// retrieving score
this.chartData.forEach(y => {
      //alert(y.set);
          this.testSetArray.push(y.set);
          //alert(y.set);
          this.testScoreArray.push(y.percent);
          //alert(y.percent);
        });

        //'myChart' is in html
        
        
        this.chartData1={
          labels: this.testSetArray,
          datasets: [
            {
              label: this.signedUser + "'s Bstr Result in sets",
              data: this.testScoreArray,
              borderColor: 'green',
              backgroundColor:'blue',
              //borderColor: '#3cba9f',
              //borderColor: '#4bc0c0',
              fill: false

            },
            {
                label: 'Current Mean Trend for class ' + this.course,
                data: [28, 48, 40, 19, 86, 27, 90],
               // backgroundColor:"white"
               fill: false,
               borderColor: 'orange',
               backgroundColor:'green'
            }
          ]
        };



        //same for line and Bar Chart
        this.options= {
          title: {
            display: true,
            fontColor:'blue',
            text: 'Performance DashBoard for you: '+ this.signedUser ,
            fontSize: 20,
            position: 'top'
          },
          legend: {
            display: true,
            position: 'bottom'

          },
          scales: {
            xAxes: [{
              display: true

            }],
            yAxes: [{
              display: true
            }],
        }  };

        
     this.lineChart();   
     this.barChart(); 

      } //method ends




lineChart(){

this.canvas = document.getElementById('myLineChart');
this.ctx = this.canvas.getContext('2d');
this.myLineChart = new Chart(this.ctx,{
  type: 'line',
  data: this.chartData1,
  options:this.options
      });
    }


    barChart(){
this.canvas = document.getElementById('myBarChart');
this.ctx = this.canvas.getContext('2d');

this.myBarChart = new Chart(this.ctx,{
        type: 'bar',
        data: this.chartData1,
        options:this.options
            });
          }    


} //class ends
