import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { ExamDataService } from '../../exam-data.service';
import { QuestionE } from '../../questione';
import { toArray } from 'rxjs/operators';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss']
})
export class SubjectComponent implements OnInit {
board:string;
offering : string ;
subject : string ;

showNavigationArrows = true;
showNavigationIndicators = true;
tips: Object;
vids : Object;
links : Object;
qstns : QuestionE[];
qstn:QuestionE;
images: object;
images1 : any[];
titleTips :string ;
titleVids :string ;
questions: Object ;
question:Object;

constructor(private route: ActivatedRoute , private data: ExamDataService) {
this.route.params.subscribe( params => {
//alert(params);
//console.log(params);
this.board= params['board'];

this.subject= params['offering'];
this.subject= params['subject'];
});
   }

ngOnInit() {

this.board= 'ICSE';
//alert(this.subject);
 this.titleTips = 'TIPS and TRICKS FOR :';
  this.titleVids = 'Some Videos  FOR : ';

if(this.board== 'ICSE' && this.subject=='Physics')
{
  this.loadIcsePhysics();
}

if(this.board== 'ICSE' && this.subject=='Chemistry')
{
 //alert('Inside Load Chem ');
 this.loadIcseChemistry();
}


if(this.board== 'ICSE' && this.subject=='Mathematics')
{
  this.loadIcseMathematics();
}

if(this.board== 'ICSE' && this.subject=='Civics')
{
  this.loadIcseCivics();
}

 if(this.board== 'ICSE' && this.subject=='History')
{
  this.loadIcseHistory();
}
  }




   loadIcsePhysics()
{

  this.data.getQuestions(699).subscribe(dataList => {
        this.links = dataList;
        });



   this.data.getQuestions(675).subscribe(dataList => {
        this.tips = dataList;
        });

        this.data.getQuestions(677).subscribe(dataList => {
        this.vids = dataList;
        });
        this.images= [
 "https://examsteppers.com/examimages/pn/physicsnote-1.jpg",
 "https://examsteppers.com/examimages/pn/physicsnote-2.jpg",
 "https://examsteppers.com/examimages/pn/physicsnote-3.jpg",
 "https://examsteppers.com/examimages/pn/physicsnote-4.jpg",
 "https://examsteppers.com/examimages/pn/physicsnote-5.jpg",
 "https://examsteppers.com/examimages/pn/physicsnote-6.jpg",
 "https://examsteppers.com/examimages/pn/physicsnote-7.jpg",
 "https://examsteppers.com/examimages/pn/physicsnote-8.jpg",
 "https://examsteppers.com/examimages/pn/physicsnote-9.jpg",
 "https://examsteppers.com/examimages/pn/physicsnote-10.jpg",
 "https://examsteppers.com/examimages/pn/physicsnote-11.jpg",
 "https://examsteppers.com/examimages/pn/physicsnote-12.jpg",
 ];
}


loadIcseChemistry()
{

this.tips= null;
   this.data.getQuestions(0).subscribe(dataList => {
        this.tips = dataList;
        });

        this.data.getQuestions(0).subscribe(dataList => {
        this.vids = dataList;
        });
        this.images= [
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-1-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-2-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-3-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-4-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-5-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-6-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-7-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-8-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-9-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-10-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-11-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-12-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-13-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-14-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-15-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-16-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-17-1024.jpg",
 "https://examsteppers.com/examimages/cn/chemistry-equations-for-icse-class-10-18-1024.jpg",
 ];
}


loadIcseMathematics()
{
this.tips= null;
   this.data.getQuestions(0).subscribe(dataList => {
        this.tips = dataList;
        });

        this.data.getQuestions(0).subscribe(dataList => {
        this.vids = dataList;
        });
        this.images= [
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-1-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-2-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-3-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-4-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-5-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-6-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-7-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-8-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-9-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-10-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-11-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-12-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-13-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-14-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-15-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-16-1024.jpg",
 "https://examsteppers.com/examimages/mn/icse-mathematics-formulae-sheet-17-1024.jpg",
 ];
}

loadIcseCivics()
{
this.tips= null;
   this.data.getQuestions(0).subscribe(dataList => {
        this.tips = dataList;
        });


        this.data.getQuestions(0).subscribe(dataList => {
        this.vids = dataList;
        });
        this.images= [

 "https://examsteppers.com/examimages//civnote/civ-3.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-4.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-5.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-6.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-7.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-8.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-9.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-10.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-11.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-12.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-13.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-14.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-15.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-16.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-17.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-18.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-19.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-20.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-21.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-22.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-23.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-24.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-25.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-26.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-27.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-28.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-29.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-30.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-31.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-32.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-33.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-34.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-35.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-36.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-37.jpeg",
 "https://examsteppers.com/examimages//civnote/civ-38.jpeg",

 ];
}

loadIcseHistory()
{
this.tips= null;
   this.data.getQuestions(0).subscribe(dataList => {
        this.tips = dataList;
        });


        this.data.getQuestions(0).subscribe(dataList => {
        this.vids = dataList;
        });
        this.images= [

 "https://examsteppers.com/examimages//histnote/indian-history.pdf",
 "https://examsteppers.com/examimages//histnote/world-history.pdf",
 ];
}



loadIcseCivics1()
{
alert("Inside");
this.tips= null;
this.qstns= [];
   this.data.getQuestionsRaw(681).subscribe(dataList => {
       this.tips= dataList;
       //this.qstns=  toArray<this.tips>();
        });


var resultArray = Object.keys(this.tips).map(function(personNamedIndex){
    let qstn = this.tips[personNamedIndex];
    // do something with person
    return qstn;
});
alert("yes" + resultArray.length);
// you have resultArray having iterated objects


}






}
