
{{table}}
<!--style="width: 800px; height: 400px;"-->
<div class="container px-5 mx-5 ">
<div *ngIf= "!changed" id="jsoneditor">
</div>


 <button class=  "float-right btn btn-outline-primary border-0 badge  " type= "button" (click) = 'submit()'> update</button>

 <button class=  "mx-2 float-right btn btn-outline-primary border-0 badge  " type= "button" (click) = 'copy()'> copy to new </button>

 <button *ngIf= 'create' class=  "mx-2 float-right btn btn-outline-primary border-0 badge  " type= "button" (click) = 'createRec()'> create </button>


 
</div>
