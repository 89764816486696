<div class= "row">
              
    <button *ngIf = "ls.enable('delete')" (click)="delete(c.id)" class="btn btn-danger">Delete</button>
    

    <button *ngIf = "ls.enable('detail')" (click)="commentDetail(c.id)" class="btn btn-info" style="margin-left: 10px">Detail</button>
  
    
  <button type= "button" *ngIf = "ls.enable('update')" (click)="displayUpdate(c.id,i)" class="btn btn-info" style="margin-left: 10px"  >Update</button> 
  
        
    <ng-container *ngIf= 'cs.getCat().startsWith("cmnt-") && c.parentDtlId'>    
  <button (click)="navigateQstn(c)" class="btn btn-secondary" style="margin-left: 10px">related Question</button>

      
  <button (click)="navigateQstnHdr(c)" class="badge btn btn-secondary" style="margin-left: 10px">Go to Qstn Set</button>
</ng-container>    
  
  <hr>
  


 <button *ngIf="ls.enable('resolve',c.cmntType, i) || ls.enable('respond',c.cmntType,i)" (click)="displayResponse(c.id,i) " class="btn btn-info" style="margin-left: 10px" >Respond</button>
   

</div>

<div class= "row">
  <app-file-upload [iComment]= c *ngIf="showUploadFile"> </app-file-upload>
  <app-comment-detail *ngIf='showDetail' [id]=id > </app-comment-detail>
  <!--
  {{showUpdate}}--{{i}}-{{updateId}}
  -->
  
  <app-update-comment  *ngIf="showUpdate && updateId == i" [id]= c.id > </app-update-comment>

  <app-reply  *ngIf="showResponse && respondI == i" [id]= c.id > </app-reply>
  <!--
    r: {{showResponse}}--{{i}}-{{responseId}}
  <app-update-comment  *ngIf="provideResponse && respondI == i" [responseMode]= true [id]=c.id [cmntHeading] = heading> </app-update-comment>
  -->



  </div>
