import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { HttpHlprService } from '../../shared/http-hlpr.service';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-daily-detail',
  templateUrl: './daily-detail.component.html',
  styleUrls: ['./daily-detail.component.scss']
})
export class DailyDetailComponent implements OnInit ,OnChanges {
  @Input() type :string;
  @Input() subject :string; 

  qd: any;
  titleMap:Map<string,string> = new Map();
  key: string;

  utube= "https://www.youtube.com/embed/";
  vimeoUrl= "https://player.vimeo.com/video/137857207";
  showVimeo:boolean;
  showPastVid:boolean;

  constructor( public cs:ClientInfoService, private dataService: BoardDataService,private h:HttpHlprService) { }

  ngOnInit(): void {
    
     this.loader();
  }

  ngOnChanges(changes: SimpleChanges): void{

 
    
    this.loader();
 }


  private loader() {
    this.key = `${this.type}-${this.subject}`.toLowerCase();
    this.titleMap['fresh-thought'] = 'Thought of the day';
    this.titleMap['fresh-story'] = 'Story of the day';
    this.titleMap['fresh-quiz'] =  "Quiz of the week";
    this.titleMap['fresh-didyouknow'] =  "did You know?";
    
    /* optimize later
    let qdStr = localStorage.getItem(this.key);
    if(qdStr){
    this.qd= JSON.parse(qdStr);
    }
    if (!this.qd) {
      */

      this.dataService.getDailyDetail(this.h, this.type, this.subject).subscribe(response => {
        if (response) {
          this.qd = response as any;
          localStorage.setItem(this.key, JSON.stringify(this.qd));
        }
      });
    }
//  }
}
