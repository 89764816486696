import { Injectable } from '@angular/core';
import { Status } from '../Status';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  showStatusBtn: boolean;
  

  constructor() { }


  loadStatus(status:string): Status[] {
    // getting called too many times, why???
    // console.log("Hi from status-changer" + status );
    this.showStatusBtn = false;
    
    let statusList: Status[] = new Array();
    switch (status) {
      case 'verify': {
        statusList = [{ code: 'store', name: 'Store in DBStore' },{ code: 'checked', name: 'QC Checked' },{ code: 'reload', name: 'activate reload' },];
        break;
      }

      case 'checked': {
        statusList = [{ code: 'store', name: 'Store in DBStore' },{ code: 'reload', name: 'activate reload' },{ code: 'avail', name: 'move to avail' }];
        break;
      }

      case 'final': {
        statusList = [{ code: 'shr', name: 'Share' }, { code: 'asgn', name: 'Assign to Student(s)' },{ code: 'dgtz', name: 'Request to Digitize' },{ code: 'wip', name: 'activate wip' }];
        break;
      }

      case 'raw': {
        statusList = [{ code: 'dgtz', name: 'Request to Digitize' },
                      { code: 'final', name: 'finalize' }];
        break;
      }

      case 'dgtz': {
        statusList = [{ code: 'raw', name: 'back to raw' }
                      ];
        break;
      }

      case 'wip': {
        statusList = [{ code: 'store', name: 'put in Store ' },{ code: 'asgn', name: 'Assign' }, { code: 'avl', name: 'Available' },{ code: 'raw', name: 'reset to Raw' },{ code: 'storeNAsgn', name: 'store & assign' },{ code: 'ready', name: 'ready to load' },
        { code: 'onDemand', name: 'enable Customizer' }];
        statusList.push(new Status('stage','move to Stage-Envi'));
        break;
      }

      case 'avl': {
        statusList = [{ code: 'store', name: 'put in Store '},{ code: 'shr', name: 'engage class'},{ code: 'asgn', name: 'Assign to Student(s)'}, { code: 'rvsn', name: 'Revision' } ,{ code: 'close', name: 'close for all sections'},{ code: 'arc', name: 'Archive '},];
        break;
      }

      case 'wipGood': {
        statusList = [{ code: 'stage', name: 'move to stage' },{ code: 'ready', name: 'ready to load' }, { code: 'wip', name: 'back to edit' }];
        statusList.push(new Status('stage','move to Stage-Envi'));
        break;
        }
      
        case 'ready': {
          statusList = [{ code: 'prod', name: 'move to prod' },{ code: 'raw', name: 'reset to Raw' },{ code: 'dgtz', name: 'Request to Digitize' }, { code: 'wip', name: 'back to edit' }];
          break;
        }
      


      case 'asgn': {
        statusList = [{ code: 'cmplt', name: 'Mark Complete' },{ code: 'avl', name: 'pull back from student' }];
        break;
      }

      case 'wrk': {

        statusList = [{ code: 'crct', name: 'Submit For correction' }];
        break;
      }

      case 'crct': {

        statusList = [{ code: 'rslt', name: 'publish Result' }];
        break;
      }
      
      case 'rslt': {
        statusList = [{ code: 'grded', name: 'Move to  Graded' }];
        break;
      }

      case 'loaded': {

        statusList = [{ code: 'reload', name: 'activate reload' },{ code: 'store', name: 'store' },
        { code: 'avl', name: 'Make Available' }
      ];
        break;
      }

      case 'shr': {
        statusList = [{ code: 'cmplt', name: 'Mark complete' },{ code: 'clr', name: 'send for check ansr & raise doubt' }];
        break;
      }

      
      case 'clr':
        
        statusList = [ { code: 'rvsn', name: 'class over?mark for revision', desc: 'click after class is over' }];
        break;

      

      case 'rvsn':
        statusList = [ { code: 'cmplt', name: 'mark Complete', desc:'click once term exam is over' }];
        break;  
         
       
        // below for Assign to Student(s)ment only 
      case 'grded':
      case 'fnsh': {
        statusList = [{ code: 'arc', name: 'Archive' }];
        break;
      }

 
       // 
       case 'cmplt':
        statusList = [{ code: 'avl', name: 'back to avail' },{ code: 'asgn', name: 'back to assign' },{ code: 'rvsn', name: 'Revision' },{ code: 'arc', name: 'Archive' }];
       break;
       
      case 'store': {
        statusList = [{ code: 'avl', name: 'move to avail' },{ code: 'cmplt', name: 'Mark Complete' },{ code: 'arch', name: 'Archive' },
        { code: 'wip', name: 'back to edit' }
      ];
        break;
      }

      default:
      
     statusList =  [{code:'asgn',name:'Assign to Student(s)'},
                 {code:'avl',name:'Available'},
                 {code:'wip',name:'Work in Progress'}]; 
                 statusList.push(new Status('verified','Verified-LoadedData'));
                 statusList.push(new Status('merge','Merge qstn with no-ansr'));
                 statusList.push(new Status('digitized','Digitized'));
                 statusList.push(new Status('stage','move to Stage-Envi'));
                 statusList.push(new Status('store','move to Store'));
                 statusList.push(new Status('prod','move to Prod-Envi'));
                 statusList.push(new Status('lock','Lock for Update'));
                 statusList.push(new Status('reload','cleanup+ reload'));
                 statusList.push(new Status('arch','Archive input folders'));
      
      statusList.push(new Status('crct','Correction'));
      statusList.push(new Status('rslt','Publish Result'));
      statusList.push(new Status('clr','t'));
      statusList.push(new Status('close','close for all'));
      


    }
    if (statusList.length > 0) {
      this.showStatusBtn = true;
    }
    return statusList;
  }

}
