import { Component, Input, OnInit } from '@angular/core';
import { TestService } from '../../test/test.service';

@Component({
  selector: 'app-mcq-qstn',
  templateUrl: './mcq-qstn.component.html',
  styleUrls: ['./mcq-qstn.component.scss']
})
export class McqQstnComponent implements OnInit {

  @Input()  q: any;
  @Input() i:number;
  
  constructor(public ts:TestService) { }

  ngOnInit(): void {
  }
  
}
