<ng-container [ngSwitch]= getView() class="row">
  
  <app-taghdr-list *ngSwitchCase='"tag"' [tagType]='"mtg"' mode='"crud"'
    [owner]=signedUser> </app-taghdr-list>

  <app-fltr-hdr-lstr *ngSwitchCase='"fltr"' [filter]=true
    [owner]=userId?userId:signedUser [fltrStts]='status'>
  </app-fltr-hdr-lstr>
  
  

  <app-njson-qstnhdr-list *ngSwitchCase='"siteowned"'>
  </app-njson-qstnhdr-list>


  <app-progress-lstr *ngSwitchCase= "'progress'" class="bg-color">
  </app-progress-lstr>

  <!--
  <app-fltr-hdr-lstr *ngSwitchCase='"only"' [filter]=true
    [owner]=userId?userId:signedUser [fltrStts]='status'>
  </app-fltr-hdr-lstr>
  {{cat}} -- view selected: {{view}}
-->
  <app-njson-qstnhdr-list *ngSwitchCase='"qstn"'>
  </app-njson-qstnhdr-list>
  
  <app-multi-hdr-slctr  *ngSwitchCase= "'multi'" > </app-multi-hdr-slctr>
  
  <app-chptr-lstr *ngSwitchCase= "'base'" class="bg-color">
  </app-chptr-lstr>

  <app-multi-hdr-slctr  *ngSwitchCase= "'src-slctr'" > </app-multi-hdr-slctr>

  <app-njson-qstnhdr-list *ngSwitchCase='"uni"'>
  </app-njson-qstnhdr-list>
  
  <app-taghdr-selector  *ngSwitchCase= "'uni'" > </app-taghdr-selector>

  <app-chptr-lstr *ngSwitchDefault class="bg-color ">
  </app-chptr-lstr>
</ng-container>