import { Component, Input, OnInit } from '@angular/core';
import { QstnListService } from '../qstn-list/qstn-list.service';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-unit-menu',
  templateUrl: './unit-menu.component.html',
  styleUrls: ['./unit-menu.component.scss']
})
export class UnitMenuComponent implements OnInit {
  @Input() hdr:any;
  @Input() i:number;

 constructor(public qs:QstnListService) { }

 ngOnInit(): void {
 
  this.qs.expandedAnsrs = this.qs.dfltExpandedAnsrs;
  }

  templateForm(value: any) {
    alert(JSON.stringify(value));
  }
  
}
