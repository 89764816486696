import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Comment } from "../comments/comment";
import { CommentService } from '../comments/comment.service';
import { ClientInfoService } from '../shared/client-info.service';
import { HeaderService } from '../tag/header.service';

@Injectable({
  providedIn: 'root'
})
export class TestService {
  
//public static hideTest: Subject<boolean> = new Subject();

cmntType= "Dans";
digAnsrs :boolean[] = new Array(4); 
featureType = '';
timer;
seconds:number =0;
testTime:number= 5;
lastSeconds:number=0;
showQstn:number =0;
responsed :number = 0;

count: number= 0;
 q :any;
qstns:any;
  closeTest: boolean;
  qstnInProcess: any;
  hdr: any;


  //very important for update or insert hence give the value if u add.
  //check for bug in assignment Mode.
  updateNeeded: boolean;
  showResult: boolean;

constructor(public cs:ClientInfoService,public cServe:CommentService,private headerService:HeaderService) {
 }


 storeAnsrsInDB():Observable<Object> {
  let user = this.cs.getSignedUser();
     let hdrId = this.cs.getChapterNum();

  if(this.qstns){
    const response = this.cServe.storeAnsrs(hdrId,user,this.qstns).pipe(map(
   data => {
     this.cs.addMsg(" ansrs Stored");
     this.cs.log(data)
    // this.qstns= (data as any).result;
     return data;
    } ));
return response;
 }

 }

 
 



setUsrAnsModel(q:any){
  if(q){
 if(q.ansCmnts && q.ansCmnts[0]){
// console.log(q.ansCmnts);
 let c= q.ansCmnts[0];
 this.updateNeeded= true; 
 } 
 else{
this.updateNeeded= false; 
q.ansCmnts= [];
q.ansCmnts.push(this.createCommentObj(q));
// console.log('ans set');
// console.log(q.ansCmnts);
}
  }
}

getDigOptn(o:string , index : string){

  var digOptnOption = o.split('.'); 
      if(digOptnOption.length>=2){
      return digOptnOption[0];
      } 

     return index;

}


 createCommentObj(q:any){
    
    let  c = new Comment();
    c.cmntType= this.cmntType;
    c.featureType= this.cs.getOffering();
    c.parentHdrId= +this.cs.getChapter();
    if(q){
    c.parentDtlId= q.id;
    }
    c.domain= this.cs.getSite();
    c.addUser= this.cs.getSignedUser();
    c.comment= "";
    if(this.featureType.startsWith('trvsn') ||
    this.featureType.startsWith('trfc'))
    {
      c.subject = this.cs.getSubject(); 
      c.clsSec = this.cs.getCrtClsSec();
      // c.course = this.cs.getCourse();  
    // console.log(c); 
    } 
    return c;
   

    //this.router.navigate(['eba','digibest','features','cmnt-add']);

 }

 previous(q?:any){
  // console.log(this.qstns.length +" hello, u calling me? "+ this.showQstn);
  if(q){ this.updateDuration(q);}
  if(this.showQstn >0){
    this.showQstn= this.showQstn-1;
    this.q=this.qstns[this.showQstn]; 
  }
  }

   
next(q?:any){
 // console.log(this.qstns.length +" hello, u calling me? "+ this.showQstn);
 if(q){ this.updateDuration(q);}

 if(this.showQstn < this.qstns.length-1){
    this.showQstn= this.showQstn+1;
    this.q=this.qstns[this.showQstn]; 
  }
  if(this.showQstn <= this.qstns.length-1){
  ++this.responsed;
  }
  


  /*
  if(this.qstns && this.showQstn== this.qstns.length){
  this.finishTest();  
  return;
  }
  */

}

getQstn(i?:number){
if(this.qstns){  
if(i){
  return this.qstns[i] ;
} else{ 
return this.qstns[this.showQstn] ;
}
}
return null;
}

startTimer() {
  this.timer = setInterval(() => {
    this.seconds++;
    localStorage.setItem('seconds', this.seconds.toString());
  }, 1000);
}

updateDuration(q: any) {

  if(q.ansCmnts && q.ansCmnts[0]){
  q.ansCmnts[0].flipWindow=  this.count;
  q.ansCmnts[0].timeTaken = q.ansCmnts[0].timeTaken + (this.seconds-this.lastSeconds);
  this.lastSeconds= this.seconds;
  }
  return q;

}


timeElapsed() {
  return Math.floor(this.seconds / 3600) + ':' + Math.floor(this.seconds- Math.floor((this.seconds / 3600)*60) / 60) + ':' + Math.floor(this.seconds % 60);
}


setTestTime(extra:string){

  this.cs.log(this.hdr,"testService-setTestTime");
  let extraJson = JSON.parse(this.hdr.extra);
  if(extraJson.testTime){
  this.testTime= extraJson.testTime;
  } else{
  this.testTime= 25; //default in minutes 
  }

  }
   


timeRemaining() {
  if(this.testTime){
  let r= this.testTime*60-this.seconds;
  let h = Math.floor(r/3600);
  let m= Math.floor((r-h*3600)/60)
  let s = Math.floor((r-h*3600-m*60)%60)
  return h + ':' + m + ':' + s;
} 
}


finishTest(){
  clearInterval(this.timer); 
  this.showQstn = 0; 
  let newOfr = this.cs.offering.replace("wrk",'crct');
  this.changeStatus(this.cs.chapter,newOfr,'crct');
    //navigate to result
//this.cs.addMsg('test is finished');
this.closeTest= true;
  }

  changeStatus(id,offering,status){
    const payload = {
      parentId: this.cs.chapter,
      };
  
    this.headerService.changeStatus(id,offering,status).subscribe(data => {
         if((data as any).status === 200) {
          console.log('data: from submission', data)
          console.log(this.cs.getRouteUrl('',''))

          }else {
        console.log((data as any).result);   
         alert("error,try later");
         }
       }
       );
  
  }

ansrTime(timeinSec:number) {
  return Math.floor(this.seconds / 3600) + ':' + Math.floor(this.seconds / 60) + ':' + Math.floor(this.seconds % 60);
}




}


