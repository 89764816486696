import { Observable, Subject } from "rxjs";
import { HeaderService } from "../header.service";
import { Header } from "../header";
import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core";
import { Router, RouterEvent, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';

import { filter } from 'rxjs/operators';
import { get } from 'http';

@Component({
  selector: 'app-taghdr-list',
  templateUrl: './taghdr-list.component.html',
  styleUrls: ['./taghdr-list.component.scss']
})
export class TagHdrListComponent implements OnInit {
  @Input() tagType:string;
  @Input() mode:string // select or crud
 /* please don't provide headerById if you want entire type like show wip in list */
  @Input() headerById :any;
  
  @Input() owner :string;
 // @Input() domain:string;
  //@Input() qstnHdrId:number;
  
  //@Input() featureType: string;
  

  //@Input() cmntType:string;
  
  //@Input() featureType: string;
  //@Input() parentHdrId: number;
  //@Input() parentDtlId: number;
  heading : string  ;
  showUpload:boolean;
  public header:Header = null;
  cmntType1:string;
  // headers: Observable<Header[]>; for async..
  headers: Header[];
  headerId:string;
  showAdd:boolean;
  showUpdate:boolean;
  showHeader:boolean;
  provideResponse:boolean;
  headerForTd: any;
  id:number;
  hideList: boolean;
  expand:boolean;
  selectedMsg: string;
  get clsSec(){ return  this.cs.getClsSec()};
  get signedUser(){ return  this.cs.getSignedUser()};
  get chapter(){ return this.cs.getChapter()};
  get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get status() { return this.cs.getStatus()};

  constructor(private route: ActivatedRoute,
    private router: Router, private headerService: HeaderService,public cs: ClientInfoService,private fd: FixedDataService
    ) {
      HeaderService.updateList.subscribe(res=>{
        this.fetchData();})
      //  
       }

  ngOnInit() {
    //alert("inside comnt srvce-ngOnInit ");
    
    this.fetchData();


    
    /* this may not be needed as @Input was not working due to absense of "' cons'" input parm 
     
    this.router.events.pipe(
    filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event) => {

      this.fetchData();

    });
   */

  }
 
  
  enable(btnId:string,i?:number,hdrRec?:any){
   
    if(btnId ==="select"){
      if(this.mode =='select'){return true;}
      if( this.cat && this.cat.includes("bldr")){
        return true;
      } else{
        return false;
      }
    }
      
     let component = 'thLstr';
     let enable = false;
     enable= this.cs.enable(component,btnId,'',hdrRec);

    }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.headerById || changes.tagType  ) {
     this.fetchData();
    }    

  }
  
   resetVar(){
    this.showAdd= false;
    this.showUpdate=false;
    this.showHeader= false;
    this.provideResponse=false;
    this.headers= null;
    this.heading= this.fd.getTagHeading(this.tagType);
      } 

  fetchData() {
   // alert("inside comnt srvce Hdr -fetchdata- ");
      this.resetVar();
      
      if(!this.headerById){
      this.headerService.getHeadersByType(this.tagType).subscribe(data => {
      this.headers  = data; });
      } 
    
      
// below method expects headerid!! not id....
      if(this.headerById){
        this.headerId= String((this.headerById as any).dataList[0].headerId);
       // console.log(this.headerId + this.tagType);
        
        if(!this.headerId){
      //  console.log("Unit HeaderID is null!! using just id");
        this.headerId = String((this.headerById as any).dataList[0].Id);
      }

        if(this.headerId){
          //alert("Hello:" + this.owner);
          if(this.owner){
            this.headerService.getUnitHeaders(this.tagType,this.headerId,this.owner).subscribe(data => {
              if(data)
              this.headers= (data as any).dataList;  
             // console.log(data);
            });   
          } else{

          this.headerService.getUnitHeaders(this.tagType,this.headerId).subscribe(data => { this.headers= (data as any).dataList;  
           // console.log(data);
            //this.selectId(this.headers[0].id);
           //console.log("selected: " + this.headers[0].id)
            //selected id by default.. first one for now..
          });}
        }
      
      }

         }
    

/* async pipe in html not working
let d  = this.headerService.getHeadersList();
this.headers = (d as any) .dataList;
*/
      

  deleteHeader(id: number) {
    this.id = id;
    this.headerService.delete(id)
      .subscribe(
        data => {
          //console.log(data);
          this.fetchData();
        },
        error => console.log(error));
  }

  loadFilterQstnSet(id: number) {
    this.id = id;
    this.headerService.loadFilterQstnSet(id)
      .subscribe(
        data => {
          console.log(data);
         // this.fetchData();
        },
        error => console.log(error));
  }

  
  updateStatus(id: number,status:string){
    this.id = id;
    this.showUpdate = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  updateHeader(id: number){
    this.id = id;
    this.showUpdate = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  getChapterUrl(type:string, header:any){
    if(!type){
      type= header.type;
      if(!type){
      type= this.offering;
      } 
    }
    let signedUser= this.cs.getSignedUser();
    if(type=='tasgn1-dhw'){
      
   // header= this.getWrkTagHeaderByNum(header.id,signedUser);  
     // alert("Hello from" );
  }
  let mode1 = '';
  //let mode = this.getContentMode(type,header);
  //default cat was "'dhs", change if needed
  return ['/',this.site,this.cat,this.board,this.course,type,this.subject,header.id,mode1].filter(Boolean).join('/');
  } 

  
  respond(id: number){
    this.id = id;
    this.provideResponse = true;

    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  provideResponseHeader(id: number){
    this.provideResponse = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  addHeader(){
    if(this.headerById){
    this.header= (this.headerById as any).dataList[0] ;
    } else{
   //create new Header
   this.header = new Header();
   this.header.site= this.site;
   this.header.board= this.board;
   this.header.course= this.course;
   this.header.subject= this.subject;
   if(this.chapter){
   this.header.headerId= Number(this.chapter);

  }

}
   this.overrideHeaderValue()    
   this.showAdd = true;
    //this.router.navigate(['eba','digibest','features','cmnt-add']);
  }

  overrideHeaderValue(){

    //temp major
    this.header.owner = this.signedUser;
    if(this.tagType){
      this.header.type= this.tagType;
      
    } else{
      this.header.type= this.offering;
    }

    this.header.clsSec = this.cs.getCrtClsSec(this.header.type);
    
    if(this.header.type && !this.header.status){
    var typeStatus = this.header.type.split('-'); 
      if(typeStatus.length==2){
      this.header.status= typeStatus[0].substr(1);
      }
    
       }

      }

  headerHeader(id: number){
    this.id = id;
    this.showHeader = true;
    //this.router.navigate(['eba','digibest','features','cmnt-dtl','byId', id]);
  }


  selectId(id:number){
    HeaderService.updateDhwHdrId.next(id);
    this.hideList= true;
    //this.selectedMsg= id + " selected,show List";
    this.selectedMsg= "show List";

    this.cs.addMsg(id + " selected,please add questions..","info");
  
   }

}

