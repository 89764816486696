import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cmnt-rspnse',
  templateUrl: './cmnt-rspnse.component.html',
  styleUrls: ['./cmnt-rspnse.component.scss']
})
export class CmntRspnseComponent implements OnInit {
@Input() c:any;
  constructor() { }

  ngOnInit(): void {
  }
  
}
