
<ng-container *ngIf= false > 
    <div (click)="s.processClick(header,i)"> 
      <span  class= " btn badge btn-primary text-white my-2"> {{s.getTitle(header)}} </span> 
    <span *ngIf='s.enable("status")' class= "mx-3 text-muted font-italic text-small"> {{header.status}}</span>
    </div>
</ng-container>


<a [routerLink]="s.processClick(header,i)"> 
  <span  class= " btn badge btn-primary text-white my-2"> {{s.getTitle(header)}} </span> 
<span *ngIf='s.enable("status")' class= "mx-3 text-muted font-italic text-small"> {{header.status}}</span>
</a>
