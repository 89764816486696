import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable} from 'rxjs';
import { RecSlctrService } from '../../gen/rec-slctr/rec-slctr.service';
import { QstnhdrService } from '../qstnhdr.service';

@Component({
  selector: 'app-multi-hdr-slctr',
  templateUrl: './multi-hdr-slctr.component.html',
  styleUrls: ['./multi-hdr-slctr.component.scss']
})
export class MultiHdrSlctrComponent implements OnInit {

  
    @Input() headerId: number;

    @Input() refHdr:any;

     // siteList: any;
      showAdd:boolean;
      title = 'Chapter/Unit List';

  hdrs$: Observable<any>;
  noCall :any;
  expandI:any;
  calling:boolean;
  multiStoreKey= "cmpltChptrs";

  dtOptions: DataTables.Settings = 
       {
        pagingType: 'full_numbers',
        pageLength: 7,
        processing: true
    };
  c: any;
    
  fldList = [ 'id', 'type','title','option'];

  dataList: any;
  loading:boolean;  
 allSelected:boolean;
      
    
      
    
  getValList(c:any, listType?:string ){
        
     return [
            c.id,
            c.type,
            this.getTitle(c) 
        ];
    
    }
    
  
  get allowEdit() {return this.qh.cs.enable('json-qstn-hdr','allowEdit')};
  
  constructor(public qh:QstnhdrService, private activatedRoute:ActivatedRoute) {
      
    }
  
    ngOnInit(): void {
      
     

      this.activatedRoute.params.subscribe(({ subject }) => {
        let offering = this.qh.cs.getOffering();
         if(offering.includes("pyq")){
          this.title = " Previous Question Papers with Answers"
         }

        this.calling = true; 
        this.hdrs$=  this.qh.list(offering)
        });
        

     if(this.allowEdit) {

     }
   

   this.calling= true;
   
    
  }
  
   
  expand(c:any,i){
    this.c= c;
    this.expandI==i ? this.expandI =-1: this.expandI=i;
    }
  
  
  getTitle(c){
  
   return [c.year,c.chapter,c.section,c.book,c.branch,c.title].filter(Boolean).join("-"); 
  }
  getTable(c){
  if(this.qh.cs.tagBased(c.type)){
    return "taghdr"
  } else{
    return "qstnhdr"
    
  }

  }


  selectAll(contents:any) {
    const selectedIds = contents.map(({ id }) => id);
  localStorage.setItem(this.multiStoreKey, selectedIds);
 
    RecSlctrService.allSelectedI.next(true);
    this.allSelected= true;
  //store all    

  }

  unselectAll(contents:any) {
    //clear the all stored   
    localStorage.removeItem(this.multiStoreKey);
    RecSlctrService.allSelectedI.next(false);
    this.allSelected= false;
    }

  }
  
