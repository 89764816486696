
<div class= "card container">
  <h5 class ="py-2 text-primary"> Sign Up Please...</h5>

<div *ngIf="!success">

  <div *ngIf="board && course"  class="text-center">
    <span class="text-info"> selected  board & Exam : {{board}}-{{course}} </span> else change above    
   </div>


<div class= "text-center ml-5" >
<form [formGroup]="signupForm" (ngSubmit)="onSubmit()">


<h5 *ngIf="success">Your form is valid!</h5>

    
<div class= "row">
      
      <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="firstName" placeholder="First Name">
      
      <span  *ngIf="submitted && signupForm.controls.firstName.errors" class="error">
        <span *ngIf="signupForm.controls.firstName.errors.required">First name is required</span>
      </span>
    
    
      <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="lastName" placeholder="Last Name">
      <span *ngIf="submitted && signupForm.controls.lastName.errors" class="error">
        <span *ngIf="signupForm.controls.lastName.errors.required">Last Name is required</span>
      </span>
    
  

      <input class="form-control col-9 col-md-4 m-1" type="text" formControlName="userId" placeholder="UserId">
      <div *ngIf="submitted && signupForm.controls.userId.errors" class="error">
        <div *ngIf="signupForm.controls.userId.errors.required">Desired userId is required and should be unique </div>
      </div>

   
      <!-- may use it later don't delete-->
       <!--
        <span *ngIf="(avl$|async) as avl" > 
        <span class="text-success"> </span> *ngIf= 'idAvailable=="true"'> &#10003;  </span> 
        </span>
        -->  

      <div class= "col-1 mt-2"  *ngIf= "true">
      <button type= "button" (click) ='isAvl(site,userId)'> check        
        </button>

      <span class="text-success" *ngIf= 'idAvailable=="true"'> &#10003;  </span> 
      <span class="text-danger" *ngIf= 'idAvailable=="false"'> &#10006;  </span> 
      
      </div>
         
      <input class="form-control col-10 col-md-5 m-1" type="password" formControlName="password" placeholder="password">
      <div *ngIf="submitted && signupForm.controls.password.errors" class="error">
        <div *ngIf="signupForm.controls.password.errors.required">Password is required</div>
      </div>
    
   



      <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="phone" placeholder="Phone">
      <div *ngIf="submitted && signupForm.controls.phone.errors" class="error ">
        <div *ngIf="signupForm.controls.phone.errors.required">Phone number  is needed</div>
      </div>

    
      <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="email" placeholder="Email">
      <div *ngIf="submitted && signupForm.controls.email.errors" class="error ">
        <div *ngIf="signupForm.controls.email.errors.required">An Email Id is required please</div>
      </div>


     
      <!--
      <div class = "col-10 col-md-5 m-1">
      <select formControlName="board" class="col-10 col-md-5 m-1 browser-default custom-select" placeholder= "Board/Exam">
        <option value="" disabled>Main Board/Exam</option>
        <option value="CBSE" selected>CBSE</option>
        <option value="ICSE">ICSE</option>
        <option value="NEET">NEET</option>
        <option value="pvt">University</option>
        <option value="Other">Other</option>
      </select>

      <select formControlName="course" class="col-10 col-md-5 m-1 browser-default custom-select">
        <option value="" disabled>Class/Course</option>
        <option value="X" selected>X</option>
        <option value="XI">XI</option>
        <option value="XII">XII</option>
        <option value="civil">Civil Eng</option>
        <option value="ee">Electrical Eng</option>
        <option value="IX">IX</option>
        <option value="Other">Other</option>
      </select>
      </div>
      -->


      <div *ngIf = 'userGroup.length>1' class = "col-10 col-md-5 m-1">
      <select formControlName="groupId" class="browser-default custom-select col-10 col-md-5 m-1" >
        <option value="" disabled>Learn group:</option>
        <option *ngFor="let t of userGroup" [value]="t.code">{{t.desc}}</option>
       
        <!--
        <option value="learner" selected>student</option>
        <option value="pteacher" selected>Parent</option>
        <option value="tteacher" selected>Tutor</option>
        <option value="teacher">Teacher</option>
        <option value="dbteacher">Digibest Teacher</option>
        
        <span *ngIf= 'groupId == "crclm_mngr"' >
        <option value="cd_team">Content Digization team</option>
        <option value="eb_digitizer">Edubestu Digitizer</option>
        <option value="crclm_mngr">EB cntnt owner</option>
        <option value="admin"> Admin</option>
        </span>

        <option value="director">Director</option>
        <option value="Other">Other</option>
        -->

      </select>

      
      
      <ng-container *ngIf = 'learnList.length>1'>
         <select formControlName="school" class="browser-default custom-select col-11 col-md-6 m-1" >
         <option value="" disabled>if Student,Learning with:</option>
        <option *ngFor="let t of learnList" [value]="t.name">{{t.desc}}</option>
        </select>
     </ng-container>
    </div>
    
<!--
    
-->
     
</div>     
       
    <button *ngIf= "!unsigned" type="button" class="btn badge btn-secondary my-1  " (click)="expand=!expand"> + optional  
      Fields </button>
      
      <!-- Field expander start -->
     `
      
        <div  class= "row" *ngIf="expand">
          

          
      <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="mainCenter" placeholder="School/Institute">
      <div *ngIf="submitted && signupForm.controls.mainCenter.errors" class="error ">
        <div *ngIf="signupForm.controls.mainCenter.errors.required">"User's main School/Institute" </div>
      </div>

      <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="userLoc" placeholder="location ">
      <div *ngIf="submitted && signupForm.controls.userLoc.errors" class="error ">
        <div *ngIf="signupForm.controls.userLoc.errors.required">"User's location" </div>
      </div>
      
      <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="extra" placeholder="optional request">
    <div *ngIf="submitted && signupForm.controls.roles.errors" class="error">
      <div *ngIf="signupForm.controls.roles.errors.required">extra message,optional" </div>
    </div>
         
          <ng-container *ngIf = 'site !== "esa"' >
        
         <input class="form-control col-9 col-md-4 m-1" type="text" formControlName="overSite" placeholder="siteCode,only if provided">
         
         <div class= "col-1 mt-2"  *ngIf= "true">
          <button type= "button" (click) ='validateSiteCode(ovrSite)'> verify  </button>
          <span class="text-success" *ngIf= 'validSiteCode=="true"'> &#10003;  </span> 
          <span class="text-danger" *ngIf= 'validSiteCode=="false"'> &#10006;  </span> 
          
          </div>
         
           
       
      <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="clsSec" placeholder="section">
      <div *ngIf="submitted && signupForm.controls.clsSec.errors" class="error  ">
        <div *ngIf="signupForm.controls.clsSec.errors.required">batch/section?" </div>
      </div>

      <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="shrGrp" placeholder="ProjectGroup">
      <div *ngIf="submitted && signupForm.controls.shrGrp.errors" class="error ">
        <div *ngIf="signupForm.controls.shrGrp.errors.required">"project/year group" </div>
      </div>
    </ng-container>

      <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="mentorId" placeholder="Parent/MentorId ,if any">
      <div *ngIf="submitted && signupForm.controls.mentorId.errors" class="error ">
        <div *ngIf="signupForm.controls.mentorId.errors.required">"Parent/MentorId" </div>
      </div>

      <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="roles" placeholder="Additional Roles">
      <div *ngIf="submitted && signupForm.controls.roles.errors" class="error ">
        <div *ngIf="signupForm.controls.roles.errors.required">Additional" </div>
      </div>

     
      

     

<!--
  <label class= "text-primary" for="firstName"> First Name:</label>
      <input  type="text" formControlName="firstName">
      <div  *ngIf="submitted && signupForm.controls.firstName.errors" class="error">
        <div *ngIf="signupForm.controls.firstName.errors.required">Your First name is required</div>
      </div>


    <label class= "text-primary" for="lastName"> Last Name:</label>
      <input type="text" formControlName="lastName">
      <div *ngIf="submitted && signupForm.controls.lastName.errors" class="error">
        <div *ngIf="signupForm.controls.lastName.errors.required">Your Last name is required</div>
      </div>




  <label class= "text-primary" for="userId"> Unique user Id:</label>
      <input type="text" formControlName="userId">
      <div *ngIf="submitted && signupForm.controls.userId.errors" class="error">
        <div *ngIf="signupForm.controls.userId.errors.required">A userId is required</div>
      </div>



<label class= "text-primary" for="password"> Password:</label>
      <input type="password" formControlName="password">

      <div *ngIf="submitted && signupForm.controls.password.errors" class="error">
        <div *ngIf="signupForm.controls.password.errors.required">A password is required</div>
      </div>


    <label class= "text-primary" for="emailId"> EMail Id:</label>
      <textarea formControlName="emailId"></textarea>

      <div *ngIf="submitted && signupForm.controls.emailId.errors" class="error">
        <div *ngIf="signupForm.controls.emailId.errors.required">An Email Id is optional</div>
      </div>


<label class= "text-primary" for="phone"> Phone Number:</label>

      <textarea formControlName="phone"></textarea>

      <div *ngIf="submitted && signupForm.controls.phone.errors" class="error">
        <div *ngIf="signupForm.controls.phone.errors.required">A Phone number  is optional</div>
      </div>
      <br>

<div class="g-recaptcha" data-sitekey= "6LfZkN8UAAAAAMkiREqITHPj6E_cj9Xn1cX_tcrv"></div>
      <br/>
-->


</div>

<p-captcha *ngIf= '!root.includes("localhost")' class= "g-recaptcha m-1" siteKey="6LfZkN8UAAAAAMkiREqITHPj6E_cj9Xn1cX_tcrv" (onResponse)="showResponse($event)"></p-captcha>


<input class="btn btn-primary btn-lg" type="submit" value="Submit" >




 <div class ="my-2">

    <i class= "text-golden small">Note: Phone/email is needed for Legal obligations only. Privacy assured.</i>
    </div>


<!--
    <input type="submit" class="btn color-white mwc-orange-background-color" name="redeem" value="SignUp">
    -->

  </form>

</div>



</div>


  <div *ngIf="success" class="results">

      <p class="alert alert-info">

        <ngb-alert [dismissible]="false">
           Thanks for registering {{signupForm.controls.userId.value}}...
        </ngb-alert>
      </p>


      <!--
    <h3 class = "bg-warning text-center text-info" > THANKS FOR signing in, you are all set .. {{signupForm.controls.firstName.value}}... </h3>

    <strong>Message:</strong>
    <span>{{signupForm.controls.message.value}}</span>

  <strong>Phone Number:</strong>
    <span>{{signupForm.controls.phone.value }}</span>
  -->
  </div>

</div>
