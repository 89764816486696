import { Component, OnInit } from '@angular/core';
import { QuizService } from '../../shared/quiz.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
qstnCount: number;
ansrCount: number;

  constructor(public quizService: QuizService, private router: Router) { }

  ngOnInit() {
    if(this.quizService.qns ){
    this.qstnCount=this.quizService.qns.length;
  }
    //  alert("Result size is: " + this.qstnCount);
    if (parseInt(localStorage.getItem('qnProgress')) == this.qstnCount) {
      this.quizService.seconds = parseInt(localStorage.getItem('seconds'));
      this.quizService.qnProgress = parseInt(localStorage.getItem('qnProgress'));
      this.quizService.qns = JSON.parse(localStorage.getItem('qns'));
      this.quizService.getAnswers().subscribe(
        (data: any) => {

          this.quizService.correctAnswerCount = 0;

          this.quizService.qns.forEach((e, i) => {


                      if(data[i] >= 0){
                        this.ansrCount++;
                      }

            if (e.answer == data[i]){
              this.quizService.correctAnswerCount++;
            e.correct = data[i];
          }
          });
        }
      );
    }
    else
      this.router.navigate(['/quiz']);
  }


  OnSubmit() {

 this.quizService.submitScore().subscribe(() => {
      //this.restart();
  var signedUser = localStorage.getItem('signedUser');
  if(signedUser && signedUser!="Guest"){
  alert( "You are set to get actual performance dashBoard soon " )  
  } else{
  alert( "Registered User domain ...Please signup/login for actual performance chart...");
  }
  this.router.navigate(['/dash']);
    });
  }

  restart() {
    localStorage.setItem('qnProgress', "0");
    localStorage.setItem('qns', "");
    localStorage.setItem('seconds', "0");
    this.router.navigate(['/quiz']);
  }

}
