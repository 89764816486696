
<div class="minHeight">
   
<app-menu   *ngIf= "cat != 'usp' "> </app-menu>
<app-news-room> </app-news-room>
<app-new-sub-mnu   > </app-new-sub-mnu>


<!--
<app-submenu > </app-submenu>

<app-cntxt-menu *ngIf= 'enable("cntxtMenu")'> </app-cntxt-menu>
-->

<!--
<ngb-toast *ngIf="message" header=" "  (hide)="message = ''" delay= "2000">
  {{ message }}
</ngb-toast>
-->

<app-boardlist *ngIf="(boardNeeded)" [boards]= this.boards> </app-boardlist>

<div  *ngIf="(!boardNeeded)">

<div [ngSwitch]="feature">
<app-prvsyrsqstn *ngSwitchCase= "'pyq1'"> </app-prvsyrsqstn>
<app-homeworkhelper *ngSwitchCase="'hwh1'"> </app-homeworkhelper>
<app-vidshower *ngSwitchCase="'video'" [headerId] = 101> </app-vidshower>

<app-pbooster *ngSwitchCase="'pbooster'">...</app-pbooster>
<app-exam-list *ngSwitchCase="'exams'"> </app-exam-list>
<app-exam-list *ngSwitchCase="'site'"> </app-exam-list>
<app-exam-list *ngSwitchCase="'coe'"> </app-exam-list>

<app-site-list *ngSwitchCase="'site-list'"> </app-site-list>
<app-add-site *ngSwitchCase="'add-site'"> </app-add-site>
<app-update-site *ngSwitchCase="'update-site'"> </app-update-site>

<app-excel   *ngSwitchCase="'learner'">...</app-excel>

<app-product   *ngSwitchCase="'pbstr'">...</app-product>

<!--for main usp -->
<app-usp-slides  *ngSwitchCase="'usp'">...</app-usp-slides>


<app-cmnt-list *ngSwitchCase="'comments'"  [mode] = "'list'"[cmntType]= "'all'" > </app-cmnt-list>
<app-cmnt-list *ngSwitchCase="'clrdbt'"   [cmntType]= "'Ddoubt'" > </app-cmnt-list>
<app-excel *ngSwitchCase="'rfcexp'">  </app-excel>
<app-excel *ngSwitchCase="'rvsnspdr'">  </app-excel>

<app-rvsn-spdr [cmntType] = '"Dclr"' *ngSwitchCase="'trvsn-type'"  > </app-rvsn-spdr>

<app-rvsn-spdr [cmntType] = '"Drfc"' *ngSwitchCase="'Drfc-type'"  > </app-rvsn-spdr>
<!--
<app-cubehor *ngSwitchCase="'products'">...</app-cubehor>

-->



<!--
<app-excel   *ngSwitchCase="'prd-cc'">...</app-excel>
<app-product-desc *ngSwitchCase="'prd-sch'">...</app-product-desc>
<app-product-desc *ngSwitchCase="'prd-cc'">...</app-product-desc>
<app-product-desc *ngSwitchCase="'prd-col'">...</app-product-desc>
<app-product-desc *ngSwitchCase="'prd-tc'">...</app-product-desc>
<app-prd-combo *ngSwitchCase="'combo'"></app-prd-combo>       
-->

<app-demo *ngSwitchCase="'demo'" >                  </app-demo>
<app-system *ngSwitchCase="'system'"> </app-system>

<app-math *ngSwitchCase="'math'"> </app-math>
<!--
<app-mathml2latex *ngSwitchCase="'mathml'" > </app-mathml2latex>
-->

<!--
<app-sliders *ngSwitchCase="'institute'">...</app-sliders>
<app-excel   *ngSwitchCase="'institute'">...</app-excel>

<app-sliders *ngSwitchCase="'coaching'">...</app-sliders>
<app-excel   *ngSwitchCase="'coaching'">...</app-excel>

<app-sliders *ngSwitchCase="'tuition'">...</app-sliders>
<app-excel   *ngSwitchCase="'tuition'">...</app-excel>
-->

<app-cmnt-list *ngSwitchCase="'mtg'" [cmntType] = "'Hmtg'" [featureType] = "''"  > </app-cmnt-list>

<app-comment-detail  *ngSwitchCase="'cmnt-dtl'"> </app-comment-detail>
<app-update-comment  *ngSwitchCase="'cmnt-updt'"> </app-update-comment>
<app-add-comment  *ngSwitchCase="'cmnt-add'"> </app-add-comment>

<app-contact *ngSwitchCase="'contact'">...</app-contact>

<!--
  <app-cmnt-list [featureType] = "home" [cmntType]= "'Hhome'" [parentHdrId] = chapter?chapter:0 *ngSwitchCase="'contact'"> </app-cmnt-list>
-->

<app-signin *ngSwitchCase="'signin'">...</app-signin>
<app-signout *ngSwitchCase="'signout'">...</app-signout>
<app-signup *ngSwitchCase="'signup'">...</app-signup>
<app-comments *ngSwitchCase="'forgot'" [myTopic]= "'Customer Service'"> </app-comments>
<app-signuplogin *ngSwitchCase="'login'">...</app-signuplogin>

<app-excel *ngSwitchCase="'manageUsr'" >...</app-excel>
<app-manage-usr *ngSwitchCase="'manageUsr'">...</app-manage-usr>

<app-excel *ngSwitchCase="'about'" >...</app-excel>
<app-aboutus *ngSwitchCase="'about'">...</app-aboutus>


<app-s-pmagic *ngSwitchCase="'s-pmagic'">...</app-s-pmagic>
<app-t-pmagic *ngSwitchCase="'t-pmagic'">...</app-t-pmagic>
<app-data-view *ngSwitchCase="'pmagic-dv'">...</app-data-view>

<!--user list-->
<app-fdbck-rcmnd *ngSwitchCase="'s-fdbck'">...</app-fdbck-rcmnd>
<app-mistake-type-rpt *ngSwitchCase="'s-mistake'">...</app-mistake-type-rpt>


<app-register *ngSwitchCase="'register'">...</app-register>
<app-quiz *ngSwitchCase="'quiz'">...</app-quiz>


<app-excel *ngSwitchCase="'thought'">  </app-excel>
<app-thought *ngSwitchCase="'thought'">...</app-thought>

<app-mind-engager *ngSwitchCase="'newQuiz'" class= "my-5">  </app-mind-engager>
<app-fresh-quiz *ngSwitchCase="'newQuiz'"> </app-fresh-quiz>
<app-edubestu *ngSwitchCase="'newsInfo'"> </app-edubestu>
<app-duk *ngSwitchCase="'didYouKnow'"></app-duk>
<app-thoughtoftheday *ngSwitchCase="'thoughtOFD'">... </app-thoughtoftheday>

<app-story-of-the-day *ngSwitchCase= "'story'" > </app-story-of-the-day>



<app-result *ngSwitchCase="'result'">...</app-result>
<app-dash *ngSwitchCase="'dash'">...</app-dash>

<app-parents *ngSwitchCase="'parents'">...</app-parents>
<app-special *ngSwitchCase="'special'">...</app-special>
<app-askbestu *ngSwitchCase="'askBestu'">...</app-askbestu>
<app-search-qstn   *ngSwitchCase="'search'">...</app-search-qstn>
<app-excel *ngSwitchCase="'rvsnmstr'" >...</app-excel>
<app-board *ngSwitchCase="'rvsnmstr'"> <div>Loading...</div>
</app-board>

<app-tbl-mkr *ngSwitchCase="'tblMkr'" [id] = '240101' ></app-tbl-mkr>

<app-sliders *ngSwitchCase="'about~slides'"> </app-sliders>   
<app-sliders *ngSwitchCase="'home~slides'"> </app-sliders>   

  <div   *ngSwitchDefault> 

 <app-usp-slides  *ngIf = 'cat== "usp" || feature.startsWith("prd"); else excel;'>...</app-usp-slides>
    
   
  </div>
  
  <ng-template #excel>
    <app-excel> ...</app-excel>
  </ng-template>


</div>
<app-display-ad></app-display-ad>
</div>



</div>



<!--nav class="footColor mb-0 py-0 float-center mt-auto navbar navbar-expand-sm  navbar-light sticky-bottom">
   
  <app-footer-link [menuType] = "'floatingFooter'" [site]=site> </app-footer-link>
  <app-footer [site] = site></app-footer>
  </nav-->
  
  
  

  <app-footer [site]=site></app-footer>

<!--div
class="footColor mb-0 py-0 float-center mt-auto">
  
<app-footer-link [menuType]="'floatingFooter'" [site]=site> </app-footer-link>
<app-footer [site]=site></app-footer>

</div-->
