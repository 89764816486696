import { Component, OnInit } from '@angular/core';
import { CntxtService } from '../../../pages/cntxt-menu/cntxt.service';
import { ClientInfoService } from '../../../shared/client-info.service';
import { HttpHlprService } from '../../../shared/http-hlpr.service';

@Component({
  selector: 'app-new-mkr',
  templateUrl: './new-mkr.component.html',
  styleUrls: ['./new-mkr.component.scss']
})
export class NewMkrComponent implements OnInit {

  showQHdr:boolean;
  showTHdr:boolean;
  showQDtl:boolean;
  showTDL:boolean;

  constructor(public cm:CntxtService, public cs:ClientInfoService, public h:HttpHlprService) { }
  
  
  ngOnInit(): void {
  }

}
