	import { Thought } from './thought';
export const THOUGHTS: Thought[] =
		[{ id:1,thought:'The way to get started is to quit talking and begin doing. ' , author:'Walt Disney'  },
		{ id:2,thought: 'First Step is what it takes to begin!! ' , author:'Founder'  },
		{ id: 3, thought :'Alone we can do so little; together we can do so much. ' , author:'Helen Keller'  },
		{ id: 4, thought :'Optimism is the faith that leads to achievement. Nothing can be done without hope and confidence.' , author:' Helen Keller'  },
		{ id: 5, thought :'Never bend your head. Always hold it high. Look the world straight in the eye.' , author:' Helen Keller'  },
		{ id: 6, thought :'Keep your face to the sunshine and you cannot see a shadow. ' , author:'Helen Keller'  },
		{ id: 7, thought :'Walking with a friend in the dark is better than walking alone in the light.' , author:' Helen Keller'  },
		{ id: 8, thought :'So long as the memory of certain beloved friends lives in my heart, I shall say that life is good.' , author:' Helen Keller' },
		{ id: 9, thought :'The world does not care about your self-esteem.Start making something of yourself today. Right now!!' , author:' Bill Gates'  },
		{ id: 10, thought :'If you think your teacher is tough, wait till you meet the boss.' , author:' Bill Gates '  },
		{ id: 11, thought :'Kind words can be short and easy to speak,but their echoes are truly endless' , author:' Unknown '},
		{ id: 12, thought :'When you really want something all the universe conspires to help you achieve it ' , author:' Unknown '},
		{ id: 13, thought :'The good news is that people are nearly always capable of doing something else when they are made aware of the habit and are sufficiently motivated to change.', author:' Unknown '},
       { id: 14, thought :'Values will get you through hard times and good times. They are the things that give meaning and purpose to what you do.' , author:'Barack Obama'  },
		{ id: 15, thought :'Stop being afraid of what could go wrong and start being positive about what could go right' , author:' Unknown ' },
       { id: 16, thought :'You don\'t get paid for having ideas, you get paid for making them happen' , author:' Unknown ' },
       { id: 17, thought :'Do you want to know who you are? Don\'t ask. Act! Action will delineate and define you.',author:' Thomas Jefferson'  },
       { id: 18, thought :'Honesty is the first chapter in the book of wisdom.', author:' Thomas Jefferson'  },
       { id: 19, thought :'When you look for the good in others\, you discover the best in yourself.', author:'Martin Walsh' },
       { id: 20, thought :'No matter what people tell you\, words and ideas can change the world.', author:' Robin Williams'},
       { id: 21, thought :'Our greatest glory is not in never falling, but in rising every time we fall.' , author:' Confucius'},
       { id: 22, thought :'To win big,you sometimes have to take big risks',author:'Bill Gates'},
       { id: 23, thought :'I don’t know the key to success, but the key to failure is trying to please everybody.', author:' Bill Cosby'},
       { id: 24, thought :'When you get pain for someone or something, let go, if it belongs to you, it will come back! ',author:'Dr. Shefali'},
       { id:25, thought:'When you really wants something,nature helps',author:'Dr. Shefali' },
       { id:26, thought:"History has proven that those who dare to imagine the impossible are the one's who breaks all human 'LIMITATIONS'",author:'Dr. Shefali' },
       { id:27, thought:"Cherish the past,live the present,nurture the future",author:'Dr. Shefali' },
       { id:28, thought:"Life can only be understood backwards; but it must be lived forwards.",author:'Soren Kierkegaard' },
			 { id:29, thought:"Incompetence annoys me,Overconfidence terrifies me.",author:'Malcolm Gladwell' },
			 { id:30, thought:"When you judge others you define yourself.",author:'Unknown' },

       ];
