import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { HttpHlprService } from '../../shared/http-hlpr.service';
import { BoardDataService } from '../../board-data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer-link',
  templateUrl: './footer-link.component.html',
  styleUrls: ['./footer-link.component.scss']
})


export class FooterLinkComponent implements OnInit {
@Input() menuType : string;
@Input() site : string;

  twitterA: string= 'edubestu';
  
  data$: Observable<any>;
  loading: boolean;
  
  get groupId() { return this.cs.getGroupId()};
  get cat() { return this.cs.getCat()};
  //get site() { return this.cs.getSite()};
  get showContact(){return this.cs.enable('footer','contact-site')}
  
  constructor(public cs:ClientInfoService,public h: HttpHlprService,private ds: BoardDataService) { 
    
    if(this.site && this.site.startsWith('es')){
      this.twitterA= 'ExamSteppers'
    }
    if(this.site && this.site.startsWith('eb')){
      this.twitterA= 'Edubestu'
    }

  }
  ngOnInit(): void {

 if(this.menuType){
  this.data$ = this.ds.getCfgData(this.site, this.h.bo, this.h.co, "cfg", this.menuType);
  }
}
// data of menu was not refreshing after site change hence the below
ngOnChanges(): void {

  if(this.menuType && this.site){
   this.data$ = this.ds.getCfgData(this.site, this.h.bo, this.h.co, "cfg", this.menuType);
   }
 }


}
