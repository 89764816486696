

<!--
    https://getbootstrap.com/docs/4.1/components/forms/
    https://www.pluralsight.com/guides/how-to-submit-form-data-using-angular
-->
<form #fldForm = "ngForm" class="form-inline" (ngSubmit) = 'submit(fldForm.value)'>
    <div  class="form-group mb-2">
     <label  for="value" class="sr-only">{{fieldName}}</label>
     <input  class="form-control-plaintext" type="text" name= "fieldValue" class="form-control" id="value" placeholder= '{{q[fieldName]}}' ngModel>
    </div>
    
    <button type="submit" class="btn btn-primary badge mb-2">change {{fieldName}} </button>
  
</form>
  
