


import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { ClientInfoService } from "./client-info.service";


// Set the http options
const httpOptions = {
  headers: new HttpHeaders({"Content-Type": "application/json", "Authorization": "c31z" })
};


const httpOptionPlainHtml = {
  headers: new HttpHeaders({
    'Content-Type': 'text/html',
    'response-Type': 'text',
    'Authorization': 'my-auth-token',
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
  })
  ,
  'response-Type': 'text',
};


@Injectable({
  providedIn: 'root'
})
export class PropFromUrlService {
  
get root(){  return this.cs.getRoot();  } 

/**
 * Service to call all the API
 */
  constructor(private http: HttpClient, private cs:ClientInfoService) {}

  /**
   * Function to handle error when the server return an error
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  /**
   * Function to extract the data when the server return some
   *
   * @param res
   */
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  /**
   * Function to GET what you want
   *
   * @param url
   */
  public getListOfGroup(url: string): Observable<any> {

    // Call the http GET
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
}


public getPropList(propFile: string): Observable<any> {
  var url= propFile;
     
  let justSite = this.cs.getJustSite();
  var  url = this.root+ `/etl/esadigi-best/management/ang/${justSite}/${propFile}`;
  
    return this.http.get(url).pipe(
    map(this.extractData),
    catchError(this.handleError)
  );
}

public getTextFile(fileLink: string): Observable<any> {
 
  let justSite = this.cs.getJustSite();
  var  url = this.root+ `${fileLink}`;
   
  const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8').set('Cache-Control' , 'no-cache' );
  return this.http.get(url,{ headers, responseType: 'text'});

}



}