    <div class="container">
       <div class="first_page">
            
        <div class="text-center mb-2" [innerHTML]='q.title|safe:"html"'> </div>
        <span *ngIf='q.instruction' [innerHTML]='q.instruction|safe:"html"'>
            </span>
            <span *ngIf='q.question' [innerHTML]='q.question|safe:"html"'>
            </span>
            <div class="image" *ngIf='q.answerImages;else qstnImg '>
                <img class="im" [src]='q.answerImages|safe:"resourceUrl"' alt="">
            </div>
            <ng-template  #qstnImg>
                <ng-container *ngIf='q.questionImages'>    
           <img class="im" [src]= 'q.questionImages|safe:"resourceUrl"'
                alt="" >
            </ng-container>    
            </ng-template>
            <span *ngIf='q.answer' class="mt-2"   [innerHTML]='q.answer|safe:"html"'>
            </span>
       </div>
       


        
 
