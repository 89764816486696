import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,Subject} from 'rxjs';
import { HttpHeaders,HttpResponse } from '@angular/common/http';
import { ClientInfoService } from '../shared/client-info.service';
const center= "Sure Shot Acedemy";

const httpOptions1 = {
  headers1: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token2',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'HEAD,GET,POST,PUT,PATCH,DELETE',
    responseType: 'text',
    center: center
  }),
  params:{'center1': 'Chrysalis'}
   };

   const httpOptions = {
    headers: { 'Content-Type': 'application/json',
                'authorization': 'my-auth-token1', 
                'center': center
             },
    //params: {'center1': 'somethingCool'}
  };

  @Injectable({
    providedIn: 'root'
  })

  export class CommentService {
    baseAIUrl: string;
    getCmntsByFeature(clsSec: any, featureType: any, subject: any, cmntType: any, owner: any) {
      return this.http.get(`${this.baseUrl}/findByFeature/${this.cs.getSite()}/${this.cs.getCourse()}/${clsSec}/${featureType}/${subject}/${cmntType}/${owner}`);
   }

   getSubjectsByFeature(clsSec: any, featureType: any, subject: any, cmntType: any, owner: any) {
    return this.http.get(`${this.baseUrl}/subjectsByFeature/${this.cs.getSite()}/${this.cs.getCourse()}/${clsSec}/${featureType}/${subject}/${cmntType}/${owner}`);
 }

       //Subject which will be pushed from child to advise parent to update List.
public static updateList: Subject<boolean> = new Subject();

    getCommentsByTypeFtrHdrId(cmntType: string, feature: string, parentHdrId: number) {
      //alert(this.cs.getSite());
     
      return this.http.get(`${this.baseUrl}/typeFtrHdrid/${this.cs.getSite()}/${cmntType}/${feature}/${parentHdrId}`);
      //throw new Error("Method not implemented.");
    }

    getCommentsByTypeFtrDtlId(cmntType: string, feature: string, parentHdrId: number,parentDtlId:number) {
     // alert ("dtl calling");
      return this.http.get(`${this.baseUrl}/typeFtrDtlid/${this.cs.getSite()}/${cmntType}/${feature}/${parentHdrId}/${parentDtlId}`);
      //throw new Error("Method not implemented.");
    }


    
     

  private baseUrl ;
  //= 'http://192.168.1.2:8080/rs/cmnt';

  
  constructor(private http: HttpClient,public cs:ClientInfoService) {
    this.baseUrl= this.cs.getRoot()+ "/rs/cmnt";
    this.baseAIUrl= this.cs.getRoot()+ "/rs/cmnt";
   }


  getComment(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/detail/${id}`);
  }

  testHdr(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/testhdr/${id}`,httpOptions);
  }

  upsert(comment: any,updFlag:boolean){
  return updFlag? this.updateComment(comment.id,comment): this.createComment(comment);
  }

  storeAnsrs(hdrId:number,user:string,qstns: Object): Observable<Object> {
    //console.log(comment);
    return this.http.post(`${this.baseUrl}/storeAnsrs/${hdrId}/${user}`, qstns);
  }

  
  evaluate(hdrId:number,user:string,qstns: Object): Observable<Object> {
    //console.log(comment);
    return this.http.post(`${this.baseUrl}/evaluate/${hdrId}/${user}`, qstns);
  }



  createComment(comment: Object): Observable<Object> {
    //console.log(comment);
    return this.http.post(`${this.baseUrl}`, comment);
  }

  //add new pbstr qstn automatically for incorect qstns..
 addSimilar(comment: Object): Observable<Object> {
    //console.log(comment);
    return this.http.post(`${this.baseUrl}/addSimilar`, comment);
  }


  updateComment(id: number, value: any): Observable<Object> {
    return this.http.put(`${this.baseUrl}/${id}`, value,httpOptions);
  }


  
//value not in use
  increaseCount(reactType: string, value: any): Observable<Object> {
    console.log("calling " + reactType);
    return this.http.put(`${this.baseUrl}/increaseCount/${reactType}`, value,httpOptions);
  }

  deleteComment(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`,httpOptions );
  }

  getCommentsList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/prntdtlset/1`);
  }


//rejuvenate  pass all in site and cmnttype if u want 
  getCommentsByType(domain: string,cmntType: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/type/${domain}/${cmntType}`);
  }
  getCommentsByTypeHdr(domain: string,cmntType: string,hdrId:number): Observable<any> {
    return this.http.get(`${this.baseUrl}/type/${domain}/${cmntType}/${hdrId}`);
  }


  enable(btnId:string,comment?:any){
    
    if ( btnId=="correction"){
   //  if(this.cs.getFeature()=='rvsnspdr'){ return false;} 
     return  this.cs.getStatus()=='crct'?true:false;    
     

    }
      
    if(btnId=="attach") {
     
      if(this.cs.getSignedUser()!='Guest'){
     if(comment.cmntType =='Dclr'|| comment.cmntType =='Dans'){
      return true;
     } 
    }
    else{
      return false;
    }
     }
     
     if(btnId=="ckeditor") {
       return (comment && comment.cmntType &&  (comment.cmntType.includes('ans') || comment.cmntType.includes('clr'))) }
     
       
   //trueFlag name for true Button
   if ( btnId=="trueFlag"){
   if(comment.cmntType =='Dans'){
          return true;
     }  else{
       return false;
     }
    }

    console.log(this.cs.getGroupId()  + comment.cmntType);
  
    if ( btnId=="addComment"  && this.cs.getGroupId() == 'learner'){
      if(comment.cmntType =='Hmtg'  ||
      comment.cmntType =='Dnote'
      ){
             return false;
        }  else{
          return true;
        }
       }
     
     }
   


}