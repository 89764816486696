import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reply',
  templateUrl: './reply.component.html',
  styleUrls: ['./reply.component.scss']
})
export class ReplyComponent implements OnInit {
  @Input()  responseMode:boolean;
  @Input()  id: number;
    
  constructor() { }

  ngOnInit(): void {
  }

}
