import { Header } from "../header";

export class BstrMkr {

template:Header;
srcHdrIds:string;
refBstrId:number;
// by default createNew is true if you don't send id in backend
createNew:boolean;
ovrUser:string;
ovrType:string;

// mention if want to multiple set
setCount: number ;

//mcq/subjective/hybrid
//qstnSetType:string= "subjective";
qstnSetType:string= "medical";
addSectionRec:boolean= true;

}
