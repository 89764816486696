
<!--
-->

<app-vision></app-vision>
<app-cubehor  >  </app-cubehor>
<br>
<br>
<br>
<br>
<br>
<app-our-team class= "mt-5"> </app-our-team>	



<div *ngIf="false">
  <app-sliders> </app-sliders>
  
Our core drivers:

<ngb-carousel>
<div *ngFor="let image of images">
  <ng-template ngbSlide >  
    <!--
    <img [src] = http://examsteppers.com/examimages/FrontPage/new/{{image}}" alt="Random slide"> 
    -->
    <img src= 'examimages/FrontPage/jsf/{{image}}'  alt= "img">
   </ng-template>
</div>
  </ngb-carousel>

<!--
  
<app-offeringlist [expanded] = true [category] = "'cs'" [hideTitle] = true >
</app-offeringlist >

<app-offeringlist [expanded] = true [category] = "'pb'" [hideTitle] = true >
</app-offeringlist >
 --> 
   
</div>

<!--

<img  src="examimages/FrontPage/jsf/{{teacher}}" >
-->
