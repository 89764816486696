import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { BtnEnablerService } from '../../shared/btn-enabler.service';

@Component({
  selector: 'app-btn-enabler-tester',
  templateUrl: './btn-enabler-tester.component.html',
  styleUrls: ['./btn-enabler-tester.component.scss']
})
export class BtnEnablerTesterComponent implements OnInit {
  @Input() hdr:any; 
  test: boolean;


  constructor(public be:BtnEnablerService, public cs:ClientInfoService) { }

  ngOnInit(): void {
    if(this.hdr){
    console.log(this.hdr);
   // this.test = this.be.enable("site","subMenu",this.hdr,true);
   // this.test = this.cs.enable("site","subMenu","","");
    
    }
  }

}
