import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';
import { ListerService } from '../../chat/lister.service';
import { Detail } from '../../qstndtl/detail';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { Edi } from '../dtl-extra/edi';
import { FldsService } from '../flds.service';
import { Format } from '../format';


@Component({
  selector: 'app-doc-mnt',
  templateUrl: './doc-mnt.component.html',
  styleUrls: ['./doc-mnt.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
 
})


export class DocMntComponent implements OnInit {
  @Input() detail :Detail;
  @Input() h :any;
  
  @Input() edi :Edi;
  
  @Input() offering:string;
  @Input() i:number;
  @Input() parentFormGroup:FormGroup; 
  
  primary : string[];
  secondary : string[];

  expand:boolean;
  additional:string[];
  additionalFlag: boolean;
  
  selectedFormat:Format;
  formatList:  Format[]= new Array();
  

  
  constructor(private fd:FixedDataService,private cs:ClientInfoService,private fldsrv:FldsService , public ls:ListerService) { }

  ngOnInit(): void {
  
  let justType = this.cs.getJustOffering(this.offering);
 // this.formatList = this.fldsrv.getFormatList(justType);  
 
  this.ls.getKeyFlds('type-subtype',justType).subscribe((data:any) => {
    this.formatList  = data.result?.value;
    console.log(data);
});

  
  if(true){
  let format= justType;
  if(this.h?.hdrFormat){
    format= this.h.hdrFormat;
  }
   //check later ad format needs to be cleaned like skipBoard etc...
   //format= this.detail.format?this.detail.format:format;


    this.selectedFormat = this.formatList.find(e => e.code === format);
  
  this.ls.getKeyFlds('dtl-flds',format).subscribe((data:any) => {
    //console.log(data);
    let flds  = data.result;
    let StrArray = flds.primary.split(",");
    this.primary = StrArray;
    //console.log(this.primary);
    this.secondary= flds.secondary.split(",");
    this.additional = ['-'].concat(this.primary).concat(this.secondary);
  });
    } else{
  let d  = this.fd.selectQDFlds(justType);
  this.primary= d.p;
  this.secondary= d.s;
  this.additional = ['-'].concat(d.p).concat(d.s);
  }
  }

  fetchData($event:any){
    //alert($event);
    this.changeFormat($event)

  }


  changeFormat(format:Format): void {
    this.selectedFormat = format;
    if(true){
      this.ls.getKeyFlds('dtl-flds',format.code).subscribe((data:any) => {
        //console.log(data);
        let flds  = data.result;
        let StrArray = flds.primary.split(",");
        this.primary = StrArray;
        //console.log(this.primary);
        this.secondary= flds.secondary.split(",");
        this.additional = ['-'].concat(this.primary).concat(this.secondary);
      });
    } else{
    let d  = this.fd.selectQDFlds(format.code);
    this.primary= d.p;
    this.secondary= d.s;
    this.additional = ['-'].concat(d.p).concat(d.s);
    }
  }
  
  enable(btnId:string){
    return this.cs.enable('qd-mnt',btnId);
  }


}
