import { Component, Input, OnInit } from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-show-result',
  templateUrl: './show-result.component.html',
  styleUrls: ['./show-result.component.scss']
})
export class ShowResultComponent implements OnInit {
  @Input() h :any;
  @Input() list :any;
  extra:NavigationExtras;
 

  public isCollapsed = false;
  cardCollapsed:any='uno';

  constructor(private router: Router,public cs:ClientInfoService) { }
  
  

  ngOnInit(): void {
  }

  //crct/rslt value
 showRslt(){

  let showMode:string = "rslt";

  if(this.cs.isUnsigned()){
    showMode= "rslt"
  }

  this.extra= {
    state: {'h' :this.h,'list': this.list}
  };

  console.log(this.extra);

  //this.router.navigate(['testAnsr'],this.extra);
  
  let type= this.cs.offering.replace('wrk',showMode);
  this.router.navigate(['/', this.cs.site, 'rslt',this.cs.board, this.cs.course, type, this.cs.subject,this.cs.chapter],this.extra);
      
 }

  }



function extra(arg0: string[], extra: any) {
  throw new Error('Function not implemented.');
}

