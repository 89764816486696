import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TaghdrListService } from '../../tag/taghdr-list/taghdr-list.service';

@Component({
  selector: 'app-doc-view',
  templateUrl: './doc-view.component.html',
  styleUrls: ['./doc-view.component.scss']
})
export class DocViewComponent implements OnInit {
  @Input() list1: any;

  showDoc:boolean;
  
  constructor(public tl:TaghdrListService,private router: Router) { }

  ngOnInit(): void {
  }


  
}
