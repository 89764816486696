import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { CommentService } from "../../comments/comment.service";
declare var MathJax:any;

@Component({
  selector: 'app-rvsn-spdr',
  templateUrl: './rvsn-spdr.component.html',
  styleUrls: ['./rvsn-spdr.component.scss']
})
export class RvsnSpdrComponent implements OnInit {
@Input() cmntType:string;
  
  get domain() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  //get subject() { return this.cs.getSubject()};
  get signedUser() { return this.cs.getSignedUser()};
  get learnMode() { return this.cs.getLearnMode() }

  // get clsSec() {  return this.cs.getClsSec()!='cmn'?this.cs.getClsSec():' '}
 get clsSec() {return this.cs.getClsSec()?this.cs.getClsSec():"clsSec" }

 get featureType() {  return this.cs.getFeature()}
 
 //featureType:string= 'rvsn-spdr';
//  featureType:string= 'rfc';
  owner:string;
  
  //clsSecS:string = this.clsSec?this.clsSec:"cmn";
  doubts:any;
  //cmntType:string= "Dclr";
  subject:string= "Physics";

  provideResponse:boolean;
  id:number;
  respondI:number;
  updateI:number;
  uploadI:number;
  showQstnI:boolean;
  
  showUpdate:boolean;
subjects:any;

  constructor(private cm: CommentService,public cs: ClientInfoService) {

    CommentService.updateList.subscribe(res=>{
      // alert("inside comnt srvce-updte lst with updateList subscrptn");
      this.respondI = -1;
      this.updateI = -1;
      this.fetchData();
      this.fetchSubjectData(this.subject);
      })
      }

  ngOnInit(): void {
this.fetchData()
  }
  
  ngAfterViewChecked(){
    //alert("Hello World");
    //MathJax.Hub.Queue(["Typeset", MathJax.Hub, "qstnSet"]);
    //alert("View typeset Retried- after view checked..! Thanks ");
 
  }

  
  typeSet(){
   // MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
  }

  fetchData(){
   // teacher should see entire class data hence no owner check 
if(this.signedUser != "Guest"){    

if(this.cs.getUserGroup() && !this.cs.getUserGroup().includes('teacher')){
  this.owner= this.signedUser;
} 
else{
this.owner= "teacher";
}

//alert(this.owner +"//"+ this.cs.getUserGroup() + "//"+ this.signedUser);
}

    this.cm.getSubjectsByFeature(this.clsSec,this.featureType,this.subject,this.cmntType,this.owner).subscribe(data => {
      this.subjects  = (data as any).dataList;
  }); 
 
  }

  fetchSubjectData(subject:string){
    this.subject= subject ; //used for refresh
    this.cm.getCmntsByFeature(this.clsSec,this.featureType,subject,this.cmntType,this.owner).subscribe(data => {
      this.doubts  = (data as any).dataList;
  }); 
  
  }

  enable(btnId:string,i?:number,rec?:any){
 return true;
  }

  respond(id: number,i:number){
    this.id = id;
    if(this.respondI==i){
      this.respondI = null;
     // this.provideResponse = false;  
    } else{
    this.respondI = i;  
   this.provideResponse = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }
  }

  
  delete(id: number) {
    this.id = id;
    this.cm.deleteComment(id)
      .subscribe(
        data => {
          console.log(data);
          this.fetchData();
        },
        error => console.log(error));
  }


update(id: number,i:number){
    this.id = id;
    this.updateI==i?this.updateI= -1 :this.updateI= i;
    //this.showUpdate = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }


  upload(id: number,i:number,d:any){
    this.id = id;
    this.uploadI==i?this.uploadI= -1 :this.uploadI= i;
    
    // create new if query has image already
    if(d.source){
      let d1:any= d; 
      d = new Comment();
      d.cmntType= d.cmntType;
      d.featureType= d1.featureType;
      d.parentHdrId= d1.parentHdrId;
      d.parentDtlId= d1.parentDtlId;
      d.domain= d1.domain;
      }
  
    }

  
}
