import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dtl-view-slctr',
  templateUrl: './dtl-view-slctr.component.html',
  styleUrls: ['./dtl-view-slctr.component.scss']
})
export class DtlViewSlctrComponent implements OnInit {
  @Input() detailId : number 
  @Input()  q: any;
  @Input() i:number;
  @Input()  hdr: any;
  
  
  constructor() { }

  ngOnInit(): void {
  }

}
