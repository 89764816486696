import { Component, OnInit } from '@angular/core';
import { Header } from '../../qstn/header';
import { QstnhdrService } from '../../qstn/qstnhdr.service';

import { ClientInfoService } from '../client-info.service';
import { FileService } from '../file.service';

@Component({
  selector: 'app-create-file',
  templateUrl: './create-file.component.html',
  styleUrls: ['./create-file.component.scss']
})
export class CreateFileComponent implements OnInit {
  fileName:string;
  submitted:boolean;
  header: Header;

  constructor(private fs: FileService, public cs: ClientInfoService,private qhs: QstnhdrService) { }

  ngOnInit(): void {
  }

  
  onSubmit(fileName:string,dryRun:boolean){
    this.header = new Header();
    this.header= this.qhs.populateDefault(this.header);
    
// create html file by default  
if(!fileName.includes(".")){
  fileName = fileName + ".html";
}

    this.fs.createFile(fileName,this.header,dryRun).subscribe(data => {

          let created= (data as any); 
          console.log(data);
          console.log(this.header)
          FileService.updateCreateUrl.next(created.url);
          this.cs.addMsg("creation Successful.,please submit to attach",'');
          //this.cs.addMsg("upload Successful",'');
        }
          , error => console.log(error));
       }


     

  }


