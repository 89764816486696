  import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { BtnEnablerService } from '../../shared/btn-enabler.service';
import { ActivatedRoute } from '@angular/router';
import { BoardDataService } from '../../board-data.service';
import { HttpHlprService } from '../../shared/http-hlpr.service';
import { Observable } from 'rxjs';
//import { Comment } from "../../comments/comment";

@Component({
  selector: 'app-news-room',
  templateUrl: './news-room.component.html',
  styleUrls: ['./news-room.component.scss']
})
export class NewsRoomComponent implements OnInit {

chapter:any;
show:boolean= false;
data$: Observable<any>;

constructor(public cs:ClientInfoService, public h:HttpHlprService,
  public be:BtnEnablerService,private ds: BoardDataService,private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
   // this.show = this.be.enable("infoCenter","show",null,false);
   this.activatedRoute.params.subscribe(({ cat }) => {
    //let key =   "cntxt-mnu~" + this.h.cat;
    let key =   "Hinfo";
    this.data$ =  this.ds.getSiteHeaders(this.h.si,this.h.bo,this.h.co,"cmnt",key);
        });
  
    }
/*
not needed...
    getCrtCmnt(){
     let newCmnt = new Comment();
     newCmnt.cmntType = "Hinfo";
     newCmnt.title = "New info banner:" 
     return newCmnt;
    }
*/

}
