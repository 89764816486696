<!--
<app-drft-maker> </app-drft-maker>
-->


<div [hidden]="submitted" class= "fullWidth  px-3">

  <form  (ngSubmit)="addForm.form.valid && onSubmit()"
    #addForm="ngForm">
        
   <app-qh-mnt [header] = header [ei]= ei [offering] = 'header.type' [parentFormGroup] = addForm > </app-qh-mnt>

    <button type="submit" class="btn btn-primary"
      [disabled]="!addForm.valid">Submit</button>
    <br>
    <!--
     <i> Note: please fillup & press upload if you have file else press submit. one is enough.
    </i>
    -->
  </form>
</div>

<p class= "text-right">QH </p> 

<!--
<div [hidden]="!submitted">
  <h4>You submitted successfully!</h4>
</div>
-->
<!-- <button class="btn btn-success" (click)="newComment()">Add</button> -->