import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PBoosterComponent } from './pages/pbooster/pbooster.component';
import { ContactComponent } from './pages/contact/contact.component';
import { SpecialComponent } from './pages/special/special.component';
import { NotFoundComponent } from './pages/notfound/notfound.component';
import { PrvsyrsqstnComponent } from './pages/prvsyrsqstn/prvsyrsqstn.component';

import {HomeworkhelperComponent } from './pages/homeworkhelper/homeworkhelper.component';
import { ThoughtComponent } from './car/thought/thought.component';
import { AboutusComponent } from './static/aboutus/aboutus.component';
import { EdubestuComponent } from './car/edubestu/edubestu.component';
import { HolidayComponent } from './pages/holiday/holiday.component';
import { BoardComponent } from './pages/board/board.component';
import { Board1Component } from './pages/board1/board1.component';
import { SubjectComponent } from './pages/subject/subject.component';
import { Subject1Component } from './pages/subject1/subject1.component';
import { PhysicsComponent } from './pages/physics/physics.component';
import { SyllabusComponent } from './pages/syllabus/syllabus.component';
import { AdminComponent } from './pages/admin/admin.component';
import { HomeComponent } from './pages/home/home.component';
import { FeatureComponent } from './pages/feature/feature.component';
import { CommentsComponent } from './comments/comments/comments.component';
import { SignupComponent } from './user/signup/signup.component';
import { SiteComponent } from './pages/site/site.component';

import { RegisterComponent } from './pages/register/register.component';
import { QuizComponent } from './pages/quiz/quiz.component';
import { DashComponent } from './perform/dash/dash.component';
import { SigninComponent } from './pages/signin/signin.component';
//import { SignoutComponent } from './pages/signout/signout.component';
import { ResultComponent } from './pages/result/result.component';
import { TestComponent } from './pages/test/test.component';

import { AuthGuard } from './auth/auth.guard';
import { PremiumGuard } from './auth/premium.guard';

import { EbhomeComponent} from './static/ebhome/ebhome.component';

import { CanActivate,
         ActivatedRouteSnapshot,
         RouterStateSnapshot,
         RunGuardsAndResolvers,
         Router,
         UrlTree,
         ExtraOptions
        } from '@angular/router';
import { ChgPwdComponent } from './user/chg-pwd/chg-pwd.component';
import { UsrhdrLstComponent } from './user/usrhdr-lst/usrhdr-lst.component';
import { UsrdtlUpdateComponent } from './user/usrdtl-update/usrdtl-update.component';
import { PrdComboComponent } from './static/prd-combo/prd-combo.component';
import { PrdComboSlidesComponent } from './static/prd-combo-slides/prd-combo-slides.component';
import { PrdComboSlidePdfComponent } from './static/prd-combo-slide-pdf/prd-combo-slide-pdf.component';
import { SiteListComponent } from './pages/site-list/site-list.component';
import { QstnhdrUpdComponent } from './qstn/qstnhdr-upd/qstnhdr-upd.component';
import { UpdQstnDtlComponent } from './qstndtl/upd-qstn-dtl/upd-qstn-dtl.component';
import { ProductComponent } from './static/product/product.component';
//import { FtrViewerComponent } from './static/ftr-viewer/ftr-viewer.component';


import { TestAnsrComponent } from './pages/test-ansr/test-ansr.component';
import { DigibestHomeComponent } from './static/digibest-home/digibest-home.component';
import { UsrRoleComponent } from './user/usr-role/usr-role.component';
import { AddSiteComponent } from './user/add-site/add-site.component';
import { UpdateSiteComponent } from './user/update-site/update-site.component';
import { ExperimentComponent } from './pages/experiment/experiment.component';
import { FeatureShowerComponent } from './pages/feature-shower/feature-shower.component';
import { ParmLoaderComponent } from './gen/parm-loader/parm-loader.component';

const method = (options: { runGuardsAndResolvers: RunGuardsAndResolvers }) => {
  return options;
};
const typedOptions = {
  runGuardsAndResolvers: "always" as RunGuardsAndResolvers
};

const appRoutes: Routes = [
  {path: 'experiment', component: ExperimentComponent},

  {path: 'testAnsr', component: TestAnsrComponent},

  { path: ':site/sites', component: QuizComponent,canActivate : [AuthGuard] },
  
  { path: 'sites', component: SiteListComponent ,canActivate : [AuthGuard] },
  { path: 'sites/:domain', component: SiteListComponent ,canActivate : [AuthGuard] },
  { path: 'sites/add/:site', component: AddSiteComponent ,canActivate : [AuthGuard] },
  { path: 'sites/update/:site', component: UpdateSiteComponent ,canActivate : [AuthGuard] },
  
  { path: 'test', component: TestComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'quiz', component: QuizComponent,canActivate : [AuthGuard] },
  { path: 'result', component: ResultComponent },
  { path: 'dash', component: DashComponent,canActivate : [AuthGuard] },
  { path: 'signin:fromUrl', component: SigninComponent},

  { path: ':site/:cat/features/pbooster/:offering', component: PBoosterComponent },
  { path: ':site/:cat/features/pbooster/:offering/:board/:course', component: PBoosterComponent },
  
  /* don't stop for result above
{ path: ':site/:cat/pbooster', component: PBoosterComponent },
{ path: ':site/:cat/pbooster/:board/:course', component: PBoosterComponent },
{ path: ':site/:cat/signup', component: SignupComponent },
{ path: ':site/:cat/signup/:board/:course', component: SignupComponent },

{ path: ':site/:cat/contact', component: ContactComponent },
{ path: ':site/:cat/contact/:board/:course', component: ContactComponent },

{ path: ':site/:cat/quiz', component: QuizComponent,canActivate : [AuthGuard] },
{ path: ':site/:cat/quiz/:board/:course', component: QuizComponent,canActivate : [AuthGuard] },

{ path: ':site/:cat/thought', component: ThoughtComponent },
{ path: ':site/:cat/thought/:board/:course', component: ThoughtComponent },

*/


/*
{ path: ':site/home', component: HomeComponent },
{ path: ':site/:cat/pyq', component: PrvsyrsqstnComponent },
{ path: ':site/:cat:/hwh', component: HomeworkhelperComponent },
{ path: 'features', component: FeatureComponent },
{ path: 'special', component: SpecialComponent },
{ path: 'totd', component: ThoughtofthedayComponent },
{ path: 'thought', component: ThoughtComponent },

{ path: 'esa-ins/edubestu', component: EdubestuComponent },

{ path: 'aboutus', component: AboutusComponent },
{ path: 'edubestu', component: EdubestuComponent },
{ path: 'holiday', component: HolidayComponent },
{ path: 'syllabus', component: SyllabusComponent },

{ path: 'physics', component: PhysicsComponent },
{ path: 'admin', component: AdminComponent },
{ path: 'feature', component: FeatureComponent },
{ path: 'comments', component: CommentsComponent },
*/

{ path: ':site/crud/:board/:course/:offering/hdr/:id', component: QstnhdrUpdComponent },

{ path: ':site/crud/:board/:course/:offering/dtl/:id', component: UpdQstnDtlComponent },

{path: ':site/bestu/:cat', component: DigibestHomeComponent },
{path: ':site/bestu/:cat/:board/:course', component: DigibestHomeComponent },




{path: ':site/ftr/:cat', component: FeatureShowerComponent },
{path: ':site/ftr/:cat/:board/:course', component: FeatureShowerComponent },
{path: ':site/ftr/:cat/slides', component: FeatureShowerComponent },
{path: ':site/ftr/:cat/slidesPdf', component: FeatureShowerComponent },

  
/*
{path: ':site/ftr/:cat', component: FtrViewerComponent },
{path: ':site/ftr/:cat/:board/:course', component: FtrViewerComponent },
{path: ':site/ftr/:cat/slides', component: FtrViewerComponent },
{path: ':site/ftr/:cat/slidesPdf', component: FtrViewerComponent },
*/

{ path: ':site/ftr/:cat/:board/:course/:offering/:subject/:chapter/:qstnId', component: SiteComponent },


{path: ':site/platform/:cat', component: FeatureShowerComponent },
{path: ':site/platform/:cat/:board/:course', component: FeatureShowerComponent },
{path: ':site/platform/:cat/slides', component: PrdComboSlidesComponent },
{path: ':site/platform/:cat/slidesPdf', component: PrdComboSlidePdfComponent },


{path: ':site/prd/:cat', component: FeatureShowerComponent },
{path: ':site/prd/:cat/:board/:course', component: FeatureShowerComponent },
{path: ':site/prd/:cat/slides', component: FeatureShowerComponent },
{path: ':site/prd/:cat/slidesPdf', component: FeatureShowerComponent },

{ path: ':site/prd/:cat/:board/:course/:offering/:subject/:chapter/:qstnId', component: SiteComponent },


{path: ':site/pd/:cat', component: PrdComboComponent },
{path: ':site/pd/:cat/slides', component: PrdComboSlidesComponent },
{path: ':site/pd/:cat/:board/:course/:feature/slides', component: PrdComboSlidesComponent },
{path: ':site/pd/:cat/slidesPdf', component: PrdComboSlidePdfComponent },
{path: ':site/pd/:cat/:board/:course/:feature/slidesPdf', component: PrdComboSlidePdfComponent },
{ path: ':site/pd/:cat/:board/:course/:offering/:feature/:chapter/:qstnId', component: SiteComponent },

{ path: 'admin', component: AdminComponent },


{ path: ':site/usr', component: UsrhdrLstComponent },
{ path: ':site/usr/chgpwd/:usrNum', component: ChgPwdComponent },
{ path: ':site/usrdtl/upd/:usrNum', component: UsrdtlUpdateComponent },

{ path: ':site/usr/:board/:course', component: UsrhdrLstComponent },

{ path: 'cmnt', redirectTo: '/eba/digibest/features/comments', pathMatch: 'full' },


{ path: ':site/usr-role', component: UsrRoleComponent },
{ path: ':site/usr-role/:board/:course', component: UsrRoleComponent },


// seo related redirect -- temporary
{ path: 'eshome/pbstr/NEET/XI', redirectTo: '/esa/ftr/pbstr/NEET/XI', pathMatch: 'full' },

{ path: 'esa-home/pbstr/NEET/XI', redirectTo: '/esa/ftr/pbstr/NEET/XI', pathMatch: 'full' },
{ path: 'esa/te/features/pbstr', redirectTo: '/esa/ftr/pbstr', pathMatch: 'full' },
{ path: 'esa-es/te/features/pbooster/ICSE/X', redirectTo: '/esa/mkr-pbstr/ICSE/X', pathMatch: 'full' },
{ path: 'pyq', redirectTo: '/esa/ftr/pyq', pathMatch: 'full' },

{ path: 'esa/eshome', redirectTo: '/esa/home', pathMatch: 'full' },
{ path: 'esa/exams', redirectTo: '/esa/learn/features/exams', pathMatch: 'full' },
{ path: 'es/exams/board/course', redirectTo: '/esa/learn/features/exams', pathMatch: 'full' },
{ path: 'esa/ee/X/pbstr', redirectTo: '/esa/ftr/pbstr', pathMatch: 'full' },
{ path: 'esa/eshome/*/car', redirectTo: '/esa/cstore', pathMatch: 'full' },
{ path: 'esa/CBSE/IV/hwh', redirectTo: '/esa/mkr-dps', pathMatch: 'full' },
{ path: 'csa/eshome', redirectTo: '/esa/home', pathMatch: 'prefix' },
{ path: 'eba/eshome', redirectTo: '/esa/home', pathMatch: 'prefix' },

{ path: 'csa/ebhome', redirectTo: '/eba/home', pathMatch: 'prefix' },
{ path: 'esa/ebhome', redirectTo: '/eba/home', pathMatch: 'prefix' },


{ path: 'esacmn-cmn/home/CBSE/X/hwh/Geography', redirectTo: 'esa/learn/CBSE/X/hwh/Social-Science~Geography', pathMatch: 'full' },

{ path: 'home/elm/CBSE/X/pbstr/Mathematics', redirectTo: 'esa/mkr-pbstr/CBSE/X', pathMatch: 'full' },

{ path: 'esa/prnt/features/pbooster/CBSE/X', redirectTo: 'esa/mkr-pbstr/CBSE/X', pathMatch: 'full' },

{ path: 'mot/exm-spl/CBSE/X/qmcq', redirectTo: 'esa/exm-spl/CBSE/X/qmcq', pathMatch: 'full' },

/*
{ path: 'cmnts', component: CommentListComponent },
{ path: 'cmnt/add', component: CreateEmployeeComponent },
{ path: 'cmnt/update/:id', component: UpdateEmployeeComponent },
*/




  { path: 'mark1',
    redirectTo: '/ebcsure-shot',
    pathMatch: 'full',
    data: { index: 'true'},
  },
  { path: 'mark2',
    redirectTo: '/esa/te/features/pbstr',
    pathMatch: 'full',
    data: { index: 'true'},
  },
  { path: 'esa-home/te/features/pbstr/NEET/XI',
    redirectTo: '/esa/prd~pbstr/NEET/XII',
    pathMatch: 'full',
    data: { index: 'true'},
  },  

  { path: 'esa/prd-pbstr/NEET/XII',
    redirectTo: '/esa/prd~pbstr/NEET/XII',
    pathMatch: 'full',
    data: { index: 'true'},
  },


  { path: ':site/features/all',
    redirectTo: '/esa/ee#offerings',
    pathMatch: 'full',
    data: { index: 'true'},
  },

  { path: ':site/features/all',
    redirectTo: '/esa/ee#offerings',
    pathMatch: 'full',
    data: { index: 'true'},
  },

/*
  //default landing page is kinda group features the setup.
  { path: ':site/te',
    redirectTo: '/:site/te/features/pbstr',
    pathMatch: 'full',
    data: { index: 'true'},
  },
  { path: ':site/ti',
    redirectTo: '/:site/te/features/thought',
    pathMatch: 'full',
    data: { index: 'true'},
  },
*/


//  { path: ':site/ebhome', component: EbhomeComponent},

  { path: '', component: ParmLoaderComponent,children: [],
   runGuardsAndResolvers: 'always' as RunGuardsAndResolvers,
 },

  { path: ':site', component: ParmLoaderComponent,children: [],
     runGuardsAndResolvers: 'always' as RunGuardsAndResolvers,
   },

  
   

   { path: ':site/:cat/s/:school/:schlLoc', component: SiteComponent },

   { path: ':site/:cat/features/:feature/s/:school/:schlLoc/:board/:course', component: FeatureComponent },
   //{ path: ':site/:cat/features/:feature/s/:school/:schlLoc/:board/:course/:offering', component: FeatureComponent },
   //{ path: ':site/:cat/:board/:course/s/:school/:schlLoc', component: SiteComponent },

   //list all the category which should defaults to feature page...
   
   //{ path: ':site/te', component: FeatureComponent },
   { path: ':site/:cat', component: SiteComponent },
   { path: ':site/:cat/boards', component: SiteComponent },
   { path: ':site/:cat/features', component: FeatureComponent },
   //{ path: ':site/:cat/features/quiz', component: QuizComponent,canActivate : [AuthGuard] },
   { path: ':site/:cat/features/quiz', component: QuizComponent,canActivate : [AuthGuard] },
   { path: ':site/:cat/features/result', component: QuizComponent,canActivate : [AuthGuard] },
   { path: ':site/:cat/features/dash', component: QuizComponent,canActivate : [AuthGuard] },
   { path: ':site/:cat/features/:feature', component: FeatureComponent },
   { path: ':site/:cat/features/:feature/byId/:id', component: FeatureComponent },
   
   
   { path: ':site/:cat/features/:feature/:board/:course', component: FeatureComponent },

   { path: ':site/:cat/features/:feature/:board/:course/ovrUsr/:userId', component: FeatureComponent },

   { path: ':site/:cat/features/:feature/:board/:course/:offering', component: FeatureComponent },
   
   //test this as I have alternated offering after features
   //{ path: ':site/:cat/features/:feature/:offering/:board/:course/:subject', component: FeatureComponent },
   //{ path: ':site/:cat/features/:feature/:offering/:board/:course/:subject/:chapter', component: FeatureComponent },
   
   { path: ':site/:cat/features/:feature/:board/:course/:offering/:subject', component: FeatureComponent },
   { path: ':site/:cat/features/:feature/:board/:course/:offering/:subject/:chapter', component: FeatureComponent },
   
   { path: ':site/:cat/features/:feature/:board/:course/:offering/:subject/:chapter/:mode', component: FeatureComponent },


   { path: ':site/:cat/:board/:course', component: SiteComponent },
   { path: ':site/:cat/:board/:course/:offering', component: SiteComponent,runGuardsAndResolvers: 'pathParamsOrQueryParamsChange' as RunGuardsAndResolvers,
   canActivate : [PremiumGuard]},
   
//premiumGuard is diabled with envi variable.. enable and streamline...
  { path: ':site/:cat/:board/:course/:offering', component: SiteComponent,runGuardsAndResolvers: 'pathParamsOrQueryParamsChange' as RunGuardsAndResolvers},

  { path: ':site/:cat/:board/:course/:offering/ovrUsr/:userId', component: SiteComponent,runGuardsAndResolvers: 'pathParamsOrQueryParamsChange' as RunGuardsAndResolvers},
  { path: ':site/:cat/:board/:course/:offering/:subject/ovrUsr/:userId', component: SiteComponent,runGuardsAndResolvers: 'pathParamsOrQueryParamsChange' as RunGuardsAndResolvers},
  { path: ':site/:cat/:board/:course/:offering/ovrUsr/:userId/:subject/:chapter', component: SiteComponent,runGuardsAndResolvers: 'pathParamsOrQueryParamsChange' as RunGuardsAndResolvers},

  { path: ':site/:cat/:board/:course/:offering/:subject', component: SiteComponent },
  
  { path: ':site/:cat/:board/:course/:offering/:subject/grpAso/:grpAsoId', component: SiteComponent },
  
  { path: ':site/:cat/:board/:course/:offering/:subject/:chapter', component: SiteComponent },
  
  { path: ':site/:cat/:board/:course/:offering/:subject/:chapter/:qstnId', component: SiteComponent },
 
  { path: ':site/:cat/:board/:course/:offering/:subject/:chapter/view-qstn/:qstnId', component: SiteComponent },
  
  { path: ':site/:cat/:board/:course/:offering/:subject/:chapter/section/:section', component: SiteComponent },
  
  { path: ':site/:cat/:board/:course/:offering/:subject/:chapter/page/:page', component: SiteComponent },
 
  { path: ':site/:cat/:board/:course/:offering/:subject/:chapter/page/:page/view-qstn/:qstnId', component: SiteComponent },
 

  { path: 'board/:course/:subject', component: SubjectComponent },
  { path: 'es/:board/:subject', component: SiteComponent },
  { path: 'site', component: SiteComponent },
  { path: 'es', component: SiteComponent },
  
  { path: 'board/:board', component: BoardComponent },



    { path: '**', component: NotFoundComponent },


];
const extraOptions: ExtraOptions = {

    useHash: false,
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
};  


@NgModule({
  imports: [
  RouterModule.forRoot(appRoutes,extraOptions
  //<-- enableTracing : true later, debugging purposes only...


  ),

  ],

  exports: [RouterModule]
})
export class AppRoutingModule { }
