<div *ngIf="calling; else noCall">


  <div class="cont1ainer" *ngIf="(vids$ | async) as ds; else loading">




    <ng-container *ngIf="ds && ds.dataList && ds.dataList.length; else noItems">

      <app-hdr-view-slctr *ngIf='ds && ds.subDesc' [hdr]=ds.subDesc>
      </app-hdr-view-slctr>

      <!--
     <app-headings-plain *ngIf= 'ds && ds.subDesc' [hdr] = ds.subDesc > </app-headings-plain>
     -->

     <!-- daycounter issue with seperate view -->
      <app-headings-offering *ngIf='!ds || !ds.subDesc'>
      </app-headings-offering>


      

      <div class="pb-1  mx-2" *ngIf='chs.enable("contentStatus")'>
        <div ngbDropdown class="d-inline-block">
          <button type="button" class="btn btn-outline-primary btn-md"
            id="dropdownBasic1" ngbDropdownToggle>
            Filter by progress</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem (click)='chs.showWithStatus("shr")'> in
              progress</button>
            <button ngbDropdownItem (click)='chs.showWithStatus("clr")'> clarify
            </button>
            <button class="dropdown-item"
              (click)='chs.showWithStatus("rvsn")'>for revision </button>
            <button ngbDropdownItem
              (click)='chs.showWithStatus("cmplt")'>completed</button>
              <button ngbDropdownItem
              (click)='chs.showWithStatus("store")'>stored</button>
              <button ngbDropdownItem
              (click)='chs.showWithStatus("avl")'>published</button>
            <button class="mx-2" (click)='refresh()'>refresh</button>
            <button class="mx-2" (click)='chs.showWithStatus("")'> show
              All</button>

          </div>
        </div>
      </div>




      <div class="align-right row  m-2" *ngIf='chs.enable("bstrStatus")'>
        <button class="mx-2" (click)='chs.showWithStatus("asgn")'>
          current</button>
        <button class="mx-2" (click)='chs.showWithStatus("wrk")'> working
        </button>
        <button class="mx-2" (click)='chs.showWithStatus("crctn")'>in correction
        </button>
        <button class="mx-2" (click)='chs.showWithStatus("rslt")'>with
          result</button>
        <button class="mx-2" (click)='chs.showWithStatus("cmplt")'>completed
        </button>
        <button class="mx-2"
          (click)='chs.showWithStatus("arc")'>archived</button>
        <button class="mx-2" (click)='chs.showWithStatus("")'> show All</button>
      </div>




      <div class="mt-1">Please select:

        <div *ngIf='chs.enable("frequency")'
          class="font-italic font-info float-right mr-2">
          New Set every : {{ds.refresh}} day(s)
        </div>


      </div>


      <div class="px-1 mt-1">

        <ng-container *ngFor="let header of ds.dataList; let i = index;let length= count ">
          
          <ng-container class="row" *ngIf='chs.checkForStatus(header)'>

            <div class="col-12" *ngIf='header && needBookList(header,i)'>
             
              <app-book-name [h]=header> </app-book-name>
            </div>

            <ng-container *ngIf='chs.shrinkMode(header)'>
              
              
              <!--
              last vs now hdr : {{( i==0 || (i>0 && ds.dataList[i-1].chapterId != header.chapterId) ) }}

              needHdr:  {{needGroupHdr(ds.dataList,"chapterId",header.chapterId,i)}}
              -->
              
              <div *ngIf='header?.chapterId &&(i==0 || (i>0 && ds.dataList[i-1].chapterId != header.chapterId) ) &&  needGroupHdr(ds.dataList,"chapterId",header.chapterId,i)' >
              chapter - {{header.chapterId}} -{{header.title}}
              </div>  

              <div *ngIf='subject == "subject" && header?.year && (i==0 || (i>0 && ds.dataList[i-1].subject != header.subject) ) && needGroupHdr(ds.dataList,"year",header.year,i)' class="text-light bg-secondary">
                {{header.board}}-{{header.course}} :{{header.subject}} -{{header.title}}
                -{{header.summary}}
              </div>
              
              <div *ngIf='subject != "subject" && header?.year &&(i==0 || (i>0 && ds.dataList[i-1].year != header.year) ) && needGroupHdr(ds.dataList,"year",header.year,i)'>
              Year - {{header.year}} -{{header.title}} --{{header.summary}}
              </div>

              <ng-container *ngIf='chs.eligibleShow(header) else disable'>
                <div class="col-12 col-md-6" *ngIf="chs.enable('simple')">
                  <app-simple-chptr [header]=header>
                  </app-simple-chptr>
                </div>
                <p class="col-12" *ngIf="chs.enable('complex')">
                  <app-complex-chptr [header]=header [i]=i [refresh]=ds.refresh>
                  </app-complex-chptr>
                </p>

              </ng-container>
            </ng-container>
          </ng-container>

          <ng-template #disable>
            <!--
              <app-disabled-chptr class="col-12"  *ngIf="!hide" [header]=header>
              </app-disabled-chptr>
              -->
          </ng-template>


        </ng-container>

      </div>








      <div *ngIf="false" class="container">
        <div class="row" id="ads">

          <ng-container *ngFor="let header of ds.dataList; let i = index;">
            <!--
            {{needBookList(header)}}
           {{lastSubject}} - {{lastBook}} -{{lastBranch}} {{header|json}}
           -->
            <!-- show raw wip to only who creates-->

            <li class="list-unstyled">

              <ng-container *ngIf='eligibleShow(header) else disable'>
                <div class="col-12 col-md-6" *ngIf="chs.enable('simple')">
                  <app-simple-chptr [header]=header>
                    *ngIf="chs.enable('simple')" </app-simple-chptr>
                </div>

                <!--
             <ng-container *ngIf= 'chs.enable("eligible",i ,header)'>
              {{header.book}}-{{header.branch}}-{{needBookList(header)}}
              
              {{header|json}}
             -->

                <!--need book title for 'all subject/multi book subjects-->
                <div *ngIf='header && needBookList(header,i)'>
                  
                  <app-book-name [h]=header> </app-book-name>
                </div>

                <div class="col-12 col-md-12" *ngIf="chs.enable('complex')">
                  <app-complex-chptr [header]=header [refresh]=ds.refresh>
                  </app-complex-chptr>
                </div>


              </ng-container>


              <ng-template #disable>
                <app-disabled-chptr *ngIf="!hide" [header]=header>
                </app-disabled-chptr>
              </ng-template>


            </li>



          </ng-container>


        </div>
      </div>



      <!-- my leave space py extend blue btn -->



      <!--
              <ul class= "row">
            
              <li *ngFor="let header of ds.dataList; let i = index;">
                <div class="col-12 col-md-6 ">
              <app-simple-chptr  [header] = header *ngIf= '!multiFeatures'></app-simple-chptr>
            </div>

              <app-complex-chptr  *ngIf= 'multiFeatures'></app-complex-chptr>

                  <div class="row col-12">
                    <app-display-ad   *ngIf="(i!='0') && (i%9 =='0')">
                    </app-display-ad>
                  </div>


              </li>
                
            </ul>   

          -->

    </ng-container>
    <ng-template #noItems>No Items!</ng-template>
  </div>

  <ng-template #loading>loading data...</ng-template>

</div>



<ng-template #noCall>c!</ng-template>