
<div *ngIf="header"  [hidden]="submitted" >
    
    <form #ngform #thForm="ngForm" (ngSubmit)="onSubmit()">
     
       <app-qh-mnt [header] = header [ei]= ei [offering] = 'header.type' [parentFormGroup] = thForm > </app-qh-mnt>

      <button type="submit" class="btn btn-success">Submit</button>
      <button *ngIf = "enable('delete')" type="submit" class="btn btn-danger" (click) = 'delete(header.id)'> Delete</button>
    </form>
  </div>
  