



<div *ngIf="menuType=='floatingFooter'">

<!--app-display-ad  > </app-display-ad-->

</div>

<nav *ngIf="(menuType=='floatingFooter' || menuType=='fixedFooter') && (data$ | async) as d; else loading" class= "float-center bg-grey navbar navbar-expand-lg  ">

    <ng-container *ngIf="d">

        <ng-container *ngIf="d.dtls && d.dtls.length; else noItems">
          
          

  <ng-container *ngFor="let i = index; let m of d.dtls;">  


            <li  *ngIf='cs.enable("subMnuHardC",m.groupId,m,hdr)' class="nav-item justify-center">
                <a [routerLinkActive]="['active']" routerLink = {{cs.getRouteUrl(m.category,m.extLink,m.format)}}  
                class="mx-1 btn btn-outline-white badge border-0" ngbTooltip={{m.instruction}} placement="right"  > <span innerHTML='{{m.title}}' > </span>
                </a>
              </li>
        </ng-container>
        
        <!--app-footer [site] = site> </app-footer-->
        </ng-container>

    </ng-container>  

    

<div *ngIf= "menuType=='fixed11Footer'" >

    <!--
    <a class="btn btn-secondary-link badge "
                              routerLink="{{cs.navigate('together','')}}"> personalized
                              learning </a>
     -->                       
    <!--
        <app-signuplogin class= "text-light"> </app-signuplogin>
    -->
    
    
    <button *ngIf="cs.enable('footer','contact-site')"
        class="btn btn-outline-secondary border-0 badge"
        (click)='cs.navigate("pb", "features/contact")'>Contact
        Us</button>

        <button *ngIf="cs.enable('footer','personalized')"
        class="btn btn-outline-secondary border-0 badge mx-3"
        (click)='cs.navigate("ftrdigibest", "")'>personalized learning</button>
    

    <!--about?{{cs.enable('footer','about-site')}} -->

    <button *ngIf="cs.enable('footer','about-site')"
        class="btn btn-outline-secondary badge border-0 ml-3"
        (click)='cs.navigate("usp", "features/about")'>About Us</button>

    <button *ngIf="cs.enable('footer','content-site')"
        class="btn btn-outline-secondary badge ml-3 border-0"
        (click)='cs.navigate("course", "features/exams")'>Course
        List</button>

    <a *ngIf='cs.enable("footer", "twitter")'
        class="btn border-0 btn-outline-secondary badge badge ml-3"
        href="https://twitter.com/{{twitterA}}" target="_blank">
        <i class="fa fa-twitter"></i>
    </a>



</div>

<!--div class="text-muted small">{{site}} </div-->


</nav>


  <ng-template #loading > </ng-template>
  <ng-template #noItems ></ng-template>
 


  
