  <div class= "threeQuarter">
  <div  *ngIf='qhf.enable(fldList,"site")'  class="form-group">
    <label for="site">Site: </label>
    <input type="text" class="form-control" id="site" required [(ngModel)]="h.site" name="site">
  </div>

  <div  *ngIf='qhf.enable(fldList,"board")'  class="form-group">
    <label for="board">Board: </label>
    <input type="text" class="form-control" id="board" required [(ngModel)]="h.board" name="board">
  </div>

  <div  *ngIf='qhf.enable(fldList,"course")'  class="form-group">
    <label for="course">Course: </label>
    <input type="text" class="form-control" id="course" required [(ngModel)]="h.course" name="course">
  </div>

  <div  *ngIf='qhf.enable(fldList,"sem")'  class="form-group">
    <label for="sem">sem: </label>
    <input type="text" class="form-control" id="sem" [(ngModel)]="h.sem" name="sem">
  </div>

  
  <div  *ngIf='qhf.enable(fldList,"actionDate")'  class="form-group">
    <label for="actionDate">action date: </label>
     {{h.actionDate|date:'dd:MMM'}}

    <input type="date" class="form-control" id="actionDate" [(ngModel)]="h.actionDate" name="actionDate" 
       >
  </div>

 <app-hdr-extra [h] = h [ei]= ei [fldList]=fldList> 
    </app-hdr-extra>

 <!-- comprehensive upload starts-->
  <div  *ngIf='qhf.enable(fldList,"upload")'  class="form-group">
    <label for="subject">Build option: </label>


    <div class="form-group row mx-2">
      <input valueChecked type="radio" id="link" name="inp" value="link"
        [(ngModel)]='inp'>
      <label class="mx-2" for="link">Link/URL</label>

      <input type="radio" id="upload" name="inp" value="upload"
        [(ngModel)]='inp'>
      <label class="mx-2" for="upload">Pick File</label><br>

      <input type="radio" id="draft" name="inp" value="new" [(ngModel)]='inp'>
      <label class="mx-2" for="draft">Create New</label>
    </div>

    <div *ngIf='inp=="new"'>
      <app-create-file>
      </app-create-file>

      <div *ngIf='createRslt' class="form-group">
        created url: {{h.extUrl}} <br>
      </div>
    </div>

    <div *ngIf='inp=="upload"'>
      <app-file-upload [iHeader]=h [hdrType]='"qstn"'>
      </app-file-upload>

      <div *ngIf='uploadRslt' class="form-group">
        Uploaded url: {{h.extUrl}} <br>
      </div>
      
    </div>

    <div *ngIf='inp=="link"' class="form-group">
      <label for="extUrl">Paste URL/Link: </label>
      <input type="text" class="form-control" id="extUrl"
        [(ngModel)]="h.extUrl" name="extUrl">
    </div>

    <div  *ngIf='qhf.enable(fldList,"uploadTitle")'  class="form-group">
      <label for="label">Title/Label: </label>
      <input type="text" class="form-control" id="label" required [(ngModel)]="h.title" name="label">
    </div>

        <!-- comprehensive upload end-->
  

  </div>
  

  <div  *ngIf='qhf.enable(fldList,"subject")'  class="form-group">
    <label for="subject">Subject: </label>
    <input type="text" class="form-control" id="subject" required [(ngModel)]="h.subject" name="subject">
    
</div>

<!--
<div [hidden]="h.subject"
class="alert alert-danger">
Subject is required.
</div>
-->


  <div  *ngIf='qhf.enable(fldList,"type")'  class="form-group">
    <label for="course">type: </label>
    <input type="text" class="form-control" id="type" required [(ngModel)]="h.type" name="type">
  </div>

  
  <div  *ngIf='qhf.enable(fldList,"chapterId")'  class="form-group">
    <label for="chapter">chapter: </label>
    <input type="text" class="form-control" id="chapter"  [(ngModel)]="h.chapterId" name="chapter" maxlength="15">
  </div>

  <div  *ngIf='qhf.enable(fldList,"section")'  class="form-group">
    <label for="section">Section: </label>
    <input type="text" class="form-control" id="section"  [(ngModel)]="h.section" name="section">
  </div>


  <div  *ngIf='qhf.enable(fldList,"school")'  class="form-group">
    <label for="school">school: </label>
    <input type="text" class="form-control" id="school"  [(ngModel)]="h.school" name="school">
  </div>

  <div  *ngIf='qhf.enable(fldList,"location")'  class="form-group">
    <label for="location">location: </label>
    <input type="text" class="form-control" id="location"  [(ngModel)]="h.location" name="location">
  </div>

   <!-- put in upload.. not in add ??? 
   <app-file-upload *ngIf='qhf.enable(fldList,"upload")' [iHeader] = header>
  </app-file-upload>
  -->
  

  
  <div  *ngIf='qhf.enable(fldList,"extUrl")'  class="form-group">
    <label for="extUrl">extUrl: </label>
    <input type="text" class="form-control" id="extUrl"  [(ngModel)]="h.extUrl" name="extUrl">
  </div>

  <div  *ngIf='qhf.enable(fldList,"title")'  class="form-group">
    <label for="title">Title: </label>
    <input type="text" class="form-control" id="title"  [(ngModel)]="h.title" name="title">
  </div>

  

  <div  *ngIf='qhf.enable(fldList,"bstrSet")'  class="form-group">
    <label for="bstrSet">Set: </label>
    <input type="text" class="form-control" id="bstrSet"  [(ngModel)]="h.bstrSet" name="bstrSet">
  </div>

  <div  *ngIf='qhf.enable(fldList,"current")'  class="form-group">
    <label for="current">current: </label>
    <input type="text" class="form-control" id="current"  [(ngModel)]="h.current" name="current">
  </div>

  <div  *ngIf='qhf.enable(fldList,"status")'  class="form-group">
    <label for="status">Status: </label>
    <input type="text" class="form-control" id="status"  [(ngModel)]="h.status" name="status">
  </div>

  <div  *ngIf='qhf.enable(fldList,"hdrFormat")'  class="form-group">
    <label for="hdrFormat">hdrFormat: </label>
    <input type="text" class="form-control" id="hdrFormat"  [(ngModel)]="h.hdrFormat" name="hdrFormat">
  </div>

  <div  *ngIf='qhf.enable(fldList,"summary")'  class="form-group">
    <label for="summary">summary: </label>
    <input type="text" class="form-control" id="summary"  [(ngModel)]="h.summary" name="summary">
  </div>

  <div  *ngIf='qhf.enable(fldList,"year")'  class="form-group">
      <label for="year"> year: </label>
      <input type="string" class="form-control" id="year"  [(ngModel)]="h.year" name="year">
    </div>
    
    
  <div  *ngIf='qhf.enable(fldList,"book")'  class="form-group">
    <label for="book">Book: </label>
    <input type="text" class="form-control" id="book"  [(ngModel)]="h.book" name="book">
  </div>
  
  <div  *ngIf='qhf.enable(fldList,"branch")'  class="form-group">
    <label for="branch">branch: </label>
    <input type="text" class="form-control" id="branch"  [(ngModel)]="h.branch" name="branch">
  </div>
  <div  *ngIf='qhf.enable(fldList,"headerId")'  class="form-group">
    <label for="headerId">Hdr-Grp Id: </label>
    <input type="text" class="form-control" id="headerId"  [(ngModel)]="h.headerId" name="headerId">
  </div>

  <div  *ngIf='qhf.enable(fldList,"prntHdrId")'  class="form-group">
    <label for="headerId">Parent Hdr Id: </label>
    <input type="text" class="form-control" id="prntHdrId"  [(ngModel)]="h.prntHdrId" name="prntHdrId">
  </div>

  
    <div  *ngIf='qhf.enable(fldList,"owner")'  class="form-group">
      <label for="owner"> Content Owner: </label>
      <input type="string" class="form-control" id="owner"  [(ngModel)]="h.owner" name="owner">
    </div>

    <div  *ngIf='qhf.enable(fldList,"clsSec")'  class="form-group">
      <label for="clsSec"> Content clsSec: </label>
      <input type="string" class="form-control" id="clsSec"  [(ngModel)]="h.clsSec" name="clsSec">
    </div>
   
  </div>
    