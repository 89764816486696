import { Component, OnInit } from '@angular/core';
import { HttpHlprService } from '../../../shared/http-hlpr.service';
import { CntxtService } from '../../../pages/cntxt-menu/cntxt.service';
import { ClientInfoService } from '../../../shared/client-info.service';

// don't use.... trying to disable
@Component({
  selector: 'app-go-store',
  templateUrl: './go-store.component.html',
  styleUrls: ['./go-store.component.scss']
})
export class GoStoreComponent implements OnInit {

  constructor(public h:HttpHlprService, public cm:CntxtService,public cs:ClientInfoService ) { }
show:boolean;
showMyMkr:boolean;
showBWC:boolean;

  ngOnInit(): void {
//note-only use for mkr- cat and tavl- offer combo 
if(this.h.st && this.h.st=="avl" && (this.h.cat.includes("mkr-") || this.h.cat == "engage-src"||this.h.cat == "interact-src" ) ){this.show= true;}

if(!this.h.st && (this.h.cat.startsWith("cstore")) ){this.showBWC= true;}

if(!this.h.st && (this.h.cat.startsWith("cstore")) ){this.showMyMkr= true;}

if(!this.h.st && (this.h.cat.startsWith("fresh")) ){this.showMyMkr= true;}

}

  //only use for mkr- cat and tavl- offer combo 
  goStore() {
    //var cat= this.cs.offering + '-bldr';
    
    var newCat= 'cstore';
    if(this.h.cat.includes("mkr-")){
      newCat= this.h.cat.replace("mkr","cstore") } 
   
      // show base type not tstore- one.. 
    var newOfr= this.h.ty.replace("tavl-","");
    let url = this.cs.router.url.replace(this.h.cat,newCat).replace(this.h.ty, newOfr)
    this.cs.router.navigate([url]);
  }
  
  
  myMkr() {
    let newCat = "myMkr";
    if(this.h.cat.startsWith("cstore") &&  this.h.justType){
    newCat= 'myMkr-' +  this.h.justType;
    }
   this.cs.navigate(newCat,null);
   }
  
   BuildWithClick() {
    var newCat= '-bldr';
    if(this.h.cat.includes("cstore-")){
    newCat= this.h.cat.replace("cstore-","") +"-bldr" } 
    // show base type not tstore- one.. 
    var newOfr= this.h.justType;
    //.replace(this.h.ty, newOfr) inser later
    let url = this.cs.router.url.replace(this.h.cat,newCat);
    this.cs.router.navigate([url]);
   }
  

}
