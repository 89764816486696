import { Component,HostListener,Input, OnInit } from '@angular/core';
import { TestService } from '../test.service';

declare var MathJax: any;

@Component({
  selector: 'app-test-list',
  templateUrl: './test-list.component.html',
  styleUrls: ['./test-list.component.scss']
})
export class TestListComponent implements OnInit {
  @Input() contents:any;
  @Input() i: number;

  previousPage: number;
  qstns:any;
  feedback:boolean;
  totalQstns: number;
  hideTest: boolean;
  
  constructor(public ts:TestService) { 
    /*
    TestService.hideTest.subscribe(hide=>{
      this.hideTest = hide; 
     });
 */

  }

  ngOnInit(): void {
    
   

   
  
   if(this.contents){

    let key = 'qstns-'+this.ts.cs.getChapter();
    let value = localStorage.getItem("key")
    let hasLocal = value && JSON.parse(value);

    if(hasLocal){
      let localQstns=  JSON.parse(value);
      this.ts.cs.log(localQstns, 'test-list,init');
      this.ts.qstns = localQstns; 
      this.totalQstns = this.ts.qstns.length;
    } 
    else{
     this.ts.qstns= this.contents.dataList;
     this.totalQstns = this.ts.qstns.length;
    }

    this.ts.hdr= this.contents.hdr;
     this.ts.q = this.ts.qstns[0];
     this.ts.startTimer();
  
     if(this.ts.hdr && this.ts.hdr.extra){
    let extraJson = JSON.parse(this.ts.hdr.extra);

   if(extraJson.testTime){
     this.ts.setTestTime(extraJson.testTime);
     }}
   
  }
   }


/* proctored test mode v */

  
  @HostListener('window:focus', ['$event'])
      onFocus(event) {
        this.ts.count= this.ts.count + 1;
        console.log("****user attempted leaving but changed its mind, do actions here");
      }

      
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
   event.preventDefault();
  }
  
  ngAfterViewChecked(){
   //MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
    this.ts.cs.typeSet();
  }
 

  


}
