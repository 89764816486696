  <ng-container *ngIf= "qstnId">
<br>
<br>
<br>
<app-reaction> </app-reaction>
<br>
<br>
<br>
</ng-container>

   <ng-container *ngIf= "false" class='text-center'>
    <!-- later  text-center 
    
    <p class= "lead, text-center mx-5 mt-4 font-weight-bold font-italic "> We would love your feedback</p>
    -->
    
    <app-cmnt-list [featureType] = cat [cmntType]= "'H'+cat" [parentHdrId] = chapter?chapter:0 >      </app-cmnt-list>
       
    </ng-container>
  