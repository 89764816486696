import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { SecurityService } from '../../shared/security.service';
import { UsrroleService } from '../usrrole.service';
import { RoleChangerService } from '../role-changer.service';

interface subscription {
  mode: string;
  desc: string;
}

@Component({
  selector: 'app-manage-subscrptn',
  templateUrl: './manage-subscrptn.component.html',
  styleUrls: ['./manage-subscrptn.component.scss']
})
export class ManageSubscrptnComponent implements OnInit {

  subscriptionList: subscription[];
  selectedMode: any;
  authorities: any[];
  roles: any[];

  constructor(public cs: ClientInfoService, private ss: SecurityService, private router: Router,private usrroleService: UsrroleService,
    public rcs:RoleChangerService ) { }

  ngOnInit(): void {

    this.subscriptionList = new Array<subscription>();

    let roles = this.ss.getRoleOptions();
    if(roles){
    let roleList = roles.split(",");
    for(let role of roleList){
       {
        this.subscriptionList.push({ "mode": role, "desc": role });
      }
    }
  }
    /*
    if (roles) {

      if (roles.includes("app_admin")) {
        this.subscriptionList.push({ "mode": "app_admin", "desc": "App Admin" });
      }else
      if (roles.includes("architect")) {
        this.subscriptionList.push({ "mode": "architect", "desc": "System Architect" });
      }
      else
      if (roles.includes("admin")) {
        this.subscriptionList.push({ "mode": "admin", "desc": "site Admin" });
      }
      else
      if (roles.includes("user_admin")) {
        this.subscriptionList.push({ "mode": "user_admin", "desc": "User Admin" });
      }
      else
      if (roles.includes("super-crud")) {
        this.subscriptionList.push({ "mode": "super-crud", "desc": "Super User" });
      }else

      if (roles.includes("sales")) {
        this.subscriptionList.push({ "mode": "sales", "desc": "Growth Manager" });
      }
      else


      if (roles.includes("director")) {
        this.subscriptionList.push({ "mode": "director", "desc": "Director" });
      }
      else
     
      
      if (roles.includes("coe-mngr")) {
        this.subscriptionList.push({ "mode": "coe-mngr", "desc": "COE Manager" });
      }
      else

      if (roles.includes("crclm_mngr")) {
        this.subscriptionList.push({ "mode": "crclm_mngr", "desc": "Curriculum Manager" });
      }

      else
      if (roles.includes("dgtzn_mngr")) {
        this.subscriptionList.push({ "mode": "dgtzn_mngr", "desc": "Digitization Manager" });
      }
      else

      if (roles.includes("digi_expert")) {
        this.subscriptionList.push({ "mode": "digi_expert", "desc": "DigiBest Teacher" });
      }
      if (roles.includes("booster_maker")) {
        this.subscriptionList.push({ "mode": "booster_maker", "desc": "Booster Maker" });
      }

      if (roles.includes("teacher")) {
        this.subscriptionList.push({ "mode": "teacher", "desc": "Teacher" });
      }

      if (roles.includes("learner")) {
        this.subscriptionList.push({ "mode": "learner", "desc": "learner" });
      }









    }
    */

    // by default to some customers we will enable 3 roles. above one is special as has more features

    //this.subscriptionList.push({"mode":"teacher", "desc": "Teacher"});
    //this.subscriptionList.push({"mode":"learner", "desc": "learner"});
    //this.subscriptionList.push({"mode":"crclm_mngr", "desc": "Cntnt Owner"});
    // this.subscriptionList.push({"mode":"sales", "desc": "Sales Team"});

  }


  
  dropdownClickV2Delete(groupId:string){
   //apply dfltGroupid logic earlier...
    var orgnlGroupId=  localStorage.getItem('dfltGroupId');

   var principal = JSON.parse(localStorage.getItem('principal'));
  principal.groupId= groupId;
   principal.learnMode = this.cs.getJustSite();
   let authorities: any[] =principal.authorities;
   authorities.splice(0,authorities.length)
   let roles:any[];
  
   //  old version console.log(roles);
  /*
   this.usrroleService.getGroupRoleList(groupId).subscribe(data => {
   roles= data;
//note typescript foreach is callback type different function..
 roles.forEach(function (value) {
  //console.log(value);
   authorities.push({"authority": value.role});
  }); 
*/

  this.usrroleService.getConsolidatedGroupRoleList(groupId).subscribe(data => {
    roles= data;
    roles.forEach(function (value) {
      //console.log(value);
       authorities.push({"authority": value.authority});
      }); 

   
  this.ss.signin(principal);
    this.cs.addMsg("Modified", "toast");

    let pr = principal;
    //'learner', 'features',
    // this approach has one bug... parent with dfltmenu will get wiped out of dflt-mnu.
    
       //dflt mnu start blocking other group change(:
       // - means its not role group but rol-subgroup with dfltmnu.
       
       //default menu will be only for comfort and won't be supported?
      
     let menu = pr.groupId;
     if(groupId.includes("-") ){ menu= groupId.split("-")[1]}
     
     //if(menu=='teacher' ||menu=='learner' ){menu= menu.substr(0,menu.length-2);}

     let myMenu = [this.cs.getSite(), menu, pr.board, pr.grade];
     this.router.navigate(myMenu);
    }
   );
  
}



  dropdownClickV1(groupId: string) {
    var principal = JSON.parse(localStorage.getItem('principal'));
    principal.learnMode = this.cs.getJustSite();
    this.changeAuthV1(groupId, principal);
    this.ss.signin(principal.authorities);
    this.cs.addMsg("Modified", "toast");
    let pr = principal;
    //'learner', 'features',
    // this approach has one bug... parent with dfltmenu will get wiped out of dflt-mnu.
    if (groupId != 'a11pp_admin') {
       //dflt mnu start blocking other group change(:
       // - means its not role group but rol-subgroup with dfltmnu.
       let groupId = pr.groupId;
       if(groupId.includes("-") && pr.dfltMnu){
        groupId = pr.dfltMnu;}
      else{
       pr.dfltMnu= "";
       }

      //
      let myMenu = [this.cs.getSite(), groupId, pr.board, pr.grade];
      this.router.navigate(myMenu);
    }
  }


  private changeAuthV1(groupId: string, principal: any) {
    switch (groupId) {
      case "digi_expert": {
        principal.groupId = "teacher";
        principal.authorities.push({ "authority": "digi_expert" });
        principal.grantedAuthorities.push({ "authority": "digi_expert" });


        break;
      }


      case "dgtzn_mngr": {
        principal.groupId = "dgtzn_mngr";
        //principal.dfltMnu = "mydgtzr";
        principal.authorities= [];
        principal.authorities.push({ "authority": "digi_expert" });
        principal.grantedAuthorities.push({ "authority": "digi_expert" });

        principal.authorities.push({ "authority": "eb_coe" });
        principal.grantedAuthorities.push({ "authority": "eb_coe" });

        principal.authorities.push({ "authority": "curriculum" });
        principal.grantedAuthorities.push({ "authority": "curriculum" });

        break;
      }




      case "coe-mngr": {
        principal.groupId = "crclm_mngr";
        principal.dfltMnu = "coe-mngr";
        principal.authorities.push({ "authority": "digi_expert" });
        principal.grantedAuthorities.push({ "authority": "digi_expert" });
        principal.authorities.push({ "authority": "eb_coe" });
        principal.grantedAuthorities.push({ "authority": "eb_coe" });


        //principal.grantedAuthorities.push({ "authority": "db_coe" });
        break;
      }

      case "super-crud": {
        principal.groupId = "crclm_mngr";
        principal.dfltMnu = "super-crud";
        principal.authorities.push({ "authority": "digi_expert" });
        principal.grantedAuthorities.push({ "authority": "digi_expert" });
        principal.authorities.push({ "authority": "eb_coe" });
        principal.grantedAuthorities.push({ "authority": "eb_coe" });
        principal.authorities.push({ "authority": "ao_mngr" });
        principal.grantedAuthorities.push({ "authority": "ao_mngr" });


        //principal.grantedAuthorities.push({ "authority": "db_coe" });
        break;
      }

      //bridge role between teacher & content manager is digi_expert 
      case "crclm_mngr": {
        principal.groupId = groupId;
        principal.authorities.push({ "authority": "digi_expert" });
        principal.grantedAuthorities.push({ "authority": "digi_expert" });
        break;
      }
      case "director": {
        principal.groupId = groupId;
        principal.authorities.push({ "authority": "admin" });
        principal.grantedAuthorities.push({ "authority": "digi_expert" });
        break;
      }

      case "booster_maker": {
        principal.groupId = "crclm_mngr";
        principal.authorities.push({ "authority": "booster_maker" });
        principal.authorities.push({ "authority": "crclm_mngr" });
        principal.grantedAuthorities.push({ "authority": "digi_expert" });
        break;
      }

      case "learner": {
        principal.groupId = groupId;
        principal.dfltMnu = "learner";
        break;
      }
      default: {

        //console.log("start", principal.authorities);
        const index = principal.authorities.findIndex(item => item.authority === "digi_expert");
        //console.log("index is ", index)
        if (index > -1) {
          principal.authorities.splice(index, 1);
          //console.log("changed:", principal.authorities);
        }
        const index1 = principal.grantedAuthorities.findIndex(item => item.authority === "digi_expert");
        if (index1 > -1) {
          principal.grantedAuthorities.splice(index1, 1);

        }

        principal.groupId = groupId;


        break;
      }

    }
    
  }

  enable(btnId?: string) {
    let roles = this.ss.getRoles();
    if (roles) {
      return true;
    }

    //return this.cs.enable("demo",btnId);
  }

}
