



<div *ngIf= "questions && questions ">

  
<span *ngIf= 'cs.debug'>
  firstId: {{questions[0]?.headerId}}-{{questions[0]?.id}}
  ftrDesc: {{ftrDescObj?.id}}
</span>

  <!--
<p class= "h3 text-center" *ngIf="!hideTitle && headerObj" >{{formatTitle()}}</p>
-->

<!--[ftrDescObj] = ftrDescObj-->


<app-headings-plain *ngIf='!hideTitle && headerObj'  [hdr] = headerObj ></app-headings-plain>


<app-hdr-view-slctr  *ngIf='!hideTitle && headerObj'  [hdr] = headerObj ></app-hdr-view-slctr>

<div *ngIf="expanded && ftrDescObjDummy" class="my-1 h3 bg-warning text-primary">
    <p  class="lead text-left badge " [innerHTML]= [ftrDescObjDummy.instruction] >  </p>
</div>

<!--
<a [ngClass]="{'disabled': name!=='junaid'}"> This a tag is disabled</a>
<a *ngFor="let link of links"
   [attr.href]="isDisabled(link) ? null : '#'"
   [class.disabled]="isDisabled(link)"
   (click)="!isDisabled(link) && onClick(link)">
   {{ link.name }}
</a>
{{ftrDescObjDummy|json}}
-->


<ul class="p-0 row" >
  
<div *ngIf="expanded && ftrDescObjDummy" class="text text-left  " [innerHTML]= [ftrDescObjDummy.content] >  </div>


<ng-container *ngFor="let i = index; let question of questions;">
  
  <li *ngIf= "(enable(question.groupId,i,question))" class="mb-2 pb-2 list-group-item col-12 col-md-6  border-0 py-0" >


<div>

<div *ngIf="false && expanded" class="lead text-left badge font-italic" [innerHTML]= [question.instruction] >  </div>
  

<div  *ngIf=" (question.format && question.format=='extLink') ||(enable(question.groupId,i,question) && question.extLink && question.extLink.lastIndexOf('http', 0) === 0); else appLink"   >


   <a href={{question.extLink}}  class="btn btn-primary border-0 btn-block pb-1" target="_blank"  >{{question.title?question.title:Link}} <span  *ngIf= "question.status=='fresh'"  class="badge text-warning float-right"  > <sup>{{question.status}}</sup> </span>  

    </a>
   </div>
   <!-- ngbTooltip={{question.instruction} placement="bottom"-->
   
 
   <ng-template #longContent let-modal>
      <div class="modal-header">
        <h4 class="modal-title" innerHTML ={{question.title}} > </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" innerHTML= {{question.answer}}> </div>
      <div class="modal-body" innerHTML= {{cs.insertSignUpMsg(question)}}> </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
      </div>
    </ng-template>

   
<ng-template  #appLink>
     <!---->

     <span  *ngIf= "question.difficulty"  class="badge text-warning float-right"  > <sup>empty-{{question.difficulty}}</sup> </span>  

     <span  *ngIf= "question.status=='beta'|| question.status=='new'"  class="badge text-warning float-right"  > <sup>{{question.status}}</sup> </span>  
     
     <span  *ngIf= "question.status=='fresh'"  class="badge text-warning float-right"  > <sup>{{question.status}}</sup> </span>  

     <button *ngIf= "unsigned && question.status && question.status== 'beta'" class="btn bg-dark text-white badge border-0  mb-2 mr-2 float-right" (click)="openScrollableContent(longContent)">coming soon</button>

     <button *ngIf= "unsigned && question.status && question.status== 'sign'" class="btn bg-dark text-white badge border-0  mb-2 mr-2 float-right" (click)="openScrollableContent(longContent)">need signin*</button>
     
     <button *ngIf= "unsigned && question.status && question.status== 'paid'" class="btn bg-dark text-white badge border-0  mb-2 mr-2 float-right" (click)="openScrollableContent(longContent)">need subscription*</button>


     <button *ngIf= "!unsigned && question.answer"  class="btn bg-dark text-white badge border-0  mb-2 mr-2 float-right" (click)="openScrollableContent(longContent)">? </button>
    
  
     <a [routerLink] = 'getRouteUrl(question)' [ngClass]="{'disabled': grey(question)}" class=" btn btn-primary border-0  btn-block pb-1"  (click) = 'clicker(question)' >
      <span  class="badge text-white " innerHTML={{question.title}}>  </span>
      <!--ngbTooltip={{getTooltip(i,question)}} placement="bottom"   
       ngbTooltip= {{question.instruction}}  placement="bottom"-->
   
        </a>

   </ng-template>
   
   <div *ngIf="expanded" class=" text-small text-muted" >  
   <span innerHTML= {{question.instruction}}> </span>
   <button *ngIf= "question.content" (click) = "more(i)" class= "px-3 btn badge   float-right"
   data-toggle="tooltip" data-placement="bottom" title="Feature Detail"
   >
     <span class= "" >...</span> </button>     
   </div>
   
   
  
  <div *ngIf="moreI==i" class= "text-small"  innerHTML="{{ question.content}}" >


</div>
 


   <!-- working but disabling in favor of button
   <div *ngIf="expanded  && question.answer" class= "text-small" [class.overflow]="isExpand" innerHTML="{{ question.answer}}" (click)="isExpandToggle()"></div>
   -->

</div>
<!-- <br>  -->
<!-- add break  if block <br>  -->
<!-- badge vs border-0 and button class vs span class does the magic!-->



</li>



</ng-container>




</ul>


<p *ngIf="expanded && ftrDescObjDummy" class="text text-left " [innerHTML]= [ftrDescObjDummy.answer] >  </p>

<!--
<app-topic-list [q] = 'ftrDescObj'> </app-topic-list>
-->
</div>

<app-thoughtoftheday  class=" text-center" *ngIf= 'cs.enable("ofrList","thoughtOTD")'>... </app-thoughtoftheday>

 <app-cube3d *ngIf= 'cs.enable("ofrList","engager")'>
  </app-cube3d>
  
<app-mind-engager *ngIf= 'cs.enable("ofrList","engager")' > </app-mind-engager>


  <!--
{{userGroup}}
-->


<app-manage-subscrptn  class= "float-center" *ngIf= 'cs.enable("ofrList","changeRole")'> </app-manage-subscrptn>
<br>
