<section [hidden]="submitted" class="">
    <h5>Send Mail</h5>
    <form #myForm="ngForm" (ngSubmit)="onSubmit()">
        <p>
            <label>Email:</label>
            <input type="text"  name= "email"
                [(ngModel)]="email" required>
        </p>
        
        <p>
            <button type="submit" [disabled]="!myForm.valid">Submit</button>
        </p>
    </form>
</section>
