import {  OnInit } from '@angular/core';

import { NgModule, Component,ElementRef, Injectable } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { ReactiveFormsModule, FormControl, FormsModule } from "@angular/forms";
import { ExamDataService } from '../../exam-data.service';
import { ClientInfoService } from '../../shared/client-info.service';


import {
  HttpClientJsonpModule,
  HttpClientModule,
  HttpClient
} from "@angular/common/http";
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {
board: string  ;
course:string ;
subjects: Object;
showS: boolean = true;
  constructor(private route: ActivatedRoute,
    private router: Router, private elementRef:ElementRef, private dataService: ExamDataService,private cs:ClientInfoService) {
 this.board = this.elementRef.nativeElement.getAttribute('board');

    this.route.params.subscribe( params => {
//alert(params);
//console.log(params);
this.board= params['board'];
});

//this.board = this.elementRef.nativeElement.getAttribute('board');
//alert(this.board);


if(this.board == null){
//this.board = this.elementRef.nativeElement.getAttribute('board');
//alert("from parm pass " + this.board);
this.board= cs.getBoard();
this.course= cs.getCourse();
};

     }

  ngOnInit() {

  this.dataService.getPyqSubjects(this.board,this.course).subscribe(dataList => {
        this.subjects = dataList;
       // alert("subjects retrieved ");
        //console.log(this.subjects);

      });

  }


onSubject(subject:string) {
  this.router.navigate(["board", this.board, subject]);
}

}
