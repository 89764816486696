import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hdr-url-shower',
  templateUrl: './hdr-url-shower.component.html',
  styleUrls: ['./hdr-url-shower.component.scss']
})
export class HdrUrlShowerComponent implements OnInit {
@Input() h :any;

  constructor() { }

  ngOnInit(): void {
    
  }

}
