import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { QstnDtlService } from '../../qstndtl/qstn-dtl.service';

@Component({
  selector: 'app-whole-qstn-viewer',
  templateUrl: './whole-qstn-viewer.component.html',
  styleUrls: ['./whole-qstn-viewer.component.scss']
})
export class WholeQstnViewerComponent implements OnInit {
  @Input() detailId : number 
  @Input()  q: any;
  @Input() i:number;
  @Input()  hdr: any;
  
  loading:boolean;
  
  questions$:Observable<any>;
 
  constructor(private router: Router,private dtlsrv: QstnDtlService) { }

  ngOnInit(): void {
    this.questions$ = this.dtlsrv.getQstnSetForHdrDtl(this.detailId);
  }
  
  ngOnChanges(): void {
    this.questions$ = this.dtlsrv.getQstnSetForHdrDtl(this.detailId);
  }

  refresh(id:any): void {
      this.questions$ = this.dtlsrv.getQstnSetForHdrDtl(id);
  }

  
  
}
