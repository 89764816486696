import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ClientInfoService } from '../../shared/client-info.service';
import { UsrhdrService } from '../../user/usrhdr.service';

@Component({
  selector: 'app-parm-loader',
  templateUrl: './parm-loader.component.html',
  styleUrls: ['./parm-loader.component.scss']
})
export class ParmLoaderComponent implements OnInit {
  site: string
  cat: string
  board: string
  course: string
  siteObj: any;

  constructor(public cs: ClientInfoService, private route: ActivatedRoute,
    private router: Router, private uhs: UsrhdrService) { }

  ngOnInit(): void {



    this.route.params.subscribe(async params => {
      let redirect = false;
      this.site = params['site']
      this.cat = params['cat']
      this.board = params['board']
      this.course = params['course']

      if (!this.site) {
        this.site = await this.cs.getDefaultSite()
        redirect = true;
      }

     // if (this.site == 'gen' && !this.cat) {
      if (!this.cat) {
        let userMenuStr: string = localStorage.getItem('myPage');
        if (userMenuStr) {
          const userMenuArray = userMenuStr.split('/');
          console.log('redirecting ' + userMenuArray);
          this.router.navigate(userMenuArray);
          return;
        }
      }

      

      if (!this.siteObj) {
        let siteObjStr= localStorage.getItem('siteObj-' + this.site)
        if(siteObjStr){
          this.siteObj = JSON.parse(siteObjStr);
          this.cs.siteObj = this.siteObj;
          //console.log('avoiding call to domainRec for ' +this.site )
        } else{
        let out = await this.uhs.domainRec(this.site).toPromise();
        //console.log('made api call for domainRec for ' +this.site )
        this.siteObj = out.result;
        this.cs.siteObj = out.result;
        localStorage.setItem('siteObj-' + this.site, JSON.stringify(this.siteObj));
        }
        

        if (!this.cat) {
          //if(!this.siteObj.dfltCat){this.siteObj.dfltCat='home'}
          this.cat = this.siteObj.dfltCat
          this.cs.cat = this.cat
          redirect = true;
        }

        if (!this.board) {
          if (!this.siteObj.dfltBoard) { this.siteObj.dfltBoard = 'board' }
          this.board = this.siteObj.dfltBoard
          //redirect= true;
        }

        if (!this.course) {
          if (!this.siteObj.dfltCourse) { this.siteObj.dfltCourse = 'course' }
          this.course = this.siteObj.dfltCourse
          //redirect= true;
        }


        if (redirect) {
          //console.log('redirecting ' + this.cat);
          this.router.navigate([`${this.site}`, `${this.cat}`])
          //adding ,`${this.board}`,`${this.course}` -not working for platform
        }
      }


    });
  }
}
