import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';
import { Header } from '../../qstn/header';
import { ConfigService } from '../../shared/config.service';
import { ConfirmModalService } from '../../shared/confirm-modal.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { FileService } from '../../shared/file.service';
import { ExtraInfo } from '../../shared/assigner/extra-info';
import { QhFldService } from './qh-fld.service';

@Component({
  selector: 'app-qh-flds',
  templateUrl: './qh-flds.component.html',
  styleUrls: ['./qh-flds.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class QhFldsComponent implements OnInit {

@Input()  fldList:string[];
@Input()  h:Header;
@Input() ei:ExtraInfo;
@Input() parentFormGroup: FormGroup;

uploadRslt: boolean;
createRslt: boolean;
 

get school() {return this.cs.getSchool()};

//question header is base ...   may have to create new hdr set if needed for tags..
//for now both is same.. and even cfghdr is same.. is format
constructor(private confirmModalService: ConfirmModalService, public cfg:ConfigService,private cs:ClientInfoService, public qhf:QhFldService) { }

ngOnInit(): void {

  
  FileService.updateUploadUrl.subscribe(res=>{
    this.uploadRslt= true;
    if(res && !res.startsWith("ERROR")){
    this.cs.addMsg("Upload successful,Please press submit btn","toast");
    this.h.extUrl = res} else{
      this.uploadRslt= false;  
      this.cs.addMsg(res,"warning");
    }
  }
    )

    FileService.updateCreateUrl.subscribe(res=>{
      this.createRslt= true;
      if(res && !res.startsWith("ERROR")){
      this.cs.addMsg("New file created,Please press submit btn","toast");
      this.h.extUrl = res} else{
        this.createRslt= false;  
        this.cs.addMsg(res,"warning");
      }
    }
      )
      
}

public help(fld:string) {
  this.confirmModalService.help('Please enter char after "v="', ' https://www.youtube.com/watch?v=<span class="bg-info">bKKJkxqIg94</span>');
}


}
