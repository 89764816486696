
  FOR {{subject}} 
<div *ngIf='!subject'> 
 <h3> select subject and search</h3>
 <app-just-subject-list> Loading Subject List</app-just-subject-list>

 

<app-subject-book-list> </app-subject-book-list>  
  
</div>

<div class= "m-3"> 

  <div class= "text-center">
  <h3 class= "text-center">   
<i class="text-primary fa fa-bicycle" aria-hidden="true"><span>&nbsp;{{"Search anything"}}</span></i>
</h3>

<p class= "text-center"><input type="text" id="key" name="key" [(ngModel)]="key"></p>

<button class= "text-center" type="button" (click)="search(key)">Search</button>

</div>


<ul *ngIf="questions" >

        <li  *ngFor="let q of questions">
         
          <!-- for future
          <app-qstn-shower [q] = q > </app-qstn-shower>
  --> 
      #{{q.groupId}} : <br>
  
  <!---{{q.subSeq}}-{{q.part}}-->
      <div *ngIf="q.question" class="px-5 text-primary questionsc"  innerHTML = " <h2>{{  q.instruction }}</h2> <br> {{  q.question }}"> </div>
      <br/>
      <div *ngIf="q.answer" class="px-5" innerHTML = " <h5 >{{q.answer}} </h5>"> </div>
       
        <br>

          
    <a href="{{navigateQstn(q.headerId,q)}}" class="btn btn-outline-secondary border-0 badge " style="margin-left: 10px">#{{qs.formatSeq(q.qstnId,q.subqstnId, q.partId)}} link</a>



  
  <hr>
 </li>
 </ul>
 <p class= "text-center" *ngIf= 'gotResponse && questions.length ==0'> No content found </p>

</div>