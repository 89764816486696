import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {
  @Input() q:any;
  @Input() i:number;
  
  constructor(public cs:ClientInfoService) { }

  ngOnInit(): void {
  }

}
