
<!--
<div id= "aa" style= "white-space:pre-wrap;" [innerHtml]="(myHtml$ | async)">
</div>
<img src="/assets/img/loader4_new.gif"> 
-->

<button *ngIf="showEdit" class= "btn btn-outline-primary badge mx-3"  (click)="showText=!showText"> {{showText? 'Hide HTML Editor': 'HTML Editor'}} </button>

<div *ngIf= "false" class="container mb-1">
    <div class="row">
      <div class="col text-center">
        <button *ngIf="showEdit" class= "btn btn-outline-primary badge "  (click)="showText=!showText"> {{showText? 'Hide HTML Editor': 'HTML Editor'}} </button>
    </div>
    </div>
  </div>

    <hr>



<div class= "my-1" *ngIf="!textFormat &&  showText"  [hidden]="submitted" >
    
    <form #ngform (ngSubmit)="onSubmit()">
        
        <!--
        <label *ngIf="!readOnly"  for="question"> <button type="submit" class="btn btn-info">Save</button>    </label>
        -->

 <div  class="form-group"  style= "white-space:pre-wrap;" >
    <input *ngIf="false" type="text" class="form-control" id="question" required  name="question">
</div>
<!--
    <ckeditor *ngIf="true" editorUrl="\assets\ckeditor\ckeditor.js"
    editorConfig -->
    <ckeditor  [(config)]="cfg.withWordConfig"
      #editor debounce="500"
      [(ngModel)]="fileData" value= "fileData"
      id="fileData" name="fileData"  
      
    [readOnly]= readOnly
    (change)="onChange($event)"
          >
    </ckeditor>
    
   
<!--(editorChange)="onEditorChange($event)" 
    [config]="{uiColor: '#99000'}"
    
    (ready)="onReady($event)"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    (contentDom)="onContentDom($event)"
    (fileUploadRequest)="onFileUploadRequest($event)"
    (fileUploadResponse)="onFileUploadResponse($event)"
    (paste)="onPaste($event)"
    (drop)="onDrop($event)"
    (save) = "postTextFile(fileData)"
    -->

   
   <div *ngIf="!readOnly"  class="form-group mt-4">    
            <div class="row">    
                    <div class="col-3 col-md-3 mb-3">    
                        <button type="submit" class="btn btn-primary badge">Save</button>    
                    </div>    
                </div>    
            </div>    
   
   </form>
   
 Edited View:
</div>
 







<div *ngIf="fileData else loadingInfo"   style= "white-space:pre-wrap;" >
<div *ngIf= "cs.enable('qstnList','oldMath')" innerHTML= {{fileData}}> </div>


<div *ngIf="cs.enable('qstnList', 'newMath')"  [appMath]  = fileData> loading... </div>
</div>

<ng-template #loadingInfo>
  <img *ngIf= "loading" src="/assets/img/loader4_new.gif"> 
  loading 
</ng-template>