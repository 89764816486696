import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-dhw-bldr',
  templateUrl: './dhw-bldr.component.html',
  styleUrls: ['./dhw-bldr.component.scss']
})
export class DhwBldrComponent implements OnInit {
  @Input() crtHdr:any;
  showUpload:boolean;
  constructor(public cs:ClientInfoService,private router: Router) { }

  ngOnInit(): void {

  }

  dgtzrUrl(){
    
    let cat = this.cs.getCat();
    
    if(cat.endsWith("-bldr")){
      cat= cat.replace("-bldr","");
      let qOfr= "q" + cat;
      
      let extUrl= qOfr ;
      if(this.cs.getSubject()){
        extUrl = extUrl +"/" +this.cs.getSubject() ;
      }

      var chptrUrl = this.cs.getRouteUrl('dgtz',extUrl );
      console.log(chptrUrl);
      this.router.navigateByUrl(chptrUrl);
      }
    
      
    }

    
    
  
    
}
