
import { Component, OnInit } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { QstnDtl } from '../duk/qstndtl';


@Component({
  selector: 'app-fresh-quiz',
  templateUrl: './fresh-quiz.component.html',
  styleUrls: ['./fresh-quiz.component.scss']
})
export class FreshQuizComponent implements OnInit {


  q:QstnDtl;
  
  constructor(private dataService: BoardDataService) { }

  ngOnInit() {

let quizStr = localStorage.getItem('quizStr');
this.q= JSON.parse(quizStr);

if(!this.q){
  this.dataService.getQuiz().subscribe(response=> {
          if(response) {
          this.q = response as any;
        localStorage.setItem('quizStr', JSON.stringify(this.q));
          }   });
};
  
// return this.quiz;
}


formatSeq(sequence: string, subSeq: string, part: string) {
  return [sequence, subSeq, part].filter(Boolean).join('-');
}

}

