
<div class="container" [formGroup]="filterForm">
  <div class="search-bar">
    <input formControlName="search" placeholder="Search by q name">
    <select formControlName="is_available">
      <option [value]="null">Is available?</option>
      <option [value]="true">Yes</option>
      <option [value]="false">No</option>
    </select>
  </div>

  <div *ngFor="let q of (page | async) as results" [ngClass]="{'available': q.is_available, 'q': true}">
  
    <hr>

    <div *ngIf='q.instruction' >
      <label><b>Ins: </b></label> 
      <span innerHTML= {{q.instruction}}></span>
    </div> 
  
    <div>
      <label><b>qstn:</b></label> 
      <span innerHTML= {{q.question}}></span>
    </div>  
  
    <div>
      <label><b>Answer:</b></label> 
      <span innerHTML= {{q.answer}}></span>
    </div>  
    

  </div>
<hr>

  
  

  <app-paginator [page]="page | async" (pageChange)="onPageChanged($event)"></app-paginator>
</div>


