
<div class="text-left bg-info">
    <app-contact> </app-contact>
</div>
    
<div *ngIf= "enable('demoUser')" class= "row d-flex">
<div class="col-12 justify-content-between d-flex">
    <a routerLink= "/eba/demo"
    class="lead text-right text-primary "> <button
      class="btn btn-outline-primary border-0 btn-lg">
      <span class="badge border-0 px-2 text-right">
        Demo Site</span></button>
        </a>
  </div>

  </div>