export class Status {
    code:string;
    name: string;
    desc?: string;
  
  
  constructor(code:string,name:string ) { 
    this.code= code;    
    this.name = name;
     }  
    }