import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  
  
  public editorConfig = {
    versionCheck : false,
		toolbar: [
			      {
        name: 'colors',
        items: ['TextColor', 'BGColor']
      },
      {
        name: 'align',
        items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
      },
     // '/',
      {
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting']
      },
      {
        name: 'links',
        items: ['Link', 'Unlink']
      },
      {
        name: 'paragraph',
        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
      },
      {
        name: 'insert',
        items: ['Image', 'Table']
      },
      {
        name: 'tools',
        items: ['Maximize']
      },
      {
        name: 'editing',
        items: ['Scayt']
      },
      [ 'Source' ],
			[ 'Styles', 'Format', 'Font', 'FontSize' ],
			//[ 'Bold', 'Italic' ],
      [ 'Undo', 'Redo' ], 
      [ 'Mathjax' ],
     // ['About'],

    ],
    width: '100%' ,
    height: 150,
    extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',

    // Adding drag and drop image upload.
    extraPlugins: 'print,format,font,colorbutton,justify,uploadimage,mathjax',
    enterMode : 2,
    filebrowserUploadUrl : 'rs/files/ckupload',
    //filebrowserBrowseUrl : '/browser/browse.php',
   mathJaxLib : '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-AMS_HTML,/assets/mathConfig.js',
   mathJaxClass :'equation',
   removeDialogTabs: 'image:advanced;link:advanced',
   editorplaceholder :'enter …'
   
     } ;
     


  /* old backup
  public editorConfig = {
		toolbar: [
			      {
        name: 'colors',
        items: ['TextColor', 'BGColor']
      },
      {
        name: 'align',
        items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
      },
     // '/',
      {
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting']
      },
      {
        name: 'links',
        items: ['Link', 'Unlink']
      },
      {
        name: 'paragraph',
        items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
      },
      {
        name: 'insert',
        items: ['Image', 'Table']
      },
      {
        name: 'tools',
        items: ['Maximize']
      },
      {
        name: 'editing',
        items: ['Scayt']
      },
      [ 'Source' ],
			[ 'Styles', 'Format', 'Font', 'FontSize' ],
			//[ 'Bold', 'Italic' ],
      [ 'Undo', 'Redo' ], 
      [ 'Mathjax' ],
     // ['About'],

    ],
    height: 150,
    extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',

    // Adding drag and drop image upload.
    extraPlugins: 'print,format,font,colorbutton,justify,uploadimage,mathjax',
    enterMode : 2,
    width: '100%',
    filebrowserUploadUrl : 'rs/files/ckupload',
    //filebrowserBrowseUrl : '/browser/browse.php',
   mathJaxLib : '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-AMS_HTML,/assets/mathConfig.js',
   mathJaxClass :'equation',
   removeDialogTabs: 'image:advanced;link:advanced'
     }
     */

     public withWordConfig = {
      toolbar: [

        {
          name: 'clipboard',
          items: ['PasteFromWord', '-', 'Undo', 'Redo']
        },

              {
          name: 'colors',
          items: ['TextColor', 'BGColor']
        },
        {
          name: 'align',
          items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
        },
       // '/',
        {
          name: 'basicstyles',
          items: ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting']
        },
        {
          name: 'links',
          items: ['Link', 'Unlink']
        },
        {
          name: 'paragraph',
          items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
        },
        {
          name: 'insert',
          items: ['Image', 'Table']
        },
        {
          name: 'tools',
          items: ['Maximize']
        },
        {
          name: 'editing',
          items: ['Scayt']
        },
        [ 'Source' ],
        [ 'Styles', 'Format', 'Font', 'FontSize' ],
        //[ 'Bold', 'Italic' ],
        [ 'Undo', 'Redo' ], 
        [ 'Mathjax' ],
       // ['About'],
  
      ],
      
      height: 150,
      //allowed content stop all the html tags etc filtering, doing to allow sup etc for now, fix it later with full listing and disable allowed content 
      //https://ckeditor.com/docs/ckeditor4/latest/guide/dev_allowed_content_rules.html
      allowedContent:true,
      extraAllowedContent: 'sup ; h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
  
      // Adding drag and drop image upload.
      extraPlugins: 'print,format,font,colorbutton,justify,uploadimage,mathjax',
      enterMode : 2,
      width: '100%',
      filebrowserUploadUrl : 'rs/files/ckupload',
      uploadUrl : 'rs/files/ckupload',
      pasteUploadFileApi : '',

      //filebrowserBrowseUrl : '/browser/browse.php',
     mathJaxLib : '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-AMS_HTML,/assets/mathConfig.js',
     mathJaxClass :'equation',
     removeDialogTabs: 'image:advanced;link:advanced',
     versionCheck : false

       }
    
  constructor() { }


}
