import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ExtraInfo } from '../../shared/assigner/extra-info';
import { ExtraHdrService } from '../../gen/extra-hdr.service';
import { QhFldService } from '../qh-flds/qh-fld.service';

@Component({
  selector: 'app-hdr-extra',
  templateUrl: './hdr-extra.component.html',
  styleUrls: ['./hdr-extra.component.scss']
})
export class HdrExtraComponent implements OnInit {
@Input() h:any;
@Input() ei: ExtraInfo;
@Input() parentFormGroup: FormGroup;
@Input()  fldList:string[];


  constructor(public qhf:QhFldService) { }

  ngOnInit(): void {
    if(this.h && !this.ei){
      if(!this.h.extra){this.h.extra= "{}"}  
      this.ei= JSON.parse(this.h.extra)
      }
     
 }

 
    
}
