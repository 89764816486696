import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

interface User{
  email: string;
  desc:string;
}



@Component({
  selector: 'app-mail-sender',
  templateUrl: './mail-sender.component.html',
  styleUrls: ['./mail-sender.component.scss']
})


export class MailSenderComponent implements OnInit {
  
  user: User;
 email:string;
  submitted: boolean;

  
  onSubmit() {

   this.cs.addMsg(this.email + ": mail not sent" );
   this.submitted= true;
  }

  constructor(private cs:ClientInfoService) { }

  ngOnInit(): void {
   // this.user = {};
  }

}
