import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  //https://www.codemag.com/article/1711021/Logging-in-Angular-Applications


  constructor() { }

  
log(msg: any) {
    console.log(new Date() + ": " + JSON.stringify(msg));
}

}
