import { Component, Input, OnInit } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { Observable } from 'rxjs';
import { ClientInfoService } from '../../shared/client-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QstnhdrService } from '../../qstn/qstnhdr.service';
import { ChptrService } from '../../pages/chptr-lstr/chptr.service';
import { ListerService } from '../../chat/lister.service';

@Component({
  selector: 'app-fltr-hdr-lstr',
  templateUrl: './fltr-hdr-lstr.component.html',
  styleUrls: ['./fltr-hdr-lstr.component.scss']
})
export class FltrHdrLstrComponent implements OnInit {
  
   @Input() filter:boolean;
  @Input() owner:string;
  @Input() fltrStts:string;
  
  headers$: Observable<any>;
  utube= "https://www.youtube.com/embed/";
showAdd : boolean;
  calling: boolean;
 i:number;
  user: string;
  allowUserChange: boolean;
  ovrStts: string;
  subDesc: any;
  statusFilter: any;
  statusTexts: any;
  
 get site() {return  this.cs.getSite()} ;
 get school() {return this.cs.getSchool() };
 get schlLoc() {return this.cs.getSchlLoc() };
 
get cat() {return this.cs.getCat() };
get board() {return this.cs.getBoard()};
get course() { return this.cs.getCourse()};
get feature() { return this.cs.getFeature()};
get offering() { return this.cs.getOffering()};
get subject() { return this.cs.getSubject()};
get status() { return this.cs.getStatus()};
get chapter() { return this.cs.getChapter()};
//get chapter(){return Number(this.cs.getChapter()?this.cs.getChapter():0)}

 constructor(public chs: ChptrService, private qh: QstnhdrService,private ds: BoardDataService,private cs:ClientInfoService,private router: Router,private activatedRoute:ActivatedRoute, public ls:ListerService) { }

processClick(header:any, i:number){
let url=   ['/',this.site,this.cat,this.board,this.course,header.type,this.subject,header.id];
//alert(url);
this.router.navigate(url);
}

  ngOnInit(): void {
    
   this.allowUserChange = this.cs.enable('vidBand','allowUserChange');

   this.user= this.owner;
   
    this.ovrStts = this.fltrStts?this.fltrStts:this.status;
    this.ovrStts= this.ovrStts?this.ovrStts:"status";
    
   
    this.activatedRoute.params.subscribe(({ subject }) => {
   this.calling = true;     
    if(this.filter){
      this.qh.filteredList(this.owner,this.ovrStts).subscribe(data => {
      this.headers$  = data; 
       this.subDesc= data.filter(d => d.hdrFormat=='subDesc')[0]; 
    
    });
  }
});

this.ls.getKeyFlds('chptr-flds',this.cat).subscribe((data:any) => {
  //console.log(data);
  let flds  = data.result;
  let StrArray = flds.statusFilter.split(",");
  this.statusFilter = StrArray;
  //console.log(this.primary);
  //this.secondary= flds.secondary.split(",");
 // this.additional = ['-'].concat(this.primary).concat(this.secondary);
});

this.ls.getKeyFlds('statusTexts','all').subscribe((data:any) => {
  //console.log(data);
   this.statusTexts  = data.result;
  //let StatustrArray = flds.statusFilter.split(",");
  //this.statusFilter = StrArray;
  //console.log(this.primary);
  //this.secondary= flds.secondary.split(",");
 // this.additional = ['-'].concat(this.primary).concat(this.secondary);
});
  


}

  refresh(){
    this.qh.filteredList(this.owner,this.ovrStts).subscribe(data => {
      this.headers$  = data; });
 
  }
  
  getStatusTitle(status:string){
    if(this.statusTexts && this.statusTexts[status]){
      return this.statusTexts[status];
    }
    else{
      return status;
    }
   //return  this.statusTexts[status]?this.statusTexts[status]:status;
  }

 changeUser(user:string){
   console.log('user changed '+ user);
 this.user= user;
 this.qh.filteredList(user,this.ovrStts).subscribe(data => {
  this.headers$  = data; });

}


//index and record is optional
enable(btnId:string,i?:number,hdrRec?:any){
  let component = 'vidBand';
  let enable= this.cs.enable(component,btnId,"",hdrRec);
  return this.customEnable(btnId,enable,i);
  }
  
  
  getTitle(hdr:any){
    //let status= hdr.status;
    //if(status=='outSlbs21'){ status= "Out Of Sylbs for 21"}
    let title= [hdr.year,hdr.chapterId,hdr.section,hdr.title,hdr.status].filter(Boolean).join("-") ;
    /*
    if(this.offering == "dhw"){
    title= title+ [' set on: ',hdr.formattedActionDate].filter(Boolean).join("-") ;
    }
    */
    return title;

  }
  
  

  customEnable(btnId: string, enable: boolean,i?:number) {
   
  if(enable){  }
    return enable;
  }
}
