import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-short-qstn',
  templateUrl: './short-qstn.component.html',
  styleUrls: ['./short-qstn.component.scss']
})
export class ShortQstnComponent implements OnInit {
  @Input()  q :any;
  @Input()  i :number;
  
  constructor() { }

  ngOnInit(): void {
  }

}
