
import { Component, Input, OnInit } from '@angular/core';
import { BtnService } from '../../gen/btn.service';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';

@Component({
  selector: 'app-platform-set',
  templateUrl: './platform-set.component.html',
  styleUrls: ['./platform-set.component.scss']
})
export class PlatformSetComponent implements OnInit {

  @Input() ovrSub: string;
  images: string[];
  markets: any;
  headerObj: any;
  // static EbIntroComponent: any[] | Type<any>;
  get rootUrl() { return this.cs.getRoot() };

  get site() { return this.cs.getSite() };
  get cat() { return this.cs.getCat() };
  get board() { return this.cs.getBoard() };
  get course() { return this.cs.getCourse() };
  get offering() { return this.cs.getOffering() };
  get subject() { return this.cs.getSubject() };

  headerId: number;


  constructor(private cs: ClientInfoService, private ds: BoardDataService, private fd: FixedDataService, public b: BtnService) { }

  ngOnInit() {

    // this.markets= this.fd.getMarketData(this.site);

    //let sub = this.ovrSub?this.ovrSub:'platform';
    //sub=     this.cat+ "~platform" ;
    let key = this.ovrSub ? this.ovrSub : this.cat + "~platform";

    this.ds.getCfgHeaders(this.site, this.board, this.course, 'cfg', key).subscribe(data => {
      //console.log(data);

      if ((data as any) && (data as any).dataList[0]) {
        this.headerId = (data as any).dataList[0].id;
        this.headerObj = (data as any).dataList[0];
        this.ds.getSiteQuestions(this.site, this.board, this.course, 'cfg', this.subject, this.headerId).subscribe(data => {
          this.markets = data;
        });
      }
    });

  }

  catWithPrdDesc = ['pbstr', 'sch', 'cc', 'col', 'tc', 'trng']

  getRouteUrl(category: string, extLink: string, format?: string): string {

    let ovrCat = category;
    if (this.catWithPrdDesc.includes(category)) {
      ovrCat = "prd~" + ovrCat;
    }
    return this.cs.getRouteUrl(ovrCat, extLink, format);
  }


  show(q: any) { return this.b.showQstn("slide", q) }

}
