
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";
import { environment } from './../../../environments/environment';

import { BoardDataService } from '../../board-data.service';

import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { CommonModule } from  '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-info-center',
  templateUrl: './info-center.component.html',
  styleUrls: ['./info-center.component.scss']
})
export class InfoCenterComponent implements OnInit {
  @Input() cmnts:any;
  expanded:boolean = true;

//  readonly rootUrl = environment.rootUrl;
//rootUrl: string;
images: string[];
//site:string;
slides: any;
headerObj:any;
  today: Date;
  addDate: Date;
get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get feature() { return this.cs.getFeature()};
  get rootUrl() {return  this.cs.getRoot() };

  headerId:number;
  key:string;
  data$: Observable<any>;
  calling:boolean;
  constructor(config: NgbCarouselConfig, private route: ActivatedRoute,
    private router: Router, private cs:ClientInfoService, private ds: BoardDataService,private fd:FixedDataService) { 
      config.showNavigationArrows = false;
      config.showNavigationIndicators = true;
      config.interval= 2000;
      config.pauseOnHover = true;
      //config.pauseOnFocus = false;
      

    }

 
  getRouteUrl(category:string, extLink: string): string{
    return this.cs.getRouteUrl(category, extLink);
   }


  ngOnInit() {

    this.today = new Date();  
    this.cmnts = this.cmnts.filter(c=>this.recent(c));
    
    //detail.addDate= this.datepipe.transform(myDate, 'yyyy/MM/dd')
  
    
    this.key="slides"
    if(this.feature){
     this.key = this.feature +"-" + this.key;
    } else{
      this.key = this.cat +"-" + this.key; 
    }
    this.calling = true;
    this.data$ =  this.ds.getCfgData(this.site,this.board,this.course,"cfg",this.key);
 
}

recent(c:any){
 if(c.digAnsr){ return true} 
 let addDateObj = new Date(c.addDate);
 addDateObj.setDate(addDateObj.getDate() + 7);
 //this.addDate= addDateObj;
  return this.today <=  addDateObj;

}
 
}
