import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ExtraHdrService } from '../../gen/extra-hdr.service';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';

@Injectable({
  providedIn: 'root'
})
export class ChptrService {
  hide: boolean;
  shrinkBook: string; // used for book-shrinking
  forStatus: string;
  

  get site() { return this.cs.getSite() };
  get cat() { return this.cs.getCat() };
  get board() { return this.cs.getBoard() };
  get course() { return this.cs.getCourse() };
  get feature() { return this.cs.getFeature() };
  get offering() { return this.cs.getOffering() };
  get subject() { return this.cs.getSubject() };

  get groupId() { return this.cs.getGroupId() };
  
  get signedUser() { return this.cs.getSignedUser()};
  get shrGrp() { return this.cs.getShrGrp()};

  
  crudCats = 'dgtz merge content';
  extra: any;
  //get chapter(){return Number(this.cs.getChapter()?this.cs.getChapter():0)}

  constructor(private ds: BoardDataService, public cs: ClientInfoService, private router: Router,public eh:ExtraHdrService) { }


  expiryDate(extra:string){
    return this.eh.dueDate(extra);
  }
  
  
  buildNewBstr(refId:string){
    localStorage.setItem("refBstrId", refId);  
    this.cs.navigate('multi-slctr','hwh');
    }
    

  getTestTime(extra:string){
    return this.eh.testTime(extra);
  }

  processClick(header: any, i?: number) {
    let url = ['/', this.site, this.cat, this.board, this.course, header.type, this.subject, header.id];
    return url;
   // this.router.navigate(url);
  }

  getTitle(hdr: any) {
    
    if(hdr){
    let ovrYear:string;
    if(hdr.type=="cfg") { ovrYear= "";} else{ ovrYear = hdr.year};
    let semPrefix = "sem: "; 
    let ovrSem = "";
    if(hdr.sem) {ovrSem = " sem: "+ hdr.sem}; 

    let title = [ovrYear,ovrSem, hdr.chapterId, hdr.section, hdr.title,hdr.bstrSet].filter(Boolean).join("-");
    // subject means generic list for all subject
    
    if (this.subject && (this.subject.startsWith("subject") )) {
      title = [hdr.subject,hdr.book,hdr.branch,title].filter(Boolean).join("-");
    }
    
    if ((hdr.book && !this.subject?.includes("~"))) {
      title = [hdr.book,hdr.branch,title].filter(Boolean).join("-");
    }
    

    
    if (hdr.status && hdr.status === 'outSlbs21') {
      title = title + '<span class= "badge  btn-info"> Deleted for 21 </span>';
    }
    return title;
  } else{

    let title = [this.board,this.course,this.subject].filter(Boolean).join("-");;
    return title;
  }

  }
  shrink(h:any){
  if(this.shrinkBook == h.book+h.branch){
    this.shrinkBook = null; 
  }  else{
    this.shrinkBook= h.book+h.branch; 
  }
  }

  blockStatus = ['raw','dgtz','wip','wipGood']
  
  shrinkMode(header:any,i:number,user?:string ){
    if(this.shrinkBook==header.book+header.branch){return false} else{return true}

  }

  eligibleShow(header:any,i:number,user?:string ){
    // data without type means it is supposed to be for desc etc?
        if(header.hdrFormat && header.hdrFormat == "subDesc"){
      return false;
    } 

    
      if(!header.type){
        this.cs.lgr.log(header.id + " has no type") ;
        this.hide = true;
        return false;}
      
    //enabling dgtzr workflow processing..

       if (this.cat == "mydgtzr" || this.cat == "dgtzn_mngr" ) {
       let dgtzrStatusList = ['raw','dgtz','wip','wipGood','onDemand','ready','reload','verify','test','stage','redigitize','checkImg','final','wipGood','store','onDemand'] 
      if( (true || dgtzrStatusList.includes(header.status)) && (user == "owner" || header.owner == user|| 
      header.addUser==user) ){
      return true; }
      else{
        this.hide = true;
        return false;
  
     } }
   if(this.cat == "mymkr"){
      let mymkrStatusList = ['raw','dgtz','wip','onDemand','ready','reload','verify','test','stage','redigitize','final','checkImg','wipGood','store', 'onDemand']  ;
   if( (true || mymkrStatusList.includes(header.status)) && (user == "owner" || header.addUser == user) )
   //if( header.addUser == user || (header.addUser != user && mymkrStatusList.includes(header.status)))  

   {return true} else{
      this.hide = true;
      return false;
    }

      }
    

   if (this.cat == "avl" && header.status != 'avl'){this.hide = true; return false }
    
        //dgtz should have only dgtz related status 
    
    
    
      //istore-individual store, dstore-digibeststore,cstore:combinedstore
    if ((this.cat == "istore" && (!header.school || header.school == "cmn")) || (this.cat == "ebstore" && (header.school && header.school != "cmn"))) { this.hide = true; return false }
    
    
    
      //app admin allowed  
    
    if(this.groupId=='app_admin' || this.groupId == 'crclm_mngr'||
    (header.shrGrp && (header.shrGrp=='public'||header.shrGrp == this.shrGrp)) ){return true};
    
    // owner should be allowed to see..? NO 
    //&& header.owner != this.signedUser
    
    
    if (this.blockStatus.includes(header.status) ){
     if(['mkr-pbstr','mymkr','mydgtzr','mycontent'].includes(this.cat)){
      return true
     } else{
    return false;
    } 
  }
    this.hide = false;
    if (header.hdrFormat && header.hdrFormat == "subDesc") { this.hide = true;  return false }
    
    
    //rest check for block
    return !this.enable('blockDisplay',i,header);//
    return true;
    }

    

  //index and record is optional
  enable(btnId: string, i?: number, rec?: any) {

    /*don't get tempted and comment out this again as this is optimization feature,
    basic check is at top level like only slbs will have slbs btn and then rec level check is at down level 
    but hardcoding vs config needs to be ascertained 
    below doesn't need setup in cfgtable as it is hardcoded here only for simple check to save component's rec level check
    //no rec check
    */

    /* quick check */
    /* if u don't pass header, check should be simple... need to check
    if(btnId=='sylbs'){ if (this.cat=='sylbs'){return true } else{return false}};
    if(btnId=='CRUD'){ if (this.crudCats.includes(this.cat)){return true } else{return false}};
    */
    /* quick check */


    let component = 'vidBand';

    if (btnId == 'simple') {
      return !this.cs.enable(component, 'complex', "", rec)
    }

    /* moved to client-info
    //this is groupid-btnid check
    if (btnId == 'eligible') {
      //subDesc is subject description..
      if (rec.status) { return this.cs.enable(component, this.groupId + "-" + btnId, "", rec) }
    }
    else {
      //return true;
      // -- console.log('status is empty for '+  rec);
    }
    */
   

    /*
  if(btnId=='status') 
  { console.log('hey status: '+ this.cs.enable(component,btnId));}
 */

    let enable = this.cs.enable(component, btnId, "", rec);
    //return this.customEnable(btnId,enable,i);
    return enable;
  }


  customEnable(btnId: string, enable: boolean, i?: number) {

    if (enable) { }
    return enable;
  }


  reportName(extUrl: string, rptType: string) {
    //  console.log(extUrl);
    if(extUrl){
    let rpt = '-' + rptType + '.html'
    if (extUrl.includes("input")) {
      extUrl = extUrl.replace('input', 'report')
    } else {
      var index1 = extUrl.lastIndexOf("/");
      var index2 = extUrl.lastIndexOf("\\");
      let index = index1 > index2 ? index1 : index2;
      extUrl = extUrl.substr(0, index) + "/report/" + extUrl.substr(index + 1);
    }
    // rpt has .html hence don't change sequence below
    return extUrl.replace('.html', rpt).replace('.txt', rpt);
  }
  
  else{
    return null;
  }

  
}

showWithStatus(forStatus:string){
  this.forStatus= forStatus;
}

checkForStatus(header:any){
  return !this.forStatus || header.status == this.forStatus
}

}