import { Component, Input, OnInit } from '@angular/core';
import { QstnListService } from '../../pages/qstn-list/qstn-list.service';
import { ConfigService } from '../../shared/config.service';
import { MathJaxService } from '../../shared/math-jax.service';
import { TestService } from '../test.service';

@Component({
  selector: 'app-test-dtl-btn',
  templateUrl: './test-dtl-btn.component.html',
  styleUrls: ['./test-dtl-btn.component.scss']
})
export class TestDtlBtnComponent implements OnInit {
  @Input()  q :any;
  @Input()  i :number;
  showAnsr:boolean;
  showCmnt:boolean;

  constructor(public qs:QstnListService, public ts:TestService,public cfg:ConfigService,public mj:MathJaxService) { }

  ngOnInit(): void {
  }

  setStatus(status:string){
    this.q.ansCmnts[0].status = status;
     }


}
