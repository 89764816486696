import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {

  get cat(){return this.cs.getCat()}

  constructor(private cs:ClientInfoService) { }

  ngOnInit(): void {
  }

  enable(btnId:string){
   return this.cs.enable("demo",btnId); 
  }
 
}
