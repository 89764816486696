import {Inject, Injectable, PLATFORM_ID, SecurityContext} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import { Renderer2, RendererFactory2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})


export class ScriptLoaderService {
    private renderer: Renderer2;

  constructor(
    
    @Inject(PLATFORM_ID) private platformId: Object,
    rendererFactory: RendererFactory2,
    private domSanitizer: DomSanitizer
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  load(dangerousUrl: string) {

   // console.log("Hello Prabhash.. loading...");

    if (isPlatformBrowser(this.platformId)) {
      //console.log(document.getElementById('footerNew').firstChild);
      if(!document.getElementById('footerNew').firstChild){ 
      let node: any = document.createElement('script');
      node.type = 'text/javascript';
      node.src = this.domSanitizer.sanitize(SecurityContext.HTML,this.domSanitizer.bypassSecurityTrustHtml(dangerousUrl));
      node.async = 'async'
      //node.charset = 'utf-8';
     // console.log('hey before add: ',document.getElementsByTagName('footer').length)
      document.getElementById('footerNew').appendChild(node);

     // document.getElementsByTagName('footer.')[0].appendChild(node);
      //console.log('hey Post load: ',document.getElementsByTagName('footerNew').length)
      
      ScriptLoaderService.loadGoogleAnalytics('G-3PQQQEG267');
      }

      
    }
  }

  removeFooter(){
    document.getElementsByTagName('footer')[0].remove();
    document.getElementsByTagName('footer')[0].remove();
  }

  static loadGoogleAnalytics(trackingID: string): void {

    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }

}