export class Comment {
    id: number;
    comment: string;
    trueFlag:boolean;
    //name: string;
    domain:string;
    role:string;
    status:string;
    featureType: string;
    cmntType: string;
    parentHdrId:number;
    parentDtlId: number;
    parentTable:string;
    addUser:string;
    addDate: string;
    addTime:string;
    active: boolean;
    response:string;
    rspnseUser:string
    rspnseDate:string;
    rspnseTime:string;
    source:string;
    extUrl:string;
    title: string;
    marks:string;
    correct:boolean;
    errorType:string;
    flipWindow:number;
    timeTaken:number;
    digAnsr:string;
  subject: string;
  clsSec: any;
  course: string;
  BkmCmnts:[];
  clrCmnt:[];
  ansCmnts:[];
  genCmnts:[];
  owner: string;
  email:string;
  phone:string;
  topic:string;
}
