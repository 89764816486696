
import { Component, OnInit, Input } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { Observable } from 'rxjs';
import { ClientInfoService } from '../../shared/client-info.service';
import { QstnListService } from './qstn-list.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { OfrDtlService } from '../../shared/headings/ofr-dtl.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-qstn-list',
  templateUrl: './qstn-list.component.html',
  styleUrls: ['./qstn-list.component.scss']
})

export class QstnListComponent implements OnInit {
  @Input() unitId:any;
  qstns$: Observable<any>;
  ftrDesc: any;
get site() {return  this.cs.getSite()} ;
get cat() {return this.cs.getCat() };
get board() {return this.cs.getBoard()};
get course() { return this.cs.getCourse()};
get feature() { return this.cs.getFeature()};
get offering() { return this.cs.getOffering()};
get subject() { return this.cs.getSubject()};
get betaViewer() { return this.cs.betaViewer()};
get chapter(){return Number(this.cs.getChapter()?this.cs.getChapter():0)}
calling:boolean;
showTextFile :boolean;

constructor(private route: ActivatedRoute, public qs:QstnListService,private ds: BoardDataService,private cs:ClientInfoService) {


  this.route.params.subscribe( params => {
    let chapterId= params['chapter'];
    if (chapterId> 0 && this.chapter!== chapterId){
    this.calling = true; 
    this.qstns$= this.ds.getSiteQuestions(this.site,this.board,this.course,this.offering,this.subject,this.chapter).pipe(map(out => {
    this.ftrDesc= (out as any).dtlDesc;
    //console.log( "constructor",out);
    OfrDtlService.ftrDescObj.next(this.ftrDesc);
    return out;   
  }));  
    this.qs.page = 1;
  }
  }); 

 }

 refresh(){
  this.qstns$= this.ds.getSiteQuestions(this.site,this.board,this.course,this.offering,this.subject,this.chapter).pipe(map(out => {

    this.ftrDesc= (out as any).dtlDesc;
    OfrDtlService.ftrDescObj.next(this.ftrDesc);
    //console.log("refresh",out);
    return out;   
  })); 

  this.qs.page = 1;
  this.cs.addMsg("refresh invoked" ,"toast")
 }
 
 
ngOnInit(): void {
  /*  
  if(!this.chapter){
      console.log("qstn lst data called");
       } else{
    this.calling = true;     
    this.qstns$= this.ds.getSiteQuestions(this.site,this.board,this.course,this.offering,this.subject,this.chapter) ; 
    }
    */
    
  }


 mergeQstns(){
  let qstnIdsStr = localStorage.getItem("mergeQstnIds");
let qstnIdList :number[] = qstnIdsStr.split(',').map(Number);

  console.log(qstnIdList + " will be merged");
 if(!qstnIdList) { 
   alert("No qstns to merge, please select first");
   return;
  }

  this.qs.qstnDtlService.mergeQstns(this.chapter,qstnIdList).subscribe(data => {
    
    if((data as any).status==200 && (data as any).result){
      
      this.qs.qstnDtlService.cs.addMsg("merged "+(data as any).result , 'toast');
      localStorage.removeItem("mergeQstnIds");
    } else {
      //alert("failure");
      //this.success = false;
      this.qs.qstnDtlService.cs.addMsg("failure" +(data as any).result, 'warning');
      //alert("error,try later");
    }
  
  }
  );

}

 

  //needed for text-file?perhaps not
  //nut this spedup first load in test division, may be as page-component mathjax process missing there.
//i notices text-down got fast in refresh too with this.

     ngAfterViewChecked(){
      // this.qs.typeSet("aa");
      
     }

//index and record is optional
enable(btnId:string,i?:number,rec?:any){
  let component = 'qstnList';
  let enable= this.cs.enable(component,btnId);
  return this.customEnable(btnId,enable,i);
    }
  
    customEnable(btnId: string, enable: boolean,i?:number) {
   
  if(enable){  }
    return enable;
  }


  

}
