


  
    <h2 > Change in syllabus-2019!</h2>

   <p>
  	<a class="nav-item nav-link" href="http://www.cisce.org/icse_X_Syllabus_2019.aspx
  ">Link to 2019 Latest Syllabus</a>

  
There is a bit difference in the syllabus of ICSE 2018 and ICSE 2019. If we go subject wise, following are the changes: 
<br>
1. English language : e-mail writing and notice writing has been added to the syllabus of ICSE 2019. Moreover the precis has a word limit of 50 words instead of 60. The essay will now be of 20 marks only. Other things are more or less the same as it used to be.
<br>
2. English literature: The prose and poetry book has been changed. The book's name is treasure trove. The drama part is same, i.e, The Merchant of Venice. This time the pattern of questions has been changed. There will be three questions for each part instead of two. The long answer type question will have 3 sub parts. Other things are the same.
<br>
3. Hindi: There is no change in the syllabus of hindi for ICSE 2019.
<br>
4. Maths: Though 1st chapter VAT is there in books of ICSE 2019, it is reported that questions from this chapter would not be asked in ICSE 2019.
<br>
5. Physics: The syllabus is more or less the same as ICSE 2018 syllabus.
<br>
6. Chemistry: The syllabus for Metallurgy has been reduced a lot.
<br>
7. Biology: Two chapters of biology have been replaced by two new chapters. The chapters “Aids to health” and “ Health Organisation” have been removed and two new chapters “Coordination in Plants” and “Evolution” have been added. Other parts are the same. “Sense organs” which was a part of “Nervous system” has been made an individual chapter with the same content.
<br>
8. History: It's the same syllabus.
<br>
9. Geography: It’s the same syllabus.
<br>
10. Almost in all extra subjects, there is no change in syllabus.
Hope this helps!
<br>
Additional: In English literature, there are two novels… The Call of the Wild by Jack London and Animal Farm by George Orwell and there is another drama too by Agatha Christie which The Mousetrap…. But most of the schools do not teach them therefore I forgot to mention that. From drama you can only opt for any one of the dramas, i.e, either Merchant of Venice or The Mousetrap and for section C too you can only opt for either of the three options, i.e, Collection of Short Stories or The Call of the Wild or Animal farm.

  <a class="nav-item nav-link" href="http://www.cisce.org/icse_X_Syllabus_2018.aspx
  ">To avoid doubt, 2018 -last Year's syllabus</a>
</p>
  
  <p>
   Also in case above link doesn't work then 2019 syllabus in hurry :

  
<a class="nav-item nav-link" href="http://examsteppers.com/examimages/syllabus/PHYSICS_ICSE.pdf
  "> Physics :2019</a>
   
   <a class="nav-item nav-link" href="http://examsteppers.com/examimages/syllabus/Mathematics_ICSE.pdf
  "> Mathematics :2019</a>
   
</p>