import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExtraDtlService {
  
  
  constructor() { }

  extraDtl(extra: string) {

    if (extra) {
      try {
        return JSON.parse(extra);
      } catch (error) {
        
      }
     
    }
    return null;
  }



  image(extra: string) {
    if (extra) {
      let extraJson = this.extraDtl(extra);
      if (extraJson.image) { return extraJson.image }
    }
  }
  

  titleImage(extra: string) {
    if (extra) {
      let extraJson = this.extraDtl(extra);
      if (extraJson?.titleImage) { return extraJson.titleImage }
    }
    
  }


  //to be checked
  dueDate(extra: string) {
    if (extra) {
      let extraJson = this.extraDtl(extra);
      if (extraJson) {
        return  extraJson.dueDate;
      }
    }
  }

  testTime(extra: string) {
    if (extra) {
      let extraJson = this.extraDtl(extra);
      if (extraJson.testTime) { return extraJson.testTime}
    }
  }

  getValue(extra: string,field:string) {
    if (extra) {
      let extraJson = this.extraDtl(extra);
      if (extraJson) {
        return extraJson[field];
      }
    }
  }
  


  slideImage(extra: string) {
    if (extra) {
      let extraJson = this.extraDtl(extra);
      if (extraJson.slideImage) { return extraJson.slideImage }
    }
  }


  other(extra: string) {
    if (extra) {
      let extraJson = this.extraDtl(extra);
      if (extraJson.other) { return extraJson.other }
    }
  }

  startPage(extra: string) {
    
    if (extra) {
      let extraJson = this.extraDtl(extra);
      if (extraJson && extraJson.startPage) { return extraJson.startPage }
    }
  }

}
