

<!-- titleFormat-->

<div *ngIf="hdr">
    



<div [ngSwitch]='hdr.hdrFormat'>

<ng-container *ngSwitchCase='"hide"'>  </ng-container>

<app-headings *ngSwitchCase='"crisp"' [hdr] = hdr> </app-headings>

<app-headings *ngSwitchCase='"pdf"' [hdr] = hdr> </app-headings>

<app-headings-plain *ngSwitchDefault [hdr] = hdr [q] = q > </app-headings-plain>

<!--
<div *ngSwitchDefault > 
<ng-container *ngIf= "hdr.hdrFormat == 'pdf' || hdr.status=='final'; else plain;">
    <app-headings [hdr] = hdr> </app-headings>   
</ng-container>
</div> 

<ng-template #plain>
 <app-headings-plain [hdr] = hdr [q] = q > </app-headings-plain>

</ng-template>
-->

</div>

<app-day-counter *ngIf="cs.enable('headings','dayCounter')" [h] = hdr> </app-day-counter>

</div>
