import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../client-info.service';

@Component({
  selector: 'app-hdr-view-slctr',
  templateUrl: './hdr-view-slctr.component.html',
  styleUrls: ['./hdr-view-slctr.component.scss']
})
export class HdrViewSlctrComponent implements OnInit {
  @Input() hdr:any;
  @Input() ftrDescObj:any;
  @Input() q: any;
  @Input() noSeo :boolean;
  
  constructor(public cs:ClientInfoService) { }

  ngOnInit(): void {
  }

}
