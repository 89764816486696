export class Header {
    
    id: number;
    site:string;
    board:string;
    course:string;
    sem:string;
    clsSec:string;
    type:string;
    subject: string;
    chapterId:string;
    section: string;
    qstnHdrId:number;
    tagHdrId:number;
    addUser:string;
    owner:string;
    bstrSet:string;
    status:string;
    headerId:number;
    prntHdrId:number;
    school:string;
    location:string;
    summary:string;
    extUrl:string;
    title:string;
    hdrFormat:string;
    year:string;
    book:string;
    branch:string;
    newDraft: boolean;
    extra:string;
    add
    
}