import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { TaghdrListService } from '../taghdr-list/taghdr-list.service';

@Component({
  selector: 'app-tag-optn',
  templateUrl: './tag-optn.component.html',
  styleUrls: ['./tag-optn.component.scss']
})
export class TagOptnComponent implements OnInit {
@Input() t:any;
@Input() i:number;
calling:any;

setDtl:any;
showUpload:boolean;
headerforTD: any;
setUpload:number;
listTagDtl: any;

  constructor(public tl:TaghdrListService,public cs:ClientInfoService) { }

  ngOnInit(): void {
  }

  enableCstm(btnId,t:any){

   if(t.qstnHdrId && t.qstnHdrId != '0') {
     return true;
   }
   else{
     if(this.cs.tagBased(t.type)){

       return this.cs.enable('crudList','btnId',"",t);
     }
     return false;
   }

  }
  
}
