import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusPicker'
})
export class StatusPickerPipe implements PipeTransform {
  
  transform(statuses, offering) {
        return status ? statuses.filter(status => offering.nextStatus === status) : statuses;
  }
}
