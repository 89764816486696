<div class="container" *ngIf="(questions$ | async) as contents; else loading">
    <!--
    <app-hdr-view-slctr *ngIf='contents.hdr' [hdr] = contents.hdr [ftrDescObj]='contents.result' [q] = 'contents.result'  > </app-hdr-view-slctr>
        -->
    <app-headings-plain *ngIf="contents.hdr" [hdr]=contents.hdr
        [q]='contents.result'> </app-headings-plain>

    <div *ngIf="contents.result as q">


        <div class="mb-1 text-center">

            Qstn# {{q.groupId}}

            <a *ngIf="q.prvs"
                class="btn-primary text-strong link link-secondary float-left "
                [routerLink]='router.url.replace(detailId,q.prvs)'>Prvs-Qstn</a>
            <span *ngIf='dtlsrv.cs.enable("dflt","debug")'
                class="mx-2 text-muted text-small float-left">{{q.prvs}}</span>


            <a *ngIf="q.next" class="link link-secondary float-right "
                [routerLink]='router.url.replace(detailId,q.next)'>Next-Qstn</a>

            <span *ngIf='dtlsrv.cs.enable("dflt","debug")'
                class="text-muted text-small float-right mx-2">{{q.next}}</span>

        </div>

        <app-qstn-page [list]=contents.dataList [h]=hdr [resetPage]=true>
        </app-qstn-page>


    </div>
</div>