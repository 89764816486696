<!---->

<!--uhh cicular dependency
    <app-comment-list *ngIf="showCmntView"  [featureType] = cat [cmntType]= "'H'+offering" [parentHdrId] = chapter?chapter:0 > </app-comment-list>
https://drive.google.com/open?id=1RVOGkKW9RkyqbafzUy9bfd3MyAfWQ-MX|
|safe :"url"

-->




<button *ngIf="contentType ==='IMG' || format==='IMG'"  class= "btn-md btn btn btn-outline-primary"  (click)="showImg=!showImg"> {{showImg ? 'Hide Image Preview': 'Show Image Preview'}} </button>
<!--
<div class= "" *ngIf="showImg" 
innerHTML= '<img src = {{link}} alt= "{{title}}" >' >
</div>
<br>
-->


<button *ngIf="contentType ==='P1DF'" class= "btn-md btn btn btn-outline-primary"  (click)="showPdf=!showPdf"> {{showPdf? 'Hide Pdf view': 'Show Pdf view'}} </button>



<!--

    <button *ngIf="false && showEdit && contentType ==='HTML'" class= "btn-md btn btn btn-outline-primary"  (click)="showHtml=!showHtml"> {{showHtml? 'Hide Html view': 'Show Html view'}} </button>



<button *ngIf="showEdit && contentType ==='HTML'" class= "btn-md btn btn btn-outline-primary"  (click)="showText=!showText"> {{showText? 'Hide TEXT view': 'Edit Text view'}} </button>


<app-html-viewer *ngIf="contentType ==='HTML' && showHtml && !showText" [headerObj] = headerById > </app-html-viewer>
<br>
-->

<img *ngIf="showImg" [src] = 'link|safe:"url"' alt= "{{title}}" >

<app-pdfviewer  *ngIf="showPdf" src= '{{link}}' [startPage] = startPage > </app-pdfviewer>



<button *ngIf='showEdit && (contentType ==="HTML"  || (contentType ==="TEXT" || contentType ==="text") )' class= "badge text-center btn-outline-primary" (click)= "showTextEditor= !showTextEditor" > Text Editor   </button>
    

<div *ngIf= "false" class="container mb-1">
    <div class="row">
      <div class="col text-center">
        
        <button *ngIf='showEdit && (contentType ==="HTML"  || (contentType ==="TEXT" || contentType ==="text") )' class= "badge text-center btn-outline-primary" (click)= "showTextEditor= !showTextEditor" > Text Editor   </button>
        <!--
        <button *ngIf="showEdit" class= "btn btn-outline-primary badge "  (click)="showText=!showText"> {{showText? 'Hide HTML Editor': 'HTML Editor'}} </button>
        -->
    </div>
    </div>
  </div>

  

<app-ngs-monaco *ngIf='showTextEditor && ((contentType ==="TEXT" || contentType ==="text")  || contentType ==="HTML")'  [hdr] = headerObj  [fileLink] = extLink > </app-ngs-monaco>

<app-text-edtr *ngIf='!showTextEditor && ((contentType ==="TEXT" || contentType ==="text")  || contentType ==="HTML")' [headerObj] = headerObj > </app-text-edtr>






<a class= "btn-md btn btn-primary badge" *ngIf="contentType ==='pdf' || contentType ==='OTHR'" (click)="makeAbsoluteUrl(link)"  [href] = 'link|safe :"url"' target="_blank" >{{title? 'Download' : 'Download Link'}}</a>


<a class= "btn-md btn btn-primary badge" *ngIf="contentType ==='EXT'" (click)="makeAbsoluteUrl(link)"  [href] = link target="_blank" >{{title? title + ' Link': 'Go To Link'}}</a>



<app-vid-player *ngIf='contentType=="intVid"' [vidUrl]= intVidUrl> </app-vid-player>


<app-vid-player *ngIf='format=="intVid" ' [vidUrl]= extLink> </app-vid-player>