import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrepAsgnrService } from '../../shared/assigner/prep-asgnr.service';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { TagService } from '../../shared/tag.service';
import { HeaderService } from '../../tag/header.service';
import { Header } from '../../tag/header';
import { OfrDtlService } from '../../shared/headings/ofr-dtl.service';
import { ViewService } from './view.service';

@Component({
  selector: 'app-view-lstr',
  templateUrl: './view-lstr.component.html',
  styleUrls: ['./view-lstr.component.scss']
})
export class ViewLstrComponent implements OnInit {
  @Input() hdr: any;
  @Input() i: number;;
  get signedUser() { return this.cs.getSignedUser() }
  get site() { return this.cs.getSite() }
  get cat() { return this.cs.getCat() }
  get board() { return this.cs.getBoard() }
  get course() { return this.cs.getCourse() }
  get offering() { return this.cs.getOffering() }
  get subject() { return this.cs.getSubject() }
  get school() { return this.cs.getSchool() }
  get schlLoc() { return this.cs.getSchlLoc() }
  get learnMode() { return this.cs.getLearnMode() }
   get groupId() { return this.cs.getGroupId() }
   get grpAsoId() { return this.cs.grpAsoId }
  activeRec: any;

  more:boolean;
  
  constructor(private route: ActivatedRoute, private router: Router, private dataService: BoardDataService, public pa: PrepAsgnrService,public ods:OfrDtlService,public vs:ViewService,
     private cs: ClientInfoService, private fd: FixedDataService, public ts: TagService, private tagHdrService: HeaderService) { }


  ngOnInit(): void {
  }

  // !(enable('takeTest',i,hdr)||enable('wrkTest',i,hdr)||enable('mockTest',i,hdr)) && enable('showDetail',i,hdr)"

  enable(btnId: string, i?: number, hdrRec?: any) {

    if (btnId == "hideDetail") {
     

      if (this.groupId == 'learner' && hdrRec.status == 'crct') {
        if(this.school ==="lwb"){
        return false;}
     
      } 

      if (this.groupId == 'learner' && hdrRec.status == 'crct') {
        if(this.school ==="prnt"){
        return false;}
      } 

    }
   

    let component = 'viewList';

    return this.cs.enable(component, btnId, '', hdrRec);
  }

  startLearning(hdr: any, i: number) {
    this.pa.autoPost(hdr, 'avl').subscribe(data => {
      if ((data as any).status === 200) {
        console.log(data);
        console.log("Success:posting done");
        this.router.navigate([this.getChapterUrl('', (data as any).result)]);
      }
      else {
        alert("error,try later");
      }

    });

  }
  // please assign eps code starting with q
  takeTestOld(hdr: any, i: number) {
    if (hdr.status = "asgn") {
      // this.prepTest(hdr, i)
      this.tagHdrService.createHeaderFromAssigner(hdr)
        .subscribe(data => {
          if ((data as any).status === 200) {
            console.log(data);
            console.log("Success:posting done");
            this.router.navigate([this.getChapterUrl('', (data as any).result)]);
          }
          else {
            alert("error,try later");
          }

        });

      return;
    }
    else {
      this.cs.addMsg("Test is not setup..")
    }

  }



  continueTest(hdr: any, i: number) {
    if (hdr.status = "wrk") {
      console.log("inside hdr actual test!");
      this.cs.openNewWindow(this.cs.getRoot() + this.getChapterUrl('', hdr));
    }
  }




  //status must be asgn for taking test??
  prepTest(hdr: any, i: number) {
    if (hdr.status && hdr.status == "asgn") {
      if(hdr.type.includes("-tg") || hdr.type.includes("-qg")){
       let newType = this.cs.getJustOffering(hdr.type.replace("-tg", "ti").replace("-qg","-qi"));
        hdr.status = 'wrk';
        hdr.type = "t" + hdr.status + "-" + newType;
      }
      else{
      let newType = "t" + (hdr.type.replace("tasgn-", "")).substring(1);
      hdr.status = 'wrk';
      hdr.type = "t" + hdr.status + "-" + newType;
      }

    }
    else {
      console.log("pls check asgned hdr:" + hdr);
      this.cs.addMsg("non-assigned Not eligible for test");
    }
    hdr.prntHdrId = hdr.id;
    hdr.owner = this.signedUser;
    this.startLearning(hdr, i);
  }

  // mess??? clean this..

  /* check above..
      hdr.type = hdr.type.replace('', 'twrk-t');
      hdr.type = hdr.type.replace('tasgn-t', 'twrk-t');
      hdr.type = hdr.type.replace('tasgn-p', 'twrk-t');
      */


  startLearningOld(hdr: any, i: number) {
    console.log(hdr);


    //based on original type

    if (this.tagBased(hdr.type)) {
      hdr.tagHdrId = hdr.tagHdrId ? hdr.tagHdrId : hdr.id;
    } else {
      hdr.qstnHdrId = hdr.QstnHdrId ? hdr.QstnHdrId : hdr.id;
    }
    //put 
    if (hdr.status && hdr.status != 'avl') {
      //this.cs.addMsg("warning:hdr.type called for creation with non 'avl' status? please check");
      // return;
    }
    //for normal homework test
    if (hdr.status && (hdr.status == 'asgn' || hdr.status == 'avl')) {
      this.cs.addMsg("Setup not correct: Please contact support" + hdr.status);
      console.log("asgn should come as wrk here, why new allocation rec for already assigned/working rec? " + hdr);
      return;

    }
    // for learning normal..
    if (!hdr.status || hdr.status != 'wrk') {

      hdr.type = 'tavl-' + hdr.type;
      hdr.status = "avl";
    }

    hdr.site = this.site;
    //hdrId is unit Hdr Id 
    console.log(hdr);
    hdr.hdrId = hdr.hdrId ? hdr.hdrId : hdr.id;
    this.tagHdrService.createHeaderFromAssigner(hdr)
      .subscribe(data => {
        if ((data as any).status === 200) {
          console.log(data);
          console.log("Success:posting done");
          this.router.navigate([this.getChapterUrl('', (data as any).result)]);
        }
        else {
          alert("error,try later");
        }

      });
  }

  //idea is backend will take taghdrDto and create new tag hdr rec from that...
  // just change tagtype to TAhwh? (T) tag based - A(Activate) -- hwh or ?
  //addTaghdr(hdr);  extend...


  startWork(hdr: any, i: number) {
    if (hdr.type.includes('dhw')) {
      hdr.type = 'twrk-thw';
      hdr.status = 'wrk';
    }
    hdr.prntHdrId = hdr.id;
    hdr.owner = this.signedUser;
    this.startLearning(hdr, i);
  }


  tagBased(offering: string) {
    return this.cs.tagBased(offering);
  }


  storeToAvailOld(hdr: any, i: number) {
    //based on original type
    if (this.tagBased(hdr.type)) {
      hdr.tagHdrId = hdr.tagHdrId ? hdr.tagHdrId : hdr.id;
    } else {
      hdr.qstnHdrId = hdr.QstnHdrId ? hdr.QstnHdrId : hdr.id;
    }
    //put 
    if (hdr.status && hdr.status != 'store') {
      this.cs.addMsg("warning:hdr.type called for creation with non 'avl' status? please check");
      return;
    }

    if (hdr.status) {
      hdr.type = hdr.type.replace(hdr.status, 'avl');
    }
    else {
      hdr.type = 'tavl-' + hdr.type;
    }
    hdr.status = "avl";

    hdr.site = this.site;
    //hdrId is unit Hdr Id 
    //console.log(hdr);
    hdr.hdrId = hdr.hdrId ? hdr.hdrId : hdr.id;
    this.tagHdrService.createHeaderFromAssigner(hdr)
      .subscribe(data => {
        if ((data as any).status === 200) {
          console.log(data);
          console.log("Success:posting done");
          this.router.navigate([this.getChapterUrl('', (data as any).result)]);
        }
        else {
          alert("error,try later");
        }
        //idea is backend will take taghdrDto and create new tag hdr rec from that...
        // just change tagtype to TAhwh? (T) tag based - A(Activate) -- hwh or ?
        //addTaghdr(hdr);  extend...
      });
  }



  getChapterUrl(type: string, hdr: any, overrideCat?: any) {
    let cat = this.cat;
    if(hdr.type && hdr.type.includes('vid')){cat= 'vid'} // special view for video

    if (overrideCat) {
      cat = overrideCat;
    } else {
      //console.log(this.cat +"  " +hdr.status )
      if ((this.cat == "te") && (hdr.status != 'wrk')) {
        cat = 'gpb';
      }
    }

    if (!type) {
      type = hdr.type;
      if (!type) {
        type = this.offering;
      }
    }
    let signedUser = this.cs.getSignedUser();
    if (type == 'tasgn1-dhw') {

      hdr = this.getWrkTaghdrByNum(hdr.id, signedUser);
      // alert("Hello from" );
    }

    //let mode = this.getContentMode(type, hdr);

    return ['/', this.site, cat, this.board, this.course, type, this.subject, hdr.id].filter(Boolean).join('/');
  }


  getWrkTaghdrByNum(hdrId: any, signedUser: string) {
    this.dataService.getUnitHeaders(this.offering, hdrId, signedUser).subscribe(data => {
      return (data as any).dataList[0];

    });


  }



  // may move to contentList-customEnable??
  getContentMode(type: string, hdr: string) {
    return this.cs.getLearnMode();
  };

// make it obsolete??
showMySub(hdr:any){
this.ods.setChapHdr(hdr);
}


  processClick(hdr: any, i: number) {
    this.router.navigate([this.getChapterUrl('', hdr)]);
    //alert(this.cat);
    if (this.cat == "ee") {
      this.router.navigate([this.getChapterUrl('', hdr)]);
    }
    if (this.cat == "et") {
      this.startLearning(hdr, i);
    }
    if (this.cat == "te") {

      //this.takeTest(hdr, i)
    }
    //getRouteUrl(category:string, extLink: string): string{
  }


  //send testMode like cat- 'te'/' 'hwrk'
  createNBrowse(hdr: Header, i: number, status: string, overCat: string) {
    console.log(hdr);
    /*
    if(hdr.owner == this.signedUser){
    let offering = this.hdr.type.replace(this.hdr.status, status);
    this.tagHdrService.changeStatus(this.hdr.id,offering,status).subscribe(data => {
      if ((data as any).status === 200) {
       // this.message = "successful"
       hdr.type= offering;
       hdr.status= status;
      this.showChptr(hdr, overCat)
      } 
      else{
        alert("error,try later");  
      }
    });

    }
      else{
        */
      this.pa.autoPost(hdr, status).subscribe(data => {
      if ((data as any).status === 200) {
       // console.log(data);
       // console.log("Success:posting done");
        //this.router.navigate([this.getChapterUrl('', (data as any).result),testMode]);
        this.showChptr((data as any).result, overCat)
      }
      else {
        alert("error,try later");
      }
    });
 // }


  }

  
  associatedWithHdrGrp(hdr:any) {
   let associationId= hdr.hdrId?hdr.hdrId:hdr.id;
  this.showChptr(hdr,"asoGrp","type",associationId) 
  }
  
  showChptr(t: any, overCat?: string, overType?: string, overId?: number) {
    var cat = "";
    var type = t.type;
    var id = t.id;

    if (overId) { id = overId; }
    if (overCat) { cat = overCat; }
    if (overType) { type = overType; }

    var chptrUrl = this.cs.getRouteUrl(cat, type + "/" + t.subject + "/" + id);
    //console.log(chptrUrl);
    this.showMySub(t)
    this.router.navigateByUrl(chptrUrl);
  }

  //intended for crclm_mngr cum site_ownr
  showOriginalChptr(t: any, overCat?: string, overType?: string) {
    let overId: number;
    t.qstnHdrId ? overId = t.qstnHdrId : (t.tagHdrId ? overId = t.tagHdrId : t.id);
    //console.log(t);
    if (t.qstnHdrId) {
    overType = t.type.replace('t' + t.status + '-', "")
    }
    this.showChptr(t, overCat, overType, overId);
  }

  
  //call with overType = "te"
  showChptrTest(t: any, overCat?: string, overType?: string) {
    var cat = "";
    var type = t.type;

    if (overCat) { cat = overCat; }
    if (overType) { type = overType; }

    var chptrUrl = this.cs.getRouteUrl(cat, type + "/" + t.subject + "/" + t.id);
    //console.log(chptrUrl);
    //  this.router.navigateByUrl(chptrUrl);
    this.cs.openNewWindow(this.cs.getRoot() + chptrUrl);
  }




}
