import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BtnService } from '../../gen/btn.service';
import { ChptrService } from '../chptr-lstr/chptr.service';

@Component({
  selector: 'app-complex-chptr',
  templateUrl: './complex-chptr.component.html',
  styleUrls: ['./complex-chptr.component.scss']
})
export class ComplexChptrComponent implements OnInit {
  @Input() header: any;
  @Input() refresh: number;
  @Input() i: number;
  actDate = new Date();
  nextDate = new Date();
  extra:any;
 showGroup:boolean;
  ownUpdate: boolean;
 
 
  constructor(public s: ChptrService,public b:BtnService) { }

  ngOnInit(): void {
    if(this.header && (this.header.owner ==this.s.cs.ss.getSignedUser() ||
  (!this.header.owner && this.header.addUser ==this.s.cs.ss.getSignedUser())) ){
     this.ownUpdate= true;
  }
      }

 
 

  getNextDate(lastDate: string, frequency: number) {
    if (frequency) {
      let realDateObj = new Date(lastDate);
      return this.actDate.setDate(realDateObj.getDate() + frequency);
    }

  }

  
  show(btnId){
   
    let component = 'complexChptr';
    if(this.ownUpdate){  
     return this.s.cs.enable(component,btnId,null,this.header);
    } else{
     
    return this.b.show(component,btnId,this.header); 
    
    }

    return this.b.show('complexChptr',btnId, this.header); 
   
    }

    

}
