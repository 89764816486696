<app-navbar></app-navbar>
<!-- {{(headerById.dataList[0]).id}} -->

<div  id="qstnSet" class="row" *ngIf="headerById && quizService.qns && this.quizService.qnProgress != qstnCount">
  <div class="col s8 offset-s1">
    <span>Time Elapsed : {{quizService.displayTimeElapsed()}}</span>
    <div class="progress">
      <div class="determinate" [style.width.%]="(quizService.qnProgress+1)*qstnCount"></div>
    </div>
    <div class="card blue-grey darken-1">
      <div class="card-content white-text">
        <!-- <span class="card-title">{{quizService.qnProgress+1}}</span> -->
        <span class="card-title">
        {{quizService.qns[quizService.qnProgress].qnId}}-
        {{quizService.qns[quizService.qnProgress].qnSubId}}

        </span>


        <div *ngIf="quizService.qns[quizService.qnProgress].instruction">
          <p innerHTML= "{{quizService.qns[quizService.qnProgress].instruction}}"> </p>
        </div>
          --
        <p innerHTML= "{{quizService.qns[quizService.qnProgress].qn}}"> </p>


        <div *ngIf="quizService.qns[quizService.qnProgress].ImageName">
          <img class="center" [src]="quizService.rootUrl+'/Images/'+quizService.qns[quizService.qnProgress].ImageName" style="width:350px;height:200px">
        </div>
      </div>
      <div class="card-action">
        <ul class="collection answer">
          <ng-container *ngFor="let option of quizService.qns[quizService.qnProgress].options;let i = index">
            <li *ngIf="option != null" class="collection-item" (click)="Answer(quizService.qns[quizService.qnProgress].QnID,i,quizService.qns[quizService.qnProgress].id)"
              innerHTML = "{{option}}">
            </li>

          </ng-container>

            <div *ngIf="!quizService.qns[quizService.qnProgress].options && quizService.qns[quizService.qnProgress].qn" class="collection-item text-center" (click)="Answer(quizService.qns[quizService.qnProgress].QnID,i,quizService.qns[quizService.qnProgress].id);typeSet();"
            innerHTML = "write answer and click..."> <button class = "btn-primary">Write answer on Paper and click and upload at last step...</button>
            </div>
            
                <app-file-upload *ngIf='quizService.qnProgress == qstnCount-1'> </app-file-upload>

            <div *ngIf="!quizService.qns[quizService.qnProgress].qn" class="collection-item text-center" (click)="Answer(quizService.qns[quizService.qnProgress].QnID,i);typeSet();"
            innerHTML = "write answer and click..."> <button class = "btn-primary">Read Instruction carefully and answer following:</button>


          </div>

        </ul>
      </div>
    </div>

  </div>
</div>
<!--
<button (click)="typeSet()">Refresh View </button>
-->
