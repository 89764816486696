

<div class = "text-center h3"> Express Menu</div>


<div class="container row ">
<div  *ngFor="let s of subjects;let i = index; ">
   
<button class="btn-md mx-3 btn-primary "  (click) =  'fetchSubjectData(s)' >{{s}}</button>    
</div>       
</div>


<div class="card card-primary">
   
    <div class="card-body p-5">

        
<div id= "aa" *ngFor="let d of doubts;let i = index; ">
  <br>
  <tr>
   
    <button (click)= 'showQstnI == i ? showQstnI =-1: showQstnI = i;typeSet(); ' class="pt-1 btn-md btn-outline-primary">Related {{showQstnI==i? "Question-":"Question+"}}
    </button>
    <app-view-qstn-dtl  *ngIf="showQstnI == i" [detailId] = 'd.parentDtlId' [i]=i ></app-view-qstn-dtl>

    


    <div  class="bg-color ">
      <app-cmnt-view  [c]=d class= "row" > </app-cmnt-view>
      <app-cmnt-rspnse class= "row" [c] =d> </app-cmnt-rspnse>
  </div>
  
   <app-cmnt-crud [c]=d >  </app-cmnt-crud>  
  </tr>
  <tr >


      
</  div>