export class ExtraInfo {
    domain:string;
    clsSec:string;
    id: number;
    owner:string;
    addUser:string;
    status:string;
    dueDate:Date;
    info:string;
    mustFlag:string;
    testTime:number;
    automate:string;
    cfgFldr:string;
    createNew:string = "true";
    titleImage:string;
}

