import { Component, OnInit, Input } from '@angular/core';
import { Detail } from '../detail';
import { DetailService } from '../detail.service';
import { Router } from '@angular/router';
import { HeaderService } from '../header.service';


@Component({
  selector: 'app-add-detail',
  templateUrl: './add-detail.component.html',
  styleUrls: ['./add-detail.component.scss']
})
export class AddDetailComponent implements OnInit {
  @Input() iDetail :Detail;
  selectedSetId: number;
  public detail: Detail = null;
  submitted = false;
  constructor(private detailService: DetailService,
    private router: Router) { }

  ngOnInit() {
    HeaderService.updateDhwHdrId.subscribe(res=>{
      this.selectedSetId= res;  
       alert(res + " from tag add-detail: "+ this.selectedSetId); 
      });
    this.createDetail();
  }

  createDetail(): void {
    this.submitted = false;

    if(this.iDetail){
      this.detail = this.iDetail;
    } else{
     this.detail = new Detail();
    }
    //this.detail = new Detail();
    
  }

  save() {
       //data => console.log(data),
    this.detailService.createDetail(this.detail)
      .subscribe( error => console.log(error));
    this.detail = new Detail();
    
   this.gotoList();
    }

  onSubmit() {
    this.submitted = true;
    this.save();    
  }


  gotoList() {
   alert("detail added");
   DetailService.updateList.next(true);
   
   // this.router.navigate(['//eba//digibest//features//details']);
  }
}
