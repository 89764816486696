import { Injectable } from '@angular/core';


import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListerService } from '../../chat/lister.service';
import { ClientInfoService } from '../client-info.service';
import { FixedDataService } from '../fixed-data.service';
import { PropFromUrlService } from '../prop-from-url.service';
import { SecurityService } from '../security.service';
import { ClassList } from './class-list';
import { ExamList } from './exam-list';
import { SectionList } from './section-list';
import { SubjectObj } from './subject-obj';


@Injectable({
  providedIn: 'root'
})
export class SelectorService {


  list: ExamList[];

  selectedExam: ExamList;
  selectedClass: ClassList;
  selectedSection: SectionList;
  selectedSubject: string;

  bypassClsSec: boolean;
  subjectList: any;
  selectedOffering: string;
  subjects: any;
  offerings: any;
  get unSigned() { return this.cs.isUnsigned() }
  get site() { return this.cs.getSite() }
  get cat() { return this.cs.getCat() }

  get board() { return this.cs.getBoard() }
  get course() { return this.cs.getCourse() }

  get feature() { return this.cs.getFeature() }
  get offering() { return this.cs.getOffering() }
  get subject() { return this.cs.getSubject() }
  get justSubject() { return this.cs.getJustSubject() }
  get usrSubject() { return this.cs.getUsrSubject() }
  get clsSec() { return this.cs.getClsSec() }
  get bypassclsSec() { return !this.ss.getUserClsSec() }
  get betaViewer() { return this.cs.betaViewer() }

  get root() { return this.cs.getRoot() }


  constructor(private httpClient: HttpClient, private router: Router, public cs: ClientInfoService, private prop: PropFromUrlService, private fd: FixedDataService, public ss: SecurityService, public ls: ListerService) { }

  ngOnInit(): void {
    /*
      let justSite = this.cs.getJustSite();
      var  url = this.root+ `/etl/property/ang/${justSite}/ExamList.json`;
    */

    let propFile = 'ExamList.json';
    this.prop.getPropList(propFile).subscribe(data => {
      //this.httpClient.get(url).subscribe(data =>{
      // console.log(data);
      (this.list as any) = data;
      if (this.list && !this.unSigned) {
        this.selectedExam = this.getUserExam();
        this.selectedClass = this.getUserClass();
        this.selectedSection = this.getUserClsSec();

         this.selectedSubject =  this.cs.getUsrSubject(); 

         
      }

      this.subjectList = this.ls
        .getSubjectList(this.offering)
        .subscribe((data: any) => {
          console.log(data);
          this.subjectList = data.result;
        });


    })


    /*this.prop
       .getListOfGroup(url)
       .subscribe(
         data => {
           console.log(data);
         },
         err => {
           console.log(err);
         }
       );
      */




  }




  getClasses(selectedBoard: ExamList) {
    return this.list.find(e => e === selectedBoard);
  }

  getUserExam() {
    return this.list.find(e => e.board === this.board);
  }

  getUserClass() {
    if (this.selectedExam) {
      return this.selectedExam.courses.find(e => e.course === this.course);
    }

  }

  getUserClsSec() {
    let clsSecForSubject = this.clsSec;
    // this.bypassClsSec= true;
    if (clsSecForSubject == 'clsSec') { clsSecForSubject = 'cmn'; }
    if (this.selectedClass && this.selectedClass.sections) {
      return this.selectedClass.sections.find(e => e.clsSec === clsSecForSubject);
    }
    return null;
  }

  getSecText(clsSecCode) {

    if (clsSecCode == 'clsSec') {
      if (this.cs.getJustSite() == 'ebc') { return "batch" }
      if (this.cs.getJustSite() == 'ebs') { return "Section" }
    }
    return clsSecCode;
  }

  getUserSubject() {
    if (this.usrSubject) {
      return this.selectedSection.subjectDtos.find(e => e.name === this.usrSubject);
    }
  }


  browseClass(board: string, course: string, subject?: string) {
    this.cs.setBoard(board);
    this.cs.setCourse(course);
    let urlStr = "/" + this.cs.getSite() + "/" + this.cat;
    if (this.feature) {
      urlStr = urlStr + "/features/" + this.feature;
    }
    let boardCourse = "/" + board + "/" + course;
    urlStr = urlStr + boardCourse;

    if (this.offering) {
      urlStr = urlStr + "/" + this.offering;

    }


    this.cs.addMsg(board + "-" + course + " selected", 'toast');
    this.router.navigateByUrl(urlStr);

  }

  setClsSec(clsSec: string) {
    this.cs.setClsSec(clsSec);
  }

  browseOffering(ofr: string) {
    this.selectedOffering = ofr;

    this.browseSubject(this.subject, ofr);
  }


  browseSubject(subject: string, ovrOfr?: string) {
    this.selectedSubject = subject;
    let ofr = this.offering;
    if (ovrOfr) { ofr = ovrOfr }

    let urlStr = "/" + this.cs.getSite() + "/" + this.cat;
    if (this.feature) {
      urlStr = urlStr + "/features/" + this.feature;
    }
    let boardCourseSub = "/" + this.board + "/" + this.course;
    urlStr = urlStr + boardCourseSub;

    if (this.offering) {
      urlStr = urlStr + "/" + ofr;

    }

    if ((this.offering || this.feature)) {
      urlStr = urlStr + "/" + subject;
    }

    this.cs.setUsrSubject(subject);

    this.cs.addMsg(subject + " selected", 'toast');
    this.router.navigateByUrl(urlStr);
    /*
    .then(() => {
      window.location.reload();
    });
    */

    //above.then makes it little slow, change to select tag type logic later...
  }




  getSubjectList(type?: string) {
    this.ls
      .getSubjectList(type)
      .subscribe((data: any) => {
        // console.log(data);
        this.subjects = data;

        if (!this.subjects.includes(this.selectedSubject)) {
          // fix subjectDefault in subject component later
         // console.log('selectedsubject is not in new type');
          this.selectedSubject = ""
        }
      });

  }

  getTypeList(selSub?: string) {
    //console.log("subject is: " +selSub );
    this.ls
      .getTypeList(selSub)
      .subscribe((data: any) => {
        //console.log(data);
        this.offerings = data;
      });
  }


}

















  //list1 not used, moving to property file;
/*
  list1: ExamList[] =
    [
      {
        "board": "CBSE",
        "classes": [
          {
            "class": "IX",
            "sections": [
              {
                "clsSec": "A", "subjects": [{ "name": 'Physics', "teacher": "pshalini" },
                { "name": 'Chemistry', "teacher": "pmishra" }]
              }],
          },

          {
            "class": "X",
            "sections": [
              {
                "clsSec": "A", "subjects": [{ "name": 'Physics', "teacher": "pshalini" },
                { "name": 'Chemistry', "teacher": "pmishra" }]
              }],
          }
        ]
      },

      {
        "board": "ICSE",
        "classes": [
          {
            "class": "X",
            "sections": [
              {
                "clsSec": "A", "subjects": [{ "name": 'Physics', "teacher": "pshalini" },
                { "name": 'Chemistry', "teacher": "pmishra" }]
              }],
          }
        ]
      },
       {
        "board": "civil",
        "classes": [
          {
            "class": "II",
            "sections": [
              {
                "clsSec": "A", "subjects": [{ "name": 'Physics', "teacher": "pshalini" },
                { "name": 'Chemistry', "teacher": "pmishra" }]
              }
              ,
              {
                "clsSec": "B", "subjects": [{ "name": 'Physics', "teacher": "pshalini" },
                { "name": 'Chemistry', "teacher": "pmishra" }]
              }
            ],
          }
        ]
      }
    ];
  */