import { Component, OnInit, Input } from '@angular/core';
  import { Header } from '../header';
  import { QstnhdrService } from '../qstnhdr.service';
  import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from '../../shared/file.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { NgForm } from '@angular/forms';
import { ExtraInfo } from '../../shared/assigner/extra-info';
import { ExtraHdrService } from '../../gen/extra-hdr.service';
import { ChptrService } from '../../pages/chptr-lstr/chptr.service';

@Component({
  selector: 'app-qstnhdr-upd',
  templateUrl: './qstnhdr-upd.component.html',
  styleUrls: ['./qstnhdr-upd.component.scss'],
  providers : [NgForm]
})
export class QstnhdrUpdComponent implements OnInit {
  
  @Input()  id: number;
  submitted:boolean;
  header:any;
  ei:ExtraInfo;
  jsonUpdate:boolean;
  
  constructor(public chptrSrv: ChptrService, private headerService: QstnhdrService,private route: ActivatedRoute,
    private router: Router,public cs:ClientInfoService,public eh:ExtraHdrService) {

     }

  ngOnInit() {

    this.submitted = false;
   // this.header = new Header();
    if(!this.id){
    this.id = this.route.snapshot.params['id'];
    this.cs.offering = this.route.snapshot.params['offering'];
    }
    
    if(this.id){
      this.headerService.get(this.id)
      .subscribe(data => {
       // console.log(data)
        this.header = data;
        if(this.header?.extra){
          this.ei = this.eh.extraHdr(this.header.extra);
         }
      
        //console.log(this.header)
        
      }, error => {this.cs.addMsg("Some problem in backend?");console.log(error)});
    
    FileService.updateUploadUrl.subscribe(res=>{
      //alert("inside comnt srvce-updte lst: "+ res);
      this.header.extUrl = res})
  
  }
}
  

  onSubmit(h:any) {
    this.submitted = true;
    this.update(h);
  }


  gotoList() {
  // alert("header added");
  //this.cs.addMsg("header updated",'toast');
   QstnhdrService.updateList.next(true);

   // this.router.navigate(['//eba//digibest//features//headers']);
  }



  update(h:any) {
    //console.log(h);
    
    if(this.ei){
      this.header.extra= JSON.stringify(this.ei);
     }
  

    this.headerService.update(h.id, this.header)
      .subscribe( data=>{
       
        if(data && data.status== 200){
          alert( data.message  + h.id  );
        this.gotoList();
        } else{
          alert(data.message +  h.id );
          
        }
    
      } , error => {
        console.log(error);
        this.cs.addMsg("header id: " + h.id + " - " +  error.statusText,"danger");
        this.header = new Header();
        this.gotoList();
         }
      );
    }

  delete(id:number) {
     this.headerService.delete(id)
       .subscribe(data=> {
      if(data.status= 200){
        alert("header id: " + id +  data.result);
      this.gotoList();
      } else{
        alert("header id: " + id +  data.statusText  + "something went wrong");
        
      }
  
       }
         , error => console.log(error));
     this.header = new Header();
      }

   enable(btnId:string) {
    return true;
   }

   browseDetail(header:any) {
    
    let detailUrl=this.router.url.replace("hdr","detail") 
     this.router.navigateByUrl(detailUrl);
   }

   getTable(h){
    if(this.cs.tagBased(h.type)){
      return "taghdr"
    } else{
      return "qstnhdr"
      
    }
  
    }
  }



