<div *ngIf="calling; else noCall"> 

    <div *ngIf="(data$ | async) as d; else loading">
        
      <ng-container *ngIf="d.dtls && d.dtls.length; else noItems">
          
        <div class=" text-light text-quote font-italic text-center bg-primary " [innerHTML] = d.hdr.title>  
        </div>

            <ngb-carousel  id="myCarousel">
            <div>
          <div *ngFor="let s of d.dtls; let i= index;" class="container row">
            
            <div *ngIf= "show(s)">
           
              <ng-template ngbSlide>

                <app-problem-space  *ngIf = 'cs.enable("ebhome","problem")' [q] = s> </app-problem-space>
  
              </ng-template>
              </div>
           </div> 
           <a class="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
            <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="right carousel-control" href="#myCarousel" role="button" data-slide="next">
            <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>         
      </div>
    </ngb-carousel>
        
  
   
        </ng-container>
     
        
        <ng-template #noItems>*</ng-template>
       </div>
       <ng-template #loading>loading slides...</ng-template>
    </div>
    <ng-template #noCall>!</ng-template> 
  
  
  
 