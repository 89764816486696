
<!-- submit() -->
 
    <form *ngIf="!uploaded" [formGroup]="myForm" (ngSubmit)="uploadFile()">
  
    
    <div *ngIf= "showHeaderId()" class="hidden form-group">
        <label for="headerId">HeaderId</label>
        <input 
            formControlName="headerId"
            id="headerId" 
            type="text" 
            class="form-control"
            value= {{iHeader.id}}>
       </div>
       
       <div *ngIf= 'iHeader' class="form-group">
        <label for="uploadFldr">upload-Fldr</label>
        <input 
            formControlName="uploadFldr"
            id="uploadFldr" 
            type="text" 
            class="form-control"
            value= {{dfltUpldFldr(iHeader)}}>
       </div>
    

       
    
    <div class="form-group">
        <label for="file">File</label>
        <input 
            formControlName="file"
            id="file" 
            type="file" 
            class="form-control"
            (change)="onFileChange($event)">
        <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
            <div *ngIf="f.file.errors.required">File is required.</div>
        </div>
    </div>
    <!--
    <div class="form-group">
        <label for="name">File-Tag</label>
        <input 
            formControlName="name"
            id="name" 
            type="text" 
            class="form-control">
        <div *ngIf="f.name.touched && f.name.invalid" class="alert alert-danger">
            <div *ngIf="f.name.errors.required">Tag Name is required to create Link.</div>
            <div *ngIf="f.name.errors.minlength">Name should be at least 3 character.</div>
        </div>
    </div>
    -->
         
    <button *ngIf= 'f.file.valid' class="btn btn-primary" type="submit">upload File</button>

</form>



<div *ngIf="uploaded && (iDetail|| uploadType== 'imageForHeaderFile')">Upload sucessful,please review and submit ...
file is at :<br> 
 {{uploadedPath}}  
 <br> 
 embed image code:<br>
 &#60;img src="{{uploadedPath}}" alt=" "/>

</div>