import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MistakeTypeRptComponent } from './mistake-type-rpt/mistake-type-rpt.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { ChartsModule } from 'ng2-charts';
import { DashComponent } from './dash/dash.component';
import { SPmagicComponent } from './s-pmagic/s-pmagic.component';
import { TPmagicComponent } from './t-pmagic/t-pmagic.component';
import { DataViewComponent } from './data-view/data-view.component';
import { FdbckRcmndComponent } from './fdbck-rcmnd/fdbck-rcmnd.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { SharedModule } from '../shared/shared.module';
import { UserListComponent } from './user-list/user-list.component';


@NgModule({
  declarations: [DashComponent,MistakeTypeRptComponent, DoughnutChartComponent, SPmagicComponent, TPmagicComponent, DataViewComponent, FdbckRcmndComponent, BarChartComponent, LineChartComponent, UserListComponent],
  imports: [
    CommonModule,
    ChartsModule,
    SharedModule,
  ],
  exports:[MistakeTypeRptComponent,DashComponent,SPmagicComponent,TPmagicComponent,DoughnutChartComponent,DataViewComponent,FdbckRcmndComponent]
})
export class PerformModule { }
