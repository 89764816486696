
<div class= "" *ngIf= "markets && markets.dataList && markets.dataList.length>0" class="my-5 container marketing ">
    <!-- {{board}} -{{course}} : -->
  
   
  <p class= "pen40  text-center">   {{headerObj.title}}</p>
   
  <ul class=" row my-3" [ngStyle]="{'background-image':(markets.hdr.extUrl) ?'url(' + markets.hdr.extUrl + ')' : '' }"
   >
  <ng-container *ngFor="let i = index; let m of markets.dataList;">
  
    <div *ngIf= 'show(m)' class="col-md-6  text-center my-1">
    <!--
        <img [src]='rootUrl + m.contentImages' class="rounded-circle" width="140" height="140">
  -->
      <h4 class="text-center text-warning my-1 " [innerHTML]= "m.instruction"> </h4>
                  <p  class="small  text-left  " [innerHTML]=m.content> </p>
  <!--
      <p><a *ngIf='m.extLink' class="btn btn-secondary" routerLink={{m.extLink}} role="button">View details &raquo;</a></p>
    -->
    <p>
      <a  *ngIf='m.category || m.extLink'  routerLink = {{getRouteUrl(m.category,m.extLink,m.format)}}
        class="btn btn-outline-primary"  role="button">
        <span [innerHTML]="m.title "></span>
      </a>
  </p>
  <br><br>
    </div>
  
  
  </ng-container>
  </ul>
  </div>
  
  