
<ng-container *ngIf= "cat && cat.includes('home') || cat.includes('platform')" > 

      
    <ng-container [ngSwitch]="justSite"> 
       
      <app-eshome *ngSwitchCase='"esa"'> </app-eshome>
      
      <app-ad-banner *ngSwitchDefault> </app-ad-banner>

      <!--
      <app-ebhome *ngSwitchCase='"eba"'> </app-ebhome>
      <app-csa-home *ngSwitchCase='"csa"'> </app-csa-home>
      <app-coaching-home *ngSwitchCase='"ebc"' > </app-coaching-home>
      

      <app-home-template *ngSwitchDefault > </app-home-template>

    <app-our-customers *ngSwitchCase='"esa"'> </app-our-customers>

    <app-testimonials *ngSwitchCase='"eba"'> </app-testimonials>
   -->

    </ng-container>
    
   
  </ng-container>
    