
<ngb-toast *ngIf="message" (hide)="message = ''" delay= "2000" 
>
 {{ message }}
</ngb-toast>

<div *ngIf="statusList.length && statusList.length>0" class="btn-group mr-3">
  <!--
  <span class="ml-5 text-center  bg-primary text-light">move to : </span>
  -->
  <div class="btn-group" ngbDropdown role="group"
    aria-label="Button group with nested dropdown">
    <button class="btn badge btn-outline-secondary dropdown-toggle-split"
      ngbDropdownToggle >change-status</button><span class="text-muted">-{{hdr?.status}}</span>
    <div class="dropdown-menu" container="body" ngbDropdownMenu>
      <ng-container *ngFor="let status of statusList" >
     <button  *ngIf= 'enable(status.code)'  class= "badge" ngbDropdownItem  
       (click)=submit(status.code) ngbTooltip={{status.desc}}>{{status.name}} </button>
      </ng-container>
    </div>
  </div>

  
</div>


<div *ngIf= "!hdr"> no-status</div> 

<ng-container> 

<app-share *ngIf= '!(hdr.hdrFormat && (hdr.hdrFormat == "worksheet" || hdr.hdrFormat == "test") || hdr.hdrFormat == "both" )  && enable("shr")' class= "mx-2"  [hdr]=hdr> </app-share>

<app-assigner container="body" *ngIf= '(hdr.hdrFormat == "worksheet" || hdr.hdrFormat == "test" || hdr.hdrFormat == "both") && enable("asgn")'  class= "mx-2"  [hdr]=hdr [store] = '"true"' > </app-assigner>

<app-assigner container="body" *ngIf= '(hdr.hdrFormat == "worksheet" || hdr.hdrFormat == "test") && enable("storeNAsgn")'  class= "mx-2"  [hdr]=hdr [store] = '"true"' > </app-assigner>




<span class="mx-2" *ngIf = 'false && (hdr.status && hdr.status != "asgn" &&  enable("avl"))'>
  
  <ng-container *ngIf= "!chapter">

  <button  *ngIf= "enableAvl('needAvail-teacher')" class= "btn-md btn-outline-primary border-0 badge mx-2"  (click)="createNBrowse(hdr,i,'avl')" >browse to customize</button>
  
  <button  *ngIf= "enableAvl('needAvail-learner')" class= "btn-md btn-outline-primary border-0 badge mx-2"  (click)="createNBrowse(hdr,i,'avl')" >personalized view</button>
</ng-container>

  <!-- above legacy, below two just for special booster-->
  <button  *ngIf= " cs.enable('status-changer','storeNavail')" class= "btn-md btn-outline-primary border-0 badge mx-2"  (click)="createNBrowse(hdr,i,'avl')" ngbTooltip="make a copy for customization at institute" placement = 'bottom'  >avail & customize</button>
  
  <button  *ngIf= "cs.enable('status-changer','justAvail')" class= "btn-md btn-outline-primary border-0 badge mx-2"  (click)="submit('avl')" ngbTooltip="use one time at institute hence don't store" placement = 'bottom'  >move to avail</button>
 
 </span>


</ng-container>










 <!--
  <ng-container *ngIf='false && statusList && statusList.length && statusList.length<3'>
   
    
    <ng-container *ngFor="let status of statusList">

      <ng-container *ngIf= 'status.code'>
   
        <ng-container [ngSwitch]="status.code">
          
      <button  *ngIf= 'enable(status.code) && status.name.includes("back to")' class ="btn btn-outline-secondary badge mx-1"  (click)='submit(status.code)' ngbTooltip={{status.desc}} >{{status.name}}
      </button>
 
          
        <span class="mx-2" *ngSwitchCase="'asgn'">       </span>
        <span class="mx-2" *ngSwitchCase="'shr'">       </span>
        
          

        <ng-container *ngSwitchDefault>
         
        <button  *ngIf= 'enable(status.code) && !status.name.includes("back to")' class ="btn btn-outline-secondary badge mx-1"  (click)='submit(status.code)' ngbTooltip={{status.desc}} >{{status.name}}
          </button>
        </ng-container>
        
               
    </ng-container>
  </ng-container>
</ng-container>

</ng-container>

-->
