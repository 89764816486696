import { Component, Input, OnInit } from '@angular/core';
import { ExtraHdrService } from '../extra-hdr.service';

@Component({
  selector: 'app-day-counter',
  templateUrl: './day-counter.component.html',
  styleUrls: ['./day-counter.component.scss']
})
export class DayCounterComponent implements OnInit {
@Input() h;
 daysLeft:number = 0 ;

constructor(public ehs :ExtraHdrService) { }

  ngOnInit(): void {
    
    this.daysLeft= this.dateDiff(this.ehs.getValue(this.h?.extra,"examDate"));
  
      
  }


  dateDiff(sentDate) {
    var date1:any = new Date(sentDate);
    var date2:any = new Date();
    var diffDays:any = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));
  
    return diffDays;
  }

}
