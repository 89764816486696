import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-parents',
  templateUrl: './parents.component.html',
  styleUrls: ['./parents.component.scss']
})
export class ParentsComponent implements OnInit {
  signupLink: string;
  get mentorId() { return this.cs.ss.getMentorId()}

  constructor(public cs: ClientInfoService) { }

  ngOnInit() {

    let userDomain = this.cs.ss.getUserDomain()?this.cs.ss.getUserDomain():this.cs.getSite()
    this.signupLink= [this.cs.getRoot(),userDomain,"mng","features","signup"].join("/");
  }

}
