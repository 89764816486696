
<div class="container" *ngIf="(hdrGrps$ | async) as ds; else loading">


<ng-container *ngFor="let header of ds?.dataList; let i = index;let length= count ">


<app-aso-hdr-lstr *ngIf= '!header.headerId || (header.id === header.headerId)'  [grpAsoId]= header.id [asoHdr]= header> Associated Records...</app-aso-hdr-lstr>

</ng-container>

</div>




<ng-template #noItems>No Items!</ng-template>


<ng-template #loading>loading data...</ng-template>