import { Component, Inject, OnInit } from '@angular/core';
import { THOUGHTS } from '../../thought-store';
import { Thought } from '../../thought';
import { ExamDataService } from '../../exam-data.service';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';

import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
@Component({
  selector: 'app-thought',
  templateUrl: './thought.component.html',
  styleUrls: ['./thought.component.scss']
})
export class ThoughtComponent implements OnInit {
   //utubeUrl : string;
   storedDate: number;
   now:number;
   elapsed:number;
   thought : Thought;
   thoughts= THOUGHTS;
   expand:boolean;
   utubeId= "VZTGBj7Zyvk"; 
   //utubeId= "v64KOxKVLVg";
   utube= "https://www.youtube.com/embed/";
   vimeoUrl= "https://player.vimeo.com/video/137857207";
  
   showVimeo:boolean;
   showPastVid:boolean;
   questions: Object ;
   headerId: number;
   headerById:Object;
   tabId:number;
 showNews:boolean;
 showQuiz:boolean;
   

   constructor(private dataService: BoardDataService,private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any ,  private cs:ClientInfoService) { }

  ngOnInit() {

    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '.theEnd',
    });


   this.storedDate = Date.now();
   this.thought = this.thoughts[Math.floor(Math.random()*28)];
   this.headerId = 674;
   this.dataService.getSiteHeaderByNum(this.headerId).subscribe(dataList => {
           this.headerById = dataList;

       });
   this.dataService.getQuestions(674).subscribe(dataList => {
         this.questions = dataList;

     });

     //video
     this.dataService.getVideo().subscribe(response=> {
      if(response) {
      let qd = response as any;
      this.utubeId = qd.vidLink;
    localStorage.setItem('utubeId', this.utubeId);
    } });  

  }

  alertMsg(str:string){
    console.log(str);
  }

  getThought(){
   this.now = Date.now();
   this.elapsed = this.now- this.storedDate;

   if(this.elapsed> 36400 ){
   this.thought = this.thought[Math.floor(Math.random()*25)];
   this.storedDate = this.now;
    }
   return this.thought;
  }

  enable(btnId:string)  { return this.cs.enable('thought',btnId ) }
 
}
