<ng-container *ngIf= 'dataList'>
   <h5 class= "text-center m-2" > {{title}} </h5>

     <button class= "mx-3 btn btn-outline-primary badge "  (click)= 'showAdd =!showAdd'>
      Add Role </button>   


      
     <div *ngIf= "showAdd" class= "my-3 mx-5">
      <app-add-usr-role class="my-5"> </app-add-usr-role>
      
    </div>
  

   <table datatable [dtOptions]="dtOptions"  class="row-border hover">
      <thead>
      <tr>
        <th *ngFor="let fld of fldList,let i=index;">
           {{fld}} 
        </th>
        <th>
          Feature
        </th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let c of dataList;let i=index">
      <td *ngFor="let val of getValList(c), let i=index;">
     {{val}}
    
     </td>
           
          <td>
          <button class= "mx-3 btn btn-outline-primary badge "  (click)= 'expand(c,i)'>
            Expand </button>   
         
            <button class= "mx-3 btn btn-outline-primary badge "  (click)= 'navigate(c.domain+"::usr")'>
            Show Users</button>   
         </td>
        
  
        </tr>
    </tbody>
  
   </table>

</ng-container>
  
 
<div *ngIf= "expandI>=0" class= "my-3 mx-5">
 <app-my-json class="my-5" [table]= "'userRole'" [q] = "c" > </app-my-json>
</div>
  
  
  
