
<div *ngIf="hdr" [ngStyle]="styles" class="jumbotron-fluid text-center my-0">
  
  <div *ngIf="false && hdr && (feature != 'pb') " class=" display-9">
    {{cs.board}}-{{cs.course}}
    <span *ngIf="cs.subject">--{{cs.subject}} </span>
  </div>

  <div *ngIf="chapter">
    <span class="bg-light " [innerHTML]=formatContentHeader()>
    </span>
  </div>

  <ng-container *ngIf="!chapter"> 
  <div  class=" h5 py-4 display-5">
    <span class="" [innerHTML]=formatContentHeader()>
    </span>
  </div>

  
  <app-hdr-view-btns [hdr] = hdr> </app-hdr-view-btns>
    

  <span *ngIf="hdr.summary"
    class="my-0 font-italic" [innerHTML]=hdr.summary>
  </span>
</ng-container>
  
</div>
<br>