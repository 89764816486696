import { Component, Input, OnInit } from '@angular/core';
import { BtnService } from '../../gen/btn.service';
import { ClientInfoService } from '../client-info.service';
import { ExtraDtlService } from '../../gen/extra-dtl.service';

@Component({
  selector: 'app-headings-offering',
  templateUrl: './headings-offering.component.html',
  styleUrls: ['./headings-offering.component.scss']
})
export class HeadingsOfferingComponent implements OnInit {
  
@Input() subDesc:any;
 constructor(public cs:ClientInfoService ){
  //public bs:BtnService -not worked
 }

  ngOnInit(): void {}

  
}