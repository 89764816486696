
<!--
<h1 class= "back-indigo jumbotron text-center"  >Thanks indeed for your interest ...</h1>
-->

<div [hidden]="submitted" >

<div class= "container" *ngIf="!success">

<form class=" border border-light p-5" [formGroup]="commentForm" (ngSubmit)="onSubmit()">
  <!--
  -->
  <p class="h4 bg-primary mb-4 ">Welcome {{signedUser}}</p>

<h5 *ngIf="success">Your form is valid!</h5>




<!--
      <select formControlName="topic" class="browser-default custom-select mb-4" placeholder= "Main topic/Topic">
        <option value="" disabled>Main topic/Topic</option>
        <option value="1" selected>Feedback</option>
        <option value="2">Report a bug</option>
        <option value="3">Feature request</option>
        <option value="4">Other</option>
      </select>
-->

<select formControlName="topic" class="browser-default custom-select mb-4" placeholder= "Main topic/Topic">
  <option value="" disabled>Main topic/Topic</option>
  <option value="prospect" >Request a Demo</option>
  <option value="Feedback">Feedback</option>
  <option value="Report a bug">Report a bug</option>
  <option value="Feature request">Feature request</option>
  <option value="Tutor request">Tutor request</option>
  <option value="Customer Service" selected >Signup/SignIn Issue</option>
  <option value="Other">Other</option>
</select>
      <div *ngIf="submitted && commentForm.controls.topic.errors" class="error">
        <div *ngIf="commentForm.controls.topic.errors.required">Topic/topic is required</div>
      </div>



      <textarea class="form-control mb-4" rows= "3" formControlName="message" placeholder="Message"></textarea>

      <div *ngIf="submitted && commentForm.controls.message.errors" class="error">
        <div *ngIf="commentForm.controls.message.errors.required">A message is required</div>
      </div>



      <div *ngIf="unsigned">
      <input class="form-control mb-4" type="text" formControlName="name" placeholder="userId">
      <div *ngIf="submitted && commentForm.controls.name.errors" class="error">
        <div *ngIf="commentForm.controls.name.errors.required">Your name is required</div>
      </div>

      <!--textarea formControlName="email"></textarea>-->
      <input class="form-control mb-4" type="text" formControlName="email" placeholder="Email: needed for verification">
      <div *ngIf="submitted && commentForm.controls.email.errors" class="error">
        <div *ngIf="commentForm.controls.email.errors.required">An Email Id is required please</div>
      </div>

        <i class= "bg-secondary"> Phone is optional, enter for quick response</i>
      <input class="form-control mb-4" type="text" formControlName="phone" placeholder="Phone">
      <div *ngIf="submitted && commentForm.controls.phone.errors" class="error">
        <div *ngIf="commentForm.controls.phone.errors.required"><p class="h4 bg-primary mb-4 ">A Phone number  is optional</p></div>
      </div>
    </div>

<!--
    <button class="form-control mb-4"  type="submit" value="Send message" class="cta bg-info">
    </button>
    [disabled]="disabledSubmitButton"
    -->

    <input class="btn btn-primary btn-bg" type="submit" value="Submit" >

  </form>

</div>

</div>

  <div *ngIf="success" class="results">
    <p class="alert alert-info">

      <ngb-alert [dismissible]="false">
         Thanks for the message {{commentForm.controls.name.value}}...
      </ngb-alert>
    </p>

  </div>


  <p *ngIf="outData?.userDetail" class="m-3 float-center" > 
     <button  (click)= 'changePwd(outData.userDetail)'>change password </button>
  </p>
