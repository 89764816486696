


<div class=" text-center">

<div *ngIf= "questions" >
    
<app-hdr-view-slctr *ngIf= 'listType.includes("NotUsedexam") && questions.hdr else justTitle'  [hdr]="questions.hdr"></app-hdr-view-slctr>

    <ng-template #justTitle>
    <h5> {{questions.hdr.title}} </h5>
    </ng-template>

    <ul class = "row p-0 m-0" >

<li class="list-group-item col-6 col-md-4 col-lg-2 border-0 btn-block" *ngFor="let q of questions.dataList; let i = index;">


<!--
<button class="btn btn-primary border-0 btn-block" (click) = 'selectExam(q)'>
    <span  innerHTML={{getTitle(q)}}> </span>  
</button> 
{{subscribed(q)}}- {{showAll}}
-->

<a *ngIf= "subscribed(q)" class="btn btn-primary border-0 btn-block" [routerLink]='selectExam(q)'>
    <span  innerHTML={{getTitle(q)}}> </span>  
      

</a> 
</li>
</ul>
</div>
</div>

<!--
<app-exam-selector> </app-exam-selector>
-->







