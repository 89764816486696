import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-math',
  templateUrl: './math.component.html',
  styleUrls: ['./math.component.scss']
})
export class MathComponent implements OnInit {
  mathml = '';
  
  constructor(public cs:ClientInfoService) { }

  ngOnInit(): void {
  }
  
  
  
  ngAfterViewChecked(){
    // MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
   this.cs.typeSet();
  }

  
}
