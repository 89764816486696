import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientInfoService } from '../client-info.service';
import { SelectorService } from '../exam-selector/selector.service';

@Component({
  selector: 'app-subject-lister',
  templateUrl: './subject-lister.component.html',
  styleUrls: ['./subject-lister.component.scss']
})
export class SubjectListerComponent implements OnInit {
  @Input() subjects:any[];
  data$: Observable<any>;
  allSub= "subject";
  
  get justSubject() { return this.cs.getJustSubject()};
  get selected() { return this.slSrv.selectedSubject?this.slSrv.selectedSubject:this.slSrv.subject}

  constructor(private slSrv:SelectorService, private cs:ClientInfoService) { }

  ngOnInit(): void {

  } 

browse(sub:string){
this.slSrv.browseSubject(sub);
}


filter(subjects:any[]){
  //console.log("subjects",subjects);
  if(subjects && this.slSrv.justSubject){
    return subjects.filter(
    item => item.toLowerCase().startsWith(this.slSrv.justSubject.toLowerCase()) 
    //item.name.toLowerCase().indexOf(this.slSrv.subject) > -1
  );
  }
  }


  getBookBranch(combo:string){
    return combo.replace(this.justSubject + "~", "");

  }
  
}
