import { createOptional } from '@angular/compiler/src/core';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { QstnhdrService } from '../../qstn/qstnhdr.service';

import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { HeaderService } from '../../tag/header.service';
import { TaghdrListService } from '../../tag/taghdr-list/taghdr-list.service';

@Component({
  selector: 'app-taghdr-selector',
  templateUrl: './taghdr-selector.component.html',
  styleUrls: ['./taghdr-selector.component.scss']
})
export class TaghdrSelectorComponent implements OnInit,OnChanges {
  @Input() hdr: any;
  @Input() slctType: string;
  @Input() crtHdr:any;
  
   //slctType: string;

  //Note- seclector header expects type to be selected like twip-dhw and hdrId group set properly for passing
  //to new record creation.

  data$: Observable<any>;
  calling: boolean;
  unitId: string;
  data: Object;

 get groupId(){return this.cs.getGroupId()}
  showAdd: boolean;


  constructor(private cs:ClientInfoService, public tl: TaghdrListService) {
   
    HeaderService.updateList.subscribe(res => {
     //this.data$ = this.tl.ths.getHeadersByType(this.slctType);
     this.fetchHdr();

    });
    
    QstnhdrService.updateList.subscribe(res => {
      //this.data$ = this.tl.ths.getHeadersByType(this.slctType);
      this.fetchHdr();
      });
   

  }

  ngOnInit(): void {
    //console.log(this.hdr);
    
   
    if (this.hdr) {
      this.calling = true;
     
     // this.data$ = this.tl.ths.getHeadersByType(this.slctType);
     // this.data$ = this.tl.ths.getUnitHeaders(this.slctType,this.crtHdr.headerId);
      this.fetchHdr();

    }

  }
  
  
  
fetchHdr(){
   
  if(!this.slctType) {this.slctType = this.crtHdr.type;}

  let hdrId= this.crtHdr.headerId>0?this.crtHdr.headerId:this.crtHdr.Id;
  
  if(!hdrId) { let hdrId= this.cs.getChapter()}

   //console.log(this.slctType + hdrId);

  //this.data$ = this.tl.ths.getUnitHeaders(this.slctType,hdrId);
  
  //(getImpl(offering)).getHeaderList(site, board, course, clsSec,offering,subject,owner,status,headerId)
 
  //this.tl.ths.getUnitHeaders(this.slctType,hdrId).subscribe(data=>
 //migrating to new with upto hdrid list..
 //note- data is just raw in current api.. hence changing html.. restore it if need to go for old

 //below ovrOfr may not be needed if slctType good?
 
 let ovrOfr = this.slctType?this.slctType:this.cs.getOffering();
  
 let status= this.cs.getStatus(ovrOfr)?this.cs.getStatus(ovrOfr):'status';
 
 let unitBased = this.tl.fd.hdrGrpNeeded(this.slctType);
 if(unitBased) {
 
  if(this.slctType == "tshr-doc"){
  this.tl.ths.hdrComboList("doc",status,hdrId).subscribe(data=>
    this.data = data
    );

 } else{ 
 this.tl.ths.fltrdHdrList(this.slctType,status,hdrId).subscribe(data=>
    this.data = data
    );
 }
}
  else{
  // assuming u r calling it just to show wip-records??
    this.tl.ths.filteredList("owner",status,ovrOfr).subscribe(data=>
      this.data = data
    );      
  }
  
  return this.data;
  
  if (this.tl.fd.hdrGrpNeeded(this.slctType)) {
}
  else{
    // get headertype in dataList
  }
}

//don't use this, use crtHdr instead.
prepUploadCrt(hdr:any){
    // let crtHdr= hdr;
     let uploadCrtHdr= Object.assign({}, hdr);
     // make teps should become qeps, qpbstr will become qpbstr for upload or not click n build type.
     if(hdr.type=="dhw"){
      uploadCrtHdr.type = 'qdhw';
     } else {
     uploadCrtHdr.type= "q"+ hdr.type.replace("twip-","").substr(1) ;
     }

     uploadCrtHdr.status= 'raw';
     return uploadCrtHdr;
   }  

  ngOnChanges(){
   // must.. without this code was not working
   /* 
   if (this.hdr) {
      this.calling = true;
      this.fetchHdr();
      //this.data$ = this.tl.ths.getHeadersByType(this.slctType);
    }
   */
  
   }

   
}
