 
<ng-container [ngSwitch]="hdr.status" >


 
 
<ng-container *ngIf= 'vs.enable("publishToCenter")'> 


<button  *ngIf= 'hdr.status &&  (hdr.status=="loaded" || hdr.status == "store")'  class= "btn-md btn-outline-secondary border-0 badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr) " >
          <span class= "">normal view </span>
</button>  

<button  *ngSwitchCase='"store"'   class= "btn-md btn-outline-primary border-0 badge mr-1"  (click)="vs.createNBrowse(hdr,i,'avl',false)"  > publish to center</button>

      <button  *ngSwitchCase='"loaded"'   class= "btn-md btn-outline-primary border-0 badge mr-1"  (click)="vs.createNBrowse(hdr,i,'avl',false)"  >publish to center </button>
  

</ng-container>
 

    <button  *ngSwitchCase='"shr"'  class= "btn-md btn-primary badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr) "  >Digi Interact</button>

    <button  *ngSwitchCase='"clr"'   class= "btn-md btn-primary badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr) "  >Doubt Duster</button>

    <button  *ngSwitchCase='"rvsn"'   class= "btn-md btn-primary badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr) " >Revision</button>
   
    <button  *ngSwitchCase='"avl"'   class= "btn-md btn-outline-primary border-0 badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr) " >customized View</button>
   
    
    <span  *ngSwitchCase='"verify"'> - </span>
    <span  *ngSwitchCase='"asgn"'> - </span>
   <!-- add span view for all the status for which teacher and student have different view-->   
   
  <span *ngSwitchDefault>
    <span  *ngIf= 'hdr.owner== vs.signedUser'>
    <a  *ngIf='!vs.cs.tagBased() else enableAI'  class= "btn-md btn-primary badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr) " >
      view <span class= "small muted "> </span>
    </a>
     <ng-template #enableAI>
    <a   class= "btn-md btn-primary badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr) " >
       <span class= "small muted "> customize</span>
    </a>
 </ng-template>
</span>

    <a  *ngIf ='hdr.owner != vs.signedUser' class= "btn-md btn-outline-primary border-0 badge mr-1"  [routerLink]= "vs.getChapterUrl(hdr) " >
      show  <span class= "small muted "> </span>
    </a>
  </span>

</ng-container>



