import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { BtnService } from '../../gen/btn.service';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { HeadingsService } from '../../shared/headings/headings.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input() hideTitle:boolean;
  @Input() showSiteInfo:boolean;
  @Input() forPdf:boolean;
  
  key:string;
  data$: Observable<any>;
  calling:boolean;
  noCall:boolean;
  ftrDescObj: any;
  
  get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get feature() { return this.cs.getFeature()};
  get rootUrl() {return  this.cs.getRoot() };
  get isUnsigned() {return  this.cs.isUnsigned() };
 


  constructor(private route: ActivatedRoute,
    private router: Router, public cs:ClientInfoService, private ds: BoardDataService,private fd:FixedDataService, private b:BtnService,public hdng:HeadingsService) { }

    
  ngOnInit(): void {
    /*
    this.key="slides"
    if(this.feature){
     this.key = this.feature +"-" + this.key;
    } else{
      this.key = this.cat +"-" + this.key; 
    }
    
    let key = "learner";
     
          
    */


    this.cs.updateParms(this.route);
   this.key = this.feature? this.feature:this.cat;
   if(!this.isUnsigned){
   //for product we want to show all features for all...as its just description
    //this.key = this.key + "~" + this.cs.ss.getGroupId();
   }
   this.calling = true;
  this.data$ =  this.ds.getCfgData(this.site,this.board,this.course,"cfg",this.key);
  }


 show(q:any){ return this.b.showQstn("desc",q)}


 //moved to backend
 stripFtrDesc(ftrData: Array<any>): any {
    const index = ftrData.findIndex(item => item.groupId === 'ftrDesc');
    // console.log(" index is: "+ index);
    if (index > -1) {
      this.ftrDescObj = ftrData[index]; 
      //ftrData.splice(index, 1);
     return ftrData;
    } else {
      return ftrData;
    }
  }

}
