import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { environment } from './../../environments/environment';
import { ClientInfoService } from './client-info.service';
import { FixedDataService } from './fixed-data.service';
import { TagService } from './tag.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class QuizService {
  
  
 





  //---------------- Properties---------------
  //readonly rootUrl = 'http://localhost/rs';
    //readonly rootUrl = 'https://examsteppers.com/rs';
  //  readonly rootUrl = environment.rootUrl+'/rs';
  rootUrl : string;



  qns: any[];
  seconds: number;
  timer;
  qnProgress: number;
  correctAnswerCount: number = 0;



  get site() {return  this.cs.getSite()} ;
get cat() {return this.cs.getCat() };
get board() {return this.cs.getBoard()};
get course() { return this.cs.getCourse()};
get feature() { return this.cs.getFeature()};
get offering() { return this.cs.getOffering()};
get subject() { return this.cs.getSubject()};
get chapter(){return this.cs.getChapter()}
get mode() { return this.cs.getMode()};
get status() {return this.cs.getStatus()};

  //---------------- Helper Methods---------------
  constructor(private router: Router,private http: HttpClient, private cs:ClientInfoService, private fd:FixedDataService,private tagService: TagService) {
    this.rootUrl= cs.getRoot() + "/rs";

  }
  displayTimeElapsed() {
    return Math.floor(this.seconds / 3600) + ':' + Math.floor(this.seconds / 60) + ':' + Math.floor(this.seconds % 60);
  }

  getParticipantName() {
    var participant = JSON.parse(localStorage.getItem('participant'));
    if(participant) {
    return participant.name;
  };
 var user= localStorage.getItem('signedUser');
if(user){
  //var signedUser = JSON.parse(localStorage.getItem('signedUser'));

  var signedUser = localStorage.getItem('signedUser');
  //var signedUser = this.dataService.getSignedUserNew();
  if(signedUser) {
  return signedUser;
}
}
return 'guest';
  }
/* check later for http json parse for undefiend
  private extractData(res: Response) {
      return res.text() ? res.json() : {}; ;
  }

  */


  //---------------- Http Methods---------------

  insertParticipant(name: string, email: string) {
    var body = {
      name: name,
      email: email
    }
    return this.http.post(this.rootUrl + '/api/insertParticipant', body);
  }

  login(userId: string, password: string) {
    var LoginDto = {
      userId: userId,
      password: password
    }

    return this.http.post(this.rootUrl + '/auth/login', LoginDto);
  }

  getQuestions(headerId:string) {
    //alert("retrieving quiz data for : " + headerId ) ;
      return this.http.get(this.rootUrl + '/api/questions/'+ headerId );

  }

  getSiteQuestions(site:string, board:string, course:string, offering:string,subject:string, x:string) {
   return   this.getQuestions(x);
      }


  getAnswers() {

    var body = this.qns.map(x => x.QnID);
    return this.http.post(this.rootUrl + '/api/answers', body);
  }

  submitAnswer1(qID:any, choice: any) {
    const commentPayload = {
      name: this.getParticipantName(),
      parentHdrId:this.cs.getChapter(),
      parentDtlId:qID,
      //message: source + ' -- '+qstnId + "//" + type ,
      message: choice,
    };

   throw new Error("Method not implemented.");
    

  }

  //postdata from contentListr
  submitAnswer(type:string,source:string,qstnId:number,k:number) {
    const commentPayload = {
      name:'' ,
      email: '',
      message: source ,
      phone: '',
      //parentId: qstnId, obsolete
      parentHdrId:this.cs.getChapter(),
      parentDtlId:qstnId,
      source:source,
      topic: '',
      type: type,
      cmntType:type,
      featureType:this.cs.getOffering(),
      };
      return this.tagService.addComment(commentPayload);
    /*
      this.tagService.addComment(commentPayload).subscribe(data => {
         if(data.status === 200) {
         //alert(data.result.name + data.result.message+data.result.phone);
          //this.success = true;
         // this.successI = k;
          //this.showAddI = k;
          // alert("reported,thanks!");
           //window.localStorage.setItem('token', data.result.token);
           //this.router.navigate(['list-user']);
         }else {
           //this.success = false;
           //  this.successI = k;
           alert("error,try later");
         }
       }
       );
       */
      
  }
  



  submitScore() {
    var body ;
    var body = JSON.parse(localStorage.getItem('participant'));
    if(!body ){
    body= JSON.parse('{"id":19851,"participantId":19851,"name":"shruthi","email":"freshu@bestu.com","score":0,"timeSpent":0}');
    //alert( "Please register/Sign in before submitting result");
      body.name = this.getParticipantName();
    //return;
  } else{

    body.score = this.correctAnswerCount;
    body.timeSpent = this.seconds;
}
    return this.http.post(this.rootUrl + "/api/updateOutput", body);

  }

  showAutoRslt(type:string){
   console.log("showing result...");
    this.router.navigate(['/', this.site, 'abc', this.board, this.course, type, this.subject,this.chapter]);
       
  }

  getChartData() {
      //return this.http.get(this.rootUrl + '/api/questions');
    var chartData = [{"set":"set1","percent":78},{"set":"set2","percent":90},{"set":"set3","percent":94},{"set":"set4","percent":93}] ;
           return  chartData;
  }

}
