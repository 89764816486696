/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "./seo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./seo.component";
import * as i3 from "../../shared/headings/headings.service";
var styles_SeoComponent = [i0.styles];
var RenderType_SeoComponent = /*@__PURE__*/ i1.ɵcrt({ encapsulation: 0, styles: styles_SeoComponent, data: {} });
export { RenderType_SeoComponent as RenderType_SeoComponent };
export function View_SeoComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_SeoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seo", [], null, null, null, View_SeoComponent_0, RenderType_SeoComponent)), i1.ɵdid(1, 638976, null, 0, i2.SeoComponent, [i3.HeadingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SeoComponentNgFactory = /*@__PURE__*/ i1.ɵccf("app-seo", i2.SeoComponent, View_SeoComponent_Host_0, { h: "h", q: "q" }, {}, []);
export { SeoComponentNgFactory as SeoComponentNgFactory };

