
import { Component, Input, OnInit } from '@angular/core';
import { TagService } from '../../shared/tag.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { HeaderService } from '../../tag/header.service';
import { BoardDataService } from '../../board-data.service';

@Component({
  selector: 'app-taghdr-crud',
  templateUrl: './taghdr-crud.component.html',
  styleUrls: ['./taghdr-crud.component.scss']
})
export class TaghdrCrudComponent implements OnInit {

  @Input() header:any;
  @Input() i :number;;
 @Input() chapter:any;

 expandI:number = -1;
showHdrGrp:boolean;

showUpdate:boolean;
  showUpdateNum: number;
  success: boolean;
  
  showAddI  : number;
  
  constructor(private tagHdrService: HeaderService,private ds: BoardDataService,private cs:ClientInfoService,public ts:TagService) { }

  ngOnInit(): void {
    // by default show all
   //this.expandI== this.i;
  }

  ngOnChanges(){
//console.log('hello' + this.cs.getCat())
  }


  expand(i:number){
    this.expandI== i? this.expandI=-1 : this.expandI =i;
  }

 tagBased(offering:string){
  return this.cs.tagBased(offering);
}

enable(btnId:string,i?:number,hdrRec?:any){
  //return true;
  let component = 'crudList';
  return this.cs.enable(component,btnId,'',hdrRec);
   }
  
  processUpdate(hdr:any,i:number){
    this.showUpdate= !this.showUpdate;
    this.showUpdateNum= i;
   }
   
   changeInactive(id,offering,inactive,i?:number){
    const payload = {
      parentId: this.chapter,
      };
      this.tagHdrService.changeInactive(id,offering,inactive).subscribe(data => {
        if((data as any).status === 200) {
       this.cs.addMsg((data as any).message + (data as any).result,'toast');
         this.success = true;
          }else {
        this.success = false;
        alert("error,try later");
        }
      }
      );
 
 }


 

}
