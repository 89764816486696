import { Component, Input, OnInit } from '@angular/core';
import { QstnListService } from '../qstn-list/qstn-list.service';

@Component({
  selector: 'app-simple-qstn',
  templateUrl: './simple-qstn.component.html',
  styleUrls: ['./simple-qstn.component.scss']
})
export class SimpleQstnComponent implements OnInit {
  @Input()  q :any;
  @Input()  i:any;

  constructor(public qs:QstnListService) { }
  showA:boolean;
  showCmntI:number;
  showCommentI:number;
  ngOnInit(): void {
  }

  showLink(q:any){
  if(this.qs.cs.tagBasedForCreate(this.qs.cs.offering) || this.qs.qstnId){return false}

  // if((q.sequence && !q.subSeq )&& (q.instruction || q.question||q.content) && !q.digQstn)
  if((q.sequence && !q.subSeq )&& (q.instruction || q.question||q.content)){return true} else{ return false;}

}
  

}
