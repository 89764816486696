import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';
import { BoardDataService } from '../../board-data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss']
})
export class AffiliatesComponent implements OnInit {
  //@Input() board:string;
  //@Input() course:string;

  headers$: Observable<any>;
  
  get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get signedUser() { return this.cs.getSignedUser()};

  constructor(private cs:ClientInfoService,private dataService: BoardDataService) { }


  ngOnInit() {
    this.fetchData();
  }

  
  ngOnChanges(changes: SimpleChanges) {
    
    this.fetchData();
  }
  
fetchData(){
 let subject = this.subject?this.subject:"Affiliates";
 // console.log("getting books"); 

 this.headers$=  this.dataService.getSiteHeaders(this.site,this.board,this.course,'mpb', subject);
 //console.log(this.headers$); 
}

}
