

<div *ngIf= 'test'  class= "text-center"> 
test is yes

</div>



<div *ngIf= '!test'  class= "text-center"> 
    test is false
    
    </div>