
<!--
<h5 class= "jumbotron"> What our customers say</h5>
-->

<div class = "bg-light fixedHeight">
<ngb-carousel>
<div  *ngFor="let c of cmnts" class="col-12">
  
  <ng-template ngbSlide>       
  <app-cmnt-view  [c]=c class= "row" > </app-cmnt-view>
  <app-cmnt-rspnse class= "row" [c] =c> </app-cmnt-rspnse>
  </ng-template>

  </div>
</ngb-carousel>
</div>