
<!--
<h1>Angular 11 Datatables Example - Tutsmake.com</h1>

-->


<table datatable [dtOptions]="dtOptions" class="row-border hover">
  <thead>
    <tr>
  <th>Subjet</th>
  <th>workType</th>
  <th>unitid</th>
  <th>Obtained Marks</th>
  <th>Feedback</th>

    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let post of posts">
      <td>{{ post[0] }}</td>
      <td>{{ post[1] }}</td>
      <td>{{ post[2]}}</td>
      <td>{{ post[3]}}</td>
      <td>{{ post[5]}}</td>

    </tr>
  </tbody>
</table>