
<div *ngIf="header"  [hidden]="submitted" >
    
    <form  #qUpdForm ="ngForm" (ngSubmit)="onSubmit(header)">
       <!-- 
      <div  class="form-group">
        <label for="type">Type </label>
        <input type="text" class="form-control" id="type" required [(ngModel)]="header.type" name="type">
      </div>
  -->

     <app-qh-mnt [parentFormGroup] = 'qUpdForm'  [header] = header [offering] = header.type [ei]= ei > </app-qh-mnt>
    

<!--
         <div  class="form-group">
        <label for="addUser">Added by: </label>
        <input type="text" class="form-control" id="addUser" required [(ngModel)]="header.addUser" name="addUser">
      </div>
-->
      

      <!--
      <div class="form-group">
        <label for="message">Message/Query</label>
        
        <ckeditor editorUrl="\assets\ckeditor\ckeditor.js"
            #editor
            [(ngModel)]="comment.comment"
            id="message"
                      name="message"  >
          </ckeditor>
        --> 
  
        <!--
  
          type="divarea"
          [config]="{ toolbar: [ [ 'Bold' ] ] }"
  
        <input type="text" class="form-control" id="message" required [(ngModel)]="comment.comment" name="message">
        
      </div>
      -->
    <!-- check it later -->
    
      <button type="submit" class="btn btn-success">Submit</button>
     
      <button *ngIf = "enable('hdelete')" type="submit" class="btn btn-danger" (click) = 'delete(header.id)'> Hard Delete</button>

      <button *ngIf = "enable('udetail')" type="button" class="btn mx-3 btn-secondary " (click) = 'browseDetail(header)'> show detail</button>
      <a [routerLink]="chptrSrv.processClick(header)">detail-data  </a>

      <button *ngIf = "enable('jsonUpdate')" type="button" class="btn btn-info badge" (click) = 'jsonUpdate= !jsonUpdate'> json Update</button>


      <div *ngIf= "jsonUpdate" >
        <app-my-json class= "float-left mx-3 " [table]= 'getTable(header)' class="mx-5"  [q] = "header" > </app-my-json>
      </div>
  
      
    <!--
      <br>
     <i> Note: please fillup & press upload if you have file else press submit. one is enough.
    </i>
    -->
    </form>
  </div>
  <br>
  
  <!--
  
  <div [hidden]="!submitted">
    <h4>You submitted successfully!</h4>
  </div>
-->
<!-- <button class="btn btn-success" (click)="newComment()">Add</button> -->