  import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, NgForm } from '@angular/forms';
import { ExtraHdrService } from '../../gen/extra-hdr.service';
import { ExtraInfo } from '../../shared/assigner/extra-info';
  import { Header } from '../../qstn/header';
import { ClientInfoService } from '../../shared/client-info.service';
  import { FixedDataService } from '../../shared/fixed-data.service';
import { ViewService } from '../../qstn/view-lstr/view.service';
import { Format } from '../format';
import { FldsService } from '../flds.service';
import { ListerService } from '../../chat/lister.service';
  
  @Component({
    selector: 'app-qh-mnt',
    templateUrl: './qh-mnt.component.html',
    styleUrls: ['./qh-mnt.component.scss'],
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
  })
  export class QhMntComponent implements OnInit {
    @Input() header :Header;
    @Input() ei:ExtraInfo;
    //obsolete?
    @Input() offering:string;
    @Input() i:number;
    @Input() parentFormGroup:FormGroup; 

  selectedFormat:Format;
  formatList:  Format[]= new Array();
  

   

    primary : string[];
    secondary : string[];
    expand:boolean;
    additional:string[];
    additionalFlag: boolean;
   updateJsonFlag
    
    
    constructor(public vs:ViewService, private fd:FixedDataService,private cs:ClientInfoService,
      private fldsrv:FldsService, public ls:ListerService) { }

      ngOnInit(): void {
      /*
        if(this.header && !this.header.board){
         this.header= this.populateDefault(this.header);
       } 
    */
   let justType = this.cs.getJustOffering(this.header.type);  
   
   let cat = this.cs.getCat();  
   if(cat && cat.endsWith('-bldr')){
       justType= cat.replace('-bldr','');
     }
     
     //console.log(justType);
        
      //d is fld-detail in setup
 
   
 this.ls.getKeyFlds('type-subtype',justType).subscribe((data:any) => {
        this.formatList  = data.result?.value;
 });


 // this.formatList = this.fldsrv.getFormatList(justType);  
  this.selectedFormat = this.formatList.find(e => e.code === justType);
  if(true){
  this.ls.getKeyFlds('hdr-flds',justType).subscribe((data:any) => {
    //console.log(data);
    let flds  = data.result;
    let StrArray = flds.primary.split(",");
    this.primary = StrArray;
    //console.log(this.primary);
    this.secondary= flds.secondary.split(",");
    this.additional = ['-'].concat(this.primary).concat(this.secondary);
  });
} else{
   let d  = this.fd.selectQHFlds(justType);
    this.primary= d.p;
    console.log(d.p);
    this.secondary= d.s;
    
    this.additional = ['-'].concat(d.p).concat(d.s);
     
    }
    }
  

    enable(btnId:string){
      return this.cs.enable('qh-mnt',btnId);
    }

    
  fetchData($event:any){
    //alert($event);
    this.changeFormat($event)

  }


    changeFormat(format:Format): void {
      this.selectedFormat = format;
      if(!this.header.hdrFormat){
      this.header.hdrFormat= format.code}
      else{
      this.header.summary= format.code + "__" + this.header.summary}
      
      if(true){
        this.ls.getKeyFlds('hdr-flds',format.code).subscribe((data:any) => {
          //console.log(data);
          let flds  = data.result;
          let StrArray = flds.primary.split(",");
          this.primary = StrArray;
          //console.log(this.primary);
          this.secondary= flds.secondary.split(",");
          this.additional = ['-'].concat(this.primary).concat(this.secondary);
        });
      } else{
      let d  = this.fd.selectQHFlds(format.code);
      this.primary= d.p;
      this.secondary= d.s;
      this.additional = ['-'].concat(d.p).concat(d.s);
      }
    }
    
   
  }
  