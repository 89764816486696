import { Observable, Subject } from "rxjs";
import { CommentService } from "../comment.service";
import { Comment } from "../comment";
import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core";
import { Router, RouterEvent, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { filter } from 'rxjs/operators';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFilm } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { MathJaxService } from "../../shared/math-jax.service";
//import { faFilm } from '@fortawesome/free-solid-svg-icons';

//declare var MathJax:any;

@Component({
  selector: "app-comment-list",
  templateUrl: "./comment-list.component.html",
  styleUrls: ["./comment-list.component.scss"]
})
export class CommentListComponent implements OnInit, OnChanges {
  @Input() cmntType:string;
  @Input() featureType: string;
  @Input() parentHdrId: number;
  @Input() parentDtlId: number;
  @Input() CRUD: boolean;
  @Input() commentsI : any;
  @Input() hideTitle : boolean;

  heading : string  
  showUpload: false;
  public comment:Comment = null;
  cmntType1:string;
  // comments: Observable<Comment[]>; for async..
  comments: Comment[];

  showUploadFile:boolean ;
  showAdd:boolean;
  showUpdate:boolean;
  showDetail:boolean;
  showReaction: boolean;
  provideResponse:boolean;
  id:number;
  disableAdd:boolean;
  updateAns:boolean;
  respondI:number;
  updateId: number;
  
  get offering() { return this.cs.getOffering()};
  get signedUser() { return this.cs.getSignedUser()}
  get userGroup() { return this.cs.getUserGroup()};
  get groupId() { return this.cs.getGroupId()};

  constructor(private route: ActivatedRoute,
    private router: Router, private commentService: CommentService,public cs: ClientInfoService,private fd: FixedDataService,private mj:MathJaxService
    ) {
    CommentService.updateList.subscribe(res=>{
       // alert("inside comnt srvce-updte lst with updateList subscrptn");
        this.fetchData();})
   
    }

  ngOnInit() {
    if(this.commentsI){
    //alert("inside comnt srvce-ngOnInit ");
    this.heading= this.fd.getCmntHeading(this.cmntType,this.groupId);
    //console.log(this.heading);
         this.comments = this.commentsI;
    } else{
      this.fetchData();
      
    if(this.cmntType && this.cmntType.includes("mtg")){  
    this.fetchData();
    }
    } 
    
    /* this may not be needed as @Input was not working due to absense of "' cons'" input parm 
     

    this.router.events.pipe(
    filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event) => {

      this.fetchData();

    });
   
*/
  }

  //Do sanity filter quickly and fail fast
 preChecker(btnId:string,i?:number){

if(this.cmntType=='Dans' &&  btnId== 'addComment'){
    
  if (this.comments && this.comments.length>0){ return false }  else{ 
      let status= this.cs.getStatus();
      if(status && !status.includes('wrk') ){
        return false;
      }
      return true};
 } 

if(btnId== 'detail'){ return false;}


return true;
}

//index and record is optional
enable(btnId:string,i?:number,rec?:any){

if(!this.preChecker(btnId,i)){ return false}

let component = 'cmntList';
let enable= this.cs.enable(component,btnId);
return this.customEnable(btnId,enable,i);
}

customEnable(btnId: string, enable: boolean,i?:number) {
//onlu during exam students are allowed to update answer
  if(enable){
let groupId= this.cs.getGroupId();
let status = this.cs.getStatus();


//console.log(groupId + " "+ status )

/*
if(this.cmntType =='Dans' &&  btnId == 'update'){

if( (groupId && groupId.includes('learner')) && ( status && status == "wrk") ){
//console.log(groupId + " "+ status + "is true"  );
return true;}
else{
 return false;
}

}
*/

}
//console.log( status + "is  false:"+ enable );
return enable;
}
  
  
  /*
  @Input() cmntType:string;

  @Input() featureType: string;
  @Input() parentHdrId: number;
  @Input() parentDtlId: number;
*/
  ngOnChanges(changes: SimpleChanges) {
   // console.log("from comment-list compo with values ${{featureType}}");
   
    if (changes.cmntType || changes.featureType|| 
      changes.parentHdrId||changes.parentDtlId ||changes.comments) {
        this.comments = [];
        if(this.commentsI){
          this.heading= this.fd.getCmntHeading(this.cmntType);
          this.comments = this.commentsI;
        } else{
        if(this.cmntType=="Hhome"){
          this.fetchData();
        }
        } 

    }  
    this.mj.typeSet();

    //MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);  
  }
  
   resetVar(){
    if(this.hideTitle){
    this.showAdd= true;
    } else{
    this.showAdd = false;
    }
    
    this.showUpdate=false;
    this.showDetail= false;
    this.showUploadFile= false;
    this.provideResponse=false;
    this.comments= null;

    this.heading= this.fd.getCmntHeading(this.cmntType,this.groupId);
    
    if(this.cmntType.startsWith('H')){
    this.showReaction= true; }
    else{
      this.showReaction= false;
     }
      
     
 

    
  } 

  fetchData() {



   // alert("inside comnt srvce-fetchdata- ");
    this.resetVar()
   
    //console.log("Retrieving Comment-list:input var -- " + this.parentHdrId + ' / ' + this.cmntType + this.featureType);
    //console.log("Retrieving Comment-list-cs var: " + this.parentHdrId + ' / ' + this.cs.getOffering() +" / " +this.cs.getCat());
    
    if(this.cmntType.startsWith('H')){
      (this.commentService.getCommentsByTypeFtrHdrId(this.cmntType,this.featureType,this.parentHdrId)).subscribe(data => {
        this.comments  = (data as any).dataList;
     }); 
       } else{
        if(this.cmntType.startsWith('D')){
      (this.commentService.getCommentsByTypeFtrDtlId(this.cmntType,this.featureType,this.parentHdrId,this.parentDtlId)).subscribe(data => {
        this.comments  = (data as any).dataList;
  }); 
        }

if(this.cmntType==="all" ){
  (this.commentService.getCommentsList()).subscribe(data => {
    this.comments  = (data as any).dataList;
}); 
}
  }

if(this.comments && this.comments.length==0){
  this.showAdd = true;
} 
else{
  this.showAdd = false;
} 

}


/* async pipe in html not working
let d  = this.commentService.getCommentsList();
this.comments = (d as any) .dataList;
*/
      

testHdr(id: number) {
  this.id = id;
  this.commentService.testHdr(id)
    .subscribe(
      data => {
        console.log(data);
        this.fetchData();
      },
      error => console.log(error));
}


  deleteComment(id: number) {
    this.id = id;
    this.commentService.deleteComment(id)
      .subscribe(
        data => {
          console.log(data);
          this.fetchData();
        },
        error => console.log(error));
  }


updateComment(id: number,i:number){
    this.updateId = i;
    this.id= id;
    this.showUpdate = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  respond(id: number,i:number){
    this.id = id;
    this.respondI= i;
    this.provideResponse = true;

    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  respondTick(id: number,i:number,comment:Comment){
    this.provideResponse = true;
    if(this.provideResponse){
      comment.rspnseUser= this.signedUser;
    } else {
      comment.addUser= this.signedUser;
    } 
  this.commentService.updateComment(id, comment)
      .subscribe(
        data =>  error => console.log(error));
    this.comment = new Comment();
    alert(this.heading + " updated");
    this.fetchData(); //refresh List
  }

  provideResponseComment(id: number){
    this.provideResponse = true;
    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  addComment(){
    this.comment = new Comment();
    this.comment.cmntType= this.cmntType;
    this.comment.featureType= this.featureType;
    this.comment.parentHdrId= this.parentHdrId;
    this.comment.parentDtlId= this.parentDtlId;
    this.comment.domain= this.cs.getSite();
    if(this.featureType.startsWith('trvsn') ||
    this.featureType.startsWith('trfc')
    ){
      this.comment.subject = this.cs.getSubject(); 
      this.comment.clsSec = this.cs.getCrtClsSec();
      this.comment.course = this.cs.getCourse();  
     //console.log(this.comment); 
    } 
    this.showAdd = true;
    return this.comment;
    //this.router.navigate(['eba','digibest','features','cmnt-add']);
  }

  uploadFile(){
    this.comment = new Comment();
    this.comment.cmntType= this.cmntType;
    this.comment.featureType= this.featureType;
    this.comment.parentHdrId= this.parentHdrId;
    this.comment.parentDtlId= this.parentDtlId;
    this.comment.domain= this.cs.getSite();
     this.showUploadFile = true;
    //this.router.navigate(['eba','digibest','features','cmnt-add']);
  }
  

  


  commentDetail(id: number){
    this.id = id;
    this.showDetail = true;
    //this.router.navigate(['eba','digibest','features','cmnt-dtl','byId', id]);
  }


}