import { Component, Input, OnInit } from '@angular/core';
import { ViewService } from '../view-lstr/view.service';

@Component({
  selector: 'app-status-based-teacher-view',
  templateUrl: './status-based-teacher-view.component.html',
  styleUrls: ['./status-based-teacher-view.component.scss']
})
export class StatusBasedTeacherViewComponent implements OnInit {
  @Input() hdr: any;
  @Input() i: number;
  

  constructor(public vs: ViewService ) { }

  ngOnInit(): void {
  }

}
