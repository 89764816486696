

<app-headings-plain *ngIf= 'hdrObj' [hdr]= 'hdrObj' > </app-headings-plain>

~
<app-rvsn-spdr [cmntType] = cmntType > </app-rvsn-spdr>
~

<button *ngIf='cs.enable("cmnt-viewer","allSite")' (click)= 'setSite("all")'> All Site </button>
<button *ngIf='cs.enable("cmnt-viewer","mySite")' (click)= 'setSite("")'> My Site </button>

<app-cmnt-list [ovrSite]= 'getSite()'  [cmntType] = cmntType 
[parentHdrId]= chapterNum
[mode] = '"list"'> </app-cmnt-list>

