import { Component, Input, OnInit } from '@angular/core';
import { ChptrService } from '../../pages/chptr-lstr/chptr.service';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { QstnhdrService } from '../qstnhdr.service';
import { Router } from '@angular/router';
import { BtnEnablerService } from '../../shared/btn-enabler.service';
import { ExamDataService } from '../../exam-data.service';
import { ExtraHdrService } from '../../gen/extra-hdr.service';
import { HeaderService } from '../../tag/header.service';

@Component({
  selector: 'app-dgtzr-btn',
  templateUrl: './dgtzr-btn.component.html',
  styleUrls: ['./dgtzr-btn.component.scss']
})
export class DgtzrBtnComponent implements OnInit {
  @Input() header:any;
  @Input() i :number;;
  get subject() { return this.cs.getSubject()}
  
  get rootUrl() { return this.cs.getRoot()}
  get signedUser() { return this.cs.getSignedUser()}
  showUpload:boolean;

  constructor(private router: Router,private qh: QstnhdrService,private ds: BoardDataService,private cs:ClientInfoService, public chptrS:ChptrService, private be:BtnEnablerService , private es: ExamDataService, public eh:ExtraHdrService, private hs: HeaderService) { }

  ngOnInit(): void {
  }

  enable(btnId:string,i?:number,hdrRec?:any){
    
    let component = 'dgtzrList';
     let enable = false;
     
     enable= this.cs.enable(component,btnId,"",hdrRec);
    
     /*
     if(btnId =='makeDraft'){
       enable= this.be.enable(component,btnId,hdrRec,false)
      } else{ 
       enable= this.be.enable(component,btnId,hdrRec);
       }
      */

     return enable;
    }

  
   getOriginal(h:any,i:number,urlType?:any){
   return this.eh.getValue(h.extra,"rawSrc");
   } 
   
   
cleanupQstn(hdr:any,i:number){
  this.ds.cleanupQstn(hdr).subscribe(data => {
    if((data as any).status === 200) {
    this.cs.addMsg("Success:cleanup done:" + (data as any).result );
    //console.log(data);
  }else {
       this.cs.addMsg("error,try later");
  }
  });
}

checkImage(hdr:any,i:number){
  this.ds.checkImage(hdr).subscribe(data => {
    console.log(data);

    if((data as any).status === 200) {
    this.cs.addMsg("Success:redigitization done:" + (data as any).result );
  }else {
       this.cs.addMsg("error,try later");
  }
  });
}


  
redigitize(hdr:any,i:number){
  this.ds.redigitize(hdr).subscribe(data => {
    console.log(data);

    if((data as any).status === 200) {
    this.cs.addMsg("Success:redigitization done:" + (data as any).result );
  }else {
       this.cs.addMsg("error,try later");
  }
  });
}
  
//idea is backend will take taghdrDto and create new tag hdr rec from that...
  // just change tagtype to TAhwh? (T) tag based - A(Activate) -- hwh or ?
  //addTagHeader(hdr);  extend...

  /* first link not working , hence moving back to old one
 parsedReport(header){
 let url= this.chptrS.reportName(header.extUrl,"Report"); 
 this.router.navigateByUrl(url);
 }
  */

 parsedReport(header:any,i:number, rptType:string){
  return this.chptrS.reportName(header.extUrl,rptType); 
   }

 

 parsedReportDlt(header){
 // this.cs.addMsg("??")
  //console.log(header.extUrl);
  let rptLink = "";
  if(header.extUrl){
    let f= header.extUrl;
    var index = f.lastIndexOf( "/" );
    //console.log("foreslash:" + index);
    if(index == -1){
      index = f.lastIndexOf( "\\" );  
    //  console.log("backslash:" + index);
    }
    if(index>0){
    f= f.substr(0,index)+ '/report'+ f.substr(index);   
    //console.log(f);
  rptLink= this.cs.getRoot()+f.replace('.txt','-Report.html'); 
    }
}
  else{
   rptLink= this.cs.getRoot()+ header.id +'-Report.html'; 
  
  }
  //this.cs.addMsg(this.rptLink);
return rptLink;  
}



  
  processDraft(hdr:any,i:number){
    this.ds.processDraft(hdr).subscribe(data => {
    if((data as any).status === 200) {
    let out = (data as any).result;
    let msg = (data as any).message;
    if (out && out.startsWith("ERROR")){
  //  this.cs.this.cs.addMsg({severity:'success', summary:out,detail:(data as any).message,sticky: true})   
   this.cs.addMsg(out,'warning'); 
  }
   else{
      // this.cs.this.cs.addMsg({severity:'error', summary:out,detail:(data as any).message,sticky: true}) 
      this.cs.addMsg(msg,'info'); 
      //this.cs.addMsg("Success:posting done:" + (data as any).result );
     } 
    } 
    else{
     this.cs.addMsg("Something went wrong,check console",'warning'); 
     console.log(data);
    }
  }
   );
  }
  
  parse(hdr:any,i:number){
    this.ds.parse(hdr).subscribe(data => {
    if((data as any).status === 200) {
    let out = (data as any).result;
    let msg = (data as any).message;
    if (out && out.startsWith("ERROR")){
  //  this.cs.this.cs.addMsg({severity:'success', summary:out,detail:(data as any).message,sticky: true})   
   this.cs.addMsg(out,'warning'); 
  }
   else{
      // this.cs.this.cs.addMsg({severity:'error', summary:out,detail:(data as any).message,sticky: true}) 
      this.cs.addMsg(msg,'toast'); 
      //this.cs.addMsg("Success:posting done:" + (data as any).result );
     } 
    } 
    else{
     this.cs.addMsg("Something went wrong,check console",'warning'); 
     console.log(data);
    }
  }
   );
  }
  

  finalizeDraft(hdr:any,i:number){
    this.ds.finalizeDraft(hdr).subscribe(data => {
      if((data as any).status === 200) {
        
      this.cs.addMsg("Success:posting done:" + (data as any).result, 'toast' );
      }else {
         this.cs.addMsg("error,try later");
    }
     });
  }


  onDemand(hdr:any,i:number){
    this.ds.onDemand(hdr).subscribe(data => {
      if((data as any).status === 200) {
        
        this.cs.addMsg("Success:transformer on demand ran:" + (data as any).result ,'toast' );
      }else {
        this.cs.addMsg("error,try later",'warning');
    }
     });
  }


  hdrSetTrial(hdr:any,i:number){
    this.ds.hdrSetTrial(hdr).subscribe(data => {
      if((data as any).status === 200) {
        
        this.cs.addMsg("Success:posting done:" + (data as any).result );
      }else {
        this.cs.addMsg("error,try later");
    }
     });
  }

  hdrSetCrtr(hdr:any,i:number){
    this.ds.hdrSetCrtr(hdr).subscribe(data => {
      if((data as any).status === 200) {
        
        this.cs.addMsg("Success:posting done:" + (data as any).result );
      }else {
        this.cs.addMsg("error,try later");
    }
     });
  }


  

  usrSetTrial(hdr:any,i:number){
    this.es.usrSetTrial(hdr).subscribe(data => {
      if((data as any).status === 200) {
        
      this.cs.addMsg("Success:posting done:" + (data as any).result );
      }else {
         this.cs.addMsg("error,try later");
    }
     });
  }


  usrSetCrtr(hdr:any,i:number){
    this.es.usrSetCrtr(hdr).subscribe(data => {
      if((data as any).status === 200) {
        
      this.cs.addMsg("Success:posting done:" + (data as any).result );
      }else {
         this.cs.addMsg("error,try later");
    }
     });
  }

  bstrSetTrial(hdr:any,i:number){
    this.ds.bstrSetTrial(hdr).subscribe(data => {
      if((data as any).status === 200) {
        
      this.cs.addMsg("Success:posting done:" + (data as any).result );
      }else {
         this.cs.addMsg("error,try later");
    }
     });
  }

  bstrSetCrtr(hdr:any,i:number){
    this.ds.bstrSetCrtr(hdr).subscribe(data => {
      if((data as any).status === 200) {
        
      this.cs.addMsg("Success:posting done:" + (data as any).result );
      }else {
         this.cs.addMsg("error,try later");
    }
     });
  }


changeField(h:any,field:any,value:any,i?:number){
    this.hs.changeField(h.id,h.type,field,value).subscribe(data =>{
       if ((data as any).status === 200) {
 this.cs.addMsg((data as any).message, 'info');         
 } else {
 this.cs.addMsg('error,try later', 'error');
 }  
 });
 }
}

