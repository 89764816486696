


<!-- logic is if note is there it will be visible to all, however add against all qstn visible to teacher to encourage customization  also Dnote is not enabled for adding comment in cmnt-title for student-->

<button  type="button" class="btn btn-outline-secondary border-0 "  (click)  ="show = !show"> + </button> 

<ng-container *ngIf=" show">
   
<app-whole-qstn-btn *ngIf='qs.enable("wholeQstnBtn")'
                        [q]='q'> </app-whole-qstn-btn>

<app-note *ngIf='qs.enable("addTeacherNote") ||(q.cmntsData && q.cmntsData.Dnote)' [q]= q [i] =i> </app-note>

<!--
clarify?: {{qs.enable("clarify",q,h,i)}}
-->
<app-doubt-dstr *ngIf='qs.enable("clarify",q,h,i)' [q]=q [i]=i>
</app-doubt-dstr>

<app-crud-qstndtl *ngIf='qs.enable("crudBtns-cstm",q,h,i)' [q]=q [i]=i [h] = h>
</app-crud-qstndtl>

<app-set-bldr *ngIf='true || qs.cat.includes("-bldr") || qs.cat.includes("-bwc") ' [q]=q [i]=i></app-set-bldr>

<app-slbs-mpr *ngIf='qs.cat=="sylbs"' [q]=q [i]=i></app-slbs-mpr>

</ng-container>

