
<app-messages></app-messages>

<router-outlet></router-outlet>


<!--app-ftr-router> </app-ftr-router-->

<!-- Footer top margin must be set on auto -->

  <!--
    <app-footer-label  class='d-none d-md-block'>  </app-footer-label> 
-->

<!--nav class="bg-primary py-0 float-center mt-auto navbar navbar-expand-sm  navbar-light sticky-bottom">
  
<app-footer-link [menuType] = "'floatingFooter'"> </app-footer-link>


</nav>

<app-footer></app-footer-->


  
