export class Alert {
  type:string;
  title:string;
  message:string //body
  delay:number=3500;
  constructor(message?:string,type?:string,title?:string){

  }


  }