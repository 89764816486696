import { Injectable } from "@angular/core";
import { Subject, ReplaySubject, Observable } from "rxjs";
import { BrowserWindowService } from "../gen/browser-window.service";
import { ClientInfoService } from "../shared/client-info.service";


interface MathJaxConfig {
  source: string;
  integrity: string;
  id: string;
  crossOrigin:string;
}

declare global {

    interface Window {


    MathJax: {

      

    
  loader: {
      load: [
        'input/tex-base', '[tex]/newcommand', '[tex]/action',
        'output/chtml', '[tex]/mhchem', '[tex]/enclose','[tex]/require'
      ]
    },
    

    tex: {
      inlineMath: [['``', '``'],['$', '$'], ['\\(', '\\)']],
      packages: ['base', 'newcommand', 'action','mhchem','ams','enclose', 'require'
    ]
    },
  
       typesetPromise: () => void;
      startup: {
        promise: Promise<any>;
      };
    };
  }
}

@Injectable({
  providedIn: "root"
})
export class MathService {
  private signal: Subject<boolean>;

  private mathJax: MathJaxConfig = {

    source : "https://cdn.jsdelivr.net/npm/mathjax@3.1.3/es5/tex-mml-chtml.js",
    integrity:"sha256-IhcxNQZPeVUaOuOM6+yjQ1Q00mG/zvHxSZOvNL/cmO4=", 
    id: "MathJaxScript",crossOrigin :"anonymous"
  };
  
  private mathJaxFallback: MathJaxConfig = {
    source: "assets/mathjax/mml-chtml.js",
    integrity: "sha256-ayts6I/2b1djaW1jw5nNfCVVZJyzTBmfS6ZwniJm6mA=",
    id: "MathJaxBackupScript",crossOrigin:"anonymous"
  };
  
  constructor(public bw:BrowserWindowService) {

    /*
    this.signal = new ReplaySubject<boolean>();
    void this.registerMathJaxAsync(this.mathJax)
      .then(() => this.signal.next())
      .catch(error => {
        
        void this.registerMathJaxAsync(this.mathJaxFallback)
          .then(() => this.signal.next())
          .catch((error) => console.log(error));
        
      });
      */

  }

  private async registerMathJaxAsync(config: MathJaxConfig): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      
      
const MathJax : any= {
    
  loader: {
      load: [
        'input/tex-base', '[tex]/newcommand', '[tex]/action',
        'output/chtml', '[tex]/mhchem', '[tex]/enclose','[tex]/require'
      ]
    },
    

    tex: {
      inlineMath: [['``', '``'],['$', '$'], ['\\(', '\\)']],
      packages: ['base', 'newcommand', 'action','mhchem','ams','enclose', 'require'
    ]
    }
  };

      const script: HTMLScriptElement = document.createElement("script");
      script.id = config.id;
      script.type = "text/javascript";
      script.src = config.source;
      script.integrity = config.integrity;
      script.crossOrigin = "anonymous";
      script.async = true;
      script.onload = () => resolve();
      script.onerror = error => reject(error);
      document.head.appendChild(script);
    });
  }

  ready(): Observable<boolean> {
    return this.signal;
  }

  render(element: HTMLElement, math: string) {

    // Take initial typesetting which MathJax performs into account
    if(this.bw.hasWindow()) {
    window.MathJax.startup.promise.then(() => {
      element.innerHTML = math;
    if(window.MathJax){
      window.MathJax.typesetPromise();
    }
    }
    );
  }

  }

  typeset(){
    if(this.bw.hasWindow()) {
    window.MathJax.startup.promise = window.MathJax.startup.promise
    .then(() => window.MathJax.typesetPromise())
    .catch((err) => console.log('Typeset failed: ' + err.message));
   return window.MathJax.startup.promise;
    }
  }


}
