

<div [hidden]="submitted" >

  <form  #addForm="ngForm" (ngSubmit)="onSubmit(c)">
  
   
<app-cmnt-mnt [c] =c  [i]= [i]  [offering] = offering [parentFormGroup] = addForm> </app-cmnt-mnt>
   

<button type="submit" class="btn btn-primary"
      [disabled]="!addForm.valid">Submit</button>
    
    
</form>

</div>


<div [hidden]="!submitted">

<h4>You submitted successfully!</h4>

</div>  