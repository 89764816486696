import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-chptr-view-selector',
  templateUrl: './chptr-view-selector.component.html',
  styleUrls: ['./chptr-view-selector.component.scss']
})
export class ChptrViewSelectorComponent implements OnInit {
  view: string;

  get cat() { return this.cs.getCat() };
  get subject() { return this.cs.getSubject() };

  get userId() { return this.cs.getUserId() }
  get status() { return this.cs.getStatus() };
  get clsSec() { return this.cs.getClsSec() };
  get signedUser() { return this.cs.getSignedUser() };
  get justSite() { return this.cs.getJustSite() };
  get unsigned() { return this.cs.isUnsigned() }
  get mentorId() { return this.cs.ss.getMentorId() }
  get school() { return this.cs.ss.getSchool() }

  staticStore = ['ebstore', 'dcstore','istore','crud'];

  constructor(private cs: ClientInfoService) { }

  ngOnInit(): void{};
  
  getView(): string {
    this.view= this.cat; //default view
    if (this.staticStore.includes(this.cat)) {
      this.view = 'qstn';
    }
    else if (this.cat.startsWith('siteowned') || this.cat== "dgtzn_mngr" ||
    this.cat == "my11dgtzr") {
      this.view = 'siteowned';
    }
    else if (this.cat.startsWith('siteowned') || this.cat.startsWith('my') ) {
      this.view = 'fltr';
    }
    else if (this.cat.endsWith('src-slctr')) {
        this.view = 'src-slctr';
    }
    
    else if
      (this.cat.startsWith('multi-slctr') || this.cat.startsWith('multiSlctr') || this.cat.endsWith('multiSlctr')) {
      this.view = 'multi';
    }

    else if (this.cat.endsWith('-bldr')||
    this.cat.endsWith('-base-slctr')) {
      this.view = 'base';
    }
 
    else if (this.cat.endsWith('uni-slctr')) {
      this.view = 'uni';
    }
 

    else if (this.cat.startsWith('m1tg')) {
      this.view = 'qstn';
    }
    
    return this.view;
  }
}



