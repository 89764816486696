import { Component, OnInit, Input } from '@angular/core';
import { Header } from '../header';
import { HeaderService } from '../header.service';
import { Router } from '@angular/router';
import { FileService } from '../../shared/file.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { ExtraInfo } from '../../shared/assigner/extra-info';


@Component({
  selector: 'app-update-header',
  templateUrl: './update-header.component.html',
  styleUrls: ['./update-header.component.scss']
})
export class UpdateHeaderComponent implements OnInit {

  
  @Input()  id: number;
  submitted:boolean;
  header:any;
  ei:ExtraInfo;

  constructor(private headerService: HeaderService,
    private router: Router,private cs:ClientInfoService) { }

  ngOnInit() {
    this.submitted = false;
   // this.header = new Header();

   // this.id = this.route.snapshot.params['id'];
    
    this.headerService.get(this.id)
      .subscribe(data => {
        console.log(data)
        this.header = data;
        if(this.header){
        if(!this.header.extra){this.header.extra= "{}"}  
        this.ei= JSON.parse(this.header.extra)
        }
        //console.log(this.header)
        if(!data){
        this.cs.addMsg("couldn't get hdr"); 
        }
      }, error => {
        console.log(error)
        this.cs.addMsg("couldn't get hdr"); 
      }
        );
/*
    FileService.updateUploadUrl.subscribe(res=>{
      //alert("inside comnt srvce-updte lst: "+ res);
      this.header.extUrl = res})
*/

  }

  

  onSubmit() {
    this.submitted = true;
    this.update();
  }


  gotoList() {
  // alert("header added");
  this.cs.addMsg("header updated",'toast');
   HeaderService.updateList.next(true);

   // this.router.navigate(['//eba//digibest//features//headers']);
  }



  update() {
   // console.log(this.header);
   //data => console.log(data),
   if(this.ei){
    this.header.extra= JSON.stringify(this.ei);
   }
    this.headerService.update(this.id, this.header)
      .subscribe( error => console.log(error));
    this.header = new Header();
    
    //alert("header updated");
    this.gotoList();
  }

  delete(id:number) {
     this.headerService.delete(id)
       .subscribe( error => console.log(error));
     this.header = new Header();
     alert("header id: " + id +   " Deleted");
     this.gotoList();
   }

   enable(btnId:string) {
    return true;
   }
}



