

<button *ngIf="(cm.offering && !cm.chapter && (cm.enable('showQH')))"
    (click)="show= !show" class="badge border-0 btn-outline-primary btn  px-1 float-right ml-3" >
     {{show?'Hide Add':'Add ' + hdrName}} <i
       class="fa fa-book" aria-hidden="true"></i> </button>



<ng-container *ngIf="show">
    
    <app-add-header *ngIf="tagBased;else showQh;" [iHeader]=hdr>
    </app-add-header>

    <ng-template #showQh>
       
        <app-qstnhdr-add [ovrHdrFormat]='ovrHdrFormat'>
        </app-qstnhdr-add>
    </ng-template>

</ng-container>