import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgModule, ElementRef, Injectable } from "@angular/core";
import { BrowserModule, Title,Meta, enableDebugTools } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { ReactiveFormsModule, FormControl, FormsModule } from "@angular/forms";
import { BoardDataService } from '../../board-data.service';
import { Board } from '../../board';
import { ClientInfoService } from '../../shared/client-info.service';
import { CommentService } from '../../comments/comment.service';
import { SecurityService } from '../../shared/security.service';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

import {
  HttpClientJsonpModule,
  HttpClientModule,
  HttpClient
} from "@angular/common/http";

import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FixedDataService } from '../../shared/fixed-data.service';
import { HeadingsService } from '../../shared/headings/headings.service';
import { UsrhdrService } from '../../user/usrhdr.service';
@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {
 
site:string;
cat:string;
board:string;
course:string;
offering: string;

offeringTitle: string;
subjects: Object;
subjects1: Object;
subject1: any;
headers: Object;
headerById: any;
hdrFormat:string;
board1:string ;
subject:string;
boards:Board[];
header:string;
chapter:string;
content:string;
questions:Object;
boardDetails:Object;
showT:boolean;
taghdr: number;
subind:number;
headerId: any;
siteHead : string;
titleHead : string;
titleDesc: string;
subjectList: string;
location:Location;
hdrTitle:string;
dfltBoard:string;
boardNeeded:boolean;
//signedUser:string;
hostName:string;
//school:string;
schlLoc:string;
src:string;
uTHeaders:any
bQHeaders:any
navbarOpen = false;
comments: any;
feature:string;
  hdrObj: any;
  qstnId: any;
  count: any;
 showOld:boolean=false;
  loading: boolean = true;


get prod(){ return this.cs.isProd()};
get status(){ return this.cs.getStatus()};
get clsSec(){ return this.cs.getClsSec()};
get signedUser(){ return this.cs.getSignedUser()};
get justSite(){ return this.cs.getJustSite()};
get unsigned(){return this.cs.isUnsigned()}
get mentorId(){return this.cs.ss.getMentorId()}
get school(){return this.cs.ss.getSchool()}
get userId(){return this.cs.getUserId()}
get grpAsoId(){return this.cs.grpAsoId}
overrideMap = new Map<string,Array<string>>();
get subMenu() { return this.cs.enable("site","subMenu")}
get enableMenu() { return this.cs.enable("site","menu-site")}
// Declaring the Promise, yes! Promise!
btnMapLoaded: Promise<boolean>;

constructor(private route: ActivatedRoute,
    private router: Router, private elementRef:ElementRef, private dataService: BoardDataService, private titleService: Title,
    private metaTagService: Meta, public cs:ClientInfoService,public fd:FixedDataService,private loc: Location, private sec:SecurityService,private commentService: CommentService,
    public hdng:HeadingsService, private uhs: UsrhdrService
    ) {

      

      //this.board= "Exam";
//this.titleHead= "Exam Steppers";
this.titleHead= "Digitally Steps ahead";
this.titleDesc= "The Most advanced Learning App with AI & Digital Contents .... ...Our goal is to help students and educators in augmenting pleasant experiences and eliminating/reducing noises by using latest and greatest AI, Machine Learning & Analyics driven Digital Techonlogies in most advanced manner to make learning natural and fun in the current digital transitory world.. ..  ";
//this.site= this.dataService.getSite();
//Exam made easy with AI & Digital Contents

/*
console.log(window.location.hostname);
alert(loc.path());
const angularRoute = this.loc.path();
const url = window.location.href;
const domainAndApp = url.replace(angularRoute, '');
*/

this.route.params.subscribe( params => {
  //this.setDefault();
  cs.updateParms(route);
  this.site= this.cs.getSite();
}) ;
  

let btnData= JSON.parse(localStorage.getItem(this.site +'-btnData'));

if(btnData){
fd.loadBtnData(btnData);
this.btnMapLoaded = Promise.resolve(true);
// console.log('API CALL avoided. getting btnMap');
}

//!fd.updatedBtnMap
if(!this.btnMapLoaded ){
//console.log('calling overrideMap');
this.dataService.getBtnMap(this.site).subscribe(
data=> {
 // console.log(data);
 if((data as any).dataList  && (data as any).dataList.length >0 ) {
//let map= (data as any).map;
//fd.loadBtnAuthMap(map);
//console.log('API CALL. getting btnMap');
fd.loadBtnData(data);
localStorage.setItem(this.site + '-btnData', JSON.stringify(data));
this.btnMapLoaded = Promise.resolve(true); // Setting the Promise as resolved after I have the needed data
this.loading = false;
} else{
 
 this.count = this.count +1; 
 if(this.count > 2){ 
  this.loading = false;
  this.cs.addMsg('internet/backend problem,try again',"warning");}

}
});
}

// upto this moving to ftr-router


this.route.params.subscribe( async params => {
//this.setDefault();
cs.updateParms(route);
this.site= this.cs.getSite();

if(this.site){
// this.cs.siteObj= await this.cs.getSiteInfo(this.site);

 
  if(!this.cs.siteObj && localStorage.getItem('siteObj-'+this.site)){
    this.cs.siteObj= JSON.parse(localStorage.getItem('siteObj-'+this.site));  
  }
  else{
  let fetched = await  this.uhs.domainRec(this.site).toPromise();
  if(fetched.status==200 && fetched.result){
    let siteInfo = fetched.result;
    localStorage.setItem('siteObj-'+this.site , JSON.stringify(siteInfo));
    this.cs.siteObj= siteInfo;
  }
}


}


this.cat= this.cs.getCat();
if(!this.cat && this.cs.siteObj.dfltCat){
  this.cs.setCat(this.cs.siteObj.dfltCat);
  this.cat= this.cs.getCat();
}



this.board= this.cs.getBoard();
if(!this.board){
  this.cs.setBoard(this.cs.siteObj.dfltBoard);
  this.board= this.cs.getBoard();
}

this.course= this.cs.getCourse();
if(!this.course){
  this.cs.setCourse(this.cs.siteObj.dfltCourse);
  this.course= this.cs.getCourse();
}

this.offering= params['offering'];
this.feature= params['feature'];
//alert("board is : " + this.board);
this.subject= params['subject'];
this.chapter= params['chapter'];
this.content= params['content'];
this.qstnId= params['qstnId'];

//enable selective later

  //don't show single listing -- skipSingleListing
if(!this.chapter && this.fd.skipSingleListing(this.offering)){
this.checkUnits();
}
//need to think
//this.hdng.setSEO(null);
});

// working.. use seoservice generic..
/*
if(this.site.startsWith("eb") || (this.cs.bw.hasWindow() &&window.location.hostname.includes("edubestu"))){
this.cs.seo.updateTitle("Welcome to best education Platform for integrated learning")
this.cs.seo.updateDescription("WE enable school, collages & coaching centers with digibest platform with intgrated AI, online and digital technologies");
//console.log("seo updated title and description for edubestu" + this.site) ;
}
*/


/*
if(!this.site){
console.log("from main routine of site compo"+ this.hostName);
if(window.location.hostname.includes("edubestu")){
  //this.site= 'eba';
  //this.cat=  'ebhome'
  console.log('SET from line 98- Welcome to EduBestu...');
  this.router.navigate(['eba','ebhome']);
} else{
  //if(window.location.hostname.includes("examsteppers")){
    this.site= 'esa';
    this.cat=  'home'
    console.log('SET from line 105- Welcome to ExamSteppers...');
    this.router.navigate(['esa','home']);
  //}
}

}
*/




this.updateDescription();

if(this.board){
//localStorage.setItem('board', JSON.stringify(this.board));
}
this.dfltBoard=cs.getBoard();




this.headerId= this.chapter;

if(this.board == null){
this.board = this.elementRef.nativeElement.getAttribute('board');

};



// alert(this.offering); fix SEO please...
// commenting out below.. remove if it needed 
//this.updateDescription();



/*
this.dataService.getSiteSubjects(this.site,this.board,this.course,this.offering).subscribe(dataList => {

        this.subjects  = dataList;
});

Fix this bug later...
alert("entered the offering condition with new value"+ this.offering);
*/








     }



/*
     onlySubject(subject:string){
      
      return this.cs.onlySubject(subject);
    }
    */


   
    enable(btnId:string,i?:number){
         
     let catwithMentor = ['engage'];

     if(btnId =='productView' && this.cat != "home"){
      
      if(this.cat.startsWith('prd')||this.cat.startsWith('ftr') ) {return true} 
     
      /*
      if(catwithMentor.includes(this.cat) 
      //&&  (this.unsigned||(this.school != "lwb" && !this.mentorId))
      ) 
      {
        // this.cs.navigate("ftr/"+ this.cat,"");
      return true;
      }
      */

      }
     

     return this.cs.enable('site',btnId);


    }

    updateDescription() {

    }


goBack(): void {
  this.location.back();
}


fetchData(): void{

if(this.chapter || this.grpAsoId){
  let id = this.chapter?this.chapter:this.grpAsoId
 this.headerById = null;
  this.dataService.getHdrObj(+id).subscribe(data => {
  //console.log(data);
  if(data && (data as any).status==200 && (data as any).result){
  this.hdrObj = (data as any).result;
  }

  }
);

if(this.hdrObj && this.hdrObj.extUrl){
  this.src = this.hdrObj.extUrl;
  }    

 /*remove below...
this.dataService.getSiteHeaderById(this.site,this.board,this.course,this.offering,this.subject,this.headerId).subscribe(dataList => {
  //console.log(dataList);
  this.headerById = dataList;
  //this.hdrTitle= this.formatContentHeader();
  this.src= this.headerById.dataList[0].extUrl;
});
return;
*/
}


  let sub= this.subject?this.subject:'subjects';
 
   if (!this.subject && this.offering){
      this.dataService.getSiteSubjects(this.site,this.board,this.course,this.offering).subscribe(data => {
  
        this.subjects  = data;
      });
  
     }
     /*not needed?
    if(this.subject){
    this.dataService.getSiteHeaders(this.site,this.board,this.course,this.offering,this.subject).subscribe(data => {
   this.headers= data;
   this.headerById= data;
   //this is buggy logic when we have muti hdrs
   //this.hdrObj = (data as any).dataList[0];
   //console.log(this.hdrObj);
   this.hdrTitle= this.formatContentHeader()
        });
   }
  */ 
 }


fetch1Data(): void{
 /*
  if(this.cs.getOffering().trim()==='orb' && this.cs.getChapter()){
    alert("assigning navigation"+ this.cs.getOffering());

    this.router.navigate([this.cs.getSite(),this.cs.getCat(),'features','pdfviewer',this.cs.getBoard(),this.cs.getCourse(),this.cs.getSubject(),'doc']  );
    alert("assigned navigation");
    return;
  }
  */

//comment-list refresh update
 //CommentService.updateList.next(true);

  this.boardNeeded=this.cs.boardMust();
  this.schlLoc=this.cs.getSchlLoc();
  //this.school=this.cs.getSchool();
  //this.signedUser = this.sec.getSignedUser();

this.headerId= this.chapter;

  //this.updateDescription();
  if(this.offering){
  if(this.subject){
  this.dataService.getSiteHeaders(this.site,this.board,this.course,this.offering,this.subject).subscribe(data => {
 this.headers= data;
 this.headerById= data;
 this.hdrTitle= this.formatContentHeader()
      });

  }

  }

//|| this.subject

  if(this.offering ){
   if(this.offering !='else not using') {
  this.dataService.getSiteSubjects(this.site,this.board,this.course,this.offering).subscribe(data => {

  this.subjects  = data;
//  alert((this.subjects as any).dataList.length);
var list= (this.subjects as any).dataList;
if(list && (list.length==1)){
//alert(list[0].name + list[0].questionHeaderEs[0].id)
//console.log('yessss');
//console.log(list);
//note: chapterlist needs headers and subject..
// need further fix later....for one subject direct listing..
//this.subject= list[0].name;
//this.headers= list[0].questionHeaderEs;
}



});
   } else{
    this.dataService.getSiteHWSubjects(this.site,this.board,this.course,this.clsSec,this.offering,this.signedUser).subscribe(data => {

      this.subjects  = data;
    });
   }

}


/* qstn-optm starts
if(this.headerId){ //check later this clause
this.dataService.getSiteQuestions(this.site,this.board,this.course,this.offering,this.subject,this.headerId).subscribe(dataList => {
        this.questions = dataList;
});
this.dataService.getSiteHeaderById(this.site,this.board,this.course,this.offering,this.subject,this.headerId).subscribe(dataList => {
  this.headerById = dataList;
  this.src= this.headerById.dataList[0].extUrl;
   });
  }
  */ // qstn optmzn-end
        // this.hdrFormat= this.headerById.dataList[0].hdrFormat;
  //console.log("hdrFormat is:" + this.hdrFormat);

        //alert("assigning "+ this.cs.getOffering());

/*
var list= (this.questions as any).dataList;
if( (list.length==0) && this.headerById[0].extUrl.includes(".pdf")){
}
alert("are you there?");
if(this.cs.getOffering().trim()==='orb'){
  alert("assigning orb"+ this.cs.getOffering());
  this.router.navigate([this.cs.getSite(),this.cs.getCat(),'features','pdfviewer',this.cs.getBoard(),this.cs.getCourse(),,this.cs.getSubject(),'doc']  );
}
*/

    

//this.setupTitle();


/*
//remove later

this.dataService.getSignedUser().subscribe(response=> {
        this.signedUser = (response as any).user as string;
        //alert("signed User  retrieved "+ this.signedUser);
        //console.log(response);
      });
*/

}

formatContentHeader(){
  if(this.headerById && this.headerById.dataList[0]){

  this.hdrTitle = [this.headerById.dataList[0].year,this.headerById.dataList[0].chapterId,this.headerById.dataList[0].section,this.headerById.dataList[0].title].filter(Boolean).join("-");
 return this.hdrTitle;

 }
}

//purpose of this routine is to bypass unitlist if we have just one record to list
//check if single subject needs similar logic.
checkUnits(){
//alert("Inside checkUnits1"+ this.headerId);

  if(this.subject && !this.chapter){
  this.dataService.getSiteHeaders(this.site,this.board,this.course,this.offering,this.subject).subscribe(data => {
   this.headers= data;
   //console.log("-----")
  //   console.log(this.headers)
  //   console.log("this.headers")
var list = (this.headers as any).dataList;
  //alert("Inside checkUnits2 "+ list.length);
  //check only for CatList - first recorded expected to be pickedup out of comon and particular records..
  if(list.length >= 1) {
  this.headerId= list[0].id;
}

//the below is for only one chapter getting auto navigated...
  if(list.length == 1) {
  this.headerId= list[0].id;
  //alert("Inside checkUnits3-don't come"+ this.headerId);
  this.chapter= this.headerId;
  let hdr= list[0];
  //offering should be of hdr as asgn etc get overrided.. 
  if(this.chapter){
  this.router.navigate([this.cs.getSite(),this.cs.getCat(),this.cs.getBoard(),this.cs.getCourse(),hdr.type,this.cs.getSubject(),this.chapter] );
  //  alert("Inside checkUnits3"+ this.chapter);
  }}

  });


    }
}

random(){
 if(Math.floor(Math.random()*100)/5 == 0){
   return true;
 } else{
   return false;
 }
}

showEE(){
  if(!this.board){
    alert("Please select exam/Class first");
    return;
  }
}

getHdr(headerById:any){
if(this.hdrObj){
return this.hdrObj;  
}  
//remove below later
if(headerById && headerById.dataList ){
  return headerById.dataList[0];
}
else{
//console.log("Error:header not found");
//return '';
}
}

  ngOnInit() {

    this.fetchData();

    this.router.events.pipe(
    filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event) => {

      this.fetchData();

    });





//alert("entered the offering condition with new value"+ this.offering + "IN NGINT");

   if(!this.board){
  //this.headerId= 19569;
  this.header= "Please select Board::";
  } else{
   this.header= "change Board";
  }

if(this.offering){
if(this.subject){
this.dataService.getSiteHeaders(this.site,this.board,this.course,this.offering,this.subject).subscribe(dataList => {
     this.headers= dataList;
     // put headerById here
    });
//Get UT List
/*
    this.dataService.getSiteHeaders(this.site,this.board,this.course,'ehw',this.subject).subscribe(dataList => {
      this.uTHeaders= dataList;
      // put headerById here
     });

   */

}
}

// || this.subject

if(this.offering && false){
this.dataService.getSiteSubjects(this.site,this.board,this.course,this.offering).subscribe(dataList => {

        this.subjects  = dataList;

        (this.subjectList as any)  = (this.subjects as any).dataList;

 if(this.subjectList.length==1){
 //alert((this.subjectList[0] as any).name  + " got size " + this.subjectList.length );
 //this.subject=(this.subjectList[0] as any).name;
}
 });
}

/* qstn-optmn starts
if(this.headerId){ //check later this clause
this.dataService.getSiteQuestions(this.site,this.board,this.course,this.offering,this.subject,this.headerId).subscribe(dataList => {
        this.questions = dataList;
    });
 
    this.comments= new Array();
  if(this.status=="clr"){
   // alert("calling");
    this.commentService.getCommentsByTypeFtrHdrId("Dclr",this.offering,this.headerId).subscribe(data => {
    let comments   = (data as any).dataList;
    Array.prototype.push.apply(this.comments,comments);
    console.log(this.comments)
}); 
this.commentService.getCommentsByTypeFtrHdrId("Dbkt",this.offering,this.headerId).subscribe(data => {
  let comments  = (data as any).dataList;
  console.log(comments)
 // this.comments.concat(comments);
  Array.prototype.push.apply(this.comments,comments);
  console.log(this.comments)
  }); 

}  else{
  this.comments= [];
} 

// this is for displaying header data on content list page...

this.dataService.getSiteHeaderById(this.site,this.board,this.course,this.offering,this.subject,this.headerId).subscribe(dataList => {
        this.headerById = dataList;
        this.src= this.headerById.dataList[0].extUrl;
        
    });
}
 // qstn-optimzation ends */








/*
  this.boards= new Array<Board>();


  this.boards.push(new Board('ICSE','X','Indian Certificate of Secondary Education') );
   this.boards.push(new Board('CBSE','XI','Central Board of Secondary Education') );
   this.boards.push(new Board('NEET','XII','National Eligibility cum Entrance Test') );
   this.boards.push(new Board('BSEB','X','Bihar Board') );

 // this.boards.push(new Board('ICSE','X-','Home work'));
 */


  }


}
