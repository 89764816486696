import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CommentService } from '../../comments/comment.service';
import { PerformanceService } from '../performance.service';


@Component({
  selector: 'app-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: ['./data-view.component.scss']
})
export class DataViewComponent implements OnInit {
  @Input() user:string;
  
  mts$: Observable<any>;
  

  constructor(private cmntSrvc:CommentService,private performSrvc:PerformanceService) { }
  
  ngOnInit(): void {
    this.mts$ = this.performSrvc.getMistakeList(this.user) ; 
  }

}
