
<!-- used for 1 record creation-->

<!--
     used for unit/chpt-bstr based on qstnSrcHdrid/template -->

<button *ngIf="bs.cs.enable('cstmBstrmkr','makeUnitBstr',null,refHdr)" (click)="makeCstmBstr(bstrType)" class="btn badge btn-info"
style="margin-left: 10px"> make chptr {{bstrType}} Bstr</button>

<!--

<button (click)="makeCstmBstr(bstrType)" class="btn badge btn-info"
style="margin-left: 10px"> take {{bstrType}} Bstr</button>

<button (click)="makeCstmBstr(bstrType)" class="btn badge btn-info"
style="margin-left: 10px"> add {{bstrType}} Test/Booster</button>



<button  (click) = 'process()'> BACK</button>
-->