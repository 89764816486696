
<app-daily-detail [type] = '"fresh"' [subject] = '"DidYouKnow"'> </app-daily-detail>



<ng-container *ngIf= "false && q"> 
<p class="bg-light text-success text-center my-3" >  EduBestu is liked by teachers and students equally! check features why </p>

<div class="fullWidth text-left  ">

    <span class="font-weight-bold"> #{{formatSeq(q.qstnId, q.subqstnId,q.partId)}}
    </span>
    <span class="badge text-danger btn-secondary"
        *ngIf="q.status == 'outSlbs21'"> Deleted for 21 </span>
  
    </div>

    <div class="text-center" *ngIf="q.title">
        <span class=""> {{q.title}} </span>
    </div>

    <div class="text-center" *ngIf="q.section && !q.qstnId">
        <span class="lead"> Section : {{q.section}} </span>
    </div>


    <!--  
    <div *ngIf="q.vidLink" class="my-2 text-center">
    <app-video class= "text-center mb-1 border-0" [q]=q [i]=i> ...</app-video>
    </div>
-->
    <p *ngIf="q.wireFrame " class="" [innerHTML]="q.wireFrame|safe :'html'"></p>

    

    <div *ngIf="q.instruction" class=""
        [innerHTML]=q.instruction></div>

    <div *ngIf="q.question" >
        <div innerHTML="{{  q.question }}"></div>
    </div>


    
    <app-url-shower *ngIf="q.extLink" [format]=q.format [extLink]=q.extLink
    [title]=q.title [headerObj]=null>
    </app-url-shower>
    
    <!--
    <div class= "float-right mr-3" *ngIf="q.wireFrame || q.vidLink || q.format=='intVid' "> 
        <app-rating class= "float-right" [q] = q> </app-rating> 
     </div>
-->
   
</ng-container>