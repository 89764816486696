<div class= "card container">
    <h5 class ="py-2 text-primary"> Add Site</h5>
  
  <div *ngIf="!success">
  
      
  <form [formGroup]="siteForm" (ngSubmit)="onSubmit()">
  
  
  <h5 *ngIf="success">Your form is valid!</h5>
  
      
  <div class= "row">
        
        <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="name" placeholder="Name of the Center">
        
        <span  *ngIf="submitted && siteForm.controls.name.errors" class="error">
          <span *ngIf="siteForm.controls.name.errors.required">Name of the Center is required</span>
        </span>
      
      
        <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="location" placeholder="Location">
        <span *ngIf="submitted && siteForm.controls.location.errors" class="error">
          <span *ngIf="siteForm.controls.location.errors.required">Location is required</span>
        </span>
      
    
  
        <input class="form-control col-9 col-md-4 m-1" type="text" formControlName="domain" placeholder="domain">
        <div *ngIf="submitted && siteForm.controls.domain.errors" class="error">
          <div *ngIf="siteForm.controls.domain.errors.required">site code at our site, should be unique</div>
        </div>
  
  
        <div class= "col-1 mt-2"  *ngIf= "true">
        <button type= "button" (click) ='isAvl(site,domain)'> check        
          </button>
  
        <span class="text-success" *ngIf= 'idAvailable=="true"'> &#10003;  </span> 
        <span class="text-danger" *ngIf= 'idAvailable=="false"'> &#10006;  </span> 
        
        </div>
           
        <input class="form-control col-10 col-md-5 m-1" type="owner" formControlName="owner" placeholder="owner">
        <div *ngIf="submitted && siteForm.controls.owner.errors" class="error">
          <div *ngIf="siteForm.controls.owner.errors.required">owner is required</div>
        </div>
      
     
  
        <select formControlName="branchType" class="col-10 col-md-5 m-1 browser-default custom-select" placeholder= "branchType/Exam">
          <option value="" disabled>Main branchType/Exam</option>
          <option value="ebs" selected>School</option>
          <option value="ebc">Coaching Center</option>
          <option value="ebi">College</option>
          <option value="pvt">University</option>
          <option value="Other">Other</option>
        </select>
  
        <!--
        <select formControlName="type" class="col-10 col-md-5 m-1 browser-default custom-select">
          <option value="" disabled>Class/type</option>
          <option value="X" selected>X</option>
          <option value="XI">XI</option>
          <option value="XII">XII</option>
          <option value="civil">Civil Eng</option>
          <option value="ee">Electrical Eng</option>
          <option value="IX">IX</option>
          <option value="Other">Other</option>
        </select>
  
        -->

        <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="phone" placeholder="Phone">
        <div *ngIf="submitted && siteForm.controls.phone.errors" class="error ">
          <div *ngIf="siteForm.controls.phone.errors.required">Phone number  is needed</div>
        </div>
  
      
        <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="email" placeholder="Email">
        <div *ngIf="submitted && siteForm.controls.email.errors" class="error ">
          <div *ngIf="siteForm.controls.email.errors.required">An Email Id is required please</div>
        </div>
        
  </div>     

        <!--
      <button type="button" class="btn badge btn-secondary my-1  " (click)="expand=!expand"> + optional  
        Fields </button>
        -->

        <!-- Field expander start -->
        
        
          <div  class= "row" *ngIf="expand">
      
           
            <ng-container *ngIf = 'site !== "esa"' >
            
            <!--    
           <input class="form-control col-9 col-md-4 m-1" type="text" formControlName="overSite" placeholder="siteCode,only if provided">
           
           <div class= "col-1 mt-2"  *ngIf= "true">
            <button type= "button" (click) ='validateSiteCode(ovrSite)'> verify  </button>
            <span class="text-success" *ngIf= 'validSiteCode=="true"'> &#10003;  </span> 
            <span class="text-danger" *ngIf= 'validSiteCode=="false"'> &#10006;  </span> 
            
            </div>
           -->
             
        
  
        <input class="form-control col-10 col-md-5 m-1" type="text" formControlName="extra" placeholder="extra message">
        <div *ngIf="submitted && siteForm.controls.roles.errors" class="error">
          <div *ngIf="siteForm.controls.roles.errors.required">extra message,optional" </div>
        </div>
        
  </ng-container>
  </div>
  
  <p-captcha *ngIf= '!root.includes("localhost")' class= "g-recaptcha m-1" siteKey="6LfZkN8UAAAAAMkiREqITHPj6E_cj9Xn1cX_tcrv" (onResponse)="showResponse($event)"></p-captcha>
  
  
  <input class="m-2 btn btn-primary btn-lg" type="submit" value="Submit" >
  
  
  
   <div class ="my-2">
  
      <i class= "text-golden small">Note: Phone/email is needed for Legal obligations only. Privacy assured.</i>
      </div>
  
    </form>
  
  </div>
  
  
  
  </div>
  
  
    <div *ngIf="success" class="results">
        <p class="alert alert-info">
          <ngb-alert [dismissible]="false">
             Thanks for registering {{siteForm.controls.name.value}}...
          </ngb-alert>
        </p>
    </div>
  
  
  