
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ListerService } from '../../chat/lister.service';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-subject-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.scss']
})
export class BookListComponent implements OnInit {

  sl$: Observable<any>;
  clsSec$: Observable<any>;
    
get site() {return  this.cs.getSite()} ;
get cat() {return this.cs.getCat() };
get board() {return this.cs.getBoard()};
get course() { return this.cs.getCourse()};
get feature() { return this.cs.getFeature()};
get offering() { return this.cs.getOffering()};
get justOffering() { return this.cs.getJustOffering()};
get subject() { return this.cs.getSubject()};
get justSubject() { return this.cs.getJustSubject()};

  constructor(private cs:ClientInfoService,public ls:ListerService) { }


  ngOnInit(): void {
   
   if( (this.site.includes("ebc") || this.board=="NEET" ) && ["hwh","rbs","pyq"].includes(this.justOffering) ){
     this.clsSec$ = this.ls.getClsSecInfo("cmn");  
    } else{
      //http://localhost/rs/list/book/esa/ICSE/X/type/Mathematics
     this.sl$ = this.ls.getBookList(this.justSubject) 
   }
  

  

  }

  getRoute(s:string){
    //no offering as book from rbs vs hwh etc..
   // console.log('feature',this.feature)
    if(this.feature && !this.offering){
      s= "type/" + s;
    }
    //console.log('s',s)
  return this.cs.getRouteUrl(this.cat, s) 
  //let url = this.cs.router.url; + "/" + s;
   //return url; 
  }

  getTitle(s:string){

    return s.replace("rbs/","Reference Book:").replace("vid/","Video:").replace("doc","misc:").replace("pyq","Previous Year Papers")
  }


}
