<app-thoughtoftheday  class=" text-center">... </app-thoughtoftheday>

<app-edubestu> </app-edubestu>

<nav class= "row mx-3">
    
    <button class="btn btn-outline-primary border-0 navbar-nav mr-auto"  (click) = "browse('fresh','');">  more...Fresh & Future </button>
    //nws/GeneralNews/674
    <button class="btn btn-outline-primary border-0 navbar-nav mx-auto"  (click)= "showDuk =!showDuk"> Did You Know? </button>

    
    <button class="btn btn-outline-primary border-0 navbar-nav ml-auto"  (click)= "showQuiz=!showQuiz"> Quiz Of the week </button>
    
    
        <span *ngIf='cm.enable("impLinks")' >
            <button type="button" class="mx-2 btn btn-outline-primary border-0" (click)='cm.navigate("impLinks",i,"",hdr)'> Important Links 
            </button>
        </span>
        <span *ngIf='cm.enable("goodRead")' >
          <button type="button" class="mx-2 btn btn-outline-primary ml-auto border-0" (click)='cm.navigate("book","btm/inspirational/114501")'> Good Read 
          </button>
        </span>
  
      
    </nav> 


    <app-fresh-quiz *ngIf= 'showQuiz' id= "quiz_div"> </app-fresh-quiz>
  
    <app-duk *ngIf= 'showDuk'></app-duk>


    <br>
    <br>
    