import { Component, OnInit } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {

  system:boolean;

 disableTracking: boolean = localStorage.getItem('disableTracking') == 'true';
 disableAdvertising: boolean = localStorage.getItem('disableAdvertising') == 'true';

  constructor(private bd: BoardDataService, public cs:ClientInfoService) { }

  ngOnInit(): void {
  }

  
clearCache(type:string, all?:boolean){
  let key = this.cs.getSite() + this.cs.getBoard() + this.cs.getCourse();
  if(all || !this.cs.getChapter() ){
    key = "all-" + key; 
  }else{
    key = this.cs.getChapter()
  }
  
  this.bd.clearCache(key,type).subscribe(response=> {
    if(response) {
      this.cs.addMsg("cleared");
       }   });
  
}


tweaklocalTracking(){
  if(this.disableTracking){
  localStorage.setItem('disableTracking','false');
} else{
  localStorage.setItem('disableTracking','true'); 
}
this.cs.addMsg("local tracking tweaked");
}


tweakAdvertising(){
  if(this.disableAdvertising){
  localStorage.setItem('disableAdvertising','false');
} else{
  localStorage.setItem('disableAdvertising','true'); 
}
this.cs.addMsg("local Advertising tweaked");
}

clearLocal(){
 localStorage.clear();
 sessionStorage.clear();
}


GDAll(){
  this.bd.GDAll().subscribe(data => {
    if((data as any).status === 200) {
      this.cs.addMsg("Success:posting done:" + (data as any).result,'toast' );
    //console.log(data);
  }else {
    this.cs.addMsg("error,try later "+ (data as any).result ,"error");
  }
  });

  
}



}
