import { Injectable } from '@angular/core';
import { Alert } from './Alert';




@Injectable({
  providedIn: 'root'
})
export class MessageService {
alerts: Alert[] = [];

  constructor() { }

add(message: string, type?:string,title?:string) {
let alert:Alert  = new Alert();


if(!message){
  message= '?'
  type= 'danger'; //type; 
// console.log(`message alert was being added with no message for ${type} - ${title}  type-title combo hence not added`);
 return;
 }

if(!type){
 type= "primary";
  //'success', 'info', 'warning', 'danger', 'primary', 'secondary', 'light' //and 'dark'.
}



alert.message= message; 
alert.type= type //type; 

alert.title= title;
this.alerts.push(alert);
 }

  clear() {
    this.alerts = [];
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
   }

}


