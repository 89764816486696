
  

  
import { Component, OnInit, Input } from '@angular/core';
import { Comment } from '../comment';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentService } from '../comment.service';
import { SecurityService } from '../../shared/security.service';
import { Subscription } from 'rxjs';
import { FileService } from '../../shared/file.service';
import { ConfigService } from '../../shared/config.service';
import { Type } from '../../qstn/type';
import { ClientInfoService } from '../../shared/client-info.service';
import { CmntListService } from '../cmnt-list/cmnt-list.service';
import { BoardDataService } from '../../board-data.service';

@Component({
  selector: 'app-cmnt-crud',
  templateUrl: './cmnt-crud.component.html',
  styleUrls: ['./cmnt-crud.component.scss']
})
export class CmntCrudComponent implements OnInit {
  @Input() c:any;
 @Input() i :number;
  @Input()  responseMode:boolean;
  @Input()  id: number;
  @Input() cmntHeading:string;
   //comment: Comment;
   toggleUpload:boolean;
  showUpload : boolean;
  updateId:any;

  showUploadFile:boolean;
  showUpdate:boolean;
  showResponse:boolean;
  

   mistakeTypes :Type[]= [new Type('ovrsght','Oversight'),
    new Type('clcln','Calculation'), new Type('cncpt','shallow Concept'),new Type('othr','Other')];
   
    
  submitted:any;
  updateFileSub: Subscription;
  showDetail: boolean;
  respondI: number;
  provideResponse: boolean;

  get signedUser() { return this.sec.getSignedUser()}

  constructor(private route: ActivatedRoute,private router: Router,public ls: CmntListService,
    private cms: CommentService,public cs: ClientInfoService,
    private sec:SecurityService,
    public cfg:ConfigService,public ds: BoardDataService
    ) {

//file upload
this.updateFileSub = FileService.updateUploadUrl.subscribe(res=>{
  //this.c.source= res;
  if(res.includes('etl')){
    if(this.responseMode){
      this.c.response= this.c.response +'<br> <img src = \"'+res + '\" />';
    }else {
  this.c.comment= this.c.comment +'<br> <img src = \"'+res + '\" />';
  }   
}
   } );
  

     }

  ngOnInit() {

    /*
    this.c = new Comment();

   // this.id = this.route.snapshot.params['id'];
    
    this.cms.getComment(this.c.id)
      .subscribe(data => {
       // console.log(data)
        this.c = data.result;
      }, error => {this.cs.addMsg("error occured") ; console.log(error);})
*/
  }
  
  ngOnDestroy(): void {
    this.updateFileSub.unsubscribe()
}

enable(btnId:string,comment?:any){
  return this.cms.enable(btnId,comment); 
   }

   commentDetail(id: number){
    this.id = id;
    this.showDetail = true;
    //this.router.navigate(['eba','digibest','features','cmnt-dtl','byId', id]);
  }

  displayUpdate(id: number,i:number){
    this.updateId= i;
    this.showUpdate = !this.showUpdate;
    //this.router.navigate(['eba','digibest','features','cmnt-dtl','byId', id]);
  }

  displayResponse(id: number,i:number){
    this.respondI= i;
    this.showResponse = !this.showResponse;

    //this.router.navigate(['eba','digibest','features','cmnt-updt','byId', id]);
  }

  
   
  updateComment() {
    //console.log(this.c);
    //console.log(this.c.response);
    if(this.responseMode){
    this.c.rspnseUser= this.signedUser;
  } else {
    this.c.addUser= this.signedUser;
  }
this.cms.updateComment(this.id, this.c)
      .subscribe(
        data =>  error => console.log(error));
    this.c = new Comment();
    //alert("comment updated");
    this.cs.addMsg("info updated", 'toast');
    this.gotoList();
  }

  delete(id: number) {
    this.id = id;
    this.cms.deleteComment(id)
      .subscribe(
        data => {
          console.log(data);
          this.cs.addMsg("Deleted", 'toast');
          //this.fetchData();
        },
        error => console.log(error));
  }

  onSubmit() {
    this.updateComment();    
  }

  gotoList() {
    CommentService.updateList.next(true);
    //this.router.navigate(['//eba//digibest//features//comments']);
  }

  

navigateQstn(c:any) {
  let route= [c.domain,this.cs.getCat(),'board',c.course,c.featureType,c.subject,c.parentHdrId,'view-qstn', c.parentDtlId];
  route = Array.from(route, item => item || "abc");
  this.router.navigate(route);
}
///esa/Drfc/board/abc/hwh/abc/166743
//esa/cstore/CBSE/XI/hwh/Biology/166743
///esa/abc/NEET/XII/hwh/abc/166743

navigateQstnHdr(c:any) {
  this.ds.getHdrObj(c.parentHdrId).subscribe(data => {
    //console.log(data);
    if(data && (data as any).status==200 && (data as any).result){
    let hdrObj:any = (data as any).result;
    let route= [c.domain,'cat',hdrObj.board,hdrObj.course,hdrObj.type,hdrObj.subject,c.parentHdrId];
    route = Array.from(route, item => item || "abc");
    this.router.navigate(route);
    } 
  });
  
  
}


}