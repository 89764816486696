<header *ngIf='site && enableMenu' class="py-0 mt-0 my-0 mx-0">
  <nav
    class="bg-primary mx-0 px-0 mt-0 py-0 navbar navbar-expand-md navbar-dark">

    <a class="navbar-brand" *ngIf="site && headerObj"
      [routerLink]="[getUsrHome()]">
      
    
    <span *ngIf=" siteObj && siteObj.extra && ehs.logo(siteObj.extra)">
      <img src="{{ehs.logo(siteObj.extra)}}" max />
    </span>
    
    <span class="pen18 bg-light text-primary">
      {{siteObj?.name}}
      <i class="fa fa-home bg-light text-primary" aria-hidden="true"></i>
      
    </span>

      </a>
    
      

      <ng-container *ngIf="site && headerObj">
      <a *ngIf="cs.ss.getUserDomain() && site!= cs.ss.getUserDomain()" class="pen18 navbar-brand text-danger" 
      [routerLink]="['/',cs.ss.getUserDomain(),'home']"> Back to My Site </a>
      </ng-container>

    <!--
    <a
      class="navbar-brand"
      *ngIf="site && headerObj"
      [routerLink]="[getUsrHome()]"
      ><div class="myfont25 text-primary bg-white">
        <img src="{{ ehs.image(headerObj.extra) }}" max />
        <i class="fa fa-home text-primary" aria-hidden="true"></i>
      </div>
    </a>
-->



    <div class="collapse navbar-collapse justify-content-beginning"
      id="navbarCollapse"></div>

    <!-- unsigned && site && site.startsWith("es")-->
    <a *ngIf="enable('examLister-site')" class="btn btn-outline-light"
      [routerLink]="['/', site, 'learner', 'features', 'exams']"
      [routerLinkActive]="['is-active']"
      ngbTooltip="Select exam/Class for ease in navigation." placement="bottom">
      Pick Exam/Class <span class="fa fa-caret-down"></span></a>

    <!--
    <div class="list-unstyled">
      <a *ngIf="enable('hardcodedmyMenu-site')" [routerLinkActive]="['active']"
        class="mx-auto nav-link" [routerLinkActive]="['is-active']"
        (click)="isNavbarCollapsed = !isNavbarCollapsed"
        routerLink="/{{ ss.getDefaultSite() }}/{{ ss.getUserMenu() }}">
        <span class="text-white">My Menu </span>
      </a>
    </div>
  -->

    <button class="navbar-toggler hidden-sm-up" type="button"
      (click)="isNavbarCollapsed = !isNavbarCollapsed"
      data-target="#navbarsDefault" aria-controls="navbarsDefault"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>


    <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse"
      id="navbarsDefault">

      <div class="ml-auto"></div>

<!--+ headerObj.summary.substr(0,25)-->
      <a class="" *ngIf="false && headerObj && headerObj.hdrFormat != 'sample'"
        class="navbar-brand" [routerLink]="['/', site, 'home']">
        <span *ngIf="siteObj"
          class="santa text-warning ml-auto navbar-nav ml-5 px-3"
          innerHTML="{{siteObj.name  }}">
        </span>
      </a>


      <app-seo *ngIf="headerObj && cat == 'home'" [h]="headerObj"></app-seo>
      
      

      <nav class="navbar navbar-expand-md" *ngIf="qstns$ | async as questions">
        <!-- needed as ngfor and ngif can't be put togetehr BUT screwing up navigation  -->
        
        <app-exam-selector *ngIf="
      (cs.getFeature() && cs.getFeature() == 'signup') ||
      enable('examSlctr-site')">
      </app-exam-selector>
      

        <div class="list-unstyled"
          *ngFor="let question of questions.dataList; let i = index">
          <a class="mx-auto nav-link" [routerLinkActive]="['active']"
            *ngIf="!question.groupId || enable(question.groupId, question)"
            routerLink="{{ cs.getRouteUrl(question.category, question.extLink,question.format,question.groupId) }}"
            ngbTooltip="{{ question.instruction }}"
            (click)="isNavbarCollapsed = !isNavbarCollapsed">
            <span class="text-white" innerHTML="{{ question.title }}"> </span>
          </a>
        </div>

        <!--div class="list-unstyled">
          <a
            *ngIf="signedUser != 'Guest'"
            [routerLinkActive]="['active']"
            class="mx-auto nav-link"
            [routerLinkActive]="['is-active']"
            (click)="isNavbarCollapsed = !isNavbarCollapsed"
            routerLink="/{{ ss.getDefaultSite() }}/{{ ss.getUserMenu() }}"
          >
            <span class="text-white" innerHTML="{{ 'My Menu' }}"> </span>
          </a>
        </div-->

      </nav>
        <!--
      <form *ngIf="enable('search')" class="form-inline mt-2 mt-md-0">
        <div class="input-group">
          <input class="form-control mr-sm-2 input-group-text" type="text"
            placeholder="Search" aria-label="Search" />

          <button class="btn btn-outline-info input-group-append my-2 my-sm-0"
            type="submit">
            <span class="fa fa-search"></span>
          </button>
        </div>
      </form>
      -->

    </div>
  </nav>
</header>