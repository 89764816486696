

  import { Component, Input, OnInit } from '@angular/core';
  import { TestService } from '../test.service';
  
  @Component({
    selector: 'app-mcq-optn',
    templateUrl: './mcq-optn.component.html',
    styleUrls: ['./mcq-optn.component.scss']
  })
  export class McqOptnComponent implements OnInit {
    @Input()  q :any;
    @Input()  i :number;
    @Input() worksheet: boolean;
      
    constructor(public ts:TestService) { }
  
    ngOnInit(): void {
    }
  
  }
  