import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page, queryPaginated } from '../../gen/paginator/pagination';
import { Detail } from '../detail';
import { catchError } from 'rxjs/operators';
import { HttpHlprService } from '../../shared/http-hlpr.service';

//import { Page, queryPaginated} from './pagination';



@Injectable({
  providedIn: 'root'
})
export class PageGetterService {
  
 
  baseUrl = "/rs/qd" ;
  
  //'http://localhost:8000/v1/ponies';

  constructor(
    private http: HttpClient, private h:HttpHlprService
  ) { }

  list1(urlOrFilter?: string | object): Observable<Page<Detail>> {
    return queryPaginated<Detail>(this.http, this.baseUrl, urlOrFilter);
  }


  list(hdrId: number): Observable<any> {

    return this.http.get(`${this.h.ba}/qd/list/${this.h.si}/${this.h.bo}/${this.h.co}/${this.h.clse}/${this.h.ty}/${this.h.su}/${this.h.ch}`).pipe(
     catchError(this.h.handleError('page-getter service', [])));
    }
 
    
}