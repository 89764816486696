import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientInfoService } from '../../../shared/client-info.service';

@Component({
  selector: 'app-km-menu',
  templateUrl: './km-menu.component.html',
  styleUrls: ['./km-menu.component.scss']
})
export class KmMenuComponent implements OnInit {

get cat() { return this.cs.getCat()}
 
  constructor(private router: Router,private cs:ClientInfoService ) { }

  ngOnInit(): void {
    
  }

  

  showStore(overCat: string) {
    let extUrl= this.cs.getOffering();
    if(this.cs.getSubject()){
      extUrl= extUrl+"/" +this.cs.getSubject();
    }
    let url = this.cs.getRouteUrl(overCat, extUrl);
    this.router.navigateByUrl(url);
  }



  alert(msg:string){alert(msg)}
}
