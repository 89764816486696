
 <table class="table table-striped">
  <thead>
    <tr class= "row">
      <th  class= "col-1"># </th>
      <th class= "col-3">Title</th>
      <th class= "col-1">Added by</th>
      <th class= "col-1">Add Date</th>
      <th class= "col-1">Content Type</th>
      
      <!--
        <th class= "col-1">Header Id</th>
      <th class= "col-2">class</th>
      <th class= "col-2">section</th>
      <th class= "col-2">subject</th>
      
      <th class= "col-2">chapter</th>
      -->

      <div *ngIf="false" class= ".d-none">
      <th class= "col-2">section</th>
      
      <th class= "col-2">Booster Set</th>
      <th class= "col-2">status</th>
      <th class= "col-2">Active Flag</th>
            
    </div>
    </tr>
  </thead>

  <div *ngFor="let t of list1;let i = index">
      <tr class= "row">
 
  <td  class= "col-1">{{t.id}}    </td>
   <td class= "col-3">{{t.title}} </td>
   <td class= "col-1">{{t.owner?t.owner:t.addUser}} </td>
   <td class= "col-1">{{t.addDate|date}} </td>
   <td class= "col-1">{{t.type}} </td>
   
   <!--
     <td class= "col-1">{{t.headerId}}</td>
   <td class= "col-2">{{t.course}}</td>
   <td class= "col-2">{{t.clsSec}}</td>
   <td class= "col-2">{{t.subject}}</td>
   <td class= "col-2">{{t.chapter}}</td>

    <!--td> 
  <button *ngIf="t.extUrl" (click) = "showDoc=!showDoc">show Doc </button>
  </td-->

   <div *ngIf="false" class= ".d-none">
   <td class= "col-2">{{t.section}}</td>
   <td class= "col-2">{{t.bstrSet}}</td>
   <td class= "col-2">{{t.status}}</td>
   <td class= "col-2">{{t.current}}</td>
   
 </div>
 
 <td class= "col-4">

<app-taghdr-crud class="mx-2" *ngIf="tl.fd.tagBased(t.type) && tl.enable('crudBtns',i,header)" [header] = t [i] = i > </app-taghdr-crud>

<app-crud-btn class="float-right mx-2" *ngIf="!tl.fd.tagBased(t.type) && tl.enable('crudBtns',i,header)" [header] = t [i] = i > </app-crud-btn>

<!--"hdr1grp" was here for vid-->
<a class="btn-md badge btn-outline-primary mr-1"
(click) = 'tl.showChptr(t)'>Show </a>

<!--
<a class="btn-md badge btn-outline-primary mr-1"
(click) = 'tl.showChptr(t,"hwrk")'>Worksheet Overview</a>
-->

<app-tag-optn *ngIf="tl.enable('tagDtl',i,header)"  [t] = t [i]= i> </app-tag-optn>

<!--
<app-status-changer [hdr] = t> </app-status-changer>
-->

</td>

</tr>

<div class= "row col-12">
  <app-url-shower *ngIf="showDoc" [extLink] = t.extUrl [title] = t.title> </app-url-shower>
  </div>
</div>
</table>

 