import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { BoardDataService } from '../../board-data.service';
import { MessageService } from '../../message.service';
import { PropFromUrlService } from '../../shared/prop-from-url.service';
//import * as Quill from 'quill';
import { ClientInfoService } from '../../shared/client-info.service';
import { BrowserWindowService } from '../browser-window.service';
//import { Mathjax } from './Mathjax';

declare const MathJax:any; 

@Component({
  selector: 'app-custom-quill',
  templateUrl: './custom-quill.component.html',
  styleUrls: ['./custom-quill.component.scss']
})
export class CustomQuillComponent implements OnInit {
@Input() content:any;
@Input() table:any;
@Output() output: EventEmitter<string> = new EventEmitter();
submit:boolean = false;
mathQuilljax :any ;
  success: boolean;
;
      
 @ViewChild('delta', { static: false }) public delta: ElementRef;
  
  @ViewChild('mathjax', { static: false }) public mathjax: ElementRef;
  quill: any;
  editorText: any;

  //https://stackoverflow.com/questions/63182907/ngx-quill-and-imageresize-with-angular-universal-ssr

  constructor( private cs:ClientInfoService, public ms: MessageService,public bw:BrowserWindowService, private ds:BoardDataService,public ps:PropFromUrlService
    ) {
    if(bw.hasWindow()){
    Promise.all([
      //import('quill-image-resize'),
      import('quill'),
    ]).then(([Quill]) => {
      const Q = Quill.default || Quill;
      
      //console.log({ Quill,  });
      //ImageResize,ImageResize
      this.quill = new Q('#editor-container', {
        contents: "hello",
        theme: 'snow',
        modules: {
          toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['link'],
              ['blockquote'],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }], 
              ['align', { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }]
              ,['formula'],
          ]
        },
      });
      
     
      this.quill.root.innerHTML = this.content;

     
      this.loadMathClass(Q);
      
      this.mathQuilljax.blotName = 'mathjax';
      this.mathQuilljax.className = 'ql-mathjax';
      this.mathQuilljax.tagName = 'SPAN';

Quill.default.register(this.mathQuilljax);

      // each has a "default" which is identical to the traditional import
     // Quill.default.register('modules/imageResize', ImageResize.default);
      //this.quillModules = { imageResize: {} }; //it's important to do this here
    });
  }
  }

  ngOnInit(): void {
    // Set module properties
/*
// Register the module
Mathjax.blotName = 'mathjax';
Mathjax.className = 'ql-mathjax';
Mathjax.tagName = 'SPAN';

Quill.register(Mathjax);
*/


  }


  loadMathClass(Quill:any) {

    const Parchment = Quill.import('parchment');
    const Delta = Quill.import('delta');
     

 this.mathQuilljax  = class MathQuilljax extends  Parchment.Embed{
  static blotName: string;
  static className: string;
  static tagName: string;
 // Create node
 static create(value) 
 {
     const node = super.create(value);    
     if (typeof value === 'string') {
         node.innerHTML = "&#65279;" + this.tex2svg(value) + "&#65279;";
         node.contentEditable = 'false';
         node.setAttribute('data-value', value);         
     }
     return node;
 }

 // Return the attribute value (probably for Delta)
 static value(domNode) 
 {
     return domNode.getAttribute('data-value');
 }

 // Manually render a MathJax equation until version 3.0.2 is not released
 static tex2svg(latex)
 {
     // Create a hidden node and render the formula inside
     let MathJaxNode = document.createElement("DIV");
     MathJaxNode.style.visibility = "hidden";
     MathJaxNode.innerHTML = '\\(' + latex + '\\)';
     document.body.appendChild(MathJaxNode);
     MathJax.typeset();
     let svg = MathJaxNode.innerHTML;
     document.body.removeChild(MathJaxNode);
     return svg;
 }
       }
      }

  changedEditor(event:EditorChangeContent|EditorChangeSelection){
  alert("called");
  
  console.log('editor got changed',event);
  this.editorText= event['editor']['root']['innerHTML'];
  
  /*
  var delta = editor.getContents();
  var text = editor.getText();
  var justHtml = editor.root.innerHTML;
  preciousContent.innerHTML = JSON.stringify(delta);
  justTextContent.innerHTML = text;
  justHtmlContent.innerHTML = justHtml;
*/
  }
  

  


clickMathjax(){
  var latex = prompt("Enter a LaTeX formula:", "e=mc^2");
	var range = this.quill.getSelection(true);
	this.quill.deleteText(range.index, range.length);
	this.quill.insertEmbed(range.index, 'mathjax', latex);
	this.quill.insertText(range.index + range.length + 1 , ' ');
	this.quill.setSelection(range.index + range.length + 1);
//alert('I am here');
   }

   getHtml(){
    var editor_content = this.quill.container.innerHTML // or quill.container.firstChild.innerHTML could also work
    return editor_content;
   }

   
   save(){
    let content= this.quill.root.innerHTML;
    this.submit= true;
    if(this.table=="comment"){
    this.output.emit( content);
    this.cs.log(content);
    } else{
      this.postTextFile(content);

    }
   }

   
   
  postTextFile(fileData: any){
    //   this.myHtml$ = this.ds.getTextFile(url);
    const formData = new FormData();
    formData.append('fileData', fileData);
  
    this.ds.postTextFile(formData).subscribe(
      data=>{
      
      if((data as any).status === 200) {
      this.ms.add("success " + (data as any).result)
      this.success = true;
       
      }else {
        console.log(data);
      this.success = false;
      this.ms.add("failure, try later" + (data as any).result)
      //alert("error,try later");
      }  
  });
  
};
   ngAfterViewChecked(){
   // this.cs.typeSet('');
  }


}
