
  //not in use 
<a class="btn-md badge btn-secondary mr-1"
(click) = "dgtzrUrl()">uploaded-in Progress </a>


<button  type="button" class="btn btn-info" (click)="showUpload=!showUpload">
  upload {{showUpload?'-':'+'}}
</button>



    <div *ngIf = 'showUpload'>

        <app-qstnhdr-add  [iHeader]=crtHdr>
            </app-qstnhdr-add>
        </div>

        