import { Component, OnInit,Input } from '@angular/core';
import { OfrDtlService } from '../../shared/headings/ofr-dtl.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-selector',
  templateUrl: './view-selector.component.html',
  styleUrls: ['./view-selector.component.scss']
})
export class ViewSelectorComponent implements OnInit {
 @Input()  headerId : any;
 submitted:boolean;
 @Input() questions: any;
 @Input() hdrObj : any;
 
 get signedUser() { return this.cs.getSignedUser() }
 get site() { return this.cs.getSite() }
 get cat() { return this.cs.getCat() }
 get board() { return this.cs.getBoard() }
 get course() { return this.cs.getCourse() }
 get offering() { return this.cs.getOffering() }
 get subject() { return this.cs.getSubject() }
 get chapter() { return this.cs.getChapter() }

 get school() { return this.cs.getSchool() }
 get schlLoc() { return this.cs.getSchlLoc() }
 get unsigned() {return this.cs.isUnsigned()} 
 get grpAsoId() { return this.cs.grpAsoId}
 get tagBased() {return this.cs.tagBased(this.offering)} 
 autoMaker:boolean;
 
 
constructor(private router:Router, private cs: ClientInfoService,public ods:OfrDtlService) { 

  //cat change was not refreshing the page
  // force route reload whenever params change;
this.router.routeReuseStrategy.shouldReuseRoute = () => false;

}

ngOnInit(): void {
   
  }

  ngOnChanges(){
   
  }
  
  /*
  getgrpAsoId(){
    // console.log('passed hdr is',this.hdr); 
     if (this.hdrObj.headerId && this.hdrObj.headerId>0) {return this.hdrObj.headerId}
      return this.cs.getChapter();
     }
*/

     
  
  enable(btnId: string, i?: number, hdrObj?: any) {
    let component = 'viewList';
    
    if (btnId == "test"){
      if((this.cat == 'gps' || this.unsigned) && this.offering.includes("asgn")){
       // this.cs.addMsg("Please signin for Test,Non-Online Mode enabled","toast");
        return false;
      }
     return (this.cs.enable(component, 'takeTest', '', hdrObj) || this.cs.enable(component, 'wrkTest', '', hdrObj) || this.cs.enable(component,'mockTest','',hdrObj) ||this.cs.enable(component,'wrkMockTest','',hdrObj));
    }

    if (btnId == "showDetail" && this.cs.isUnsigned()) { return true }
    return this.cs.enable(component, btnId, '', hdrObj);
  }


  //http://localhost/rs/cmnt/typeFtrHdrid/esasursht/Dclr/tasgn-hwh/117327
}
