import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";
import { environment } from '../../../environments/environment';

import { BoardDataService } from '../../board-data.service';

import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { CommonModule } from  '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { BtnService } from '../../gen/btn.service';

@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['./sliders.component.scss'
 ],
 encapsulation: ViewEncapsulation.None
})

export class SlidersComponent implements OnInit {

  @Input() ovrSub:string;
  
//  readonly rootUrl = environment.rootUrl;
//rootUrl: string;
images: string[];
//site:string;
slides: any;
headerObj:any;
get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get feature() { return this.cs.getFeature()};
  get rootUrl() {return  this.cs.getRoot() };
  get unsigned() {return  this.cs.isUnsigned() };

  headerId:number;
  key:string;
  data$: Observable<any>;
  calling:boolean;
  constructor(config: NgbCarouselConfig, private route: ActivatedRoute,public b:BtnService,
    private router: Router, private cs:ClientInfoService, private ds: BoardDataService,private fd:FixedDataService) { 
      config.showNavigationArrows = true;
      config.showNavigationIndicators = true;
      //config.interval= 1500;
      config.interval= 1500;
     // config.pauseOnHover = false;
      //config.pauseOnFocus =true;
       }

    
      
/*
    getFullLink(category:string, extLink: string){
    //return "['/',site,'et','features','signup']"
    if(extLink=='/features/signup'){
    return "/"+this.site+'/' +category + extLink;
}
    if(extLink== ''){
    return "/"+this.site+'/' +category;
  }

    }
*/

    getRouteUrl(category:string, extLink: string): string{
      if(extLink=='te/Features/excel'){
      //alert("here: "+ this.cs.getRouteUrl(category, extLink));
    }
    //return "/"+this.site+"/et/features/signup";
    return this.cs.getRouteUrl(category, extLink);
    }


  ngOnInit() {
    //this.slides= this.fd.getSliderData(this.site);
    /*
    this.key="slides"
    if(this.feature){
     this.key = this.feature +"-" + this.key;
    } else{
      this.key = this.cat +"-" + this.key; 
    }
    */

   let key =  this.ovrSub;

   if(!key){
   key = this.feature?this.feature:this.cat + "~slides";
   }

    this.calling = true;
    this.data$ =  this.ds.getCfgData(this.site,this.board,this.course,"cfg",key);
    
   /* needed for non async block in template call
    this.data$.subscribe(res => {
    this.slides= res.dtls;
    this.headerObj= res.hdr;
    //console.log(res);
  }  )
  */
    
   


  /*
    //put check for cat/features with slides..
    this.ds.getCatHeaders(this.site,this.board,this.course,this.key,this.subject).subscribe(data => {
     this.headerObj= (data as any).dataList[0];
     if(this.headerObj){
    this.headerId= this.headerObj.id;
    this.ds.getSiteQuestions(this.site,this.board,this.course,'cfg',this.subject,this.headerId).subscribe(data => {
    this.slides= data;
    //alert("Data retrieved"+ data);
    console.log(data);

    });
  }
  });
*/
 

}
  

show(q:any){ return this.b.showQstn("slides",q)}


}
