import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientInfoService } from '../shared/client-info.service';
import { UsrroleService } from './usrrole.service';
import { SecurityService } from '../shared/security.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoleChangerService {

  
    baseUrl: string;
    
    constructor(private http: HttpClient,private cs:ClientInfoService,
      private usrroleService: UsrroleService, private ss: SecurityService, private router: Router
      ) {
      this.baseUrl= this.cs.getRoot()+ "/rs/ur-v2";
    
    }
  
 changeRole(groupId:string,menu?:string){
  this.dropdownClickV2(groupId,menu);
 }
  
  
  dropdownClickV2(groupId:string ,menu?:string){
    //apply dfltGroupid logic earlier...
     var orgnlGroupId=  localStorage.getItem('dfltGroupId');
 
 var principal = JSON.parse(localStorage.getItem('principal'));
   principal.groupId= groupId;
    principal.learnMode = this.cs.getJustSite();
    let authorities: any[] =principal.authorities;
    authorities.splice(0,authorities.length)
    let roles:any[];
   
    //  old version console.log(roles);
   /*
    this.usrroleService.getGroupRoleList(groupId).subscribe(data => {
    roles= data;
 //note typescript foreach is callback type different function..
  roles.forEach(function (value) {
   //console.log(value);
    authorities.push({"authority": value.role});
   }); 
 */
 
   this.usrroleService.getConsolidatedGroupRoleList(groupId).subscribe(data => {
     roles= data;
     roles.forEach(function (value) {
       //console.log(value);
        authorities.push({"authority": value.authority});
       }); 
    
    this.ss.signin(principal);
     this.cs.addMsg("Modified", "toast");
 
     let pr = principal;
     //'learner', 'features',
     // this approach has one bug... parent with dfltmenu will get wiped out of dflt-mnu.
     
        //dflt mnu start blocking other group change(:
        // - means its not role group but rol-subgroup with dfltmnu.
        
        //default menu will be only for comfort and won't be supported?
      menu=menu?menu:groupId; 
      this.goHome(pr, menu);
     }
    );
   
 }

 

  public goHome(pr: any, ovrGroupId?: string) {
    //idea is group changer can continue with signeduser selected site if changed
        //but security perspective check later didn't allow below line
    //let site = ovrGroupId?this.cs.getSite():pr.domain;
    // for group chain director --need to put lohgic of first 2 segment check only

    //domain for site.. 3-segment fir data share
    let site =pr.domain
    let groupId = ovrGroupId?ovrGroupId:pr.groupId;
    let menu= groupId;
    if (groupId.includes("-")) {
       menu = groupId.split("-")[1];
      //console.log(groupId +" turned into "+ menu)  
      }

    let myMenu = [site, menu, pr.board, pr.grade];
    //userHomePage set-up
    localStorage.setItem('myPage',myMenu.filter(Boolean).join('/'));

    this.router.navigate(myMenu);
  }
}
