
<app-daily-detail [type] = '"fresh"' [subject] = '"Quiz"' > </app-daily-detail>



<div  *ngIf="false"> 
<p   class= "my-3 text-center" >Quiz of the week</p>

<div class= "my-3 text-center">
<!--
    <img  src = "/etl/puzzle/current/pzl.png"  alt= "pzl">
-->

** {{q.question}} **

<img *ngIf= "q" src = {{q?.questionImages}} alt= "quiz">
</div>

</div>