import { NgModule } from "@angular/core";
import { MathDirective } from "./math.directive";
import { MathService } from "./math.service";
import { MathComponent } from './math/math.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [MathDirective, MathComponent],
  exports: [MathDirective,MathComponent],
  providers: [MathService],
  imports:      [ BrowserModule, FormsModule],
 
})
export class MathModule {}
