

import { Comment } from '../comment';
import { Component, OnInit, Input } from '@angular/core';
import { CommentService } from '../comment.service';
import { CommentListComponent } from '../comment-list/comment-list.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-comment-detail',
  templateUrl: './comment-detail.component.html',
  styleUrls: ['./comment-detail.component.scss']
})
export class CommentDetailComponent implements OnInit {
 
 @Input()  id: number;
 @Input() cmntHeading:string;
  comment: Comment;

  constructor(private route: ActivatedRoute,private router: Router,
    private commentService: CommentService) { }

  ngOnInit() {
    this.comment = new Comment();
   // this.id = this.route.snapshot.params['id'];
    
    this.commentService.getComment(this.id)
      .subscribe(data => {
        console.log(data)
        this.comment = data.result;
      }, error => console.log(error));
  }

  list(){
   // alert("comment added");
   CommentService.updateList.next(true);
    //this.router.navigate(['//eba//digibest//features//comments']);
  }
}