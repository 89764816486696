<form (ngSubmit)="onSubmit()">

  
  <div  *ngIf="q" class="fullWidth container p-1 div1 bg-violet  border-0" id="showQstn!">

    <div id="aa" class="card m-0 bg-pink">
      
      <div class="card-body  ">
        <div class="card-title">
                    <span class="font-weight-bold">
            #{{qs.formatSeq(q.sequence,q.subSeq,q.part)}}
          </span>
          <span class= "float-right"> Marks: {{q.marks}} </span>
          <!--question section 
          <img src="https://i.imgur.com/A8eQsll.jpg" class="card-img-top" alt="">
          -->


          <ng-container *ngIf='q.optionList; else subjective'>
            <p class="card-text" [innerHTML]=q.digInstrctn> </p>
            <p class="card-text" [innerHTML]=q.digQstn> </p>
            <div  *ngIf= 'q.contentImages'  class= "text-center"> 
              <img  [src] = "q.contentImages|safe:'resourceUrl'" height= "200px" alt= "">
          </div>
      
          </ng-container>

          <ng-template #subjective>
            <p class="card-text" [innerHTML]=q.instruction> </p>
            <p class="card-text" [innerHTML]=q.content> </p>
            <div  *ngIf= 'q.contentImages'  class= "text-center"> 
              <img  [src] = "q.contentImages|safe:'resourceUrl'" height= "200px" alt= "">
          </div>

          <!--moving to test-dtl-btn-->
          <ng-container *ngIf='false && showAnsr'>
           
            <ng-container *ngIf='(q.question ||q.content) && (q.ansCmnts[0])'>
              <h5> {{"User's Answer"}} </h5>
             <div class= "container mx-5">
              <ckeditor [(ngModel)]='q.ansCmnts[0].comment'
                [(config)]="cfg.editorConfig" name="comment">
              </ckeditor>
            </div>
            </ng-container>
          </ng-container>

     
          </ng-template>
        </div>

        <!-- answer -->
          
        <app-mcq-ma *ngIf='(q.format && q.format== "MCQM") && q.optionList'  [q] = q > </app-mcq-ma>

        
          <ng-container    *ngIf='(!q.format || q.format != "MCQM")  && q.optionList; else writeT'>
            
            <!--
            <app-mcq-qstn [q]= q > </app-mcq-qstn>
            -->
              
            <ul *ngFor="let o of q.optionList;let j = index"
              class="list-group list-group-flush">
              <p *ngIf= 'o' class= "text-secondary" >
                <input type="radio" [value]= ts.getDigOptn(o,j+1) name="digAnsr" [(ngModel)]="q.ansCmnts[0].digAnsr" > 
                <span innerHTML= {{o}}> </span>
                </p>
            </ul>
            
            <p *ngIf= "q.ansCmnts[0].digAnsr" > selected: {{q.ansCmnts[0].digAnsr}} </p>
            
          </ng-container>

          
     <ng-template  *ngIf= 'q.format && q.format != "MCQM"' #writeT>
           
          </ng-template>

      </div>

    </div>
    
    <div class="text-center" >
      <app-test-dtl-btn [q] =q [i] = i >  </app-test-dtl-btn> 
      
      <app-gencmnt [q]=q [i]=i> </app-gencmnt>
            
     </div>

  </div>
</form>


<!-- uploadedObj is output of file-upload and is in left side-->
<app-file-upload *ngIf='flup' [iDetail]= q  (uploadedObj)='getChildOutput($event)'>
</app-file-upload>




<!-- good example
    <div class="card-footer text-center"> 
              
      <p style="color:green;">We are inside card footer</p> 
        
       
      <button class="btn btn-primary btn-sm float-left"
                  id="left" style="color:white"> 
          Left Button 
      </button> 
      <button class="btn btn-warning btn-sm"
              id="center" style="color:white"> 
          Center Button 
      </button> 
        
      <button class="btn btn-danger btn-sm float-right"
              id="right" style="color:white"> 
          Right Button 
      </button>
       
      <button class="btn btn-danger btn-sm float-right"
              id="right" style="color:white"  (click)="ts.next()" > 
              {{ts.showQstn< ts.qstns.length-1?'skip to Next':'Final'}}
      </button> 
  </div> 
-->