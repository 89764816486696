<!--
<app-exam-list> </app-exam-list>
-->
<div *ngIf= "board!=='ICSE'">
      
</div>

<h3 class="text-center"> Welcome to {{board}} Page </h3>
<div class ="text-center bg-white font-weight-bold">
<a routerLink="/esa/exams"><i>Change Board</i></a>
<br>
</div>
<h5 class="text-center"> Quick Revision-Subject Notes/Formulae </h5>
<!--
<div class="jumbotron text-center">
  <h1>{{board}}- {{grade}}Exam Steppers</h1>
  <p>Step In to Step up!</p>
</div>
-->



<div class="text-center" >
<button type="button"
        class="btn btn-primary"
        (click)="onSubject('Physics')">
        Physics

</button> ...

<button type="button"
        class="btn btn-primary"
        (click)="onSubject('Chemistry')">
        Chemistry

</button>
...
<button type="button"
        class="btn btn-primary"
        (click)="onSubject('Mathematics')">
        Mathematics

</button>

...
<button type="button"
        class="btn btn-primary"
        (click)="onSubject('Civics')">
        Civics

</button>
<!--
...
<button type="button"
        class="btn btn-primary"
        (click)="onSubject('History')">
        History

</button>
-->
</div>

<!--
<button type="button"
        class="btn btn-primary"
        (click)="onSubject('Civics1')">
        Civics -

</button>
-->
