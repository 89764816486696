import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchoolComponent } from './school/school.component';
import { HomeTemplateComponent } from './home-template/home-template.component';
import { EshomeComponent } from './eshome/eshome.component';
import { EbhomeComponent } from '../static/ebhome/ebhome.component';
import { McloudComponent } from '../static/mcloud/mcloud.component';
import { CubehorComponent } from '../static/cubehor/cubehor.component';
import { OurCustomersComponent } from '../static/our-customers/our-customers.component';
import { CsaHomeComponent } from '../static/csa-home/csa-home.component';
import { OurTeamComponent } from '../static/our-team/our-team.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { SlidersComponent } from './sliders/sliders.component';
import { SharedModule } from '../shared/shared.module';
import { DefaultvalueComponent } from './defaultvalue/defaultvalue.component';
import { CarModule } from '../car/car.module';
import { CommentsModule } from '../comments/comments.module';
import { FeatureteComponent } from './featurete/featurete.component';
// import { MarketComponent } from './market/market.component';
import { EbIntroComponent } from './eb-intro/eb-intro.component';
import { MenuComponent } from './menu/menu.component';
import { AffiliatesComponent } from './affiliates/affiliates.component';
import { ProductComponent } from './product/product.component';
import { BoardlistComponent } from './boardlist/boardlist.component';
import { OurProductsComponent } from './our-products/our-products.component';
import { ProductDtlComponent } from './product-dtl/product-dtl.component';
import { QstndtlModule } from '../qstndtl/qstndtl.module';
import { CoachingHomeComponent } from './coaching-home/coaching-home.component';
import { FreshShortComponent } from './fresh-short/fresh-short.component';
//import { KmMenuComponent } from './menu/km-menu/km-menu.component';
//import { UserModule } from '../user/user.module';
import { ProblemSpaceComponent } from './problem-space/problem-space.component';
import { UspSlidesComponent } from './usp-slides/usp-slides.component';
import { UspComponent } from './usp/usp.component';
import { PricingComponent } from './pricing/pricing.component';
import { PrdIntroComponent } from './prd-intro/prd-intro.component';
import { PrdComboComponent } from './prd-combo/prd-combo.component';
import { PrdComboSlidesComponent } from './prd-combo-slides/prd-combo-slides.component';
import { PrdComboSlidePdfComponent } from './prd-combo-slide-pdf/prd-combo-slide-pdf.component';
import { CatalogComponent } from './catalog/catalog.component';
import { PlatformSetComponent } from './platform-set/platform-set.component';
import { SectionDividerComponent } from './section-divider/section-divider.component';
import { CmntRctnComponent } from './cmnt-rctn/cmnt-rctn.component';
//import { FtrViewerComponent } from './ftr-viewer/ftr-viewer.component';

import { MenuModule } from './menu/menu.module';

import { ExamListComponent } from './exam-list/exam-list.component';

import { UspDetailComponent } from './usp-detail/usp-detail.component';
import { NewsRoomComponent } from './news-room/news-room.component';
import { DigibestHomeComponent } from './digibest-home/digibest-home.component';
import { Cube3dComponent } from './cube3d/cube3d.component';

@NgModule({
  declarations: [SchoolComponent, HomeTemplateComponent, EshomeComponent,
    EbhomeComponent,
    McloudComponent,
    CubehorComponent,
    OurCustomersComponent,
    CsaHomeComponent,
    OurTeamComponent,
    AboutusComponent,
    SlidersComponent,
    DefaultvalueComponent,
    FeatureteComponent,
    // MarketComponent,
    EbIntroComponent,
    MenuComponent,
    AffiliatesComponent,
    ProductComponent,
    BoardlistComponent,
    OurProductsComponent,
    ProductDtlComponent,
    CoachingHomeComponent,
    FreshShortComponent,
    //KmMenuComponent,
    ProblemSpaceComponent,
    UspSlidesComponent,
    UspComponent,
    PricingComponent,
    PrdIntroComponent,
    PrdComboComponent,
    PrdComboSlidesComponent,
    PrdComboSlidePdfComponent,
    CatalogComponent,
    PlatformSetComponent,
    SectionDividerComponent,
    CmntRctnComponent,
    //FtrViewerComponent,
    Cube3dComponent,
    
    ExamListComponent,
          //TopicListComponent,
          UspDetailComponent,
          NewsRoomComponent,
          DigibestHomeComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CarModule,
    CommentsModule,
    QstndtlModule,
    //UserModule,
    MenuModule
  ],
  exports: [
    SchoolComponent,
    HomeTemplateComponent,
    EshomeComponent,
    EbhomeComponent,
    McloudComponent,
    CubehorComponent,
    OurCustomersComponent,
    CsaHomeComponent,
    OurTeamComponent,
    AboutusComponent,
    SlidersComponent,
    DefaultvalueComponent,
    FeatureteComponent,
    // MarketComponent,
    EbIntroComponent,
    MenuComponent,
    AffiliatesComponent,
    BoardlistComponent,
    ProductComponent,
    CoachingHomeComponent,
    //KmMenuComponent,
    UspComponent,
    UspSlidesComponent,
    PricingComponent,
    ProblemSpaceComponent,
    PrdIntroComponent,
    PrdComboComponent,
    PlatformSetComponent,
    CmntRctnComponent,
   NewsRoomComponent,
    MenuModule,
    ExamListComponent,
    //TopicListComponent,
    DigibestHomeComponent,
    Cube3dComponent,

  ]
})
export class StaticModule { }
