import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MassBldrComponent } from './mass-bldr/mass-bldr.component';
import { SharedModule } from '../../shared/shared.module';
import { QstnModule } from '../../qstn/qstn.module';
import { TagModule } from '../../tag/tag.module';
import { KmMenuComponent } from './km-menu/km-menu.component';
import { SubMnuComponent } from './sub-mnu/sub-mnu.component';
import { NewMkrComponent } from './new-mkr/new-mkr.component';
import { GoStoreComponent } from './go-store/go-store.component';
import { GenNavComponent } from './gen-nav/gen-nav.component';
import { QstndtlModule } from '../../qstndtl/qstndtl.module';

@NgModule({
  declarations: [MassBldrComponent,KmMenuComponent, SubMnuComponent, NewMkrComponent, GoStoreComponent, GenNavComponent],
  imports: [
    CommonModule,
    SharedModule,
    QstnModule,

    TagModule,
    QstndtlModule
  ],
  exports: [
    MassBldrComponent,
    KmMenuComponent,
    SubMnuComponent,
    GenNavComponent,
    GoStoreComponent,
    KmMenuComponent,
    NewMkrComponent
  ]
})
export class MenuModule { }
