import { SubjectObj } from "../../shared/exam-selector/subject-obj";

export class Edi {

    subjects: SubjectObj[];
    


    parse(extra: string) {
     // console.log(extra);
    if (extra) {
      return JSON.parse(extra);
    }
    return null;
  }

  
}
