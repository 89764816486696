import { Component, OnInit } from '@angular/core';
import { ListerService } from '../../chat/lister.service';
import { ClientInfoService } from '../client-info.service';
import { SelectorService } from '../exam-selector/selector.service';

@Component({
  selector: 'app-context',
  templateUrl: './context.component.html',
  styleUrls: ['./context.component.scss']
})
export class ContextComponent implements OnInit {
  selectedSubject:string;
  selectedType:string;
  get offering() { return this.cs.getOffering()} 
  get subject() { return this.cs.getSubject()} 

  constructor(public slSrv:SelectorService, public ls:ListerService,public cs:ClientInfoService) { }

  ngOnInit(): void {

//can block if u want when offering is already selected.
  //act like initilizer
 
  
  if(this.subject && this.subject != "subject"){
    //pass subject if u want filtered types.
    this.slSrv.getTypeList(this.subject)
  
  this.selectedType = this.offering;
  this.slSrv.getSubjectList(this.selectedType);


  } 
        else{
        //  this.slSrv.getTypeList()
          }  
  
  
  }

  ngOnChanges(): void {
      
  }


  
}
