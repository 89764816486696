import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfrDtlService {
  
  public static ftrDescObj: Subject<any> = new Subject<any>();

  
  setMenuHdr(headerObj: any) {
    localStorage.setItem('menuHdr', JSON.stringify(headerObj))
     }
  
  getMenuHdr() {
    return JSON.parse(localStorage.getItem('menuHdr'));
  }
  

  setChapHdr(headerObj: any) {
    localStorage.setItem('chapHdr', JSON.stringify(headerObj))
     }
  
  getChapHdr() {
    return JSON.parse(localStorage.getItem('chapHdr'));
  }

  
  
  
  
  
  //hdrDtl: any;
  



  
  setClickedOfrDtl(q: any) {
    //this.hdrDtl= q;
    localStorage.setItem('ofrDtl', JSON.stringify(q))
   // console.log(q);
    
    // throw new Error('Method not implemented.');
  }
  

  constructor() { }

 getClickedOfrDtl(){
  //console.log(localStorage.getItem('ofrDtl'));
  return JSON.parse(localStorage.getItem('ofrDtl'));
  //return this.hdrDtl;
 }






/*

clickedInd: number;
  
  ofrDtls: any;
  currentCat: string;

loadOfrDtls(cat:string, ofrDtls:any){
  this.currentCat= cat;
  this.ofrDtls= ofrDtls;
  
  localStorage.setItem('ofrDtls', JSON.stringify(ofrDtls))
  console.log(ofrDtls);
  }
  
  
  setIndexClicked(i: any) {
    // throw new Error('Method not implemented.');
    this.clickedInd = i; 
    localStorage.setItem('clickedInd', i);
    //console.log("clicked ind: " + i);
   }
  
  
getClickedOld(){
var ofrDtls:[] = JSON.parse(localStorage.getItem('ofrDtls'))
if(ofrDtls){
//let cfgDtl=  this.ofrDtls[this.clickedInd];
console.log(ofrDtls);
let ind= localStorage.getItem('clickedInd');
console.log(ind);
let cfgDtl=  ofrDtls[ind];
console.log(cfgDtl);

return cfgDtl;
}
return null;
}
*/


}
