import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesModule } from '../pages/pages.module';
import { CommentsModule } from '../comments/comments.module';
import { ShowResultComponent } from './show-result/show-result.component';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { NavbarComponent } from '../pages/navbar/navbar.component';
import { SharedModule } from '../shared/shared.module';
import { TestListComponent } from './test-list/test-list.component';
import { QstndtlModule } from '../qstndtl/qstndtl.module';
import { McqMaComponent } from './mcq-ma/mcq-ma.component';
import { TestQstnComponent } from './test-qstn/test-qstn.component';
import { QstnStatusComponent } from './qstn-status/qstn-status.component';
import { DtlTopComponent } from './dtl-top/dtl-top.component';
import { ShortQstnComponent } from './short-qstn/short-qstn.component';
import { DtlFtrComponent } from './dtl-ftr/dtl-ftr.component';
import { ChatModule } from '../chat/chat.module';
import { TestHdrBtnComponent } from './test-hdr-btn/test-hdr-btn.component';
import { TestDtlBtnComponent } from './test-dtl-btn/test-dtl-btn.component';
import { McqOptnComponent } from './mcq-optn/mcq-optn.component';


@NgModule({
  declarations: [TestQstnComponent, ShowResultComponent, TestListComponent, McqMaComponent, QstnStatusComponent, DtlTopComponent, ShortQstnComponent, DtlFtrComponent, TestHdrBtnComponent, TestDtlBtnComponent,  McqOptnComponent],
  imports: [
    CommonModule,
    CommentsModule,
    //NgbModule,
    SharedModule,
    QstndtlModule,
    ChatModule,
    
    //NavbarComponent,
    //PagesModule
  ],
  exports: [TestListComponent,TestQstnComponent,QstnStatusComponent,McqMaComponent,DtlTopComponent,ShortQstnComponent,DtlFtrComponent,
    McqOptnComponent,TestHdrBtnComponent 
  ]
})
export class TestModule { }
