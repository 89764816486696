import { Component, Input, OnInit } from '@angular/core';
import { TestService } from '../test.service';

@Component({
  selector: 'app-test-hdr-btn',
  templateUrl: './test-hdr-btn.component.html',
  styleUrls: ['./test-hdr-btn.component.scss']
})
export class TestHdrBtnComponent implements OnInit {
  @Input() h: any;
  @Input() i: number;
  @Input() list:any;

  feedback :boolean;
  qstns: any;

  constructor(public ts:TestService) { }

  ngOnInit(): void {
  }

  storeAndClose(){
    
    //TestService.hideTest.next(true);
    this.ts.closeTest= true;
    this.ts.showResult= true;

  }
    
  loadResult(){
    //TestService.hideTest.next(true);
     let user = this.ts.cs.getSignedUser();
     let hdrId = this.ts.cs.getChapterNum();
     if(this.ts.qstns){
      this.ts.cServe.evaluate(hdrId,user,this.ts.qstns).subscribe(
       data => {
         if((data as any).status ==200){
         
         this.ts.qstns= (data as any).result;
         localStorage.setItem('qstns-'+this.ts.cs.getChapter(),JSON.stringify(this.ts.qstns) )
        // console.log(data);
        // this.ts.cs.addMsg(" ansrs received after evaluation");
         this.ts.closeTest= true;
         this.ts.showResult= true;
         this.ts.finishTest();
        } else{
          this.ts.cs.addMsg(" error --");  
         }
        } );

     }
    else{
     this.ts.cs.addMsg(" no locally stored ansrs found");
     
    }
    
  }

  
  showResult(){
    //this.ts.closeTest= true;
    this.ts.showResult= true;
    this.ts.finishTest();
  }


  
  storeAnsrsInDB(){
    this.ts.storeAnsrsInDB().subscribe(data=>{ 
      localStorage.setItem('qstns-'+this.ts.cs.getChapter(),JSON.stringify(this.ts.qstns));
      this.ts.cs.addMsg("pocessed","info");
      this.ts.qstns= (data as any).result;
      localStorage.setItem('qstns-'+this.ts.cs.getChapter(),JSON.stringify(this.ts.qstns) )
      console.log(data);
      
    });

   
  }


}
