
<div *ngIf='showForm'>

<div *ngIf= "ss && !ss.principal" class="container">
	
	<div *ngIf="!success" class="d-flex justify-content-center h-100">
		<div class="card">
			<div class="card-header">
				<h3>Sign In</h3>
				<div class="d-flex justify-content-end social_icon">
					<!--					
					<span><i class="fa fa-facebook-square"></i></span>
					<span><i class="fa fa-google-plus-square"></i></span>
					<span><i class="fa fa-twitter-square"></i></span>
					-->
				</div>
			</div>
			<div class="card-body">
				<form [formGroup]="signinForm" (ngSubmit)="onSubmit()">
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text"><i class="fa fa-user"></i></span>
						</div>
						<input type="text" formControlName="userId" class="form-control" placeholder="username">

					</div>
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text"><i class="fa fa-key"></i></span>
						</div>
						<input type="password" formControlName="password" class="form-control" placeholder="password">
					</div>
					<div class="row align-items-center remember">
						<input type="checkbox" 
						formControlName="remember" class="form-control" placeholder="remember"
						>Remember Me
					</div>
					<div class="form-group">
						<input type="submit" value="Login" class="btn float-right login_btn">
					</div>

				</form>
			</div>
			
			<div  *ngIf= "bs.enable('signin','signupLink-site')" class="card-footer">
				<div  class="d-flex justify-content-center links">
					Don't have an account?<a [routerLink] = "['/',site,cat,'features','signup']" class="btn badge btn-primary rounded mx-2" >Sign Up</a>
				</div>
				
				<div class="d-flex justify-content-center text-light text-small"> for &nbsp; <a  class="btn btn-link badge bg-light" routerLink = {{togetherUrl()}}> personalized learning </a> 
				</div>
				
			</div>

				<div class="d-flex justify-content-center">
<!--
					<a [routerLink] = "['/',site,cat,'features','contact']">Forgot your password?</a>
-->
					
<button class= "badge btn-secondary mt-2 mx-3 px-2 " (click) = 'expand= !expand'> {{ expand? ' hide- ':' Forgot your password?'}} </button> 

<!--
 or 

<a class="badge btn-secondary mt-2 mx-3 px-3"
routerLink= '{{cs.getRouteUrl(this.cat,"features/signup")}}' > Sign Up </a>
-->

<ng-container *ngIf=  "cs.enable('signin','genericSignin')">
or 

<a  class="badge btn-secondary mt-2 mx-3 px-3"
routerLink= '{{cs.getRouteUrl("gen::"+ this.cat,"features/signin")}}' >clean memory & Sign in </a>
</ng-container>


</div>
			


		</div>
	</div>

	<app-comments *ngIf= "expand" [myTopic]= "'Customer Service'"> </app-comments>
	

	<!-- try later ngb bootstrap way 
	<div *ngIf="success" class="results">
			<p class="alert alert-success">
				<ngb-alert [dismissible]="false">
					 Thanks for logging in {{signinForm.controls.userId.value}}...
				</ngb-alert>
			</p>
	</div>

	<div *ngIf="failure" class="results">
			<p class="alert alert-failure">
				<ngb-alert [dismissible]="true">
					 Sorry, Something went wrong...{{signinForm.controls.userId.value}}...
				</ngb-alert>
			</p>
	</div>
	-->
	
<!-- dismissible event needs to be handled!-->
</div>



</div>
