

      <div *ngIf = "enable('id')"  class="form-group">
          <label for="id">id# </label>
          <input type="text" class="form-control" id="id" required [(ngModel)]="ud.id"  name="id">
        </div>
    
        
      <div *ngIf = "enable('lastName')" class="form-group">
        <label for="lasName">last Name# </label>
        <input type="text" class="form-control" id="lastName" required [(ngModel)]="ud.lastName" name="lastName">
      </div>
  
      <div *ngIf = "enable('firstName')" class="form-group">
        <label for="firstName">firstName: </label>
        <input type="text" class="form-control" id="firstName" required [(ngModel)]="ud.firstName" name="firstName">
      </div>
  
      <div *ngIf = "enable('board')" class="form-group">
        <label for="board">board</label>
        <input type="text" class="form-control" id="board" required [(ngModel)]="ud.board" name="board">
      </div>
  
      <div  *ngIf = "enable('course')" class="form-group">
          <label for="course">course/Class# </label>
          <input type="text" class="form-control" id="course" required [(ngModel)]="ud.grade" name="course">
        </div>
  
        <div  *ngIf = "enable('clsSec')" class="form-group">
          <label for="clsSec">cls-Section# </label>
          <input type="text" class="form-control" id="clsSec" required [(ngModel)]="ud.clsSec" name="clsSec">
        </div>
  
        
      <div  *ngIf = "enable('dfltMnu')" class="form-group">
        <label for="dfltMnu">dflt Menu</label>
        <input type="text" class="form-control" id="dfltMnu" required [(ngModel)]="ud.dfltMnu" name="dfltMnu">
      </div>
  
        
      <div *ngIf = "enable('subject')" class="form-group">
        <label for="subject">Dflt subject </label>
        <input type="text" class="form-control" id="subject" required [(ngModel)]="ud.subject" name="subject">
      </div>
  
        <div  *ngIf = "enable('groupId')" class="form-group">
          <label for="groupId">User Type# </label>
          <input type="text" class="form-control" id="groupId" required [(ngModel)]="ud.groupId" name="groupId">
        </div>
         
        <div  *ngIf = "enable('roles')" class="form-group">
          <label for="roles">Additional Role(s) </label>
          <input type="text" class="form-control" id="roles" required [(ngModel)]="ud.roles" name="roles">
        </div>
         
  
        
        <div *ngIf = "enable('shrgrp')"  class="form-group">
          <label for="shrgrp">Share/Project Grp </label>
          <input type="text" class="form-control" id="shrgrp" required [(ngModel)]="ud.shrGrp" name="shrgrp">
        </div>
  
      <div  *ngIf = "enable('emailId')" class="form-group">
        <label for="emailId">EmailId# </label>
        <input type="text" class="form-control" id="emailId" required [(ngModel)]="ud.emailId" name="emailId">
      </div>
  
      <div  *ngIf = "enable('phone')" class="form-group">
          <label for="phone">phone# </label>
          <input type="text" class="form-control" id="phone" required [(ngModel)]="ud.phone" name="phone">
        </div>
      
  
       
      
      <div  *ngIf = "enable('learnMode')" class="form-group">
          <label for="learnMode">learnMode# </label>
          <input type="text" class="form-control" id="learnMode" required [(ngModel)]="ud.learnMode" name="learnMode">
        </div>
      
        <div  *ngIf = "enable('domain')" class="form-group">
          <label for="domain">domain </label>
          <input type="text" class="form-control" id="domain" required [(ngModel)]="ud.domain" name="domain">
        </div>
       
        
        <div  *ngIf = "enable('mentorId')" class="form-group">
          <label for="mentorId">mentor Id </label>
          <input type="text" class="form-control" id="mentorId" required [(ngModel)]="ud.mentorId" name="mentorid">
        </div>
       
       


  <app-ur-flds *ngIf = "enable('usrRole')" [headerId] = ud.headerId >  </app-ur-flds>