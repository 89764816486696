


<div class="ansrWidth  container m-2" *ngIf="!qs.onlyQstn && qs.enable('viewAnsrEligible'); else lineItm">

  <!--
  {{qs.enable('viewAnsrEligible')}} --{{qs.enable('displayAllAnswer')}}
  
  {{offering}} -{{qs.enable('displayAllAnswer')}}--{{qs.enable("isAnsr")}}
-->

  <div class= "" *ngIf="(q.answer ||q.digAnsr)  && qs.enable('isAnsr') && !qs.enable('displayAllAnswer')">
     <button class= "btn btn-block badge  btn-info" *ngIf="!showA" (click)="showAns()">Show Answer </button>

      <!--&& qs.enable('displayAllAnswer')-->
      <button class= "btn btn-block badge btn-success" *ngIf="showA " (click)="hideAns()">Hide Answer </button>
  
     

    </div> 


      <ng-container *ngIf="displayAnsUI(q)"  > 
        <span *ngIf= 'qs.status=="crct"' class="answersc font-weight-bold font-italic   mt-1 ">Suggested </span>
         
    <div class= "float-right" *ngIf= 'qs.cs.enable("ansrView","digAnsr") && q.digAnsr'>  
      <span class= " text-info small" >digAnsr: </span>    
        &nbsp; {{q.digAnsr}}
    </div> 

        <span *ngIf= 'qs.enable("isAnsr") && q.answer' [id] = 'ansDiv' class="answersc font-weight-bold font-italic  mt-1 " > Ans  </span> :



        
        
        <div  *ngIf= 'false && q.answerImages'  class= "text-center"> 
          <img  src= {{q.answerImages}} alt= "">
        </div>
        

         <!--{{q.digAnsr}}&nbsp;-->
        <span *ngIf= "qs.enable('oldMath')" class="answersc"  innerHTML= '{{ q.answer }} ' >   </span>
        
       

        <div *ngIf= "qs.enable('newMath')"  [appMath]  = 'q.answer' > </div>

    </ng-container>
    </div>
    
<ng-template #lineItm>
        
</ng-template> 