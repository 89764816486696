
<button (click)="makeCstmBstr(refBstrId,key)" class="btn badge btn-info"
style="margin-left: 10px">make {{count}} -% Bstr</button>

<button  (click) = 'changeCount(1)'> change count to 1</button>

<!--
<button  (click) = 'process()'> BACK</button>

<button backButton > BACKDir</button>
-->