<div *ngIf="calling; else noCall"> 

    <div *ngIf="(data$ | async) as d; else loading">
        
      

      <ng-container *ngIf="d.dtls && d.dtls.length; else noItems">
          
        <div class="text-quote text-center bg-info" [innerHTML] = d.hdr.title>  
        </div>

           
          <div *ngFor="let s of d.dtls; let i= index;" class="container row">
            
            <div *ngIf= "show(s)">
              <!-- 
              <app-problem-space  *ngIf = 'cs.enable("ebhome","problem")' [q] = s> </app-problem-space>
              -->
              <app-catalog  *ngIf = 'cs.enable("ebhome","problem")' [q] = s> </app-catalog>

            </div>    
           </div> 
        </ng-container>
        
        <ng-template #noItems>*</ng-template>
       </div>
       <ng-template #loading>loading slides...</ng-template>
    </div>
    <ng-template #noCall>!</ng-template> 