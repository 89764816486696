import { Component, OnInit } from '@angular/core';
import { ExamDataService } from '../../exam-data.service';

@Component({
  selector: 'app-asgnmnt-service',
  templateUrl: './asgnmnt-service.component.html',
  styleUrls: ['./asgnmnt-service.component.scss']
})
export class AsgnmntServiceComponent implements OnInit {

  constructor(private es:ExamDataService) { }

  ngOnInit(): void {
  }

  autoRollAsgnmnt(){
    this.es.autoRollAsgnmnt("esa","CBSE","X","cmn","tasgn-pbstr","subject",).subscribe();
    
    this.es.autoRollAsgnmnt("esa","CBSE","X","cmn","tasgn-tbstr","subject").subscribe();
     return true;
  }

  startAutoRoller(){
    this.es.startAutoRoller("esa","CBSE","X","cmn","tasgn-pbstr","subject",).subscribe();
  return true;
  }

}
