import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-vid-dtl',
  templateUrl: './vid-dtl.component.html',
  styleUrls: ['./vid-dtl.component.scss']
})
export class VidDtlComponent implements OnInit {
@Input() q:any;
@Input() i:number;
showUpdate:boolean;
longContent:string;

utube = "https://www.youtube.com/embed/";
  

  constructor(private modalService: NgbModal,public cs:ClientInfoService) { }

  ngOnInit(): void {
  }

  
  
openScrollableContent(longContent) {
  this.modalService.open(longContent, { scrollable: true });
}


}
