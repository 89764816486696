
<!--
<button  *ngIf= "(hdr.school && hdr.school != 'cmn') && vs.show('makeCmn',i,hdr)"  class= "btn-md btn-primary text-white  badge mr-1"  (click)="vs.makeCmnBranch(hdr,i)"> share with class/batch</button>



<button  *ngIf= "(hdr.school && hdr.school != 'cmn') && vs.show('makeCmn',i,hdr)"  class= "btn-md btn-primary text-white  badge mr-1"  (click)="vs.makeCmnBranch(hdr,i)"> share across current branch</button>


<button  *ngIf= "(hdr.school && hdr.school != 'cmn') && vs.show('makeCmn',i,hdr)"  class= "btn-md btn-primary text-white  badge mr-1"  (click)="vs.makeCmnBranch(hdr,i)"> use across all branches</button>

<p> welcome from cntnt-availer </p>
-->

<button  *ngIf= "(hdr.site && hdr.site != 'cmn') && cs.enable('cntnt-availer','makeCmn',null,hdr)"  class= "btn-md btn-primary text-white  badge mr-1"  (click)="makeCmn(hdr,i)"> Share with COE</button>


<button  *ngIf= "(hdr.site && hdr.site != 'cmn') && cs.enable('cntnt-availer','makeTagCmn',null,hdr)"  class= "btn-md btn-primary text-white  badge mr-1"  (click)="makeCmnTH(hdr,i)">share with COE</button>

<button  *ngIf= "(!hdr.site || hdr.site !=cs.getSite())  && cs.enable('cntnt-availer','override',null,hdr)"  class= "btn-md btn-primary text-white  badge mr-1"  (click)="copy(hdr,i)">duplicate for Institute</button>


<button  *ngIf= "(!hdr.site || hdr.site !=cs.getSite()) && cs.enable('cntnt-availer','overrideCFG',null,hdr)"  class= "btn-md btn-primary text-white  badge mr-1"  (click)="copy(hdr,i)">copy config for Institute</button>

