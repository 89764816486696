import { Injectable } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Injectable({
  providedIn: 'root'
})
export class CmntListService {
  //cmntType: string;
  
  showdataTable:boolean;
  
  get signedUser() { return this.cs.getSignedUser()};

  constructor(public cs:ClientInfoService) { }

  
  
  

  preChecker(btnId:string,cmntType:string,i?:number){
    if(btnId== 'detail'){ return false;}
    if(cmntType == 'Hinfo'){
   if(btnId== 'crud'){ return false;}
   if(btnId== 'addComment'){ return false;}
  }

  if ( btnId=="addComment"  && cmntType == "HnewInfo" && this.cs.getGroupId() != 'site_admin'){
    return false;
}  

  if ( btnId=="addComment"  && this.cs.getGroupId() == 'learner'){
    if(['Hmtg','Dnote'].includes(cmntType)){
           return false;
     }  else{
        return true;
      }
     }
     
    

       
    return true;
    }
  
      enable(btnId:string,cmntType?:string, i?:number,rec?:any){

      if(!this.preChecker(btnId,cmntType,i)){ return false}
      let component = 'cmntList';
      let enable= this.cs.enable(component,btnId);
      return this.customEnable(btnId,enable,i);
      }
      
    customEnable(btnId: string, enable: boolean,i?:number) {
      return enable;
      }

      
}
