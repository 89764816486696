import { Component, Input, OnInit } from '@angular/core';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  providers: [NgbRatingConfig] // add NgbRatingConfig to the component providers
})
export class RatingComponent implements OnInit {
  @Input() q: any;
  @Input() i:number;
  
  selected = 0;
  hovered = 0;
  readonly = true;

  constructor(config: NgbRatingConfig) {
    config.max = 5;
    config.readonly = true;
   }

  ngOnInit(): void {
    
  }


}


