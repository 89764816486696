import { Component, OnInit,AfterViewInit } from '@angular/core';
import { ExamDataService } from '../../exam-data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {BstrSet} from '../../bstr-set';
import { test1 } from '../../../assets/js/custom';
import { } from 'jquery';
import * as Chart from 'chart.js';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
//import { BoardDataService } from '../../board-data.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
canvas: any;
  ctx: any;
 messageForm: FormGroup;
  submitted = false;
  success = false;

contact:Object;
questions:Object;
bstrSet:BstrSet;
uploadedFiles:any;
dataBubble:any;

testMath="<strong> Ram </strong>  <math> <mfrac><mn>1</mn><mn>2</mn></math> Lakshman $$ <math><mfrac><mn>1</mn><mn>2</mn></math> $$    ax^2  $$ax^2$$" ;

options: any;
chartData: any;
  dataPrime: {
    labels: string[]; datasets: {
      label: string; data: number[];
      //backgroundColor:"red"
      fill: boolean; borderColor: string;
    }[];
  };
  fileData: string | any[];

  constructor(private bd: BoardDataService ,private data: ExamDataService,private formBuilder: FormBuilder, public cs:ClientInfoService) {

   }


   ngOnInit() {

    


  $(document).ready(function () {
      //console.log("working");
       // alert("JQuery working");

       });

//primeng chart data

       this.dataPrime = {
                   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                   datasets: [
                       {
                           label: "Student-1's Score",
                           data: [65, 59, 80, 81, 56, 55, 40],
                           //backgroundColor:"red"
                           fill: false,
                    borderColor: '#4bc0c0'
                                     },
                       {
                           label: 'Current Trend ',
                           data: [28, 48, 40, 19, 86, 27, 90],
                          // backgroundColor:"white"
                          fill: false,
                    borderColor: '#4bc0c0'
                       }
                   ]
               };




               this.dataBubble = [
                    [{
                      x: 40,
                      y: 10,
                      r: 20
                    }],
                    [{
                      x: 10,
                      y: 40,
                      r: 50
                    }]
                  ];

               this.options = {
                 backgroundColor: "red",
                     title: {
                         display: true,
                         text: 'Boost-o-Meter',
                         fontSize: 16,

                     },
                     legend: {
                         position: 'bottom'
                     }
                 };






this.messageForm = this.formBuilder.group({
bstrSetId: ['', Validators.required],

    });

// console.log('yes' + test1());
//  test1();
//external js example..

  }

changeQuestionSet(board:string, subject:string, bstrSetId:string) {

// alert("Booster set getting invoked with  "+board +"/"+ bstrSetId);
 this.data.changeBstrSet(board,bstrSetId).subscribe();


 alert("Booster set Changed "+ bstrSetId +  board);
  }

onSubmit(bstrSet: string) {
//alert("Booster set String is "+ bstrSet);
    this.submitted = true;

    if (this.messageForm.invalid) {
        return;
    }

    this.success = true;
    //alert("Booster set String submitted: "+ bstrSet);
   this.changeQuestionSet("ICSE","all",bstrSet);
}





ngAfter1ViewInit() {
    this.canvas = document.getElementById('myChart');
    this.ctx = this.canvas.getContext('2d');
    let myChart = new Chart(this.ctx, {
      type: 'line',
      data: {
         // label: 'Marks',
          labels: [5,10,15,20,25,30,35,40,45,50,55],
          datasets: [{
              label: 'Cumulative Frequencey',
              data: [2,7,13,21,31,56,76,94,98,100,105],

              //(5, 2), (10, 7), (15, 13), (20, 21),
//(25, 31), (30, 56), (35, 76), (40, 94), (45, 98), (50, 100)
              //backgroundColor: ["#F5DEB3","blue","red","yellow"],
              backgroundColor: ["#F5DEB3",],
              /*
              backgroundColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)'
              ],
              */
              borderWidth: 1
          }]
      },
      options: {
        responsive: false,
       // display:true
      }
    });
  }
}
