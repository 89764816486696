import { Component, Input, OnInit } from '@angular/core';
import { TaghdrListService } from '../taghdr-list/taghdr-list.service';

@Component({
  selector: 'app-slctr-view',
  templateUrl: './slctr-view.component.html',
  styleUrls: ['./slctr-view.component.scss']
})
export class SlctrViewComponent implements OnInit {
@Input() list1: any;
  loadedFilter: boolean;

constructor(public tl:TaghdrListService) { }

  ngOnInit(): void {
  }

  loadFilterQstnSet(id: number) {
    
    this.tl.ths.loadFilterQstnSet(id)
      .subscribe(
        data => {
          localStorage.setItem('filterQstns', JSON.stringify((data as any)?.result));
          this.loadedFilter= true;
         
        },
        error => console.log(error));
  }

cleanFilterQstnset(){
  localStorage.removeItem('filterQstns');
  this.loadedFilter= false;
}
}
