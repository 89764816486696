import { Injectable } from '@angular/core';
import { CanActivate,ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PremiumGuard implements CanActivate {
  readonly premiumSignNeeded = environment.premiumSignNeeded;
  signedUser:string;
constructor(private router: Router) { }

canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
          debugger
        return true;
// fix this later... default yes for all for now..
/*
        if (localStorage.getItem('participant') != null)
          return true;

          if (localStorage.getItem('signedUser') != null){
            this.signedUser = localStorage.getItem('signedUser');
            return true;
          }

              if(!this.premiumSignNeeded || (this.signedUser && this.signedUser!= 'Guest'))  {
                  return true;
                }


                else{
                  return false;

                }
*/

      }
    }
