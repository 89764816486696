<div *ngIf= 'true || !scrolled'>
<div class="scene">
  <div class="cube">
    <div class="side back">
      <div class="guides"></div>
      <span>Build with Click Assignment</span>
    </div>
    <div class="side top">
      <div class="guides"></div>
      <span>Digitize Properly</span>
    </div>
    <div class="side bottom">
      <div class="guides"></div>
      <span>Personalized Interaction</span>
    </div>
    <div class="side front">
      <div class="guides"></div>
      <span>DigiBest Teacher</span>
    </div>
  </div>
</div>
<br>
<br>
<br>
</div>