<div *ngIf="calling; else noCall"> 

  <div *ngIf="(data$ | async) as d; else loading">
      
    <ng-container *ngIf="d.dtls && d.dtls.length; else noItems">
        
      <app-section-divider [h]= 'd.hdr' [titleFlag]= 'true'> </app-section-divider>
      
        <!--enough for animation-->
        
       
        <!-- debugger
        <div *ngFor="let s of d.dtls; let i= index;" class="container row">
          {{s.questionImages}}
          hello-- {{s.questionImages}}
           </div>
          -->

        <ngb-carousel [interval] ="3000" [pauseOnHover]="false" id="myCarousel">
        <div *ngFor="let s of d.dtls; let i= index;" class="container row">
          <ng-container *ngIf= 'show(s)'>
            <ng-template ngbSlide>
              <img [src]='rootUrl + s.questionImages' alt= '{{i}}' class="center-block img-fluid">
          
              <!--for text on image-->
              <div class="carousel-caption">
                <div class = "h4 esSlide1" >
                <span class= "text-left" [innerHTML]="s.instruction|safe :'html'">  </span>  
                </div>    
  
                <span class = "text-center" [innerHTML]="s.question|safe :'html'"> </span>    

              <div class="py-1 text-center">
              <a *ngIf='s.extLink && unsigned'  class="btn btn-primary badge"    routerLink = {{getRouteUrl(s.category,s.extLink)}} fragment=""
              [routerLinkActive]="['is-active']"
               ngbTooltip="" placement="bottom"> {{s.title}} </a>
              </div>

                <div class="text-right" > 
                    <span [innerHTML] = "s.answer|safe :'html'"></span> 
                </div>

            </div>  
            <!--End of text on image-->

            </ng-template>
          </ng-container>
         </div> 
         
         <a class="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
          <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#myCarousel" role="button" data-slide="next">
          <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
      </a>         
        </ngb-carousel>
        
        
      <app-section-divider [h]= 'd.hdr'> </app-section-divider>  
 
      </ng-container>
      <ng-template #noItems>*</ng-template>
     </div>
     <ng-template #loading>loading slides...</ng-template>
  </div>
  <ng-template #noCall>!</ng-template> 

 


