

<app-daily-detail [type] = '"fresh"' [subject] = '"Thought"' > </app-daily-detail>


<div *ngIf= 'false' class="text-center pt-5 my-1" >
<p class= "font-italic text-muted">  Thought Of the Day </p>
<ng-container *ngIf= "thought && thought.thought">
<blockquote class="blockquote pb-4" innerHTML= {{thought.thought}}> 
</blockquote>

<p class= "text-small text-muted font-italic float-right pr-3 mb-2" > {{thought.author}} </p>    

</ng-container>

<br>
<br>
<br>
<br>

</div>




<!--
<div  class="lead text-center text-success py-4" innerHTML={{thought}}>
Thought...
</div>
-->
