import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-cmnt-rctn',
  templateUrl: './cmnt-rctn.component.html',
  styleUrls: ['./cmnt-rctn.component.scss']
})
export class CmntRctnComponent implements OnInit {

  get cat(){return this.cs.getCat()};
  get chapter(){return this.cs.getChapter()};
  get qstnId(){return this.cs.qstnId};
  constructor(public cs:ClientInfoService) { }

  ngOnInit(): void {
  }

}
