import { Component, Input, OnInit } from '@angular/core';
import { OfrDtlService } from '../../shared/headings/ofr-dtl.service';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-ofr-btn',
  templateUrl: './ofr-btn.component.html',
  styleUrls: ['./ofr-btn.component.scss']
})
export class OfrBtnComponent implements OnInit {
  @Input() h:any;
  @Input() d:any;
  @Input() subDesc:any;
  edit: boolean;

  constructor(public cs:ClientInfoService) {
        
   }

  ngOnInit(): void {
    this.edit= this.cs.enable("ofrBtn","edit")
    
  }

  
ngOnChanges(){
 // console.log("ftrDesc",this.d)
  OfrDtlService.ftrDescObj.next(this.d);
  }
}
