export class Format {
    code:string;
    name: string;
    desc?: string;
  
  
  constructor(code:string,name:string,desc?:string ) { 
    this.code= code;    
    this.name = name;
    this.desc= desc;
     }  

    }