<div *ngIf="formatList" class="btn-group" ngbDropdown role="group"
        aria-label="Button group with nested dropdown">
        <span class= 'mr-1'>format:</span> 
        <button type ="button"  class="btn btn-outline-info border-0" ngbDropdownToggle>
                {{selectedFormat?selectedFormat.name :"select"}}
        </button>
        <div class="dropdown-menu" ngbDropdownMenu>
                <button *ngFor='let c of formatList' ngbDropdownItem type= "button"
                        (click)='changeFormat(c)'> <span innerHTML='{{c.name}}'>
                        </span></button>

        </div>
</div>

