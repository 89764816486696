import { Component, OnInit,Input } from '@angular/core';
import { NgModule, ElementRef, Injectable } from "@angular/core";

import { BrowserModule, Title,Meta } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { ReactiveFormsModule, FormControl, FormsModule } from "@angular/forms";
import { BoardDataService } from '../../board-data.service';
import { Board } from '../../board';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';

import {
  HttpClientJsonpModule,
  HttpClientModule,
  HttpClient
} from "@angular/common/http";
/*
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
*/

import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-excel',
  templateUrl: './excel.component.html',
  styleUrls: ['./excel.component.scss']
})
export class ExcelComponent implements OnInit {

//@Input() feature: string;


  site:string;
  cat:string;
  board:string;
  course:string;
  feature: string;
  offering: string;
  subject: string;
  chapter: string;
  content: string;
  showBoards:boolean;
  offering2: string;
  get unsigned(){return this.cs.isUnsigned()}
  
  boardNeeded:boolean;
  headerId:number;
  headerObj:any;
  ftrDtlObj:any;
  questions:any;
  categoryComp:string;
  styles: any;
  school:string;
  schlLoc:string;
  ftrDescObj:any; //question 
  ftrDescObjDummy:any; //just for hiding on detail screen...as moving to modal
  get signedUser(){ return  this.cs.getSignedUser()};
  constructor(private route: ActivatedRoute,
    private router: Router, private elementRef:ElementRef, private dataService: BoardDataService, private titleService: Title,
    private metaTagService: Meta,private cs:ClientInfoService,private fd:FixedDataService) {
      this.fetchData();
  }


stripFtrDesc(extUrl: string): any{
    const index = this.questions.dataList.findIndex(item => item.groupId === 'ftrDesc');
    //console.log(" index is: "+ index);
    if(index >-1){
    this.ftrDescObj = this.questions.dataList[index]; 
    //this.questions.dataList.splice(index, 1);
    } else{
      this.ftrDescObj = null;
    }
    return this.ftrDescObj;
          }


          
fetchData(){

  this.cs.updateParms(this.route);

  this.site= this.cs.getSite();
  this.board= this.cs.getBoard();
  this.course= this.cs.getCourse();
  this.cat= this.cs.getCat();
  this.feature= this.cs.getFeature();
  this.boardNeeded= this.cs.boardMust();
  //console.log(this.feature);

  this.school= this.cs.getSchool();
  this.schlLoc= this.cs.getSchlLoc();
 

  if(this.cs.getFeature()){
  this.categoryComp=  this.cs.getFeature();
  //alert("Feature is " + this.cs.getFeature());
  }else{
    //alert("cat is " + this.cs.getCat());
    this.categoryComp =  this.cs.getCat();
    }

  //alert(this.categoryComp);
  this.dataService.getCatHeaders(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.categoryComp,this.cs.getSubject()).subscribe(data =>
  {
  this.headerObj= (data as any).dataList[0];

  //alert(this.headerObj.id);
  if(this.headerObj){
  //let bgImageUrl = 'assets/img/3bb.jpg';
    this.headerId= (data as any).dataList[0].id;
  //this.offering removed as booster failing as searching tag file
  this.dataService.getCfgQuestions(this.site,this.board,this.course,'pyq','subj',this.headerId).subscribe(data => {
            
              this.questions=(data as any).dataList;
              //console.log(this.questions);
              this.ftrDescObj = this.cs.getFtrDesc(this.questions);


  /*  introduce this later for feature detail line item
           if((data as any).dataList[0]){   
           this.ftrDtlObj=    (data as any).dataList[0]? (data as any).dataList.filter(item => item.groupId.search(new RegExp('ftrDtl', 'i')) > -1) : [];
              console.log(this.ftrDtlObj);
   } 
  */

  });
            
  }

  });


}


ngOnInit() {
  this.fetchData();
this.router.events.pipe(
filter((event: RouterEvent) => event instanceof NavigationEnd)
).subscribe((event) => {
  this.boardNeeded=this.cs.boardMust();
  this.fetchData();
});






/*
//alert(this.feature);
if(this.cs.getFeature()){
  this.categoryComp=  this.cs.getFeature();
}else{
  this.categoryComp=  this.cs.getCat();
  }




    this.dataService.getCatHeaders(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.categoryComp,this.cs.getSubject()).subscribe(data =>
    {
    //this.headerObj= (data as any).dataList[0];
    this.headerId= (data as any).dataList[0].id;

    this.dataService.getCfgQuestions(this.site,this.board,this.course,this.offering,'subj',this.headerId).subscribe(dataList => {
            this.boardDetails = dataList;
            this.questions=dataList;
            console.log(dataList);
        });


    });

if(this.feature){

this.headerId= this.fd.getFeatureHdrId(this.cs.getSite(),this.feature);

}else{
  this.headerId= this.fd.getFeatureHdrId(this.cs.getSite(),this.cs.getCat());
};

this.dataService.getSiteHeaderById(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.cs.getOffering(),this.cs.getSubject(),this.headerId).subscribe(data => {
 this.headerObj= (data as any).dataList[0];
 console.log(this.headerObj);
  });
this.dataService.getCfgQuestions(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.cs.getOffering(),this.cs.getSubject(),this.headerId).
subscribe(data => {

        this.details = (data as any).dataList;
        console.log(this.details);
//sorting logic
      this.details=   this.details.sort(function(a,b) {
                  if (a.sequence < b.sequence)
              return -1;
            if (a.sequence > b.sequence)
              return 1;
            return 0;
          });
console.log(this.details);

    });
*/


  }


enable(btnId:string){
 return this.cs.enable("excel",btnId);
 }

  showEE(category:string, offering:string){
    if(!this.cs.getBoard() ){
      this.showBoards = true;
      this.offering2= offering;
        //alert("offering2 in excel is"+ this.offering2);
      alert("Please select exam/Class first");
        //this.router.navigate([this.cs.getSite(),this.cat,'features','excel','board','course',offering],{ fragment: 'boards' } );
//'board','course',instruction
      //  this.router.navigate([this.cs.getSite(),category,this.cs.getBoard(),this.cs.getCourse(),offering]  );
        return false;
    } else{
      this.router.navigate([this.cs.getSite(),category,this.cs.getBoard(),this.cs.getCourse(),offering]  );
    }
  }

}
