import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QstnhdrListComponent } from './qstnhdr-list/qstnhdr-list.component';
import { SharedModule } from '../shared/shared.module';
import { QstnhdrAddComponent } from './qstnhdr-add/qstnhdr-add.component';
import { QstnhdrUpdComponent } from './qstnhdr-upd/qstnhdr-upd.component';
import { QstnhdrShowComponent } from './qstnhdr-show/qstnhdr-show.component';
import { FltrHdrLstrComponent } from './fltr-hdr-lstr/fltr-hdr-lstr.component';
import { HdrBtnLstrComponent } from './hdr-btn-lstr/hdr-btn-lstr.component';
import { DgtzrBtnComponent } from './dgtzr-btn/dgtzr-btn.component';
import { SylbsMaprBtnComponent } from './sylbs-mapr-btn/sylbs-mapr-btn.component';
import { CrudBtnComponent } from './crud-btn/crud-btn.component';
import { TagModule } from '../tag/tag.module';
import { ViewLstrComponent } from './view-lstr/view-lstr.component';
import { GateMoverComponent } from './gate-mover/gate-mover.component';
import { DrftMakerComponent } from './drft-maker/drft-maker.component';
import { OptionalModule } from '../optional/optional.module';
import { CntntAvailerComponent } from './cntnt-availer/cntnt-availer.component';
import { SelfLrnrComponent } from './self-lrnr/self-lrnr.component';
import { MentorLrnrComponent } from './mentor-lrnr/mentor-lrnr.component';
import { SemMapperComponent } from './sem-mapper/sem-mapper.component';
import { TestViewBtnComponent } from './test-view-btn/test-view-btn.component';
import { StatusBasedViewComponent } from './status-based-view/status-based-view.component';
import { StatusBasedStudentViewComponent } from './status-based-student-view/status-based-student-view.component';
import { StatusBasedTeacherViewComponent } from './status-based-teacher-view/status-based-teacher-view.component';
import { StatusBasedCmnViewComponent } from './status-based-cmn-view/status-based-cmn-view.component';
import { JsonQstnHdrListComponent } from './json-qstn-hdr-list/json-qstn-hdr-list.component';
import { NjsonQstnhdrListComponent } from './njson-qstnhdr-list/njson-qstnhdr-list.component';
import { MultiHdrSlctrComponent } from './multi-hdr-slctr/multi-hdr-slctr.component';
import { ChptrSrcSlctrComponent } from './chptr-src-slctr/chptr-src-slctr.component';





@NgModule({
  declarations: [QstnhdrListComponent, QstnhdrAddComponent, QstnhdrUpdComponent, QstnhdrShowComponent, FltrHdrLstrComponent, HdrBtnLstrComponent, DgtzrBtnComponent, SylbsMaprBtnComponent, CrudBtnComponent, ViewLstrComponent, GateMoverComponent, DrftMakerComponent, CntntAvailerComponent, SelfLrnrComponent, MentorLrnrComponent, SemMapperComponent, TestViewBtnComponent, StatusBasedViewComponent, StatusBasedStudentViewComponent, StatusBasedTeacherViewComponent, StatusBasedCmnViewComponent, JsonQstnHdrListComponent, NjsonQstnhdrListComponent, MultiHdrSlctrComponent, ChptrSrcSlctrComponent ],
  imports: [
    CommonModule,
    SharedModule,
    TagModule,
    OptionalModule 
      ],
  exports: 
  [GateMoverComponent,ViewLstrComponent, HdrBtnLstrComponent,DgtzrBtnComponent,QstnhdrListComponent,QstnhdrUpdComponent,FltrHdrLstrComponent,SylbsMaprBtnComponent,CrudBtnComponent,QstnhdrAddComponent,CntntAvailerComponent,SemMapperComponent,JsonQstnHdrListComponent,NjsonQstnhdrListComponent,MultiHdrSlctrComponent,ChptrSrcSlctrComponent]
})
export class QstnModule { }
