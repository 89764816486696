

<div class= "fullWidth" [hidden]="submitted" *ngIf= "detail">
    
    <!--&& this.offering.includes('cfg')-->
    
      <form #ngform (ngSubmit)="onSubmit()">
       
       <div  *ngIf= "true || this.offering">
        

      <app-doc-mnt [detail] = detail [i] = i [offering] = '"tblMkr"'> </app-doc-mnt>
  
      <!--
      <app-upd-cfg-dtl [detail] = detail [i] = i>  </app-upd-cfg-dtl>
      -->
     
      </div>
              
      
    <button type="submit" class="btn btn-primary mx-3 ">Submit</button>
        
        
        <!-- check it later -->
    
        <button *ngIf='cs.enable("updQstn", "hDelete",detail)' type="submit" class="btn btn-danger" (click) = delete(detail.id)>
       Hard Delete {{detail.prntDtlId?'Customized': ""}}
    </button>
  
  
  <!-- soft delete on crdu-menu already below not implemented need .subscribe
    <button type="submit" class="btn btn-warning" (click) = detailService.changeInactive(detail.id,true)>
    Soft Delete {{detail.prntDtlId?'Customized': ""}}
   </button>
   -->
  
  
  <!-- idea is prntDtlId means this is a override rec for an master/prnt detail -->
  
  <!--    
  <br>
       <i> Note: please fillup & press upload if you have file else press submit. one is enough.
      </i>
    -->
  
      </form>
    </div>
    
  
  <div [hidden]="!submitted">
     
  <ngb-toast *ngIf="msg" header=""   (hide)="msg = ''" delay= "2000"
  [autohide]="true" 
  >
    {{ msg }}
  </ngb-toast>
  
  
  </div>
  
  
  <div *ngIf= "outQ" class= "my-3">
    
    <h4>Table looks like: </h4>

  <div class= "text-center my-3" innerHTML = {{outQ.answer}}>

  </div>

  <br>
  <br>

  code to copy is :
  <hr>

{{outQ.answer}}


</div>