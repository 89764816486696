
<blockquote class= "vis" >

    <h3 class ="text-center" style="color: Purple;background-color:lightblue;">Our Team</h3>
   
    <div class= "text-center">


<p>  Our chief mentor has 30+ years of experience of teaching and was associated with a  premier coaching Institue in Delhi. </p>


<p class= "font-italic">  Our Chief Technical Officer  has 21+ years of experience in full stack development & architecture and a digital expert with multiyears US experience  </p>


</div>

</blockquote>