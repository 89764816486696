
<!--   post qd with  qd/dgtz/{id} -->

<button *ngIf= 'enable("digitize")'  class= "btn-md btn-info badge mr-1" (click)="digitize(q)"> Digitize
  </button>

  <button *ngIf= 'enable("split")'  class= "btn-md btn-info badge mr-1" (click)="split(q)"> Split
  </button>

  <button *ngIf= 'q.format && q.format== "MCQ" && enable("removeMCQ")'  class= "btn-md btn-info badge mr-1" (click)="removeMCQ(q)">remove MCQ option
  </button>

  <app-rec-slctr *ngIf="qs.enable('mergeQstn')" [fldVal] = q.id [storeKey] = '"mergeQstnIds"' > </app-rec-slctr>

<!--post qd with   tblBldr/{id} 

<button *ngIf="qs.enable('tblBldr')"  class= "btn-md btn-info badge mr-1" (click)="showMkrI==i?showMkrI=-1:showMkrI=i;">Make Table </button>


<div class= "container-fluid" > 
  
  <app-tbl-mkr *ngIf="showMkrI==i" [id] = 'q.id' ></app-tbl-mkr>
</div>
-->
