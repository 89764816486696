import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { HelpComponent } from './help/help.component';


@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, { size: dialogSize,centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    return modalRef.result;
  }


  public help(
    title: string,
    message: string,
    //btnOkText: string = 'OK',
    //btnCancelText: string = 'Close',
    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(HelpComponent, { size: dialogSize,centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    //modalRef.componentInstance.btnOkText = btnOkText;
    //modalRef.componentInstance.btnCancelText = btnCancelText;
    return modalRef.result;
  }



  
  
}