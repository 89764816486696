import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { CodeModel } from '@ngstack/code-editor';
import { MessageService } from '../../message.service';
import { PropFromUrlService } from '../../shared/prop-from-url.service';
import { BoardDataService } from '../../board-data.service';
import { BrowserWindowService } from '../browser-window.service';
import { ClientInfoService } from '../../shared/client-info.service';


@Component({
  selector: 'app-ngs-monaco',
  templateUrl: './ngs-monaco.component.html',
  styleUrls: ['./ngs-monaco.component.scss']
})

export class NgsMonacoComponent implements OnInit {
  @Input() hdr :any; 
  @Input() dtl:any;


  @Input() fileLink:string;
  


  theme = 'vs-light';

  codeModel: CodeModel = {
    language: 'json',
    uri: 'main.json',
    value: '{}',
  };
 
  options = {
    contextmenu: true,
    theme: this.theme, 
    language: 'text/plain',
    wordWrap: 'on' ,
    minimap: {
      enabled: false,
    },
  };

  

  submitted: boolean;
  fileData: any;
  editorRef: any;
  success: boolean;
  quill: boolean;
  editorDup: any;

  onCodeChanged(value) {
    //console.log('CODE', value);
   // this.fileData= value;
  }
  
  getFileLink(){
    let fileLink= this.fileLink;

    if(!fileLink && !this.dtl && this.hdr){
      fileLink = this.hdr.extUrl;
      }
  return fileLink;
  }
  constructor(public cs:ClientInfoService, public ms: MessageService,private ds:BoardDataService, public bw:BrowserWindowService,public ps:PropFromUrlService) { }
  

  ngOnInit(): void {
    let fileLink = this.getFileLink();
    if(!fileLink){this.ms.add("No file to display","warning","warning"); return};
    this.ps.getTextFile(fileLink).subscribe(data =>{
    this.fileData = data;

    if(this.hdr.hdrFormat && this.hdr.hdrFormat=='quill'){
      this.quill= true;
    }
    });
//https://stackoverflow.com/questions/41642649/how-do-i-insert-text-into-a-monaco-editor

//https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-completion-provider-example

//https://stackoverflow.com/questions/55928045/monaco-editor-update-cursor-position-on-text-inserted

    if (this.bw.hasWindow()) {
      /*
      this.codeModel = {
      language: 'json',
      uri: 'main.json',
      //value: '{}',
      value: this.fileData,
    };
    */

  }
  
  
  }

  async onInitEditor(editor, editorId) {
    this.editorRef[editorId] = editor;
    
    }


//new 
    onInit(editor) {
      this.editorDup = editor;
      let line = editor.getPosition();
      console.log(line);
    }

    addMath(value:string){
      let editor = this.editorDup;
     // let value = '``';
      editor?.trigger('keyboard', 'type', {text: value});
      editor?.focus();
      const position: any  = editor?.getPosition();
      editor?.setPosition(position);
    }

//just text
addMath1(){
let editor = this.editorDup;
var line = editor.getPosition();
var range = new monaco.Range(line.lineNumber, 1, line.lineNumber, 1);
var id = { major: 1, minor: 1 };             
var text = "``";
var op = {identifier: id, range: range, text: text, forceMoveMarkers: true};
editor.executeEdits("my-source", [op]);
     }

    close(){
      //console.log(this.fileData);
     //this.postTextFile(this.fileData);
     this.success= true;       
    }

    
  onSubmit(){
    //console.log(this.fileData);
    this.postTextFile(this.fileData);
          
  }

  ngAfterViewChecked(){
        this.cs.typeSet('');
  }

  
  
  postTextFile(fileData: any){
    //   this.myHtml$ = this.ds.getTextFile(url);
    const formData = new FormData();
    formData.append('fileData', fileData);
  
    this.ds.postTextFile(formData).subscribe(
      data=>{
      
      if((data as any).status === 200) {
      this.ms.add("success " + (data as any).result, "toast")
      //this.success = true;
       
      }else {
        console.log(data);
      //this.success = false;
      this.ms.add("failure, try later" + (data as any).result)
      //alert("error,try later");
      }  
  });
  
};



}
