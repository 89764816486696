import { Component, Input, OnInit } from '@angular/core';
import { ChptrService } from '../../pages/chptr-lstr/chptr.service';
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { QstnhdrService } from '../qstnhdr.service';

@Component({
  selector: 'app-hdr-btn-lstr',
  templateUrl: './hdr-btn-lstr.component.html',
  styleUrls: ['./hdr-btn-lstr.component.scss']
})
export class HdrBtnLstrComponent implements OnInit {
  @Input() header:any;
  @Input() i :number;;
  
  
  constructor(private dataService: BoardDataService,
    private cs:ClientInfoService, private headerService: QstnhdrService,public chptrS:ChptrService) { }

  ngOnInit(): void {
  }

  checkMerge(hdr:any,i:number){
    this.dataService.checkMerge(hdr).subscribe(data => {
    if((data as any).status === 200) {
    let out = (data as any).result;
    let msg = (data as any).message;
    if (out && out.startsWith("ERROR")){
  //  this.cs.alert({severity:'success', summary:out,detail:(data as any).message,sticky: true})   
   this.cs.addMsg(out,'warning'); 
  }
   else{
      // this.cs.alert({severity:'error', summary:out,detail:(data as any).message,sticky: true}) 
      //this.cs.addMsg(msg,'toast'); 
      this.cs.addMsg(out); 
      //alert("Success:posting done:" + (data as any).result );
     } 
    } 
    else{
     this.cs.addMsg("Something went wrong,check console",'warning'); 
     console.log(data);
    }
  }
   );
  }
  
  enable(btnId:string,i?:number,hdrRec?:any){
    let component = 'mergeList';
    
    //console.log(hdrRec)
    return this.cs.enable(component,btnId,"",hdrRec);
    
  }
    
    makeCmnBranch(hdr:any,i:number){
    //hdr.site= "cmn";
    //hdr.school= "cmn";
    hdr.location= "cmn";
    this.headerService.update(hdr.id, this.header)
      .subscribe( error => console.log(error));
     this.cs.addMsg("Resource made common for other branches");
     }

     makeCmn(hdr:any,i:number){
      hdr.site= null;
      hdr.school= null;
      hdr.location= null;
      this.headerService.update(hdr.id, this.header)
        .subscribe( error => console.log(error));
       this.cs.addMsg("Resource made common for all sites");
       }

       
  
  processMerge(hdr:any,i:number){
    this.dataService.processMerge(hdr).subscribe(data => {
    if((data as any).status === 200) {
    let out = (data as any).result;
    let msg = (data as any).message;
    if (out && out.startsWith("ERROR")){
  //  this.cs.alert({severity:'success', summary:out,detail:(data as any).message,sticky: true})   
   this.cs.addMsg(out,'warning'); 
  }
   else{
      // this.cs.alert({severity:'error', summary:out,detail:(data as any).message,sticky: true}) 
      //this.cs.addMsg(msg,'toast'); 
      this.cs.addMsg(out); 
      //alert("Success:posting done:" + (data as any).result );
     } 
    } 
    else{
     this.cs.addMsg("Something went wrong,check console",'warning'); 
     console.log(data);
    }
  }
   );
  }
  
  
cleanupQstn(hdr:any,i:number){
  this.dataService.cleanupQstn(hdr).subscribe(data => {
    if((data as any).status === 200) {
    alert("Success:cleanup done:" + (data as any).result );
    //console.log(data);
  }else {
       alert("error,try later");
  }
  //idea is backend will take taghdrDto and create new tag hdr rec from that...
  // just change tagtype to TAhwh? (T) tag based - A(Activate) -- hwh or ?
  //addTagHeader(hdr);  extend...
});
}



mergeReport(header){
  return this.chptrS.reportName(header.extUrl,"mergeRpt"); 
  }

mergeReportDelete(header){
  let rptLink = "";
  if(header.extUrl){
  rptLink= this.cs.getRoot()+ header.extUrl.replace('.txt','-mergeRpt.html'); 
  }
  else{
   rptLink= this.cs.getRoot()+ header.id +'-mergeRpt.html'; 
  
  }
  //alert(this.rptLink);
return rptLink;  
}


}
