import { Injectable } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Injectable({
  providedIn: 'root'
})
export class QhFldService {
  

  get justOffering() {return this.cs.getJustOffering()}


  constructor(private cs:ClientInfoService) { }

  
enable(fldList: any, fld: string){
  if(!fldList) {return false;}

  if(fldList && fldList.includes('-')){
    if(fldList && fldList.includes('all')){return false}  
    return !fldList.includes(fld);
    }  


if(fldList && fldList.includes('all')){
  if(fld.startsWith(this.justOffering)){return false}
  return true}  

return fldList.includes(fld);
}


}
