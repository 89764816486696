
<!--
<div class="btn-md badge btn-primary mr-1"
(click) = "tl.showChptr(t)">Show Selected </div>
-->


<!--
        aria-pressed="true"

         <button
            (click)="showUpload=!showUpload; headerforTD= t.id;setUpload= i;"
            class="btn btn-outline-primary border-0">Upload Doc</button>
        
        <app-file-upload *ngIf="showUpload && i==setUpload" [iHeader]=header>
        </app-file-upload>
mx-2  bg-white btn-md btn-outline-primary border-0
-->   

         
            <button *ngIf='enableCstm("tagDtl",t)' class="btn btn-outline-primary border-0" 
            (click)="listTagDtl = !listTagDtl; headerforTD= t.id;setDtl= i;">Tag
            Detail</button>


        <!--  Implement & show -->


        


        <!--       
          <button *ngIf="!t.response && t.message" (click)="respond(t.id)" class="btn btn-outline-primary border-0" style="margin-left: 10px" >Respond</button>
                
                 
         <a *ngIf="cs.enable('updateQstn') && t.qstnDtlId "  href = '{{cs.getRoot()}}/faces/content-dtl-update.xhtml?qstnId={{t.qstnDtlId}}'
         class="nav-link text-warning px-3 " > <span class= "">update Question  </span>
       </a>
       -->
    



<!-- [tagHdrId]="headerforTD + ''"?????-->

<tr *ngIf="i==setDtl" class=" row col-12 float-right">
    <app-list-tagdtl *ngIf='listTagDtl' [tagHdrId]=t.id>
    </app-list-tagdtl>
</tr>