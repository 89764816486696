import { Component, OnInit, Input } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { Observable } from 'rxjs';
import { ClientInfoService } from '../../shared/client-info.service';
import { ɵHttpInterceptingHandler } from '@angular/common/http';
import { QstnListService } from '../qstn-list/qstn-list.service';
import { QstnhdrService } from '../../qstn/qstnhdr.service';
import { QstnDtlService } from '../../qstndtl/qstn-dtl.service';

@Component({
  selector: 'app-dt-selector',
  templateUrl: './dt-selector.component.html',
  styleUrls: ['./dt-selector.component.scss']
})
export class DtSelectorComponent implements OnInit {
  @Input() hdr: any;
  @Input() unitId: any;
  @Input() slctType:any;

  vids$: Observable<any>;
  utube = "https://www.youtube.com/embed/";
  showAddDtl: boolean;
  iHeader: any;
  collapseVid: boolean;
  get site() { return this.cs.getSite() };
  get cat() { return this.cs.getCat() };
  get board() { return this.cs.getBoard() };
  get course() { return this.cs.getCourse() };
  get feature() { return this.cs.getFeature() };
  get offering() { return this.cs.getOffering() };
  get subject() { return this.cs.getSubject() };
  get chapter() { return Number(this.cs.getChapter() ? this.cs.getChapter() : 0) }
  calling: boolean;
  showAddHdr: boolean;
  
  

  constructor(private ds: BoardDataService, private cs: ClientInfoService) {
    QstnDtlService.updateVidList
    .subscribe(res=>{
      //console.log(res); 
      this.fetchData();})
     }

   

fetchData(){
  
  this.vids$ = this.ds.getVidData(this.site, this.board, this.course, this.offering, this.subject, this.slctType, this.unitId);
 
}

hideVid(){
  QstnListService.emptyVid.next(true) }
  
  ngOnInit(): void {
    
    
    //this.showAddHdr = false;
    if (!this.unitId) {
      //alert("undefined val")
    } else {
      this.calling = true;
      this.fetchData()
    }
  }

  prepAddHdr(hdr: any) {
    console.log(hdr);
    this.iHeader = hdr;
    this.iHeader.type = this.slctType;
    if (hdr.headerId) {
      this.iHeader.headerId = hdr.headerId;
    } else {
      this.iHeader.headerId = hdr.id;
    }
    /*
    console.log(this.iHeader);
    console.log("***parent***");
    console.log(this.hdr);
    */
    return this.iHeader;
  }

  //index and record is optional
  enable(btnId: string, i?: number, rec?: any) {
    let component = 'vidBand';
    let enable = this.cs.enable(component, btnId);
    return this.customEnable(btnId, enable, i);

  }

  customEnable(btnId: string, enable: boolean, i?: number) {

    if (enable) { }
    return enable;
  }
}
