import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";
import { environment } from './../../../environments/environment';

import { BoardDataService } from '../../board-data.service';

import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { CommonModule } from  '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

// Customer feedback component !! not for student one...
@Component({
  selector: 'app-feedbacks',
  templateUrl: './feedbacks.component.html',
  styleUrls: ['./feedbacks.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class FeedbacksComponent implements OnInit {
@Input() cmnts:any;

//  readonly rootUrl = environment.rootUrl;
//rootUrl: string;
images: string[];
//site:string;
slides: any;
headerObj:any;
get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get feature() { return this.cs.getFeature()};
  get rootUrl() {return  this.cs.getRoot() };

  headerId:number;
  key:string;
  data$: Observable<any>;
  calling:boolean;
  constructor(config: NgbCarouselConfig, private route: ActivatedRoute,
    private router: Router, private cs:ClientInfoService, private ds: BoardDataService,private fd:FixedDataService) { 
      config.showNavigationArrows = false;
      config.showNavigationIndicators = true;
      config.interval= 2000;
      config.pauseOnHover = true;
      //config.pauseOnFocus = false;
      

    }

 
  getRouteUrl(category:string, extLink: string): string{
    return this.cs.getRouteUrl(category, extLink);
   }


  ngOnInit() {
    this.key="slides"
    if(this.feature){
     this.key = this.feature +"-" + this.key;
    } else{
      this.key = this.cat +"-" + this.key; 
    }
    this.calling = true;
    this.data$ =  this.ds.getCfgData(this.site,this.board,this.course,"cfg",this.key);
 
}
 
}
