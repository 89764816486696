import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../client-info.service';

@Component({
  selector: 'app-btn-map',
  templateUrl: './btn-map.component.html',
  styleUrls: ['./btn-map.component.scss']
})
export class BtnMapComponent implements OnInit {
@Input() section:string ;
  constructor(public cs:ClientInfoService) { }

  ngOnInit(): void {
  }

}
