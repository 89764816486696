



  <div class= "bg-light my-0" >
    
    <button *ngIf='(offering && offering.startsWith("trvsn-") )' type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" routerLink="/{{site}}/prepexp/features/{{offering}}/{{board}}/{{course}}" > Prep Express</button>
    
    <button *ngIf='(cat && cat=="crud")' type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" routerLink="/{{site}}/rfcexp/features/rfcexp/{{board}}/{{course}}" > RFC Express</button>
  
    <!--
    <button *ngIf='(offering && offering.startsWith("tasgn-"))' type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" routerLink="/{{site}}/basic/features/pbooster/{{board}}/{{course}}/{{offering}}" >Booster-View</button>
    -->  
    
      
    <button *ngIf='(cat && (cat.includes("pd") ||cat.startsWith("prd")) )' type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click)='cs.navigate("", site+"/pd/" + cat + "/slides","fullNav")' > Slides</button>
    


    <button *ngIf='status=="wip" && cat.includes("mkr")' type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'cm.selectAndAdd()'  > Select qstn and Add</button>


   

  <span *ngIf='cm.enable("solution")' >
      <button  type="button" class="mx-2 btn btn-secondary badge float-right" (click)='cm.navigate("cstore")'> Solution Center
      </button>
  </span>

    <!--
    <ng-container *ngIf='cm.enable("content")'> 
     
        <button  type="button" class="mx-2 btn btn-outline-secondary badge float-right border-0 " (click)='cm.navigate("content")'> Content Corner
        </button>
      </ng-container>
      
       -->


    
 <ng-container *ngIf='offering && offering.startsWith("tavl-")'>   
  

<button *ngIf='!chapter && cat.startsWith("mkr-")' type= "button" class="badge nav-link btn-secondary button px-1 float-right ml-3" (click) = 'cm.addNew()'  > Add New</button>



</ng-container> 



    <ng-container class="text-center my-0"  *ngIf="offering && chapter ">

    
      <button *ngIf="qs.bldrSet" (click)= 'qs.bldrSet = ""' >unselect-{{qs.bldrSet}}  </button>
       
  </ng-container>

  <!-- cm.navigate logic won't work as now we add subject-->
  <span *ngIf='false && cm.chapter && cm.fd.skipSingleListing(offering)  && cm.enable("skipSub",i,hdr)' >
    <button *ngIf= "cat && !feature" type="button" class="mx-2 btn btn-secondary badge" (click)='cm.navigate(cat, offering)'> Go back
    </button>
  </span>

  
<span *ngIf='(hdr && hdr.prntHdrId)  && cm.enable("showParent",i,hdr)' >
    <button  type="button" class="mx-2 btn btn-secondary badge" (click)='cm.navigatePrnt(hdr)'> show Parent
    </button>
  </span>


    
    <ng-container *ngIf= 'cat=="engage"'>
      <span *ngIf='cm.enable("showMtg")' >
        <button type="button" class="mx-2 btn btn-outline-secondary badge " (click)='cm.navigate("cmnt","Hmtg/meeting")'> Scheduled Meeting  
        </button>
      </span>
    </ng-container>

  

    <!--
  <button  class= "mx-2 float-left badge" *ngIf= "cm.offering && cm.enable('cntxtExpand')" (click) ='maintenance = !maintenance'> {{maintenance?'-':'+'}} maintain </button>
  -->

  
<button *ngIf="cm.enable('rExpand')" type= "button" class="badge bg-dark nav-link button px-1 float-right ml-3" (click) = "cntntMnu=!cntntMnu"> {{cntntMnu?'-':'+'}} All-Contents </button>

<!--
<button *ngIf="chapter && cm.enable('showAsoRec')" type="button" class="float-right btn badge btn-outline-primary border-0 " (click)="showAsoRec = !showAsoRec"
[attr.aria-expanded]="showAsoRec" aria-controls="collapseAsoRec">
Associated contents
</button>
-->

  <ng-container *ngIf= 'groupId && cm.enable("maintenance",i,hdr)'>
    
    
  <span *ngIf='false && groupId && cm.enable("cfgSetup",i,hdr)' >
    <button *ngIf= "cat && !feature" type="button" class="mx-2 px-2 btn btn-outline-primary badge border-0" (click)='cm.navigate("admin", "cfg/" + cat)'> Config
    </button>
  </span>
  
       
    <!--
    <button *ngIf="cm.offering && !cm.chapter && (cm.enable('showQH'))"  (click)="showQHdr= !showQHdr" class="btn btn-outline-primary border-0" style="margin-left: 10px"> {{showQHdr?'Hide Add':'Add ' + getHdrName()}} <i class="fa fa-book" aria-hidden="true"></i> </button>

    <button *ngIf="(cm.offering && !cm.chapter && (cm.enable('showTH')))"  (click)="showTHdr= !showTHdr" class="btn btn-outline-primary border-0" style="margin-left: 10px"> {{showTHdr?'Hide Add':'Add t ' + getHdrName()}} <i class="fa fa-book" aria-hidden="true"></i> </button>

    
     
<button *ngIf= 'cm.offering && cm.enable("showTHL")' type="button" class="btn badge btn-outline-primary" (click)="showTHL = !showTHL">Tag Hdr List</button>


<button *ngIf="cm.chapter && cm.enable('showTDL')" type="button" class="btn badge btn-outline-primary border-0 " (click)="showTDL = !showTDL"
  [attr.aria-expanded]="showTDL" aria-controls="collapseTD">
 Toggle Tag Detail
</button>



<button *ngIf="cm.offering && cm.enable('showQHL')" type="button" class="badge btn btn-outline-primary" (click)="showQHL = !showQHL"
  [attr.aria-expanded]="showQHL" aria-controls="collapseQH">
 Toggle Qstn Hdr
</button>

 
<button *ngIf="cm.chapter && cm.enable('showQDL',hdr)" type="button" class="btn btn-outline-primary border-0" (click)="showQDL = !showQDL"
  [attr.aria-expanded]="showQDL" aria-controls="collapseQDL">
 dig answer updater
</button>

-->

<button *ngIf="false && cm.enable('showCmnt')" type="button" class="btn btn-outline-primary" (click)="showCmnt = !showCmnt"
  [attr.aria-expanded]="showCmnt" aria-controls="collapseQDL">
 Toggle Meeting Detail
</button>


    <ng-container *ngIf= "chapter"> 
    
    
    <app-btn-enabler-tester *ngIf= 'false && hdr' [hdr] = 'hdr'> </app-btn-enabler-tester>
    
    <!--
     <button *ngIf="false && cm.enable('addQDtl',hdr)"  (click)="showQDtl= !showQDtl" class="btn btn-outline-primary border-0 ml-2" style="margin-left: 10px"> {{showQDtl?'Hide Add':'Add '+ getQstnName()}} <i class="fa fa-book" aria-hidden="true"></i> </button>
   
     <button *ngIf='cm.enable("additionalInfo")' type= "button" class="btn btn-outline-primary border-0 ml-2" (click) = 'cm.navigateCmnt("Hinfo")' > Additional Info</button>
     
     
     
     <button *ngIf='cm.enable("digiError")'  type= "button" class="btn btn-outline-primary border-0 ml-2" (click) = 'cm.navigateCmnt("DdigErr")' > Digitization Errors</button>
    -->
  </ng-container>


<ng-container *ngIf= "offering">
<span *ngIf='cm.enable("bwc-cstm")' >
  <button type="button" class="btn nav-link btn-outline-primary border-0" (click)='cm.buildWithClick()'> build with click {{cm.getDgtzrName()}}
  </button>
</span>

<!--   
<span *ngIf='cm.enable("anb-cstm")' >
  <button type="button" class="btn btn-outline-primary border-0" (click)='cm.buildWithClick()'> Associate & build {{cm.getDgtzrName()}}
  </button>
</span>
-->

<!--Digitize with Upload -->
 <span *ngIf='cm.enable("dwu-cstm")' >
   <button type="button" class="btn btn-outline-primary border-0" (click)='cm.buildWithDdgtzr()'>
    Upload {{cm.getDgtzrName()}}
   </button>
 </span>

 <app-km-menu *ngIf='cm.enable("myMaker")' >  </app-km-menu>
    
  
<!--
   <app-mass-bldr *ngIf='true || subject && !chapter && cm.enable("massBldr")' class= "float-right" >  </app-mass-bldr>
 -->   

</ng-container>


  </ng-container>
 

  <ng-container *ngIf= "cntntMnu" class="bg-dark float-right "> 
  
    <a class="col-2" *ngIf="chapter"  routerLink="/{{site}}/{{cat}}/{{board}}/{{course}}/{{'type'}}/{{onlySubject}}" class="badge nav-link button px-1 float-right" > <span class= "" innerHTML="{{'Show All source'}}"> </span></a>
  

    <a class="col-2" *ngIf="chapter"  routerLink="/{{site}}/{{cat}}/{{board}}/{{course}}/{{'pyq'}}/{{onlySubject}}" class="badge nav-link button px-1 float-right" > <span class= "" innerHTML="{{'Prvs Yr Paper'}}"> </span></a>
   
    <a class="col-2" *ngIf="chapter"  routerLink="/{{site}}/{{cat}}/{{board}}/{{course}}/{{'hwh'}}/{{onlySubject}}" class="badge nav-link button px-1 float-right" > <span class= "" innerHTML="{{'Txt Book'}}"> </span></a>
   
    <a class="col-2" *ngIf="chapter"  routerLink="/{{site}}/{{cat}}/{{board}}/{{course}}/{{'rbs'}}/{{onlySubject}}" class="badge nav-link button px-1 float-right" > <span class= "" innerHTML="{{'Reference Book'}}"> </span></a>
   
  </ng-container>


    
<!--skip associator and create directly -->
 <span *ngIf='cat.includes("-bldr") && cm.enable("skipBldr")  && !chapter' >
   <button type="badge button btn-secondary" class="btn btn-outline-primary border-0" (click)='cm.skipBldr()()'>
    Skip Associator
   </button>
 </span>

 <!--
  <app-group-addon *ngIf='hdr&& chapter && cm.enable("groupMenu",i,hdr) ' class= "ml-2 align-center"  [hdr] =hdr> </app-group-addon>
  
  -->
 
    
   

</div>

 
 

 
<div id="collapseQH" *ngIf="showQHL"   [ngbCollapse]="!showQHL">
  <div class="card">
   <div class="card-body">
<app-qstnhdr-list class= "" *ngIf= 'cm.enable("CRUD")' > </app-qstnhdr-list>

</div>
  </div>
  </div>

  


<!--
<div id="collapseTD" *ngIf="showTDL"   [ngbCollapse]="!showTDL">
  <div class="card">
   <div class="card-body">
<app-list-tagdtl [tagHdrId]= cm.chapter [tagDtlId] = ''> </app-list-tagdtl>
   </div>
  </div>
  </div>
-->
  

<!--[iHeader] = header-->
  

 <div *ngIf= 'showTHL' > 
  
 <app-taghdr-list  *ngIf= '!cm.offering.includes("wrk")' [tagType] = cm.offering > tag CRUD  </app-taghdr-list>
  
 <!-- note [headerById]= headerById needs to be given below-->
<app-taghdr-list *ngIf= 'cm.offering.includes("wrk")'  [tagType]= "'twrk-dhw'"  [owner]= signedUser>  </app-taghdr-list>
  </div>


  

<div id="collapseQDL" *ngIf="showQDL"   [ngbCollapse]="!showQDL">
  <div class="card">
   <div class="card-body">
    <app-qstndtl-list [qstnHdrId]= cm.chapter > </app-qstndtl-list>
   </div>
  </div>
  </div>

  <!--don't call if chapter is 0 or absent
  <div id="collapseAsoRec" *ngIf="showAsoRec"   [ngbCollapse]="!showAsoRec">
    <div class="card">
     <div class="card-body">
      <app-associated-rec [grpAsoId]= getgrpAsoId() > Associated Records...</app-associated-rec>
     </div>
    </div>
    </div>
-->

    <div id="collapseHinfo" *ngIf="showHinfo"   [ngbCollapse]="!showHinfo">
      <div class="card">
       <div class="card-body">
        <app-show-info [hdr] = hdr> </app-show-info>
       </div>
      </div>
      </div>

    
   
<!-- enable it if needed -->
<div id="collapseCmnt" *ngIf="showCmnt"   [ngbCollapse]="!showCmnt">
  <div class="card">
   <div class="card-body">
     
    <app-taghdr-list  [tagType] = '"mtg"' > tag CRUD  </app-taghdr-list>

    <app-cmnt-list [cmntType]= '"Hmtg"' [featureType] = "'mtg'"  [parentHdrId] = cm.chapter > </app-cmnt-list>
   </div>
  </div>
  </div>


  <app-qstnhdr-add  *ngIf="showQHdr" [ovrHdrFormat] = 'ovrHdrFormat' > </app-qstnhdr-add>

  
  <app-add-header  *ngIf="showTHdr"  > </app-add-header>



<div id="collapseQDtl" *ngIf="showQDtl"   [ngbCollapse]="!showQDtl">
  <div class="card">
   <div class="card-body">

  <app-add-qstn-dtl *ngIf="showQDtl" ></app-add-qstn-dtl>
  

  </div>
</div>
</div>

