import { Injectable } from '@angular/core';
import { ClientInfoService } from '../client-info.service';
import { FixedDataService } from '../fixed-data.service';
import { HeaderService } from '../../tag/header.service';
import { ExtraInfo } from './extra-info';
import { Header } from '../../tag/header';

@Injectable({
  providedIn: 'root'
})
export class PrepAsgnrService {
    
 
  constructor(public hs: HeaderService,private cs: ClientInfoService,private fd: FixedDataService) { }

prePrepEI(hdr:any, status:string){
  
  let ei: ExtraInfo = new ExtraInfo();
  let today1 = new Date();
  
  //very important the below field is used by student to get ownership of test record.. uh even domain is weaved in...
  ei.id= hdr.id;
  ei.domain = this.cs.getDomain();
  if(!this.cs.getSignedUser() || this.cs.getSignedUser()=="Guest"){
  ei.addUser = "Alien";
  ei.clsSec= " ";
  } else{
  ei.addUser = this.cs.getSignedUser();
  ei.clsSec= this.cs.getuserClsSec();
  }
  
    
  
  ei.status= status;
  //ei.owner= "section";
  if(hdr.feature){
  ei.dueDate = new Date();
  ei.dueDate.setDate(ei.dueDate.getDate() + this.fd.getDueDays(hdr.feature));
   }
  
   if(this.cs.getGroupId() == "learner" || ei.status == "wrk")
   {
   if(!this.cs.getSignedUser() || this.cs.getSignedUser()=="Guest"){
      ei.owner = "Alien";
   } else{
   ei.owner = this.cs.getSignedUser();
   }
  this.cs.log("prep-adgnr changed owner","prep-asgnr");
  }
  

 if(hdr && hdr.extra){
  let extraJson = JSON.parse(hdr.extra);
  if(extraJson && extraJson.testTime){
    ei.testTime = extraJson.testTime;
   }  
  }
  //console.log(ei);
  return ei;
 }


 postEI(hdr:Header,ei:ExtraInfo){
 //let newOffering = this.cs.getOffering().replace(this.cs.getStatus(), status);
  //console.log("offering sent is: " + newOffering );
 return this.hs.changeStatus(hdr.id,hdr.type,ei.status,ei);
}

autoPost(hdr:Header,status:string,){
let ei:ExtraInfo= this.prePrepEI(hdr,status); 
return this.postEI(hdr,ei);
}

}
