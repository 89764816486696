import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BoardDataService } from '../../board-data.service';
import { HttpHlprService } from '../../shared/http-hlpr.service';

@Component({
  selector: 'app-progress-lstr',
  templateUrl: './progress-lstr.component.html',
  styleUrls: ['./progress-lstr.component.scss']
})
export class ProgressLstrComponent implements OnInit {

  hdrGrps$: Observable<any>;
  unitHdrType:string= "hwh";

  constructor(private h:HttpHlprService,  private bds: BoardDataService) { }

  ngOnInit(): void {
  if(this.h.justSite == 'csa'){
    this.unitHdrType= "unit";
  }  


   //this.h.ty
    this.hdrGrps$= this.bds.getSiteHeaders(this.h.si,this.h.bo,this.h.co,this.unitHdrType,this.h.su);
  }

}
