

<ng-container *ngIf='q.optionList;'>
    <p class="card-text" [innerHTML]=q.digInstrctn> </p>
    <p class="card-text" [innerHTML]=q.digQstn> </p>
    <div  *ngIf= 'q.contentImages'  class= "text-center"> 
      <img  [src] = "q.contentImages|safe:'resourceUrl'" height= "200px" alt= "">
  </div>

  <ul *ngFor="let o of q.optionList;let j = index"
  class="list-group list-group-flush">

  <p *ngIf= 'o' class= "text-secondary" >
    <input type="radio" [value]= ts.getDigOptn(o,j+1) name="digAnsr" > &nbsp; <span innerHTML= {{o}}> </span>
    </p>
 
  <!-- {{j+1}}-  (ngModel)]="q.ansCmnts[0].digAnsr"
  <button type="button" class="btn btn-outline-primary"
    innerHTML={{o}} (click)="setDigAnsr(j)">
  </button>
   --> 
</ul>

  </ng-container>


 