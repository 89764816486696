
  import { Component, OnInit, Input } from '@angular/core';
import { Header } from '../header';
import { HeaderService } from '../header.service';
import { Router } from '@angular/router';
import { ClientInfoService } from '../../shared/client-info.service';
import { ExtraInfo } from '../../shared/assigner/extra-info';

@Component({
  selector: 'app-add-header',
  templateUrl: './add-header.component.html',
  styleUrls: ['./add-header.component.scss']
})
export class AddHeaderComponent implements OnInit {

  @Input() iHeader :Header;
  ei: ExtraInfo;
expand:boolean;
  public header: Header = null;
  submitted = false;
  constructor(private cs:ClientInfoService, private headerService: HeaderService,
    private router: Router) { }

  ngOnInit() {
    this.createHeader();
    this.ei= new ExtraInfo();
  }

  createHeader(): void {
    this.submitted = false;
 //console.log(this.iHeader);  test reset in casual time
    if(this.iHeader){
     //  this.header = Object.assign({}, this.iHeader);
      this.header = this.iHeader;
      this.header.summary= null;
      //console.log(this.header);    
    } else{

     //this.header = new Header();
    this.header= this.headerService.hdrWithDefault();
    }
    //this.header = new Header();
    
  }

  save() {
    
    if(this.ei){
      this.header.extra= JSON.stringify(this.ei);
     }
        
    this.headerService.create(this.header)
      .subscribe(data => 
        {
          console.log(data);
          this.header = new Header();
          if((data as any).status== 200){
            let id= (data as any).result.id;
            HeaderService.updateDhwHdrId.next(id);
          }
          this.gotoList();
                 
        }, error => console.log(error));
     }

  onSubmit() {
    this.submitted = true;
    this.save();    
  }


  gotoList() {
   this.cs.addMsg("header added",'toast');
   HeaderService.updateList.next(true);
   
   // this.router.navigate(['//eba//digibest//features//headers']);
  }
}
