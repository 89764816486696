import { Injectable } from '@angular/core';
import { Header } from '../qstn/header';
import { Detail } from '../qstndtl/detail';
import { ClientInfoService } from '../shared/client-info.service';
import { SecurityService } from '../shared/security.service';

@Injectable({
  providedIn: 'root'
})
export class BtnService {
//please develop this for crud-btn management 
  
  
  constructor(private ss: SecurityService, private cs: ClientInfoService) {
  }

  //not in list, don't allow to edit even own record like student-answer script
  excludedOwner = ['learner', 'Guest', 'UNSIGNED'];
  
  show(component: string, btnId: string, h: Header,) {
    if (!h) {
      console.log("header not passed in btn service for update check");
    }

    if (h) {
      if (this.grpAuthFast(btnId, h)) { return true }
    }

    //console.log( "data school : = " + h.school  );
    //console.log( "my school : = " + this.ss.getSchool()  );


    //allow-cross-school only to app_admin or has allow other permission-"ao_mngr"
    if (h && (!h.school || (h.school != this.ss.getSchool()))) {
      let ug = this.ss.getUserGroup();

      //db coe folks should be allowed to change coe data
      let hdrDtlBtns = ['updateHdr','update', 'updateDtl', 'sdelete', 'crudQstnDtl'];
      if ( hdrDtlBtns.includes(btnId)  && ug && ug.includes("eb_coe")) {
        if (!h.school || (h.school == "cmn")) {
          // this.cs.log( "ebcoe recs enabled for udate for usergroup: = " + this.ss.getUserGroup() );
          return true;
        }
      }


      //(btnId=="update" ||btnId=="sdelete")
    //  console.log("user group : = " + this.ss.getUserGroup());
      let GSBtns = ['updateHdr', 'updateDtl', 'sdelete', 'up--date'];
      if (GSBtns.includes(btnId) && ug && (
        ug.includes("ao_mngr"))) {

       // console.log("user group true : = " + this.ss.getUserGroup());
        return true;
      }
      else {
        //curriculum team will be allowed access to  full site's content..
       //ug.includes("crclm_mngr") || ug.includes("digi_expert")||
        if((ug.includes("site_manager")) && h.site== this.ss.getUsrSite()){
         // alert('yes allow update -true');
          return true;
        }
        if(btnId== "updateDtl"){
          
          //alert('yes update -false');
        }
        return false;
      }

    } else {

      if (this.excludedOwner.includes(this.ss.getGroupId())) {
        return false;
      }

      return true;
    }

  }


  grpAuthFast(btnId: string, hdrRec: any) {
    // across the component button
    let crudBtns = ['updateQstnGlobal', 'updateGlobal', 'updateLocal', 'updateHdr', 'updateDtl', 'updateTagGS', 'updateHeader','deleteHdr', 'update', 'sdelete', 'changeStatus', 'allowEdit', 'addQDtl', 'crudBtns-cstm'];
    // crud should be allowed for owned record..

    if (crudBtns.includes(btnId)) {
      let usrGrp = this.ss.getUserGroup();
      if (hdrRec && hdrRec.shrGrp && usrGrp) {
        if (hdrRec.shrGrp == 'public' || usrGrp.includes(hdrRec.shrGrp)) return true;
      }
    }

  }



  showQstn(component: string, q: Detail) {

    if (q.groupId == "together") {
      //console.log(q)
    }

    if (q.partId && !this.cs.authorized(component, q.groupId, q.partId)) {
      return false;
    }


    if (q.status && q.status == 'sign') {
      return !this.ss.isUnsigned()
    }
    if (q.status && q.status == 'hide') {
      return false;
    }

    let show = true;

    if (q.groupId && (q.groupId == "ftrDesc" || q.groupId == "template")) { return false; }

    switch (component) {

      case "slides":
      case "slide":
        show = show && (!q.format || q.format != "introOnly");
        break;

      case "intro":
        show = show && (q.format && q.format == "introOnly");
        break;


      default:
        show = show && (!q.format || !q.format.includes("Only"));
    }

    return show;

  }
  enable(component:string,btnId:string,dtl?:any,hdr?:any ){

   this.cs.enable(component,btnId,dtl,hdr);

  }


}
