
<ng-container  > 
 
  <div> 

    <span (click)="help('disabled')" class= "badge  text-info my-2"> {{s.getTitle(header)}}  -- by {{header.owner}}    
    </span> 
    
  <span *ngIf='s.enable("status")' class= "mx-3 text-muted font-italic small">coming soon - {{header.status}}</span>
  
</div>

</ng-container>
