import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-divider',
  templateUrl: './section-divider.component.html',
  styleUrls: ['./section-divider.component.scss']
})
export class SectionDividerComponent implements OnInit {
@Input() h:any;
@Input() titleFlag: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
