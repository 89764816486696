import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform1(list: any[], prop: string, filterText: string , method:Method): any {
      return list ? list.filter(item => item.name.search(new RegExp(filterText, 'i')) > -1) : [];
    }


  transform(arr: any[], prop: string, value: string , method:Method): any {
//console.log("search arr input:"+ prop + value + method);
      if (arr) {
        if (!value) {
          return arr
        } else {
          //console.log("search Filter");
          var source;

          if(prop==='*'){
            return arr.filter(obj => this.filter(JSON.stringify(obj), value, method))
          }  else{
          return arr.filter(obj => this.filter(obj[prop],value, method))
        }

        }
      } else {
        return []
      }
    }

    filter(source :string, target :string, method:Method) : boolean {
  //console.log(source + " / "+ target + " / "+ method);
    switch(method) {
      case "includes" :
    //  console.log("yes/no: " +  source.includes(target));
      //return source.includes(target) making case insensitive
      return source.search(new RegExp(target, 'i')) > -1;

      case "equal"  : return source === target
      case "not-equal" : return source !== target
      default:
      if (source){
      //return source.includes(target)
      return source.search(new RegExp(target, 'i')) > -1;
    }else{
      return false;
    }
    }
  }
}

type Method ="includes" | "equal" | "not-equal"
