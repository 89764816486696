<!--
<ngx-extended-pdf-viewer  [src]= "'assets/abc.pdf'" 
  [height]="'90vh'"></ngx-extended-pdf-viewer>

"'http://localhost/etl/file/esa-cbse-xi/physics-rbs-rh/HallidayResnickWalker-Physics.pdf'"
-->

  <br>
<!-- [height]="'100vh'"  [pageLabel] = "15" [contextMenuAllowed]="false"
   [pageViewMode] = "single" -->
   
  <ngx-extended-pdf-viewer  [src]= getFullUrl() useBrowserLocale="true" class="text-center"
    [showDownloadButton] = "false" [showOpenFileButton] = "false" [showPrintButton] = "false" [backgroundColor]= "'navi'" [page] = startPage  textLayer= "true"  
  [height]="'90vh'"  [pageViewMode] = '"single"' 
  ></ngx-extended-pdf-viewer>
