import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-vid-player',
  templateUrl: './vid-player.component.html',
  styleUrls: ['./vid-player.component.scss']
})
export class VidPlayerComponent implements OnInit {
@Input() vidUrl:any;

@ViewChild('videoPlayer') videoplayer: any;

  video() {
    console.log('im Play!');
    this.videoplayer?.Play();
  }
  constructor() { 
    
  }

  ngOnInit(): void {
  }
  
 

}
