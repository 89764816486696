import { Component, Input, OnInit } from '@angular/core';
import { Header } from '../../qstn/header';
import { MathService } from '../../math/math.service';
import { QstnListService } from '../qstn-list/qstn-list.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { Detail } from '../../qstndtl/detail';
declare var MathJax: any;
@Component({
  selector: 'app-qstn-page',
  templateUrl: './qstn-page.component.html',
  styleUrls: ['./qstn-page.component.scss']
})
export class QstnPageComponent implements OnInit {
  @Input() list: any;
  @Input() h: Header;
  @Input() resetPage:boolean;

  previousPage: number;
  slicedList: any;
  dgtlView: boolean;
  prvsUrlPage: number;

  //get page() { return this.qs.page}
  page: number;
  get pageSize() { return this.qs.pageSize }
  showQstnStts: boolean;
  showA:boolean;




  constructor(public qs: QstnListService, private ms: MathService, config: NgbPaginationConfig) {
    // customize default values of paginations used by this component tree
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  ngOnInit(): void {
    
    //important for resetting to first page when nth page view-qstn link is pressed
    if(this.resetPage){
     this.qs.page= 1;
  
    } 
    else{
    if (this.qs.cs.getPage()) {
      this.qs.page = this.qs.cs.getPage();
    }

  }
    // note- for view qstn screen - show answer by default
    if (this.qs.cs.qstnId) {
      this.showA = true;
    }


    if (this.list) {
      // let local list prevail for unsigned user??
      let key = 'qstns-' + this.qs.cs.getChapter();
      let val = localStorage.getItem(key);
      let localList = JSON.parse(localStorage.getItem(key));
      if (localList) {
        this.qs.originalList = localList;
        this.qs.filteredList = localList;
        console.log(localList);
      }
      else {
        this.qs.originalList = this.list;
        this.qs.filteredList = this.list;
      }

    }

  }


  viewType(h: Header, d: Detail): string {
    let viewType = "qstn";
    if (this.qs.cs.enable("qstn-page", "dgtlView", null, this.h)) {
      viewType = "dgtl";
      return viewType;
    };

    if (this.qs.cat == "vid" || (d.vidLink && (d.format && d.format == "vid")) || ( h?.type && h.type?.includes("vid")) ) { viewType = "video" };

    if (this.qs.cat == "hwrk"|| this.qs.cat == "gpb"){
      viewType= this.qs.cat;
    }
    return viewType;

  }
  

  enable(btnId: string, q?: any) {
    if (q && q.status && q.status == 'hide') { return false };
    return true;

    //enable later
    //return this.qs.cs.enable('qstnPage',btnId,q);
  }


  typeSet() {
    //alert("Hello World");
    // MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
    // alert("View typeset Retried! Thanks from tyepset ");

  }

  //main method responsible for first load..called after each qtn hence fast i guess
  // on reload called just once hence full process take time??
  ngAfterViewChecked() {
    this.qs.typeSet('');
    //alert("Hello World");


    // MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
    //MathJax.typesetPromise();
    //alert("View typeset Retried- after view checked..! Thanks ");

  }



  nextPageUrl() {
    let localPage = this.qs.cs.getPage();
    if (!localPage) { localPage = 1 }
    let pc = "page/" + this.qs.cs.getPage();
    let nextPage = +this.qs.page + 1;
    let rpc = "page/" + nextPage;
    let url = this.qs.cs.router.url;
    if (url.includes(pc)) {
      url = url.replace(pc, rpc);
    } else {
      if (url.includes("/page")) {
        url = url.replace("/" + this.qs.cs.getPage(), "/" + this.qs.page);
      } else {
        url = url + "/" + rpc;
      }
    }

    return url;
  }


  loadPage(page: number) {
    if (this.qs.cs.bw.hasWindow()) {
      window.scrollTo(10, 10);
      if (page !== this.previousPage) {
        this.page = this.qs.page;
        this.previousPage = page;
        this.loadPageData(this.page, this.pageSize);
      }
    }
  }

  //this.page - 1, this.pageSize,
  loadPageData(page: number, pageSize: number) {
    //this.collectionSize= (this.questions as any).dataList.length;

    var begin = (page - 1) * pageSize;
    var size = (page - 1) * pageSize + pageSize;
    // this.slicedList = this.list.dataList.slice(begin,size) ;
    // to be implemented as list is not in asynch component now
  }


  valueChange(value) {
    if (value > 0) {
      this.qs.pageSize = value;
    }
  }


  enableNextPageSEO() {
    if (this.qs.cs.tagBasedForCreate()) { return false }
    //console.log(this.page +"--" + this.qs.filteredList.length + "--" +this.qs.pageSize + this.qs.cs.tagBasedForCreate())

    return (!this.page && this.qs.filteredList.length > this.qs.pageSize) || (this.page < this.qs.filteredList.length / this.qs.pageSize)
  }


}
