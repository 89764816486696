import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentListComponent } from './comment-list/comment-list.component';
import { CommentDetailComponent } from './comment-detail/comment-detail.component';
import { AddCommentComponent } from './add-comment/add-comment.component';
import { UpdateCommentComponent } from './update-comment/update-comment.component';
import { FormsModule } from '@angular/forms';
import { CommentService } from './comment.service';
import { CKEditorModule } from 'ckeditor4-angular';

//import { Comment } from './comment';

import { SharedModule } from '../shared/shared.module';
import { CrudComponent } from './crud/crud.component';
import { TagModule } from '../tag/tag.module';
import { PagesModule } from '../pages/pages.module';
import { ReactionComponent } from './reaction/reaction.component';
import { InfoShowerComponent } from './info-shower/info-shower.component';
import { CmntListComponent } from './cmnt-list/cmnt-list.component';
import { CmntViewComponent } from './cmnt-view/cmnt-view.component';
import { CmntCrudComponent } from './cmnt-crud/cmnt-crud.component';
import { CmntTitleComponent } from './cmnt-title/cmnt-title.component';
import { CmntCrctnComponent } from './cmnt-crctn/cmnt-crctn.component';

import { CmntRspnseComponent } from './cmnt-rspnse/cmnt-rspnse.component';
import { FeedbacksComponent } from './feedbacks/feedbacks.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { AddQstnCmntComponent } from './add-qstn-cmnt/add-qstn-cmnt.component';
import { CrctnRcmndComponent } from './crctn-rcmnd/crctn-rcmnd.component';
import { OptionalModule } from '../optional/optional.module';
import { CrctnRcmndAddComponent } from './crctn-rcmnd-add/crctn-rcmnd-add.component';
import { ReplyComponent } from './reply/reply.component';
import { CmntTableComponent } from './cmnt-table/cmnt-table.component';
import { InfoCenterComponent } from './info-center/info-center.component';
import { CommentsComponent } from './comments/comments.component';
@NgModule({
  declarations: [CommentListComponent, CommentDetailComponent, AddCommentComponent, UpdateCommentComponent, CrudComponent, ReactionComponent, InfoShowerComponent, CmntListComponent,  CmntViewComponent, CmntCrudComponent, CmntTitleComponent, CmntCrctnComponent,  CmntRspnseComponent, FeedbacksComponent, TestimonialsComponent, AddQstnCmntComponent, CrctnRcmndComponent, CrctnRcmndAddComponent, ReplyComponent, CmntTableComponent, InfoCenterComponent,CommentsComponent ],
  imports: [
    CommonModule,
    FormsModule,
    TagModule,
    OptionalModule,
 SharedModule,
    CKEditorModule
  ],
  exports:[CommentListComponent,
    AddCommentComponent,
    CommentDetailComponent,
    UpdateCommentComponent,
    AddCommentComponent,
    CrudComponent,
    ReactionComponent,
    CmntListComponent,
    TestimonialsComponent,
    AddQstnCmntComponent,
    CrctnRcmndComponent,
    CmntViewComponent,
    CmntRspnseComponent,
    CmntCrudComponent,
    CommentsComponent
    
  ],
  providers: [CommentService],

})
export class CommentsModule {
  
 }
