import { Component, OnInit, Input } from '@angular/core';
import { NgModule, ElementRef, Injectable } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { ReactiveFormsModule, FormControl, FormsModule } from "@angular/forms";
import { BoardDataService } from '../../board-data.service';
import { Board } from '../../board';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';

import {
  HttpClientJsonpModule,
  HttpClientModule,
  HttpClient
} from "@angular/common/http";
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { identifierModuleUrl } from '@angular/compiler';
import { SecurityService } from '../../shared/security.service';

@Component({
  selector: 'app-boardlist',
  templateUrl: './boardlist.component.html',
  styleUrls: ['./boardlist.component.scss']
})
export class BoardlistComponent implements OnInit {
  @Input() listType: string;
  @Input() offering2: string;
  @Input() boards: Board[];

  boardDetails: any;
  questions: any;
  headerId: number;
  headerObj: any;

  //site:string;
  //cat:string;
  cat1: string;
  board: string;
  course: string;
  feature: string;
  offering: string;
  subject: string;
  staticFlag: boolean;
  signedUser: string;
  userGroup: string;
  dfltCat: string = "learner";
  get site() { return this.cs.getSite() };
  get cat() { return this.cs.getCat() };
  get unsigned() { return this.cs.isUnsigned() };
  get groupId() { return this.cs.getGroupId() };
  get showAll() {return  this.cs.enable("boardList", "bypass") } 
  get betaViewer() { return this.cs.betaViewer()}
  
  constructor(public cs: ClientInfoService, private route: ActivatedRoute, private router: Router,
    private elementRef: ElementRef, private dataService: BoardDataService, private fd: FixedDataService) {
  }


  ownerGroup(part: string) {
    //this.userGroup= "[OWNER]";
    //alert(this.userGroup);
    if (this.userGroup && this.userGroup.includes(part)) {
      return true;
    }
    else {
      return false;
    }
  }



  staticData() {
    //turnong off static data list for board
    //cs.getList is not in use..

    if (!this.cs.getRoot().includes('localhost')) {
      this.dataService.getList(this.cs.getSite()).subscribe(data => {
        this.boardDetails = data;
        this.questions = data;
        if (this.questions.length === 0) {
          //console.log("could not get"+  + this.questions);
          this.questions = this.fd.getList(this.cs.getJustSite());
        }
        //console.log('OK from hardcoded value fd.getList' + JSON.stringify(this.questions));
      });

    } else {
      this.questions = this.fd.getList(this.cs.getJustSite());
    }

  }

  ngOnInit() {

    this.signedUser = this.cs.getSignedUser();


    this.staticFlag = false;
    if (this.staticFlag) {
      this.staticData();
    }
    else {

      //important- examList is setup for cfg-home to show in esa home..
      // later change it to fit the need
      //home setup makes sure exams doesn't have submenu. and home has the same.
      //if you need data just change exams to home for now or change subject to 'home' in cfg record..
      //later change it...

      this.dataService.getSiteHeaders(this.cs.getSite(), this.cs.getBoard(), this.cs.getCourse(), 'cfg', this.listType).subscribe(data => {
        this.headerObj = (data as any).dataList[0];
        if (this.headerObj) {
          this.headerId = (data as any).dataList[0].id;
          this.dataService.getSiteQuestions(this.cs.getSite(), this.cs.getBoard(), this.cs.getCourse(), this.headerObj.type, 'subj', this.headerId).subscribe(data => {
            this.questions = data;
          });

        }
      });



      /*
      this.headerId= 727;
      this.offering = 'pyq' //hardcoded as pbstr will go to tag file which has no board list!
      
      this.dataService.getSiteHeaderById(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.offering,this.cs.getSubject(),this.headerId).subscribe(data => {
           this.header= data;
          });
      
      
      if(!this.questions){
      this.dataService.getSiteQuestions(this.cs.getSite(),this.cs.getBoard(),this.cs.getCourse(),this.offering,'subj',727).subscribe(dataList => {
                this.boardDetails = dataList;
                this.questions=dataList;
              });
        //console.log(this.questions);
      }
      */

    }

    if (this.cs.boardMust() && this.cs.getCat() !== "home") {
      alert("Please select Exam/Board&Class");
    }
  }

  getTitle(qstn: any) {

    let str = qstn.title;
    if (!(qstn.sctnPart == 'null' || qstn.sctnPart == 'cmn')) {
      str = [str, qstn.sctnPart].filter(Boolean).join("-");
    }
    if(qstn.status && qstn.status == 'beta'){
      str = str + " <sup>" + qstn.status +  "</sup>";
    }
    return str;
  }



  selectExam(q: any) {
    let ovrCat = q.category;

    if (this.groupId != "UNSIGNED") {
      ovrCat = this.cs.ss.getUserMenu()
    }

    //note q.qstnId/q.sequence = board based on data source
    //q.subSeq,q.subqstnId = course
    let extLink = [q.qstnId, q.sequence, q.subSeq, q.subqstnId, q.extLink].filter(Boolean).join("/")
    return this.cs.getRouteUrl(ovrCat, extLink, "skipBoard");
  }


  subscribed(q: any) {
    if (q?.status=='beta'  && !this.betaViewer) { return false }

    if (this.cs.enable("boardList", "bypass")) { return true }
    else {
      //q.sequence,q.subSeq
      if ((this.cs.ss.getBoard() == q.sequence || q.sequence == "cmn")
        && (this.cs.ss.getCourse() == q.subSeq)
        || q.subSeq == "cmn") { return true }
      else {
        return false;
      }
    }
  }


  selectExamOld(q: any) {
    if (this.cs.isUnsigned()) {
      //this.cs.addMsg(q.extLink + " selected" , "toast");  
    } else {
      // this.cs.addMsg(q.extLink + " selected,happy browsing", "toast" );  
    }

    let category = q.category ? q.category : this.dfltCat;

    //temp- remove later  
    let extLink = "";
    if (q.extLink) {
      extLink = q.extLink.replace(q.sequence, "").replace(q.subSeq, "").replace("//", "");
    }

    let urlStr = ["/", this.site, category, q.qstnid, q.sequence, q.subSeq, q.subqstnId, extLink].filter(Boolean).join("/");

    //let urlStr= "/" + this.site + "/" + category + "/" +q.extLink;


    //this.router.navigateByUrl(urlStr);
    return urlStr;
  }

}
