
<form #fldForm = "ngForm" class=" row form-inline" (ngSubmit) = 'submit(fldForm.value)'>
    <span  class="form-group">
     <label  for="value" class="sr-only">{{fieldName}}</label>
     <input  class="form-control-plaintext" type="text" name= "fieldValue" class="form-control" id="value" placeholder= '{{h[fieldName]}}' ngModel>
    </span>
       <button type="submit" class="btn btn-primary badge">change {{fieldName}} </button>
  
</form>
  
