import { Component, OnInit } from '@angular/core';

  
import { FormControl, FormGroup } from '@angular/forms';

import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, map, share, startWith, switchMap } from 'rxjs/operators';
import { Page } from '../../gen/paginator/pagination';
import { Detail } from '../detail';
import { PageGetterService } from './page-getter.service';





@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss']
})
export class ListPageComponent implements OnInit {


  filterForm: FormGroup;
  page: Observable<Page<Detail>>;
  pageUrl = new Subject<string>();

  constructor(
    private pageGetterService: PageGetterService
  ) {
    this.filterForm = new FormGroup({
      is_available: new FormControl(),
      search: new FormControl()
    });

    const filterValue = this.filterForm.valueChanges.pipe(
      debounceTime(200),
      startWith(this.filterForm.value),
    );
    this.page = merge(filterValue, this.pageUrl).pipe(
      switchMap(urlOrFilter => this.pageGetterService.list(urlOrFilter)),
      share()
    );
    
  }

  
  ngOnInit(): void {
  //  throw new Error('Method not implemented.');
  }

  onPageChanged(url: string) {
    this.pageUrl.next(url);
  }
}