

<ng-container >
<div *ngIf='qhf.enable(fldList,"testTime")' class="form-group">
    <label for="testTime">Test Time in Minutes</label>
    <input type="text" class="form-control" 
    [(ngModel)]="ei.testTime" 
    name= "testTime"   placeholder="25">
  </div>

  


<div *ngIf='qhf.enable(fldList,"dueDate")' class="form-group">
    <label for="dueAng">Due date</label>
    {{ei.dueDate|date:'dd:MMM'}}
    <input type="date" class="form-control" [(ngModel)]="ei.dueDate" value= "ei.dueDate"
    name= "dueAng"  placeholder="dd/mm/yyyy">
  </div>

  
  
<div *ngIf='qhf.enable(fldList,"cfgFldr")' class="form-group">
  <label for="cfgFldr">Config Folder</label>
  <input type="string" class="form-control" [(ngModel)]="ei.cfgFldr" value= "ei.cfgFldr"
  name= "cfgFldr" >
</div>


 
<div *ngIf='qhf.enable(fldList,"titleImage")' class="form-group">
  <label for="cfgFldr">title-image URL</label>
  <input type="string" class="form-control" [(ngModel)]="ei.titleImage" value= "ei.titleImage"
  name= "titleImage" >
</div>




<div *ngIf= 'qhf.enable(fldList,"extraJson")'>
  
  <app-my-json class="my-5" [table]= "'qstnhdr'" [q] = "h"
   [justOneField]= "'extra'"> </app-my-json>
  
  </div>


</ng-container>
