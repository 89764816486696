     
     <!--[ngClass]="{ 'from': c.role === 'learner',
  'to':   c.role === 'teacher' }"-->


  <div class="message fullWidth">
  
    <td *ngIf= '!quillView' innerHTML = {{formatMsg(c)}}> </td>
     

<quill-view *ngIf= 'quillView'  [content]= 'formatMsg(c)' format="html" theme="snow"></quill-view>

    <!-- 
    <ng-container *ngIf ='! ["HnewInfo"].includes(c.cmntType)' >
   </ng-container>
   -->
   
   

   <div class= "float-center " > 
 <span class= "font-italic small " *ngIf= 'c.status!=="1-1"' >{{c.addUser}}</span>
 <span class= "bg-warning font-italic small " *ngIf= 'c.status=="1-1" &&(userGroup && userGroup.includes("teacher"))' >{{c.addUser}}</span> @ <span class= "small">{{c.addDate}} </span>
</div>
<hr>
 <!-- @ {{c.addTime}}-->
</div>


    