
<!--
<br>
-->
<button  class= "btn badge text-muted float-right px-5 mx-5 "> QC Check done: ...  </button>

<div *ngIf='!q.difficulty && cs.enable("qstnAnalyzer","asgnDifficulty")' class= "px-2" >

    
<!--span class="rainbow-text font-italic" >pls assign difficulty:</span-->

qstn is:

<button class="btn btn-sm btn-info btn-sm-2 badge mx-2 badge  " (click)= 'changeQDField("difficulty", 1)'> easy </button>

<button class="btn btn-sm btn-info btn-sm-2 badge mx-2 badge  " (click)= 'changeQDField("difficulty", 2)'> ok </button>

<button class="btn btn-sm btn-info badge btn-sm-2 badge mx-2 " (click)= 'changeQDField("difficulty", 3)'> difficult </button>

</div>



<div *ngIf='cs.enable("qstnAnalyzer","voteDifficulty")' class="px-2"> 

 <span class="text-info rainbow-text font-italic"> vote and see result:</span>  
 Qstn is:
<button class="btn btn-sm btn-info btn-sm-2 badge mx-2 badge  " (click)= 'increaseCount("simple")'> simple </button>
<span class="text-success" *ngIf="voted"> #: {{extra.get('simple')}} </span>
<button class="btn btn-sm btn-info btn-sm-2 badge mx-2 badge  " (click)= 'increaseCount("average")'> average </button>
<span class="text-success" *ngIf="voted"> #: {{extra.get('average')}} </span>
<button class="btn btn-sm btn-info badge btn-sm-2 badge mx-2 " (click)= 'increaseCount("difficult")'> difficult </button>
<span class="text-success" *ngIf="voted"> #: {{extra.get('difficult')}} </span>

<span class="text-success" *ngIf="voted"> per Teacher: {{q.difficulty}}/3 </span>

<!--
<button  class= "btn btn-sm badge text-muted float-right px-5 mx-5"> QC Check done: ...  </button>
-->
</div>