<div *ngIf="extra" class="float-right"> {{s.testTime(header.extra)}} </div>

<div class="row ">


  <div class=" col-10 col-md-4"> <span class="col-10 text-left badge my-1 "
      innerHTML='{{s.getTitle(header)}}'> </span>
  </div>

  <!--
  <div class=" col-4 "> <span class="col-10 text-left text-truncate badge my-1 "
    innerHTML='{{s.getTitle(header)}}'> </span>
</div>
-->

  <!--
       <span class= "px-1"> : </span>  
       
     post view check failing
      <span *ngIf="s.enable('asgn')" class= "col-1 text-muted font-italic text-small">Nxt: {{getNextDate(header.actionDate,refresh) | date}}</span>
   -->

  <div class="col-4 col-md-3">
    <ng-container>

      <span *ngIf="s.enable('status')"
        class="col-1 text-muted font-italic text-small"> {{header.status}}
      </span>

      <!--&& header.clsSec &&header.clsSec != 'null' && header.clsSec != 'cmn'-->
      <span *ngIf="s.enable('clsSec') "
        class="col-1 text-muted font-italic text-small"> {{header.clsSec}}
      </span>

      <span *ngIf="s.offering && s.offering.includes('type')"
        class="col-1 text-muted font-italic text-small">
        {{s.cs.getJustOffering(header.type)}}</span>

      <!--
      <span *ngIf="!s.subject || s.subject.includes('subject')"
        class="col-1 text-muted font-italic text-small">
        {{header.subject}}-{{header.book}}</span>
        -->

      <span *ngIf="s.enable('owner')"
        class="col-1 text-muted font-italic text-small">
        {{header.owner}}</span>
    </ng-container>
  </div>

  <span class="col-6 col-md-5">

    <app-view-lstr class="ml-1" [hdr]=header [i]=i></app-view-lstr>

      <span *ngIf="s.enable('setOn') && header.actionDate"
      class="text-muted font-italic small">set:
      {{header.actionDate|date:'dd:MMM'}}
    </span>


    

    <span *ngIf="s.enable('dueDate') && s.eh.dueDate(header.extra)"  class="text-muted font-italic small">
      -due: {{s.eh.dueDate(header.extra)|date:'dd:MMM'}}

      <!--  
      <span *ngIf='s.eh.dueDate(header.extra)'>due:{{s.eh.dueDate(header.extra)|date:'dd:MMM'}}</span>
      
      <span *ngIf='!s.expiryDate(header.extra) && getNextDate(header.actionDate,refresh)'> due: {{getNextDate(header.actionDate,refresh)|date:'dd:MMM'}}</span>
      -->
    </span>


    <app-group-addon *ngIf="s.enable('groupMenu',i,header)" [hdr]=header>
    </app-group-addon>

    <!-- cat based features *ngIf="s.enable('mergeBtns')" *ngIf="s.enable('dgtzBtns')" -->

    <ng-container *ngIf='s.enable("dgtzrBtns",i,header)'>

      <app-dgtzr-btn class="ml-2" [header]=header [i]=i></app-dgtzr-btn>
      <!--
    <app-hdr-btn-lstr  [header] = header [i]= i > </app-hdr-btn-lstr>
    -->
      <app-gate-mover [header]=header [i]=i> </app-gate-mover>

    </ng-container>

    <ng-container *ngIf='s.enable("mergeBtns",i,header)'>
      <app-hdr-btn-lstr [header]=header [i]=i> </app-hdr-btn-lstr>
    </ng-container>

    <ng-container *ngIf='s.enable("slbsBtns",i,header)'>

      <app-sem-mapper [h]=header [i]=i> </app-sem-mapper>

      <app-sylbs-mapr-btn [header]=header [i]=i> </app-sylbs-mapr-btn>
    </ng-container>



  </span>



  <!--common section-->
<span class="mx-4">
 <!--update:{{show('updateHdr')}}-->
<app-crud-btn *ngIf="show('updateHdr')" [header]=header [i]=i>
</app-crud-btn>
</span>






  <!--additional field -->



</div>