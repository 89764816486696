
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute,RouterEvent,NavigationEnd } from "@angular/router";
import { BoardDataService } from '../../board-data.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { FixedDataService } from '../../shared/fixed-data.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { BtnService } from '../../gen/btn.service';

@Component({
  selector: 'app-usp-slides',
  templateUrl: './usp-slides.component.html',
  styleUrls: ['./usp-slides.component.scss']
})
export class UspSlidesComponent implements OnInit {

@Input() ovrSub:string;

//rootUrl: string;
images: string[];
slides: any;
headerObj:any;
get site() {return  this.cs.getSite()} ;
  get cat() {return this.cs.getCat() };
  get board() {return this.cs.getBoard()};
  get course() { return this.cs.getCourse()};
  get offering() { return this.cs.getOffering()};
  get subject() { return this.cs.getSubject()};
  get feature() { return this.cs.getFeature()};
  get rootUrl() {return  this.cs.getRoot() };

  headerId:number;
  key:string;
  data$: Observable<any>;
  calling:boolean;
  constructor(config: NgbCarouselConfig, private route: ActivatedRoute,
    private router: Router, public cs:ClientInfoService, private ds: BoardDataService,private fd:FixedDataService,public b:BtnService) { 
      config.showNavigationArrows = true;
      config.showNavigationIndicators = true;
      //config.interval= 1500;
      config.interval= 0;
      config.pauseOnHover = true;
      //config.pauseOnFocus =true;
       }


   getRouteUrl(category:string, extLink: string): string{
      if(extLink=='te/Features/excel'){
    }
    return this.cs.getRouteUrl(category, extLink);
    }

    show(q:any){ return this.b.showQstn("slide",q)}

    
  ngOnInit() {
   let key =  this.ovrSub;
   if(!key){
   key = this.feature?this.feature:this.cat + "~slides";
   }

    this.calling = true;
    this.data$ =  this.ds.getCfgData(this.site,this.board,this.course,"cfg",key);
    
   /* needed for non async block in template call
    this.data$.subscribe(res => {
    this.slides= res.dtls;
    this.headerObj= res.hdr;
    //console.log(res);
  }  )
  */
    
   

  /*
    //put check for cat/features with slides..
    this.ds.getCatHeaders(this.site,this.board,this.course,this.key,this.subject).subscribe(data => {
     this.headerObj= (data as any).dataList[0];
     if(this.headerObj){
    this.headerId= this.headerObj.id;
    this.ds.getSiteQuestions(this.site,this.board,this.course,'cfg',this.subject,this.headerId).subscribe(data => {
    this.slides= data;
    //alert("Data retrieved"+ data);
    console.log(data);

    });
  }
  });
*/
 

}
  


}
