import { Component, OnInit, Input, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { BoardDataService } from '../../board-data.service';
import { Observable } from 'rxjs';
import { ClientInfoService } from '../../shared/client-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChptrService } from './chptr.service';
import { Header } from '../../tag/header';
import { BtnEnablerService } from '../../shared/btn-enabler.service';
@Component({
  selector: 'app-chptr-lstr',
  templateUrl: './chptr-lstr.component.html',
  styleUrls: ['./chptr-lstr.component.scss']
})

export class ChptrLstrComponent implements OnInit , AfterViewChecked{
  @Input() unitId:any;
  vids$: Observable<any>;
  utube= "https://www.youtube.com/embed/";
showAdd : boolean;
  calling: boolean;
multiFeatures:boolean;
activeRec: any;
  viewType: string;
  lastBook: string;
  lastBranch: string;
  lastSubject: string;
  hide: boolean;
  lastProcessedRec: any;
  lastBoard: string;
  lastCourse: string;

get site() {return  this.cs.getSite()} ;
get cat() {return this.cs.getCat() };
get board() {return this.cs.getBoard()};
get course() { return this.cs.getCourse()};
get feature() { return this.cs.getFeature()};
get offering() { return this.cs.getOffering()};
get subject() { return this.cs.getSubject()};
get groupId() { return this.cs.getGroupId()};
get signedUser() { return this.cs.getSignedUser()};
get shrGrp() { return this.cs.getShrGrp()};

//get chapter(){return Number(this.cs.getChapter()?this.cs.getChapter():0)}
constructor(public chs: ChptrService, private bds: BoardDataService,private cs:ClientInfoService,private router: Router,private cdRef : ChangeDetectorRef, private be:BtnEnablerService,private activatedRoute: ActivatedRoute) { }
 

ngAfterViewChecked(){
// why this is neded a mystry...
 this.cdRef.detectChanges();
}


ngOnInit(): void {
  
  this.activatedRoute.params.subscribe(({ subject }) => {
  this.calling = true; 
  this.cs.subject= subject;
  this.vids$= this.bds.getSiteHeaders(this.site,this.board,this.course,this.offering,subject);
  });

 }
 
refresh(){
  this.vids$= this.bds.getSiteHeaders(this.site,this.board,this.course,this.offering,this.subject);
}

 blockStatus = ['raw','dgtz','wip']
 
eligibleShowDelete(header:any,i:number){
// data without type means it is supposed to be for desc etc?
  if(!header.type){
    this.cs.lgr.log(header.id + " has no type") ;
    this.hide = true;
    return false;}
  
 //enabling dgtzr workflow processing..
let dgtzrStatusList = ['dgtz','wip','ready','reload','verify','test','stage']  
if (this.cat == "mydgtzr") {
 if(dgtzrStatusList.includes(header.status)){
  return true; 
 } else{
  this.hide = true;
  return false;
 }
  }


    if (this.cat == "avl" && header.status != 'avl'){this.hide = true; return false }

    //dgtz should have only dgtz related status 



  //istore-individual store, dstore-digibeststore,cstore:combinedstore
if ((this.cat == "istore" && (!header.school || header.school == "cmn")) || (this.cat == "ebstore" && (header.school && header.school != "cmn"))) { this.hide = true; return false }



  //app admin allowed  

if(this.groupId=='app_admin' || this.groupId == 'crclm_mngr'||
(header.shrGrp && (header.shrGrp=='public'||header.shrGrp == this.shrGrp)) ){return true};

// owner should be allowed to see..
if (this.blockStatus.includes(header.status) && header.owner != this.signedUser){
return false;
} 

this.hide = false;
if (header.hdrFormat && header.hdrFormat == "subDesc") { this.hide = true;  return false }


//rest check for block
//return !this.chs.enable('blockDisplay',i,header);//
return true;
}

//https://stackoverflow.com/questions/72352179/typescript-filter-array-of-objects-by-duplicate-properties 

needGroupHdr(values:Header[],field:string,fieldValue:any,i:number){
let   duplicateObjects = values.filter(

  (h:Header) => {
    return (
    h[field] === fieldValue
    && ((h.subject == values[i].subject) &&(!h.book || h.book == values[i].book ) && (!h.branch || h.branch == values[i].branch) ) 
  );
}  );

 this.cs.log(duplicateObjects.length);
  
 return duplicateObjects.length >1 ;
  }


needBookList(h:Header,i:number){
  
  // for all course always write book for first record
 //if(this.course && this.course.startsWith("course") && i==0 ){return true}

  if(this.subject && this.subject.startsWith("subject")){return false}
    
  if ((h.board !== this.lastBoard) || (h.course !== this.lastCourse) || (h.subject !== this.lastSubject) || (h.book !== this.lastBook) || (h.branch !== this.lastBranch) )  {
   // console.log("different:" +  this.lastSubject + this.lastBook + this.lastBranch)
 this.lastBoard = h.board;
 this.lastCourse = h.course;
 this.lastSubject = h.subject;
 this.lastBook = h.book;
 this.lastBranch= h.branch;
 return true;
}
else{
return false;
}

}

getView(){
if(this.chs.enable('multiFeatures')) { return 'multi'}
return "single";
}



enable(btnId:string,h:any){
  let component ="chptrLstr";
  this.be.enable(component,btnId,h,false);
}


}

 /*
   this.vids$= this.ds.getSiteQuestions(this.site,this.board,this.course,this.offering,this.subject,this.unitId ) ; 
   */
  
  //
  

  
  
   /*
    if(this.offering == "dhw"){
    title= title+ [' set on: ',hdr.formattedActionDate].filter(Boolean).join("-") ;
    }
    */
   
  

