<div class="sky">
  <img class="cloud" src="/assets/img/clouds-background-png-CloudImage.png"
    alt="cloud">
  <!--
  <img class="cloud" src="http://images.clipartpanda.com/clouds-background-png-CloudImage.png" alt="cloud">
-->
</div>
<div class="grass"></div>
<div class="road"> 
  <div class="lines"></div>
  <img
    src="https://forum.imguol.com/avatars/gallery/noticias/Gifs%20Animados%2006/animados298.gif"
    class="mario" />
  </div>
  <div class="grass"></div>
  
  <!-- <img src="https://forum.imguol.com/avatars/gallery/noticias/Gifs%20Animados%2006/animados298.gif" alt="luigi" class="luigi" /> -->


<app-ad-banner> </app-ad-banner>