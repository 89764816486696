import { Component, Input, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-cfg-dtl',
  templateUrl: './cfg-dtl.component.html',
  styleUrls: ['./cfg-dtl.component.scss']
})
export class CfgDtlComponent implements OnInit {
  @Input()  q :any;
  @Input()  i :number;
 get offering() { return this.cs.getOffering()}
  constructor(private cs:ClientInfoService) { }

  ngOnInit(): void {
     
  }

  
}
