

<div  class= "pb-2" *ngIf= 'q'>
    
    <app-doubt-dstr [q]= q *ngIf="q.answer"  > </app-doubt-dstr>
   
    <app-qstn-analyzer [q] = q> </app-qstn-analyzer>


<!--
<app-cmnt-list  [cmntType]= '"Dqchat"' [featureType] = "'viewQstn'"  [parentHdrId] = cs.getChapter() [parentDtlId] = q.id [mode]= '"list"' > </app-cmnt-list>
-->

</div>
