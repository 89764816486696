import { Component, OnInit } from '@angular/core';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-home-template',
  templateUrl: './home-template.component.html',
  styleUrls: ['./home-template.component.scss']
})
export class HomeTemplateComponent implements OnInit {
  component: string;
  
  get justSite(){return this.cs.getJustSite()}

  constructor(public cs:ClientInfoService) { }

  ngOnInit(): void {
    //get home data and show..
    this.component = this.justSite +'-home'
  }

  
  
}
