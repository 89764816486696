import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-screen-end-footer',
  templateUrl: './screen-end-footer.component.html',
  styleUrls: ['./screen-end-footer.component.scss']
})
export class ScreenEndFooterComponent implements OnInit {
  @Input() site:string;
  
  
  constructor() { }

  ngOnInit(): void {
  }

}
