import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-shower',
  templateUrl: './info-shower.component.html',
  styleUrls: ['./info-shower.component.scss']
})
export class InfoShowerComponent implements OnInit {
@Input() c:any;

  constructor() { }

  ngOnInit(): void {
    console.log(this.c);
  }

}
