import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsrhdrService } from '../../user/usrhdr.service';
import { ListerService } from '../../chat/lister.service';
import { ClientInfoService } from '../client-info.service';
import { FixedDataService } from '../fixed-data.service';
import { PropFromUrlService } from '../prop-from-url.service';
import { SecurityService } from '../security.service';
import { ClassList } from './class-list';
import { ExamList } from './exam-list';
import { SectionList } from './section-list';
//import { SubjectList } from './subject-list';
import { SubjectObj } from './subject-obj';


@Component({
  selector: 'app-exam-selector',
  templateUrl: './exam-selector.component.html',
  styleUrls: ['./exam-selector.component.scss']
})
export class ExamSelectorComponent implements OnInit {
  list: ExamList[] ;
 
  selectedExam: ExamList;
  selectedClass: ClassList;
  selectedSection: SectionList;
  selectedSubject: SubjectObj;
  bypassClsSec: boolean;
  subjectList: any;
  showSection: boolean;
  get unSigned() { return this.cs.isUnsigned()} 
  get site() { return this.cs.getSite()} 
  get cat() { return this.cs.getCat()} 
  
  get board() { return this.cs.getBoard()} 
  get course() { return this.cs.getCourse()} 
  
  get feature() { return this.cs.getFeature()} 
  get offering() { return this.cs.getOffering()} 
  get subject() { return this.cs.getSubject()} 
  get usrSubject() { return this.cs.getUsrSubject()} 
  get clsSec() { return this.cs.getClsSec()} 
  get signedUser() { return this.cs.getSignedUser()} 

  //get bypassclsSec() {if(!this.ss.getUserClsSec()) return true; else return false} 
  get betaViewer() {return this.cs.betaViewer()}
  //note-- backend filters only at exam-level, front end can filter at teacher level
  get showAll123(){ return this.cs.enable('examSlctr','showAllSub123')}
  get showTeacher(){ return this.cs.enable('examSlctr','showTeacher')}
  get root() { return this.cs.getRoot()} 
  get showAll(){ return this.cs.enable('examSlctr','showAllSub')}
get showAssignedSub(){ return this.cs.enable('examSlctr','showAssignedSub')}  

  
  constructor(private httpClient: HttpClient,private router: Router,public cs:ClientInfoService,private prop:PropFromUrlService , private fd:FixedDataService, public ss:SecurityService, public ls:ListerService, private uhs: UsrhdrService) { }

  ngOnInit(): void {
 
    this.uhs.domainRec(this.site).subscribe(data =>{
     // console.log(data);
      this.showSection= data?.result?.showClsSec;
    });

    // latter get it from site table
   
if(this.site.startsWith("eb") ){
} 


let justSite = "cmn"; //this.cs.getJustSite();
/*
  var  url = this.root+ `/etl/property/ang/${justSite}/ExamList.json`;
*/

var url= `/etl/esadigi-best/content/system/stage/property/ang/${justSite}/ExamList.json`;
let fileBased = false;
if(!fileBased){
this.fetchData();
}
else{

this.prop.getPropList(url).subscribe(data =>{
 //this.httpClient.get(url).subscribe(data =>{
 // console.log(data);
  (this.list as any )= data;
  this.fd.examList = data.result; 
  // && !this.unSigned
  if(this.list){
    this.selectedExam =  this.getUserExam();
    this.selectedClass =  this.getUserClass();
    this.selectedSection =  this.getUserClsSec();
    
    this.selectedSubject =  this.getUserSubject(); 

  }

 this.subjectList= this.ls
 .getSubjectList(this.offering)
 .subscribe((data:any) => {
  // console.log(data);
   this.subjectList = data.result;
});


})
}

  
   /*this.prop
      .getListOfGroup(url)
      .subscribe(
        data => {
          console.log(data);
        },
        err => {
          console.log(err);
        }
      );
     */ 


    
    
  }

  //if just cmn-section then don't show.
  showClsSec(selectedClass:any){
   return  !(selectedClass.sections && selectedClass.sections[0].clsSec && selectedClass.sections[0].clsSec== "cmn");
  }


  ngOnChanges(){
   
    if(!this.unSigned){
     this.selectedExam =  this.getUserExam();
     this.selectedClass =  this.getUserClass();
     if(true || (this.clsSec && this.selectedClass)){
     this.selectedSection =  this.getUserClsSec();
    }
   
  }
  }


  fetchData(){
    
    let user = this.signedUser;
    if(this.showAll){
      user = "owner";
    }
   this.ls.getExamList(user).subscribe((data:any) => {
      //console.log(data);
      this.list  = data.result;
      this.fd.examList = data.result;
     // (this.list as any )= data;
     // && !this.unSigned
      if(this.list ){
       this.selectedExam =  this.getUserExam();
       this.selectedClass =  this.getUserClass();
       this.selectedSection =  this.getUserClsSec();
       
       this.selectedSubject =  this.getUserSubject(); 
   
     }
  
    } );
  }


  getClasses(selectedBoard: ExamList) {
    return this.list.find(e => e === selectedBoard);
  }

  getUserExam() {
    return this.list.find(e => e.board === this.board);
  }

  getUserClass() {
    if(this.selectedExam){
    return this.selectedExam.courses.find(e => e.course === this.course);
   }
    
  }

  getUserClsSec() {
    let clsSecForSubject = this.clsSec;
    // this.bypassClsSec= true;
    if(clsSecForSubject=='clsSec') {clsSecForSubject= 'cmn';}
    if(this.selectedClass && this.selectedClass.sections){
      //return default class if just 1 class there
      if(this.selectedClass.sections.length == 1){
       return this.selectedClass.sections[0];
      }
      else{
    return this.selectedClass.sections.find(e => e.clsSec === clsSecForSubject);
      }
      }

      return null; 
      }
  
      getSecText(clsSecCode){
      
      if(clsSecCode== 'clsSec'){
      if(this.cs.getJustSite()== 'ebc'){return "batch"}
      if(this.cs.getJustSite()== 'ebs'){return "Section"}
      }
     return clsSecCode;
      }

  getUserSubject() {
    if(this.usrSubject && this.selectedSection && this.selectedSection.subjectDtos){
      //console.log('subject retrieved ', this.selectedSection );
     return this.selectedSection.subjectDtos.find(e => e.name === this.usrSubject);
    }
  }
  

browseClass(selectedExam:ExamList,course:string,subject?:string){
  
  this.cs.setBoard(selectedExam.board);
  // if selected class not in the list, choose first class of board
  if(selectedExam.courses.indexOf(this.selectedClass)!= -1){
   course= course;
   console.log("course found ",course)
  } else{
    
   course =  selectedExam.courses[0].course;
   this.selectedClass = selectedExam.courses.find(e=>e.course===course);
   console.log("new course assigned1",this.selectedClass)
   
   console.log("new course assigned",course)
  }

  //revisit below 2 if needed , by default we add first section if just one section there...
  this.selectedSection =  this.getUserClsSec();
  this.selectedSubject =  this.getUserSubject(); 
   

  let urlStr= "/" + this.cs.getSite() + "/" + this.cat ;
  if(this.feature){
    urlStr= urlStr+ "/features/"+ this.feature;
  }
  let boardCourse= "/" + selectedExam.board  + "/" + course; 
   urlStr= urlStr+ boardCourse;

   if(this.offering){
    urlStr= urlStr+ "/"+ this.offering ;
     
   }
    
  this.cs.addMsg(selectedExam.board +"-" + course + " selected", 'toast');  
  this.router.navigateByUrl(urlStr);
  
}

setClsSec(clsSec:string){
  this.cs.setClsSec(clsSec);
}


browseSubject(subject:string){
  
  
  let urlStr= "/" + this.cs.getSite() + "/" + this.cat ;
  if(this.feature){
    urlStr= urlStr+ "/features/"+ this.feature;
  }
  let boardCourseSub= "/" + this.board  + "/" + this.course ; 
   urlStr= urlStr+ boardCourseSub;

   if(this.offering){
    urlStr= urlStr+ "/"+ this.offering;
    
   }
 
   if((this.offering || this.feature)){
    urlStr= urlStr + "/" + subject;
   }

  this.cs.setUsrSubject(subject);
   
  this.cs.addMsg( subject + " selected", 'toast');  
 
  if(this.unSigned){
  urlStr = urlStr.replace('home','learner')
  }

  this.router.navigateByUrl(urlStr);
  
}

showSubDelete(sub:SubjectObj){

 return this.showAll || sub.teacher == this.signedUser ;

}


showSub(sub:SubjectObj){

  return !this.showAssignedSub || sub.teacher == this.signedUser ;
 
 }
 

}
















 
  //list1 not used, moving to property file;
/*
  list1: ExamList[] =
    [
      {
        "board": "CBSE",
        "classes": [
          {
            "class": "IX",
            "sections": [
              {
                "clsSec": "A", "subjects": [{ "name": 'Physics', "teacher": "pshalini" },
                { "name": 'Chemistry', "teacher": "pmishra" }]
              }],
          },

          {
            "class": "X",
            "sections": [
              {
                "clsSec": "A", "subjects": [{ "name": 'Physics', "teacher": "pshalini" },
                { "name": 'Chemistry', "teacher": "pmishra" }]
              }],
          }
        ]
      },

      {
        "board": "ICSE",
        "classes": [
          {
            "class": "X",
            "sections": [
              {
                "clsSec": "A", "subjects": [{ "name": 'Physics', "teacher": "pshalini" },
                { "name": 'Chemistry', "teacher": "pmishra" }]
              }],
          }
        ]
      },
       {
        "board": "civil",
        "classes": [
          {
            "class": "II",
            "sections": [
              {
                "clsSec": "A", "subjects": [{ "name": 'Physics', "teacher": "pshalini" },
                { "name": 'Chemistry', "teacher": "pmishra" }]
              }
              ,
              {
                "clsSec": "B", "subjects": [{ "name": 'Physics', "teacher": "pshalini" },
                { "name": 'Chemistry', "teacher": "pmishra" }]
              }
            ],
          }
        ]
      }
    ];
  */