import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExtraHdrService } from '../../gen/extra-hdr.service';
import { BoardDataService } from '../../board-data.service';
import { ExtraInfo } from '../assigner/extra-info';
import { ClientInfoService } from '../client-info.service';

@Component({
  selector: 'app-hdr-view-btns',
  templateUrl: './hdr-view-btns.component.html',
  styleUrls: ['./hdr-view-btns.component.scss']
})
export class HdrViewBtnsComponent implements OnInit {
@Input() hdr:any;
  extraObj: any;
  
  constructor(private router: Router,public cs:ClientInfoService, public ds: BoardDataService,public eh:ExtraHdrService) { }

  ngOnInit(): void {
    this.extraObj = JSON.parse(this.hdr?.extra);
  }

  ngOnChanges():void{
    this.extraObj = JSON.parse(this.hdr?.extra);
  }
  navigateNewUnit(newUnit:any) {
    
 this.ds.getHdrObj(newUnit).subscribe(data => {
      if((data as any).status==200 && (data as any).result){
      let hdr = (data as any).result;
      let url = this.router.url.replace(this.cs.getChapter(),newUnit).replace(this.cs.getOffering(),hdr.type)
      this.router.navigate([url]);
       }  });
    
     }
   
   navigateDigiSetup(){  
let cfgFldr = this.eh.getValue(this.hdr.extra,'cfgFldr');
if(!cfgFldr){
  cfgFldr =this.cs.getSignedUser(); 
}
this.cs.navigateToNewWindow("mymkr","digitizer/"+cfgFldr, this.hdr?.hdrFormat);
    }

}
