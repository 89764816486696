import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-eshome',
  templateUrl: './eshome.component.html',
  styleUrls: ['./eshome.component.scss']
})
export class EshomeComponent implements OnInit {
board:string;
course:string;
index: boolean;
site:string;
cat:string;
subject:string;
get ussigned(){ return this.cs.isUnsigned()}


/*
images= ["https://examsteppers.com/examimages/FrontPage/ang/1.jpg",
 "https://examsteppers.com/examimages/FrontPage/ang/2.jpg",
 "https://examsteppers.com/examimages/FrontPage/ang/3.jpg"];
*/
 images = [1, 2, 3].map(() => `https://picsum.photos/1900/500?random&t=${Math.random()}`);

  constructor(private route: ActivatedRoute,
    private router: Router,public cs:ClientInfoService) {
this.route.params.subscribe( params => {
//alert(params);
//console.log(params);
this.site= params['site'];
this.board= params['board'];
this.course= params['course'];
this.course= params['subject'];
this.cat= params['cat'];

});
}

  ngOnInit() {
  //this.board= "ICSE";
   this.index= true;
  }


  
 goToRoute(id) {
    document.getElementById(id).scrollIntoView();
  }

}
