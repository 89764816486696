import { Component, OnInit } from '@angular/core';
import { ExamDataService } from '../../exam-data.service';
import { CommonModule } from '@angular/common';
import { ClientInfoService } from '../../shared/client-info.service';

import { BoardDataService } from '../../board-data.service';


declare var MathJax:any;


@Component({
  selector: 'app-homeworkhelper',
  templateUrl: './homeworkhelper.component.html',
  styleUrls: ['./homeworkhelper.component.scss']
})
export class HomeworkhelperComponent implements OnInit {

appTitle: string = 'Percent App';
site:string;
board: string ;
course:string;
yearName: string;
subject: string ;
subjectO: Object ;
subjects: Object;
users : Object;
users2: Object;
header: Object;
headers: Object;
title = 'ES- Percentage Booster'
questions: Object ;
showA: boolean = false;
showY: boolean = false;
showQ: boolean = false;
showS: boolean = true;
b : string;
questionHeaderE: Object;


  constructor(private boardData: BoardDataService, private data: ExamDataService, private cs:ClientInfoService) {
this.site= cs.getSite();
this.board = cs.getBoard();
this.course= cs.getCourse();
 //this.subject = 'Chemistry';
}



ngOnChanges() {

    this.cs.typeSet();
        // MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
        alert("View typeset Retried! on changes - data load ");
        }



  ngOnInit() {
 this.b = "5";
//  this.board= "ICSE";
  /*
   this.data.getUsers().subscribe(data => {
        this.users = data;

    });



    this.data.getUsers2().subscribe(data => {
        this.users2 = data;
        //console.log(this.users);
      });
*/

  this.data.getTagQuestions(1).subscribe(dataList => {
        this.subject = "Physics";
        this.questions = dataList;
        //console.log(this.questions);
       // MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
        //alert("View typeset Retried! initial data load ");
      });


      this.boardData.getSiteSubjects(this.site,this.board,this.course,"hwh").subscribe(dataList => {
              this.subjects = dataList;
              //alert("subjects retrieved ");
              //console.log(this.site+this.board+this.course);
              //console.log(this.subjects);

            });


/* moving to new upper one.. examdata is legacy..
      this.data.getSubjects(this.site,this.board,this.course,"hwh").subscribe(dataList => {
              this.subjects = dataList;
              //alert("subjects retrieved ");
              //console.log(this.subjects);

            });

            */

/*
this.data.getHWSubjects(this.board,this.course).subscribe(dataList => {
        this.subjects = dataList;
        //alert("subjects retrieved ");
        //console.log(this.subjects);

      });
*/


  }



firstClick() {
    this.data.firstClick();

  }

showAns() {
    this.showA= true;
    this.typeSet();
  }

showSubject() {
    this.showS= true;
    this.showY = false;
    //this.typeSet();
  }

showYears() {
    this.showY= true;
    //this.typeSet();
  }


prvsyrsqstn(subject: string,headerId: number,tagHdrId: number,subjectO: Object) {
    this.subject= subject;
   this.showY= true;
   this.headers = (subjectO as any).questionHeaderEs;
   this.showQ= false;
   this.showS= false;
    this.data.getQuestions(headerId).subscribe(dataList => {
        this.questions = dataList;

    });

this.data.getTagQuestions(tagHdrId).subscribe(dataList => {
        this.questions = dataList;

    });


  }


loadQuestion(subject: string,headerId: number,tagHdrId: number,header:Object) {
    this.yearName= (header as any).year;
   this.showQ= true;
   this.showY= false;
   this.showS= false;
   this.showA= false;
   this.header  = (header as any);

   this.data.getQuestions(headerId).subscribe(dataList => {
        this.questions = dataList;

    });


  }




hideAns() {
    this.showA= false;
  }

  typeSet(){
  //alert("Hello World");
  MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
 // alert("View typeset Retried! Thanks from tyepset ");

}

 ngAfterViewChecked(){
  //alert("Hello World");
  MathJax.Hub.Queue(["Typeset", MathJax.Hub, "aa"]);
  //alert("View typeset Retried- after view checked..! Thanks ");

}

}
