import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddQstnDtlComponent } from './add-qstn-dtl/add-qstn-dtl.component';
import { SharedModule } from '../shared/shared.module';
import { UpdQstnDtlComponent } from './upd-qstn-dtl/upd-qstn-dtl.component';
import { ViewQstnDtlComponent } from './view-qstn-dtl/view-qstn-dtl.component';
import { DoubtDstrComponent } from './doubt-dstr/doubt-dstr.component';
import { CommentsModule } from '../comments/comments.module';
import { CrudQstndtlComponent } from './crud-qstndtl/crud-qstndtl.component';
import { TagModule } from '../tag/tag.module';
import { GencmntComponent } from './gencmnt/gencmnt.component';
import { SetBldrComponent } from './set-bldr/set-bldr.component';
import { SlbsMprComponent } from './slbs-mpr/slbs-mpr.component';
import { ShowInfoComponent } from './show-info/show-info.component';
import { CrctnComponent } from './crctn/crctn.component';

import { AnsrViewComponent } from './ansr-view/ansr-view.component';
import { UsrAnsrComponent } from './usr-ansr/usr-ansr.component';
import { AddVidComponent } from './add-vid/add-vid.component';
import { CfgDtlComponent } from './cfg-dtl/cfg-dtl.component';
import { WriteAnswerComponent } from './write-answer/write-answer.component';
import { McqQstnComponent } from './mcq-qstn/mcq-qstn.component';
import { SubjectiveQstnComponent } from './subjective-qstn/subjective-qstn.component';
import { RatingComponent } from './rating/rating.component';

import { UpdCfgDtlComponent } from './upd-cfg-dtl/upd-cfg-dtl.component';
import { QstndtlListComponent } from './qstndtl-list/qstndtl-list.component';
import { JsonQstnComponent } from './json-qstn/json-qstn.component';
import { VidDtlComponent } from './vid-dtl/vid-dtl.component';

import { VidFldsComponent } from './vid-flds/vid-flds.component';
import { NoteComponent } from './note/note.component';
import { DigitizeComponent } from './digitize/digitize.component';
import { OptionalModule } from '../optional/optional.module';
import { SlideComponent } from './slide/slide.component';
import { TblMkrComponent } from './tbl-mkr/tbl-mkr.component';
import { FieldChangerComponent } from './field-changer/field-changer.component';
import { WholeQstnBtnComponent } from './whole-qstn-btn/whole-qstn-btn.component';
import { QstnAnalyzerComponent } from './qstn-analyzer/qstn-analyzer.component';
import { QstnTopComponent } from './qstn-top/qstn-top.component';
import { ListPageComponent } from './list-page/list-page.component';
import { DtlViewSlctrComponent } from './dtl-view-slctr/dtl-view-slctr.component';

@NgModule({
  declarations: [DoubtDstrComponent,AddQstnDtlComponent, UpdQstnDtlComponent, ViewQstnDtlComponent, DoubtDstrComponent, CrudQstndtlComponent, GencmntComponent, SetBldrComponent, SlbsMprComponent, ShowInfoComponent, CrctnComponent, AnsrViewComponent, UsrAnsrComponent, AddVidComponent, CfgDtlComponent, WriteAnswerComponent, McqQstnComponent, SubjectiveQstnComponent, RatingComponent, UpdCfgDtlComponent, QstndtlListComponent, JsonQstnComponent, VidDtlComponent, VidFldsComponent, NoteComponent, DigitizeComponent, SlideComponent,TblMkrComponent, FieldChangerComponent, WholeQstnBtnComponent, QstnAnalyzerComponent, QstnTopComponent, ListPageComponent, DtlViewSlctrComponent],
  imports: [
    CommonModule,
    SharedModule,
    CommentsModule,
    TagModule,
    OptionalModule
  ],
  exports:[CrctnComponent,SlbsMprComponent,SetBldrComponent,GencmntComponent,CrudQstndtlComponent,DoubtDstrComponent,AddQstnDtlComponent,UpdQstnDtlComponent,ViewQstnDtlComponent, ShowInfoComponent ,
    AnsrViewComponent,UsrAnsrComponent,AddVidComponent,CfgDtlComponent ,SubjectiveQstnComponent,RatingComponent,QstndtlListComponent,VidDtlComponent,NoteComponent,DigitizeComponent,SlideComponent,TblMkrComponent ,McqQstnComponent, FieldChangerComponent,WholeQstnBtnComponent, QstnAnalyzerComponent,
    ListPageComponent]
})
export class QstndtlModule { }
