

  <!--
<div class= " fullWidth my-0 py-0" *ngIf="(data$ | async) as d; else loading">
 
<div *ngIf= "d.hdr" class="my-5 container marketing ">
 <p class= "pen40  text-center">   {{d.hdr.title}}</p>
 -->


 <div *ngIf="(data$ | async) as d; else loading" class="bg-light px-1">
  
  
  <ng-container *ngIf="d">
       
    <span class="row" >
      <app-go-store *ngIf='false && cs.enable("subMnuHardC","goStore")'> </app-go-store>
    
      <app-new-mkr *ngIf='cs.enable("subMnuHardC","newMkr",null,hdr)'> </app-new-mkr>
      
      <app-km-menu *ngIf='cs.enable("subMnuHardC","km")'> </app-km-menu>

      
      
      <app-cntxt-menu  *ngIf='cs.enable("subMnuHardC","oldCntxt",null,hdr)' [hdr] = hdr  >  </app-cntxt-menu>
    

    <ng-container *ngIf="d.dtls && d.dtls.length; else noItems">
    

    <!--target="_blank" slow and no session maintained-->

    
    

  <ng-container *ngFor="let i = index; let m of d.dtls;">
    <div *ngIf="m.groupId =='asoVidLdr' ">  
      <!--
  {{cs.enable("subMnuHardC",m.groupId,m,d.hdr)}}--
    {{m.groupId}}=={{m|json}}-<hr> -{{d.hdr|json}}
-->

  </div>  
  <!--
  {{cs.enable("subMnuHardC",m.groupId,m,hdr)}}- {{m.groupId}}--{{hdr.status}}
  -->

  <!-- don't pass btn Deatil as its not needed.--> 
<!-- d.hdr has 33 as submnubtn hdr and hdr has actual record-->
  <ng-container *ngIf= '!m.groupId  || !m.groupId.includes("newWin"); else newWindow'>
   <!--
    <span *ngIf="m.groupId== 'Pbstr'">{{cs.enable("subMnuHardC",m.groupId,m,hdr)}} -- {{m1|json}} </span>
    
    
    <span *ngIf='false  && m.groupId== "mymkr"'> enable: {{cs.enable("subMnuHardC",m.groupId,m,hdr)}}-- {{m|json}} </span>
  -->

  <li id="{{m.section + '-' + m.groupId}}" *ngIf='cs.enable("subMnuHardC",m.groupId,m,hdr)' class="list-unstyled nav-item justify-center">
        <a [routerLinkActive]="['active']" routerLink = {{cs.getRouteUrl(m.category,m.extLink,m.format,m.groupId)}}  
        class="mx-2 px-2 btn btn-outline-primary badge border-0" ngbTooltip={{m.instruction}} placement="right"  > <span innerHTML='{{m.title}}' > </span>
        </a>
      </li>

    </ng-container>

    <ng-template #newWindow>
     
      <!-- for hindi and sanskrit show devnagri typing-->
      <button *ngIf='
      
      (!m && !m.title ||!m.title.includes("Hindi")  || (m.title.includes("Hindi") && (justSubject=="Hindi"||justSubject=="Sanskrit"))) 
      
      && 
      cs.enable("sub-menu",m.groupId,m,hdr)' class="mx-2 btn btn-outline-info badge border-0" (click) = cs.navigateToNewWindow(m.category,m.extLink,m.format)>{{m.title}} </button> 

      
    </ng-template>


  </ng-container>

</ng-container>  <!--inner div-->

  <!-- don't change this is for new windows opening -->
   
  <app-system class='' *ngIf='cm.enable("system")'> </app-system> 
  <app-gen-nav class="ml-auto" > </app-gen-nav>
    

</span>
  </ng-container>

  

  <!-- old booster -alternate view-->
  <span  *ngIf='cs.getSubject() && cs.enable("subMnuHardC","bstrView")' class="nav-item justify-center">
    
    <a [routerLinkActive]="['active']" 
    routerLink = '{{cs.getRouteUrl("basic/features/pbooster","","withChptr")}}'  class="mx-2 btn text-warning btn-primary badge border-0"> Alternate View </a>
    
  </span>
  
  
  <app-subject-book-list *ngIf=" false"> </app-subject-book-list>  
  
  
</div>



<!--
<app-group-addon *ngIf='hdr&& !cs.getChapter() && !cs.enable("contextMenu","topGroupMenu","",hdr) ' class= "ml-2"  [hdr] =hdr> </app-group-addon>
-->

  <!--
  <nav *ngIf ='!cs.getChapter() && cs.enable("subMnuHardC", "context")'>
   <button  class= "btn-outline-primary border-0 badge float-right" (click) = 'showOld = !showOld'>... </button>
   
  </nav>
  
-->
  

   
<!---->
 




  <ng-template #loading >... </ng-template>
  <ng-template #noItems ></ng-template>
 




