

<div *ngIf="(data$ | async) as d; else loading" class="px-1">

    <!-- {{d.hdr.hdrFormat}} -->


     <ng-container *ngIf="d.dtls && d.dtls.length; else noItems">

          <ng-container
               *ngIf="d.hdr?.hdrFormat == 'ad-banner'; else homeTemplate">

               <ng-container *ngFor="let i = index; let m of d.dtls;">

                    <div [ngSwitch]="m.format" class="container">
                         <app-job-ad *ngSwitchCase='"job-ad"'
                              [headline]='c.inputs.headline'
                              [body]='c.inputs.body'>
                         </app-job-ad>

                         <app-exam-list *ngSwitchCase='"exam"'>
                         </app-exam-list>
                         <app-profile *ngSwitchCase='"profile"'
                              [name]='c.inputs.name'></app-profile>

                         <app-sliders *ngSwitchCase="'slide'"></app-sliders>
                         <app-usp *ngSwitchCase="'usp'" [ovrSub] = 'm.groupId'></app-usp>
                         <app-featurete
                              *ngSwitchCase='"feature"'></app-featurete>
                         <app-profile *ngSwitchCase='"profile"'> </app-profile>
                         <app-platform-set *ngSwitchCase='"platform"'  [ovrSub] = 'm.groupId'>
                         </app-platform-set>
                         
                         <app-daily-detail *ngSwitchCase='"thought"' [type] = '"fresh"' [subject] = '"Thought"' > </app-daily-detail>
                         <app-testimonials *ngSwitchCase='"testimon"'> </app-testimonials>
                         <div *ngSwitchDefault>
                              <p> {{m.format}}?</p>
                         </div>

                    </div>

               </ng-container>

          </ng-container>
     </ng-container>
</div>






<ng-template #loading>... </ng-template>
<ng-template #noItems>
     <app-home-template> </app-home-template>
</ng-template>

<ng-template #homeTemplate>
     <!-- if no config for the site-home~config  then also show default-->
     <app-home-template> </app-home-template>

</ng-template>



<!--div id="aa" *ngFor="let c of adList;let i = index; " class="dontuse-ad-banner">

     processing{{i}} --- {{c.component}}

     <div [ngSwitch]="c.component" class="container">

          <app-job-ad *ngSwitchCase='"job-ad"' [headline]='c.inputs.headline'
               [body]='c.inputs.body'> </app-job-ad>

          <app-exam-list *ngSwitchCase='"exam-list"'> </app-exam-list>
          <app-profile *ngSwitchCase='"profile"'
               [name]='c.inputs.name'></app-profile>

          <app-sliders *ngSwitchCase="'sliders'"></app-sliders>
          <app-usp *ngSwitchCase="'usp'"></app-usp>
          <app-featurete *ngSwitchCase='"featurete"'></app-featurete>
          <app-profile *ngSwitchCase='"profile"'> </app-profile>
          <app-platform-set *ngSwitchCase='"platform-set"'> </app-platform-set>

          <div *ngSwitchDefault>
               <p> {{c.component}}?</p>
          </div>
     </div>

</div-->