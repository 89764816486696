

<button  class= "btn btn-outline-primary border-0 badge   mx-3 "  (click) = 'expand(i)'> {{ expandI === i ? ' - ':' + '}} </button> 


 

 <ng-container class= "mx-3" *ngIf='expandI == i'> 

  <span *ngIf= 'header && (!header.site || header.site!=site)  && (!header.board || header.board == "cmn") '> *coe* </span>        

  
  <button *ngIf= " i==0 && tagBased(header.type) &&  enable('makeTermBstr',header,i)"  (click)="buildTermBstr(header.id)" class="btn badge btn-info"
  style="margin-left: 10px">make customized Bstr</button>
 
  
  <!-- this is for automated bstr src slctr, setup first  with normal build and activate  with after first model-->
  <button *ngIf= " tagBased(header.type) &&  enable('makeSpecialBstr',header,i)"  (click)="buildPercentageBstr(header)" class="btn badge btn-info"
  style="margin-left: 10px">make 5 Percentage Bstr</button>

  <!-- need  tuning for student - bstr on demand
  <button *ngIf= "enable('cBstrOnDemand',header,i)"  (click)="makeChptrBstr(header.id, header.id)" class="btn badge btn-info"
  style="margin-left: 10px">take Chptr Bstr</button>
-->

<!-- select chptr-source and setup bstr-->
  <button *ngIf= "(!tagBased(header.type) &&  enable('makeUnitBstr',header,i))"  (click)="buildChptrBstr(header)" class="btn badge btn-info"
  style="margin-left: 10px">make Chapter Bstr</button>

  <button *ngIf= "(tagBased(header.type) &&  enable('makeUnitBstr',header,i))"  (click)="buildChptrBstr(header)" class="btn badge btn-info"
  style="margin-left: 10px">make-tag Bstr</button>


    <app-status-changer *ngIf= "enable('changeStatus',header,i)" [hdr] = header [i] = i> </app-status-changer>
  
   <span class="mx-1 text-muted text-small">{{selfOwned?'self':""}} </span> 
   <button class= "btn-warning badge px-3 " *ngIf="show('updateHdr',header,i)" (click)="processUpdate(header,i)"
    > <span >{{(showCmnNum == i && showUpdate)?'Hide Update':'update'}} <i class="fa fa-edit"></i> </span>
    </button>
     
   <button *ngIf="show('updateHdr',header,i)" class= "mx-2 badge  btn-danger" (click)="clickMethod(header,i)">delete <i class="fa fa-trash" aria-hidden="true"></i> </button>

   
  <!--
<div *ngIf = "success &&(showAddI== i)"> changed: {{success}} </div>
-->

<button *ngIf= "enable('hdrGroup',header,i)"  (click) = 'showHdrGrp=!showHdrGrp' class= "btn-md btn-info badge mr-1"  >Associator# </button>

<button *ngIf= "grpAsoId && enable('hdrGroup',header,i)"  (click) = 'changeField(header,"headerId", grpAsoId)' class= "btn-md btn-info badge mr-1"  >Associate to {{grpAsoId}} </button>



</ng-container>
<br>

<div class= "mx-3 ">

  <app-qstnhdr-upd class="mx-5 row col-10" *ngIf="showUpdate && (showUpdateNum == i) && !tagBased(header.type)" [id]= 'header.id' ></app-qstnhdr-upd>

<app-update-header class="row col-12" *ngIf="showUpdate && (showUpdateNum == i) && tagBased(header.type)" [id]= 'header.id' ></app-update-header>

<app-show-hdrgrp *ngIf= 'showHdrGrp' [hdr] = header> </app-show-hdrgrp> 


<app-chptr-src-slctr *ngIf= 'chptrSrcSlctr' [refHdr] = header>  </app-chptr-src-slctr>
</div>