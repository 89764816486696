
  
  import { Component, Input, OnInit } from '@angular/core';
  import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
  
  @Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
  })
  export class HelpComponent implements OnInit {
  
    @Input() title: string;
    @Input() message: string;
    @Input() btnOkText: string;
    @Input() btnCancelText: string;
  
    constructor(private activeModal: NgbActiveModal) { }
  
    
    ngOnInit(): void {
    }
    
    public decline() {
      this.activeModal.close(false);
    }
  
    public accept() {
      this.activeModal.close(true);
    }
  
    public dismiss() {
      this.activeModal.dismiss();
    }
  }