import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHlprService } from '../../shared/http-hlpr.service';
import { ListerService } from '../../chat/lister.service';
import { ClientInfoService } from '../../shared/client-info.service';
import { HeadingsService } from '../../shared/headings/headings.service';
import { ExtraDtlService } from '../extra-dtl.service';

@Component({
  selector: 'app-ofr-title',
  templateUrl: './ofr-title.component.html',
  styleUrls: ['./ofr-title.component.scss']
})
export class OfrTitleComponent implements OnInit {
  @Input() subDesc:any;
  //ftrDtls$:Observable<any>;
  
  ftrDtl: any;
 
 h:any;
 d:any;

 constructor(public lstr:ListerService,public hdng:HeadingsService,private hlpr:HttpHlprService,public  ed:ExtraDtlService) { }

  ngOnInit(): void {
  this.getFtrDtl();
  
  }

  ngOnChanges(): void {
    this.getFtrDtl();
    
    }
  

  
getFtrDtl() {
  this.h= null;
  this.d= null;

  let extUrl:string = this.hlpr.ty;
  if(this.hlpr.su){
    extUrl = extUrl + "/" + this.hlpr.su;
  }
  
  this.lstr.getFtrDtl(extUrl).subscribe(data => {
  if((data as any).status==200 && (data as any).result){
    //console.log((data as any).result);
    let ftrDtl = (data as any).result;
    
    if(ftrDtl && ftrDtl.hdr){ 
      this.h = ftrDtl.hdr;
     // console.log(this.h);
      }
    
      //**important--need to be investigated as feature-detail getting screwed.
      // change subDESC back to h if needed...
      if(this.subDesc && this.subDesc.title){ 
        this.h = this.subDesc;
       // console.log(this.h);
        }
      
      if(ftrDtl && ftrDtl.dtls){  
      this.d = ftrDtl.dtls[0];
      //console.log(this.d);
    }
    this.hdng.setSEO(this.h, this.d);
    
  }  else{
  console.log("error");
   }
  },error=>{
    console.log(error);
    
  });
  }
  
}
