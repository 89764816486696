<ul class="r1ow" *ngIf="(sl$ | async) as sl; else loading">
    <!--
    {{sl|json}}
    -->
        <ng-container *ngIf='sl  && sl.length>1; else noItems'>
                <!--*ngIf= 's.includes("~") && (s.startsWith("rbs") || s.startsWith("vid")) '-->
            <li class="list-unstyled" *ngFor="let s of sl; let i = index;">
                <a *ngIf= 's.includes("~") && (s.startsWith("pyq") || s.startsWith("rbs") || s.startsWith("vid")) && cs.enable("bookList","topType")' class="px-2 mx-2 btn btn-outline-primary badge border-0"
                    [routerLink]='getRoute(s)'>
                    {{getTitle(s)}}</a>
            </li>
        </ng-container>
   

</ul>


<ng-template #noItems>
</ng-template>



<ng-template #loading>...</ng-template>


